import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';


export default Controller.extend(UseCurrentTrip, TripSaver, {


});
