import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';


export function historicPrice(params, namedArgs) {
  // let charge = namedArgs.charge;

  // Historic prices don't changes according to current exchange rates or the users pref

  return htmlSafe(formatCurrencyHash({
    val: namedArgs.val,
    fromCurrency: namedArgs.currency,
    toCurrency: namedArgs.currency,
    roundToClosest: 0,
    isText: false,
    truncate: false,
    markPricesUp: false
  }));

}

export default buildHelper(historicPrice);
