import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  observer
} from '@ember/object';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';

export default Helper.extend({

  settings: service(),
  onCurrencyChange: observer('settings.currentCurrency', function() {
    this.recompute();
  }),

  // Use this to show an exchange rate

  compute(params, namedArgs) {
    this.settings.currentCurrency; //Have to access this value in order for the recompute to trigger in the observer

    return htmlSafe(formatCurrencyHash({
      val: 1,
      fromCurrency: namedArgs.fromCurrency,
      toCurrency: namedArgs.toCurrency || this.get('settings.currentCurrency'),
      roundToClosest: 'skipRounding',
      isText: false,
      truncate: false,
      markPricesUp: false
    }));
  }

});
