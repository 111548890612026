import payFormComponent from 'b5b/components/pay-form/component';

import Service, {
  inject as service
} from '@ember/service';
import RSVP, {
  Promise as EmberPromise
} from 'rsvp';
import {
  observer
} from '@ember/object';
import {
  PAYPAL_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  run
} from '@ember/runloop';

import config from 'b5b/config/environment';

export default payFormComponent.extend({
  chargeType: 'paypal',

  ui: service(),
  _payButtonReady :false,

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return PAYPAL_CURRENCIES.includes(settlementCurrency) ? settlementCurrency : 'usd';
  },

  renderPaypalButton: observer('transactionAmount', 'transactionCurrency', function() {

    throw new TypeError('This is not compatible with latest charges implementation.');

    if (!this.get('transactionAmount') || !this.get('transactionCurrency')) {
      return
    }
    var promise = new EmberPromise((resolve) => {
      let clientId=config.APP.payPalClientId;
      addAsyncScript('https://www.paypal.com/sdk/js?client-id='+clientId+'&currency='+this.get('transactionCurrency').toUpperCase(), null, function() {
        // '&disable-card=amex,jcb,discover,elo,hiper'
        resolve(null);
      });
    });
    let context = this;

    promise.then(()=> {
      this.set('_payButtonReady', true);
      paypal.Buttons({
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: context.get('transactionAmount').toString(),
              }
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        },
        onApprove: function(data, actions) {
          context.ui.set('showProcessingModal', true);
          run.later(() => {
            //Basically stop showing that modal after 5 seconds.. as there is a lot of auto magic with Paypal
            context.ui.set('showProcessingModal', false);
          }, 5000);
          return actions.order.capture().then(function(details) {
            context.handleSuccesfulPayment(details)
          });
        },
        onError: function (err) {
          context.handleFailedPayment(stripeResponse).then().finally(()=> {
            context.ui.set('showProcessingModal', false);
            context.ui.showGeneralMessage('Oops', 'We were unable to process your payment. Please get in touch.');
          });
        }
      }).render('#paypal-button-container-'+context.elementId);
    })
  }).on('init'),

  // didInsertElement() {
  //   this._super(...arguments);

  // }

   //
});
