import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({


  tagName: '',
  templateManager: service(),
  tripService: service('trip'),

  @computed('whitelabel.isForTimbuktu')
  tripLayouts(isForTimbuktu) {
    let layouts = ['day-by-day', 'magazine']
    if (isForTimbuktu) {
      layouts.pushObject('static')
    }

    return layouts;
  },

});
