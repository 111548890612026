import TWidgetComponent from 'b5b/components/t-widget/component';

import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default TWidgetComponent.extend({
  classNames: ['pay'],
  tripService: service('trip'),
  ui: service(),
  @alias('tripService.currentTrip') trip: null

});