import { helper } from '@ember/component/helper';
import {
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';

export function subtractPriceHash(params, namedArgs) {
  let fromPriceHashDupe = duplicateCurrencyHash(namedArgs.from)
  return subtractSecondCurrencyHashContentsFromFirstHash(fromPriceHashDupe, namedArgs.val);
}

export default helper(subtractPriceHash);
