import Controller from '@ember/controller';

export default Controller.extend({
  activeTab: 'lodges',

  queryParams: [{
    activeTab: {
      as: 'active'
    }
  }]
});
