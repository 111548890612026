import TEnquireComponent from 'b5b/components/t-enquire/component';
import {
  trackEvent,
  captureEmail
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';

export default TEnquireComponent.extend({
  classNames: ['wizard'],

  ui: service(),

  enquiryType: 'wizard',

  enquireWizard(){
    trackEvent('wizard-enquire:submit');

    this.set('submittingEnquiry', true);

    var enquiry = this.setUpEnquiry();

    if (!enquiry.email){
      enquiry.set('email', this.model.email);
    }

    if (!enquiry.firstName){
      enquiry.set('firstName', this.model.firstName);
    }

    enquiry.set('wizardSubmission', this.model);

    captureEmail(enquiry.email, this.sharedUrl, 'ask', enquiry.firstName);

    enquiry.save().then(() => {
      this.set('submittingEnquiry', false);
      this.set('note', null);
      this.set('ui.wizardEnquirySent', true);
      // this.get('router').transitionTo('message-success');
      trackEvent('wizard:enquire:complete', {location: 'wizard-success'});
    }).catch((serverResponse) => {
      this.get('flashMessages')
        .danger('Please supply all the required information', {
          timeout: 4000,
          sticky: false
        });
      trackEvent('wizard-enquire:validation:failed');

      this.set('submittingEnquiry', false);

      // this.set('showErrors', true);
      // this.set('errors', getErrorsHashFromServerResponse(serverResponse));
    });
  },

  actions: {
    enquire() {
      this.enquireWizard();
    }
  }
});
