import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  alias
} from 'ember-decorators/object/computed';


import TripSaver from 'b5b/mixins/trip-saver';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import trip from '../../adapters/trip';

export default Controller.extend(TripSaver, UseCurrentTrip, {

  tripService: service('trip'),
  xplorer: service('components/x-plorer'),
  session: service(),
  router: service(),
  whitelabel: service(),
  flashMessages: service(),
  viaCreateParams: null, //Set by the route if a trip is being created for a type of entity

  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.savingTrip') savingTrip: false,

  actions : {
    onClose() {
      window.history.back();
    },

    onContinue() {
      if (!this.trip.name) {
        this.get('flashMessages').warning('Please name your trip');
        return;
      }
      if (!this.trip.hubspotDealId && this.whitelabel.isForTimbuktu) {
        this.get('flashMessages').warning('Please provide Hubspot deal id');
        return;
      }
      if (!this.trip.name) {
        this.trip.set('name', 'My new trip')
      }
      this.saveTrip(this.trip,{
        blockFailureToSaveReporting: true,
        allowCreationOfBlankTripForOnboardingSite: true,
        newTripLayout: this.showNewTripModal.newTripLayout
      }).then((trip) => {
        this.transitionToRoute('trip.index.edit.itinerary', trip, {queryParams: this.viaCreateParams});
      });
    }
  }
})
