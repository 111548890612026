import { helper } from '@ember/component/helper';

export function feefoReviewBarWidth(params, namedArgs) {
  let percent = (namedArgs.numStarReviews / namedArgs.totalReviews) * 100;
  if (isNaN(percent)) {
    return Ember.String.htmlSafe("right: 0");
  }
  return Ember.String.htmlSafe(`right: ${100 - percent}%`);
}

export default helper(feefoReviewBarWidth);
