import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import sideNav from 'b5b/mixins/side-nav';
import {
  setupDealEntityNav
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

import { isEmpty } from '@ember/utils';

export default Route.extend(sideNav, {

  ui: service(),
  scroll: service(),
  session: service(),
  screen: service(),
  templateManager: service(),
  dealService: service(),

  model() {
    return this.modelFor('deal.index');
  },

  beforeModel(transition) {
    this._super(...arguments);
    return this.get('session.currentUserPromise').then(() => {
      if (!(this.get('session.currentUser.isManager'))) {
        transition.abort();
        window.history.back();
      }


    })
  },


  setupController(controller, model) {
    this._super(...arguments);

    let deal = model;
    controller.set('deal', deal);
    // navs setup
    setupDealEntityNav(deal, this.get('dealService'), this.get('ui'), this.get('session'));

    // let userManagingThisTrip = trip.get('userTrip') && this.get('tripService.userCanManageThisTrip');

    // if (userManagingThisTrip || (trip.contentManagedTrip && this.get('session.currentUser.isContentTeam'))) {
    // if (this.get('screen.isMobileOnly')) {/
      this.get('ui').setProperties({
        displayOnSideBar: 'hide-after-tablet',
        sideNavOptions: [{
          route: 'deal.index.edit.trips',
          title: 'Trips',
          routeModel: deal
        },
        {
          route: 'deal.index.edit.details',
          title: 'Deal details',
          item: deal
        },
        {
          route: 'deal.index.edit.guests',
          title: 'Guests',
          item: deal
        }]

      });
    // }
    // }

    // if (userManagingThisTrip) {
    //   this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
    //     route: 'trip.index.edit.guests',
    //     title: 'Guests',
    //     item: trip
    //   }, {
    //     route: 'trip.index.edit.quote-details',
    //     title: 'Quote details',
    //     item: trip
    //   }, {
    //     route: 'trip.index.edit.pricing',
    //     title: 'Pricing',
    //     routeModel: trip
    //   }, {
    //     route: 'trip.index.edit.pricing.payments',
    //     title: 'Payments',
    //     routeModel: trip
    //   }, ]))
    // }

  },

  resetController(isExiting) {
    this._super(...arguments);

    this.get('ui').setProperties({
      sideNavOptions: null,
      showMainNav: true,
      displayOnSideBar:null,
      displayOn: null
    });


    if (isExiting) {
      this.set('ui.entityNavData', null);
      this.set('templateManager.tripToolsShowTemplateType', null)
    }
  },

  actions: {
    saveDeal() {
      return this.dealService.saveDeal({ deal: this.controller.model });
    }
  }

});
