import entityStartRoute from 'b5b/mixins/entity-start/route';
import Route from '@ember/routing/route';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(entityStartRoute, {

  entityType: 'general',

  headTags() {
    let model = this.currentModel;
    let title = `Plan a trip or african safari | ${this.whitelabel.agencySeoName}`


    var description = `Plan your trip or african safari with our fully customizable trip ideas, or start from scratch. Our travel experts can give you all the help and advice you need.`;
    let robots = 'noindex, nofollow'
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    return generateMeta({
      description,
      link,
      'og:title': title,
      robots
    });
  },

});
