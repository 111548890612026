import { helper } from '@ember/component/helper';

let bookingStateLabels = {
  'all': 'All booking stages',
  'saved': 'Saved',
  'quote': 'Quote',
  'booking': 'Booking',
  'past': 'Past',
  'enquiry': 'Enquiry',
  'deposit_paid': 'Deposit Paid',
  'balance_due': 'Balance Due',
  'balance_paid': 'Paid'
}

export function getBookingStateLabel([bookingState]) {

  return bookingStateLabels[bookingState];
}

export default helper(getBookingStateLabel);
