import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  run
} from '@ember/runloop';
import {
  isPresent
} from '@ember/utils';
import {
  htmlSafe
} from '@ember/string';
import AppRouteAuthMixin from 'b5b/mixins/app-route-auth-mixin';
import {
  generateMeta,
  captureEmail,
  trackEvent,
  getLoccalStorageEmailCapturedKey,
  getLoccalStorageKeyModalUXFeedbackShown,
  getLoccalStorageKeyModalWizardShown,
  getItemFromLocalStorage,
  setItemInLocalStorage,
  shuffleArray,
  hexToHsl,
  hexToRgb,
  getCookieValue,
  getCapuredEmail,
  setupConversionReportingWrapper,
  reportEmailToHubspot
} from 'b5b/utils';
import {
  setRates
} from 'b5b/helpers/format-currency';
import config from 'b5b/config/environment';
import RSVP, {
  Promise as EmberPromise
} from 'rsvp';

const EMAIL_CAPTURE_KEY = {
  FULL_SCREEN: 'signup_email_full_screen',
  MODAL: 'signup_email_modal'
};

const SIGNUP_MODAL_TIMEOUT = 30000;
// const SIGNUP_MODAL_TIMEOUT = 60000;
// const SIGNUP_MODAL_TIMEOUT = 1000;

// This needs to be removed after we move toogleLiek to service
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const rates = {};

export default Route.extend(AppRouteAuthMixin, {
  queryParams: {
    mapOnlyView: {
      replace: true
    },
    filterOnlyView: {
      replace: true
    }
  },
  affiliate: service(),
  feefo: service(),
  store: service(),
  scroll: service(),
  settings: service(),
  configService: service('config'),
  ui: service(),
  whitelabel: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  headData: service(),
  locationScrollHistory: service(),
  historyService: service(),
  router: service(),
  tripService: service('trip'),

  init() {
    this._super(...arguments);
    this.setupThirdPartyScripts();
  },

  headTags() {

    var url = this.isFastBoot ? 'https://www.timbuktutravel.com' : window.location.href;
    if (url.indexOf('https') === -1) {
      url = url.replace('http', 'https');
    }

    let robots = 'noindex, nofollow';

    return generateMeta({
      'og:url': url,
      "og:image": 'https://waybird.imgix.net/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg', //update when image changes
      robots
    });
  },

  beforeModel(transition) {
    this._super(...arguments);

    // The widget related url params don't actually need to be modelled as query params in controller as they are not mapped
    // Note that affiliate tracking needs to be done first in the route.activate, as once the transition is aborted and the user redirected to
    // page handling widget link, the afiliate ids in the url will be gone

    if (transition.queryParams.wId && transition.queryParams.action) {
      trackEvent('widget:click', {
        wId: transition.queryParams.wId,
        action: transition.queryParams.action,
        a_aid: transition.queryParams.a_aid,
        a_bid: transition.queryParams.a_bid
      })
    }

    if (transition.queryParams.wId && transition.queryParams.action=='DesignATrip') {
      transition.abort();

      if (transition.queryParams.regionName) {
        let regionModel = this.store.findRecord('region', transition.queryParams.regionId);
        return this.transitionTo('region', regionModel);
      }

      if (transition.queryParams.countryName) {
        let countryModel = this.store.findRecord('country', transition.queryParams.countryId);
        return this.transitionTo('country', countryModel);
      }

      if (transition.queryParams.lodgeName) {
        let lodgeModel = this.store.findRecord('lodge', transition.queryParams.lodgeId);
        return this.transitionTo('lodge', lodgeModel);
      }

      return this.transitionTo('index');
    }

    if (transition.queryParams.gclid) {
      trackEvent('adwords:visitor');
    }


    // var hotJarReady = new EmberPromise((resolve) => {
    //   this.get('session.currentUserPromise').then((currentUser) => {
    //     //Don't load Hot jar for timbuktu staff or agents
    //     if (!currentUser || (currentUser && !currentUser.get('isTimbuktu') && !currentUser.get('isAgent'))) {
    //       if (typeof Fastboot === 'undefined' && config.APP.hotJarId && window && window.timbuktu && !window.timbuktu.preRendering) {
    //         (function(h, o, t, j, a, r) {
    //           h.hj = h.hj || function() {
    //             (h.hj.q = h.hj.q || []).push(arguments)
    //           };
    //           h._hjSettings = {
    //             hjid: config.APP.hotJarId,
    //             hjsv: 5
    //           };
    //           a = o.getElementsByTagName('head')[0];
    //           r = o.createElement('script');
    //           r.async = 1;
    //           r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //           a.appendChild(r);
    //         })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
    //       }
    //     }
    //     // We don't actually need for hotjar script to finish loading.. all we is access to window.hj element
    //     resolve(null)
    //   });
    // });
    // this.set('session.hotJarReady', hotJarReady);

    // we just create temporary local models for the wizard here, primaroly for routing
    if (!this.store.peekAll('step').get('length')) {
      let i = 1;
      while (i <= 5) {
        this.store.createRecord('step', {
          id: i
        });
        i++
      }
    }

    // just to trigger init
    this.get('historyService');

    let authEmail = getCookieValue('timbuktu_auth_email');
    let authToken = getCookieValue('timbuktu_auth_token');
    if (authEmail && authToken) {
      var credentials = {
        token: authToken,
        id: 'pdfGen'
      };

      return this.session.authenticate('authenticator:server-side-aware-authenticator', authEmail, credentials);
    }

  },

  setupCriticalData(homes) {
    this.set('configService.allTripTypeExperiences', homes.get('firstObject.tripTypeExperiences'));

    this.ui.setProperties({
      homeImages: shuffleArray(homes.get('firstObject.homeImages')),
      homeImagesCache: [] // clear cache
    });

    var agencyId = 8;
    if (window.location.host.indexOf('nologo.localhost') >= 0 ) {
      agencyId = 83;
    }

    if (typeof document !== 'undefined' && document.querySelector('meta[name=agency-data]')) {
      let storeContent = JSON.parse(decodeURIComponent(document.querySelector('meta[name=agency-data]').content));
      // console.log(storeContent)
      this.store.pushPayload(storeContent);
      agencyId = storeContent['agency']['id'];
    }

    // Demo agency = 8
    // Timbuktu = 7
    let agency = this.whitelabel.isForAgency ? this.store.peekRecord('agency', agencyId) : this.store.peekRecord('agency', 7);
    // Get the latest data including terms and conditions url for that agency
    // if (!agency) {
    //   debugger
    // }
    agency.reload();
    this.setupAgency(agency);

    this.session.currentUserPromise.then((user)=> {
      if (this.whitelabel.isForTimbuktu && user && (user.isManager || user.isTimbuktu)) {
        this.set('ui.hideHubSpot', true)
      }
      if (this.whitelabel.isForTimbuktu && user) {
        reportEmailToHubspot(user.get('email'))
      }
      if (this.whitelabel.isOnboardingAgency || (this.whitelabel.isForAgency && user && user.isManager)) {
        let waybirdHubspotId=config.APP.waybirdHubspotId;
        addAsyncScript(`//js.hs-scripts.com/${waybirdHubspotId}.js`, 'id="hs-script-loader"');
      }
    })

  },

  setupAgency(agency) {
    this.whitelabel.set('agency', agency);
    // Theme the whitelabel solution according to the colour pulled from the agency


    if (agency.primaryColour) {
      let themeColor = '#'+agency.primaryColour;
      let themeRgb = hexToRgb(themeColor);
      let themeHsl = hexToHsl(themeColor);
      let themeColorHSL = `hsl(${themeHsl['h']}, ${themeHsl['s']}%, ${themeHsl['l']}%)`;
      let themeColorHSL10Darker = `hsl(${themeHsl['h']}, ${themeHsl['s']}%, ${themeHsl['l'] - 10}%)`;
      let themeColorHSL15Darker = `hsl(${themeHsl['h']}, ${themeHsl['s']}%, ${themeHsl['l'] - 15}%)`;

      cssVars({
        variables: {
          '--theme': themeColor,
          '--themeRgb': themeRgb,
          '--theme10Darker': themeColorHSL10Darker,
          '--theme15Darker': themeColorHSL15Darker,
        }
      });
    }

    if(this.whitelabel.isForAgency) {

      let navFontColour = '#'+(agency.navFontColour && agency.navFontColour != "null" ? agency.navFontColour : "474747");
      let navBgColour = '#'+(agency.navBgColour && agency.navBgColour != "null" ? agency.navBgColour: "ffffff");

      cssVars({
        variables: {
          '--navFontColour': navFontColour,
          '--navBgColour': navBgColour,
        }
      });
    }
  },

  model(params) {
    if (params.iframeResize == 'true') {
      this.set('ui.iframeEmbed', true)
      // This allows us to embed the ember app in an iframe which auto-resizes according to the size of the page
      addAsyncScript('https://assets.timbuktutravel.com/scripts/iframeResizer.contentWindow.min.js');
    }

    if (window && window.timbuktu && window.timbuktu.isForAgency) {
      this.set('whitelabel.isForAgency', true);
    }

    let xeDate = params.xeDate;

    if (false && typeof document !== 'undefined' && document.querySelector('meta[name=homes-data]') && !xeDate) {
      let storeContent = JSON.parse(decodeURIComponent(document.querySelector('meta[name=homes-data]').content));
      this.store.pushPayload(storeContent);
      let homes = this.store.peekAll('home')
      this.setupCriticalData(homes)
    } else {
      let homeQueryParams = {
        id: 1
      };
      if (xeDate) {
        homeQueryParams.xeDate = xeDate;
        window.timbuktu.xeDate = xeDate;
      }
      return this.store.queryRecord('home', homeQueryParams).then((home) => {
        this.setupCriticalData([home])
      });
    }

  },

  afterModel() {
    // This data won't be available until model hook has completed cause it needs data in homes
    this.set('headData.dataStructures', [
      htmlSafe(JSON.stringify(this.get('settings.organizationSchema')))
    ]);

    setRates(this.store.peekAll('exchangeRate').get('firstObject').get('rates'));

    // trackEvent('app:loaded');
  },

  shouldShowEmailCapture(location) {
    if (this.isFastBoot || typeof localStorage === 'undefined' || this.ui.iframeEmbed || this.configService.preRendering) {
      return false;
    }

    return !this.get('whitelabel.isForAgency') && !getItemFromLocalStorage(EMAIL_CAPTURE_KEY[location]) &&
      !this.get('session.currentUser') &&
      (this.controller && !this.controller.get('email'));
  },

  shouldShowUXFeedbackModal() {

    if (this.isFastBoot || this.ui.iframeEmbed || this.configService.preRendering) {
      return false;
    }

    return !this.get('whitelabel.isForAgency') && !getItemFromLocalStorage(getLoccalStorageKeyModalUXFeedbackShown());
  },

  shouldShowWizardModal() {

    if (this.isFastBoot || this.ui.iframeEmbed || this.configService.preRendering) {
      return false;
    }

    return !this.get('whitelabel.isForAgency')
    && !this.ui.showEnquirePopupModal
    && !getItemFromLocalStorage(getLoccalStorageKeyModalWizardShown())
    ;
  },

  activate() {
    this._super(...arguments);
    this.affiliate.trackAffiliateLanding();

    (function ($) {
      var timer;

      function trackLeave(ev) {

        // if (ev.clientY > 0) {
        //   return;
        // }

        if (timer) {
          clearTimeout(timer);
        }

        if ($.exitIntent.settings.sensitivity <= 0) {
          $.event.trigger('exitintent');
          return;
        }
        $.event.trigger('willExitintent');

        timer = setTimeout(
          function() {
            timer = null;
            $.event.trigger('exitintent');
          }, $.exitIntent.settings.sensitivity);
      }

      function trackEnter() {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
      }

      $.exitIntent = function(enable, options) {
        $.exitIntent.settings = $.extend($.exitIntent.settings, options);

        if (enable == 'enable') {
          $(document).mouseleave(trackLeave);
          $(document).mouseenter(trackEnter);
        } else if (enable == 'disable') {
          trackEnter(); // Turn off any outstanding timer
          $(document).unbind('mouseleave', trackLeave);
          $(document).unbind('mouseenter', trackEnter);
        } else {
          throw "Invalid parameter to jQuery.exitIntent -- should be 'enable'/'disable'";
        }
      }

      $.exitIntent.settings = {
        'sensitivity': 300
      };

    })($);

    let context = this;

    $(function() {
      $.exitIntent('enable');
      // $(document).bind('willExitintent', function() {
      //   console.log('willExitintent')
      //   context.set('ui.showEnquirePopupModal', true);
      // });
      run.later(()=> {
        $(document).bind('exitintent', function() {

          if (context.get('whitelabel.isForTimbuktu')
              && !getCapuredEmail()
              && !context.get('session.currentUser')
              && !window.timbuktu.seenEnquirePopup
              && !window.timbuktu.preRendering
              && !context.get('ui.iframeEmbed')
              && !(context.get('router.currentRouteName').indexOf('ask')>=0)
              && !(context.get('router.currentRouteName').indexOf('enquir')>=0)) {
            window.timbuktu.seenEnquirePopup=true;
            context.set('ui.showEnquirePopupModal', true);
            context.set('ui.showEnquirePopupModalVisible', true);
          }
        });
      }, 50000)



    });

    run.later(() => {
      if (this.get('ui.inWizard')
      || this.get('router.currentRouteName').indexOf('trip.') == 0
      || this.get('router.currentRouteName').indexOf('ask')>=0
      || this.get('router.currentRouteName').indexOf('enquir')>=0) {
        // If we are in a wizard or trip route then don't interrupt with modal
        // console.log('blocking modal')
        return;
      }

      if (this.shouldShowWizardModal()) {
        // console.log('showing wizard modal');
        this.set('ui.showWizardModal', true);
      }
      //  else if (this.shouldShowUXFeedbackModal()) {
      //   // console.log('showing ux feedback modal as the wizard modal has already been seen');
      //   this.set('ui.showUXFeedbackModal', true);
      // }

    }, SIGNUP_MODAL_TIMEOUT);






      // PLEASE DONT DELTE THESE VARIATIONS OF MODAL TO SHOW

      // run.later(() => {
      //   if (this.shouldShowWizardModal()) {
      //     console.log('showing wizard modal');
      //     this.set('ui.showWizardModal', true);
      //   }
      // }, SIGNUP_MODAL_TIMEOUT);
      // run.later(() => {
      //   if (this.shouldShowUXFeedbackModal()) {
      //     console.log('showing ux feedback modal');
      //     this.set('ui.showUXFeedbackModal', true);
      //   }
      // }, SIGNUP_MODAL_TIMEOUT);

      // run.later(() => {
      //   if (this.shouldShowEmailCapture('FULL_SCREEN')) {
      //     console.log('showing full screen email modal');
      //     this.set('ui.showingFullScreenEmailSubscription', true);
      //   }
      // }, SIGNUP_MODAL_TIMEOUT);

  },


  setupController(controller) {
    this._super(...arguments);

    run.next(() => {
      // we start the app with no nav set, this is to default to the main one, should a subroute not set one before
      if (!this.get('ui.showMainNav') && !this.get('ui.showSideNav') && !this.get('ui.showBasicNav') && !this.get('ui.showNoNav') ) {
        this.set('ui.showMainNav', true);
      }
    });


    if (this.isFastBoot) {
      return;
    }

    if ($) {
      $('#builder').remove();
    }

    controller.setProperties({
      emailCaptured: getItemFromLocalStorage(getLoccalStorageEmailCapturedKey()),
      showModalUXFeedback: getItemFromLocalStorage(getLoccalStorageKeyModalUXFeedbackShown()),
      shouldShowWizardModal: getItemFromLocalStorage(getLoccalStorageKeyModalWizardShown()),
      iframeResize: undefined
    });

  },


  likeFromLastAttempt() {
    if (!this.controller) {
      return;
    }

    let lastLikeAttempt = this.get('ui.lastLikeAttempt');
    if (lastLikeAttempt) {
      trackEvent('like:login-challenge:completed');
      this.ui.toggleLike(lastLikeAttempt.entityType, lastLikeAttempt.entity);
    }
    this.set('ui.lastLikeAttempt', null);
  },


  retryLastWizardTripRecommendationsSave() {
    if (!this.controller) {
      return;
    }

    let tripRecommendations = this.get('ui.lastWizardTripRecommendations');
    if (tripRecommendations) {
      trackEvent('save-all-wizard-recommendations:login-challenge:completed');
      this.tripService.saveWizardRecommendations();
    }
  },


  actions: {

    trackEvent() {
      if (arguments[arguments.length-1] instanceof Event) {
        let args = Array.prototype.slice.call(arguments, 0, arguments.length-1)
        trackEvent(...args)
      } else {
        trackEvent(...arguments)
      }
    },
    trackClick(linkName, options) {
      let eventName = 'link:click:'+linkName;
      if (options) {
        trackEvent(eventName, options);
      } else {
        trackEvent(eventName);
      }
    },

    reportClickAndOpenLink() {
      let options = null;
      let linkName = arguments[0];
      let e = arguments[arguments.length-1];
      if (arguments.length>2) {
        options = arguments[1]
      }
      trackEvent('link:click:'+linkName, options);
      e.stopPropagation();
      e.preventDefault();
      run.later(()=> {
        if (options && options.openInNewWindowUrl) {
          window.open(options.openInNewWindowUrl, '_blank');
        } else {
          window.location=e.target.href
        }
      }, 200)
      return false;
    },
    readAllReviewsClicked(eventName, eventProps) {
      trackEvent(eventName, eventProps);
      this.set('feefo.viewedFeefoReview', null)
    },

    stopPropagation(){
      // To use template helper anywhere:
      // {{action (route-action 'stopPropagation') bubbles=false}}
    },

    stopEventPropagation(e) {
      e.stopPropagation();
    },

    noop() {},

    closeMapModal() {
      this.controller.set('mapOnlyView', null);
    },

    showLodge(lodge) {
      trackEvent('popular-lodges:view-lodge');
      this.transitionTo('lodge', lodge);
    },

    toggleLike(entityType, entity) {

      let user = this.get('session.currentUser');
      if (!user) {
        this.set('ui.lastLikeAttempt', {
          entityType,
          entity
        });
        trackEvent('like:login-challenge:displayed', {
          entity: entityType
        });
        return this.set('ui.showRegisterModal', true);
      }

      let entityFavourites = user.get(`${entityType}Favourites`);
      var likedEntity = entityFavourites.findBy(`${entityType}Id`, entity.get('id'));
      var liking = likedEntity;
      if (liking) {
        entityFavourites.removeObject(likedEntity);
        likedEntity.deleteRecord();
      } else {
        likedEntity = this.store.createRecord(`${entityType}-favourite`);
        likedEntity.set('user', user);
        likedEntity.set(entityType, entity);
        entityFavourites.addObject(likedEntity);
      }

      if (!likedEntity.debouncedSave) {
        likedEntity.debouncedSave = debounce(run.bind(likedEntity, 'save'), 1500);
      }
      likedEntity.debouncedSave();

      trackEvent('like:toggle', {
        liked: !liking,
        entity: entityType
      });
    },

    dontShowSignupEver(location) {
      setItemInLocalStorage(EMAIL_CAPTURE_KEY[location], true);
      this.set('ui.showingFullScreenEmailSubscription', false);
    },

    dontShowUXFeedbackModalEver() {
      setItemInLocalStorage(getLoccalStorageKeyModalUXFeedbackShown(), true);
      this.set('ui.showUXFeedbackModal', false);
    },

    dontShowWizardModalEver() {
      this.get('ui').dontShowWizardModalEver();
    },

    showShareModal(shareEntity, shareEntityType) {
      trackEvent(`share-modal:show:${shareEntityType}`);
      this.set('ui.shareEntityType', shareEntityType);
      this.set('ui.shareEntity', shareEntity);
      this.set('ui.copyLinkText', 'Copy link');
      this.set('ui.showShareModal', true);
    },

    showTrip(trip) {
      this.transitionTo('trip', trip);
    },

    signupNewsletterIgnoreExistingSubscription(name, email, location, callToAction) {

      captureEmail(email, location, callToAction, name);

      return this.store.createRecord('subscription', {
          name,
          email,
          location,
          callToAction
        }).save()
        .then(() => {
          this.controller.set('isSignupSuccess', true);
          trackEvent('mailing-list:subscribe', {
            location
          });
          window.timbuktu.reportConversions.reportNewsletterSubscription();
          this.flashMessages
            .success('Done! Keep an eye on your inbox.', {
              timeout: 3000,
              sticky: false,
              showProgress: true
            });
          this.set('ui.showingFullScreenEmailSubscription', false);

        }, (problem) => {
          if (isPresent(problem) && isPresent(problem.errors) && isPresent(problem.errors[0])) {
            var message = problem.errors[0].detail;
            if (message && message.indexOf('is already subscribed to the list') < 0 && message.indexOf('has already been taken') < 0) {
              this.flashMessages
                .danger('Oh dear! We had a problem subscribing you to the mailing list. ' + message, {
                  timeout: 4000,
                  sticky: false,
                  showProgress: true
                });
              return RSVP.Promise.reject();
            }
          }
        });
    },

    print() {
      window.print();
    },

    goBack() {
      window.history.back();
    },

    goToSearchResult(occurrence) {
      if (occurrence && occurrence.result && occurrence.type) {
        this.controller.set('isPrimarySearchOpen', false);

        let route = occurrence.type;
        // if (occurrence.type === 'country' || occurrence.type === 'region' || (occurrence.type === 'experience' && occurrence.result.get('isCategory'))) {
        //   route += '.routes';
        // }
        this.transitionTo(route, occurrence.result);
      }
    },

    makeEnquiry(options /* model info */ ){
      let url = this.router.currentURL;
      var params = {
        url
      };

      if (options && options.info) {
        params.info = options.info;
      }
      this.configService.set('enquiryData', params);

      trackEvent('enquire:click', {url, location: options.location})

      if (options && options.location == 'enquire:popup:modal') {
        this.set('ui.showEnquirePopupModal', false);
        this.set('ui.showEnquirePopupModalVisible', false);
      }

      if(options && options.model && options.model._internalModel.modelName.indexOf('trip') >= 0){
        this.transitionTo('trip.index.enquire', options.model);
      } else {
        let queryParams = {};
        if (options && options.model) {
          queryParams.destination=options.model.name;
        } else if (options && options.destination) {
          queryParams.destination=options.destination;
        }
        this.transitionTo('ask', {
          queryParams
        });

        this.historyService.enterSuspension({
          name: 'ask'
        });
      }
    }
  },

  setupThirdPartyScripts() {
    // Note that ui.iframeEmbed is only set in the model hook which hasnt been processed yet so check in href
    if (typeof Fastboot === 'undefined' && window && window.timbuktu && !window.timbuktu.preRendering && !window.timbuktu.pdfGen && !window.timbuktu.isForAgency && !window.timbuktu.blockAnalyticsAndAffiliates && !this.ui.iframeEmbed && window.location.href.indexOf('iframeResize') < 0) {
      this.setupAdwordsRetargeting()
      this.setupGTag()
      this.setupFacebookPixel()
      this.setupMixpanel();
      this.setupTimbuktuHubspot()
      this.setupBing()
    }
    // We call this even for waybird as all of the functions check if it is safe to report conversions.
    //  Otherwise you end up null pointer exceptions when the functiosn are called or you have to pollute the codebase everywhere with checks to report conversions
    setupConversionReportingWrapper()

  },

  setupAdwordsRetargeting() {

    var adwords_timbuktu_us_google_conversion_id = config.APP.timbuktuUSConversionId;
    var adwords_timbuktu_uk_row_google_conversion_id = config.APP.timbuktuUKROWConversionId;
    // We don't report any conversions to the manager account but just we search for the id
    var timbuktu_group_google_conversion_id = 10972999326;

    var google_custom_params = window.google_tag_params;
    var google_remarketing_only = true;
    /* ]]> */
    // Load in page ad conversions script asnc and then create a conversion for the general audience
    function trackVisitorForGeneralAudieceRemarketing() {
      if (typeof window.google_trackConversion === 'undefined') {
        return console.log('window.google_trackConversion blocked');
      }

      window.google_trackConversion({
        google_conversion_id: adwords_timbuktu_us_google_conversion_id,
        google_custom_params: window.google_tag_params,
        google_remarketing_only: true
      });
      window.google_trackConversion({
        google_conversion_id: adwords_timbuktu_uk_row_google_conversion_id,
        google_custom_params: window.google_tag_params,
        google_remarketing_only: true
      });
    }

    addAsyncScript('//www.googleadservices.com/pagead/conversion_async.js', undefined, trackVisitorForGeneralAudieceRemarketing);
  },

  setupGTag() {

    var aw_us_original_timbuktu_google_conversion_id = config.APP.adwordsUSConversionId;
    var aw_uk_rest_of_world_timbuktu_google_conversion_id = config.APP.adwordsUKROWConversionId;
    var ga4_id = config.APP.googleAnalytics4Id;

    addAsyncScript('https://www.googletagmanager.com/gtag/js?id='+ga4_id);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    window.gtag=gtag;

    gtag('config', ga4_id);
    gtag('config', aw_uk_rest_of_world_timbuktu_google_conversion_id, {
      'conversion_linker': true,
      'allow_enhanced_conversions':true
    });
    gtag('config', aw_us_original_timbuktu_google_conversion_id, {
      'conversion_linker': true,
      'allow_enhanced_conversions':true
    });


  },


  setupFacebookPixel() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
      n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
      document,'script','https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', config.APP.facebookPixelId);
      fbq('track', 'PageView');
      window.fbq=fbq;
    }
  },

  setupMixpanel() {
    (function(e,b){if(!b.__SV){var a,f,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
    for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=e.createElement("script");a.type="text/javascript";a.async=!0;a.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f=e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a,f)}})(document,window.mixpanel||[]);
    mixpanel.init(config.APP.mixpanelId);

  },

  setupTimbuktuHubspot() {
    if (window.location.href.indexOf('iframeResize=true') <0) {
      addAsyncScript(`//js.hs-scripts.com/${config.APP.hubspotId}.js', 'id="hs-script-loader"`);
    }
  },

  setupBing() {
    (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){
      var o={ti:"26055586"};
      o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;
      s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
      window.uetq = window.uetq || [];
  }

});
