import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNames: ['x-dmy'],

  date: null,

  selectedDay: null,
  selectedMonth: null,
  selectedYear: null,

  yearRange: 100,

  possibleMonths: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],

  @computed('yearRange')
  possibleYears(yearRange) {
    let currentYear = moment().year(),
      startYear = currentYear - yearRange,
      years = [];

    if (startYear < currentYear) {
      for (var i = startYear; i <= currentYear; i++) {
        years.pushObject(i);
      }
    } else {
      for (var x = startYear; x >= currentYear; x--) {
        years.pushObject(x);
      }
    }
    return years;
  },

  @computed('selectedMonth')
  possibleDays(selectedMonth) {
    let currentMonth = selectedMonth || moment().month(),
      daysInMonth = moment().month(currentMonth).daysInMonth(),
      days = [];

    for (var i = 1; i <= daysInMonth; i++) {
      days.pushObject(i);
    }
    return days;
  },

  init() {
    this._super(...arguments);
    if (this.get('date')) {
      this.setProperties({
        selectedDay: moment(this.get('date')).date(),
        selectedMonth: this.get('possibleMonths')[moment(this.get('date')).month()],
        selectedYear: moment(this.get('date')).year()
      });
    }
    let allPossibleDays = [];

    for (var i=1; i < 32; i++) {
      allPossibleDays.pushObject(i);
    }
    this.set('allPossibleDays', allPossibleDays);
  },

  _updateDate() {
    if (this.get('selectedYear') && this.get('selectedMonth') && this.get('selectedDay')) {
      // parse and run month through moment
      var month = moment().month(this.get('selectedMonth')).month();

      this.set('date', new Date(this.get('selectedYear'), month, this.get('selectedDay')));
    }
  },

  actions: {
    updateDay(day) {
      if (day) {
        this.set('selectedDay', day);
        this._updateDate();
      }
    },

    updateMonth(month) {
      if (month) {
        this.set('selectedMonth', month);
        this._updateDate();
      }
    },

    updateYear(year) {
      if (year) {
        this.set('selectedYear', year);
        this._updateDate();
      }
    }
  }

});
