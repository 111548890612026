import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['booking-steps'],

  session: service(),
  settings: service(),
  whitelabel: service(),

  trip: null,

  processPath: 'flights_last', // default,
  @alias('trip.itinerary.processPathSteps') processPathSteps: null

});