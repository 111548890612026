import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  entityType
} from 'b5b/utils';

export default Component.extend({
  scriptLoaded: false,
  tripService: service('trip'),
  ui: service(),
  classNames: ['gps-finder'],

  didInsertElement() {

    this.ui.getGoogleMapsReadyPromise().then(()=> {
      this.initAutocomplete()
    })
  },

  updateEntity(marker, place) {
    // console.log(marker, place);
    this.set('entity.latitude', marker.position.lat())
    this.set('entity.longitude', marker.position.lng())
    if (marker.title) {
      this.set('entity.name', marker.title)
    }
    if (marker.timbuktuMeta && marker.timbuktuMeta.countryCode) {
      let country = this.countries.findBy('countryCode', marker.timbuktuMeta.countryCode)
      if (country != this.get('entity.country')) {
        this.selectCountry(country);
      }
    }
    if (this.afterGpsFinderUpdateEntity) {
      this.afterGpsFinderUpdateEntity()
    }
  },

  initAutocomplete() {
    this.set('scriptLoaded', true);
    let context = this;

    var geocoder = new google.maps.Geocoder;
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 26.2897427, lng: 12.8269669 },
      zoom: 2,
      mapTypeId: 'roadmap',
      zoomControl: true,
      disableDefaultUI: true
    });

    let creatingNewLodge = this.entity.isNew;
    let boundUpdateEntity = run.bind(this, 'updateEntity')

    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');    
    var searchBox = new google.maps.places.SearchBox(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
      $(input).addClass('ready')
      if (context.startSearch) {
        input.value=context.startSearch
        input.focus();
        run.next(()=> {
          input.focus();
        })        
      }      
    });

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });

    map.addListener('click', function (event) {
      let marker = placeMarker(event.latLng);
      setupMarker(marker);
    });

    var markers = [];

    function placeMarker(location) {
      var marker = new google.maps.Marker({
        position: location,
        map: map
      });
      return marker;
    }

    function reverseGeocodeMarker(geocoder, marker) {
      var latlng = {lat: marker.position.lat(), lng: marker.position.lng()};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK' && results.length > 0) {
          marker.timbuktuMeta = {};
          let countryResult = results[results.length-1];
          if (countryResult.types && countryResult.types.includes('country') &&  countryResult.address_components &&  countryResult.address_components.length > 0) {
            let countryName = countryResult.address_components[0].long_name
            let countryCode = countryResult.address_components[0].short_name
            marker.timbuktuMeta.countryCode = countryCode;
          }
          let titleResult = results[0];
          if ( titleResult.address_components &&  titleResult.address_components.length > 0) {
            let title = titleResult.address_components[0].long_name;
            marker.timbuktuMeta.title = title;
          }
        }
      });
    }

    function setupMarker(marker, place) {

      let buttonTitle = creatingNewLodge ? 'Create ' : 'Update ';
      buttonTitle = buttonTitle + entityType(context.entity);
      var contentString =
      '<div class="google-map-popup">' +
        '<button class="create-lodge btn">'+buttonTitle+'</button>' +
      '</div>';

      reverseGeocodeMarker(geocoder, marker)

      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      function openInfoWindow() {
        infowindow.open(map, marker);
        infowindow.addListener('domready', function () {
          $('.create-lodge').click(()=>{
            boundUpdateEntity(marker, place)
          })
        });
      }

      marker.addListener('click', function () {
        openInfoWindow();
      });
      // Create a marker for each place and open the winfo window for easy clicking immediately
      markers.push(marker);
      openInfoWindow();

    }

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();

      if (!places) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        var marker = new google.maps.Marker({
          map: map,
          // icon: icon,
          title: place.name,
          position: place.geometry.location
        })
        setupMarker(marker);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }
});
