import Component from '@ember/component';

export default Component.extend({
  tagName: 'li',
  classNameBindings: [':toggle-pill', 'selected'],

  click() {
    this.sendAction('togglePill', this.tab, this.index)
  }
});
