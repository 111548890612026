import {
  computed
} from 'ember-decorators/object';

import {
  or,
  equal
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import Self from 'b5b/models/block-item-experience';


export default DS.Model.extend({
  sequence: DS.attr(),
  originalBlockItemId: DS.attr(),

  blockItem: DS.belongsTo('blockItem', {
    async: false
  }),
  experience: DS.belongsTo('experience', {
    async: false,
    inverse: null
  }),

  @or('heading', 'experience.name') displayName: false,

  makeCopy() {

    let copy = this.get('store').createRecord('block-item-experience');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    copy.set('experience', this.get('experience'))
    return copy;
  }


});
