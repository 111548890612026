import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  getProportionalPriceHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';

export default DS.Model.extend({

  amount: DS.attr(),
  state: DS.attr(),
  note: DS.attr(),
  paymentType: DS.attr(),
  currency: DS.attr(),
  percentageOfBalance: DS.attr(),
  externalPaymentLink: DS.attr(),
  multiCurrency: DS.belongsTo('multi-currency', {
    async: false,
    inverse: null
  }),
  itinerary: DS.belongsTo('itinerary', {
    async: false
  }),
  charge: DS.belongsTo('charge'),

  @alias('receiptsHash') priceHash: false,

  @computed('paymentType')
  isRealAmountPayment(paymentType) {
    return paymentType === 'real_amount';
  },

  @computed('paymentType')
  isQuoteCurrenciesPayment(paymentType) {
    return paymentType === 'quote_currencies';
  },

  @computed('itinerary.quote.balanceDuePriceHash', 'isRealAmountPayment', 'amount', 'currency', 'multiCurrency.priceHash')
  receiptsHash(balanceDuePriceHash, isRealAmountPayment, amount, currency, multiCurrencyPriceHash) {
    if (isRealAmountPayment) {
      // Deal with case where payment is only for a single real world currency rather a quote currencies
      return getProportionalPriceHash(amount, currency, balanceDuePriceHash)
    } else {
      return multiCurrencyPriceHash;
    }
  }
});
