import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({

  pathForType: function() {
    return 'trips';
  },

  buildURL: function( /*record, suffix*/ ) {
    if (this.isFastBoot) {
      return this._super(...arguments);
    }
    var s = this._super(...arguments);

    return s = s.indexOf('?') > 0 ? s + '&summaries=true' : s + '?summaries=true'
  }

});
