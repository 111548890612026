import {
  isEmpty
} from '@ember/utils';
import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';

export default Controller.extend({
  entityModals: service(),
  settings: service(),

  showMapModal: null,

  @computed('countries.[]')
  mapMarkers(countries) {
    if (isEmpty(countries)) {
      return;
    }

    let markers = [];
    countries.forEach((country) => {
      markers.pushObject([parseFloat(country.get('longitude')).toFixed(6), parseFloat(country.get('latitude')).toFixed(6)]);
    });

    return markers;
  },
  actions: {
    openMapModal() {
      this.set('showMapModal', true);
    }
  }
});