import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  htmlSafe
} from '@ember/string';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  generateMeta,
  trackEvent,
  metadataDate
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  ui: service(),
  settings: service(),
  session: service(),
  headTagsService: service('head-tags'),
  headData: service(),
  whitelabel: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags() {

    let model = this.currentModel;
    var name = model.get('name');
    const title = `${name} | ${this.whitelabel.agencySeoName}`;

    var description = model.get('asyncModels.experienceInfo.overview');
    model.set('asyncModels', null);

    const imageUrl = model.get('kodakOriginalUrl');

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';

    let meta = {
      description,
      'og:title': title,
      'og:image': imageUrl,
      'link': `experience/${model.get('friendlyId')}`,
      robots
    };

    return generateMeta(meta);
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('experience', model);
    trackEvent('experience:view');

    let entityNavData = {};

    if (model.get('isRegional')) {
      // remove experiences for regional experiences
      entityNavData = {
        entity: model,
        title: model.get('displayName'),
        titleLink: {
          routeName: 'experience.routes',
          routeModel: model
        },
        links: [{
          name: 'Overview',
          params: {
            routeName: 'experience.index',
            routeModel: model
          }
        }]
      };
      if (this.whitelabel.showRoutes) {
        entityNavData.links.pushObject({
          name: 'Trips',
          params: {
            routeName: 'experience.routes',
            routeModel: model
          }
        })
      }
    } else {

      entityNavData = {
        entity: model,
        title: model.get('displayName'),
        titleLink: {
          routeName: 'experience',
          routeModel: model
        },
        links: [{
          name: 'About',
          params: {
            routeName: 'experience.index',
            routeModel: model
          }
        }]
      };
      if (this.whitelabel.showRoutes) {
        entityNavData.links.pushObject({
          name: 'Trips',
          params: {
            routeName: 'experience.routes',
            routeModel: model
          }
        })
      }
      entityNavData.links.pushObject({
        name: 'Experiences',
        params: {
          routeName: 'experience.experiences',
          routeModel: model
        }
      })
      if (this.whitelabel.isForTimbuktu) {
        entityNavData.links.pushObject({
          name: 'Articles',
          params: {
            routeName: 'experience.articles',
            routeModel: model
          }
        })
      }
    }

    this.get('session.currentUserPromise').then((user) => {
      if (user && (user.get('isTimbuktu'))) {
        entityNavData.links.pushObject({
          name: 'Tools',
          params: {
            action: 'ui.viewedEntityToolsModal',
            actionProperty: model
          }
        });
      }

      this.get('ui').setProperties({
        entityNavData: entityNavData
      });
    })
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (!isExiting) {
      // causing issues on mobile when transitioning from mapView to experience - disabling for now
      // this.get('scroll').to(0, this);
    } else {
      this.set('ui.entityNavData', null);
    }
  },

  afterModel(model) {

    let asyncModels = RSVP.hash({
      experienceInfo: model.get('experienceInfo')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.headTagsService.collectHeadTags();
    });


    var webPage,
      breadcrumbList,
      organization;

    model.get('experienceInfo').then(() => {

      webPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "about": model.get('seoName'),
        "contentLocation": (model.get('region') ? (model.get('region.name') + ', ' + model.get('region.country.name')) : undefined),
        "headline": model.get('seoName'),
        "image": {
          "@type": "ImageObject",
          "url": model.get('kodakOriginalUrl') + "?w=1400&h=960",
          "height": 960,
          "width": 1400
        },
        "datePublished": metadataDate(model.get('createdAt')),
        "dateModified": metadataDate(model.get('updatedAt')),
        "publisher": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "author": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "description": model.get('experienceInfo.overview'),
        "mainEntity": {
          "@type": "TouristAttraction",
          "name": model.get('name'),
          "address": (model.get('region') ? (model.get('region.name') + ', ' + model.get('region.country.name')) : undefined),
          "geo": {
            "@type": "GeoCoordinates",
            "address": (model.get('region') ? (model.get('region.name') + ', ' + model.get('region.country.name')) : undefined),
            "addressCountry": (model.get('region') ? model.get('region.country.name') : undefined),
            "latitude": (model.get('region') ? model.get('region.latitude') : undefined),
            "longitude": (model.get('region') ? model.get('region.longitude') : undefined)
          }
        }
      };

      breadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "http://www.timbuktutravel.com/experiences",
            "name": "Experiences",
            "image": "https://assets.timbuktutravel.com/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.timbuktutravel.com/experience/" + model.get('friendlyId'),
            "name": model.get('name'),
            "image": model.get('kodakOriginalUrl') + "?w=1200&h=630"
          }
        }]
      };

      organization = this.get('settings.organizationSchema');

    }).finally(() => {
      this.set('headData.dataStructures', [
        htmlSafe(JSON.stringify(webPage)),
        htmlSafe(JSON.stringify(breadcrumbList)),
        htmlSafe(JSON.stringify(organization))
      ]);
    });

  }

});
