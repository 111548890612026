import ArrayPathLayer from 'ember-leaflet/components/array-path-layer';

export default ArrayPathLayer.extend({
  leafletOptions: [
    'smoothFactor', 'noClip'
  ],

  createLayer() {
    var polyline = this.L.polyline(...this.requiredOptions, this.options);
    // Anything less than Edge whihc is v12 in script should not do polyline arrows
    if (typeof window !== 'undefined' && (window.timbuktu.usingIe == false || window.timbuktu.ieVersion > 11)) {
      polyline.setText(' \u25BA', {
        repeat: false,
        center: true,
        offset: 6,
        attributes: {
          'font-size': '18',
          'fill': '#3c74b4',
          'fill-opacity': '1.0'
        }
      });
    }
    return polyline;
  }
});