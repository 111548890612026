import DS from 'ember-data';
import {
  mapBy
} from '@ember/object/computed';
import {
  computed
} from 'ember-decorators/object';
import {
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';

import {
  memberAction
} from 'ember-api-actions';

export default DS.Model.extend({

  pdfInvoiceUrl: DS.attr(),
  termsAcceptedUrl: DS.attr(),
  affiliateId: DS.attr(),
  affiliateVisitorId: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  state: DS.attr(),
  stripeEmail: DS.attr(),
  stripeToken: DS.attr(),
  transactionInfo: DS.attr(),
  amount: DS.attr('number'),
  currency: DS.attr(),
  surchargeAmount: DS.attr('number'),
  preferredCurrencyAmount: DS.attr('number'),
  preferredCurrency: DS.attr(),
  preferredCurrencySurchargeAmount: DS.attr('number'),
  trip: DS.belongsTo('trip'),
  itinerary: DS.belongsTo('itinerary', {
    inverse: 'charges'
  }),
  user: DS.belongsTo('user'),
  payment: DS.belongsTo('payment'),
  affiliatePayment: DS.belongsTo('affiliatePayment'),
  paymentType: DS.attr(),
  chargeType: DS.attr(),
  giftFrom: DS.attr(),
  giftMessage: DS.attr(),
  hideGiftAmount: DS.attr(),
  receipts: DS.hasMany('receipt'),  
  receiptCurrencies: mapBy('receipts', 'currency'),

  @computed('receiptCurrencies.[]', 'currency')
  containReceiptsInOtherCurrencies(receiptCurrencies, currency) {
    return receiptCurrencies.length > 1 || receiptCurrencies.length == 1 && !receiptCurrencies.includes(currency);
  },


  @computed('amount', 'surchargeAmount')
  amountWithoutSurcharge(amount, surchargeAmount) {
    return amount - surchargeAmount;
  },

  @computed('receipts.@each.amount', 'receipts.@each.currency')
  receiptsHash(receipts) {

    let hash = {};
    receipts.forEach((receipt) => {
      hash[receipt.currency] = receipt.amount;
    })
    return hash;
  },

  updateFlywireState: memberAction({
    path: 'update_flywire_state'
  }),

  createStripePaymentIntent: memberAction({
    path: 'create_stripe_payment_intent'
  }),


  getPpsSignature: memberAction({
    path: 'get_pps_signature'
  }),

  

});
