import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return this.modelFor('users');
  },

  actions: {
    save() {
      let emailChanged = this.currentModel.changedAttributes().email && true;
      return this.currentModel.save()
        .then(() => {
          if (emailChanged) {
            location.reload();
          }
          this.flashMessages.success('Changes Saved');
        })
        .catch(() => {
          this.currentModel.rollbackAttributes();
          this.flashMessages.danger('Could not save');
        });
    }
  }

});