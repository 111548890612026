import {
  computed
} from 'ember-decorators/object';
import {
  or,
  equal
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';


import DS from 'ember-data';
import Self from 'b5b/models/block';


export default DS.Model.extend({

  tripService: service('trip'),

  heading: DS.attr(),
  overview: DS.attr(),
  sequence: DS.attr(),
  daysAfterStart: DS.attr(),

  dayTitle: DS.attr(),
  dayImageTitle: DS.attr(),
  overrideDayImageUrl: DS.attr(),
  overrideDayImageCoverStyle: DS.attr(),
  overrideDayImageFocusX: DS.attr(),
  overrideDayImageFocusY: DS.attr(),

  showTripMap: DS.attr(),

  schedule: DS.belongsTo('schedule', {
    async: false
  }),

  blockItems: DS.hasMany('blockItem', {
    async: false
  }),

  @computed('overrideDayImageUrl', 'coverExperience.kodakOriginalUrl', 'schedule.stageForCustomSchedule.destinationKodakImageUrl', 'tripService.currentTrip.uniqueCountries.firstObject.kodakOriginalUrl')
  dayImageUrl(overrideDayImageUrl, coverExperienceKodakOriginalUrl, destinationKodakImageUrl, firstCountryKodakUrl) {

    if (overrideDayImageUrl) {
      return overrideDayImageUrl
    } else if (coverExperienceKodakOriginalUrl) {
      return coverExperienceKodakOriginalUrl
    } else if (destinationKodakImageUrl) {
      return destinationKodakImageUrl;
    } else {
      return firstCountryKodakUrl;
    }
  },

  @computed('overrideDayImageCoverStyle', 'coverExperience.coverStyle', 'schedule.stageForCustomSchedule.destinationKodakImageCoverStyle', 'tripService.currentTrip.uniqueCountries.firstObject.coverStyle')
  dayImageCoverStyle(overrideDayImageCoverStyle, coverExperienceCoverStyle, destinationKodakImageCoverStyle, firstCountryCoverStyle) {

    if (overrideDayImageCoverStyle) {
      return overrideDayImageCoverStyle
    } else if (coverExperienceCoverStyle) {
      return coverExperienceCoverStyle
    } else if (destinationKodakImageCoverStyle) {
      return destinationKodakImageCoverStyle
    } else {
      return firstCountryCoverStyle;
    }
  },

  @computed('blockItems.@each.allExperiences.[]')
  allExperiences(blockItems) {
    return blockItems.reduce(function(result, blockItem) {

      return result.concat(blockItem.get('allExperiences'));
    }, []);
  },

  @computed('allExperiences.@each.experience')
  coverExperience (allExperiences) {
    if (allExperiences && allExperiences.length > 0) {
      return allExperiences.get("firstObject");
    }
  },

  @computed('blockItems.[]')
  allBlockItems(blockItems) {
    return blockItems.reduce(function(result, blockItem) {
      result.push(blockItem);
      return result;
    }, []);
  },

  @computed('allBlockItems.@each.blockItemType')
  tripSummaryBlockItem(allBlockItems) {
    return allBlockItems.findBy('blockItemType', 'trip-summary')
  },

  @computed('blockItems.@each.overview')
  previewOverview(blockItems) {
    let previewOverview = null;
    blockItems.forEach((blockItem)=> {
      if (blockItem.overview && !previewOverview) {
        previewOverview = blockItem.overview;
      }
    })
    return previewOverview;
  },

  @computed('blockItems.@each.heading')
  previewHeading(blockItems) {
    let previewHeading = null;
    blockItems.forEach((blockItem)=> {
      if (blockItem.heading && !previewHeading) {
        previewHeading = blockItem.heading;
      }
    })
    return previewHeading;
  },

  @computed('daysAfterStart')
  isDay(daysAfterStart) {
    return daysAfterStart !=null && !isNaN(daysAfterStart);
  },

  // @computed('blockItems.@each.experience')
  // allExperiences(blockItems) {
  //   return blockItems.reduce(function(blockItemResult, blockItem) {

  //     let experiences = blockItem.get('blockItemExperiences').reduce(function(blockItemExperienceResult, blockItemExperience) {
  //       if (blockItemExperience.experience) {
  //         blockItemExperienceResult.push(blockItemExperience.experience);
  //       }
  //       return blockItemExperienceResult;
  //     }, []);

  //     return blockItemResult.concat(experiences);
  //   }, []);
  // },

  makeCopy() {

    let copy = this.get('store').createRecord('block');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    this.get('blockItems').forEach((blockItem)=> {
      copy.get('blockItems').pushObject(blockItem.makeCopy())
    })
    return copy;
  }


});
