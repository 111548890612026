import { helper } from '@ember/component/helper';

export function isSubRoute([options, currentRoute]) {
  const routes = options.map(function(option) {
    return option.route;
  });
  return routes.includes(currentRoute);
}

export default helper(isSubRoute);
