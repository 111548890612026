import Route from '@ember/routing/route';
import EntitiesRegionsRouteMixin from 'b5b/mixins/entities/regions/route';
import {
  inject as service
} from '@ember/service';

export default Route.extend(EntitiesRegionsRouteMixin, {

  cache: service(),

  afterModel() {
    return this.cache.getAllPublishedCountriesPromise();
  },


});
