import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  setupController() {
    // trackEvent('trip:brochure');
    if (this.get('tripService.currentTrip') && window.timbuktu.pdfGen) {
      this.get('tripService').removeDayByDayContent(this.get('tripService.currentTrip'))
    }
  }

});
