import { helper } from '@ember/component/helper';
import {
  TRIP_IDEAS_BUDGET_MAPPING
} from 'b5b/utils';

export function tripIdeaBudgetMapping([key]) {
  if (!key){
    return;
  }

  return TRIP_IDEAS_BUDGET_MAPPING[key]
}

export default helper(tripIdeaBudgetMapping);
