import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  classNames: ['stage stage-counter'],

  ui: service(),
  tripService: service('trip'),
  entityModals: service(),

  mapHover: false,

  mouseEnter() {
    if (this.mapHover) {
      this.set('stage.hover', true);
    }
  },

  mouseLeave() {
    if (this.mapHover) {
      this.set('stage.hover', false);
    }
  }
});