import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import PrettyQuery from 'b5b/mixins/pretty-query';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(PrettyQuery, AuthenticatedRouteMixin, {
  ui: service(),
  session: service(),

  beforeModel(transition) {
    this._super(...arguments);
    return this.get('session.currentUserPromise').then(() => {
      if (!(this.get('session.currentUser.isManager'))) {
        transition.abort();
        window.history.back();
      }
    })
  },

  queryParams: {
    // bookingState: {
    //   replace: true
    // },
    // searchValue: {
    //   replace: true
    // },
    // page: {
    //   replace: true
    // },
    // sort: {}
  },

  model() {
    return this.get('session.currentUserPromise');
  },

  setupController(controller) {
    this._super(...arguments);

    controller.setProperties({
      firstTimeLoad: false,
      userOnly: true,
      buster: true
    });
    controller.get('loadDealsTask').perform();

  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      controller.setProperties({
        // searchValue: '',
        // page: 1,
        // firstTimeLoad: true,
        // userOnly: false,
        // buster: false
      });
    }
  }

});
