import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  not,
  or
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNameBindings: [':trips-section', 'showButtons::no-buttons'],
  showButtons: true,

  store: service(),

  whitelabel: service(),
  @not('whitelabel.isForAgency') notForAgency: true,

  @or('notForAgency', 'recommendedTrips.length') isVisible: true,

  isQuerying: false,

  didInsertElement() {
    this._super(...arguments);

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    // we can probably do with a better solution here

    let destination = this.get('destination');
    let trip = this.get('trip');
    let categoryExperience = this.get('categoryExperience');
    let experienceName = this.get('experienceName');

    let query = {
      per_page: 7
    };

    if (this.get('isPopular')) {
      query['isPopular'] = true;
    }

    if (trip) {
      destination = trip.get('countries.firstObject');
    }

    if (destination) {
      let actualDestinationType = destination.get('constructor.modelName') || this.get('destinationType');
      if (actualDestinationType === 'country') {
        query['selectedCountryNames'] = [destination.get('name')];
      }

      if (actualDestinationType === 'region') {
        query['selectedRegionNames'] = [destination.get('name')];
      }

      if (this.get('excludedTrip')) {
        query['excludedTrip'] = this.get('excludedTrip.id');
      }
    }
    if (categoryExperience) {
      query['selectedExperienceNames'] = [categoryExperience.get('name')]
    }

    if (experienceName) {
      query['selectedExperienceNames'] = [experienceName]
    }


    this.set('isQuerying', true);

    this.get('store').query('tripSummary', query).then((trips) => {
      if (this.get('excludedTrip')) {
        trips = trips.rejectBy('id', this.get('excludedTrip.id'));
      }

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('isQuerying', false);
      this.set('recommendedTrips', trips);
    });

  },

  @computed('destination', 'destinationType')
  selectedCountryName(destination, destinationType) {
    if (destination) {
      let actualDestinationType = destination.get('constructor.modelName') || destinationType
      if (actualDestinationType === 'region') {
        return this.get('destination.country.name')
      } else if (actualDestinationType === 'country') {
        return this.get('destination.name')
      }
    }
  },

  @computed('destination', 'destinationType')
  selectedRegionName(destination, destinationType) {
    if (destination) {
      let actualDestinationType = destination.get('constructor.modelName') || destinationType
      if (actualDestinationType === 'region') {
        return this.get('destination.name')
      } else {
        return 'All Regions';
      }
    }
  },

  @computed('selectedCountryName', 'selectedRegionName', 'categoryExperience', 'seeMoreTitle', 'isPopular', 'experienceName')
  seeAllLinkHash(selectedCountryName, selectedRegionName, categoryExperience, seeMoreTitle, isPopular, experienceName) {
    var query = {};
    var hash = {
      route: 'trips',
      query
    };

    if (selectedCountryName) {
      query['selectedCountryNames'] = [selectedCountryName];
    }
    if (selectedRegionName) {
      query['selectedRegionNames'] = [selectedRegionName];
    }
    if (categoryExperience) {
      query['selectedExperienceNames'] = [categoryExperience.get('name')];
    }
    if (experienceName) {
      // this will overwrite category experience if it exists - need to setup logic once applicable
      query['selectedExperienceNames'] = [experienceName];
    }

    if (isPopular) {
      query['isPopular'] = true;
    }

    if (seeMoreTitle) {
      hash['title'] = seeMoreTitle;
    }

    return hash;
  }

});
