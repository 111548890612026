import {
  alias,
  or
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';
import {
  friendlyIdToSeoName
} from 'b5b/utils';

export default DS.Model.extend(HasKodakImage, {
  friendlyId: DS.attr(),
  name: DS.attr('string'),
  longitude: DS.attr('string'),
  latitude: DS.attr('string')

});
