import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNames: ['x-paginate'],
  attributeBindings: ['style'],

  pages: 0,
  max: 10,
  sep: '...',
  current: null,

  @computed('pages', 'max', 'current')
  pagesArray(pages, max, current) {
    if (!pages) {
      return;
    }

    var arr = [],
      i;

    if (max) {

      var buffer = Math.round((this.get('max') - 2) / 2);

      if (current === pages || current === 1) {
        buffer++;
      }

      var activeRange = [Math.max(current - buffer, 0), Math.min(current + buffer, pages)];

      for (i = 1; i < pages + 1; i++) {
        if (i > activeRange[0] && i < activeRange[1] || i === pages || i === 1) {
          arr.push(i);
        }
      }

      if (activeRange[0] >= 2) {
        arr.insertAt(1, this.get('sep'));
      }

      if (activeRange[1] < pages - 1) {
        arr.insertAt(arr.length - 1, this.get('sep'));
      }

    } else {
      for (i = 1; i < pages + 1; i++) {
        arr.push(i);
      }
    }

    return arr;
  }

});