import xSuggestionsComponent from 'b5b/components/x-suggestions/component';
import {
  alias
} from 'ember-decorators/object/computed';


export default xSuggestionsComponent.extend({
  classNames: ['regions'],
  suggestionTypes: ['regions'],

  @alias('loadEntitiesTask') loadRegionsTask: null,

  init() {
    this._super(...arguments);
    this.get('messageBus').subscribe('listing-map-position-changed', this, this.loadSuggestions);
  }

});
