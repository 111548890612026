import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';
import Component from '@ember/component';
import tripCarouselImages from '../../mixins/trip-carousel-images';
import RSVP from 'rsvp';
import {
  getTripImageFromRegionalImage,
  getTripImageFromEntityMetaImage,
  getTripImageFromExperienceImage,
  getTripImageFromExperience,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  tagName: '',
  store: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),

  @alias('xplorer.isLoading') isLoading: null,

  choosingImage: false,
  coverStyles: ['left top', 'left center', 'left bottom' ,'right top', 'right center', 'right bottom', 'center top', 'center center', 'center bottom'],

  didInsertElement() {
    this._super(...arguments);
    console.log(arguments)
  },

  didReceiveAttrs(){
    this._super(...arguments);
    console.log(arguments)
    this.set('urlPropertyName', 'tripService.overrideStageImageOptions.target.'+this.tripService.overrideStageImageOptions.imageType+'Url')
    this.set('coverStylePropertyName', 'tripService.overrideStageImageOptions.target.'+this.tripService.overrideStageImageOptions.imageType+'CoverStyle')
    this.set('overrideUrlPropertyName', 'tripService.overrideStageImageOptions.target.override'+capitalizeFirstLetter(this.tripService.overrideStageImageOptions.imageType)+'Url')
    this.set('overrideCoverStylePropertyName', 'tripService.overrideStageImageOptions.target.override'+capitalizeFirstLetter(this.tripService.overrideStageImageOptions.imageType)+'CoverStyle')
console.log(this.coverStylePropertyName)
    this.setupImages()

  },


  setupImages(){
    let tripCarouselImages = this.tripCarouselImages;

      let mapping = {};
      let uniqueRegions = [this.get('tripService.overrideStageImageOptions.stage.regionProxy')]
      if (!this.get('tripService.overrideStageImageOptions.stage.regionProxy') && this.get('tripService.overrideStageImageOptions.trip')) {
        uniqueRegions = this.get('tripService.overrideStageImageOptions.trip.uniqueRegions')
      }

      RSVP.all(uniqueRegions.mapBy('parentRegion').uniq()).then((parentRegions)=> {
        let regionsForImages=[];

        uniqueRegions.forEach((region)=> {
          regionsForImages.pushObject(region)
          let parentRegionId = region.belongsTo('parentRegion').id();
          if (parentRegionId) {
            regionsForImages.pushObject(this.store.peekRecord('region', parentRegionId))
          }
        })
        regionsForImages = regionsForImages.uniq();

        let regionIds = regionsForImages.mapBy('id');

        regionIds.forEach((regionId)=> {
          let region = this.store.peekRecord('region', regionId);
          mapping[regionId]={id: regionId, name: region.name, images: []}
        })

        regionsForImages.forEach((region)=> {
          let regionId = region.id
          if (mapping[regionId]) {
            mapping[regionId].images = region.metaImagesAfterQuote.map((experienceImage) => {
              return getTripImageFromRegionalImage(experienceImage, region)
            })
            mapping[regionId].images.unshift(getTripImageFromRegionalImage({
              description: region.name,
              originalUrl: region.heroEntity.kodakOriginalUrl,
              coverStyle: region.heroEntity.coverStyle,
              regionId: region.id
            }, region))
          }
        })

        let loopable = [];

        if (this.get('tripService.overrideStageImageOptions.imageType')=='dayImage') {
          let blockMeta={name: "Experiences", images:[]}
          this.get('tripService.overrideStageImageOptions.target.allExperiences').forEach((experience)=> {
            blockMeta.images.pushObject(getTripImageFromExperience(experience))
            if (experience.get('metaImages')) {
              experience.get('metaImages').forEach((experienceImage)=> {
                blockMeta.images.pushObject(getTripImageFromEntityMetaImage(experienceImage, experience))
              })
            }
          })
          loopable.pushObject(blockMeta)
        }

        regionIds.forEach((regionId)=> {
          loopable.pushObject(mapping[regionId])
        })

        if (this.get('tripService.overrideStageImageOptions.stage.lodge')) {
          let lodgeMeta={id: this.get('tripService.overrideStageImageOptions.stage.regionProxy.id'), name: this.get('tripService.overrideStageImageOptions.stage.lodge.name'), images:[]}
          this.get('tripService.overrideStageImageOptions.stage.lodge.metaLodgeImages').forEach((lodgeImage)=> {
            lodgeMeta.images.pushObject(getTripImageFromEntityMetaImage(lodgeImage, this.get('tripService.overrideStageImageOptions.stage.lodge')))
          })
          loopable.pushObject(lodgeMeta)
        }

        this.set('regionImagesGroups', loopable)
      })

  },

  actions: {
    goChooseImage(options) {
      this.set('choosingImage', true)
    },
    setCoverStyle(coverStyle) {
      console.log(this.overrideCoverStylePropertyName, coverStyle);
      this.set(this.overrideCoverStylePropertyName, coverStyle);
    },

    selectImage(options) {
      if (!options.imageMeta.coverStyle) {
        options.imageMeta.coverStyle="center center";
      }

      this.set(this.overrideUrlPropertyName, options.imageMeta.originalUrl);
      this.set(this.overrideCoverStylePropertyName, options.imageMeta.coverStyle);
      this.set('choosingImage', false)
    }
  }
});
