import Component from "@ember/component";
import { TRANSFER_VEHICLE_TYPES } from "b5b/utils";
import { computed } from "ember-decorators/object";
import { reads } from "ember-decorators/object/computed";
import { inject as service } from "@ember/service";
import { AVAILABLE_CURRENCIES } from "b5b/services/settings";

export default Component.extend({
  cache: service(),

  errors: null,
  editingFromDestination: null,

  @reads("editTemplateModalOptions.template") template: {},

  @computed()
  allVehicleTypeKeys() {
    return Object.keys(TRANSFER_VEHICLE_TYPES).sort();
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

  actions: {

    setVehicleType(vehicleType) {
      this.set("template.vehicleType", vehicleType);
    },

    changeFromDestination(selectResult) {
      if (selectResult.action == "search:select" && selectResult.result) {
        if (selectResult.type == "location") {
          this.template.set("fromLocation", selectResult.result);
        } else if (selectResult.type == "region") {
          this.template.set("fromLocation", null);
          this.template.set("fromRegion", selectResult.result);
        }
        this.template.set(
          "fromCountry",
          selectResult.result.country ? selectResult.result.country : null
        );
      }
      this.set("editingFromDestination", false);
    },

    changeToDestination(selectResult) {
      if (selectResult.action == "search:select" && selectResult.result) {
        if (selectResult.type == "location") {
          this.template.set("toLocation", selectResult.result);
        } else if (selectResult.type == "region") {
          this.template.set("toLocation", null);
          this.template.set("toRegion", selectResult.result);
        }
        this.template.set(
          "toCountry",
          selectResult.result.country ? selectResult.result.country : null
        );
      }
      this.set("editingToDestination", false);
    }
  }
});
