import Mixin from '@ember/object/mixin';

import MapSearchingRouteMixin from 'b5b/mixins/map-searching/route';

import {
  reads
} from '@ember/object/computed';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';
import PrettyQuery from 'b5b/mixins/pretty-query';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';

import {
  DEFAULT_REGIONS_SORT
} from 'b5b/mixins/entities/regions/controller';

export default Mixin.create(PrettyQuery, MapSearchingRouteMixin, {

  historyService: service(),
  ui: service(),
  screen: service(),
  fastboot: service(),
  messageBus: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  xplorer: service('components/x-plorer'),
  tripService: service('trip'),
  pills: service(),
  router: service(),

  @alias('tripService.hasBackButton') hasBackButton: null,
  @alias('tripService.changingRegion') changingRegion: false,
  @alias('tripService.currentTrip') trip: null,

  queryParams: {
    selectedContinentNames: {
      replace: true
    },
    selectedCountryNames: {
      replace: true
    },
    selectedRegionNames: {
      replace: true
    },
    selectedMonthNames: {
      replace: true
    },
    selectedExperienceNames: {
      replace: true
    },
    selectedRegionTypeNames: {
      replace: true
    },
    changingRegion: {
      replace: true
    },
    curentRegionIds: {
      replace: true
    },
    sort: {
      replace: true
    }
  },

  per_page: 18,

  selectedModelType: 'selectedCountryNames',
  selectedModelTypeNameReference: null,

  headTags() {

    let model = this.currentModel;
    const title = `Regions of ${model.name} | ${this.whitelabel.agencySeoName}`;
    var description = `Explore places in ${model.name} with our fully customizable trip ideas and our travel experts to give you all the help and advice you need.`;

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;
    return generateMeta({
        description,
        'og:title': title,
      link,
      robots
    });

  },

  setupController(controller, model) {
    this._super(...arguments);

    this.set('ui.viewingMapListing', true);

    if (!this.get('trip')) {
      controller.set('changingRegion', false);
    }

    if (!controller.get('changingRegion')) {
      this.set('tripService.currentStageIndex', null);
    }

    this.get('xplorer').setupController(controller, this.controller.entitiesLoaded.bind(this.controller));

    if (controller && model) {
      this.xplorer.addFilter(model)
    }

    controller.setProperties({
      reset: false,
      firstTimeLoad: false,
      parentEntity: model
    });

    trackEvent('regions:view');
    controller.loadRegionsTask.perform();

    this.set('pills.activeType', 'regions');
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.get('xplorer').teardownController();
      // reset the properties not related to filters
      controller.setProperties({
        zoom: 3,
        mapInstance: null,
        center: null,
        searchMode: 'loading',
        firstTimeLoad: true,
        selectedContinentNames: [],
        selectedCountryNames: [],
        selectedRegionNames: [],
        selectedMonthNames: [],
        selectedExperienceNames: [],
        selectedRegionTypeNames: [],
        curentRegionIds: [],
        page: 1,
        swlat: null,
        swlng: null,
        nelat: null,
        nelng: null,
        changingRegion: false,
        mapOnlyView: null,
        filterOnlyView: null,
        sort: DEFAULT_REGIONS_SORT,
        filters: [],
        additionalFilter: null,
        polishLevel: 'default'
      });

      this.set('ui.viewingMapListing', false);
      this.set('pills.activeType', null);

    }
  },

  activate() {
    this._super(...arguments);
    this.get('screen').addBreakpoints({
      perPaneView: "(max-width: 1373px)"
    });
  },

  deactivate() {
    this._super(...arguments);
    this.get('screen').removeBreakpoints({
      perPaneView: "(max-width: 1373px)"
    })
  },

  actions: {

    showNewCustomRegion() {
      // The lodge version of this clears filters here
      console.log('showNewCustomRegion')
      this.set('controller.searchMode', 'destinationChange');
      this.set('controller.polishLevel', 'custom')
      this.set('controller.sort', 'created_at_desc')
      this.messageBus.publish('suggestions-refresh');
      this.controller.loadRegionsTask.perform();
    },

    resetFilters() {
      this.resetController(this.controller, true);
      this.set('controller.searchMode', 'destinationChange');
      this.controller.loadRegionsTask.perform();
      this.set('polishLevel', 'default')
    },

    showRegion(region) {
      trackEvent('regions-list-map:view-region');
      this.transitionTo('region', region);
    },

    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);

      if (this.get('changingRegion') &&
        transition.targetName !== 'region.index'
      ) {

        run.next(() => {
          this.setProperties({
            hasBackButton: false,
            changingRegion: false
          });
        });

      }
      // return true so willTransition bubbles
      return true;

    }
  }
});
