import {
  inject as service
} from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import {
  reads
} from 'ember-decorators/object/computed';
import {
  serializeDate
} from 'b5b/transforms/b5bdate';

export default Component.extend({
  classNames: ['date-selector'],

  pickerInstance: null,

  fastboot: service(),
  @reads('fastboot.isFastBoot') isFastBoot: false,

  setAction: null,
  date: null,

  minDateAllowed: null, //Optioncal configuration passed in
  syncBoundDate: true, //Optional configuraiton passed in
  placeholder: 'Select a date',
  disablePastDates: false,

  title: null,
  dateFormat: "D MMM 'YY",


  didUpdateAttrs() {
    this._super(...arguments);

    this.setupPickadate();
  },

  setupPickadate() {
    this._super(...arguments);
    // dont instantiate more than once
    if (this.get('pickerInstance')) {
      // clear old instance before setting new one
      this.get('pickerInstance').stop();

      // if fastboot ignore
      if (this.get('isFastBoot') || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
    }

    let toDate = true;
    let minDate = this.get('minDateAllowed') ? this.get('minDateAllowed') : moment().subtract(1, 'days');
    let dateMoment = moment(minDate);
    toDate = [dateMoment.year(), dateMoment.month(), dateMoment.date()];

    var options = {
      format: this.get('format'),
      container: '#date-picker-container',
      containerHidden: '#date-picker-container',
      onClose: this.onClose.bind(this),
      onSet: this.onSet.bind(this)
    };


    if (this.disablePastDates && (!this.date || moment(this.date).isAfter(moment()))) {
      options.disable = [{
        from: [1970, 0, 1],
        to: true
      }];
    }

    this.set('pickerInstance', $(this.element).pickadate(options).pickadate('picker'));

    if (this.get('title')) {
      this.get('pickerInstance.$root').find('.picker__frame').prepend('<div class="picker__title-bar">' + this.get('title') + '</div>');
    }
  },

  didInsertElement() {
    this.setupPickadate();
  },

  onSet(event) {
    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    // theres a bug where opening the datepicker triggers onSet
    // we dont want the bound logic to fire on open
    if (moment(this.date).isSame(event.select)){
      return
    }

    if (event.clear === null) {
      this.set('date', null)
    } else if (event.select) {
      let newDate = new Date(event.select);
      let serializedDate = serializeDate(newDate);
      if (this.get('date') !== serializedDate) {
        if (this.get('syncBoundDate')) {
          this.set('date', serializedDate);
        }
        if (this.get('setAction')) {
          this.get('setAction')(newDate);
        }

      }
    }
  },


  onClose() {
    // Prevent pickadate from re-opening on focus
    $(document.activeElement).blur();
  },

  click(event) {
    this.get('pickerInstance').open();
    if (this.get('date')) {
      this.get('pickerInstance').set('select', new Date(this.get('date')));
    }


    event.stopPropagation();
  }

});
