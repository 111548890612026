import Service from '@ember/service';

export default Service.extend({

  outstandingImages: {},
  outstandingImagesTimeStamp: null,

  imageRequested(url) {
    let key = url
    console.log('adding image to rendering service: ', key)
    this.get('outstandingImages')[key]=true;
    window.timbuktu.outstandingImages=this.outstandingImages;
    this.set('outstandingImagesTimeStamp', "requested"+Date.now().toString())
  },

  imageLoaded(url) {
    let key = url
    console.log('completed image loading: ', key)
    delete this.get('outstandingImages')[key]
    this.set('outstandingImagesTimeStamp', "loaded"+Date.now().toString())
  }


});
