import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  tagName: '',

  ui: service(),
  whitelabel: service(),
  
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null

});