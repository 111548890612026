import MapListing from 'b5b/components/map-listing/component';
import {
  inject as service
} from '@ember/service';

import {
  task
} from 'ember-concurrency';
import {
  run
} from '@ember/runloop';
import {
  or
} from 'ember-decorators/object/computed';

export default MapListing.extend({
  tagName: '',

  ui: service(),
  screen: service(),
  scroll: service(),
  messageBus: service(),
  xplorer: service('components/x-plorer'),

  mapOnlyViewTitle: 'Experiences',

  @or('xplorer.isLoading', 'loadExperiencesTask.isRunning') experiencesLoading: false,

  init() {
    this._super(...arguments);
    this.get('messageBus').subscribe('pill-added', this, this.reload);
    this.get('messageBus').subscribe('pill-removed', this, this.reload);
    this.get('messageBus').subscribe('suggestions-reset', this, this.reload);
    this.get('messageBus').subscribe('suggestions-refresh', this, this.reload);
  },

  reload() {
    this.get('loadExperiencesTask').perform();
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.loader);
  },

  actions: {
    setPolishLevel(val) {
      this.set('polishLevel', val)
      if(val==='custom' || val==='personal') {
        this.set('sort', 'created_at_desc')
      } else {
        this.set('polishLevel',null)
        this.set('sort', 'priority_asc')
      }
      this.set('settings.searchMode', 'destinationChange')
      this.get('loadExperiencesTask').perform();
    },  

    setSort(sort) {
      this.set('sort', sort);
      this.set('page', 1);
      this.messageBus.publish('suggestions-refresh');
    },

  }

});
