import {
  and
} from '@ember/object/computed';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';


export default DS.Model.extend({

// NB: Guests are not part of the embedded save for trips. ItineraryGuest mappings are, but the actual guests need to be saved seperately

  sequence: DS.attr(),

  //TODODEALGUESTS this should only belong to deal
  itinerary: DS.belongsTo('itinerary'),
  deal: DS.belongsTo('deal'),

  contactSharedGuest: DS.belongsTo('guest', {
    inverse: null
  }),
  emergencySharedGuest: DS.belongsTo('guest', {
    inverse: null
  }),
  insuranceSharedGuest: DS.belongsTo('guest', {
    inverse: null
  }),

  name: DS.attr('string'),
  surname: DS.attr('string'),
  ageType: DS.attr('string'),
  birthDate: DS.attr('b5bdate'),
  weight: DS.attr('string'),
  gender: DS.attr('string'),
  description: DS.attr('string'),

  passportNumber: DS.attr('string'),
  passportCountry: DS.attr('string'),
  passportExpiryDate: DS.attr('b5bdate'),

  email: DS.attr(),
  mobileNumber: DS.attr('string'),
  mobileCountryCode: DS.attr('string'),
  addressLineOne: DS.attr('string'),
  addressLineTwo: DS.attr('string'),
  city: DS.attr('string'),
  state: DS.attr('string'),
  postcode: DS.attr('string'),
  country: DS.attr('string'),

  emergencyContactName: DS.attr('string'),
  emergencyCountryCode: DS.attr('string'),
  emergencyNumber: DS.attr('string'),

  specialDietary: DS.attr('string'),
  specialMedical: DS.attr('string'),
  roomType: DS.attr('string'),

  travelInsuranceState: DS.attr('string'), // confirmed / pending / null

  insurancePolicyNumber: DS.attr('string'),
  insuranceProvider: DS.attr('string'),
  insuranceCountryCode: DS.attr('string'),
  insuranceContactNumber: DS.attr('string'),

  personalDetailsComplete: DS.attr('boolean'),
  insuranceDetailsComplete: DS.attr('boolean'),

  isComplete: and('personalDetailsComplete', 'insuranceDetailsComplete'),

  possibleAgeTypes: ['adult', 'youngAdult', 'child', 'infant'],
  possibleRoomTypes: ['twin', 'triple', 'double', 'single'], // this also exists on stage model

  @computed('name', 'surname')
  fullName(name, surname) {
    return `${name ? name : ''} ${surname ? surname : ''}`;
  },

  @computed('fullName', 'ageType')
  displayName(fullName, ageType) {
    return `${fullName}`;
  },

  //TODODEALGUESTS 
  // @computed('deal.guests.@each.addressLineOne')
  @computed('itinerary.guests.@each.addressLineOne')
  otherGuestsWithContactDetails(guests) {
    let guestsArr = [];
    guests.forEach((guest) => {
      if (guest.get('addressLineOne')) {
        guestsArr.pushObject(guest);
      }
    });
    return guestsArr.rejectBy('id', this.id);
  },

    //TODODEALGUESTS 
  // @computed('deal.guests.@each.emergencyNumber')
  @computed('itinerary.guests.@each.emergencyNumber')
  otherGuestsWithEmergencyContacts(guests) {
    let guestsArr = [];
    guests.forEach((guest) => {
      if (guest.get('emergencyNumber')) {
        guestsArr.pushObject(guest);
      }
    });
    return guestsArr.rejectBy('id', this.id);
  },

      //TODODEALGUESTS 
  // @computed('deal.guests.@each.insurancePolicyNumber')
  @computed('itinerary.guests.@each.insurancePolicyNumber')
  otherGuestsWithInsurance(guests) {
    let guestsArr = [];
    guests.forEach((guest) => {
      if (guest.get('insurancePolicyNumber')) {
        guestsArr.pushObject(guest);
      }
    });
    return guestsArr.rejectBy('id', this.id);
  },

  @computed('mobileNumber', 'mobileCountryCode')
  mobileNumberInternational(mobileNumber, mobileCountryCode) {
    let number = mobileCountryCode + ' ' + mobileNumber;
    return number;
  },

  @computed('emergencyNumber', 'emergencyCountryCode')
  emergencyNumberInternational(emergencyNumber, emergencyCountryCode) {
    let number = emergencyCountryCode + ' ' + emergencyNumber;
    return number;
  },

  @computed('insuranceContactNumber', 'insuranceCountryCode')
  insuranceContactNumberInternational(insuranceContactNumber, insuranceCountryCode) {
    let number = insuranceCountryCode + ' ' + insuranceContactNumber;
    return number;
  }
});
