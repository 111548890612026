import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';

import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),

  setupController(controller) {

    var draftItinerary = controller.get('trip.itinerary');

    // NB don't set the trip here. It is injected into the controller
    controller.setProperties({
      itinerary: draftItinerary,
      saving: false
    });

    trackEvent('trip:dashboard:guest-details:index');
  },

  actions: {
    saveSpecialRequest() {
      this.controller.set('saving', true);
      return this.controller.get('itinerary').updateItinerary({
        'special_request': this.controller.get('itinerary.specialRequest')
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.controller.set('saving', false);
        this.transitionTo('trip.index.dashboard', this.controller.get('trip'));
      });
    }
  }
});
