import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';

import {
  computed
} from 'ember-decorators/object';
import {
  not,
  reads,
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['x-feefo'],

  @not('whitelabel.isForAgency') isVisible: false,

  whitelabel: service(),
  store: service(),
  fastboot: service(),
  feefo: service(),

  @reads('fastboot.isFastBoot') isFastBoot: false,

  @alias('feefo.feefoMeta') feefoMeta: null,
  @alias('feefo.feefoServiceReviews') allReviews: null,

  activeReviewIndex: 0,

  @computed('activeReviewIndex', 'allReviews')
  activeReview(activeReviewIndex, allReviews) {
    return allReviews[activeReviewIndex];
  },

  @computed('activeReview.date')
  reviewDate(activeReviewDate) {
    return moment(activeReviewDate).fromNow();
  },

  actions: {
    prev() {
      trackEvent('x-feefo:prev');
      if (this.get('activeReviewIndex') === 0) {
        return this.set('activeReviewIndex', this.get('allReviews.length') - 1);
      }
      this.decrementProperty('activeReviewIndex');
    },

    next() {
      trackEvent('x-feefo:next');
      if (this.get('activeReviewIndex') === this.get('allReviews.length') - 1) {
        return this.set('activeReviewIndex', 0);
      }
      this.incrementProperty('activeReviewIndex');
    },

    trackEvent(event) {
      trackEvent(event);
    }
  }
});
