import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default Component.extend({

  rendering: service(),

  init() {
    this._super(...arguments);
    const appDiv = document.getElementById("app");
    let context = this;
    appDiv.addEventListener("xhrComplete", () => {
        console.log('recevied xhr complete')
        context.set('allXhrCompleted', true)
    });
  },

  @computed('allXhrCompleted', 'rendering.outstandingImagesTimeStamp')
  allXhrAndImagesCompleted(allXhrCompleted, outstandingImagesTimeStamp) {
    let outstandingImages = Object.keys(this.rendering.outstandingImages).length;
    console.log(outstandingImages, "Outstanding images")
    console.log(Object.keys(this.get('rendering.outstandingImages')))
    if (allXhrCompleted && outstandingImages==0) {
      console.log('inserting pdf-gen-ready')
      // window.prerenderReady = true;
      return true;
    }
  },





});
