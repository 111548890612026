import filterPaneComponent from 'b5b/components/filter-pane/component';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';

import {
  getCategoryExperiencesPromise
} from 'b5b/utils';

export default filterPaneComponent.extend({
  classNames: ['experiences'],

  @computed('entityModel')
  showCountries(entityModel) {
    return !entityModel || entityModel.get('constructor.modelName') === 'experience';
  },

  init() {
    this._super(...arguments);
    this.set('performingSetupTask', this.get('setupCategoryExperiences').perform());

    this.cache.getAllPublishedCountriesPromise().then((countries)=> {
      this.set('countries', countries)
    })
  },

  didRender() {
    this._super(...arguments);

    // All of this is to expand experiences if one which is too low down on the list is selected
    let filterExperiences = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'experience')    
    if (this.get('categoryExperiences.length') && filterExperiences && filterExperiences.length > 0) {
      this.set('showAllExperiences', true);
    }        

    let filterCountries = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'country')    
    if (filterCountries && filterCountries.length > 0) {
      this.set('showAllCountries', true);
    }    

  },

  setupCategoryExperiences: task(function*() {
    let catExperiences = yield getCategoryExperiencesPromise(this.get('store'));
    this.set('categoryExperiences', catExperiences);
  })


});
