import EntityContent from 'b5b/components/entity-content/component';
import {
  inject as service
} from '@ember/service';

import {
  computed
} from 'ember-decorators/object';
import {
  trackFacebookDestinationView
} from 'b5b/utils';


export default EntityContent.extend({

  tripService: service('trip'),
  store: service(),
  router: service(),
  queriedForRegions: false,

  country: null,

  didReceiveAttrs() {
    this._super(...arguments);

    trackFacebookDestinationView(this.country, this.tripService);

    // if (this.onModal) {
      let query = {
        selectedCountryNames: [this.get('country.name')],
        per_page: 3,
        isRegional: true
      };

      this.get('store').query('experience', query).then((experiences) => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }

        this.set('recommendedExperiences', experiences);
      });

      this.get('store').query('lodge', query).then((lodges) => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }

        this.set('recommendedLodges', lodges);
      });

      // WE need to include isManager here to ensure that the cache keys look different for managers and guests 
      // Otherwise we would end up getting the responses cached for wrong user type
      if (!this.queriedForRegions && (!this.regions || this.get('regions.firstObject.country.id') != this.country.id)) {
        this.set('queriedForRegions', true)
        this.store.query('region', {
          filters: JSON.stringify([{type: 'country', id: this.country.id}])
        }).then((regions)=> {
          this.set('regions', regions)
        })        
      }

    // }
  },

  @computed('regions')
  mapMarkers(regions) {
    let markers = [];
    if (regions) {
      regions.forEach((region) => {
        markers.push(region.get('mapMarker')[0]);
      });  
    }

    return markers;
  },

  actions: {
    openMap(options) {
      if (this.onModal) {
        this.entityModals.openModal(options)
      } else {
        this.router.transitionTo('country.regions')
      }
    }
  }
});
