import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';


export default Component.extend({
  classNames: ['add-a-stage'],
  tripService: service('trip'),

  actions: {
    showNewStageModal(options) {
      // console.log(options)
      this.set('ui.showNewStageModal', options)
    }
  }
});
