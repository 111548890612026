import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  getSortTitle
} from 'b5b/utils';

import Component from '@ember/component';

export default Component.extend({
  classNames: ['region-experiences-list'],

  ui: service(),
  screen: service(),

  @computed('sort', 'screen.isMobileOnly')
  sortTitle(sort, isMobileOnly) {
    return getSortTitle(sort, isMobileOnly);
  },  

  @computed('experiences.meta.totalCount')
  experiencesCount(experiencesLength) {
    return experiencesLength;
  }

});
