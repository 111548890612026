import Service, {
  inject as service
} from '@ember/service';
import RSVP from 'rsvp';

export default Service.extend({

  getStripeReadyPromise() {
    if (!this.stripeReadyPromise) {
      var promise = new RSVP.Promise((resolve) => {
        addAsyncScript('https://js.stripe.com/v3/', 'stripe-script', function() {
          resolve(null);
        });
      });
      this.set('stripeReadyPromise', promise);
    }
    return this.stripeReadyPromise;
  }


});
