import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNames: ['pill-tags'],

  items: [],
  itemAction: null,
  sort: true,

  count: 2,

  @computed('items.@each.name')
  itemsSorted(items) {
    if (this.sort) {
      return items.sortBy('name.length');
    } else {
      return items;
    }
    
  },

  @computed('itemsSorted', 'count')
  topItems(items, count) {
    if (items.length > count) {
      return items.slice(0, count);
    } else {
      return items;
    }
  },

  @computed('itemsSorted.length', 'count')
  tooltipCount(length, count) {
    return length - count;
  },

  @computed('itemsSorted', 'count')
  toolTipContent(items, count) {
    if (items.length > count) {
      return items.slice(count).mapBy('name').join(', ');
    }
  },

  actions: {
    pillAction(param) {
      if (this.get('itemAction')) {
        this.get('itemAction')(param);
      }
    }
  }

});