import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  equal
} from 'ember-decorators/object/computed';
import {
  TRANSFER_VEHICLE_TYPES
} from 'b5b/utils'

export const DESCRIPTION_CODE_SELF='self'
export const DESCRIPTION_CODE_WALK='walk'
// DESCRIPTION_CODES doesnt need to be sorted here.. It is sorted wherever we use it
export const DESCRIPTION_CODES = ["boat", "boat_light_road", "comm_road", "guided", "heli", "heli_light_light", "heli_light_light_road", "light_light_heli", "light_light_road", "light_road", "road", "road_boat_light", "road_comm", "road_comm_comm_light_road", "road_comm_comm_road", "road_comm_light_road", "road_comm_road", "road_comm_road_light_road", "road_heli_comm_comm_light_road", "road_light", "road_light_boat", "road_light_boat_road", "road_light_comm_comm_heli_road", "road_light_comm_comm_light_road", "road_light_comm_comm_road", "road_light_comm_road", "road_light_heli", "road_light_heli_road", "road_light_light", "road_light_light_heli", "road_light_light_road", "road_light_road", "road_light_road_comm_road", "road_light_road_boat", "boat_road_light", DESCRIPTION_CODE_SELF, DESCRIPTION_CODE_WALK]

export const VEHICLE_TYPE_SELF_DRIVE = 'self-drive';
export const VEHICLE_TYPE_COMMERCIAL_FLIGHT = 'commercial_flight';
export const VEHICLE_TYPE_LIGHT_AIRCRAFT = 'light_aircraft';

export const CHARGE_PER_VEHICLE = 'vehicle';
export const TRANSFER_TYPE_CUSTOM = 'custom';
export const TRANSFER_TYPE_TEMPLATE = 'template';
export const TRANSFER_TYPES = [TRANSFER_TYPE_CUSTOM, TRANSFER_TYPE_TEMPLATE];

export default DS.Model.extend({

  vehicleType: DS.attr(),
  duration: DS.attr('number'), // minutes
  description: DS.attr(),
  transferType: DS.attr(),
  flightNum: DS.attr(),
  distance: DS.attr(),
  isMaster: DS.attr(),
  polished: DS.attr(),

  // These are exclusively for template transfer
  cost: DS.attr('number'),
  currency: DS.attr(),
  rateType: DS.attr(),
  chargePer: DS.attr(), // pax, vehicle
  descriptionCode: DS.attr(),
  recommended: DS.attr('boolean'),
  isFallback: DS.attr('boolean'),
  metaLocations: DS.attr(),
  internalName: DS.attr(),

  originalUser: DS.belongsTo('user', {inverse: null, async: false}),
  fromCountry: DS.belongsTo('country', {
    async: false,
    inverse: null
  }),
  fromRegion: DS.belongsTo('region', {
    async: false,
    inverse: null
  }), 
  fromLocation: DS.belongsTo('location', {
    async: false,
    inverse: null
  }),
  toCountry: DS.belongsTo('country', {
    async: false,
    inverse: null
  }),  
  toRegion: DS.belongsTo('region', {
    async: false,
    inverse: null
  }),
  toLocation: DS.belongsTo('location', {
    async: false,
    inverse: null
  }),
  



  @equal('transferType', TRANSFER_TYPE_TEMPLATE) isTemplateTransfer: false,
  @equal('transferType', TRANSFER_TYPE_CUSTOM) isCustomTransfer: false,

  @computed('duration')
  durationInTime(duration){
    if (!duration){
      return;
    }

    let str = '';

    if (duration >= 60) {
      let hrs = ~~(duration / 60);
      str += hrs;
      str += hrs > 1 ? 'hrs ' : 'hr ';
    }

    str += (duration % 60) > 0 ? (duration % 60) + 'mins' : '';

    return str;
  },

  @computed('metaLocations')
  locations(metaLocations){
    return metaLocations.map((destination)=> {
      return this.store.peekRecord('location', destination.location_id);
    });
  },

  @computed('vehicleType')
  isOwnArrangement(vehicleType){
    return vehicleType === 'own_arrangement';
  },


  @computed('fromLocation', 'fromRegion')
  fromDestination(fromLocation, fromRegion){
    if (fromLocation) {
      return fromLocation;
    } else {
      return fromRegion;
    }
  },

  @computed('toLocation', 'toRegion')
  toDestination(toLocation, toRegion){
    if (toLocation) {
      return toLocation;
    } else {
      return toRegion;
    }
  },

  @computed('vehicleType')
  vehicleTypeLabel(vehicleType) {
    if (!vehicleType) {
      return 'Transfer';
    }
    return TRANSFER_VEHICLE_TYPES[vehicleType.toLowerCase()].label;
  },

  @computed('vehicleType')
  vehicleTypeSVG(vehicleType) {
    if (!vehicleType) {
      return 'transfers--general';
    }
    return 'transfers--' + TRANSFER_VEHICLE_TYPES[vehicleType.toLowerCase()].svg;
  },

  makeCopy() {

    let props = this.getProperties(
      'vehicleType',
      'duration',
      'chargePer',
      'description',
      'transferType',
      'flightNum',
      'distance'
    );
    let copy = this.get('store').createRecord('transfer', props);
    copy.set('transferType', TRANSFER_TYPE_CUSTOM);
    return copy;
  }

});
