import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  trackEvent,
  getTermsAndConditionsUrlForTrip
} from 'b5b/utils';
import {
  reads
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import EmberObject from '@ember/object';

export default Controller.extend(UseCurrentTrip, {
  settings: service(),
  ui: service(),
  screen: service(),
  whitelabel: service(),
  tripService: service('trip'),

  @computed()
  giftListContributionPayment() {
    // This can't be set on the trip/itin the route because when the user logs for protected outlet, it gets lost
    return EmberObject.create({
      paymentType: 'deposit',
      priceHash: null,
      paymentReason: 'giftListContribution',
      settlementCurrency: this.get('tripService.currentTrip.itinerary.currencyForSettlement'),
      heading: 'Choose your own amount'
    }) 
  },


  
  
});
