import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  tagName: '',

  ui: service(),
  screen: service(),

  mapOnlyViewTitle: 'Countries',

  didRender() {
    this._super(...arguments);

    if (this.mapOnlyView && !this.get('screen.isMapOnlyView')) {
      this.set('mapOnlyView', null);
    }
  }
});
