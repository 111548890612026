import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

import {
  task
} from 'ember-concurrency';


export default Controller.extend(UseCurrentTrip, {

  session: service(),
  settings: service(),
  ui: service(),

  loadChargesTask: task(function*() {
    let queryAttrs = {
      tripId: this.get('trip.id'),
      per_page: 100, 
      page: 0,
      buster: true
    };


    return this.store.query('charge', queryAttrs).then((charges)=>{
      this.set('charges', charges)
    })

  }).keepLatest()   

});
