import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['trip-tools-right-pane-add-inclusions', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  templateManager: service(),
  entityModals: service(),
  store: service(),
  session: service(),
  dayExperiences: null,
  showCurrentUserInclusionsOnly: false,
  showCurrentLodgeInclusionsOnly: false,

  didInsertElement() {
    this._super(...arguments);
    if (this.get('templateManager.currentStage.regionProxy.name')) {
      this.get('loadInclusionsTask').perform();
    }
  },

  loadInclusionsTask: task(function*() {
    let queryAttrs = {
      templateType: 'template',
      buster: true,      
      sort: 'recommended'
    };

    let filters = [{type:'region', id: this.get('templateManager.currentStage.regionProxy.id')}]
    this.get('templateManager.currentStage.regionProxy').get('associatedEntities') && this.get('templateManager.currentStage.regionProxy').get('associatedEntities').forEach((associatedEntity)=> {
      if (associatedEntity.get('associatedRegion')) {
        filters.pushObject({type: 'region', id: associatedEntity.get('associatedRegion.id')})
      }
    })  
    
    if (this.showCurrentLodgeInclusionsOnly) {
      filters.pushObject({type: 'lodge', id: this.templateManager.currentStage.lodge.id})
    }
    queryAttrs.filters=JSON.stringify(filters)

    if (this.showCurrentUserInclusionsOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }

    return this.store.query('inclusion', queryAttrs).then((inclusions)=>{
      this.set('inclusions', inclusions)
    })

  }).keepLatest()
});
