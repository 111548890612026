define('ember-chrome-devtools/formatters/utils/jsonml', ['exports', 'ember-chrome-devtools/formatters/lazy'], function (exports, _lazy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.header = header;
  exports.emberIcon = emberIcon;
  exports.computedPropertyIcon = computedPropertyIcon;
  exports.list = list;
  exports.item = item;
  exports.name = name;
  exports.separator = separator;
  exports.reference = reference;
  exports.lazy = lazy;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function header(text) {
    var style = '\n    color: #E14E34;\n    margin-bottom: 4px;\n  ';

    return ['div', { style: style }, emberIcon(), ' ' + text];
  }

  function emberIcon() {
    var style = '\n    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAuVJREFUOBE9U0tIlFEU/u7/mvfoNJqmlYmLnuJGYdwMEppB0YtISqhdEBREj1WLNkG7qF1IUFJt2khGEGEtEnORUCSYmGDMNIWpMzrv/3k79454mPnv45zvO8/LMpeOchIwDoBt/sUeCrg4qzoUhYF7dOk5dMGhqBqdHbilEjQBZIwMiEQwyFXcaQpQLoDnfhAuB67GwRr2QQ3Xk50Hz3bhmRVonBil400SWsgrecimYXQfQaDvLpRgCLxSQmXqA6xvk1AiRKaoZAiKQADFTkRAYC4U+X8IDV1D9OR5CRZqIcGubmQfqzC/fAT3hykQD0oNTFpBJMAby/D3n0X9mWEJdtZWUJycgJtdBQtHYfQk4cxOwbNEPUSlXBfcoQOxwTQp1ALCyUHAFwAcG/lXo8gmB2AtzkuATM+iLSd7cqp5xTwYgd3KBrjrwDjYC31nmzS2UksoPb0N4+oQ9F3t8s5ZnAMaCE/kQjTByHwK/F0JWPMzMA50QQlTfiT2nzT8x68jdvkWtOZWmPOzKL8eAduRIKUpbVhqYA8PX7iJ2MUrcHNZyaw1tUglN6tE7qf0XJSmPyH/7CGc34vggQhE6kI0pgfgFYvSmxbfDhYIUnykoY4wXYf5cw6FsReojN0HC0XgSwzD/puBu5yidhtgmXNJ7lULULc1Qu/oRPDwMQR7+2SBvPU1rN67ASe1AG1/D4L9J+Bra0fuySNUPr8BizRTEUWYGkVRqqA88Rz2wlf49h6CGm+EEo0hfueB7JJaVw9m+ODl1+Hmc+SdUhNjTV8yoFbaNtSWTrjkdf3lCPV9hZqsQI3FoTU2SbCoSf79OE3jW0onJmtDMyt46ENt5aUNKL4oyu9G4WZ+ITR4Gmprm/TqpJdgfp9BZXocLNpBRawNEkufStD78ahm4hEIoT0VlrvUpuoqGZbAKFwOnSaVViO0BRbWGqchEu9BxFH7EZUt6qIDdbupmCLLWq6ebYFbVYHbkv9aQEzuWmoUDAAAAABJRU5ErkJggg==)\n      no-repeat\n      left center;\n    padding-right: 16px;\n    border-radius: 2px;\n  ';

    return ['span', { style: style }, ''];
  }

  function computedPropertyIcon() {
    var style = '\n    background-color: #E14E34;\n    color: white;\n    padding: 0 4px;\n    margin-right: 6px;\n    border-radius: 2px;\n  ';
    return ['span', { style: style }, 'get'];
  }

  function list() {
    var style = '\n    margin: 0;\n    z-index: 0;\n    position: relative;\n    padding-left: 12px;\n    display: flex;\n    flex-direction: column;\n  ';

    for (var _len = arguments.length, children = Array(_len), _key = 0; _key < _len; _key++) {
      children[_key] = arguments[_key];
    }

    return ['ol', { style: style }].concat(children);
  }

  function item() {
    var style = '\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    position: relative;\n    display: flex;\n    align-items: flex-start;\n    min-height: 16px;\n  ';

    for (var _len2 = arguments.length, children = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      children[_key2] = arguments[_key2];
    }

    return ['li', { style: style }].concat(children);
  }

  function name(name) {
    var enumerable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    var style = '\n    color: rgb(136, 19, 145);\n    flex-shrink: 0;\n  ';

    if (!enumerable) {
      style += '\n      opacity: 0.6;\n    ';
    }
    return ['span', { style: style }, name];
  }

  function separator() {
    var style = '\n    flex-shrink: 0;\n    padding-right: 5px;\n  ';
    return ['span', { style: style }, ': '];
  }

  function reference(object) {
    if (object === undefined) {
      // Special case for `undefined`, otherwise an error will occur:
      // > Custom Formatter Failed: Illegal format: obligatory attribute "object" isn't specified
      var style = '\n      color: rgb(128, 128, 128);\n    ';
      return ['span', { style: style }, 'undefined'];
    }

    if (object !== null && (typeof object === 'undefined' ? 'undefined' : _typeof(object)) === 'object' && !(object instanceof Ember.Object)) {
      // fix alignment
      var _style = '\n      margin-top: -4px;\n    ';

      return ['span', { style: _style }, ['object', { object: object }]];
    }

    return ['object', { object: object }];
  }

  function lazy(compute) {
    return ['object', { object: new _lazy.Lazy(compute) }];
  }
});