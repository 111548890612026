import {
  trackEvent
} from 'b5b/utils';
import Controller from '@ember/controller';
import EntitiesRoutesControllerMixin from 'b5b/mixins/entities/routes/controller';

export default Controller.extend(EntitiesRoutesControllerMixin, {
  userTripsOnly: true,
  allAccountTrips: false,
  polishLevel: 'personal',
  defaultPolishLevel: 'personal',
  
  sort: 'api_updated_at_asc',
  defaultSort: 'api_updated_at_asc',

  paginationScrollHeight: 240,


});
// import {
//   inject as service
// } from '@ember/service';
// import Controller from '@ember/controller';
// import {
//   observer
// } from '@ember/object';
// import {
//   computed
// } from 'ember-decorators/object';
// import {
//   alias
// } from 'ember-decorators/object/computed';
// import {
//   trackEvent
// } from 'b5b/utils';

// export default Controller.extend({

//   xplorer: service('components/x-plorer'),
//   scroll: service(),

//   @alias('xplorer.tripSummaries') tripSummaries: null,
//   @alias('xplorer.isLoading') isLoading: null,

//   searchValue: '',
//   bookingState: 'all',
//   page: 1,

//   // We don't need a sort here. The server needs to handle the different sorts based on the booking state
//   // sort: '',

//   queryParams: [{
//     bookingState: {
//       as: 'state'
//     },
//     searchValue: {
//       as: 'q'
//     },
//     sort: {},
//     allAccountTrips: {}
//   }],


//   tripToDelete: null,
//   showingShouldDeleteModal: false,
//   firstTimeLoad: true,
//   per_page: 18,
//   paginationPageCount: 3,
//   user_id: null,
//   userTripsOnly: true,
//   allAccountTrips: false,


//   updatePageParam: observer('tripSummaries.page', function() {
//     var page = this.get('tripSummaries.page');
//     if (page) {
//       this.set('page', page);
//     }

//     if (!this.get('firstTimeLoad')) {
//       this.get('scroll').to(0, this, 300);
//     }

//   }),

//   @computed('tripSummaries.meta.total_pages', 'tripSummaries.page')
//   hasNext(totalPages, currentPage) {
//     return currentPage < totalPages;
//   },

//   @computed('tripSummaries.meta.total_pages', 'tripSummaries.page')
//   hasPrev(totalPages, currentPage) {
//     return currentPage > 1
//   },

//   actions: {






//     doSearch(e) {
//       if (e) {
//         e.preventDefault();
//       }
      
//       this.set('page', 1);
//       this.get('xplorer').loadRecords('tripSummaries');
//     }
//   }
// });
