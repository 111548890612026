import DS from 'ember-data';

export default DS.Model.extend({
  itinerary: DS.belongsTo('itinerary'),
  itineraryAttributes: DS.attr(),
  numNights: DS.attr(),
  estimate: DS.attr(),
  updatedAt: DS.attr()


});
