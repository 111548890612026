import Route from '@ember/routing/route';

export default Route.extend({
  beforeModel() {
    // Just remeber that not all generic widget point at this url. THere are plenty out there deployed that have  url set to /trips. They are currently being redirected to index in applicaiton route because they dont ahve a country naem or region


    this.transitionTo('index');
  }
});
