import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import RSVP from 'rsvp';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  task
} from 'ember-concurrency';



export default Controller.extend(UseCurrentTrip, {

  tripService: service('trip'),
  fileQueue: service('file-queue'),
  xplorer: service('components/x-plorer'),  
  @alias('tripService.currentTrip.id') tripId: null,
  documents: [],


  entitiesLoaded() {
    // Right now we do nothing, but this can be used to trigger side effects when entities are loaded. See the map listing for example
  }, 

  loadDocumentsTask: task(function* () {
    this.setProperties({      
      buster: true,
      sort: "created_at_asc",
      tripId: this.trip.id,
      per_page: 1000
    });

    let results = yield this.get("xplorer").loadRecords("documents", {
      skipSettingsRecords: true,
    });

    this.set("documents", results);
  }).restartable(),


  actions: {
    
    saveDocs() {

    },
    reloadDocs() {
      this.get('loadDocumentsTask').perform()
    }
  }
});
