import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';
  
export default Component.extend({
  classNames: ['stage stage-counter'],

  tripService: service('trip'),
  router: service(),
  entityModals: service(),
  screen: service(),

  trip: null,
  stage: null
});
