import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';
import {
  setupTripEntityNav,
  trackEvent
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

export default Route.extend(tripSubRoute, {


  affiliate: service(),
  settings: service(),
  store: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') currentTrip: false,
  router: service(),
  ui: service(),
  session: service(),

  setupController(controller, model) {
    this._super(...arguments);

    setupTripEntityNav(model, this.tripService, this.ui, this.session)

    trackEvent('trip:quote:view', {
      trip: this.get('currentTrip.id'),
      type: this.get('currentTrip.tripType'),
      state: this.get('currentTrip.itinerary.state')
    });
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set('ui.entityNavData', null);
    }
  }

});