import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';

export default Route.extend({

  ui: service(),

  setupController(controller) {
    this._super(...arguments);
    controller.set('agency', this.modelFor('waybird.account'))
    
  }
});
