import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['special-offers-summary'],

  ui: service(),
  router: service(),

  lodge: null,

  click() {
    this.get('router').transitionTo('lodge.special-offers', this.get('lodge'));
  }

});
