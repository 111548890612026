import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  or
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNameBindings: [':x-tabs', 'activeClass'],

  defaultItem: 1, // 1, 2, 3 etc.
  _activeItem: null, // private
  @or('_activeItem', 'defaultItem') activeItem: null, // actual

  onToggle: null, // closure

  @computed('activeItem')
  activeClass(activeItem) {
    return 'tab-' + activeItem;
  },

  actions: {
    toggleTab(item, params) {
      if (this.get('onToggle')) {
        this.get('onToggle')(params);
      }
      this.set('_activeItem', item);
    }
  }

});