import Service, {
  inject as service
} from '@ember/service';
import {
  getCookieValue,
  LOCAL_STORAGE_CONSULTANT_ID_KEY,
  getItemFromLocalStorage,
  setItemInLocalStorage
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  CONSENT_COOKIE_NAME
} from 'b5b/components/gdpr-banner/component'
import envConfig from 'b5b/config/environment';

const countryCurrencyMap = {
  'AU': 'aud',
  'GB': 'gbp',
  'GG': 'gbp',
  'GI': 'gbp',
  'IM': 'gbp',
  'IO': 'gbp',
  'AD': 'eur',
  'AT': 'eur',
  'BE': 'eur',
  'BG': 'eur',
  'CH': 'chf',
  'CY': 'eur',
  'CZ': 'eur',
  'DE': 'eur',
  'DK': 'eur',
  'EE': 'eur',
  'ES': 'eur',
  'FI': 'eur',
  'FR': 'eur',
  'GR': 'eur',
  'HR': 'eur',
  'HU': 'eur',
  'IE': 'eur',
  'IT': 'eur',
  'LV': 'eur',
  'LU': 'eur',
  'LT': 'eur',
  'MT': 'eur',
  'NL': 'eur',
  'PL': 'eur',
  'PT': 'eur',
  'RO': 'eur',
  'SK': 'eur',
  'SE': 'eur',
  'SI': 'eur',
  'US': 'usd',
  'ZA': 'zar',
  'CA': 'cad',
  'NZ': 'nzd',
  'SG': 'sgd',
  'HK': 'hkd',
  'JP': 'jpy'

}

const countryOfficeMap = {
  'US': 'US',
  'CA': 'US',
  'ZA': 'ZA'
}

const investLandingPageMap = {
  'US': 'https://trips.timbuktutravel.com/invest-us/',
  'CA': 'https://trips.timbuktutravel.com/invest-us/',
  'JP': 'https://trips.timbuktutravel.com/invest-us/',
  'ZA': 'https://trips.timbuktutravel.com/invest-us/',
  'TZ': 'https://trips.timbuktutravel.com/invest-us/',
}

const officePhoneNumMap = {
  'US': '+1 (646) 542 0667',
  'ZA': '+27 (0)21 201 7253',
  'GR': '+44 (0)203 8083 860'
  }


const CURRENCY_KEY = 'currency';
// const TRIP_CARD_COUCH_CARD_KEY = 'TRIP_CARD_COUCH_CARD_KEY';
// const TRIP_CUSTOMIZE_CARD_COUCH_CARD_KEY = 'TRIP_CUSTOMIZE_CARD_COUCH_CARD_KEY';

// these need to match order
// Not you can't sort this as the two need to march
export const AVAILABLE_CURRENCIES = ['aud', 'cad', 'chf', 'eur', 'gbp', 'hkd', 'jpy', 'nzd', 'sgd', 'usd', 'zar'] //No sort.. see comment above
export const CURRENCY_SYMBOLS = ['A&#36;', 'C&#36;', '&#8355;', '&#8364;', '&pound;', '&#165;', 'HK&#36;', 'S&#36;', 'NZ&#36;', '&#36;', 'R'];
export const CURRENCY_SYMBOLS_HASH = {
  'aud': 'A$',
  'cad': 'C$',
  'chf': '₣',
  'eur': '€',
  'gbp': '£',
  'hkd': 'HK$',
  'jpy': '¥',
  'nzd': 'NZ$',
  'sgd': 'S$',
  'usd': '$',
  'zar': 'R',
};

export const INCLUSION_TYPES = ['game_package', 'bed_breakfast', 'full_board', 'self_catering', 'half_board', 'other']

export default Service.extend({
  session: service(),
  store: service(),
  config: service(),
  feefo: service(),
  countryCode: null,
  defaultPhoneNum: null,
  showCookieConsentBanner: true, //Behaviour here is determined by whether the consent cookie has been set or not

  searchMode: 'loading',

  init() {
    let consultantId = getItemFromLocalStorage(LOCAL_STORAGE_CONSULTANT_ID_KEY);
    // Check for null here, as we had bug where `null` string was being written to local storage. Doing this quiclly
    // cause I'm at a conference but we should check for `null` string in the local storage reading writing convenience methods

    if (consultantId && consultantId != 'null' && !isNaN(consultantId) && consultantId != 'undefined') {
      this.set('consultantId', consultantId);
    } else {
      // Note we don't want to write this to local storage. So we can keep options open in terms of what default consultant to show
      this.set('consultantId', 1793);
    }

    let countryCode = getCookieValue('Timbuktu-Country-Code');
    let officeCode = countryOfficeMap[countryCode] || 'US';
    let investBannerUrl = investLandingPageMap[countryCode] || 'https://www.crowdcube.com/companies/timbuktu/pitches/qD02Vq';
    let defaultPhoneNum = officePhoneNumMap[officeCode];


    let consentCookie = getCookieValue(CONSENT_COOKIE_NAME);
    // console.log(consentCookie);
    if (consentCookie || this.config.preRendering) {
      this.set('showCookieConsentBanner', false);
    }

    this.setProperties({
      countryCode: countryCode,
      defaultPhoneNum: defaultPhoneNum,
      investBannerUrl
    });
    this.set('hubspotId', envConfig.APP.hubspotId);
  },

  updateConsultant(consultant) {
    if (consultant && consultant.get('id')) {
      this.set('consultantId', consultant.get('id'));
      setItemInLocalStorage(LOCAL_STORAGE_CONSULTANT_ID_KEY, consultant.get('id'));
    } else if (consultant.then) {
      consultant.then((resolvedConsultant) => {
        if (resolvedConsultant && resolvedConsultant.get('id')) {
          this.set('consultantId', resolvedConsultant.get('id'));
          setItemInLocalStorage(LOCAL_STORAGE_CONSULTANT_ID_KEY, resolvedConsultant.get('id'));
        }
      })
    }
  },

  @computed('consultantId', 'session.currentUser')
  consultant(consultantId, currentUser) {
    if (currentUser && currentUser.get('isManager')) {
      return currentUser;
    } else if (consultantId) {
      return this.get('store').peekRecord('user', consultantId);
    }
  },

  @computed()
  get currentCurrency() {
    let current = getItemFromLocalStorage(CURRENCY_KEY);
    if (!current || AVAILABLE_CURRENCIES.indexOf(current) === -1) {
      current = countryCurrencyMap[this.get('countryCode')] || 'usd'
      setItemInLocalStorage(CURRENCY_KEY, current);
    }
    return current;
  },
  set currentCurrency(val) {
    setItemInLocalStorage(CURRENCY_KEY, val);
    return val;
  },

  @computed('currentCurrency')
  currentCurrencySymbol(currentCurrency) {
    return CURRENCY_SYMBOLS[AVAILABLE_CURRENCIES.indexOf(currentCurrency)];
  },

  @computed('session.currentUser', 'session.currentUser.isManager')
  isNotManager(currentUser, isManager) {
    //non authenticated or logged in but not manager
    return !currentUser || (currentUser && !isManager);
  },

  @computed('feefo.feefoMeta')
  organizationSchema(feefoMeta) {
    if (!feefoMeta) {
      return;
    }
    return {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "Timbuktu",
      "url": "https://www.timbuktutravel.com",
      // "telephone": "+44 (0)203 8083 860",
      // "address": {
      //   "@type": "PostalAddress",
      //   "streetAddress": "Timbuktu Travel Limited, 31 Loop St",
      //   "addressLocality": "Cape Town, 8000",
      //   "addressCountry": "South Africa"
      // },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": parseFloat(feefoMeta.get('serviceRating')),
        "bestRating": 5,
        "worstRating": 1,
        "reviewCount": feefoMeta.get('totalReviews')
      }
    };
  }
});
