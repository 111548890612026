import {
  alias,
  uniqBy,
  mapBy
} from '@ember/object/computed';
import {
  not
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';
import {
  isEmpty,
  isPresent
} from '@ember/utils';
import DS from 'ember-data';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

const {
  PromiseArray
} = DS;

export default DS.Model.extend({

  name: DS.attr(),
  adminNote: DS.attr(),
  meta: DS.attr(),
  ratesBasedOnNumPeopleSharing: DS.attr(),

  partner: DS.belongsTo('partner', {
    inverse: null
  }),

  @computed('meta.rates.actual.[]')
  bookingDateRange(meta) {
    if (this.get('meta.rates.actual')) {
      let standardRates = this.get('meta.rates.actual').filterBy('tripRateType', 'standard');
      if (standardRates) {
        return 'from '+moment(standardRates.get('firstObject').validFromDate).format("D MMM [']YY") + ' to ' + moment(standardRates.get('lastObject').validToDate).format("D MMM [']YY")
      }
    }

  },




});
