import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from '@ember/object';
import {
  not
} from 'ember-decorators/object/computed';

export default Component.extend({
  whitelabel: service(),
  ui: service(),

  tagName: '',

  entityFavourites: computed('actualEntityType', 'session.currentUser.lodgeFavourites', 'session.currentUser.countryFavourites', 'session.currentUser.regionFavourites', 'session.currentUser.experienceFavourites', function() {
    return this.get(`session.currentUser.${this.actualEntityType}Favourites`);
  }),

  entityFavourite: computed('entity.id', 'entityFavourites.[]', function() {
    let favs = this.get('entityFavourites')
    if (favs) {
      return favs.findBy(`${this.actualEntityType}Id`, this.get('entity.id'));
    }
  }),

  userLikesEntity: computed('entityFavourite', 'session.currentUser', function() {
    return this.get('session.currentUser') && this.get('entityFavourite');
  }),

  actualEntityType: computed('entityType', function() {
    if (this.entityType=='tripSummary') {
      return 'trip';
    }
    return this.entityType;
  })

});
