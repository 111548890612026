import {
  inject as service
} from '@ember/service';
import {
  capitalize
} from '@ember/string';
import NavBarComponent from 'b5b/components/nav-bar/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  pluralize
} from 'ember-inflector';

export default NavBarComponent.extend({

  session: service(),
  scroll: service(),
  ui: service(),
  router: service(),
  whitelabel: service(),

  classNameBindings: [':main-agency-guest', 'isActive', 'session.currentUser.isManager', ':agency-nav'],

  tagName: 'nav',

  @alias('scroll.isMenuExpanded') isActive: false,

  @computed('router.currentRouteName')
  isInProfile(routeName) {
    return routeName.indexOf('users') === 0;
  },

  @computed('router.currentRouteName')
  showTripName(currentRouteName) {
    return false;
    // return  currentRouteName.indexOf('trip.index') > -1;
  },


  didRender() {
    this._super(...arguments);
    if (this.get('screen.isMobileOnly')) {
      // reset scroll for mobile
      this.get('scroll').to(0, this, null, null, this.$('.nav-items'));
    }
  },

  actions: {
    closeNav() {
      this.set('isActive', false);
    },

    toggleNav() {
      this.toggleProperty('isActive');
    },

    logout() {
      this.get('router').transitionTo('index');
      return this.get('session').invalidate();
    }

  }

});
