import Route from '@ember/routing/route';
import EntitiesCountriesRouteMixin from 'b5b/mixins/entities/countries/route';
import {
  trackEvent,
  generateMeta
} from 'b5b/utils';

export default Route.extend(EntitiesCountriesRouteMixin, {

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('countries:view');
  },

  headTags() {

    const title = this.whitelabel.isForTimbuktu ? `Timbuktu Travel - Holiday & Vacation Destinations` : this.whitelabel.agencySeoName;
    var description = 'Explore the far flung corners of the world with Timbuktu Travel. Discover the best holiday and vacation destinations in Africa, Asia and South America.';

    if (!this.isFastBoot) {
      document.title = title;
    }

    // let robots = !this.whitelabel.isForAgency && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let robots = !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    return generateMeta({
      description,
      'og:title': title,
      link: 'countries',
      robots
    }, this.whitelabel);
  },
});
