import xSuggestionsComponent from 'b5b/components/x-suggestions/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default xSuggestionsComponent.extend({
  session: service(),

  classNames: ['review'],
  suggestionTypes: ['review'],
  tripService: service('trip'),
  @alias('loadEntitiesTask') loadReviewsTask: null

});
