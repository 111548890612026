import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  observer
} from '@ember/object';

export default Helper.extend({
  tripService: service('trip'),

  settings: service(),
  onCurrencyChange: observer('settings.currentCurrency', function() {
    this.recompute();
  }),

  // Trip prices are used across the site and are characterised by not being rounded off or truncatd as the user needs to see an exact price
  // IN addition, if markPricesUp is not specified then it will be determined using tripService.useNewPricingModel
  // This is typically used anywhere that a price is shown that is related to a trip

  // You can optionally pass in the trip that the price refers to. If you don't it will use the tripService.currentTrip

  compute(params, namedArgs) {
    this.settings.currentCurrency; //Have to access this value in order for the recompute to trigger in the observer
    let tripPrice = this.tripService.getTripPrice(params, namedArgs);

    return htmlSafe(tripPrice);
  }

});
