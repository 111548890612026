import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  or,
  reads,
  alias
} from 'ember-decorators/object/computed';


export default Component.extend({

  classNameBindings: [':region-card', 'simple', 'keepMobileCard'],

  entityModals: service(),
  tripService: service('trip'),

  region: null,
  hideCountryName: false,
  hideLikeButton: false,
  openInModal: false, //Passed into region card config

  simple: false, // simplified version like on route index

  canSwipe: true,

  includeContextMenu: false,

  @reads('entityModals.onModal') onModal: false,
  @alias('tripService.changingRegion') changingRegion: false,
  @or('onModal', 'openInModal', 'changingRegion') modalOpener: null,
  onStage: false,

  mouseEnter() {
    if (this.get('mapHover')) {
      this.set('region.hover', true);
    }
  },

  mouseLeave() {
    if (this.get('mapHover')) {
      this.set('region.hover', false);
    }
  },

  actions: {
    openModal(region) {
      if (this.get('changingRegion')) {
        this.get('entityModals').addModalPermission('perm-add-region');
      }
      if (this.get('createStage')) {
        this.get('entityModals').addModalPermission('perm-create-stage');
      }

      if (this.modalOpener) {
        this.get('entityModals').openModal({
          model: region
        });
      }
    }
  }

});
