import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  computed
} from 'ember-decorators/object';


export default Controller.extend(UseCurrentTrip, TripSaver, {

  actions: {
    addVideo() {
      let newVideo = this.store.createRecord('video');
      newVideo.set('itinerary', this.tripService.get('currentTrip.itinerary'));
      this.tripService.get('currentTrip.itinerary.videos').pushObject(newVideo);
    },

    removeVideo(video) {
      this.tripService.get('currentTrip.itinerary.videos').removeObject(video);
    },    
    
    sortEndActionVideos() {
      this.tripService.get('currentTrip.itinerary.videos').forEach((video, index)=> {
        video.set('sequence', index);
      })
    }
  }
});
