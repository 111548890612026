import Component from '@ember/component';
import {
  run
} from '@ember/runloop';

export default Component.extend({

  classNameBindings: [':import-image'],
  
  didInsertElement() {
    this._super(...arguments);

    this.set('_loadedBound', run.bind(this, '_loaded', this));
    this.set('_errorBound', run.bind(this, '_error', this));
    this.set('imageTag', this.$('img')[0])
    this.$('img')[0].addEventListener('load', this.get('_loadedBound'));
    this.$('img')[0].addEventListener('error', (this.get('_loadedBound')));
    
   
  },

  willDestroyElement() {
    this.imageTag.removeEventListener('load', this.get('_loadedBound'));
    this.imageTag.removeEventListener('error', this.get('_errorBound'));
  },

  _loaded() {
    let w = this.imageTag.naturalWidth;
    let h = this.imageTag.naturalHeight;
    
    if (w < 1000 || h < 666) {
      this.set('importableImage.lowRes', true)
    }    
    this.set('dimensions', `${w}px x ${h}px`)
    this.set('importableImage.resolved', true)
    if (w < 200 || h < 200) {
      this.set('importableImage.available', false)      
    }
  },
  _error() {
    this.set('importableImage.resolved', true)
    this.set('importableImage.available', false)
  }
});
