import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  tripService: service('trip'),

  setupController() {
    trackEvent('trip:dashboard:deposit-success');
  },

  actions: {
    setProcessPath(path) {

      return this.controller.get('trip.itinerary').setProcessPath({
        process: path
      }).then((response) => {
        this.store.pushPayload(response);
      }).then(() => {
        this.transitionTo('trip.index.pay.success', this.controller.get('trip'));
      });
    }
  }

});