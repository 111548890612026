import Route from '@ember/routing/route';
import RSVP from 'rsvp';

import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  afterModel(model) {
    let asyncModels = RSVP.hash({
      guests: model.get('itinerary.guests')
    });
    asyncModels.then((asyncModels) => {
      model.set('guests', asyncModels.guests);
    });
  },

  setupController(controller, model) {
    // NB don't set trip here. It is injected into the controller
    controller.setProperties({
      guests: model.guests
    });
    trackEvent('trip:dashboard:invoice');
  }
});