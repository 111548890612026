import TWidgetComponent from 'b5b/components/t-widget/component';
import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

export default TWidgetComponent.extend({

  classNames: ['special'],

  ui: service(),
  router: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,

  // Power-selects seem to be unable to use integer 0 as a selected value by default
  guestAdults: '2',
  guestChildren: '0',
  guestInfants: '0',

  possibleAdults: ['1', '2', '3', '4', '5', '6'],
  possibleChildren: ['0', '1', '2', '3', '4', '5', '6'],
  possibleInfants: ['0', '1', '2', '3', '4', '5', '6'],

  actions: {
    setNumAdults(numAdults) {
      this.set('trip.itinerary.numAdults', numAdults);
      this.tripService.madeChanges();
    },
    setNumChildren(numChildren) {
      this.set('trip.itinerary.numChildren', numChildren);
      this.tripService.madeChanges();
    },
    setNumInfants(numInfants) {
      this.set('trip.itinerary.numInfants', numInfants);
      this.tripService.madeChanges();
    },
    openDatePicker(e) {
      this.tripService.tripDatePickerInstance.open();
      e.stopPropagation()
    },
  }

});
