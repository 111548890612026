import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['blog-post-list'],

  ui: service(),

  blogPosts: null,

  willDestroyElement() {
    // this.set('blogPosts', null);
  }
});
