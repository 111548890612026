import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';

export default DS.Model.extend(HasKodakImage, {

  name: DS.attr('string'),
  teaserText: DS.attr('string'),
  description: DS.attr('string'),

  latitude: DS.attr('string'),
  longitude: DS.attr('string'),

  region: DS.belongsTo('region', {
    async: false
  })

});
