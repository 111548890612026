import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({

  // experiences: DS.hasMany('experience', {
  //   async: false
  // }),

  eventType: DS.attr(),
  properties: DS.attr(),
  location: DS.attr(),
  allocatedEmail: DS.attr(),
  createdAt: DS.attr(),
  properties: DS.attr(),
  
  
  // Just commenting this out as we arent using this relationship
  // itinerary: DS.belongsTo('itinerary', {
  //   inverse: null,
  //   async: false
  // }),
  user: DS.belongsTo('user', {
    inverse: null,
    async: false
  }),  

  // @computed('experiences')
  // tripTypeExperiences(experiences) {
  //   return experiences.filterBy('isTripType')
  // }

});
