import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import { A } from '@ember/array';
import {
  alias
} from 'ember-decorators/object/computed';

export default Controller.extend({

  ui: service(),
  userData: 'en',
  advisorData: 'ad',
  addAdvisor: [{ 'code': 'ad', 'name': 'Add an advisor' }, { 'code': 'adb', 'name': 'Add an advisor' }],
  init() {
    this.set('context', A([{ 'code': 'ad', 'name': 'Add an advisor' }, { 'code': 'adb', 'name': 'Add an advisor' }]));
  },
  actions: {
    setUserData() {

    }
  }

});
