import Service from '@ember/service';
import {
    inject as service
} from '@ember/service';
import {
    getSortedTripIdeas
  } from 'b5b/utils';

export default Service.extend({

    tripService: service('trip'),
    store: service(),

    getLodgeIdeas(trip, stage, tripIdeasLodgesFirst=false, luxuryFirst=false) {

        if (!trip.tripIdeasLoaded) {
            console.log('THIS COMPONENT CAN ONLY BE INSERTED ONCE TRIP IDEAS LOADED')
        }

        let tripIdeas = trip.get('tripIdeas');
        let lodgesFromTripIdeas = [];
        let lodgeIdeas = [];
        let excludedLodgeIds = [];

        if (tripIdeas.length && tripIdeasLodgesFirst) {
            let sortedTripIdeas = getSortedTripIdeas(tripIdeas, 'tripIdeaType', luxuryFirst)
            sortedTripIdeas.forEach((idea) => {
                if (!idea) {
                    return
                }
                let lodge = idea.get('itinerary.stages').objectAt(stage.absoluteIndex).get('lodge');
                if (lodge) {
                    let lodgeIdea = {
                        budget: idea.get('tripIdeaType'),
                        lodge
                    }
                    lodgeIdeas.pushObject(lodgeIdea);
                    lodgesFromTripIdeas.pushObject(lodgeIdea);
                    excludedLodgeIds.pushObject(lodge.id);
                }
            })
        }

        let queryParams = this.tripService.getQueryParamsForChoosingLodge({ stage });

        const lodgeQueryPromise = this.store.query('lodge', {
            filters: JSON.stringify(queryParams.filters)
        }).then((lodges) => {
            lodges.forEach((lodge) => {
                if (!excludedLodgeIds.includes(lodge.id)) {
                    lodgeIdeas.pushObject({ lodge });
                }
            })
            return lodges;
        });

        return { lodgeIdeas, lodgesFromTripIdeas, lodgeQueryPromise };
    }



});
