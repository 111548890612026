import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';
import Component from '@ember/component';
import tripCarouselImages from '../../mixins/trip-carousel-images';
import RSVP from 'rsvp';
import {
  getTripImageFromRegionalImage
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNames: ['trip-images-manager'],
  store: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),

  didInsertElement() {
    this._super(...arguments);
  },
  didReceiveAttrs(){
    this._super(...arguments);
    if (this.get('tripCarouselImagesMaybePromise').then){
      this.get('tripCarouselImagesMaybePromise').then((images) => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }
        this.set('tripCarouselImages', images)
        // this.setupImages()
      })
    } else {
      this.set('tripCarouselImages',  this.get('tripCarouselImagesMaybePromise'))
      // this.setupImages()
    }
  },

});
