import Helper from '@ember/component/helper';

export default Helper.extend({
  compute(params) {

    let routeName = params[0].routeName;
    let queryParams = params[0].queryParams || {};
    let routeModel = params[0].routeModel || undefined;
    let link = [];

    link.pushObject(routeName);

    if (routeModel) {
      link.pushObject(routeModel);
    }

    if (queryParams) {
      link.pushObject({
        isQueryParams: true,
        values: queryParams
      });
    }

    return link;
  }
});
