import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';


export default Controller.extend(UseCurrentTrip, TripSaver, {

  tripService: service('trip'),
  ui: service(),
  
  @computed('trip.itinerary.guests.@each')
  guests(guests) {
    return guests.sortBy('name');
  },

  actions: {
    addGuest() {
      let newGuest = this.store.createRecord('guest');
      newGuest.set('ageType', 'adult');
      newGuest.set('name', `Guest ${this.tripService.get('currentTrip.itinerary.guests.length')+1}`)
      this.tripService.get('currentTrip.itinerary.guests').pushObject(newGuest);
      this.set('tripService.quoteGuestsChanged', true);
      this.set('tripService.quoteGuestsAdded', true);
      this.set('tripService.quoteGuestsRemoved', false);
    },

    removeGuest(guest) {
      let blocked = false;
      if (this.tripService.get('currentTrip.itinerary.quote.actualLineItems')) {
        this.tripService.get('currentTrip.itinerary.quote.actualLineItems').forEach((lineItem)=> {
          if (!blocked && lineItem.selectedGuests.includes(guest)) {
            this.ui.showGeneralMessage('Oops!', 'This guest is currently allocated to a line item. Please remove them from the line item before deleting this guest');
            blocked = true;
          }
        })
      }
      if (!blocked) {
        this.tripService.get('currentTrip.itinerary.guests').removeObject(guest);
        this.set('tripService.quoteGuestsChanged', true);
        this.set('tripService.quoteGuestsRemoved', true);
        this.set('tripService.quoteGuestsAdded', false);
      }
    },

    changeGuestAgeType(guest, ageType) {
      guest.set('ageType', ageType)
    }
  }

  // TODODEALS this is the version required to support guests on deals
  // actions: {
  //   addGuest() {
  //     let newGuest = this.store.createRecord('guest', {deal: this.tripService.get('currentTrip.deal')});
  //     let newItineraryGuest = this.store.createRecord('itinerary-guest', {guest: newGuest});
  //     newGuest.set('ageType', 'adult');
  //     newGuest.set('label', `Guest ${this.tripService.get('currentTrip.deal.guests.length')+1}`)
  //     this.tripService.get('currentTrip.itinerary.itineraryGuests').pushObject(newItineraryGuest);
  //   },

  //   removeGuest(itineraryGuest) {
  //     let blocked = false;
  //     if (this.tripService.get('currentTrip.itinerary.quote.actualLineItems')) {
  //       this.tripService.get('currentTrip.itinerary.quote.actualLineItems').forEach((lineItem)=> {
  //         if (!blocked && lineItem.selectedGuests.includes(itineraryGuest.guest)) {
  //           this.ui.showGeneralMessage('Oops!', 'This guest is currently allocated to a line item. Please remove them from the line item before deleting this guest');
  //           blocked = true;
  //         }
  //       })
  //     }
  //     if (!blocked) {
  //       this.tripService.get('currentTrip.itinerary.itineraryGuests').removeObject(itineraryGuest);
  //     }
  //   }
  // }
});
