import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

// TODODAY remove this component
export default Component.extend({
  classNames: ['add-what-you-do'],
  tripService: service('trip'),
  templateManager: service(),

  actions: {
  }
});
