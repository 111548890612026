import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  tagName: '',
  ui: service(),
  templateManager: service(),
  entityModals: service(),

  tripService: service('trip'),

  actions: {
   
  }
});
