import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['trip-tools-right-pane-add-transfers', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  templateManager: service(),
  entityModals: service(),
  store: service(),
  session: service(),
  tripService: service('trip'),
  dayExperiences: null,
  showCurrentUserTransfersOnly: false,
  showCurrentLodgeTransfersOnly: false,

  didInsertElement() {
    this._super(...arguments);
    this.get('loadTransfersTask').perform();
  },

  loadTransfersTask: task(function*() {
    let queryAttrs = this.templateManager.getQueryAttrsForStageTransferOptions(this.templateManager.currentStage)

    if (this.showCurrentUserTransfersOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }
    queryAttrs['consultantAndGuestTemplates']=true

    return this.store.query('transfer', queryAttrs).then((transfers)=>{
      this.set('transfers', transfers)
    })
  }).keepLatest()
});
