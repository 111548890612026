import Mixin from '@ember/object/mixin';
import {
  run
} from '@ember/runloop';

export default Mixin.create({
  triggerGallery: false,

  mouseEnter() {
    if (!this.loadImages){
      this.set('loadImages', true);
    }

    this.startTimer = run.next(() =>{
      this.set('triggerGallery', true);
    });
  },

  mouseLeave() {
    this.endTimer = run.next(() =>{
      this.set('triggerGallery', false);
    });
  },

  willDestroyElement(){
    this._super(...arguments);
    run.cancel(this.startTimer);
    run.cancel(this.endTimer)
  }
});
