import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';
import get from 'ember-metal/get';

const DIGIT_REGEX = /^\d+$/;

export default Mixin.create({

  store: service(),
  tripService : service('trip'),

  serialize(model, params) {
    // Override the default serialize method as it seems that it can't reseolve the modelNameFriendlyId aliases as properties on the model
    let dynamicSegmentName = params[0];
    let serializedRepresentation = {};
    serializedRepresentation[dynamicSegmentName] = get(model, dynamicSegmentName);
    return serializedRepresentation
  },

  model(params) {
    let modelName = this.modelName;

    if (!modelName) {
      modelName = this.routeName;
      let splits = modelName.split('.');
      if (splits.length > 1) {
        modelName = splits[splits.length - 1];
      }
    }

    let id = params[modelName + 'FriendlyId'];
    let searchKey = DIGIT_REGEX.test(id) ? 'id' : 'friendlyId';

    if (modelName=='trip' && id=='my-new-trip' && this.get('tripService.currentTrip')) {
      return this.tripService.currentTrip;
    }

    if (modelName!=='trip') {
      let modelFromStore = this.store.peekAll(modelName).findBy(searchKey, id);
      if (modelFromStore) {
        return modelFromStore;
      }
    }

    let queryCriteria = {
      id
    };
    // note we can't refer to buster query param on application controller as it won't have been processed yet. routes work from outside in
    if (window && window.location && window.location.href.indexOf('buster=true') >= 0 || !isNaN(id)) {
      queryCriteria.buster = true;
    }

    if (this.modelQueries) {
      // keep in mind this is done even if a empty hash is passed in
      Object.assign(queryCriteria, this.modelQueries(params));
    }


    if (queryCriteria.includeSpecifiedTripIdea) {
      return this.store.query(modelName, queryCriteria).then((trips)=> {
        // You can't just filter these by the supplied searchKey. For example the friendlyId of the trip might ahve changed but rails will still find
        // the trip based on its historical slug but the returned friendly id won't match the friendlyId from the url
        let route = trips.findBy('isRoute', true);
        if (route) {
          return route;
        } else {
          return trips.objectAt(0);
        }
      })
    } else {
      return this.store.queryRecord(modelName, queryCriteria);
    }
  }

});
