import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({

  ui:service(),
  classNames: ['youtube-player'],  //
  manualPlayIndex: 0,
  reportedBadVideo: false,

  init() {
    this._super(...arguments);
    this.set('playerId', `player${new Date().getTime()}`);
    if (this.url) {

      let youtubeId = null;    
      let rIndex = this.url.lastIndexOf("v=")
    
      if (rIndex > 0) {
        youtubeId = this.url.substring(rIndex + 2, rIndex + 13)
      } else {
        rIndex = this.url.lastIndexOf("/")
        youtubeId = this.url.substring(rIndex + 1, this.url.length)
      }
                        
      this.set('videos', [youtubeId])
    }
    if (this.get('videos.length') > 1)  {
      this.set('multipleVideos', true);
    } else {
      this.set('singleVideoUrl', `https://www.youtube.com/embed/${this.videos[0]}`)
    }
  },

  didInsertElement() {
    let videoIds = this.videos;

    let context = this;
    let player = null;

    function playNextVideoManually() {
      if (context.manualPlayIndex == context.videos.length) {
        context.manualPlayIndex = 0;  
      }
      player.loadVideoById (context.videos[context.manualPlayIndex]);
      context.manualPlayIndex = context.manualPlayIndex+1;
  
    }

    function onPlayerReady(event) {
      // console.log('onPlayerReady', event)
      event.target.cuePlaylist(videoIds);
    }
    function onPlayerStateChange(event) {
      // console.log('onPlayerStateChange', event)   
      if (event.data == YT.PlayerState.ENDED) {   
        playNextVideoManually();
      }       
    }

    function onPlayerError(event) {
      // console.log('onPlayerError', event) 
      let message = window.location.href +"\r\n";
      message = message + `error code: ${event.data}\r\n`;
      message = message + JSON.stringify(videoIds);

      if (!context.reportedBadVideo && context.lodge) {
        context.lodge.reportBadVideo({message: message})
        context.reportedBadVideo = true;
      }
      playNextVideoManually();
    }

    if (this.multipleVideos) {
      this.ui.getYoutubeReadyPromise().then(()=> {
        // console.log('player ready')
        player = new YT.Player(this.playerId, {
          height: '432',
          width: '768',
          events: {
            'onReady': onPlayerReady,
            'onError': onPlayerError,
            'onStateChange': onPlayerStateChange
          }
        });      
      })
    }    
  },


});
