import {
  computed
} from 'ember-decorators/object';
import {
  equal
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import Self from 'b5b/models/template';


export default DS.Model.extend({
  internalName: DS.attr(),
  overview: DS.attr(),
  templateType: DS.attr(),
  polished: DS.attr(),
  isMaster: DS.attr(),
  region: DS.belongsTo('region', {inverse: null, async: false}),
  country: DS.belongsTo('country', {inverse: null, async: false}),
  location: DS.belongsTo('location', {inverse: null, async: false}),
  lodge: DS.belongsTo('lodge', {inverse: null, async: false}),
  originalUser: DS.belongsTo('user', {inverse: null, async: false}),
  updatedAt: DS.attr('b5bdate'),

  @computed('region', 'lodge')
  entity(region, lodge) {
    return lodge || region;
  },


});
