export function initialize(application) {

  if (typeof FastBoot !== 'undefined') {
    return;
  }

  application.inject('controller', 'config', 'service:config');
  application.inject('controller', 'session', 'service:session');
  application.inject('component', 'session', 'service:session');
  application.inject('route', 'session', 'service:session');
  application.inject('route', 'config', 'service:config');
  application.inject('component', 'config', 'service:config');
  application.inject('component', 'settings', 'service:settings');
  application.inject('controller', 'settings', 'service:settings');

  application.inject('controller', 'whitelabel', 'service:whitelabel');
  application.inject('route', 'whitelabel', 'service:whitelabel');
  application.inject('component', 'whitelabel', 'service:whitelabel');

  application.inject('controller', 'ui', 'service:ui');
  application.inject('route', 'ui', 'service:ui');
  application.inject('component', 'ui', 'service:ui');


  application.inject('component', 'store', 'service:store');
}

export default {
  name: 'inject-filter-options',
  initialize: initialize
};
