import Component from '@ember/component';

export default Component.extend({
  classNameBindings: ['copyToClipboardVersion', ':trip-idea-block'],

  click(e){
    if (this.onClick){
      e.stopPropagation();
      this.onClick();
    }
  }

});
