import { helper } from '@ember/component/helper';
import {
  scheduleHeading
} from 'b5b/utils';

export function scheduleHeadingHelper(params, namedArgs) {
  return scheduleHeading(namedArgs.stateBeforeQuote, namedArgs.scheduleHeadingTripIdeas, namedArgs.scheduleHeadingQuotes, namedArgs.tripScheduleHeading, namedArgs.scheduleHeading);
}

export default helper(scheduleHeadingHelper);
