import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNames: ['my-trip'],

  router: service(),
  entityModals: service(),
  tripService: service('trip'),

  tripSummary: null,

  didReceiveAttrs(){
    this._super(...arguments);
    // We do this to trigger the CP which sets firstRegionImage and secondRegionImage properties
    this.set('images', this.tripSummary.get('tripCarouselImages'))
  },


  @computed('tripSummary.processPathSteps')
  nextCurrentStep(processPathSteps) {
    if (processPathSteps) {
      return processPathSteps.filterBy('current') ? processPathSteps.filterBy('current')[0] : [];
    }
  },

  click({
    target
  }) {
    let $el = $(target);
    if (!$el.parents('.button,.pill-tags,.remove-trip')[0] && !$el.hasClass('remove-trip')) {
      if (this.get('showTrip')) {
        this.get('showTrip')();
      }
    }
  },

  actions: {
    showCountryModal(country) {
      this.get('entityModals').openModal({
        model: country
      });
    },
    previewTrip() {
      this.set('tripService.tripPreviewModal', {
        trip: this.store.queryRecord('trip', {id: this.tripSummary.tripFriendlyId}),
        view: 'list'
      });
    }    
  }

});
