import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  alias,
  or
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import {
  trackEvent,
  getDurationForNumNights
} from 'b5b/utils';

import TripSaver from 'b5b/mixins/trip-saver';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

export default Controller.extend(TripSaver, UseCurrentTrip, {

  queryParams: [{
    allocatedEmail: {
      as: 'allocatedEmail'
    },
    allocatedFirstName: {
      as: 'allocatedFirstName'
    },
    allocatedLastName: {
      as: 'allocatedLastName'
    },
    selectedTripIdea: {
      as: 'tripIdea'
    },
    startDate: {}
  }],

  entityModals: service(),
  ui: service(),
  scroll: service(),
  settings: service(),
  store: service(),
  session: service(),
  affiliate: service(),
  tripService: service('trip'),
  templateManager: service(),
  router: service(),
  historyService: service(),

  @alias('tripService.savingTrip') savingTrip: false,
  @alias('tripService.showTripLockedModal') showTripLockedModal: false,
  @alias('tripService.showTripNamingModal') showTripNamingModal: false,
  @alias('tripService.showSaveTripModalAfterLogin') showSaveTripModalAfterLogin: false,
  @alias('tripService.tripToBeSavedAfterLogin') tripToBeSavedAfterLogin: false,
  @alias('tripService.unsavedTripChangesTransition') unsavedTripChangesTransition: null,
  @or('trip.itinerary.quote.settlementCurrency', 'settings.currentCurrency') bankAccountCurrency: false,

  showBankTransferModal: false,
  showDurationModal: false,

  newStageNumNights: 3,


  showUnsavedTripChangesModal: false,
  ignoreUnsavedChangesOnExit: false,

  modelToUpdate: null,
  suggestionField: null,
  showTextSuggestionsModal: false,


  trackQuoteViews() {
    this.get('session.currentUserPromise').then((currentUser) => {

      if (!this.get('tripService.currentTrip.quoteViewReported') && this.get('tripService.currentTrip.itinerary.stateQuote') && currentUser && this.get('tripService.currentTripUserEmails').includes(currentUser.get('email')) && !currentUser.get('isManager')) {
        trackEvent('trip:quote');
        this.get('affiliate').trackQuoteView(this.get('tripService.currentTrip'));
        this.set('tripService.currentTrip.quoteViewReported', true);
      }
    });
  },

  @computed('trip.tripLength')
  duration(tripLength) {
    return getDurationForNumNights(tripLength)
  },

  @computed('trip.contractingEntity', 'bankAccountCurrency')
  bankAccountsForSettlementCurrency(tripContractingEntity, bankAccountCurrency) {
    return tripContractingEntity.bankAccountsJson.filterBy("currency", bankAccountCurrency)
  },


  startSortIndex: null,

  actions: {

    resolveRegionMismatch(options) {
      options.stage.set('region', null)
      this.set('ui.regionMismatchModalOptions', false)
    },

    sortStartAction(stageIndex) {
      this.set('startSortIndex', stageIndex);

      this.get('trip.itinerary.stages').forEach((stage) => {
        stage.set('stageExpanded', false);
      });

      return true;
    },

    sortEndAction(stage) {
      // only do this if a stage was actually moved
      if (this.get('trip.itinerary.stages').indexOf(stage) !== this.startSortIndex){
        // fake prop which we use to determine dirty transfers with
        stage.set('madeTransferAffectingChanges', true);
        // set swopped stage to dirty as well
        this.get('trip.itinerary.stages').objectAt(this.startSortIndex).set('madeTransferAffectingChanges', true);
        this.set('startSortIndex', null);
      }

      this.tripService.madeChanges();
      return true;
    },

    createStage(options) {
      this.set('ui.showNewStageModal', false);
      this.set('tripService.tripPreviewModal', null);
      this.get('tripService').createStage(options)
    },

    logout() {
      trackEvent('trip:logout');
      this.set('session.afterLogoutUrl', window.location.href);
      return this.get('session').invalidate();
    },

    // runClosureAndTransitionToTripAuthentication
    login(closure) {
      closure();
      this.set('ui.showLoginModal', true);

      // this.transitionToRoute('trip.index.quote', this.get('tripService.currentTrip'));
    },

    incrementStageNights() {
      this.incrementProperty('newStageNumNights');
    },

    decrementStageNights() {
      if (this.get('newStageNumNights') > 1) {
        this.decrementProperty('newStageNumNights');
      }
    },

    saveChangesAndExit(hash) {
      this.setProperties({
        showUnsavedTripChangesModal: false
      });

      if (this.get('session.isAuthenticated')) {
        this.get('tripService').saveChanges(hash)
        this.setProperties({
          ignoreUnsavedChangesOnExit: true
        });


        // if we need to, rather exit the suspension
        if (this.get('unsavedTripChangesTransition')['suspension']) {
          return this.get('historyService').exitSuspension(this.get('unsavedTripChangesTransition')['suspension'].name)
        }

        this.get('unsavedTripChangesTransition').retry();
      } else {
        this.set('tripToBeSavedAfterLogin', this.get('tripService.currentTrip'));
        this.set('ui.showRegisterModal', true);
      }

    },

    dontSaveChangesAndExit() {
      this.setProperties({
        showUnsavedTripChangesModal: false,
        ignoreUnsavedChangesOnExit: true
      });


      // if we need to, rather exit the suspension
      if (this.get('unsavedTripChangesTransition')['suspension']) {
        return this.get('historyService').exitSuspension(this.get('unsavedTripChangesTransition')['suspension'].name)
      }

      this.get('unsavedTripChangesTransition').retry();
      this.set('tripService.currentTrip', null);

    },
  }

});
