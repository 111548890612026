import {
  inject as service
} from '@ember/service';
import NavBarComponent from 'b5b/components/nav-bar/component';
import {
  computed
} from 'ember-decorators/object';
import {
  htmlSafe
} from '@ember/string';

export default NavBarComponent.extend({
  classNameBindings: [':side', 'ui.sideNavActive:is-active', 'ui.sideNavActive:-block' , 'ui.displayOnSideBar'],

  scroll: service(),
  ui: service(),

  @computed('ui.agencyTopNavHeight')
  sideNavStyle (agencyTopNavHeight) {
    return htmlSafe(`padding-top: ${agencyTopNavHeight - 22}px`);
  },
  
  willDestroyElement() {
    this._super(...arguments);
    this.set('ui.sideNavActive', false);
  },

  actions: {
    toggleNav() {
      this.toggleProperty('ui.sideNavActive');
    },
    
    closeNav() {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      this.set('ui.sideNavActive', false);
    }
  }
});
