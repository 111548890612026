import DS from 'ember-data';
import { ActiveModelSerializer } from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    blocks: {
      serialize: 'records'
    },
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards
    json.day_schedules_attributes = json.day_schedules;
    delete json.day_schedules;
    json.blocks_attributes = json.blocks;
    delete json.blocks;


    return json;
  }
});
