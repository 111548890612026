import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  trim
} from 'b5b/utils';

export function trimText([val, len, wordCut = false]) {
  return trim(val, len, wordCut);
}

export default buildHelper(trimText);
