import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';
import {
  teamSort
} from 'b5b/utils';

export default Controller.extend({

  settings: service(),
  ui: service(),

  @computed('users.@each')
  teamCouples(users) {
    let team  = users.toArray().sort(teamSort);    
    let couples = [];
    for (var i = 0; i < team.get('length') / 2; i++) {
      couples.pushObject([team.objectAt(i * 2), team.objectAt(i * 2 + 1)]);
    }
    return couples;
  }

});