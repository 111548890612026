import Route from '@ember/routing/route';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  setupController(controller, model) {
    this._super(...arguments);

    controller.set('country', model);
    controller.set('loading', true);

    this.store.query('blog-post', {
      per_page: 60,
      selectedCountryNames: [model.get('name')]
    }).then((blogPosts) => {
      controller.set('blogPosts', blogPosts);
      controller.set('loading', false);
    });

  },

  headTags() {

    let model = this.currentModel;
    let title = model.get('name');
    if (this.whitelabel.isForTimbuktu) {

      if (model.isSafari) {
        title = 'Safari blog articles';
      } else {
        title = 'Blog articles';
      }
      title += ` about ${model.name}`;
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }
    var description = `Browse our ${model.isSafari ? 'safari ' : ''}blog articles about ${model.name}. Explore our fully customizable trip ideas and our travel experts to give you all the help and advice you need.`;
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;
    return generateMeta({
      description,
      'og:title': title,
      link,
      robots
    });
  },

});
