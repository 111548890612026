import Route from '@ember/routing/route';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend({

  model() {
    return this.modelFor('when-to-go');
  },

  headTags() {

    const title = `When to go to Africa | ${this.whitelabel.agencySeoName}`;
    var description = 'A month by month guide to the highlights on the continent';

    if (!this.isFastBoot) {
      document.title = title;
    }
    return generateMeta({
      description,
      'og:title': title,
      link: 'when-to-go'
    });
  }

});
