import {
  inject as service
} from '@ember/service';
import {
  setupTripEntityNav
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

import Route from '@ember/routing/route';

export default Route.extend({

  ui: service(),
  store: service(),
  session: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') currentTrip: false,

  setupController(controller, model) {
    this._super(...arguments);

    setupTripEntityNav(model, this.get('tripService'), this.get('ui'), this.get('session'));
    this.tripService.prepareVideos()
    // TODOSUMMARY remove
    // this.tripService.addDayByDaySupport(this.tripService.currentTrip)

    trackEvent('trip:view', {
      trip: this.get('currentTrip.id'),
      type: this.get('currentTrip.tripType'),
      state: this.get('currentTrip.itinerary.state')
    });

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set('ui.entityNavData', null);
    }
  }

});
