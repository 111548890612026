import Mixin from '@ember/object/mixin';
import {
  computed
} from 'ember-decorators/object';

// This is used on itineraries and also trip-summaries

export default Mixin.create({

  @computed('pricingModel')
  useNewPricingModel(pricingModel) {
    if (pricingModel == 1) {
      return false
    }
    // Default true, unless the pricing model is defo equal to 1
    return true;
  }

});
