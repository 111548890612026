import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import TripEditorController from 'b5b/mixins/trip-editor/controller';

export default Controller.extend(UseCurrentTrip, TripSaver, TripEditorController, {

  tripService: service('trip'),

  @alias('tripService.currentStageIndex') stageIndex: null,
  @alias('tripService.numNights') numNights: null,
  @alias('tripService.dontBook') dontBook: null,
  queryParams: ['scrollToStage', 'blockIndex'],

  @computed('tripService.currentTrip.itinerary.accomStages.@each.customSchedule')
  allCustomSchedulesAdded(stages) {
    return stages.reduce(function(customSchedulePresentThisFar, stage) {
      return customSchedulePresentThisFar && stage.get('customSchedule');
    }, true);
  }, 

});
