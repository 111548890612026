import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  observes
} from 'ember-decorators/object';
import {
  not
} from 'ember-decorators/object/computed';
export const CONSENT_COOKIE_NAME = "_iub_cs-44323705";

export default Component.extend({

  tagName: '',
  @not('whitelabel.isForAgency') isVisible: false,
  // This is what the actual iubenda cookie looks like
  // _iub_cs-44323705=%7B%22consent%22%3Atrue%2C%22timestamp%22%3A%222018-07-24T15%3A22%3A06.197Z%22%2C%22version%22%3A%220.14.10%22%2C%22id%22%3A44323705%7D

  settings: service(),
  scroll: service(),
  whitelabel: service(),

  init() {
    this._super(...arguments);
    // We need to get the value of scroll.isCTABarVisible here so that the observer actually watches the value.. otherwise it doesn't fire
    this.get('scroll.isCTABarVisible');
  },

  consentCaptured() {
    if (typeof document !== 'undefined') {
      const DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSS';
      let consentDate = moment().format(DATE_FORMAT) + 'Z';
      let consentInfo = `{"consent":true,"timestamp":"${consentDate}","version":"0.14.10","id":44323705}`;
      let cookieValue = `${CONSENT_COOKIE_NAME}=${encodeURIComponent(consentInfo)}`;
      document.cookie = cookieValue;
      this.set('settings.showCookieConsentBanner', false);
    }
  },

  // Note this component is not inserted into DOM or removed from DOM once consent cookie is captured
  @observes('scroll.isCTABarVisible')
  captureConsentOnScroll() {
    if (this.get('scroll.isCTABarVisible')) {
      this.consentCaptured();
    }
  },

  actions: {

    // Probably a bad idea to write their cookie.. so maybe jsut create somethign else.. If people see the banner briefly on blog so what
    closeBanner() {
      this.consentCaptured();
    }

  }
});
