import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNameBindings: [':step', 'completed', 'current'],

  trip: null,
  index: 0,
  step: null,

  @computed('index')
  stepIndex(index) {
    return index + 1;
  },

  @computed('step.current')
  current(stepCurrent) {
    return stepCurrent;
  },

  @computed('step.completed')
  completed(stepCompleted) {
    return stepCompleted;
  }

});