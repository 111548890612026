import { helper } from '@ember/component/helper';

export function stringifyDayAfterStart([val]) {
  if (val===null || val===undefined) {
    return 'No day';
  } else if (val===0) {
    return '0';
  } else if (val) {
    return val.toString();
  }
}

export default helper(stringifyDayAfterStart);
