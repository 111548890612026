import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';
import {
  AVAILABLE_CURRENCIES,
} from 'b5b/services/settings';

export default Component.extend({

  classNames: ['experience-form'],

  router: service(),
  store: service(),
  cache: service(),
  whitelabel: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.editEntityModalOptions') editEntityModalOptions: null,
  @alias('tripService.savingCustomEntity') savingCustomEntity: false,

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },
  


});
