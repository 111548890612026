import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';
import {
  computed
} from 'ember-decorators/object';

export default Mixin.create({

  uniqueCountryIds: null,

  @computed('trip.itinerary.stages.length', 'uniqueCountryIds')
  tripHasContext(stagesLength, uniqueCountryIds) {
    return stagesLength > 0 || (uniqueCountryIds && uniqueCountryIds.length > 0);
  },


});
