import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

import {
  divIcon
} from 'ember-leaflet/helpers/div-icon';

const redMarker = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Oval Copy 3</title><defs><circle id="b" cx="12" cy="12" r="8"/><filter x="-37.5%" y="-37.5%" width="175%" height="175%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><circle stroke="#E52543" stroke-width="3" stroke-linejoin="square" fill="#FFF" cx="12" cy="12" r="6.5"/></g></svg>';
// const redMarkerHover = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Oval Copy 3</title><defs><circle id="b" cx="12" cy="12" r="8"/><filter x="-37.5%" y="-37.5%" width="175%" height="175%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><circle stroke="#860c1f" stroke-width="3" stroke-linejoin="square" fill="#FFF" cx="12" cy="12" r="6.5"/></g></svg>';

const greenMarker = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Oval Copy 3</title><defs><circle id="b" cx="12" cy="12" r="8"/><filter x="-37.5%" y="-37.5%" width="175%" height="175%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><circle stroke="#128D8D" stroke-width="3" stroke-linejoin="square" fill="#FFF" cx="12" cy="12" r="6.5"/></g></svg>';
// const greenMarkerHover = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Oval Copy 3</title><defs><circle id="b" cx="12" cy="12" r="8"/><filter x="-37.5%" y="-37.5%" width="175%" height="175%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><use fill="#000" filter="url(#a)" xlink:href="#b"/><circle stroke="#355461" stroke-width="3" stroke-linejoin="square" fill="#FFF" cx="12" cy="12" r="6.5"/></g></svg>';

const lodgeMarker = '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>inverse lodge</title><defs><circle id="b" cx="12" cy="12" r="12"/><filter x="-25%" y="-25%" width="150%" height="150%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><g transform="translate(4 4)"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#128D87" xlink:href="#b"/></g><path d="M15.547 11.403l-3.217 2.899a1 1 0 0 0-.33.743V19a1 1 0 0 0 1 1h.809a1 1 0 0 0 1-1v-1.783a1 1 0 0 1 1-1h.815a1 1 0 0 1 1 1V19a1 1 0 0 0 1 1h.808a1 1 0 0 0 1-1v-3.955a1 1 0 0 0-.33-.743l-3.216-2.899a1 1 0 0 0-1.34 0z" fill="#FFFFFF" fill-rule="nonzero"/></g></svg>';
const lodgeMarkerHover = '<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>inverse lodge</title><defs><circle id="b" cx="12" cy="12" r="12"/><filter x="-25%" y="-25%" width="150%" height="150%" filterUnits="objectBoundingBox" id="a"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.348420516 0" in="shadowBlurOuter1"/></filter></defs><g fill="none" fill-rule="evenodd"><g transform="translate(4 4)"><use fill="#000" filter="url(#a)" xlink:href="#b"/><use fill="#FFF" xlink:href="#b"/></g><path d="M15.547 11.403l-3.217 2.899a1 1 0 0 0-.33.743V19a1 1 0 0 0 1 1h.809a1 1 0 0 0 1-1v-1.783a1 1 0 0 1 1-1h.815a1 1 0 0 1 1 1V19a1 1 0 0 0 1 1h.808a1 1 0 0 0 1-1v-3.955a1 1 0 0 0-.33-.743l-3.216-2.899a1 1 0 0 0-1.34 0z" fill="#128D87" fill-rule="nonzero"/></g></svg>'

export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  // compute([model, currentStage], namedArgs) {
  compute(params, namedArgs) {
    // default pin in a modal is the red one
    if (!namedArgs.entity || namedArgs.defaultPin) {
      return divIcon(null, {
        className: 'pin-marker red',
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        html: redMarker
      });
    }

    let classNames, anchors, sizes, html,
      model = namedArgs.entity,
      hoverState = namedArgs.hover,
      currentStage = this.get('tripService.currentStage');

    switch (model.get('constructor.modelName')) {
      case 'stage':

        return divIcon(null, {
          className: (model.get('isLodgeStage') || model.get('isRegionStage') ? 'stage-marker' : 'stage-marker location') + (hoverState ? ' hover' : ''),
          iconSize: [14, 14],
          html: '<div></div>'
        });

      case 'lodge':
        if (model.get('fromPrice') && !namedArgs.hidePrice) {

          let priceHash = {
            lodge: model,
            lodgeAccuratePriceDate: model.accuratePriceDate,
            lodgeStartDate: model.startDate,
            lodgeAccuratePriceAmount: model.accuratePriceAmount,
            lodgeAccuratePriceCurrency: model.accuratePriceCurrency,
            excludePersonPerNight: true
          }

          html = this.tripService.getLodgePrice(null, priceHash);
          classNames = 'price-marker';
          anchors = [37, 34];
          sizes = [74, 27];
        } else {
          classNames = 'pin-marker';
          anchors = [12, 12],
            sizes = [24, 24],
            html = hoverState ? lodgeMarkerHover : lodgeMarker;
        }

        classNames += hoverState ? ' hover' : '';
        classNames += currentStage ? ((model.get('id') === currentStage.get('lodge.id') ? ' current' : '')) : '';

        return divIcon(null, {
          className: classNames,
          iconAnchor: anchors,
          iconSize: sizes,
          html: html
        });


      case 'country':
      case 'region':
      case 'experience':
        classNames = 'pin-marker red' + (hoverState ? ' hover' : '');
        anchors = [12, 12],
          sizes = [24, 24],
          html = hoverState ? greenMarker : redMarker;


        return divIcon(null, {
          className: classNames,
          iconAnchor: anchors,
          iconSize: sizes,
          html: html
        });

      default:
        return divIcon(null, {
          className: 'pin-marker red',
          iconSize: [24, 24],
          iconAnchor: [12, 12],
          html: redMarker
        });
    }

  }
});
