import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import ListenForFacebookAuth from 'b5b/mixins/listen-for-facebook-auth';
import {
  safeVoid0
} from 'b5b/utils';

export default Component.extend(ListenForFacebookAuth, {
  classNames: ['auth-form'],

  safeVoid0,
  whitelabel: service(),
  session: service(),
  router: service(),
  store: service(),
  ui: service(),

  onToggle: null,
  showErrors: false,
  errors: {},
  saving: false,

  allocatedFirstName: null,
  allocatedLastName: null,
  allocatedEmail: null,

  didInsertElement() {
    this._super(...arguments);

    // don override redirect after login if it exists
    if (this.get('session.attemptedTransition')) {
      this.set('redirectRouteName', null);
    }

    this.setProperties({
      firstName: this.allocatedFirstName,
      lastName: this.allocatedLastName,
      email: this.allocatedEmail
    });
  },

  genericUserAuthenticated() {
    //Generic function to show that user has logged in, can be called from succesful username/password login
    //or through facebook auth mixin
    // Please ntoe that redirecting to original url and default login behaviour is already covered

    // Disable showing the screen asking people if they want to sign up for the newsletter as all emails being harvested are being automatically added to the newsletter
    // this.get('session.currentUserPromise').then(() => {
    //   this.ui.activateFullScreenEmailSubscription();
    // });


    if (this.authenticated) {
      this.authenticated();
    } else if (this.redirectRouteName) {
      // Redirect after auth if a redirectRouteName is supplied. This wouln't happen if a a modal is used for auth
      this.router.transitionTo(this.redirectRouteName);
    }
  },

  actions: {
    onToggle() {
      if (this.onToggle) {
        this.onToggle();
      }
    },
    onPasswordReset() {
      this.set('ui.userResetEmail', this.email);
      if (this.onPasswordReset) {
        this.onPasswordReset();
      }
    }

  }

});
