import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: ['share-priceless'],

  ui: service(),

  name: null,

});
