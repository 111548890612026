import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  pluralize
} from 'ember-inflector';

export function pluralise(params, namedArgs) {
  var num = namedArgs.num;
  var singular = namedArgs.singular;
  var plural = namedArgs.plural; // for specific cases

  var output = namedArgs.excludeNum ? '' : num + ' ';

  if (num > 1 || num === 0) {
    output += plural ? plural : pluralize(singular);
  } else {
    output += singular;
  }
  return output;
}

export default buildHelper(pluralise);
