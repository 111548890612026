import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  humanReadableList
} from 'b5b/utils';
import {
  htmlSafe
} from '@ember/template';

export function limitedList(params, namedArgs /* listItems limit style*/ ) {
  let output = "";
  let limit = namedArgs.limit ? namedArgs.limit : 3;

  if (namedArgs.listItems.length > limit) {
    for (var i = limit - 1; i >= 0; i--) {
      output += `${namedArgs.listItems[i]} `;
    }
    if (namedArgs.style) {
      output += `<span class="limited-list">+ ${namedArgs.listItems.length - limit} more</span>`;
    } else {
      output += `+ ${namedArgs.listItems.length - limit} more`;
    }
  } else {
    output = humanReadableList(namedArgs.listItems);
  }

  return htmlSafe(output);
}

export default buildHelper(limitedList);
