define('ember-picturefill/components/pf-img', ['exports', 'ember'], function (exports, _ember) {
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    tagName: 'img',

    picturefill: service(),

    init: function init() {
      var attributes = A(Object.keys(this.attrs)).without('class');
      set(this, 'attributeBindings', attributes);

      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      var el = get(this, 'element');
      get(this, 'picturefill').enqueue(el);
    }
  });
});