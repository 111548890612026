import { helper } from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';


export function verySimpleFormat([val], namedArgs) {

  var string = Ember.Handlebars.Utils.escapeExpression(val);
  string = string.replace(/\r\n?/g, "\n").trim();
  if (string.length > 0) {
    string = string.replace(/\n/g, '<br />');
    return htmlSafe(string);
  }
  return htmlSafe("");
}

export default helper(verySimpleFormat);
