import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';


export function historicChargeAmount(params, namedArgs) {
  let charge = namedArgs.charge;

  if (!charge) {
    return '' ;
  }


  // We always use the real amount.. not the preferred currency amount

  return htmlSafe(formatCurrencyHash({
    val: charge.get('amount'),
    fromCurrency: charge.get('currency'),
    toCurrency: charge.get('currency'),
    roundToClosest: 0,
    isText: false,
    truncate: false,
    markPricesUp: false
  }));


}

export default buildHelper(historicChargeAmount);
