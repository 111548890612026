import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNameBindings: [':whats-next-cta', ':guide-block', ':has-specialist', 'trip.itinerary.state'],

  ui: service(),
  router: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: false

});
