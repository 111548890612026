import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import stage from '../../models/stage';

export default Component.extend({
  classNames: ['stage-details-row'],

  tripService: service('trip'),
  entityModals: service(),
  router: service(),
  ui: service(),

  stage: null,
  index: null,
  trip: null,

  @computed('index', 'stage.stageType')
  isFirstStage(index, stageType) {
    return index === 0 && stageType == 'location';
  },

  @computed('index', 'trip.itinerary.stages.[]', 'stage.stageType')
  isLastStage(index, stages, stageType) {
    return index === (stages.get('length') - 1)  && stageType == 'location';
  },

  actions: {
    openModal() {
      let model = (this.get('isFirstStage') || this.get('isLastStage')) ? this.get('stage.location.region') : this.get('stage.lodge') ? this.get('stage.lodge') : this.get('stage.regionProxy');
      if (model) {
        this.get('entityModals').openModal({model: model});
      }
    }
  }

});
