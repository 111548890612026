import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: ['no-results-block'],

  ui: service(),
  router: service(),

  routesHint: null,
  mapsHint: null,

  // THis is not integrated with custom-trip-params
  @computed('router.currentURL', 'selectedRegionNames.[]', 'selectedLodgeNames.[]')
  customTripParams(currentURL, selectedRegionNames, selectedLodgeNames) {
    let params = {};
    let routeName = 'trip.new';

    if (selectedRegionNames) {
      params['selectedRegionNames'] = selectedRegionNames;
    }

    if (selectedLodgeNames) {
      params['selectedLodgeNames'] = selectedLodgeNames;
    }

    return [
      routeName, {
        isQueryParams: true,
        values: params
      }
    ];
  }
});
