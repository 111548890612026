import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNameBindings: [':trip-name-bar'],

  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,

  click() {
    this.set('tripService.showTripNamingWithoutSaveModal', true);
  }
});