import DS from 'ember-data';

export default DS.Model.extend({

  guest: DS.belongsTo('guest', {
    async: false
  }),
  itinerary: DS.belongsTo('itinerary', {
    async: false
  }),



});
