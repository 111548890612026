import Component from '@ember/component';

export default Component.extend({
  classNames: ['inc-dec'],

  value: 0,

  label: null,

  subZero: false,

  onChange: null, // closure | optional

  actions: {
    inc() {
      this.incrementProperty('value');
      if (this.onChange) {
        this.onChange(this.value);
      }

    },

    dec() {
      if (this.value === 0 && !this.subZero) {
        return;
      }
      this.decrementProperty('value');
      if (this.onChange) {
        this.onChange(this.value);
      }
    }
  }
});
