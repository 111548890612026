import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  task
} from 'ember-concurrency';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import {
  getSortTitle
} from 'b5b/utils';

import {
  trackEvent,
  getCategoryExperiencesPromise,
  REGION_TYPE_KODAKS
} from 'b5b/utils';

import EmberObject from '@ember/object';

const RegionType = EmberObject.extend({
  kodakOriginalUrl: null,
  name: null,
  modelName: 'region-type'
});

import PillsMixin from 'b5b/pills/mixin';

export default Component.extend(PillsMixin, {
  classNames: ['x-suggestions'],

  messageBus: service(),
  store: service(),
  settings: service(),
  entityModals: service(),
  xplorer: service('components/x-plorer'),
  tripService: service('trip'),

  possibleMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

  throttleTimer: null,
  canScrollLeft: false,
  canScrollRight: true,

  activeType: null,

  viewport: null,
  scrolled: false,

  selectedContinentNames: null,
  selectedCountryNames: null,
  selectedExperienceNames: null,
  selectedRegionNames: null,
  selectedAreaNames: null,
  selectedMonthNames: null,
  selectedBudget: null,
  selectedAutoBudgetNames: null,

  polishLevels: ["default", "custom", "personal", "personal_archived"],

  @alias('pills.suggestionsCompilation') compilation: null,
  @alias('pills.loadSuggestionsTaskRunning') loadSuggestionsTaskRunning: false,

  init() {
    this._super(...arguments);
    this.messageBus.subscribe('pill-added', this, this.loadSuggestions);
    this.messageBus.subscribe('pill-removed', this, this.loadSuggestions);
    this.messageBus.subscribe('suggestions-reset', this, this.loadSuggestions);
    this.populatePills();    
    this.loadSuggestions();
  },


  @computed('sort', 'screen.isMobileOnly')
  sortTitle(sort, isMobileOnly) {
    return getSortTitle(sort, isMobileOnly);
  },

  loadSuggestions() {
    this.loadSuggestionsTask.perform();
  },

  setCompilation(result) {
    getCategoryExperiencesPromise(this.get('store')).then(() => {
      this.set('compilation', result.get('compilation').map((record) => {

        // We create a pseudo model here for suggestions - region type isnt a real model
        if (record.type === 'region-type') {
          return RegionType.create({
            kodakOriginalUrl: REGION_TYPE_KODAKS[record.name],
            name: record.name
          });
        }
        if (record.type === 'continent') {
          return {
            name: record.name,
            id: record.id,
            type: 'continent'
          };
        }

        return this.store.peekRecord(record.type, record.id);
      }));
    })
  },

  didInsertElement() {
    this._super(...arguments);
    this.setViewport();
    this.$('.viewport').scrollLeft(0);
    // this.set('boundScrollHandler', this.onScroll.bind(this));
  },

  setViewport() {
    this.set('viewport', this.element.getElementsByClassName('viewport suggestions')[0]);
    this.get('viewport').addEventListener('scroll', this.onScroll.bind(this), false);
  },

  didRender() {
    this._super(...arguments);

    if (this.element.getElementsByClassName('viewport suggestions')[0] && this.set('viewport') !== this.element.getElementsByClassName('viewport suggestions')[0]) {
      this.setViewport();
    }
    this.updateScrollState();
  },

  onScroll() {
    this.throttleTimer = run.throttle(this, this.updateScrollState, 150, false);
  },

  updateScrollState() {
    this.scrollStateTimer = run.next(() => {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.setProperties({
        canScrollLeft: this.viewport.scrollLeft > 10,
        canScrollRight: this.viewport.scrollWidth - this.viewport.offsetWidth !== this.viewport.scrollLeft,
        scrolled: true
      });
    })

  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.throttleTimer);
    run.cancel(this.scrollStateTimer);
    // this.$('.viewport').off('scroll', this.boundScrollHandler);
    this.set('compilation', null);
    this.get('viewport').removeEventListener('scroll', this.onScroll.bind(this), false);
  },

  loadSuggestionsTask: task(function*() {

    // this.set('loadSuggestionsTaskRunning', true);

    // // we only want countries but do we need the other params to filter them?
    // let result = yield this.get('store').queryRecord('suggestion', {
    //   suggestionTypes: this.get('suggestionTypes'),
    //   filters: JSON.stringify(this.get('xplorer.filters')),
    //   selectedMonthNames: this.get('xplorer.selectedMonthNames'),
    //   selectedRegionTypeNames: this.get('xplorer.selectedRegionTypeNames'),
    //   curentRegionIds: this.get('xplorer.curentRegionIds'),
    //   swlat: this.get('swlat'),
    //   swlng: this.get('swlng'),
    //   nelat: this.get('nelat'),
    //   nelng: this.get('nelng')
    // })

    // yield this.get('performingSetupTask')


    // this.set('loadSuggestionsTaskRunning', false);

    // this.setCompilation(result);
  }).keepLatest(),

  actions: {


    setPolishLevel(val) {
      this.set('polishLevel', val)
      if(val==='custom' || val==='personal' || val=='personal_archived') {
        this.set('sort', 'created_at_desc')
      } else {
        this.set('polishLevel',null)
        this.set('sort', 'priority_asc')
      }
      this.set('settings.searchMode', 'destinationChange')
      this.get('loadEntitiesTask').perform();
    },  

    searchBoxCleared() {
      this.xplorer.clearAdditionalFilter()
      this.loadEntitiesTask.perform();  
    },
    selectSearchResult(searchResult) {
      
      console.log('selectSearchResult', arguments)
      if (searchResult.action === "search:select") {
        
        this.xplorer.clearAdditionalFilter()
        // This calls selectResult in the pills mixin
        this.selectResult(searchResult.result, searchResult.type);        
      }     

    },            
    onKeyUpInSearchBox(textBeingTyped) {
      this.xplorer.setAdditionalFilter(textBeingTyped)
      this.loadEntitiesTask.perform();  
    },    
  

    resetSuggestionsCategory(name) {
      this._super(...arguments);
      // extended in child components

      switch (name) {

        case 'selectedContinentNames':
          this.set('selectedContinentNames', []);
          break;

        case 'selectedCountryNames':
          this.set('selectedCountryNames', []);
          break;

        case 'selectedRegionNames':
          this.set('selectedRegionNames', []);
          break;

        case 'selectedAreaNames':
          this.set('selectedAreaNames', []);
          break;

        case 'selectedExperienceNames':
          this.set('selectedExperienceNames', []);
          break;

        case 'selectedMonthNames':
          // this.set('selectedMonths', null);
          // can we remove the default of Any Month here?
          this.set('selectedMonthNames', ['Any Month']);
          break;

        case 'datesAndAvailability':
          this.setProperties({
            showOnlyAvailable: false,
            startDate: null,
            endDate: null
          });
          break;

        default:
          break;
      }

      run.next(() => {
        this.messageBus.publish('suggestions-refresh');
        this.loadSuggestions();
      })

    },

    addSuggestion(item, type, key) {
      // console.log('addSug', arguments)
      trackEvent('suggestion:added');
      this.messageBus.publish('suggestion-added', item, type, key);
    },

    removeSuggestion(suggestion) {

      trackEvent('suggestion:removed');
      this.messageBus.publish('suggestion-removed', suggestion);
    },

    resetSuggestions() {
      this.resetFilters();
    },

    updateBudget([min, max]) {
      run.next(() => {
        this.set('minPrice', Math.round(min));
        this.set('maxPrice', Math.round(max));
      });
    },

    toggleFilter(filter, name, type) {
      let suggestion = {
        name,
        type
      };
      if (this.get(filter).constructor === Array) {
        if (this.get(filter).includes(name)) {
          this.send('removeSuggestion', suggestion)
        } else {
          this.send('addSuggestion', name, type)
        }
      } else {
        if (this.get(filter)) {
          this.send('removeSuggestion', suggestion)
        } else {
          this.send('addSuggestion', name, type)
        }
      }
    },

    scrollRight() {
      $('.viewport').animate({
        scrollLeft: '-=353'
      }, 300);
    },

    scrollLeft() {
      $('.viewport').animate({
        scrollLeft: '+=353'
      }, 300);
    }

  }

});
