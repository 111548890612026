import filterPaneComponent from 'b5b/components/filter-pane/component';
import {
  computed
} from 'ember-decorators/object';
import {
  not
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import {
  task
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';

import {
  trackEvent,
  maxLodgePriceForRange,
  getLodgeRelatedExperiencesPromise
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

export default filterPaneComponent.extend({
  classNames: ['lodges'],
  session: service(),
  xplorer: service('components/x-plorer'),



  // We use a read only version of showOnlyAvailable because the t-check is bound to the value.
  // The t-check shouldn't be able to change its value. it relies on the action of adding a suggestion to toggle the value
  showOnlyAvailable: false,  
  @not('showOnlyAvailable') ignoreAvailability: null,


  // We used to use this, but it doesnt update if the parent updates for some reason
  // we now use the teomplate (readonly ) helper
  // @reads('ignoreAvailability') readOnlyIgnoreAvailability: true,

  showOnlyWithOffers: false,
  exclusiveUseOnly: false,

  minLodgePrice: null,
  maxLodgePrice: null,

  maxPrice: null,
  minPrice: null,
  // PLEASE DO NOT CHANGE THESE DEFAULTS WITOUT CHANGING THE SERVER SIDE DEFAULTS
  priceRange: {
    min: 0,
    max: maxLodgePriceForRange()
  },

  showAllExperiences: false, // show more

  init() {
    this._super(...arguments);
    this.set('performingSetupTask', this.get('setupLodgeRelatedExperiences').perform());
  },

  didRender() {
    this._super(...arguments);
    
    let filterExperiences = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'experience')    
    if (this.get('lodgeTypeAndActivityExperiences.length') && filterExperiences && filterExperiences.length > 0) {
      this.set('showAllExperiences', true);
    }  
  },

  setupLodgeRelatedExperiences: task(function*() {
    let experiences = yield getLodgeRelatedExperiencesPromise(this.get('store'));
    this.set('lodgeTypeAndActivityExperiences', experiences);
  }),

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.budgetTimer);
  },

  @computed('startDate')
  minEndDateAllowed(startDate) {
    if (startDate) {
      return (moment(startDate)).toDate();
    }
  },

  actions: {
    selectBudget(val) {
      let _val = val ? val.map(Math.round) : [this.get('minLodgePrice'), this.get('maxLodgePrice')];

      this.set('selectedBudget', _val);
      this.get('messageBus').publish('suggestion-added', _val, 'budget');

      trackEvent('lodges:filter-pane', {
        filterBy: 'budget',
        value: _val
      });
    },

    updateBudget([min, max]) {
      this.budgetTimer = run.next(() => {
        this.set('minPrice', Math.round(min));
        this.set('maxPrice', Math.round(max));
      });
    }   

  }

});
