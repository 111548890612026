import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  AVAILABLE_CURRENCIES
} from 'b5b/services/settings';

export default Controller.extend(UseCurrentTrip, TripSaver, {

  settings: service(),
  store: service(),
  tripService: service('trip'),

  tripIndexEditController: controller('trip.index.edit'),

  @computed('trip.itinerary.payments.@each.state')
  paidCustomPayments(payments) {
    return payments.filterBy('state', 'paid');
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

  actions: {
    addPayment() {
      // We specify these defaults so that we can't possibly get validation errors when we save
      let payment = this.get('store').createRecord('payment', {
        state: 'unpaid'
      });

      this.get('trip.itinerary.payments').pushObject(payment);
      this.set('tripService.paymentsChanged', true);
    },

    setupQuoteCurrenciesPayment(payment) {
      payment.set('paymentType', 'quote_currencies');
      payment.set('multiCurrency', this.get('store').createRecord('multi-currency'));
      this.get('trip.itinerary.quote.uniqueCurrencies').forEach((currency) => {
        payment.get('multiCurrency.currencyContributions').pushObject(this.get('store').createRecord('currency-contribution', {
          currency
        }))
      })
      this.set('tripService.paymentsChanged', true);
    },

    setupRealAmountPayment(payment) {
      payment.set('paymentType', 'real_amount');
      payment.set('currency', 'usd');
      this.set('tripService.paymentsChanged', true);
    },

    removePayment(item) {
      this.get('trip.itinerary.payments').removeObject(item);
      this.set('tripService.paymentsChanged', true);
    },
    splitCurrenciesForPayment(payment) {
      let ratio = 0;
      if (this.splitPercent) {
        ratio = this.splitPercent / 100;
      } else {
        ratio = 1 / this.splitNumber
      }

      Object.keys(this.get('trip.itinerary.quote.balanceDuePriceHash')).forEach((currency) => {
        payment.get('multiCurrency.currencyContributions').findBy('currency', currency).set('amount', (this.get('trip.itinerary.quote.balanceDuePriceHash')[currency] * ratio).toFixed(2));
      })
      this.set('splitNumber', null);
      this.set('splitPercent', null);
      this.set('paymentForSplitting', null);
    },
    onChangePaymentField(event) {
      this.set('tripService.paymentsChanged', true);
    },  
    setPaymentCurrency(payment, currency)   {
      payment.set('currency', currency)
      this.set('tripService.paymentsChanged', true);
    },
    setContributionCurrency(contribution, currency)   {
      contribution.set('currency', currency)
      this.set('tripService.paymentsChanged', true);
    },

    
  }
});