import Route from '@ember/routing/route';
import EntitiesExperiencesRouteMixin from 'b5b/mixins/entities/experiences/route';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default Route.extend(EntitiesExperiencesRouteMixin, {

  selectedModelType: 'selectedExperienceNames',
  cache: service(),

  afterModel(experience) {
    if (experience.isCategory) {
      // This is needed to show all the countries in the left filter pane
      return this.cache.getAllPublishedCountriesPromise();
    }
  },

  @computed()
  selectedModelTypeNameReference() {
    return this.modelFor('experience').get('isRegional') ? 'experienceTags.firstObject' : 'name';
  }

});
