import { helper } from '@ember/component/helper';

export function getSubRoutes(parentRoute) {
  const mapping = {
    'trip.index.edit.pricing.line-items': 'trip.index.edit.pricing.line-items trip.index.edit.pricing.discounts trip.index.edit.pricing.payments trip.index.edit.pricing.summary'
  }
  return mapping[parentRoute];
}

export default helper(getSubRoutes);
