import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  alias
} from 'ember-decorators/object/computed';

export default Controller.extend(UseCurrentTrip, {
  paymentProcessedController: controller('trip.index.payment-processed'),

  @alias('paymentProcessedController.paymentType') paymentType: false,
  @alias('paymentProcessedController.paymentId') paymentId: false,

});
