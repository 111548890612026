import { computed } from '@ember/object';
import DS from 'ember-data';

export default DS.Model.extend({
  user: DS.belongsTo('user', {
    async: false
  }),
  lodge: DS.belongsTo('lodge', {
    async: true
  }),
  lodgeId: computed('lodge', function() {
    return this.belongsTo('lodge').id();
  })

});
