import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  AVAILABLE_CURRENCIES,
  INCLUSION_TYPES
} from 'b5b/services/settings';

export default Controller.extend(UseCurrentTrip, TripSaver, {

  scroll: service(),
  ui: service(),
  whitelabel: service(),
  tripService: service('trip'),
  templateManager: service(),
  entityModals: service(),

  showCheckVehicleTypes: false,

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

  @computed()
  inclusionTypes() {
    return INCLUSION_TYPES;
  },

  @computed('tripService.quoteGuestsChanged', 'tripService.lineItemsChanged', 'tripService.paymentsChanged', 'tripService.discountsChanged')
  recordsChanged(quoteGuestsChanged, lineItemsChanged, paymentsChanged, discountsChanged) {
    return quoteGuestsChanged || lineItemsChanged || paymentsChanged || discountsChanged;
  },


  actions: {
    closeOptionsModalThen(action){
      action();
    },
    closeSpecialPanesOnRight() {
      this.set('templateManager.tripToolsShowTemplateType', null)
    },

    openSummaryModal() {
      this.set('showSummeryModal', true)
    },

    closeSummaryModal(){
      this.set('showSummeryModal', false)
    }


  }

});
