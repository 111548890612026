import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';
import {
    computed
  } from 'ember-decorators/object';
import {
  isEmpty
} from '@ember/utils';

export default Component.extend({
  classNames: ['day-by-day-trip'],
  tripService: service('trip'),
  templateManager: service(),



  actions: {
  }

});
