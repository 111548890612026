import DS from 'ember-data';

export default DS.Model.extend({

  slideType: DS.attr(),

  lodge: DS.belongsTo('lodge'),
  region: DS.belongsTo('region'),
  country: DS.belongsTo('country'),
  experience: DS.belongsTo('experience')

});
