import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  htmlSafe
} from '@ember/string';


export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let title = this.get('tripService.currentTrip.isLayoutDayByDay') ? 'section' : 'activity'
    if (namedArgs.plural) {
      title = this.get('tripService.currentTrip.isLayoutDayByDay') ? 'sections' : 'activities'
    }
    return htmlSafe(title);
  }

});
