import DS from 'ember-data';
import Self from 'b5b/models/experience-info';

export default DS.Model.extend({
  overview: DS.attr(),
  recommendedExperiences: DS.hasMany('experience', {
    inverse: null
  }),
  // Note that if you remove this relationshup then the save for a new experience will failm as it causes an empty payload
  experience: DS.belongsTo('experience', {
    async: false
  }),  
  duration: DS.attr(),
  timings: DS.attr(),
  whatsIncluded: DS.attr(),
  notes: DS.attr(),
  locationName: DS.attr(),
  experienceImages: DS.attr(),

  metaRegionalExperiencesImages: DS.attr(),

  makeCopy() {

    let copy = this.get('store').createRecord('experienceInfo');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })

    return copy;
  }    

});
