import { inject as service } from '@ember/service';
import xDialog from 'b5b/components/x-dialog/component';
import {
  trackEvent
} from 'b5b/utils';

export default xDialog.extend({
  classNames: ['how-it-works-dialog'],

  ui: service(),

  actions: {
    openVideo() {
      trackEvent('x-video:open:how-it-works-dialog')
      this.set('ui.showVideo', true);
    }
  }
})
