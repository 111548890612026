import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  or,
  not,
  equal,
  notEmpty,
  filterBy,
  alias,
  mapBy
} from 'ember-decorators/object/computed';
import {
  scheduleHeading
} from 'b5b/utils';

import RSVP, {
  Promise as EmberPromise
} from 'rsvp';

import moment from 'moment';

export default DS.Model.extend({

  state: DS.attr(),
  startDate: DS.attr('b5bdate'),

  filterJoins: DS.hasMany('filterJoin', {
    async: false
  }),

  lodgeOptions: DS.hasMany('lodgeOption', {
    async: false
  }),

  whatsIncluded: DS.attr('string'),
  whatsExcluded: DS.attr('string'),
  overviewWhyLove: DS.attr(),
  overviewCovid: DS.attr(),
  showLodgeVideos: DS.attr(),  
  sequence: DS.attr('number'),

  overrideDestinationKodakImageUrl: DS.attr(),
  overrideDestinationKodakImageCoverStyle: DS.attr(),
  overrideLodgeKodakImageUrl: DS.attr(),
  overrideLodgeKodakImageCoverStyle: DS.attr(),
  overrideScheduleKodakImageUrl: DS.attr(),
  overrideScheduleKodakImageCoverStyle: DS.attr(),

  // Added this default with transfers - problem?
  numNights: DS.attr('number', {
    defaultValue: 0
  }),

  roomsAvailable: DS.attr(),
  lodgeAvailable: DS.attr(),

  dontBook: DS.attr('boolean', {
    defaultValue: false
  }),

  needsTransfer: DS.attr('boolean'),  
  stageType: DS.attr('string'),
  quoteNote: DS.attr('string'),
  roomType: DS.attr(),

  location: DS.belongsTo('location', {
    async: false,
    inverse: null
  }),

  lodge: DS.belongsTo('lodge', {
    async: false,
    inverse: null
  }),

  region: DS.belongsTo('region', {
    async: false,
    inverse: null
  }),

  accuratePriceAmount: DS.attr(),
  accuratePriceCurrency: DS.attr(),
  accuratePriceDate: DS.attr('b5bdate'),

  itinerary: DS.belongsTo('itinerary'),
  customSchedule: DS.belongsTo('schedule', {async: false, inverse: 'stageForCustomSchedule'}),
  templateSchedule: DS.belongsTo('schedule', {async: false, inverse: 'stageForTemplateSchedule'}),

  templateTransfer: DS.belongsTo('transfer', {
    async: false,
    inverse: null
  }),
  customTransfer: DS.belongsTo('transfer', {
    async: false,
    inverse: null
  }),

  @or('customTransfer', 'templateTransfer') transfer: null,
  @or('customSchedule', 'templateSchedule') schedule: null,
  @or('regionProxy', 'countryProxy') geoProxy: null,


  @notEmpty('filterJoins') hasStageFilters: false,
  @mapBy('filterJoins', 'stageFilter') stageFilters: null,

  @filterBy('stageFilters', 'filterType', 'add-on') addOnFilters: null, // all
  @alias('addOnFilters.firstObject') addOnFilter: null, // primary
  @notEmpty('addOnFilters') hasAddOnFilters: false,

  // @filterBy('stageFilters', 'filterType', 'lodge-filter') lodgeFilters: null, // all
  @alias('lodgeFilters.firstObject') lodgeFilter: null, // primary
  @notEmpty('lodgeFilters') hasLodgeFilters: false,

  @filterBy('stageFilters', 'filterType', 'budget-filter') budgetFilters: null, // all
  @alias('budgetFilters.firstObject') budgetFilter: null, // primary
  @notEmpty('budgetFilters') hasBudgetFilters: false,

  @computed('hasStageFilters', 'addOnFilter', 'stageType')
  addOnStateActive(hasStageFilters, addOnFilter, stageType) {
    // making the assumption that a stage wont have its type set to lodge or region
    return hasStageFilters && addOnFilter && (stageType === null || stageType === 'add-on');
  },

  @computed('stageFilters.[]')
  lodgeFilters(stageFilters) {
    return stageFilters.map((stageFilter)=> {
      if (['lodge-options', 'lodge-filter'].includes(stageFilter.filterType)) {
        return stageFilter;
      }
    });
  },

  // not to be confused with the region prop
  @computed('region.isStopOver', 'numNights')
  isStopOver(isStopOver, numNights) {
    return isStopOver && numNights < 2;
  },

  @computed('itinerary.stages.@each')
  absoluteIndex(stages) {
    if (stages && stages.indexOf(this) !== undefined) {
      return stages.indexOf(this);
    }
  },

  @or('overrideDestinationKodakImageUrl', 'geoProxy.heroEntity.kodakOriginalUrl') destinationKodakImageUrl: null,
  @or('overrideDestinationKodakImageCoverStyle', 'geoProxy.heroEntity.coverStyle') destinationKodakImageCoverStyle: null,
  @or('overrideLodgeKodakImageUrl', 'lodge.heroEntity.kodakOriginalUrl') lodgeKodakImageUrl: null,
  @or('overrideLodgeKodakImageCoverStyle', 'lodge.heroEntity.coverStyle') lodgeKodakImageCoverStyle: null,
  @or('overrideScheduleKodakImageUrl', 'schedule.coverExperience.kodakOriginalUrl') scheduleKodakImageUrl: null,
  @or('overrideScheduleKodakImageCoverStyle', 'schedule.coverExperience.coverStyle') scheduleKodakImageCoverStyle: null,

  @computed('overrideScheduleKodakImageUrl', 'schedule.coverExperience.name')
  scheduleDescription(overrideScheduleKodakImageUrl, coverExperienceName) {
    if (overrideScheduleKodakImageUrl) {
      return scheduleHeading(this.get('itinerary.trip'))
    } else {
      return coverExperienceName;
    }
  },

  @computed('startDate', 'numNights')
  transferDate(startDate, numNights) {
    if (startDate) {
      return moment(startDate).add(numNights, 'days');
    }
  },

  @computed('startDate', 'numNights')
  endDate(startDate, numNights) {
    if (startDate) {
      return moment(startDate).add(numNights, 'days').toDate();
    }
  },

  @computed('itinerary.nonTransferAndLocationStages.[]')
  isLastAccomStage(stages) {
    if (stages.indexOf(this)) {
      return stages.indexOf(this) === (stages.length - 1);
    }
  },

  // Watch out this is 1 indexed because it is used for display purposes
  @computed('dayNumberEnd', 'dayNumberStart')
  nightRange(dayNumberEnd, dayNumberStart) {

    return {
      start: dayNumberStart + 1,
      end: dayNumberEnd + 1
    }
  },

  
  @computed('itinerary.stages', 'numNights')
  dayNumberStart(stages, numNights) {

    let stageIndex = stages.indexOf(this),
      i = 0,
      startRange = 0,
      endRange = 0;

    while (i < stageIndex) {
      startRange += stages.objectAt(i).get('numNights');
      i++;
    }

    return startRange
  },  
  
  @computed('dayNumberStart', 'numNights')
  dayNumberEnd(dayNumberStart, numNights) {

    return dayNumberStart + numNights;    

  },    

  @computed('entity', 'entity.region', 'isRegionStage', 'isLodgeStage', 'region')
  regionProxy(entity, entityRegion, isRegionStage, isLodgeStage, region) {
    // Note that a stage can point at a lodge but have a custom region allocated to it which is different to the region of the lodge. For example a duplicate of the lodges region where the content has been edited
    if (isLodgeStage && region) {
      return region
    }
    // Dont understand why we have to go through this malarky but a newly created region that is being added to a trip
    // The reference from entity.region is null instead of returning refrence to itself
    let derivedRegion = entity && entityRegion;
    if (!derivedRegion && isRegionStage && region) {
      derivedRegion = region;
    }
    return derivedRegion;
  },

  @computed('regionProxy.country', 'entity.country')
  countryProxy(regionProxyCountry, entityCountry) {
    return regionProxyCountry || entityCountry;
  },

  @computed('regionProxy', 'countryProxy', 'entity.heroEntity')
  heroEntity(regionProxy, countryProxy, entityHeroEntity){
    if (this.entity.hasKodakImage) {
      return entityHeroEntity;
    } else if (regionProxy && regionProxy.get('hasKodakImage')) {
      return regionProxy;
    } else if (countryProxy && countryProxy.get('hasKodakImage')) {
      return countryProxy;
    }
  },

  @computed('entity')
  entityRegionId(entity) {
    // Useful to see whether there is a region associated with the entity of this stage. (which ca be different to the region originally allocated to this stage, before a lodge from a different region was chosen)
    return this.get('regionProxy') && (this.get('regionProxy.id') || this.entity.belongsTo('region').id());
  },

  @computed('stageType', 'lodge', 'regionProxy', 'regionProxy.shortName')
  placeName(stageType, lodge, regionProxy, regionProxyShortName) {
    if (regionProxy) {
      return regionProxyShortName
    } else if (lodge && stageType == 'lodge') {
      return lodge.placeName;
    }
  },

  @computed('stageType', 'lodge', 'location', 'region', 'customTransfer', 'templateTransfer')
  entity(stageType, lodge, location, region, customTransfer, templateTransfer) {
    if (stageType == 'lodge') {
      return lodge;
    } else if (stageType == 'location') {
      return location;
    } else if (stageType == 'region') {
      return region;
    } else if (stageType == 'custom-transfer') {
      return customTransfer;
    } else if (stageType == 'template-transfer') {
      return templateTransfer;
    }
  },

  @computed('entity', 'stageType')
  stageEntityType(entity, stageType) {
    return stageType ? stageType : entity ? entity.get('constructor.modelName') : null;
  },

  @equal('stageEntityType', 'location') isLocationStage: false,
  @equal('stageEntityType', 'lodge') isLodgeStage: false,
  @equal('stageEntityType', 'region') isRegionStage: false,
  @or('isLodgeStage', 'isRegionStage') isLodgeOrRegionStage: false,

  @equal('stageType', 'template-transfer') isTemplateTransferStage: false,
  @equal('stageType', 'custom-transfer') isCustomTransferStage: false,
  @or('isTemplateTransferStage', 'isCustomTransferStage') isTransferStage: false,
  @not('isTransferStage') notTransferStage: false,


  @computed('entity.latitude', 'entity.longitude', 'regionProxy.latitude', 'regionProxy.longitude')
  mapLocation(latitude, longitude, regionProxyLatitude, regionProxyLongitude) {
    if (latitude && longitude) {
      return {
        lng: longitude,
        lat: latitude
      }
    } else if (this.regionProxy && regionProxyLongitude && regionProxyLatitude) {
      return {
        lng: regionProxyLongitude,
        lat: regionProxyLatitude
      }
    }
  },

  makeCopy() {
    let props = this.getProperties(
      'startDate',
      'sequence',
      'needsTransfer',
      'stageType',
      'numNights',
      'dontBook',
      'showLodgeVideos',
      'quoteNote',
      'whatsIncluded',
      'whatsExcluded',
      'overviewWhyLove',
      'overviewCovid',
      'overrideDestinationKodakImageUrl',
      'overrideDestinationKodakImageCoverStyle',
      'overrideLodgeKodakImageUrl',
      'overrideLodgeKodakImageCoverStyle',
      'overrideScheduleKodakImageUrl',
      'overrideScheduleKodakImageCoverStyle',     
      // These relationships are copied across directly as the stage just points at these entities which are shared by multiple stages
      'lodge',
      'location',
      'region',
      'templateTransfer'
    );
    let copy = this.get('store').createRecord('stage', props);

    // We need to make a copy of any custom transfers as these need to be duplicated when you duplicate a stage. (they are not shared by stages)

    if (this.isCustomTransferStage && this.customTransfer) {
      copy.set('customTransfer', this.customTransfer.makeCopy())
    }

    if (this.belongsTo('customSchedule').value()) {
      copy.set('customSchedule', this.belongsTo('customSchedule').value().makeCopy())
    } else if (this.belongsTo('templateSchedule').value()) {
      copy.set('customSchedule', this.belongsTo('templateSchedule').value().makeCopy())
    }

    // Beacause we are making a copy of the stage we need to create new joins to hook up the duplicated stage with the original stage filters
    // We can't use the same joins as they are for the old stage
    // ie: The stage filters are shared but unique filter joins are needed for the copy
    this.get('filterJoins').forEach((filterJoin) => {
      let filterJoinCopy = this.get('store').createRecord('filterJoin');
      filterJoinCopy.set('stageFilter', filterJoin.get('stageFilter'))
      copy.get('filterJoins').pushObject(filterJoinCopy);
    });

    // Beacause we are making a copy of the stage we need to create new lodge options to link new stage to lodge
    // We can't use the same lodge options  as they are for the old stage
    // ie: The slodges are shared but unique lodge options are needed for the copy
    // PS: You really do need to check if these exist. Otherwise there is error when creating a brand new trip
    if (this.get('lodgeOptions')) {
      this.get('lodgeOptions').forEach((lodgeOption) => {
        let lodgeOptionCopy = lodgeOption.makeCopy();
        copy.get('lodgeOptions').pushObject(lodgeOptionCopy);
      });      
    }

    return copy;
  }
});
