import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';


export default Helper.extend({

  store: service(),

  compute(params, namedArgs) {
    let partner = this.store.peekRecord('partner', parseInt(namedArgs.partnerId));
    if (partner) {
      return htmlSafe(partner.name);
    } else {
      return htmlSafe('Missing partner');
    }

  }

});
