import Mixin from '@ember/object/mixin';

import {
  inject as service
} from '@ember/service';
import {
  generateMeta
} from 'b5b/utils';
import PrettyQuery from 'b5b/mixins/pretty-query';

export default Mixin.create(PrettyQuery, {

  screen: service(),
  ui: service(),
  cache: service(),

  beforeModel() {
    this._super(...arguments);
    // Need current user to be authetnicated otherwise doesnt get published for waybird for timbuktu consultants
    return this.get('session.currentUserPromise').then(() => {
      return this.cache.getAllPublishedCountriesPromise();
    })
  },

  setupController(controller, model) {
    this._super(...arguments);

    this.set('ui.viewingMapListing', true);

    if (!model) {
      return controller.setCountries();
    }

    switch (model.get('constructor.modelName')) {
      case 'month':
        var countries = [];

        model.get('monthInfo').then((monthInfo) => {
          monthInfo.get('bestTimeDestinations').forEach((destination) => {
            if (destination.type === 'country' && !countries.includes(destination.destination)) {
              countries.pushObject(destination.destination);
            }
          });

          monthInfo.get('highlightDestinations').forEach((destination) => {
            if (destination.type === 'country' && !countries.includes(destination.destination)) {
              countries.pushObject(destination.destination);
            }
          });

          controller.setProperties({
            countries: countries
          });
        });
        break;

      default:
        controller.setProperties({
          // We can do this
          countries: this.cache.getAllPublishedCountries()
        });
        break;
    }
  },


  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      controller.setProperties({
        countries: null,
        continents: []
      });

      this.set('ui.viewingMapListing', false);
    }
  },

  activate() {
    this._super(...arguments);
    this.screen.addBreakpoints({
      mapOnlyView: "(max-width: 1000px)"
    });
  },

  deactivate() {
    this._super(...arguments);
    this.screen.removeBreakpoints({
      mapOnlyView: "(max-width: 1000px)"
    })
  }

});
