import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';
import tripEditorRoute from 'b5b/mixins/trip-editor/route';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent,
  setupTripEntityNav,
  generateMeta
} from 'b5b/utils';

export default Route.extend(tripSubRoute, tripEditorRoute, {

  store: service(),
  @alias('tripService.currentTrip') currentTrip: false,
  screen: service(),
  ui: service(),
  session: service(),

  afterModel(trip, transition) {
    this._super(...arguments);

    if (this.whitelabel.isForAgency) {
      return transition.abort();
    }

    if (this.tripService.guestCanEditTrip || this.tripService.userCanManageThisTrip || !trip.id) {
      // Allow the user through to the editor if trip manager for trip or still editable by guest owner of trip

      if (!trip.belongsTo('templateTripIdea').id() && trip.hasTripIdeas && !transition.queryParams.tripIdea){
        this.set('ui.showTripIdeasModal', true);
      }


      // this is also done on the index route but not triggered for subroute transitions, like when choosing a trip idea on the index page
      // its reset in didTransition
      if (!this.tripService.appliedTripIdeaInTransition) {
        if (transition.queryParams.tripIdea) {
          // this.flashMessages.success('Applying trip idea...');
          this.tripService.applyTripIdeaFromQueryParam({trip, transition});
        }
      }



    } else {
      transition.abort();
      this.transitionTo('trip.index.index', trip);
      // if (this.tripService.viewingSomeoneElsesTrip) {
      //   this.set('tripService.showTripLockedForNonOwnerModal', true);
      // } else {
        this.set('tripService.showTripLockedModal', true);
      // }
    }
  },

  setupController(controller, model) {
    this._super(...arguments);

    setupTripEntityNav(model, this.get('tripService'), this.get('ui'), this.get('session'));

    this.set('ui.viewingMapListing', true);

    trackEvent('trip:editor:view', {
      trip: this.get('currentTrip.id'),
      type: this.get('currentTrip.tripType')
    });

  },


  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      this.set('ui.viewingMapListing', false);
      this.set('ui.entityNavData', null);
      // controller.set('selectedTripIdea', null);
      controller.set('mapOnlyView', false);
    }
  },

  headTags() {
    var title = `Edit trip | ${this.whitelabel.agencySeoName}`;

    if (!this.get('isFastBoot')) {
      document.title = title;
    }

    let robots = 'noindex, nofollow';
    let description = 'Edit a trip'


    return generateMeta({
      description,
      robots,
    });
  },

});
