import Controller from "@ember/controller";
import { computed } from "ember-decorators/object";
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import { AVAILABLE_CURRENCIES } from "b5b/services/settings";

export default Controller.extend(UseCurrentTrip, {
  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },
  actions: {
    addDiscount() {
      // We specify these defaults so that we can't possibly get validation errors when we save
      let a = this.get("store").createRecord("discount", {
        currency: "usd",
        discountType: "general",
        forceCardOpen: true,
      });

      // if you remove all the discounts from a quote and save it to the server,
      // the serialized response doesn't include a quote so you need to create a new one when someon adds a discount
      this.tripService.ensureTripHasQuote(this.trip);

      this.get("trip.itinerary.quote.discounts").pushObject(a);

      this.set("tripService.discountsChanged", true);
    },

    removeDiscount(discount) {
      this.get("trip.itinerary.quote.discounts").removeObject(discount);
      this.set("tripService.discountsChanged", true);
    },

    updateDiscountGuestSelection(guest) {
      if (this.get("selectedDiscount.selectedGuests").includes(guest)) {
        let discountGuest = this.get(
          "selectedDiscount.discountGuests"
        ).filterBy("guest", guest);
        if (discountGuest.length) {
          // we don't need to worry about destroying the record because when the trip is saved then we overwrite the previous version on the server
          this.get("selectedDiscount.discountGuests").removeObject(
            discountGuest.firstObject
          );
        }
      } else {
        let discountGuest = this.get("store").createRecord("discountGuest", {
          guest: guest,
          lineItem: this.get("selectedDiscount"),
        });

        this.get("selectedDiscount.discountGuests").pushObject(discountGuest);
      }
    },

    openChooseDiscountGuestModal(discount) {
      this.set("selectedDiscount", discount);
      this.set("showDiscountGuestAllocationModal", true);
    },

    closeChooseDiscountGuestModal() {
      this.set("showDiscountGuestAllocationModal", false);
    },

    onChangeDiscountField(event) {
      // What happens is someone clicks into a field, and then clicks out, but the null value is set to empty string.
      // The empty string is serialized as a null though
      // So this field stays as a dirty value
      let value = event.target.value;
      if (value === "") {
        event.target.value = null;
      }
      this.set("tripService.discountsChanged", true);
    },

    setDiscountCurrency(discount, currency) {
      discount.set("currency", currency);
      this.set("tripService.discountsChanged", true);
    },
  },
});
