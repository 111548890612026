import { isEmpty } from '@ember/utils';
import { A } from '@ember/array';
import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({

  store: service(),

  allMonths: null,
  internalMonths: A(), // these are only set once to avoid conflict of data and UI state
  hasSetup: false, // flag

  didInsertElement() {
    this._super(...arguments);
    this.set('allMonths', this.store.peekAll('month').sortBy('sequence'));
  },

  didUpdateAttrs() {
    this._super(...arguments);
    if (!isEmpty(this.selectedMonths) && !this.hasSetup) {
      this.selectedMonths.forEach((month) => {
        this.internalMonths.pushObject(month);
      });
      this.set('hasSetup', true);
    }

  },

  actions: {
    toggleMonth(month, checked) {
      if (this.onToggle) {
        this.onToggle(month, checked);
      }
    }
  }
});
