import Component from '@ember/component';

import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  task
} from 'ember-concurrency';
import {
  getLodgeRelatedExperiencesPromise
} from 'b5b/utils';

export default Component.extend({
  classNameBindings: [':stage-lodge-card', 'mobileLayoutTill1000'],

  tripService: service('trip'),
  entityModals: service(),
  screen: service(),

  init() {
    this._super(...arguments);
  },

  actions:{
    openModal() {
      if (this.onOpen){
        return this.onOpen();
      }

      // if (this.changingLodge || this.onModal) {
        this.get('entityModals').openModal({
          model: this.get('lodge')
        });
      // }
    }
  }
});
