import { helper } from '@ember/component/helper';
import {
  monthNameFromNumber
} from 'b5b/utils';


let possibleMonths =  ["Any month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function monthNameFromNumberHelper([monthNumber]) {
  return monthNameFromNumber(monthNumber);
}

export default helper(monthNameFromNumberHelper);
