import Component from '@ember/component';

export default Component.extend({
  classNames: ["x-card-header"],
  actions: {
    toggleIsOpen() {
      this.toggleIsOpen();
    }
  }
});
