import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import HasKodakImage from 'b5b/mixins/has-kodak-image';

export default DS.Model.extend(HasKodakImage, {

  name: DS.attr('string'),
  shortName: DS.attr(),
  monthInfo: DS.belongsTo('monthInfo'),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),


  @computed('name')
  friendlyId(name) {
    return name.toLowerCase();
  },

  @alias('friendlyId') monthFriendlyId: null,

  @computed('id')
  sequence(id) {
    // cant sort the id while its a string
    return parseInt(id);
  }

});