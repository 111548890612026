define("ember-fn-helper-polyfill/helpers/fn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var HAS_NATIVE_PROXY = typeof Proxy === 'function';
  var INVOKE = 'invoke'; // TODO: this is wrong

  var context = function buildUntouchableThis() {
    var context = null;
    if (true /* DEBUG */ && HAS_NATIVE_PROXY) {
      var assertOnProperty = function assertOnProperty(property) {
        (true && !(false) && Ember.assert("You accessed `this.".concat(String(property), "` from a function passed to the `fn` helper, but the function itself was not bound to a valid `this` context. Consider updating to usage of `@action`.")));
      };
      context = new Proxy({}, {
        get: function get(_target, property) {
          assertOnProperty(property);
        },
        set: function set(_target, property) {
          assertOnProperty(property);
          return false;
        },
        has: function has(_target, property) {
          assertOnProperty(property);
          return false;
        }
      });
    }
    return context;
  }();
  var _default = _exports.default = Ember.Helper.extend({
    init: function init() {
      this._super();
      this._positional = null;
      this._fn = null;
    },
    compute: function compute(positional) {
      var _this = this;
      (true && !(typeof positional[0] === 'function') && Ember.assert("You must pass a function as the `fn` helpers first argument, you passed ".concat(positional[0]), typeof positional[0] === 'function'));
      this._positional = positional;
      if (this._fn === null) {
        this._fn = function () {
          var _this$_positional = _toArray(_this._positional),
            fn = _this$_positional[0],
            args = _this$_positional.slice(1);

          // TODO: fix this branch
          for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
            invocationArgs[_key] = arguments[_key];
          }
          if (typeof fn[INVOKE] === 'function') {
            // references with the INVOKE symbol expect the function behind
            // the symbol to be bound to the reference
            return fn[INVOKE].apply(fn, _toConsumableArray(args).concat(invocationArgs));
          } else {
            return fn.call.apply(fn, [context].concat(_toConsumableArray(args), invocationArgs));
          }
        };
      }
      return this._fn;
    }
  });
});