import Component from '@ember/component';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  
  classNames: ['doc-list-section'],
  documentTypes: ['passport', 'ticket', 'voucher', 'miscellaneous'],

  saveDocument: task(function*(document) {
    return document.save();
  }).keepLatest(),

  actions: {
    documentChanged(document) {
      this.get('saveDocument').perform(document);
    },
    setDocumentType(document, documentType) {
      document.set('documentType', documentType)
      this.get('saveDocument').perform(document);
    },
    
    archiveDoc(document) {
      document.set('enabled', !document.enabled)
      this.get('saveDocument').perform(document);
    },

  }

});
