import {
  inject as controller
} from '@ember/controller';
import Mixin from '@ember/object/mixin';

import {
  computed
} from 'ember-decorators/object';
import {
  and,
  gt
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';


export default Mixin.create({

  screen: service(),
  cache: service(),
  ui: service(),
  applicationController: controller('application'),

  popupOpenIndex: null,
  selectedContinentNames: ['All'],
  queryParams: [{
    selectedContinentNames: {
      as: 'continents'
    }
  }],

  @alias('applicationController.mapOnlyView') mapOnlyView: null,
  @gt('bounds.length', 2) hasBounds: null,
  @and('screen.initialized', 'hasBounds') readyToShowMap: false,

  @computed('countries.[]')
  bounds(countries) {

    if (countries && countries.get('length') > 0) {
      var bounds = countries.map((country) => {
        return [country.get('latitude'), country.get('longitude')];
      });

      if (bounds.get('length') == 1) {
        // Its crazy but true. Ian 30/12/2016. This is needed because otherwise the bounds calculation blows up on initial page load if there is only a single lodge in the result set.
        // The bounds seems fine if you change fitlers so there is a single lodge but refresh and everythign falls over!
        var lat = parseFloat(bounds[0][0]);
        var lng = parseFloat(bounds[0][1]);
        bounds.pushObject([(lat - 0.25).toString(), (lng - 0.25).toString()])
        bounds.pushObject([(lat + 0.25).toString(), (lng + 0.25).toString()])
      }

      return bounds;
    }
  },

  setCountries() {
    // we can use this peekAll here as the countries are loaded in the mxiin route
    let countries = this.cache.getAllPublishedCountries();
    if (this.selectedContinentNames && this.selectedContinentNames.length > 0) {
      let continentName = this.selectedContinentNames.firstObject;
      if (continentName!=='All') {
        countries = countries.filterBy('continentName', this.selectedContinentNames.firstObject);
      }
    }
    countries = countries.sortBy('name');
    this.set('countries', countries);
  },

  actions: {

    setContinent(tag) {
      this.set('selectedContinentNames', [tag.name]);
      this.setCountries();
    },

    hideMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed') || this.get('mapOnlyView')) {
        return;
      }
      marker.target.setOpacity(0);
    },

    showMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed') || this.get('mapOnlyView')) {
        return;
      }

      marker.target.setOpacity(1);
    }
  }

});
