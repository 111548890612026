define("ember-deferred-content/templates/components/deferred-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PH5vzmFy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"pending\",\"fulfilled\",\"rejected\",\"settled\",\"isPending\",\"isSettled\",\"isRejected\",\"isFulfilled\",\"content\"],[[27,\"component\",[\"deferred-content/pending-content\"],[[\"isSettled\"],[[23,[\"isSettled\"]]]]],[27,\"component\",[\"deferred-content/fulfilled-content\"],[[\"isFulfilled\",\"result\"],[[23,[\"isFulfilled\"]],[23,[\"content\"]]]]],[27,\"component\",[\"deferred-content/rejected-content\"],[[\"isRejected\",\"result\"],[[23,[\"isRejected\"]],[23,[\"content\"]]]]],[27,\"component\",[\"deferred-content/settled-content\"],[[\"isSettled\"],[[23,[\"isSettled\"]]]]],[23,[\"isPending\"]],[23,[\"isSettled\"]],[23,[\"isRejected\"]],[23,[\"isFulfilled\"]],[23,[\"content\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-deferred-content/templates/components/deferred-content.hbs"
    }
  });
  _exports.default = _default;
});