
import DayByDaySection from 'b5b/components/day-by-day-section/component';
import {
  computed
} from 'ember-decorators/object';
import {
  isEmpty
} from '@ember/utils';

export default DayByDaySection.extend({

  @computed('stage.lodge', 'stage.regionProxy', 'block.blockItems.@each.blockItemType')
  blockImageClickEntity(lodge, regionProxy, blockItems) {

    let checkinBlockItem = this.block.get('blockItems').findBy('blockItemType', 'checkin')

    if (checkinBlockItem && lodge) {
      return lodge
    } else {
      return regionProxy
    }

  },
  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(stages) {
    return stages.map((stage) => {
    return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapDefaultLatLng(stages) {
    if (isEmpty(stages)) {
      return {
        lat: -4.721974,
        lng: 22.884122
      }
    }
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  },

});
