import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import togglePillsComponent from 'b5b/components/toggle-pills/component';

export default togglePillsComponent.extend({
  classNames: ['continents'],
  allContinentsCompilation: [{type: "continent-type", count: 1, name: "All"}, {type: "continent-type", count: 1, name: "Africa"}, {type: "continent-type", count: 1, name: "Asia"}, {type: "continent-type", count: 1, name: "Europe"}, {type: "continent-type", count: 1, name: "Latin America"}],

});
