import { helper } from '@ember/component/helper';

export function entityStartRouteName(params, namedArgs) {

  let entity = namedArgs.entity;
  let entityType = namedArgs.entityType;

  let routeName = 'start';
  if (entityType) {
    routeName = entityType+'.'+routeName;
    if (entityType=='month') {
      routeName = 'when-to-go.'+routeName;
    }
  }
  return routeName;
}

export default helper(entityStartRouteName);
