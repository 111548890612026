import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  humanReadableList
} from 'b5b/utils';
export function humanReadableListHelper([list, options]) {
  return humanReadableList(list, options);
}

export default buildHelper(humanReadableListHelper);
