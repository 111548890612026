import xGalleryComponent from 'b5b/components/x-gallery/component';
import {
  run
} from '@ember/runloop';
import { decamelize } from 'b5b/helpers/decamelize';
import {
  computed
} from 'ember-decorators/object';

export default xGalleryComponent.extend({
  classNameBindings: [':carousel', 'captionPosition'],

  isGridItem: false,

  showCase: false, // eg, lodge cards on desktop
  _showCaseInitialized: false,

  loadTimer: null,

  // custom prop for our setup
  _recognizers: 'swipe',

  didReceiveAttrs() {
    this._super(...arguments);

    if ((this.coverUrl && this._cachedCoverUrl && this.coverUrl !== this._cachedCoverUrl) || (this.coverStyle && this._cachedCoverStyle && this.coverStyle !== this._cachedCoverStyle)) {
      this._setupImages();
    }

    // console.log('showcase', this.showCase, this._showCaseInitialized)
    if (this.showCase && !this._showCaseInitialized) {      
      this.set('startIndex', 2);
      this.set('_showCaseInitialized', true);

      this.loadTimer = run.next(() => {
        if (this.loadImages) {
          this.loadImages();
        }
      })
    } else if (!this.showCase && this._showCaseInitialized && this.startIndex==2) {
      // This caters for case where showcase is initialised, but them something changes like the element next to the showcase gets popuplated with a schedule and showCase is no longer true. 
      // Otherwise you end up showing the 3rd image instead of cover image
      this.set('startIndex', 0)
      this.set('_activeIndex', 0)      
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    run.cancel(this.loadTimer);
    run.cancel(this.nextTimer);
    run.cancel(this.prevTimer);

    this.setProperties({
      _cachedCoverUrl: null,
      _showCaseInitialized: false,
      showCase: false
    });
  },

  animate: true,

  _next() {
    this._super(...arguments);

    if (this.showCase) {
      this.nextTimer = run.later(() => {
        let index = this.get('_activeIndex') - 2;

        this.$('> .frame > .item:eq(' + index + ')').velocity('scroll', {
          container: $('#' + this.elementId + ' > .frame')[0],
          axis: 'x',
          duration: 200
        });
      }, 150)

    }
  },

  _prev() {
    this._super(...arguments);

    if (this.showCase) {
      this.prevTimer = run.later(() => {
        let index = this.get('_activeIndex') - 2;

        this.$('> .frame > .item:eq(' + index + ')').velocity('scroll', {
          container: $('#' + this.elementId + ' > .frame')[0],
          axis: 'x',
          duration: 200
        });
      }, 150);
    }
  }

});
