import Component from '@ember/component';

export default Component.extend({

  isSignupSuccess: false,

  hasBottomBar: false,

  submit(e) {
    e.preventDefault();

    this.signupNewsletterIgnoreExistingSubscription(
      this.subscriptionName,
      this.subscriptionEmail,
      'full-screen',
      'newsletter-signup'
    );
    this.set('isSignupSuccess', true);
    return false;

  }
});
