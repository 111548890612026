// import LocalStorageStore from 'ember-simple-auth/session-stores/local-storage';
// import CookieStore from 'ember-simple-auth/session-stores/cookie';

// export default LocalStorageStore.extend();

// export default CookieStore.extend();


import AdaptiveStore from 'ember-simple-auth/session-stores/adaptive';

export default AdaptiveStore.extend();
