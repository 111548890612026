import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend({

  session: service(),
  ui: service(),
  cache: service(),

  skipToStep: null,

  model() {
    return this.cache.getAllPublishedCountriesPromise();
  },

  afterModel(model, transition) {
    if (transition.queryParams.step) {
      this.set('skipToStep', transition.queryParams.step);
    }
  },

  setupController(controller) {
    this._super(...arguments);
    controller.set('steps', this.store.peekAll('step'));
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      this.set('skipToStep', null);
    }
  },

  headTags() {
    let description = 'Not sure where to go? Try our trip wizard to find your dream trip';
    const title = `Trip Wizard | ${this.whitelabel.agencySeoName}`;

    let robots = 'index, nofollow';
    if (!this.isFastBoot) {
      document.title = title;
    }

    return generateMeta({
      description,
      robots,
      'og:title': title,
      'og:image': 'https://waybird.imgix.net/images/wizard/wizard.jpg'
    });
  },

  activate() {
    this.get('ui').setProperties({
      hideHubSpot: true,
      inWizard: true
    });
    // Once a user has started the wizard modal we don't ever need to show the wizard modal to them
    this.get('ui').dontShowWizardModalEver();
  },

  deactivate() {
    this.get('ui').setProperties({
      hideHubSpot: false,
      inWizard: false
    });
  },

  actions: {
    didTransition() {
      // we need to do it here to ensure a full transition into wizard.index has occured for checkPermanentSuspensions
      if (this.skipToStep) {
        this.transitionTo('wizard.step', this.skipToStep);
        this.set('skipToStep', null);
      }
      return true;
    }
  }

});
