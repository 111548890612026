import { computed } from '@ember/object';
import DS from 'ember-data';

export default DS.Model.extend({
  user: DS.belongsTo('user', {
    async: false
  }),
  experience: DS.belongsTo('experience', {
    async: true
  }),
  experienceId: computed('experience', function() {
    return this.belongsTo('experience').id();
  })

});
