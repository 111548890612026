import entityStartRoute from 'b5b/mixins/entity-start/route';
import Route from '@ember/routing/route';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(entityStartRoute, {

  entityType: 'region',

  headTags() {
    let model = this.currentModel;
    let title = `Plan a trip`
    let description = `Start designing your trip`
    if (model) {
      title +=` to ${model.name}`;
      description +=` to ${model.name}`;
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = "noindex, nofollow";
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    let metas = {
      description,
      robots,
      'og:title': title,
      link
    }
    if (model) {
      metas['og:image'] = model.get('kodakOriginalUrl')
    }

    return generateMeta(metas);
  },

});
