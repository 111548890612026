import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import HasKodakImage from 'b5b/mixins/has-kodak-image';

export default DS.Model.extend(HasKodakImage, {

  description: DS.attr(),
  useAsKodak: DS.attr(),
  useAsSecond: DS.attr(),
  useAsThird: DS.attr(),
  path: DS.attr(),

  region: DS.belongsTo('region', {
    inverse: null,
    async: false
  }),
  // // regionImage: DS.belongsTo('regionImage', {
  //   inverse: null,
  //   async: false
  // }),
  trip: DS.belongsTo('trip', {
    async: false
  }),
  lodge: DS.belongsTo('lodge', {
    inverse: null,
    async: false
  }),

  // @computed('name')
  // friendlyId(name) {
  //   return name.toLowerCase();
  // },

  // @alias('friendlyId') monthFriendlyId: null,

  // @computed('id')
  // sequence(id) {
  //   // cant sort the id while its a string
  //   return parseInt(id);
  // }

});
