import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import RSVP from 'rsvp';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['trip-tools-right-pane-add-templates', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  templateManager: service(),
  entityModals: service(),
  store: service(),
  session: service(),
  dayExperiences: null,
  showCurrentUserTemplatesOnly: false,
  showCurrentLodgeTemplatesOnly: false,

  didInsertElement() {
    this._super(...arguments);
    this.get('loadTemplatesTask').perform();
  },

  loadTemplatesTask: task(function*() {
    let queryAttrs = {
      buster: true,
      sort: 'recommended',
      templateType: this.get('templateManager.tripToolsShowTemplateType'),
    };

    let filters = []    
    if (this.showCurrentLodgeTemplatesOnly) {
      filters.pushObject({type: 'lodge', id: this.templateManager.currentStage.lodge.id})
    }
    if (this.showCurrentLocationTemplatesOnly) {
      filters.pushObject({type: 'location', id: queryAttrs.locationId=this.templateManager.currentStage.location.id})
    }
    if (!this.showCurrentLodgeTemplatesOnly && !this.showCurrentLocationTemplatesOnly) {
      if (this.get('templateManager.currentStage.regionProxy.id')) {
        filters.pushObject({type: 'region', id: this.get('templateManager.currentStage.regionProxy.id')})
        this.get('templateManager.currentStage.regionProxy').get('associatedEntities') && this.get('templateManager.currentStage.regionProxy').get('associatedEntities').forEach((associatedEntity)=> {
          if (associatedEntity.get('associatedRegion')) {
            filters.pushObject({type: 'region', id: associatedEntity.get('associatedRegion.id')})
          }
        })    
      } else if (this.get('templateManager.currentStage.countryProxy.id')) {
        filters.pushObject({type: 'country', id: this.get('templateManager.currentStage.countryProxy.id')})
      }        
    }
    if (this.textFilter) {
      filters.pushObject({
        name: this.textFilter,
        type: "text",
      })      
    }    

    queryAttrs.filters=JSON.stringify(filters)

    if (this.showCurrentUserTemplatesOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }

    return this.store.query('template', queryAttrs).then((results)=>{
      if (this.get('templateManager.tripToolsShowTemplateType')=='lodge_why_love') {
        let extraInfos = [this.get('templateManager.currentStage.lodge.lodgeInfo'), this.get('templateManager.currentStage.regionProxy.regionInfo')]

        let templates=[];
        results.forEach((result)=> {
          templates.pushObject(result)
        })
        this.set('templates', templates)

        let promise = RSVP.all(extraInfos).then(()=> {
          if (this.get('templateManager.currentStage.lodge.lodgeInfo.overviewWhyLove')) {
            templates.pushObject({internalName: 'Lodge - Why love', overview: this.get('templateManager.currentStage.lodge.lodgeInfo.overviewWhyLove'), templateType: "lodge_why_love"})
          }
          if (this.get('templateManager.currentStage.lodge.lodgeInfo.overviewInsidersTip')) {
            templates.pushObject({internalName: 'Lodge - Insiders tips', overview: this.get('templateManager.currentStage.lodge.lodgeInfo.overviewInsidersTip'), templateType: "lodge_why_love"})
          }
          if (this.get('templateManager.currentStage.regionProxy.regionInfo.overviewWhyLove')) {
            templates.pushObject({internalName: 'Region - Why love', overview: this.get('templateManager.currentStage.regionProxy.regionInfo.overviewWhyLove'), templateType: "lodge_why_love"})
          }
          if (this.get('templateManager.currentStage.regionProxy.regionInfo.overviewOffBeatenTrack')) {
            templates.pushObject({internalName: 'Region - Off the beaten track', overview: this.get('templateManager.currentStage.regionProxy.regionInfo.overviewOffBeatenTrack'), templateType: "lodge_why_love"})
          }
        })
      } else {
        this.set('templates', results)
      }
    })

  }).keepLatest(),

  actions: {
    textFilterChanged(textFilter) {
      this.set('textFilter', textFilter)
      this.loadTemplatesTask.perform();
    }    
  }
});
