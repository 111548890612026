import EntityContent from 'b5b/components/entity-content/component';
import {
  inject as service
} from '@ember/service';
import {
  trackFacebookDestinationView
} from 'b5b/utils';



export default EntityContent.extend({

  store: service(),
  tripService: service('trip'),

  region: null,

  didReceiveAttrs() {
    this._super(...arguments);
    trackFacebookDestinationView(this.region, this.tripService);

    let query = {
      filters: JSON.stringify([{type: 'region', id: this.region.id}]),
      per_page: 3,
      isRegional: true
    };

    // if (this.onModal) {
      this.store.query('lodge', query).then((lodges) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        this.set('recommendedLodges', lodges);
      });
    // }

    this.store.query('experience', query).then((experiences) => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.set('recommendedExperiences', experiences);
    });

  }

});
