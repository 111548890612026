import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';

export default DS.Model.extend(HasKodakImage, {

  sector: DS.belongsTo('sector'),

  specialOfferTravelDateRanges: DS.hasMany('specialOfferTravelDateRange'),

  name: DS.attr(),
  description: DS.attr(),

  travelUntil: DS.attr('b5bdate'),

  bookingDateFrom: DS.attr('b5bdate'),
  bookingDateTo: DS.attr('b5bdate'),
  expiresDate: DS.attr('b5bdate'),

  teaser: DS.attr(),
  termsAndConditions: DS.attr(),
  stayNights: DS.attr(),
  payNights: DS.attr(),
  lodgePercentOff: DS.attr()

});
