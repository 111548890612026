import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    enquiry: {
      serialize: 'records'
    },
    stages: {
      serialize: 'records'
    },
    quote: {
      serialize: 'records'
    },
    payments: {
      serialize: 'records'
    },
    // TODODEALGESTS we should only have itineraryGuests
    guests: {
      serialize: 'records'
    },
    videos: {
      serialize: 'records'
    },
    firstSchedule: {
      serialize: 'records'
    },
    // TODODEALGESTS replace this with serialize records below
    itineraryGuests: {
      serialize: false
    },

    // itineraryGuests: {
    //   serialize: 'records'
    // },
    tripRatesSpecialOffer: {
      serialize: false
    },
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards

    json.enquiry_attributes = json.enquiry;
    json.quote_attributes = json.quote;
    // json.stages_attributes = json.stages;
    // delete json.stages;
    delete json.enquiry;
    delete json.quote;

    json.first_schedule_attributes = json.first_schedule;
    delete json.first_schedule;


    json.stages_attributes = json.stages;
    delete json.stages;

    json.payments_attributes = json.payments;
    delete json.payments;

    json.videos_attributes = json.videos;
    delete json.videos;

    // TODODEALGESTS replace guests_attributes with itinerary_guests_attributes
    json.guests_attributes = json.guests;
    delete json.guests;

    // json.itinerary_guests_attributes = json.itinerary_guests;
    // delete json.itinerary_guests;

    return json;
  }

  // serializeBelongsTo: function() {
  // }


  // keyForRelationship: function(key, typeClass, method) {

  // WE TRIED THIS BUT IT DID NOT WORK

  //   if (key === "stages" ) {

  //     return 'stages_attributes';
  //   }
  //   return this._super(key, typeClass, method);
  // },
});
