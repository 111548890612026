import WaybirdWidgetComponent from 'b5b/components/t-widget/waybird/component';
import {
  inject as service
} from '@ember/service';

export default WaybirdWidgetComponent.extend({
  classNames: ['bottom-bar'],

  ui: service(),

  didInsertElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', true);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', false);
  }
});
