import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';

export default Route.extend({

  ui: service(),

  afterModel(model, transition) {
    this._super(...arguments);

    if (transition.queryParams.fromFinalStep){
      model.set('fromFinalStep', true);
    }

    let submission = model;
    if (submission.wizardRules && submission.wizardRules.personalisedFirstTrip) {
      transition.abort();
      let tripFriendlyId = submission.get('tripSummaries').objectAt(0).tripFriendlyId;
      this.store.queryRecord('trip', {id: tripFriendlyId}).then((resultTrip)=> {
        resultTrip.set('name', `${submission.firstName}'s ${resultTrip.countriesList} trip`);
        // resultTrip.set('showWizardSuccessModal', true);
        resultTrip.set('wizardSubmission', submission);
        this.get('router').transitionTo('trip.index.editor', resultTrip);
      });

    }

    // Hacky but we assume the last step is the success route
    // let lastStep = this.store.peekAll('step').length;

    // this.controllerFor('wizard').set('currentStep', null);
    // this.controllerFor('wizard').setStep(null);

  },

  setupController(controller, model) {
    this._super(...arguments);

    if (model.fromFinalStep){
      controller.set('ui.showTripPlanningModal', true);
      model.set('fromFinalStep', false);
    }


    if (model.wizardRules && model.wizardRules.hasMoreResults){

      let params = {},

      selectedCountryNames = model.submissions.selectedCountryNames && model.submissions.selectedCountryNames.removeObject(null) || [],
      selectedExperienceNames = model.submissions.selectedExperienceNames && model.submissions.selectedExperienceNames.removeObject(null) || [],
      selectedMonthNames = model.submissions.selectedMonthNames && model.submissions.selectedMonthNames.removeObject(null) || [];

      if (selectedCountryNames.length) {
        params['selectedCountryNames'] = selectedCountryNames
      }
      if (selectedExperienceNames.length) {
        params['selectedExperienceNames'] = selectedExperienceNames
      }
      if (selectedMonthNames.length) {
        params['selectedMonthNames'] = selectedMonthNames
      }

      controller.set('seeMoreParams', [
        'trips', {
          isQueryParams: true,
          values: params
        }
      ]);
    }

    controller.set('tripRecommendations', model.get('tripSummaries'));
    trackEvent('wizard:success:set-trips');

    trackEvent('wizard:success');
  },

  resetController(isExiting){
    this._super(...arguments);
    if (isExiting){
      this.set('ui.showSavedWizardTripsModal', false);
      this.set('ui.savingWizardTrips', false);
    }
  }
});
