import {
  computed
} from '@ember/object';
import {
  reads,
  and,
  not
} from '@ember/object/computed';
import Service, {
  inject as service
} from '@ember/service';

export default Service.extend({

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  // showingNavBar: true,
  startDate: null,
  endDate: null,
  buster: null,

  datesSelected: and('startDate', 'endDate'),

  enquiryData: null,

  preRendering: computed('isFastBoot', function() {
    if (typeof Fastboot !== 'undefined' || this.isFastBoot) {
      return;
    }
    return window.timbuktu.preRendering;
  }),

  trackImageLoads: computed('preRendering', function() {
    return window.timbuktu.preRendering || window.timbuktu.pdfGen;
  }),  

  notPreRendering: not('preRendering')

});
