import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  tagName: '',
  entityModals: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') model: null,

  @computed('currentModalConfig.needToKnowType')
  faqTitle(needToKnowType) {
    let title = "";
    switch (needToKnowType) {
      case 'gettingThere':
        title = 'Getting there';
        break;
      case 'health':
        title = 'Health & Safety';
        break;
      case 'visas':
        title = 'Visas';
        break;
      case 'whatToPack':
        title = 'What to pack';
        break;
      case 'faqMoney':
        title = 'Money & Gratuities';
        break;
      case 'faqTravelInsurance':
        title = 'Travel insurance';
        break;        
  
    }
    return title;
  }
})