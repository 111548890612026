import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  capitalizeFirstLetter,
  getErrorsHashFromServerResponse
} from 'b5b/utils';
import schedule from '../../../serializers/schedule';

export default Component.extend({
  tagName: '',

  tripService: service('trip'),
  entityModals: service(),
  ui: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') schedule: null,
  @alias('entityModals.currentModalConfig.stage') stage: null,
  @alias('entityModals.currentModalConfig.stage.itinerary.trip') trip: null,


  actions: {
    closeEntityModalsAndSaveTemplate(options) {

      options.schedule.save().then(()=> {
        this.entityModals.closeModal();
      }, (error) => {

        let errors = getErrorsHashFromServerResponse(error)
        let message = '';
        Object.keys(errors).forEach((key) => {
          let descriptiveKey = capitalizeFirstLetter(key.replace(/_/g, ' '))
          message += `${descriptiveKey}: ${errors[key]}<br>`;
        })
        this.ui.showGeneralMessage('We couldnt save your template', message);

        throw error;
      })
    },
  }
});
