import DS from 'ember-data';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

var Location = DS.Model.extend({
  name: DS.attr(),
  overview: DS.attr(),
  latitude: DS.attr(),
  longitude: DS.attr(),
  region: DS.belongsTo('region', {
    async: false,
    inverse: null
  }),
  country: DS.belongsTo('country', {
    async: false,
    inverse: null
  }),
  //originalEntity currently not serialised back from server. This is used to save relationship to server
  originalEntity: DS.belongsTo('location', {
    inverse: null,
    async: false
  }),
  polishLevel: DS.attr('string'),
  userOwnerId: DS.attr('string'),

  @alias('name') displayName: null,  //Needs an alias of displayName to match displayName on region as many things can point at a region or location

  @alias('latitude') latitudeWithFallback: null,
  @alias('longitude') longitudeWithFallback: null,

  @alias('region.shortName') regionName: null,
  @alias('region.country.name') countryName: null,
  
  @computed()
  mapEntity(latitude, longitude) {
    return this;
  },

  @computed('latitude', 'longitude')
  mapMarker(latitude, longitude) {
    if (!latitude || !longitude) {
      return;
    }

    return [
      [parseFloat(longitude).toFixed(6), parseFloat(latitude).toFixed(6)]
    ];
  },  

});

export default Location;
