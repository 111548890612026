import { subscribe, instrument } from '@ember/instrumentation';
import {
  inject as service
} from '@ember/service';
import {
  Promise as EmberPromise
} from 'rsvp';
import Mixin from '@ember/object/mixin';
import {
  A
} from '@ember/array';
import {
  bind,
  run
} from '@ember/runloop';
import {
  observer
} from '@ember/object';
import {
  getItemFromLocalStorage
} from 'b5b/utils';

import {
  captureEmail,
  trackEvent
} from 'b5b/utils';
import config from 'b5b/config/environment';

const EMBER_SIMPLE_AUTH_KEY = 'ember_simple_auth:session';

// Based on https://github.com/simplabs/ember-simple-auth/blob/master/addon/mixins/application-route-mixin.js
export default Mixin.create({

  store: service(),
  session: service(),
  ui: service(),

  init() {
    this._super(...arguments);

    this._checkIfUserDetailsExist();
    this._subscribeToExternalAuthEvents();
    this._subscribeToSessionEvents();
  },

  _checkIfUserDetailsExist() {
    var simpleAuthKey = getItemFromLocalStorage(EMBER_SIMPLE_AUTH_KEY);
    if (simpleAuthKey && simpleAuthKey.indexOf('email') > 0) {
      this.set('session.userDetailsExist', true);
    }
    this._ensureThereIsUserPromiseEvenIfNoUserBeingLoggedIn();

  },

  _subscribeToExternalAuthEvents() {
    //Allow external apps to notify ember app of logged in user
    var self = this;
    subscribe("user.externallyAuthorised", {
      after: function() {

      },
      before: function(name, timestamp, payload) {

        console.log('Recieved ', name, ' at ' + timestamp + ' with payload: ', payload);

        var email = payload.email;
        var credentials = {
          token: payload.token,
          id: payload.id
        };

        self.get('session').authenticate('authenticator:server-side-aware-authenticator', email, credentials).then(function() {
          captureEmail(email, 'login', 'facebook');
          //Ok so at this point the user has authenticated through facebook
          //Lets alert any listeners
          trackEvent('login', {
            registration: 'facebook'
          });
          instrument("user.loggedIn", {}, function() {});
        });
      }
    });
  },


  _ensureThereIsUserPromiseEvenIfNoUserBeingLoggedIn() {

    // So basically at this point, if there are user credentials in the local storage then the session.currentUserPromise
    //would have been created already and will resolve to logged in user.
    //By creating this promise to return null, it means anywhere in the app we can safely call get session.currentUserPromise
    //and will then have a promise which will resolve to the logged in user or null if there is no logged in user or user busy logging in
    if (!this.get('session.currentUserPromise')) {
      var promise = new EmberPromise((resolve) => {
        resolve(null);
      });
      this.set('session.currentUserPromise', promise);
    }

  },


  _subscribeToSessionEvents() {
    A([

      ['invalidationSucceeded', 'sessionInvalidated']
    ]).forEach(([event, method]) => {
      this.session.on(event, bind(this, () => {
        this[method](...arguments);
      }));
    });
  },

  watchSessionAuthenticated: observer('session.isAuthenticated', function() {
    if (this.get('session.isAuthenticated')) {

      const attemptedTransition = this.get('session.attemptedTransition');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else {
        let routeAfterAuthentication = this.get('session.routeAfterAuthentication');
        if (routeAfterAuthentication) {
          this.transitionTo(this.routeAfterAuthentication);
          // routeAfterAuthentication = Configuration.routeAfterAuthentication;
        }
      }

      var promise = new EmberPromise((resolve) => {
        this.set('userAuthenticatedResolver', resolve);
      });
      this.set('session.currentUserPromise', promise);

      var currentUserId = this.get('session.session.content.authenticated.id');
      if (currentUserId) {
        this.store.findRecord('user', currentUserId).then((user) => {
          if (this.get('session.userInitiatedLogin') && user.get('isManager')) {
            // If a consultant or partner attempts to log in we need to reload the app because the data that has been pulled in will be mising vital data such as quote line items. If they try to edit a trip then can then override proper quotes on the server with empty data.
            this.get('ui').showGeneralMessage('Logged in', 'We will automatically reload your browser as you have logged in as a travel specialist');
            run.later(this, function() {
              location.reload();
            }, 500);
          }
          if (!window.timbuktu.pdfGen && window.mixpanel) {
            mixpanel.identify(user.get('email'))
          }          
          this.userAuthenticatedResolver(user);
          this.set('session.currentUser', user);

          if (window) {
            window.timbuktu.blockAnalyticsAndAffiliates = user.get('isTimbuktu') || user.get('isAgent') || window.timbuktu.pdfGen;
          }
          run.once(this, 'likeFromLastAttempt');
          run.once(this, 'retryLastWizardTripRecommendationsSave');
        });
      } else {
        this.session.invalidate();
      }
    }
  }),

  /**
    This overrides the sessionInvalidated function from ApplicationRouteMixin in ember-simple-auth
    With the change from baseUrl to rootUrl, ember-simple-auth needs to use rootUrl that we have defined in our app
    The original is defined here:
    https://github.com/simplabs/ember-simple-auth/blob/9d5a37b1ece0b82ee504e6cd6f367458b1d3120d/addon/mixins/application-route-mixin.js#L109
  */
  sessionInvalidated() {
    if (this.get('session.afterLogoutUrl')) {
      window.location.replace(this.get('session.afterLogoutUrl'));
    } else {
      window.location.replace(config.rootURL);
    }
  }



});
