import {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';

var rates = {};

const textFormatters = {
  gbp(val) {
    return '£' + val.toLocaleString();
  },
  usd(val) {
    return '$' + (val).toLocaleString();
  },
  zar(val) {
    return 'R' + val.toLocaleString();
  },
  eur(val) {
    return '€' + val.toLocaleString();
  },
  aud(val) {
    return 'A$' + val.toLocaleString();
  },
  cad(val) {
    return 'C$' + val.toLocaleString();
  },
  nzd(val) {
    return '$' + val.toLocaleString();
  },
  chf(val) {
    return '₣' + val.toLocaleString();
  },  
  jpy(val) {
    return '¥' + val.toLocaleString();
  },    
  etb(val) {
    return 'Br' + val.toLocaleString();
  },
  sgd(val) {
    return '$' + val.toLocaleString();
  },
  hkd(val) {
    return '$' + val.toLocaleString();
  },    
};

const formatters = {
  gbp(val) {
    return '<span>&pound;</span>' + val.toLocaleString();
  },
  usd(val) {
    return '<span>&#36;</span>' + val.toLocaleString();
  },
  zar(val) {
    return '<span>R</span>' + val.toLocaleString();
  },
  eur(val) {
    return '<span>&#8364</span>' + val.toLocaleString();
  },
  aud(val) {
    return '<span>A&#36;</span>' + val.toLocaleString();
  },
  cad(val) {
    return '<span>C&#36;</span>' + val.toLocaleString();
  },
  nzd(val) {
    return '<span>NZ&#36;</span>' + val.toLocaleString();
  },
  sgd(val) {
    return '<span>S&#36;</span>' + val.toLocaleString();
  },
  hkd(val) {
    return '<span>HK&#36;</span>' + val.toLocaleString();
  },    
  chf(val) {
    return '<span>CH&#8355;</span>' + val.toLocaleString();
  },  
  jpy(val) {
    return '<span>&#165;</span>' + val.toLocaleString();
  },  
  etb(val) {
    return '<span>Br</span>' + val.toLocaleString();
  }
};

function roundPrice(price, roundToClosest) {

  let rounded = Math.floor((price + 0.5) / roundToClosest) * roundToClosest;
  if (rounded == 0) {
    return 0;
  }
  let remainder = rounded % 100;
  if (remainder < 10) {
    return rounded - remainder - 10;
  } else {
    return rounded;
  }
}

function truncate(num, truncLimit) {

  if (!truncate) {
    return num;
  }

  if (isNaN(truncLimit) || truncLimit == true) {
    truncLimit = 10000;
  }

  let neg = num < 0 ? true : false,
    newNum;

  if (num < truncLimit && num > 0 || num < 0 && num > -truncLimit) {
    return num;
  }

  num = Math.abs(num);

  if (num >= 1000000) {
    newNum = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= truncLimit) {
    newNum = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    newNum = num;
  }

  return neg ? ('-' + newNum) : newNum;
}

export function setRates(newRates) {
  rates = newRates;
}

export function performSingleConversion(val, toCurrency, fromCurrency) {


  let currencyKey = fromCurrency + '-' + toCurrency;
  let rate = rates[currencyKey];
  if (!rate) {
    let inverseRate = rates[toCurrency + '-' + fromCurrency];
    if (inverseRate) {
      rate = 1 / inverseRate;
    }
    if (!rate && toCurrency === fromCurrency) {
      rate = 1;
    } else {
      rate = 0;
    }
  }

  if (rate && fromCurrency === 'zar' && toCurrency !== 'zar') {
    // add percentage when converting ZAR base currency to get from mid-rate to buy rate that we use. defined in pricing config or default 3
    rate = rate * window.timbuktu.services.whitelabel.agency.meta.pcEzarmm;
  } else if (fromCurrency !== toCurrency) {
    // add percentage when converting other base currencies to get from mid-rate to buy rate that we use. defined in pricing config or default 1
    rate = rate * window.timbuktu.services.whitelabel.agency.meta.pcEmm;
  }

  let converted = val * rate;
  return converted
}

export function getProportionalPriceHash(amount, currency, priceHash) {
  let hash = {
    val: priceHash,
    toCurrency: currency,
    roundToClosest: 0,
    truncate: false,
    markPricesUp: false
  }

  let total = convertCurrencyHash(hash);
  let ratio = amount / total;
  // ratio in target currency
  let proportionalHash = {};
  Object.keys(priceHash).forEach((currencyKey) => {
    proportionalHash[currencyKey] = Math.round(priceHash[currencyKey] * ratio * 100) / 100;
  })

  return proportionalHash;
}

export function addAutoMarkupAndFees(basePrice, settlementCurrency) {
  if (window.timbuktu.services.whitelabel.isForAgency) {
    return basePrice;
  }
  let price = basePrice;
  price = price / window.timbuktu.services.whitelabel.agency.meta.pcTfmm;
  if (['usd'].includes(settlementCurrency)) {
    // Extra 3% markup for USD
    price = price * window.timbuktu.services.whitelabel.agency.meta.pcNam;
  }


  return price;
}

export function performCurrencyHashConversion(hash, toCurrency, markPricesUp) {
  let converted = 0;
  Object.keys(hash).forEach((currencyKey) => {
    converted += performSingleConversion(hash[currencyKey], toCurrency, currencyKey, markPricesUp);
  })
  return converted
}

export function convertCurrencyHash(hash) {
  return convertCurrency(hash.val, hash.toCurrency, hash.fromCurrency, hash.roundToClosest, hash.truncate, hash.markPricesUp);
}
export function convertCurrency(val, toCurrency, fromCurrency, roundToClosest = 10, truncLimit, markPricesUp = true) {
  if (!val) {
    return 0;
  }

  let converted = 0;
  if (isNaN(val)) {
    converted = performCurrencyHashConversion(val, toCurrency, markPricesUp);
  } else {
    converted = performSingleConversion(val, toCurrency, fromCurrency, markPricesUp);
  }

  if (markPricesUp) {

    let currentCurrency = window.timbuktu.services.settings.get('currentCurrency');
    // add in the fees that we are charged by stripe
    converted = addAutoMarkupAndFees(converted, currentCurrency);
  }

  var amount;
  if (roundToClosest == 'skipRounding') {
    amount = converted;
  } else {
    amount = roundToClosest == 0 ? Math.floor(converted + 0.5) : roundPrice(converted, roundToClosest);
  }

  return truncLimit ? truncate(amount, truncLimit) : amount;
}

export function addSecondCurrencyHashContentsIntoFirstHash(targetHash, sourceHash={}) {
  Object.keys(sourceHash).forEach((currencyKey) => {
    if (!targetHash[currencyKey]) {
      targetHash[currencyKey] = 0;
    }
    targetHash[currencyKey] += parseFloat(sourceHash[currencyKey]);
  })
  return targetHash;
}
export function subtractSecondCurrencyHashContentsFromFirstHash(targetHash, sourceHash={}) {
  Object.keys(sourceHash).forEach((currencyKey) => {
    if (!targetHash[currencyKey]) {
      targetHash[currencyKey] = 0;
    }
    targetHash[currencyKey] -= parseFloat(sourceHash[currencyKey]);
  })
  return targetHash;
}
export function duplicateCurrencyHash(sourceHash) {
  let newHash = {};
  addSecondCurrencyHashContentsIntoFirstHash(newHash, sourceHash);
  return newHash;
}

export function getBrowserPriceHash(priceHash) {
  let browserPriceHash = duplicateCurrencyHash(priceHash);

// Note that this wouldn't be used on a price hash where transaction fees are going to be applied. This is used on quotesAndBeyond

  // It is possible for us to have quotes that are fixed in a guest currency
  // This means the quote will have the underlying currencies for cost of sales with an override to zero and cost of sales of zero in guest currency with override price for what they are guaranteed in their currency
  // We remove the zero cost currencies as they shouldn't be shown to guests
  Object.keys(browserPriceHash).forEach((currencyKey) => {
    if (parseInt(browserPriceHash[currencyKey])==0) {
      delete browserPriceHash[currencyKey];
    }

  })
  return browserPriceHash;
}

export function formatConvertedPrice(hash) {
  let formatter = hash.isText ? textFormatters[hash.toCurrency] : formatters[hash.toCurrency];

  if (formatter) {
    return formatter(hash.converted);
  }
}

export function formatCurrencyHash(hash) {
  return formatCurrency(hash.val, hash.toCurrency, hash.fromCurrency, hash.roundToClosest, hash.isText, hash.truncate, hash.markPricesUp);
}

export function formatCurrency(val, toCurrency, fromCurrency, roundToClosest = 10, isText, truncate, markPricesUp = true) {

  let _toCurrency = toCurrency || window.timbuktu.services.settings.get('currentCurrency'),
    _fromCurrency = fromCurrency,
    _truncate = truncate,
    _roundToClosest = (roundToClosest || roundToClosest == 0) ? roundToClosest : 10;

  let formatter = isText ? textFormatters[_toCurrency] : formatters[_toCurrency];
  let converted = convertCurrency(val, _toCurrency, _fromCurrency, _roundToClosest, _truncate, markPricesUp);

  if (formatter) {
    return formatter(converted);
  }

}

export default Helper.extend({

  settings: service(),

  onCurrencyChange: observer('settings.currentCurrency', function() {
    this.recompute();
  }),

  compute([val, fromCurrency, toCurrency, roundToClosest, isText, truncate, markPricesUp], namedArgs) {
    this.settings.currentCurrency; //Have to access this value in order for the recompute to trigger in the observer

    return htmlSafe(formatCurrency(val || namedArgs.val, (toCurrency || namedArgs.toCurrency) || this.get('settings.currentCurrency'), (fromCurrency || namedArgs.fromCurrency), (roundToClosest || namedArgs.roundToClosest), (isText || namedArgs.isText), (truncate || namedArgs.truncate), (markPricesUp || namedArgs.markPricesUp)));
  }
})
