import Mixin from '@ember/object/mixin';

import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import PrettyQuery from 'b5b/mixins/pretty-query';
import MapSearchingRouteMixin from 'b5b/mixins/map-searching/route';

import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Mixin.create(PrettyQuery, MapSearchingRouteMixin, {

  ui: service(),
  screen: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  xplorer: service('components/x-plorer'),
  pills: service(),
  messageBus: service(),
  router: service(),

  queryParams: {
    selectedContinentNames: {
      replace: true
    },
    selectedCountryNames: {
      replace: true
    },
    selectedRegionNames: {
      replace: true
    },
    selectedExperienceNames: {
      replace: true
    },
    selectedLodgeNames: {
      replace: true
    },
    sort: {},
    polishLevel: {}
  },

  per_page: 18,

  selectedModelType: 'selectedCountryNames',
  selectedModelTypeNameReference: null,

  headTags() {

    let model = this.currentModel;
    let title = model.get('name');
    if (this.whitelabel.isForTimbuktu) {
      title = `Luxury ${model.name}`
      if (model.isSafari) {
        title += ' Safari';
      }
      title += ' Experiences';
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }
    var description = `Discover experiences in ${model.name} with our fully customizable trip ideas and our travel experts to give you all the help and advice you need.`;
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;
    return generateMeta({
      description,
      'og:title': title,
      link,
      robots
    });
  },

  setupController(controller, model) {
    this._super(...arguments);

    this.set('ui.viewingMapListing', true);

    this.get('xplorer').setupController(controller, this.controller.entitiesLoaded.bind(this.controller));

    if (controller && model) {
      this.xplorer.addFilter(model)
      model.get('associatedEntities') && model.get('associatedEntities').forEach((associatedEntity)=> {
        if (associatedEntity.get('associatedRegion')) {
          this.xplorer.addFilter(associatedEntity.get('associatedRegion'))
        }
      })
    }

    controller.setProperties({
      reset: false,
      firstTimeLoad: false,
      parentEntity: model,
      isRegional: true
    });

    trackEvent('experiences:view');
    controller.loadExperiencesTask.perform();

    this.set('pills.activeType', 'experiences');
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {

      this.clearFilters(controller);
      // reset the properties not related to filters

      controller.setProperties({
        zoom: 3,
        mapInstance: null,
        center: null,
        searchMode: 'loading',
        firstTimeLoad: true,
        mapOnlyView: null,
        filterOnlyView: null
      });

      this.get('xplorer').teardownController();
      this.set('ui.viewingMapListing', false);
      this.set('pills.activeType', null);
    }
  },

  clearFilters(controller) {
    controller.setProperties({
      selectedCountryNames: [],
      selectedRegionNames: [],
      selectedExperienceNames: [],
      selectedLodgeNames: [],
      page: 1,
      isRegional: null,
      polishLevel: 'default',
      sort: null,
      filters: [],
      additionalFilter: null,
    });
  },

  activate() {
    this._super(...arguments);
    this.get('screen').addBreakpoints({
      perPaneView: "(max-width: 1373px)"
    });
  },

  deactivate() {
    this._super(...arguments);
    this.get('screen').removeBreakpoints({
      perPaneView: "(max-width: 1373px)"
    })
  },

  actions: {

    showNewCustomExperience() {
      // The lodge version of this clears filters here
      this.clearFilters(this.get('controller'));
      this.set('controller.searchMode', 'destinationChange');

      if (this.controller.polishLevel=='default') {
        this.set('controller.polishLevel', 'personal')
      }

      this.set('controller.sort', 'created_at_desc')
      this.messageBus.publish('suggestions-refresh');
      this.controller.loadExperiencesTask.perform();
    },

    resetFilters() {
      this.resetController(this.controller, true);
      this.set('controller.searchMode', 'destinationChange');
      this.controller.loadExperiencesTask.perform();
    }


  }
});
