import Component from '@ember/component';

export default Component.extend({
  tagName: 'label',
  classNames: ['t-check'],

  name: null,
  checked: null,
  onChange: null,
  disabled: false,
  onlyUpdateCheckedThroughOnChange: false,

  actions: {
    onChange(e) {
      if (!this.onlyUpdateCheckedThroughOnChange) {
        this.set('checked', e.target.checked);
      }      
      if (this.onChange) {
        this.onChange();
      }
    }
  }

});
