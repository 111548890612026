import { helper as buildHelper } from '@ember/component/helper';

/*
  Note: doesnt update with array - best for loops, also look at array-contains
*/

export function inArray([array, value]) {
  return array.includes(value);
}

export default buildHelper(inArray);
