import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  reads
} from '@ember/object/computed';
import {
  htmlSafe
} from '@ember/string';

export default Component.extend({
  
  ui: service(),
  whitelabel: service(),
  classNameBindings: ['imgSrc:img:text', ':site-logo'],


  click() {
    if (this.onClick) {
      this.onClick();
    }
  },

  didInsertElement() {
    this._super(...arguments);
  
    if(this.setNavHeight && this.whitelabel.agency.profileImageUrl) {
      this.set('boundLoadCallback', run.bind(this.ui, 'setAgencyTopNavHeight', this));
      this.$('img')[0].addEventListener('load', this.boundLoadCallback);        
    } else if(this.setNavHeight) {
      this.ui.setAgencyTopNavHeight()
    }
  },

  willDestroyElement() {
    if (this.boundLoadCallback && this.$('img')[0]) {
      this.$('img')[0].removeEventListener('load',  this.boundLoadCallback);
    }  
  },

  @computed('preferDarkLogo', 'whitelabel.agency.darkProfileImageUrl', 'whitelabel.agency.profileImageUrl')
  imgSrc(preferDarkLogo, darkProfileImageUrl, profileImageUrl) {
    if (preferDarkLogo && darkProfileImageUrl) {
      return darkProfileImageUrl;
    } else {
      return profileImageUrl;
    }    
  },

  @computed('ui.agencyTopNavHeight')
  siteLogoStyle (agencyTopNavHeight) {
    if (this.whitelabel.agency.profileImageWidth) {
      return htmlSafe(`max-width: ${this.whitelabel.agency.profileImageWidth}px;`);
    }
    
  },
   

});
