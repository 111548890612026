import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['trip-inclusions'],
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null

});
