import MapListing from 'b5b/components/map-listing/component';

import {
  inject as service
} from '@ember/service';
import {
  or
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import {
  maxLodgePriceForRange,
  minLodgePriceForRange
} from 'b5b/utils';

export default MapListing.extend({
  tagName: '',

  ui: service(),
  entityModals: service(),
  screen: service(),
  scroll: service(),
  tripService: service('trip'),
  historyService: service(),
  messageBus: service(),
  xplorer: service('components/x-plorer'),

  mapOnlyViewTitle: 'Properties',

  // referenced in parent component
  minPriceForRange: minLodgePriceForRange,
  maxPriceForRange: maxLodgePriceForRange,

  @or('xplorer.isLoading', 'loadLodgesTask.isRunning') lodgesLoading: false,

  init() {
    this._super(...arguments);
    this.get('messageBus').subscribe('pill-added', this, this.reload);
    this.get('messageBus').subscribe('pill-removed', this, this.reload);
    this.get('messageBus').subscribe('suggestions-reset', this, this.reload);
    this.get('messageBus').subscribe('suggestions-refresh', this, this.reload);
  },

  reload() {
    this.get('loadLodgesTask').perform();
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.loader);
  },

  actions: {

    setSort(sort) {
      this.set('sort', sort);
      this.set('page', 1);
      this.messageBus.publish('suggestions-refresh');
    },

    resetSuggestionsCategory(name) {
      this._super(...arguments);
      // extends action in parent component
      switch (name) {

        case 'selectedAutoBudgetNames':
          this.set('selectedAutoBudgetNames', []);
          break;

        case 'selectedLodgeStyleNames':
          this.set('selectedLodgeStyleNames', []);
          break;

        case 'selectedBudget':
          this.set('selectedBudget', [minLodgePriceForRange(), maxLodgePriceForRange()]);
          break;

        case 'showOnlyWithOffers':
          this.set('showOnlyWithOffers', false);
          break;

        case 'exclusiveUseOnly':
          this.set('exclusiveUseOnly', false);
          break;


        default:
          break;
      }
    },

    openModal(options) {
      if (this.changingLodge) {
        this.entityModals.addModalPermission('perm-add-lodge');
      }
      if (this.changingLodge) {
        this.entityModals.openModal({
          model: options.model
        });
      }
    }
  }
});
