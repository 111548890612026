import Component from '@ember/component';
import { inject as service } from "@ember/service";
import { getErrorsHashFromServerResponse } from "b5b/utils";

export default Component.extend({
  classNames: ['video-row'],

  ui: service(),
  showingShouldDeleteModal: false,
  guestToRemove: null,
  videoTypes: ['tripclip', 'youtube', 'loom'],

  actions: {

  },
});
