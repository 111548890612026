import xItineraryStageEdit from "b5b/components/x-itinerary/stage/edit/component";
import {
  transferDescriptionParagraph,
  prevNonTransferStage,
  nextNonTransferStage,
} from "b5b/utils";
import { TRANSFER_VEHICLE_TYPES } from "b5b/utils";
import { computed } from "ember-decorators/object";

export default xItineraryStageEdit.extend({
  classNames: ["transfer"],
  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get("stage.transfer.duration")) {
      let hours = Math.floor(this.get("stage.transfer.duration") / 60),
        minutes = this.get("stage.transfer.duration") % 60;

      this.setProperties({
        hours: hours,
        minutes: minutes,
      });
    }

    if (
      this.stage.isTemplateTransferStage &&
      this.stage.templateTransfer &&
      (!this.stage.description || this.stage.description == "")
    ) {
      let description = transferDescriptionParagraph(null, {
        prevStage: prevNonTransferStage(this.stages, this.stageIndex),
        nextStage: nextNonTransferStage(this.stages, this.stageIndex),
        transfer: this.stage.transfer,
      });
      this.set("stage.templateTransfer.description", description);
    }
  },




});
