import DS from 'ember-data';

export default DS.Model.extend({

  name: DS.attr(),
  email: DS.attr(),
  callToAction: DS.attr(),
  location: DS.attr()

});
