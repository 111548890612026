import {
  helper as buildHelper
} from '@ember/component/helper';

// Expects lodge or stage
export function regionCommaCountry(params, namedArgs ) {

  let lodge = namedArgs.lodge;
  let stage = namedArgs.stage;
  let region = namedArgs.region || lodge && lodge.region || stage && stage.regionProxy

  if (!lodge && !stage && !region) {
    console.log('WARNING --- You need to pass in a lodge, region or stage!!!!')
  }

  if (region && region.get('regionAndCountryAreTheSameThing')) {
    return region.get('country.displayName');
  }

  if (!region) {
    return '';
  }

  let country = region && region.country || lodge && lodge.country || stage && stage.countryProxy

  let displayValue = '';
  if (region.belongsTo('parentRegion').id()) {
    displayValue = `${region.get('parentRegion.displayName')}, `
  }
  if (country) {
    displayValue = `${displayValue}${country.get('displayName')}`
  }
  return displayValue;

}

export default buildHelper(regionCommaCountry);
