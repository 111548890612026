import Component from '@ember/component';

export default Component.extend({
  tagName: 'li',
  classNames: ['suggestion-tab'],

  // This only needs to be done for pills with image backgroun
  // didInsertElement() {
  //   let rect = this.$()[0].getBoundingClientRect();
  //   this.set('width', rect.width);
  //   this.set('height', rect.height);
  // },

  click() {
    // Continents arent real model, the just represent a model so you need to use model.type
    // THe tab object passed here is the actual model 
    this.sendAction('addSuggestion', this.tab, this.tab.constructor.modelName || this.tab.modelName || this.tab.type, this.index)
  }
});
