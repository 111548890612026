import {
  inject as service
} from '@ember/service';
import {
  reads
} from '@ember/object/computed';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  isEmpty,
  isNone
} from '@ember/utils';
import {
  observer
} from '@ember/object';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

import {
  copyTextToClipboard
} from 'b5b/utils';
import { trackEvent } from '../utils';
import {
  htmlSafe
} from '@ember/string';

const searchTable = [{
  term: 'terms conditions johnny atta about',
  name: 'About Page',
  routeName: 'about'
}];

export default Controller.extend({
  queryParams: ['buster', 'iframeResize', 'gclid', 'isForAgency', 'mapOnlyView', 'filterOnlyView', 'xeDate', 'darkMode'],

  lodgesController: controller('lodges'),
  lodgeController: controller('lodge.index'),
  router: service(),
  store: service(),
  scroll: service(),
  ui: service(),
  whitelabel: service(),
  tripService: service('trip'),
  settings: service(),
  feefo: service(),
  templateManager: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  // tripIndexController: inject.controller('trip.index'),

  @alias('ui.showingFullScreenEmailSubscription') showingFullScreenEmailSubscription: false,
  emailCaptured: null,

  @alias('ui.isPrimarySearchOpen') isPrimarySearchOpen: false,

  darkMode: undefined,
  email: '',

  watchBuster: observer('buster', function() {
    if (this.get('isFastBoot')) {
      return;
    }
    this.set('config.buster', Boolean(this.get('buster')))
  }).on('init'),

  @computed('currentRouteName')
  dashedRouteName(currentRouteName) {
    if (isNone(currentRouteName)) {
      return '';
    }
    return currentRouteName.replace(/\./g, '-');
  },

  @computed('ui.agencyTopNavHeight')
  navSpacerStyle(agencyTopNavHeight) {
    return htmlSafe(`height: ${agencyTopNavHeight}px`);
  },

  @computed('ui.agencyTopNavHeight', 'ui.entityNavStuckToTop')
  templatesRightHandTop(agencyTopNavHeight, entityNavStuckToTop) {
    let height = 54; //Default height of entity nav
    if (!entityNavStuckToTop) {
      height += agencyTopNavHeight;
    }
    return height;
  },

  @computed('router', 'ui.shareEntityType', 'ui.shareEntity', 'isFastBoot')
  shareUrl(router, shareEntityType, shareEntity, isFastBoot) {
    if (isFastBoot) {
      return this.get('fastboot.request.path');
    }

    if (shareEntityType=='share-priceless-trip') {
      return window.location.origin + "/trip/" + shareEntity.get('shareVersionId');
    } else {
      return `${window.location.origin}${router.urlFor(shareEntityType, shareEntity)}`;
    }


  },

  @computed('ui.shareEntity', 'ui.shareEntityType')
  shareText(shareEntity, polishLevel) {
    let output = "Check out ";
    let entityName = shareEntity.get('name');

    switch (polishLevel) {
      case 'trip':
        output += 'this trip';
        break;
      case 'lodge':
        output = output + entityName + " lodge";
        break;
      default:
        if (entityName) {
          output += entityName;
        } else {
          output = 'Check this out';
        }
    }

    return output;
  },

  @computed('currentRouteName', 'ui.showBasicNav', 'ui.showSideNav', 'whitelabel.isForAgency', 'tripService.currentTrip')
  isFooterHidden(currentRouteName, showBasicNav, showSideNav, isForAgency, currentTrip) {
    return isForAgency ||
      currentRouteName === 'trips' ||
      currentRouteName === 'lodges' ||
      currentRouteName === 'trip.index.quote' ||
      currentRouteName === 'trip.index.index' && currentTrip.get('itinerary.stateQuoteAndAfter') ||
      currentRouteName === 'trip.index.video' && currentTrip.get('itinerary.stateQuoteAndAfter') ||
      currentRouteName === 'trip.index.login' ||
      currentRouteName === 'trip.index.enquire' ||
      currentRouteName === 'trip.index.editor' ||
      currentRouteName === 'country.regions' ||
      currentRouteName === 'country.lodges' ||
      currentRouteName === 'country.experiences' ||
      currentRouteName === 'country.routes' ||
      currentRouteName === 'lodge.regions' ||
      currentRouteName === 'lodge.experiences' ||
      currentRouteName === 'lodge.routes' ||
      currentRouteName === 'experience.experiences' ||
      currentRouteName === 'experience.routes' ||
      currentRouteName === 'region.experiences' ||
      currentRouteName === 'region.lodges' ||
      currentRouteName === 'region.regions' ||
      currentRouteName === 'region.routes' ||
      currentRouteName === 'region-experiences' ||
      currentRouteName === 'countries' ||
      currentRouteName === 'when-to-go.month.countries' ||
      currentRouteName === 'when-to-go.month.routes' ||
      currentRouteName === 'regions' ||
      currentRouteName.indexOf('library') > -1 ||
      currentRouteName.indexOf('trip.index.dashboard') > -1 ||
      showBasicNav ||
      showSideNav;
  },

  @computed('currentRouteName', 'ui.showBasicNav', 'ui.showSideNav', 'whitelabel.isForAgency', 'tripService.currentTrip.itinerary.state')
  blockCorona(currentRouteName, showBasicNav, showSideNav, isForAgency, tripState) {
    let blockCorona = isForAgency ||
      (currentRouteName.indexOf('countries') > -1) ||
      (currentRouteName.indexOf('lodges') > -1) ||
      (currentRouteName.indexOf('regions') > -1) ||
      (currentRouteName.indexOf('experiences') > -1) ||
      (currentRouteName.indexOf('edit') > -1) ||
      (currentRouteName.indexOf('wizard') > -1) ||
      (currentRouteName.indexOf('trip.index.deposit-success') > -1) ||
      (currentRouteName.indexOf('trip.') > -1 && ['quote','deposit_paid'].includes(tripState)) ||
      showBasicNav ||
      showSideNav;
      let extraBannerHeight = blockCorona ? 0 : 64;

        cssVars({
          variables: {
            '--extraBannerHeight': extraBannerHeight + 'px',
          }
        });

      return blockCorona
  },

  @computed('currentRouteName', 'tripService.currentTrip')
  dontMentionCancellationPolicy(currentRouteName, currentTrip) {
    return false;
      // return (currentRouteName !== 'trip.new') &&
      // currentRouteName.indexOf('trip.') === 0 &&
      // currentTrip.userTrip &&
      // currentTrip.itinerary.stateDepositPaidAndAfter;

  },

  @computed('currentRouteName', 'tripService.hasBackButton', 'ui.showHeroNav', 'mapOnlyView', 'filterOnlyView')
  isFooterHeaderHidden(currentRouteName, hasBackButton, showHeroNav, ) {

    // console.log(currentRouteName)
    return showHeroNav ||
      (currentRouteName === 'wizard.index') ||
      (currentRouteName === 'wizard.step') ||
      (currentRouteName === 'iframe-embed') ||
      (currentRouteName === 'trip.index.deposit-success') ||
      (currentRouteName === 'trip.index.brochure') ||
      (currentRouteName === 'lodge' && hasBackButton);
  },

  @computed('router.currentRouteName', 'screen.isMapOnlyView', 'scroll.isModalOpen')
  hideBottomLeftWidget(currentRouteName, isMapOnlyView, isModalOpen) {
    return (currentRouteName.indexOf('trip.index.edit') > -1) ||
    (isMapOnlyView && currentRouteName=='countries') ||
    (currentRouteName === 'trip.index.brochure') ||
    (currentRouteName === 'ask') ||
    (currentRouteName.indexOf('trip.index.pay') > -1) ||
    (currentRouteName.indexOf('trip.index') > -1) ||
    (currentRouteName.indexOf('enquire') > -1) ||
    (currentRouteName.indexOf('enquiry-success') > -1) ||
    (currentRouteName.indexOf('trip.index.dashboard.invoice') > -1) ||
    (currentRouteName.indexOf('trip.index.deposit-success') > -1) ||
    isModalOpen;
  },

  @computed('router.currentRouteName')
  accessingQuoteTools(currentRouteName) {
    return currentRouteName.indexOf('trip.index.edit.') > -1 || currentRouteName == 'trip.index.edit'
  },

  @computed('currentRouteName', 'session.currentUser', 'tripService.currentTrip.itinerary.afterEnquiry', 'isFastBoot')
  isSignupFooterHidden(currentRouteName, currentUser, isTripAfterEnquiry, isFastBoot) {
    return isFastBoot ||
      currentRouteName === 'index' ||
      currentRouteName === 'join-newsletter' ||
      currentUser ||
      (currentRouteName === 'trip.index.index' && isTripAfterEnquiry);
  },


  @computed('emailCaptured', 'session.currentUser', 'session.currentUser.isSubscribedToNewsletter', 'session.userDetailsExist', 'showingFullScreenEmailSubscription', 'screen.isMobileOnly', 'isFastBoot')
  needToCaptureEmail(emailCaptured, currentUser, currentUserIsSubscribed, userDetailsExist, showingFullScreenEmailSubscription, isMobile, isFastBoot) {
    if (isMobile || isFastBoot) {
      return;
    }

    let bool;

    if (currentUser) {
      bool = !currentUserIsSubscribed;
    } else {
      bool = !emailCaptured && !userDetailsExist;
    }

    return bool;
  },


  actions: {

    inspireMeWidgetClicked() {
      trackEvent('inspire-me-widget:clicked');
    },
    requestOnboarding() {
      this.whitelabel.agency.requestOnboarding();
      this.set('ui.showNeedsOnboardingModal', {onboardingRequested: true})
    },
    inspireMeWidgetMouseOver() {
      trackEvent('inspire-me-widget:mouse-over');
    },

    openSearch(searchInitiator) {
      this.set('scroll.isMenuExpanded', false);
      this.set('isPrimarySearchOpen', true);
      trackEvent('link:click:'+searchInitiator)
    },

    clearGeneralMessage() {
      this.set('ui.showGeneralMessageModal', false);
      this.set('ui.generalMessageHeading', null);
      this.set('ui.generalMessage', null);
    },

    copyLinkToClipboard() {
      try {
        copyTextToClipboard(this.get('shareUrl'));
        this.set('ui.copyLinkText', 'Link copied');
      } catch (err) {
        this.set('ui.copyLinkText', 'Error :(');
      }
    }


  }

});
