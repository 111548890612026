import {
  helper as buildHelper
} from '@ember/component/helper';
import EmberObject from '@ember/object';

export function tripsLinkExperienceQueryParams([experience]) {
  var hashParams = {};
  if (experience.get('isRegional') && experience.belongsTo('region').id()) {
    hashParams.selectedRegionNames = [experience.get('region.name')];
  }
  if (experience.get('isCategory') || experience.get('isTripType')) {
    hashParams.selectedExperienceNames = [experience.get('name')];
  }

  return EmberObject.create({
    isQueryParams: true,
    values: hashParams
  });
}

export default buildHelper(tripsLinkExperienceQueryParams);
