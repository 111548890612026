import EmberObject from '@ember/object';
import {
  isPresent
} from '@ember/utils';
import {
  A
} from '@ember/array';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  filterBy,
  equal,
  or,
  empty,
  not,
  mapBy
} from 'ember-decorators/object/computed';
import {
  uniqBy,
  addSurchagesIfNecessary,
  STRIPE_CURRENCIES,
  FLYWIRE_CURRENCIES,
  convertTemplateTransferToCustomTransfer
} from 'b5b/utils';
import moment from 'moment';
import {
  memberAction
} from 'ember-api-actions';
import {
  DATE_FORMAT
} from 'b5b/transforms/b5bdate';
import {
  convertCurrencyHash,
  addSecondCurrencyHashContentsIntoFirstHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash,
  getBrowserPriceHash
} from 'b5b/helpers/format-currency';
import {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';

export const TRANSFER_TYPE_SELF_DRIVE = 'self-drive';
export const TRANSFER_TYPE_FLYING = 'flying';
export const TRANSFER_TYPES = ['standard', TRANSFER_TYPE_SELF_DRIVE, TRANSFER_TYPE_FLYING];
export const SPECAL_TRANSFER_TYPES = [TRANSFER_TYPE_SELF_DRIVE, TRANSFER_TYPE_FLYING];


import ItineraryStates from 'b5b/mixins/itineraries/itinerary-states';
import ItineraryProcessPath from 'b5b/mixins/itineraries/itinerary-process-path';
import PricingModel from 'b5b/mixins/itineraries/pricing-model';

export default DS.Model.extend(ItineraryStates, ItineraryProcessPath, PricingModel, {

  settings: service(),
  session: service(),
  whitelabel: service(),

  validCharges: null,
  processPath: DS.attr(),
  numNights: DS.attr(),
  transferType: DS.attr(),
  pricingModel: DS.attr(),
  specialOfferId: DS.attr(),
  travelStatus: DS.attr(),
  expectedStartDate: DS.attr('b5bdate'),
  blockGenericCancellationPolicy: DS.attr(),
  // default value required for autoAllocateStartAndEnd as otherwise the start and end locations aren't assigned when creating a trip
  autoAllocateStartAndEnd: DS.attr('boolean', {
    defaultValue: true
  }),

  manualStartLocation: DS.attr('boolean', {
    defaultValue: false
  }),
  manualEndLocation: DS.attr('boolean', {
    defaultValue: false
  }),

  tripRatesSpecialOffer: DS.belongsTo('tripRatesSpecialOffer', {
    inverse: null,
    async: false
  }),

  budget: DS.attr(),
  price: DS.attr(),
  overview: DS.attr(),
  estimate: DS.attr(),
  updatedAt: DS.attr(),
  accommodationStyle: DS.attr('string', {
    readOnly: true
  }),
  trip: DS.belongsTo('trip', {
    inverse: 'defaultItinerary'
  }),
  user: DS.belongsTo('user', {
    readOnly: true
  }),
  enquiry: DS.belongsTo('enquiry', {
    async: false
  }),

  quote: DS.belongsTo('quote', {
    async: false
  }),
  firstSchedule: DS.belongsTo('schedule', {
    async: false,
    inverse: null
  }),

  quoteValidUntil: DS.attr('b5bdate'),
  quoteBalanceDueBy: DS.attr('b5bdate'),
  forceBalanceDueBy: DS.attr(),
  quoteIntro: DS.attr(),
  partners: DS.hasMany('partner', {
    async: false,
    inverse: null
  }),

  generalNote: DS.attr(),
  contactsNote: DS.attr(),
  specialRequest: DS.attr(),
  consultantNote: DS.attr(),
  migratedPerGuestPricing: DS.attr(),
  needsPerGuestMigration: DS.attr(),

  allGuestsPersonalDetailsComplete: DS.attr(),
  allGuestsInsuranceDetailsComplete: DS.attr(),

  termsAcceptedDateTime: DS.attr('b5bdate-time'),
  termsAcceptedUrl: DS.attr(),

  disablePaymentRemindersUntil: DS.attr('b5bdate'),

  videos: DS.hasMany('video', {
    async: false,
    inverse: null
  }),

  // TODODEALGUESTS we shoudl only have itineraryGuests. Remove guests. use mapBy below to replace guests
  guests: DS.hasMany('guest', {
    async: false
  }),
  itineraryGuests: DS.hasMany('itineraryGuest', {
    async: false
  }),

  flightsSentByGuest: DS.attr(),
  flightsConfirmed: DS.attr(),
  itineraryConfirmed: DS.attr(),
  balanceSentByGuest: DS.attr(),

  numAdults: DS.attr('number', {
    defaultValue: 2
  }),

  numYoungAdults: DS.attr('number', {
    defaultValue: 0
  }),

  numChildren: DS.attr('number', {
    defaultValue: 0
  }),
  numInfants: DS.attr('number', {
    defaultValue: 0
  }),

  state: DS.attr(),
  startDate: DS.attr('b5bdate'),
  startMonth: DS.attr(),
  startYear: DS.attr('number'),

  charges: DS.hasMany('charge', {
    inverse: 'itinerary'
  }),

  whatsIncluded: DS.attr(),
  whatsExcluded: DS.attr(),
  cancellationPolicy: DS.attr(),


  stages: DS.hasMany('stage', {
    async: false
  }),

  payments: DS.hasMany('payment', {
    async: false,
    inverse: null
  }),

  addOns: DS.hasMany('addOn', {
    async: false
  }),

  setDisablePaymentRemindersUntil: memberAction({
    path: 'set-disable-payment-reminders-until'
  }),

  setFlightsSentByGuest: memberAction({
    path: 'set-flights-sent-by-guest'
  }),

  setFlightsConfirmed: memberAction({
    path: 'set-flights-confirmed'
  }),

  setProcessPath: memberAction({
    path: 'set-process-path'
  }),

  setBalanceSentByGuest: memberAction({
    path: 'set-balance-sent-by-guest'
  }),

  setTermsAcceptedDateTime: memberAction({
    path: 'set-terms-accepted-date-time'
  }),

  updateItinerary: memberAction({
    path: 'update-itinerary'
  }),

  updateItineraryTravelStatus: memberAction({
    path: 'update-itinerary-travel-status'
  }),

  setItineraryConfirmed: memberAction({
    path: 'set-itinerary-confirmed'
  }),

  sendLatestGuestDetails: memberAction({
    path: 'send-latest-guest-details'
  }),

  addItineraryPartner: memberAction({
    path: 'add-itinerary-partner'
  }),

  removeItineraryPartner: memberAction({
    path: 'remove-itinerary-partner'
  }),

  sendEvent: memberAction({
    path: 'send-event'
  }),
  emailBrochureToCurrentUser: memberAction({
    path: 'email_brochure_to_current_user'
  }),


  // TODODEALGUESTS
  // @mapBy('itineraryGuests', 'guest') guests: null,


  init() {
    // Always remember https://guides.emberjs.com/release/object-model/classes-and-instances/#toc_initializing-instances
    // Otherwise array is shared
    // The validCharges is updated from the draft itinerary.. what a mess!
    if (this._internalModel.modelName=='itinerary') {
      this.set('validCharges', []);
    }
  },

  @computed('stateQuote', 'isInstantBookable')
  isBookable(stateQuote, isInstantBookable) {
    return stateQuote || isInstantBookable;
  },

  @computed('trip.isPackage', 'trip.isSpecial')
  isInstantBookable(isPackage, isSpecial) {
    return isPackage || isSpecial;
  },

  @computed('stateBeforeQuote', 'isInstantBookable')
  beforeQuoteNotInstantBookable(stateBeforeQuote, isInstantBookable) {
    return stateBeforeQuote && !isInstantBookable;
  },

  @computed('guests.@each')
  adults(guests) {
    return guests.filterBy('ageType', 'adult');
  },

  @computed('guests.@each')
  youngAdults(guests) {
    return guests.filterBy('ageType', 'youngAdult');
  },

  @computed('guests.@each')
  children(guests) {
    return guests.filterBy('ageType', 'child');
  },

  @computed('guests.@each')
  infants(guests) {
    return guests.filterBy('ageType', 'infant');
  },

  @computed('adults.@each', 'youngAdults.@each', 'children.@each', 'infants.@each')
  allGuestTypes(adults, youngAdults, children, infants) {
    let arr = A();

    arr.pushObject(adults);
    arr.pushObject(youngAdults);
    arr.pushObject(children);
    arr.pushObject(infants);

    return arr.sortBy('sequence');
  },

  @computed('numAdults', 'numYoungAdults', 'numChildren', 'numInfants')
  numGuests(numAdults, numYoungAdults, numChildren, numInfants) {
    return (parseInt(numAdults) || 0) + (parseInt(numYoungAdults) || 0) + (parseInt(numChildren) || 0) + (parseInt(numInfants) || 0);
  },

  numRooms: DS.attr('number', {
    defaultValue: 1
  }),

  @computed('stages.[]')
  addOnsAdded(stages) {
    return stages.filterBy('hasAddOnFilters', true).mapBy('addOnFilter');
  },

  logStages() {
    this.get('stages').forEach((stage) => {
      console.log(`stageId: ${stage.get('id')} itineraryId: ${stage.get('itinerary.id')} stageType: ${stage.get('stageType')} entity: ${stage.get('entity.name')}`);
    })
  },

  makeCopy: function() {
    var copy = this.get('store').createRecord('itinerary', {
      budget: this.get('budget'),
      accommodationStyle: this.get('accommodationStyle'),
      startDate: this.get('startDate'),
      startMonth: this.get('startMonth'),
      startYear: this.get('startYear'),
      manualStartLocation: this.get('manualStartLocation'),
      manualEndLocation: this.get('manualEndLocation'),

      numNights: this.get('numNights'),
      transferType: this.get('transferType'),
      numChildren: this.get('numChildren'),
      numInfants: this.get('numInfants'),
      numAdults: this.get('numAdults'),
      numYoungAdults: this.get('numYoungAdults'),
      numRooms: this.get('numRooms'),
      state: this.get('state'),
      email: this.get('email'),
      firstName: this.get('firstName'),
      surname: this.get('surname'),
      message: this.get('message'),
      specialOfferId: this.specialOfferId,
      generalNote: this.generalNote,
      quoteIntro: this.quoteIntro
    });

    if (isPresent(this.get('enquiry'))) {
      copy.set('enquiry', this.get('enquiry'));
      copy.set('enquiry.itinerary', copy);
    }
    if (this.belongsTo('firstSchedule').value()) {
      copy.set('firstSchedule', this.belongsTo('firstSchedule').value().makeCopy())
    }

    this.get('stages').forEach(function(stage) {
      copy.get('stages').addObject(stage.makeCopy());
    });
    return copy;
  },

  getItineraryAttributes() {
    let itineraryAttributes = {
      transfer_type: this.get('transferType'),
      special_offer_id: this.specialOfferId,
      num_adults: this.numAdults,
      num_children: this.numChildren,
      num_inftants: this.numInfants,
      stages_attributes: []
    }
    if (this.get('startDate')) {
      itineraryAttributes.start_date = moment(this.get('startDate')).format(DATE_FORMAT)
    }

    this.get('stages').forEach((stage, index) => {
      if (!stage.isDeleted) {
        stage.set('sequence', index); //This is a hack like in the trip saver as the server respects the sequence property but its not used in the ember app
      }

      let stageJson = stage.serialize();
      if (stage.isCustomTransferStage) {
        stageJson.custom_transfer_attributes = stage.get('customTransfer').serialize();
      }
      itineraryAttributes.stages_attributes.pushObject(stageJson)
    })
    return itineraryAttributes;
  },

  calculatePricingAndNumNights() {
    if (this.get('stages.length') > 0) {

      let itineraryAttributes = this.getItineraryAttributes();
      let priceRequest = this.get('store').createRecord('price-request', {
        itineraryAttributes,
        type: 'itinerary'
      })
      this.set('priceRequestInProgress', true)
      return priceRequest.save().then((priceRequest) => {
        let isManager = this.get('session.currentUser.isManager');
        priceRequest.get('itinerary.stages').forEach((stage, stageIndex) => {

          let correspondingStage = this.get('stages').objectAt(stageIndex);


          if (isManager && correspondingStage.needsTransfer && stage.templateTransfer && stage.isTemplateTransferStage) {
            stage = convertTemplateTransferToCustomTransfer({sourceStage: stage, targetStage: correspondingStage, stageIndex, targetStages: priceRequest.get('itinerary.stages')})
          }

          // Stages need to be set back to default after price request or save
          stage.set('madeTransferAffectingChanges', false);
          stage.set('needsTransfer', false);
          if (!correspondingStage.isDeleted) {
            correspondingStage.setProperties(stage.getProperties('accuratePriceAmount', 'accuratePriceCurrency', 'accuratePriceDate', 'roomsAvailable', 'lodgeAvailable', 'needsTransfer', 'madeTransferAffectingChanges'));
            if (!isManager) {
              correspondingStage.set('templateTransfer', stage.get('templateTransfer'))
            }
          }
        });
        // Basically the price request is made to the server, and then we use the response to update the itinerary in the app.
        // This is done for template trips because you don't want to chenage them on the server but for users own trips you can jsut update them and get new estimate
        this.setProperties(priceRequest.getProperties('numNights', 'estimate', 'updatedAt'));
        this.set('priceRequestInProgress', false)
        return priceRequest
      })
    }
  },

  // If a quote has a settlement currency it should override the users current currency
  @computed('quote.settlementCurrency', 'settings.currentCurrency')
  currencyForSettlement(quoteSettlementCurrency, currentCurrency) {
    let returnCurrency = 'usd'; //Default

    if (quoteSettlementCurrency && this.whitelabel.settlementCurrencies.includes(quoteSettlementCurrency)) {
      returnCurrency = quoteSettlementCurrency;
    } else {
      if (this.get('trip.paymentProcessor')=='flywire' && FLYWIRE_CURRENCIES.includes(currentCurrency)) {
        returnCurrency = currentCurrency;
      }
    }
    return returnCurrency;
  },

  @computed('accomStages.@each.roomType')
  allRoomTypesAdded(stages) {
    return stages.reduce(function(roomTypesPresentThusFar, stage) {
      return roomTypesPresentThusFar && stage.get('roomType');
    }, true);
  },

  @computed('accomStages.@each.roomType')
  accomStagesIncludesAdded(stages) {
    return stages.reduce(function(stageIncludesPresentThusFar, stage) {
      return stageIncludesPresentThusFar && stage.get('whatsIncluded');
    }, true);
  },

  @computed('accomStages.@each.roomType')
  accomStagesExcludesAdded(stages) {
    return stages.reduce(function(stageExcludesPresentThusFar, stage) {
      return stageExcludesPresentThusFar && stage.get('whatsExcluded');
    }, true);
  },

  @computed('stages.firstObject.location', 'stages.firstObject.isLocationStage')
  startLocation(firstLocation, firstStageIsLocation) {
    if (firstStageIsLocation) {
      return firstLocation;
    }
    return null
  },

  @computed('stages.lastObject.location', 'stages.lastObject.isLocationStage')
  endLocation(endLocation, endStageIsLocation) {
    if (endStageIsLocation) {
      return endLocation;
    }
    return null
  },

  @computed('startDate', 'numNights')
  endDate(startDate, numNights) {
    if (startDate) {
      return moment(startDate).add(numNights, 'days').toDate();
    }
  },

  @computed('endDate')
  isPast(endDate) {
    if (endDate) {
      return moment(endDate).isBefore(moment.now());
    }
  },

  @computed('payments.@each.state')
  customPaymentsDue(payments) {
    return payments.filterBy('state', 'unpaid');
  },

  @computed('customPaymentsDue')
  sumCustomPaymentsDuePriceHash(customPaymentsDue) {
    let sumCustomPaymentsDuePriceHash = {}
    customPaymentsDue.forEach((customPayment) => {
      addSecondCurrencyHashContentsIntoFirstHash(sumCustomPaymentsDuePriceHash, customPayment.get('priceHash'))
    })
    return sumCustomPaymentsDuePriceHash;
  },

  @computed('sumCustomPaymentsDuePriceHash', 'quote.balanceDuePriceHash')
  remainingBalanceAfterCustomPaymentsDuePriceHash(sumCustomPaymentsDuePriceHash, balanceDuePriceHash) {
    if (balanceDuePriceHash) { //without this guard clause,there is an error when you log in to see your quote
      let remainingBalanceAfterCustomPaymentsDuePriceHash = duplicateCurrencyHash(balanceDuePriceHash)
      subtractSecondCurrencyHashContentsFromFirstHash(remainingBalanceAfterCustomPaymentsDuePriceHash, sumCustomPaymentsDuePriceHash);
      return remainingBalanceAfterCustomPaymentsDuePriceHash;
    }
  },

  @computed('customPaymentsDue', 'state', 'depositPriceHash', 'quote.balanceDuePriceHash', 'remainingBalanceAfterCustomPaymentsDuePriceHash', 'currencyForSettlement')
  paymentsDue(customPaymentsDue, state, depositPriceHash, balanceDuePriceHash, remainingBalanceAfterCustomPaymentsDuePriceHash, currencyForSettlement) {
    let payments = [];

    let paymentType = this.stateQuote || this.isBookable ? 'deposit' : 'balance';
    if (customPaymentsDue.get('length') > 0 || paymentType=='balance') {
      customPaymentsDue.forEach((customPayment) => {
        payments.pushObject(EmberObject.create({
          paymentType: 'payment',
          customPayment: customPayment,
          priceHash: customPayment.get('priceHash'),
          settlementCurrency: currencyForSettlement,
          heading: customPayment.note
        }))
      })

      if (this.stateDepositPaidAndAfter) {

        let remainder = this.getUsdEstimate(remainingBalanceAfterCustomPaymentsDuePriceHash)
        if (remainder > 5) {
          payments.pushObject(EmberObject.create({
            paymentType,
            priceHash: remainingBalanceAfterCustomPaymentsDuePriceHash,
            paymentReason: 'remainingBalance',
            settlementCurrency: currencyForSettlement,
            heading: 'Remaining balance'
          }))
        }
        let chooseOwnAmountPayment = EmberObject.create({
          paymentType,
          priceHash: null,
          paymentReason: 'chooseOwnAmount',
          settlementCurrency: currencyForSettlement,
          heading: 'Choose your own amount'
        })
        // If there are custom payments then this must go into array with all other payments
        if (customPaymentsDue.length > 1) {
          payments.pushObject(chooseOwnAmountPayment)
        }
        this.set('chooseOwnAmountPayment', chooseOwnAmountPayment)
      }
    } else if (this.stateQuote || this.isInstantBookable) {
      payments.pushObject(EmberObject.create({
        paymentType,
        priceHash: depositPriceHash,
        settlementCurrency: currencyForSettlement,
        heading: 'Payment due'
      }))
    } else {
      let balanceEstimate = this.getUsdEstimate(balanceDuePriceHash)
      if (balanceEstimate > 5) {
        payments.pushObject(EmberObject.create({
          paymentType,
          priceHash: balanceDuePriceHash,
          settlementCurrency: currencyForSettlement,
          heading: 'Payment due'
        }))
      }
    }
    return payments;
  },

  getUsdEstimate(priceHash) {
    return convertCurrencyHash({
      val: priceHash,
      toCurrency: 'usd',
      roundToClosest: 0,
      truncate: false,
      markPricesUp: false
    });
  },

  @computed('paymentsDue')
  totalPaymentsDuePriceHash(payments) {
    var hash = {};
    payments.forEach((payment) => {
      if (payment.get('priceHash')) {
        addSecondCurrencyHashContentsIntoFirstHash(hash, payment.get('priceHash'))
      }
    });
    return hash;
  },

  @computed('startDate')
  daysUntilStart(startDate) {
    return moment(startDate).diff(moment.now(), 'days');
  },

  @computed('quote.balanceDuePriceHash')
  hasBalanceDue(balanceDue) {

    if (!balanceDue) {
      return false;
    }

    let total = convertCurrencyHash({
      val: balanceDue,
      toCurrency: 'usd',
      roundToClosest: 0,
      truncate: false,
      markPricesUp: false
    });

    return total > 0;
  },

  @filterBy('stages', 'isRegionStage', true) regionStages: null,
  @filterBy('stages', 'isLocationStage', false) nonLocationStages: null,
  @filterBy('stages', 'isLodgeStage', true) accomStages: null,
  @filterBy('stages', 'isTransferStage', true) transferStages: null,
  @filterBy('nonTransferStages', 'isLocationStage', false) nonTransferAndLocationStages: null,

  @computed('stages.@each.entity','stages.@each.stageType')
  nonTransferStages(stages) {
    // debugger
    return stages.filter((stage)=> {
      if (!stage.isTransferStage && stage.entity) {
        return true;
      } else {
        return false;
      }
    });
  },

  @computed('nonTransferAndLocationStages.[]', 'accomStageLodgeMapping.[]')
  allStagesHaveLodges(nonTransferAndLocationStages, accomStageLodgeMapping) {
    return nonTransferAndLocationStages.get('length') === accomStageLodgeMapping.get('length');
  },

  @computed('accomStages.[]', 'trip.uniqueCountries')
  countryAccomStageMappings(accomStages, uniqueCountries) {

    return uniqueCountries.map(country => {
      var accomStagesWithoutStopOvers = accomStages.filter((stage) => {
        return stage.get('region.country') === country && !stage.get('region.isStopOver')
      });
      accomStagesWithoutStopOvers = uniqBy(accomStagesWithoutStopOvers, 'region.id');
      return {
        country,
        accomStagesWithoutStopOvers
      };
    });
  },

  @computed('accomStages.[]')
  accomStageLodgeMapping(accomStages) {
    return accomStages.map(stage => {
      let lodge = stage.get('lodge'),
        startDate = stage.get('startDate'),
        numNights = stage.get('numNights');
      return {
        lodge,
        startDate,
        numNights
      };
    });
  },

  @computed('accomStageLodgeMapping')
  uniqAccomStageLodgeMapping(accomStageLodgeMapping) {
    return accomStageLodgeMapping.uniqBy('lodge');
  },

  @computed('accomStages.[]')
  accomStagesSequence(accomStages) {
    return accomStages && accomStages.reduce(function(key, stage) {
      return key += stage.get('lodge.id') + '-';
    }, '');
  },


    // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('updatedAt', 'isInstantBookable')
  totalSellingPriceHash(updatedAt, isInstantBookable) {
    if (isInstantBookable) {
      return getBrowserPriceHash(this.get('estimate.prices.selling'));
    } else {
      return getBrowserPriceHash(this.get('quote.bill.prices.selling'));
    }
  },

    // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('updatedAt', 'isInstantBookable')
  totalSellingOriginalPriceHash(updatedAt, isInstantBookable) {
    if (isInstantBookable) {
      let priceHash = getBrowserPriceHash(this.get('estimate.originalPrices.selling'));
      let keys = Object.keys(priceHash)
      if (keys.length==0) {
        return null;
      }
      return priceHash;
    } else {
      console.log('THIS IS NOT CURRENTLY SUPPORTED ??? !! ???')
      return null;
      // return getBrowserPriceHash(this.get('quote.bill.prices.selling'));
    }
  },

    // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('updatedAt', 'isInstantBookable', 'numGuests')
  averagePerGuestPriceHash(updatedAt, isInstantBookable, numGuests) {
    let priceHash ={};
    if (isInstantBookable) {
      priceHash = getBrowserPriceHash(this.get('estimate.prices.total'));
    } else {
      priceHash = getBrowserPriceHash(this.get('quote.bill.prices.selling'));
    }
    Object.keys(priceHash).forEach((currencyKey) => {
      priceHash[currencyKey] = priceHash[currencyKey] / numGuests;
    })
    return priceHash;
  },

    // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('updatedAt', 'isInstantBookable', 'numGuests')
  averagePerGuestOriginalPriceHash(updatedAt, isInstantBookable, numGuests) {
    let priceHash ={};
    if (isInstantBookable) {
      priceHash = getBrowserPriceHash(this.get('estimate.originalPrices.total'));
    } else {
      console.log('THIS IS NOT CURRENTLY SUPPORTED ??? !! ???')
      return null;
      // priceHash = getBrowserPriceHash(this.get('quote.bill.prices.selling'));
    }
    let keys = Object.keys(priceHash)
    if (keys.length==0) {
      return null;
    }
    keys.forEach((currencyKey) => {
      priceHash[currencyKey] = priceHash[currencyKey] / numGuests;
    })
    return priceHash;
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  @computed('updatedAt', 'isInstantBookable')
  depositPriceHash(updatedAt, isInstantBookable) {
    console.log('generating depositPriceHash')
    if (isInstantBookable) {
      return getBrowserPriceHash(this.get('estimate.prices.deposit'));
    } else {
      return getBrowserPriceHash(this.get('quote.bill.prices.deposit'));
    }
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // Allow the server to specify when additional markup and transaciton fees should be applied
  // but dont mark real quotes up
  @computed('updatedAt', 'isInstantBookable')
  factor(updatedAt, isInstantBookable) {
    if (isInstantBookable) {
      return this.get('estimate.meta.factor');
    } else {
      return undefined;
    }
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of estimate hash comes back from server
  @computed('updatedAt', 'isInstantBookable')
  dateValidForInstantBook(updatedAt, isInstantBookable) {
    return !this.get('estimate.meta') || this.get('estimate.meta.rateClosed') !== true;
  },

  @computed('numAdults', 'numChildren', 'numInfants')
  numGuestsValidForInstantBook(numAdults, numChildren, numInfants) {
    return (numAdults % 2 === 0) && numChildren === 0 && numInfants === 0;
  },

  @computed('numGuestsValidForInstantBook', 'dateValidForInstantBook')
  validForInstantBook(numGuestsValidForInstantBook, dateValidForInstantBook) {
    return numGuestsValidForInstantBook && dateValidForInstantBook;
  },

  setValidCharges: observer('charges.@each.state', function() {

    // WE are doing this in order to stop the itinerary.paymentsDue from regenerating a new array when a failed charge is saved because then the pay-form gets re-inserted after failed card transaction when a failed charge is created
    let validChargeIds = this.get('validCharges').mapBy('id');
    // console.log(validChargeIds)
    this.get('charges').forEach((charge)=> {
      if (charge.id && charge.state && charge.state == 'completed' && !validChargeIds.includes(charge.id)) {
        // console.log('pushing charge', charge.id, charge.state)
        this.get('validCharges').pushObject(charge);
      }
    })
    // validChargeIds = this.get('realItinerary.validCharges').mapBy('id');
    // console.log(validChargeIds)
  }).on('init'),

  setStageDates: observer('stages.@each.numNights', 'startDate', function() {
    var totalNights = 0;

    this.get('stages').forEach((stage) => {
      if (this.startDate) {
        stage.set('startDate', moment(this.get('startDate')).add(totalNights, 'days'));
        stage.set('endDate', moment(this.get('startDate')).add(totalNights+stage.numNights, 'days'));
        totalNights += stage.get('numNights');
      } else {
        stage.set('startDate', null)
        stage.set('endDate', null)
      }
    })

  }),



  /*---------------
    States
  ----------------*/

  // This has moved to b5b/mixins/itineraries/itinerary-states (shared by trip-summaries)

  /*---------------
    Process path
  ----------------*/

  // This has moved to b5b/mixins/itineraries/itinerary-process-path (shared by trip-summaries)

});
