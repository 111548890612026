import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({
  quote: DS.belongsTo('quote'),
  description: DS.attr(),
  discountType: DS.attr('string', {
    defaultValue: 'general'
  }),
  currency: DS.attr(),
  amount: DS.attr(),
  sequence: DS.attr(),
  discountGuests: DS.hasMany('discountGuest'),

  @computed('discountGuests.@each.guest')
  selectedGuests(discountGuests) {
    return discountGuests.mapBy('guest');
  },

  @computed('discountGuests.@each.guest', 'quote.itinerary.guests.@each.id')
  applicableGuests(discountGuests, itinGuests) {
    let guests = []
    if (discountGuests.length > 0) {
      guests = discountGuests.mapBy('guest');
    } else {
      guests = itinGuests;
    }
    if (guests.length > 0 && guests.objectAt(0).content) {
      guests = guests.mapBy('content')
    }
    return guests;
  },

  @computed('applicableGuests.@each.guest', 'quote.itinerary.guests.@each.id')
  excludedGuests(applicableGuests, itinGuests) {
    return itinGuests.filter((guest) => {
      return !applicableGuests.includes(guest);
    });
  }
});
