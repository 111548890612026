import Service, {
  inject as service
} from '@ember/service';
import RSVP, {
  Promise as EmberPromise
} from 'rsvp';
import {
  getRequestParamByName,
  trackEvent,
  getCapuredEmail
} from 'b5b/utils';


import config from 'b5b/config/environment';

export default Service.extend({

  affiliateReadyPromise: null,
  affiliateId: null,
  affiliateVisitorId: null,
  email: null,
  session: service(),

  init() {
    this.executeWhenAffiliateInfoReady(() => {
      this.set('affiliateId', PostAffTracker.getAffInfo().getAffiliateId());
      this.set('affiliateVisitorId', PostAffTracker.getAffInfo().cookieManager.getVisitorId());
    })
  },

  getAffiliateReadyPromise() {
    if (!this.affiliateReadyPromise) {
      var promise = new EmberPromise((resolve) => {
        addAsyncScript('https://' + config.APP.postAffiliateProSubdomain + '.postaffiliatepro.com/scripts/' + config.APP.postAffiliateProId, 'pap_x2s6df8d', function() {
          resolve(null);
        });
      });
      this.set('affiliateReadyPromise', promise);
    }
    return this.affiliateReadyPromise;
  },

  getAffiliateAndCurrentUserPromise() {
    let promiseHash = RSVP.hash({
      affiliate: this.getAffiliateReadyPromise(),
      user: this.get('session.currentUserPromise')
    });
    return promiseHash.then((resolvedHash) => {

      let email = getCapuredEmail();
      if (resolvedHash.user && resolvedHash.user.get('email')) {
        email = resolvedHash.user.get('email');
      }
      // In my computer at least, my email is ;undefined' the string in my local storageg
      if (email && email !== 'undefined') {
        this.set('email', email);
        // eslint-disable-next-line no-use-before-define
        // window.Data1 = email;
      }

      return resolvedHash;
    });

  },

  trackAffiliateLanding() {
    if (typeof PostAffTracker === 'undefined') {
      return console.log('PostAffTracker blocked');
    }

    // the affiliate script reads these variables directly. We read these in now, just to make sure they are still in the browser location as these params will disappear after route transition

    let affiliateId = getRequestParamByName('a_aid');
    let bannerId = getRequestParamByName('a_bid');
    let channel = getRequestParamByName('chan');

    if (affiliateId) {
      window.AffiliateID = affiliateId;
      this.set('affiliateId', affiliateId);
      trackEvent('affiliate:click')
    }
    if (bannerId) {
      window.BannerID = bannerId;
      this.set('bannerId', bannerId);
    }
    if (channel) {
      window.Channel = channel;
      this.set('channel', channel);
    }

    this.getAffiliateAndCurrentUserPromise().then(() => {
      if (!window.timbuktu.blockAnalyticsAndAffiliates && !window.timbuktu.preRendering && PostAffTracker) {
        PostAffTracker.setAccountId('default1');
        PostAffTracker.track();
      }
    });

  },

  trackSale(affiliatePayment) {
    if (typeof PostAffTracker === 'undefined') {
      return console.log('PostAffTracker blocked');
    }

    this.getAffiliateAndCurrentUserPromise().then(() => {
      PostAffTracker.setAccountId('default1');
      var sale = PostAffTracker.createSale();
      sale.setTotalCost(affiliatePayment.get('amount'));
      sale.setOrderID(affiliatePayment.get('charge.trip.id') + '_' + affiliatePayment.get('charge.id'));
      sale.setProductID(affiliatePayment.get('charge.trip.id'));
      PostAffTracker.register();
      return affiliatePayment.commissionReported();
    });
  },

  trackEmailCapture() {
    if (typeof PostAffTracker === 'undefined') {
      return console.log('PostAffTracker blocked');
    }

    this.getAffiliateAndCurrentUserPromise().then(() => {
      if (!window.timbuktu.blockAnalyticsAndAffiliates && !window.timbuktu.preRendering) {
        var action = PostAffTracker.createAction('capture_email');
        action.setData1(this.email);
        PostAffTracker.register();
      }
    });
  },

  trackQuoteView(trip) {
    if (typeof PostAffTracker === 'undefined') {
      return console.log('PostAffTracker blocked');
    }

    this.getAffiliateAndCurrentUserPromise().then(() => {

      // eslint-disable-next-line no-use-before-define
      if (!window.timbuktu.blockAnalyticsAndAffiliates && !window.timbuktu.preRendering) {
        var action = PostAffTracker.createAction('quote_viewed');
        action.setProductID(trip.get('id'));
        action.setData1(this.email);
        PostAffTracker.register();
      }
    });
  },

  executeWhenAffiliateInfoReady(callback) {
    this.getAffiliateReadyPromise().then(() => {
      if (typeof PostAffTracker === 'undefined') {
        return console.log('PostAffTracker blocked');
      }

      PostAffTracker.getAffInfo().call(callback);
    });
  }

});
