import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { run } from "@ember/runloop";
import { isEmpty } from "ember-utils";

export default Component.extend({
  entityModals: service(),
  templateManager: service(),
  tripService: service('trip'),
  ui: service(),
  querying: false,
  editTemplateModalOptions: {
    isModalOpen: false,
  },
  didInsertElement() {
    this._super(...arguments);
    this.selectTemplate(this.templateKey);
    var trip = this.get('store').createRecord('trip');
    trip.set('layout', 'day-by-day')
    var itinerary = this.get('store').createRecord('itinerary');
    var stage = this.get('store').createRecord('stage');
    trip.set('itinerary', itinerary)
    itinerary.get('stages').pushObject(stage)
    this.set('fakeStage', stage)
    this.set('fakeTrip', trip)
    if (this.get('templateManager.useFakeTrip')) {
      this.set('tripService.currentTrip', trip)
    }

  },
  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.debounceTimer);
  },
  isDuplicatePill(name) {
    return (this.get("filters") || []).map((p) => p.name).includes(name);
  },
  reloadTemplates() {
    this.get("fetchTemplates").perform();
  },
  setupFakeStage(options) {
    this.set('fakeStage.stageType', null)
    this.set('fakeStage.lodge', null)
    this.set('fakeStage.region', null)
    this.set('fakeStage.templateSchedule', null)
    if (options.template && options.template.get('lodge')) {
      this.set('fakeStage.stageType', 'lodge')
      this.set('fakeStage.lodge', options.template.get('lodge'))
      this.set('fakeStage.region', options.template.get('lodge.region'))
    } else if (options.template && options.template.get('region')) {
      this.set('fakeStage.stageType', 'region')
      this.set('fakeStage.region', options.template.get('region'))
    }
  },

// TODOLIBRARY this whoel component needs to be looked at. Especially this debounce
// TODOLIBRARY bad name for all of the sub components here
// If you refresh on page 2, you lose your pagination position .. sigh


  actions: {
    onKeyUpInSearchBox(val) {
      const newFilter = {
        name: val,
        type: "text",
      };

      this.set("additionalFilter", newFilter);
      this.debounceTimer = run.debounce(
        this,
        this.reloadTemplates,
        val,
        350,
        false
      );
    },
    selectTemplate(templateLabel) {
      this.selectTemplate(templateLabel);
    },
    previewTemplate(options) {
      if (this.templateKey=='schedules') {
        options.widePortal=true
      }
      options.model=options.template
      options.stage=this.fakeStage
      this.entityModals.openModal(options)
    },
    editTemplate(options) {
      console.log(options)
      this.setupFakeStage(options)
      this.entityModals.addModalPermission('perm-save-template')
      if (this.templateKey=='schedules') {
        options.widePortal=true
        options.editInModal=true
        options.model=options.template
        options.stage=this.fakeStage
        options.stage.set('templateSchedule', options.template)
        this.entityModals.openModal(options)
      } else {
        options.isModalOpen = true;
        this.setProperties({
          editTemplateModalOptions: options,
        });
      }
    },
    saveTemplate(options) {
      // TODOLIBRARY Tidy this up
      if (this.templateModel == "transfer") {
        options.template.set("transferType", "template");
      }
      return options.template.save().then(() => {
        this.setProperties({
          editTemplateModalOptions: { template: null, isModalOpen: false },
        });
        this.get("ui").showGeneralMessage("Congrats!", `Template is saved`);
        this.get("fetchTemplates").perform();
      });
    },
    selectSearchResult(searchResult) {
      if (searchResult.action === "search:select") {
        if (!this.isDuplicatePill(searchResult.result.name)) {
          let newFilter = {
            name: searchResult.result.name,
            id: searchResult.result.id,
            type: searchResult.type,
          };
          this.setProperties({
            filters: (this.filters || []).concat(newFilter),
          });
        }
        this.get("fetchTemplates").perform();
      }
    },
    removePill(pill) {
      let remainingFilters = this.filters.filter((s) => s.name !== pill.name);
      let filters = isEmpty(remainingFilters) ? [] : remainingFilters;
      this.setProperties({
        filters: filters,
      });
      this.get("fetchTemplates").perform();
    },
  },
});
