import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: ['token'],

  actions: {
    goToLogin() {
      this.transitionToRoute('login');
    }
  }
});
