import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  alias
} from 'ember-decorators/object/computed';

export default Controller.extend(UseCurrentTrip, {

});
