import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import Component from '@ember/component';

export default Component.extend({

  // entityModals is used in the sub-classes
  entityModals: service(),
  @alias('entityModals.onModal') onModal: false,

  actions: {
    openModalOrBubbleToLink(options) {
      if (this.get('onModal')) {
        this.get('entityModals').openModal(options);
      }
    }
  }

});