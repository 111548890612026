import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: ['trip-page-header'],

  screen: service(),
  tripService: service('trip'),
  tripCarouselImages: null,

  didReceiveAttrs(){
    this._super(...arguments);
    if (this.get('tripCarouselImagesMaybePromise').then){
      this.get('tripCarouselImagesMaybePromise').then((images) => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }
        this.set('tripCarouselImages', images)
      })
    } else {
      this.set('tripCarouselImages',  this.get('tripCarouselImagesMaybePromise'))
    }
  },

});
