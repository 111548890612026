import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['tweaker'],

  tripService: service('trip'),
  ui: service(),
  session: service(),
  @alias('tripService.currentTrip') trip: null,

  @computed('session.currentUser')
  showTweaker(currentUser) {
    if (currentUser && ['archie.leeming@timbuktutravel.com', 'ianpetzer+consultant@gmail.com', 'ian.petzer@timbuktutravel.com', 'qwe@qwe.qwe'].includes(currentUser.email)) {
      return true;
    }
    if (window.location.host.indexOf('localhost')>=0 || window.location.host.indexOf('staging.timbuktutravel')>=0) {
      return true;
    }
    return false
  },


});
