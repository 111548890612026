import pillSearchComponent from 'b5b/components/pill-search/component';

/*
  Note: There is a strong relationship between this component and x-suggestions/routes
*/

export default pillSearchComponent.extend({
  classNames: ['routes'],

  searchType: 'trip',

  includeLodges: true

});
