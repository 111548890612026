import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNameBindings: [':lodge-card-simple', 'lodgeOption:is-lodge-option'],

  entityModals: service(),
  tripService: service('trip'),



  @alias('entityModals.onModal') onModal: null,

  lodge: null,

  canSwipe: true, // gallery

  click() {
    if (this.get('lodge') && this.get('mapHover')) {
      this.set('lodge.hover', false);
    }
  },

  mouseEnter() {
    if (this.get('mapHover')) {
      this.set('lodge.hover', true);
    }
    if (this.onMouseEnter) {
      this.onMouseEnter();
    }
  },

  mouseLeave() {
    if (this.get('mapHover')) {
      this.set('lodge.hover', false);
    }
    if (this.onMouseLeave) {
      this.onMouseLeave();
    }
  },

  actions: {
    openModal() {
      if (this.changingLodge) {
        this.entityModals.addModalPermission('perm-add-lodge');
      }
      this.get('entityModals').openModal({
        model: this.get('lodge'),
        stage: this.stage,
        scrollToLodge: true
      });
    }
  }

});
