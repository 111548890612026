import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNameBindings: [':trip-price-bar', 'stagesWithLodgesCount::hidden'],

  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('trip.itinerary.accomStageLodgeMapping.length') stagesWithLodgesCount: null,
  @alias('trip.itinerary.nonTransferAndLocationStages.length') nonTransferAndLocationStages: null


});
