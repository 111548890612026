import TripWidgetComponent from 'b5b/components/t-widget/trip/component';

import {
  inject as service
} from '@ember/service';
// import {
//   isEmpty
// } from '@ember/utils';
// import {
//   run
// } from '@ember/runloop';
// import {
//   observer
// } from '@ember/object';

// import {
//   computed
// } from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

// const STATE_NEED_STAGE = 'needStage';
// const STATE_NEED_START_DATE = 'needDate';
// const STATE_NEED_LODGE = 'needLodges';
// const STATE_VIEW_QUOTE_NO_PRICES = 'viewQuoteNoPrices';

export default TripWidgetComponent.extend({
  // classNameBindings: [':floating', 'popupVisible'],
  classNameBindings: [':floating'],

  settings: service(),
  router: service(),
  scroll: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null

  // animatePopupOut: true, // note this works in reverse
  // popupTimer: null,

  // watchPopupChanges: observer('popupState', function() {

  //   this.set('animatePopupOut', true);
  //   this.popupTimer = run.later(() => {
  //     if (this.get('isDestroying') || this.get('isDestroyed')) {
  //       return;
  //     }
  //     this.set('animatePopupOut', false);
  //   }, 1500);
  // }),

  // didInsertElement() {
  //   this._super(...arguments);
  //   // initial animate in
  //   this.popupTimer = run.later(() => {
  //     if (this.get('isDestroying') || this.get('isDestroyed')) {
  //       return;
  //     }
  //     this.set('animatePopupOut', false);
  //   }, 5000)
  // },

  // @alias('tripService.showWidgetPopup') showWidgetPopup: true,
  // @and('popupMessage', 'showWidgetPopup') popupVisible: false,

  // @computed('viewingSomeoneElsesTrip', 'trip.itinerary.nonTransferAndLocationStages.[]', 'trip.itinerary.startDate', 'stagesWithLodgesCount', 'trip.itinerary.stateEnquiryAndAfter')
  // popupState(viewingSomeoneElsesTrip, nonTransferAndLocationStages, tripStateDate, stagesWithLodgesCount, stateEnquiryAndAfter) {
  //   if (viewingSomeoneElsesTrip) {
  //     return STATE_VIEW_QUOTE_NO_PRICES;
  //   }
  //   if (stateEnquiryAndAfter) {
  //     // No more popups as the user can't make any changes
  //     return;
  //   }
  //   if (isEmpty(nonTransferAndLocationStages)) {
  //     return STATE_NEED_STAGE;
  //   }
  //   if (!tripStateDate) {
  //     return STATE_NEED_START_DATE;
  //   }
  //   if (stagesWithLodgesCount === 0) {
  //     return STATE_NEED_LODGE;
  //   }
  // },

  // @computed('showWidgetPopup', 'popupState')
  // popupMessage(showWidgetPopup, popupState) {
  //   switch (popupState) {
  //     case STATE_VIEW_QUOTE_NO_PRICES:
  //       return "Is this your trip? Log in to see all details.";
  //     case STATE_NEED_STAGE:
  //       return "This trip has no stages. Add a stage to start.";
  //     case STATE_NEED_START_DATE:
  //       return "Choose a start date for updated pricing and availability";
  //     case STATE_NEED_LODGE:
  //       return "Add your first lodge to start getting an accurate price";
  //   }
  // },

  // willDestroyElement() {
  //   this._super(...arguments);
  //   run.cancel(this.popupTimer);
  // },

  // actions: {
  //   popupAction() {
  //     let popupState = this.get('popupState');
  //     switch (popupState) {
  //       case STATE_VIEW_QUOTE_NO_PRICES:
  //         this.get('router').transitionTo('trip.index.quote', this.get('trip'));
  //         break;
  //       case STATE_NEED_STAGE:
  //         this.set('ui.showNewStageModal', true);
  //         break;
  //       case STATE_NEED_START_DATE:
  //         this.get('tripService').addDates();
  //         break;
  //       case STATE_NEED_LODGE:
  //         this.get('scroll').to($('.trip-stages .stage:eq(1)').offset().top - 94, this)
  //         break;
  //     }
  //   }
  // }
});
