import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['guide-block'],

  ui: service(),

  entity: null,
  showEntityLink: false,

  init() {
    this._super(...arguments);
    if (this.entity.then) {
      this.entity.then((resolvedEntity) => {
        this.setRouteParams(resolvedEntity);
      })
    } else {
      this.setRouteParams(this.entity)
    }
  },

  setRouteParams(entity) {


    let routeName = `${entity.constructor.modelName}.routes`;
    let queryParams = {};

    switch (entity.get('constructor.modelName')) {
      case 'country':
        queryParams.selectedCountryNames = [entity.get('name')];
        break;
      case 'region':
        queryParams.selectedRegionNames = [entity.get('name')];
        break;
      case 'experience':
        queryParams.selectedExperienceNames = [entity.get('name')];
        break;
      case 'lodge':
        queryParams.selectedLodgeNames = [entity.get('name')];
        break;
      case 'month':
        routeName = 'when-to-go.' + routeName;
        queryParams.selectedMonthNames = [entity.get('name')];
        break;
    }

    this.set('routeParams', [
      routeName, entity, {
        isQueryParams: true,
        values: queryParams
      }
    ]);

    this.set('showEntityLink', true);

  }
});