import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent
} from 'b5b/utils';
import basicNav from 'b5b/mixins/basic-nav';
import Route from '@ember/routing/route';

export default Route.extend({

  ui: service(),
  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    let modelRoute = this.entityType;
    if (this.entityType=='month') {
      modelRoute = 'when-to-go.'+modelRoute
    } else if (this.entityType=='general') {
      return null;
    }
    return this.modelFor(modelRoute);
  },

  beforeModel(transition) {
    this._super(...arguments);
    if (this.whitelabel.isForAgency && !this.get('session.currentUser.isManager') && !this.whitelabel.isOnboardingAgency) {
      transition.abort();
      window.history.back();
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent(this.entityType+':start-planning:view');
    controller.set('entity', model);
    controller.set('entityType', this.entityType);
    this.set('ui.entityNavVisible', false)
    this.set('ui.monthNavVisible', false)
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      // controller.setProperties({
      // });

      this.set('ui.entityNavVisible', true)
      this.set('ui.monthNavVisible', true)
    }
  }

});
