import DS from 'ember-data';

export default DS.Model.extend({

  trip: DS.belongsTo('trip', {async: true}),
  itineraries: DS.hasMany('itinerary', {async: false}),
  messages: DS.hasMany('message', {async: false}),
  quotes: DS.hasMany('quote', {async: false})
  
});
