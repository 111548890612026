import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';

import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  settings: service(),
  tripService: service('trip'),

  setupController() {
    trackEvent('trip:dashboard');
  },
  beforeModel(transition) {

    // When captuing pdf, the pdf should make sense in terms of settlement currency. Otherwise its always generated for usd current currency
    if (window.timbuktu.pdfGen && this.get('tripService.currentTrip.itinerary.quote.settlementCurrency')) {
      this.get('settings').set('currentCurrency', this.get('tripService.currentTrip.itinerary.quote.settlementCurrency'));
    }

    if (this.get('tripService.currentTrip.isGiftList') && !(this.tripService.currentUserIsGiftListOwner || this.session.get('currentUser.isManager') || window.timbuktu.pdfGen)) {
      transition.abort();
    }    
  },  

});