import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend({

  whitelabel: service(),
  router: service(),

  setupController(controller) {
    this._super(...arguments);
    this.store.queryRecord('page-content', {
      id: 'home'
    }).then((pageContent)=> {
      controller.set('highlightSlides', pageContent.get('highlightsSlider.slides'));
    });
  },

  afterModel(model, transition) {
    if (this.whitelabel.isForAgency) {
      return this.session.currentUserPromise.then((currentUser)=> {
        if (currentUser && currentUser.email) {
          transition.abort();
          this.transitionTo('users.my-trips');
        } else {
          if (this.whitelabel.isOnboardingAgency) {
            this.transitionTo('countries')
          } else {
            this.transitionTo('login');
          }

        }
      })
    }
  },

  headTags() {

    const title = this.whitelabel.isForTimbuktu ? "Timbuktu Travel - Tailor-Made Tours, Vacations and Safaris" : this.whitelabel.agencySeoName;

    let description = this.whitelabel.isForTimbuktu ? 'Get started with our online trip designer or chat to our experts to help you plan your dream trip': '';

    if (!this.isFastBoot) {
      document.title = title;
    }

    let robots = (this.router.currentURL=='/' && this.whitelabel.isForTimbuktu) ? 'index, follow' : 'noindex, nofollow';
    let link = '' //Default of canonical for homepage

    return generateMeta({
      description,
      'og:title': title,
      robots,
      link
    });
  },


});
