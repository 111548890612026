define('ember-chrome-devtools/initializers/install-devtools', ['exports', 'ember-chrome-devtools/formatters'], function (exports, _formatters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function initialize() /* application */{
    var old = window.devtoolsFormatters || [];
    window.devtoolsFormatters = [].concat(_toConsumableArray(old), _toConsumableArray(_formatters.default));
  }

  exports.default = {
    name: 'install-devtools',
    initialize: initialize
  };
});