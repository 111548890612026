import Component from '@ember/component';

export default Component.extend({
  classNames: ['simple-entity-block'],

  entity: null,
  w: 48,
  h: 48

});
