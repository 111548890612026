import EditorWidgetComponent from 'b5b/components/t-widget/editor/component';
import {
  inject as service
} from '@ember/service';

export default EditorWidgetComponent.extend({
  classNames: ['nav'],

  router: service(),
  screen: service(),
  historyService: service()
});
