import EntityContent from 'b5b/components/entity-content/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';


export default EntityContent.extend({

  store: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') currentTrip: null,

  lodge: null,

  @computed('lodge.lodgeInfo.standaloneQuote')
  lodgeStandaloneQuote(standaloneQuote) {
    standaloneQuote = standaloneQuote.trim();
    if (standaloneQuote[0] === `"` || standaloneQuote[0] === `“`) {
      standaloneQuote = standaloneQuote.substring(1, standaloneQuote.length);
    }
    if (standaloneQuote[standaloneQuote.length - 1] === `"` || standaloneQuote[standaloneQuote.length - 1] === `”`) {
      standaloneQuote = standaloneQuote.substring(0, standaloneQuote.length - 1);
    }

    // quotes don't end in a full stop
    if (standaloneQuote[standaloneQuote.length - 1] !== '.') {
      standaloneQuote += '.';
    }

    return standaloneQuote;
  }
});
