import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['reviews-list'],

  store: service(),
  scroll: service(),
  feefo: service(),
  messageBus: service(),
  xplorer: service('components/x-plorer'),

  init() {
    this._super(...arguments);
    this.get('messageBus').subscribe('pill-added', this, this.reload);
    this.get('messageBus').subscribe('pill-removed', this, this.reload);
    this.get('messageBus').subscribe('suggestions-reset', this, this.reload);
    this.get('messageBus').subscribe('suggestions-refresh', this, this.reload);
  },

  reload() {
    this.get('loadReviewsTask').perform();
  },

  actions: {
    setStarRating(tab, index) {
      // this.loader = run.next(() => {
      //   this.get('scroll').to($('.toggle-pills.stars')[0].getBoundingClientRect().top + window.scrollY - 60);
      // });
      this.set('starRating', tab.value)
      this.loadReviewsTask.perform()
    }
  }

});
