import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['trip-tools-right-pane-add-schedules', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  templateManager: service(),
  entityModals: service(),
  tripService: service('trip'),
  store: service(),
  session: service(),
  dayExperiences: null,
  showCurrentUserSchedulesOnly: false,
  showCurrentLodgeSchedulesOnly: false,

  didInsertElement() {
    this._super(...arguments);
    if (this.get('templateManager.currentStage.regionProxy.id')) {
      this.get('loadSchedulesTask').perform();
    }
    this.tripService.ensureAllPartnersLoaded();
  },

  loadSchedulesTask: task(function*() {
    let queryAttrs = {
      templateType: 'template',
      buster: true,
      sort: 'recommended'
    };

    let filters = [{type:'region', id: this.get('templateManager.currentStage.regionProxy.id')}]

    this.get('templateManager.currentStage.regionProxy').get('associatedEntities') && this.get('templateManager.currentStage.regionProxy').get('associatedEntities').forEach((associatedEntity)=> {
      if (associatedEntity.get('associatedRegion')) {
        filters.pushObject({type: 'region', id: associatedEntity.get('associatedRegion.id')})
      }
    })

    if (this.showCurrentLodgeSchedulesOnly) {
      filters.pushObject({type: 'lodge', id: this.templateManager.currentStage.lodge.id})
    }
    if (this.textFilter) {
      filters.pushObject({
        name: this.textFilter,
        type: "text",
      })
    }
    if (this.templateManager.tripToolsFilterByPartner) {
      filters.pushObject({type: 'partner', id: this.templateManager.tripToolsFilterByPartner.id})
    }
    queryAttrs.filters=JSON.stringify(filters)

    if (this.showCurrentUserSchedulesOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }

    return this.store.query('schedule', queryAttrs).then((schedules)=>{
      this.set('schedules', schedules)
    })

  }).keepLatest(),

  actions: {
    textFilterChanged(textFilter) {
      this.set('textFilter', textFilter)
      this.loadSchedulesTask.perform();
    },
    selectPartnerForFiltering(partner) {
      this.set('templateManager.tripToolsFilterByPartner', partner)
      this.loadSchedulesTask.perform();
    },
    // clearPartner() {
    //   this.set('templateManager.tripToolsFilterByPartner', null)
    //   this.loadSchedulesTask.perform();

    // }

  }
});
