import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  tagName: '',

  entityModals: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') transfer: null,
  @alias('entityModals.currentModalConfig.stage') stage: null,
  @alias('entityModals.currentModalConfig.stageIndex') stageIndex: null,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get("transfer.duration")) {
      let hours = Math.floor(this.get("transfer.duration") / 60),
        minutes = this.get("transfer.duration") % 60;

      this.setProperties({
        hours: hours,
        minutes: minutes,
      });
    }
  },

});
