import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model: function() {
    return this.modelFor('region');
  },

  afterModel(model) {
    if (this.isFastBoot) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('regionInfo'));
      });
    }
  },

  setupController: function(controller, model) {
    this._super(controller, model);
    trackEvent('region:view')
    controller.set('region', model);
    this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: model.country.id}]),
    }).then((regions)=> {
      controller.set('allRegionsInCountry', regions)
    })
  }
});