import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  store: service(),
  classNames: ['show-trip-views bs14'],

  didInsertElement() {
    console.log('insert')
    this.loadTripViewEvents.perform();
  },

  loadTripViewEvents: task(function * () {
    let query = {
      per_page: 20,
      page: 1,
      tripId: this.tripSummary.id,
      eventType: 'trip:view',
      sort: 'created_at_desc',
      buster: true
    };

    let events = yield this.get('store').query('event', query);
    this.set('events', events);
  }),

});
