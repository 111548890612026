import Component from '@ember/component';

export default Component.extend({

    possibleGenders: ["Female", "Male", "Other"],

    actions: {

        setInsuranceToPending(guest) {
            guest.setProperties({
                travelInsuranceState: guest.get('travelInsuranceState') === 'pending' ? null : 'pending'
            });
        },

        setInsuranceToConfirmed(guest) {
            guest.setProperties({
                travelInsuranceState: guest.get('travelInsuranceState') === 'confirmed' ? null : 'confirmed'
            });
        },
    }
});
