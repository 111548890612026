import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  alias
} from 'ember-decorators/object/computed';

export default Route.extend({

  store: service(),
  scroll: service(),
  dealService: service(),


  model(params) {
    return this.store.queryRecord('deal', {id: params.deal_id, buster: true});
  },

  // afterModel(model) {
  //   if (this.get('isFastBoot')) {
  //     return new RSVP.Promise(function(resolve) {
  //       resolve(model.get('lodgeInfo'));
  //     });
  //   }
  // },

  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('deal', model);
    this.dealService.set('currentDeal', model);
  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      controller.set('deal', null)
      controller.set('guests', null)
    }


  }

});
