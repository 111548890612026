import {
  inject as service
} from '@ember/service';
import StepComponent from 'b5b/components/booking-steps/step/component';
import {
  computed
} from 'ember-decorators/object';

export default StepComponent.extend({
  classNameBindings: [':clearfix', 'notYetCurrent'],

  tripService: service('trip'),
  settings: service(),
  whitelabel: service(),

  @computed('trip.itinerary.stateBeforeBalanceDue')
  notYetCurrent(stateBeforeBalanceDue) {
    return stateBeforeBalanceDue;
  },

  @computed('settings.currentCurrency')
  defaultTab(currentCurrency) {
    switch (currentCurrency) {
      case 'usd':
        return 2;
      case 'gbp':
        return 1;
      case 'eur':
        return 3;
      default:
        return 1;
    }
  }

});