import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  inject as service
} from '@ember/service';

export default Controller.extend(UseCurrentTrip, TripSaver, {

});
