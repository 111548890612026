import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  reads
} from 'ember-decorators/object/computed';
import {
  getSortTitle
} from 'b5b/utils';

export default Component.extend({
  classNames: ['regions-list'],

  scroll: service(),
  ui: service(),
  messageBus: service(),
  fastboot: service(),
  @reads('fastboot.isFastBoot') isFastBoot: false,

  tripService: service('trip'),

  regionCountCache: 0,
  reloadTimer: null,

  sort: null,

  @computed('sort', 'screen.isMobileOnly')
  sortTitle(sort, isMobileOnly) {
    return getSortTitle(sort, isMobileOnly);
  },  


  @computed('regions.meta.totalCount', 'regions.length', 'regionsLoading')
  regionCount(regionsLength, regions, regionsLoading) {
    if (regionsLength) {
      this.set('regionCountCache', regionsLength);
    }

    if (!regions && !regionsLoading) {
      this.set('regionCountCache', 0);
    }

    return this.get('regionCountCache');
  }

});
