import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  computed
} from 'ember-decorators/object';

export default Controller.extend(UseCurrentTrip, {

  @computed('trip.itinerary.videos.@each.videoType', 'trip.itinerary.accomStages.@each.showLodgeVideos')
  hasNonTripClipVideos(tripVideos, accomStages) {
    let nonTripClipvideos =  tripVideos.reduce(function(result, tripVideo) {
      if (tripVideo.videoType!='tripclip') {
        result=result.concat(tripVideo);
      }  
      return result    
    }, []);

    if (nonTripClipvideos.length>0) {
      return true;
    }
    let stagesWithLodgeVideosEnabled =  accomStages.reduce(function(result, accomStage) {
      if (accomStage.showLodgeVideos) {
        result=result.concat(accomStage);
      }      
      return result
    }, []);    
    if (stagesWithLodgeVideosEnabled.length>0) {
      return true;
    }
    return false;
  }  



});
