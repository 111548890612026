import DS from 'ember-data';
import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';

export function deserializeDate(serialized) {
  if (serialized) {
    return moment(serialized, DATE_FORMAT).toDate();
  }
  return serialized;
}
export function serializeDate(deserialized) {
  if (deserialized) {
    return moment(deserialized).format(DATE_FORMAT);
  }
  return deserialized;
}


export default DS.Transform.extend({
  deserialize(serialized) {
    return deserializeDate(serialized);
  },

  serialize(deserialized) {
    return serializeDate(deserialized);
  }
});