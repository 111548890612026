import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({

  name: DS.attr(),
  friendlyId: DS.attr(),

  @computed('friendlyId', 'id')
  pageContentFriendlyId(friendlyId, id) {
    return friendlyId || id;
  },

  highlightsSlider: DS.belongsTo('slider', {
    inverse: null
  }),

  recommendedDestinationsSlider: DS.belongsTo('slider', {
    inverse: null
  }),

  recommendedExperiencesSlider: DS.belongsTo('slider', {
    inverse: null
  }),

  recommendedLodgesSlider: DS.belongsTo('slider', {
    inverse: null
  })

});