import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';
import {
    computed
  } from 'ember-decorators/object';
import {
  isEmpty
} from '@ember/utils';
import {
  prevTransferStage
} from 'b5b/utils';

export default Component.extend({
  tagName: '',
  tripService: service('trip'),
  templateManager: service(),
  entityModals: service(),

  actions: {
  },


  @computed('trip.itinerary.stages.@each.entity', 'stageIndex')
  prevTransferStage(stages, stageIndex) {
    console.log(this.trip, arguments)
    return prevTransferStage(stages, stageIndex);
  },

  actions: {
    closeMiniContextThen(action) {
      action();
    },
  }

});
