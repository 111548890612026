import {
  inject as service
} from '@ember/service';
import {
  reads
} from '@ember/object/computed';
import {
  run
} from '@ember/runloop';
import xGalleryComponent from 'b5b/components/x-gallery/component';

export default xGalleryComponent.extend({

  // custom prop for our setup
  _recognizers: 'pan-all swipe-all',

  classNameBindings: [':lightbox', 'isOpen'],

  scroll: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  showImageCaptions: true,

  isOpen: false,
  onClose: null,

  closingTimer: null,
  openingTimer: null,

  startIndex: null,

  _horizontalMove: false,
  _verticalMove: false,
  _navigating: false,

  panUp() {
    if (this._horizontalMove) {
      return;
    }
    this._doVert();
    // trackEvent('x-gallery:lightbox:panUp:close');
  },

  panDown() {
    if (this._horizontalMove) {
      return;
    }
    this._doVert();
    // trackEvent('x-gallery:lightbox:panDown:close');
  },

  panLeft() {
    if (this._verticalMove || this._navigating) {
      return;
    }
    this._next();
    this._doHori();
    // trackEvent('x-gallery:next:pan');
  },

  panRight() {
    if (this._verticalMove || this._navigating) {
      return;
    }
    this._prev();
    this._doHori();
    // trackEvent('x-gallery:prev:pan');
  },

  panEnd() {
    this._endGesture();
  },

  swipeUp() {
    if (this._horizontalMove) {
      return;
    }
    this._doVert();
    // trackEvent('x-gallery:lightbox:swipeUp:close');
  },

  swipeDown() {
    if (this._horizontalMove) {
      return;
    }
    this._doVert();
    // trackEvent('x-gallery:lightbox:swipeDown:close');
  },

  swipeLeft(e) {
    if (this._verticalMove || this._navigating) {
      return;
    }
    this._doHori(e);
    this._next();
    // trackEvent('x-gallery:next:swipe');
  },

  swipeRight(e) {
    if (this._verticalMove || this._navigating) {
      return;
    }

    this._doHori(e);
    this._prev();
    // trackEvent('x-gallery:prev:swipe');
  },

  swipeEnd() {
    this._endGesture();
  },

  _doHori() {
    this.set('_horizontalMove', true);
    this.set('_navigating', true);
  },

  _doVert() {
    this.set('_verticalMove', true);
    this._close();
  },

  _endGesture() {
    this.setProperties({
      _horizontalMove: false,
      _verticalMove: false,
      _navigating: false
    });
  },

  didInsertElement() {
    this._super(...arguments);

    this.scroll.addModal(this.elementId, this);

    this.openingTimer = run.later(() => {
      this.set('isOpen', true);
    }, 100);
    // trackEvent('x-gallery:lightbox:open');
    if (!this.isFastBoot) {
      this.set('boundKeyDownHandler', this.handleKeyPress.bind(this));
      $(document).on('keydown', this.boundKeyDownHandler);
    }
  },

  handleKeyPress(e) {
    switch (e.which) {
      case 27: // esc
        this._close();
        break;

      case 37: // left
        this._prev();
        break;

      case 39: // right
        this._next();
        break;

      default:
        return; // exit this handler for other keys
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    this.scroll.removeModal(this.elementId, this);

    this.setProperties({
      closingTimer: null,
      openingTimer: null
    });

    if (!this.isFastBoot) {
      $(document).off('keydown', this.boundKeyDownHandler);
    }

    // trackEvent('x-gallery:lightbox:close');
  },

  _close() {
    this.set('isOpen', false);
    this.closingTimer = run.later(() => {
      if (this.onClose) {
        this.onClose();
      }
    }, 300);

    // trackEvent('x-gallery:lightbox:close');
  },

  actions: {
    close(e) {
      this._close();
      e.stopPropagation();
    },

    itemClicked(e) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

});
