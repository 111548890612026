import DS from 'ember-data';

export default DS.Model.extend({
  searchType: DS.attr('string'),
  polishLevel: DS.attr('string'),
  value: DS.attr('string'),
  email: DS.attr('string'),
  user: DS.belongsTo('user'),
  previousSearch: DS.belongsTo('search', {
    inverse: null
  }),
  locations: DS.hasMany('location'),
  agencies: DS.hasMany('agency'),
  continents: DS.hasMany('continent'),
  lodges: DS.hasMany('lodge'),
  trips: DS.hasMany('trip'),
  areas: DS.hasMany('areas'),
  regions: DS.hasMany('region'),
  countries: DS.hasMany('country'),
  experiences: DS.hasMany('experience'),
  months: DS.hasMany('month'),
  resultsCount: DS.attr('number'),

  excludedCountryNames: DS.attr(),
  excludedAreaNames: DS.attr(),
  excludedRegionNames: DS.attr(),
  excludedExperienceNames: DS.attr(),
  excludedMonthNames: DS.attr(),
  excludedTripTypeNames: DS.attr()
})
