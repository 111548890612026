import EntityContent from 'b5b/components/entity-content/component';
import {
  inject as service
} from '@ember/service';

export default EntityContent.extend({

  store: service(),
  entityModals: service(),

  experience: null,
  trips: null,
  categoryRegionalExperiences: null,
  regionalExperiences: null,


  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.onModal) {
      if (this.get('experience.isCategory')) {
        this.set('trips', this.store.query('tripSummary', {
          page: 1,
          per_page: 6,
          selectedExperienceNames: [this.get('experience.name')]
        }));
      } else {
        this.set('trips', this.store.query('tripSummary', {
          page: 1,
          per_page: 6,
          selectedRegionNames: [this.get('experience.region.name')]
        }));
      }
    }
  }
});
