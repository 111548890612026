import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  tagName: '',

  tripService: service('trip'),
  entityModals: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.scrollToLodge') scrollToLodge: null,
  @alias('entityModals.currentModalConfig.stage') stage: null,
  @alias('entityModals.currentModalConfig.model') lodge: null,
  @alias('entityModals.currentModalConfig.tripForModal') tripForModal: null,
  @alias('entityModals.currentModalConfig.stageForModal') stageForModal: null,
  stage: null,

  actions: {
    chooseLodge() {
      this.get('tripService').doChangeFoundNewLodge({
        lodge: this.get('lodge')
      })
      this.get('entityModals').closeModal();
    },

    showLighboxIfAvailable() {
      if (this.get('lodge.metaImagesCount') > 1) {
        this.set('showGallery', true);
      }
    }
  }
});
