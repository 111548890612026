import {
  A
} from '@ember/array';
import Mixin from '@ember/object/mixin';


const transformProps = ['budget', 'countries', 'length', 'month', 'continents', 'blogTags'];

export default Mixin.create({
  deserializeQueryParam(value, urlKey, defaultValueType) {
    if (value && defaultValueType === 'array') {
      if (transformProps.includes(urlKey) && ['any', 'all'].includes(value)) {
        value = value.capitalize() + ' ' + (urlKey.capitalize() + (urlKey === 'budget' ? 's' : ''));
      }
      if (value.length > 0) {
        if (['lodges', 'regions', 'countries', 'experiences', 'continents', 'blogTags'].includes(urlKey) ) {
          return value.split('+');
        }
        if (urlKey === 'filters') { 
          return JSON.parse(value)
        }
        return A(value.split('-'));
      } else {
        return value;
      }
    }
    return this._super(...arguments);
  },

  serializeQueryParam(value, urlKey, defaultValueType) {
    if (value && defaultValueType === 'array') {
      if (transformProps.includes(urlKey)) {
        const regEx = new RegExp('^(All|Any) ' + urlKey.capitalize() + 's?$');
        value = value.map((val) => {
          let matched = typeof val === 'string' && val.match(regEx);
          return matched && matched[1] ? matched[1].toLowerCase() : val;
        });
      }
      if (value.length > 0) {
        if (['lodges', 'regions', 'countries', 'experiences', 'continents', 'blogTags'].includes(urlKey) ) {

          return value.join('+');
        }
        if (urlKey === 'filters') { 
          return JSON.stringify(value)
        }        
        return value.join('-');
      } else {
        return value;
      }
    } 
    return this._super(...arguments);
  }
});
