import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';

export default Mixin.create({

  ui: service(),

  setupController() {
    this._super(...arguments);
    this.ui.setProperties({
      showBasicNav: true,
      showMainNav: false,
      showSideNav: false
    });
  },

  resetController() {
    this._super(...arguments);
    this.ui.setProperties({
      showBasicNav: false,
      showMainNav: true
    });
  }

});
