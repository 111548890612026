import Route from '@ember/routing/route';

export default Route.extend({

  setupController(controller, model) {
    this._super(...arguments);

    controller.set('experience', model);
    controller.set('loading', true);

    this.store.query('blog-post', {
      per_page: 60,
      selectedExperienceNames: [model.get('name')]
    }).then((blogPosts) => {
      controller.set('blogPosts', blogPosts);
      controller.set('loading', false);
    });

  }

});
