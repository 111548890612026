import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  observer
} from '@ember/object';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';
import {
  task
} from 'ember-concurrency';

export default Controller.extend({

  actions: {

  }
});
