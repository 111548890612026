import TripWidgetComponent from 'b5b/components/t-widget/trip/component';

import {
  inject as service
} from '@ember/service';

import {
  alias
} from 'ember-decorators/object/computed';

export default TripWidgetComponent.extend({
  classNames: ['bottom-bar'],
  dateFormat: "D MMM",

  tripService: service('trip'),
  screen: service(),
  ui: service(),
  router: service(),
  settings: service(),
  @alias('router.currentRouteName') currentRouteName: null,

  didInsertElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', true);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', false);
  },

  actions: {
    openDatePicker(e) {
      this.tripService.tripDatePickerInstance.open();
      e.stopPropagation()
    },
  }

});
