import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend({

  attrs: {
    personalDetailsComplete: {
      serialize: false
    },
    insuranceDetailsComplete: {
      serialize: false
    }
  }

});
