import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),


  model() {
    return this.store.query('user', {agency_id: this.whitelabel.agency.id, generate_signature: true})
  },

  afterModel(model, transition) {
    this._super(...arguments);
    if (this.whitelabel.isForAgency) {
      transition.abort();
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('users', model)
  }

});
