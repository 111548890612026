import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import config from 'b5b/config/environment';

export default payFormComponent.extend({

  chargeType: 'direct-bank-transfer',

  classNames: ['pay-form'],

  router: service(),
  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  didInsertElement() {
    this._super(...arguments);
    this.set('_payButtonReady', true)
  }

});



