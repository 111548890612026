import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNameBindings: [':kb-gallery', 'hovering'],

  insertedImages: false,
  haveLoadedImages: false,
  externalActivation: false,
  hovering: false,
  triggerGallery: false,
  _cachedTriggerGallery: false,

  activeIndex: -1, // cover

  currentActive: null,
  currentActiveIndex: null,

  transitionDuration: 500,

  waitingForImage: false,
  loadedImages: null,
  nextIndex: 0,

  didInsertElement(){
    this._super(...arguments);
    this.set('loadedImages', [-1]);
  },

  didReceiveAttrs(){
    this._super(...arguments);

     if (this.get('carouselImages') && !this.get('haveLoadedImages')) {
      if (this.get('carouselImages').then){
        this.get('carouselImages').then((images) => {
          if (this.get('isDestroying') || this.get('isDestroyed')){
            return;
          }

          // override and replace the promise here
          this.set('insertedImages', true);
          this.set('images', images);
        })
      } else {

       if (this.get('isDestroying') || this.get('isDestroyed')){
          return;
        }
        this.set('insertedImages', true);
        this.set('images', this.carouselImages);
      }
    }

    if (this.get('externalActivation')){
      if (this.triggerGallery && this.triggerGallery !== this._cachedTriggerGallery){
        this.set('_cachedTriggerGallery', this.triggerGallery);
        this.startGallery();
      } else {
        this.set('_cachedTriggerGallery', this.triggerGallery);
        this.stopGallery()
      }
    }
  },

  mouseEnter() {
    if (!this.get('externalActivation')) {
      this.startGallery()
    }
  },

  startGallery(){
    this.set('hovering', true);

    if (this.onMouseEnter && !this.get('haveLoadedImages')) {
      this.onMouseEnter();
    }

    this.restartTimer = run.next(() => this.startPaging());
  },

  stopGallery(){
    this.setProperties({
      insertedImages: false,
      hovering: false,
      activeIndex: -1,
      transitionDuration: -1,
      waitingForImage: false,
      nextIndex: 0
    })
    run.cancel(this.restartTimer);
    run.cancel(this.nextTimer);
  },

  startPaging(){
    run.cancel(this.nextTimer);

    if (this.insertedImages) {
      if ((this.childViews.length - 3) >= this.activeIndex){
        this.set('nextIndex', this.activeIndex + 1);
      } else {
        this.set('nextIndex', -1);
      }
    }

    if (!this.get('loadedImages').includes(this.nextIndex)){
      this.set('waitingForImage', true);
      this.nextTimer = run.later(() => {
        this.restartTimer = run.next(() => this.startPaging());
      }, 200);
      return;
    }

    if (this.waitingForImage){
      this.set('waitingForImage', false);
    }

    this.nextTimer = run.later(() => {
      this.set('activeIndex', this.nextIndex);

      this.restartTimer = run.next(() => this.startPaging());
    }, this.transitionDuration);

    this.set('transitionDuration', 2000);
  },

  mouseLeave() {
    if (!this.get('externalActivation')) {
      this.stopGallery()
    }
  },

  willDestroyElement(){
    this._super(...arguments);
    this.stopGallery();
  },

  actions: {
    addLoadedImage(idx){
      this.get('loadedImages').pushObject(idx);

      if ((idx === this.nextIndex) && this.waitingForImage) {
        // if pending image is loaded transition immediately
        run.cancel(this.restartTimer);
        this.restartTimer = run.next(() => this.startPaging());
      }
    }
  }
});
