import { helper } from '@ember/component/helper';
import {
  capitalizeFirstLetter
} from 'b5b/utils';

export function guestSummaryName(params, namedArgs) {
  let guestSummary = namedArgs.guestSummary;  
  if (guestSummary.name) {
    let name = capitalizeFirstLetter(guestSummary.name);
    if (guestSummary.surname) {
      name = name + " " + capitalizeFirstLetter(guestSummary.surname)      
    }
    return name;
  } else {
    return '';
  }
}

export default helper(guestSummaryName);
