import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import RSVP from 'rsvp';

export default Component.extend({
  classNames: ['upload-box'],
  fileQueue: service('file-queue'),
  
  actions: {
    uploadDocument: function (file) {
            
      let url = this.whitelabel.getFullHostUrl('/api/v1/upload_requests/url_for_trip_doc');
      let authHeader = `Token token="${this.session.data.authenticated.token}", email="${this.session.data.authenticated.email}"`

      let data = {name: file.blob.name, type: file.blob.type, slug: this.get('trip.friendlyId')};
      this.set('photosUploading', true)


      let context = this;
      let doc = null;
      RSVP.cast($.ajax({
        url,
        type: "POST",
        data: `utf8=%E2%9C%93&data%5D=${JSON.stringify(data)}`,
        headers: {'Authorization': authHeader},
        context: this
      })).then(function (urlForDocResponse) {
        let name = file.name;
        if (name.lastIndexOf('.')>0) {
          name = name.slice(0, name.lastIndexOf('.'))
        }
        doc = context.store.createRecord("document", {
          path: urlForDocResponse.credentials.key,
          trip: context.trip,
          name,
          enabled: true
        });        
        return file.upload(urlForDocResponse.url, {
          data: urlForDocResponse.credentials
        });
      }).then(function (response) {      
        
        if (this.fileQueue.find(this.fileQueueName).get('files.length')==0) {
          this.set('photosUploading', false)
        }
        return doc.save().then(()=> {
          if (this.afterDocUpload) {
            this.afterDocUpload();
          }
        })
      }.bind(this));
    },
  }
});
