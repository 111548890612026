define("ember-leaflet/helpers/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.icon = _exports.default = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var icon = _exports.icon = isFastBoot ? function () {} : function icon(_, hash) {
    // https://github.com/emberjs/ember.js/issues/14668
    var options = Ember.assign({}, hash);
    return L.icon(options);
  };
  var _default = _exports.default = Ember.Helper.helper(icon);
});