import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';

export default Route.extend(FriendlyIdRoute, {

  store: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  beforeModel(transition) {
    this._super(...arguments);
    if (this.get('whitelabel.isForAgency')) {
      transition.abort();
    }
  },

  model() {
    return this.modelFor('lodge');
  },

  afterModel(model) {
    if (this.get('isFastBoot')) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('lodgeInfo'));
      });
    }
  },

  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('lodge', model);
  }

});
