import Mixin from '@ember/object/mixin';

/*
  NOTE:
  We can eliminate this mixin potentially, just need to update existing includes to point directly to the service
  However, using this is probably DRYer
*/


import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default Mixin.create({

  pills: service(),

  @alias('pills.selectedPills') selectedPills: null,

  populatePills() {
    this._super(...arguments);
    this.pills.populatePills();
  },

  selectResult(result, type, key) {
    this._super(...arguments);
    this.pills.selectResult(result, type, key);
  },

  removeSelection(pill) {
    this._super(...arguments);
    this.pills.removeSelection(pill);
  },

  getBudgetPillNameForPriceRange(priceRange) {
    return this.pills.getBudgetPillNameForPriceRange(priceRange);
  },

  externalReset() {
    this._super(...arguments);
    this.pills.externalReset();
  },

  actions: {
    removeSelection(pill) {
      this.removeSelection(pill);
    },

    selectResult(result, type) {
      this.selectResult(result, type);
    }
  }

});
