import {
  alias
} from '@ember/object/computed';
import {
  computed
} from 'ember-decorators/object';
import DS from 'ember-data';
import Self from 'b5b/models/region-info';

export default DS.Model.extend({  
  note: DS.attr(),
  noteTitle: DS.attr(),
  highlights: DS.attr(),
  wildlifeIntro: DS.attr(),
  seasonalRating: DS.attr(),
  bestTimeToGo: DS.attr(),
  overview: DS.attr(),
  overviewWhyLove: DS.attr(),
  overviewOffBeatenTrack: DS.attr(),
  overviewThinkAbout: DS.attr(),
  metaImages: DS.attr(),
  
  region: DS.belongsTo('region', {
    async: false
  }),

  defaultSchedule: DS.belongsTo('schedule', {
    async: false,
    inverse: null
  }),
  regionsWildlives: DS.hasMany('regionsWildlives', {
    async: false
  }),
  recommendedLodges: DS.hasMany('lodge', {
    inverse: null
  }),
  // These are included by default when serialized from the server, as opposed to the ones on the region model that are just the ids
  experiences: DS.hasMany('experience', {
    inverse: null
  }),

  name: alias('region.name'),
  images: alias('metaImages'),
  wildlives: alias('regionsWildlives'),


  makeCopy() {

    let copy = this.get('store').createRecord('regionInfo');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })

    return copy;
  }    
});
