import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({

  ui: service(),

  setupController(controller) {
    this._super(...arguments);
    this.ui.setProperties({
      showMainNav: false,
      showNoNav: true,
    });
  },

  resetController() {
    this._super(...arguments);
    this.ui.setProperties({
      showNoNav: false
    });
  }

});
