import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  or,
  alias
} from 'ember-decorators/object/computed';

import NavBarComponent from 'b5b/components/nav-bar/component';
export default NavBarComponent.extend({
  classNames: ['basic-agency', 'agency-nav'],
  session: service(),

  @alias('whitelabel.agency') agency: false,

});
