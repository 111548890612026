import Component from '@ember/component';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import {
  serializeDate
} from 'b5b/transforms/b5bdate';
import {
  performSingleConversion
} from 'b5b/helpers/format-currency';
import {
  getEntityFiltersForServerQuery
} from 'b5b/utils';


export default Component.extend({

  ui: service(),
  tripService: service('trip'),

  didInsertElement() {
    this.getLodgePrices();
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES.map((cur, index) => {
      return {
        name: cur,
        symbol: CURRENCY_SYMBOLS[index]
      };
    });
  },

  getLodgePrices() {

    let selectedLodges = [];
    if (this.get('ui.addLodgeOptionModal.stage.lodgeOptions')) {
      selectedLodges = this.get('ui.addLodgeOptionModal.stage.lodgeOptions').map((lodgeOption)=>{
        return lodgeOption.get('lodge');
      })
    }
    if (this.get('ui.addLodgeOptionModal.stage.lodge')) {
      selectedLodges.pushObject(this.get('ui.addLodgeOptionModal.stage.lodge'))
    }

    let queryAttrs={
      filters: getEntityFiltersForServerQuery({lodges: selectedLodges})
    }
    if (this.ui.addLodgeOptionModal.stage.startDate) {
      queryAttrs.startDate=serializeDate(this.ui.addLodgeOptionModal.stage.startDate)
      queryAttrs.endDate=serializeDate(this.ui.addLodgeOptionModal.stage.endDate)
    }
    this.store.query('lodge', queryAttrs).then((lodges)=> {

    })

  },

  actions: {
    addLodgeOption(options, selectResult) {
      if (selectResult.action=='search:select') {
        if (!options.stage.get('lodgeOptions').mapBy('lodge').includes(selectResult.result)) {
          let queryAttrs={
            ids: [selectResult.result.id]
          }
          if (this.ui.addLodgeOptionModal.stage.startDate) {
            queryAttrs.startDate=serializeDate(this.ui.addLodgeOptionModal.stage.startDate)
            queryAttrs.endDate=serializeDate(this.ui.addLodgeOptionModal.stage.endDate)
          }
          this.store.query('lodge', queryAttrs).then((lodges)=> {

            let lodge = lodges.get('firstObject')
            let currency = lodge.accuratePriceCurrency || 'usd';
            let lodgeOption = this.store.createRecord('lodge-option', {
              lodge,
              currency,
              priceType: 'net-ppn-extra',
              sequence: options.stage.get('lodgeOptions.length'),
            });
            let netPerPersonNightExtra=0;
            if (currency == this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceCurrency')) {
              netPerPersonNightExtra = lodge.accuratePriceAmount - this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceAmount');
            } else {
              currency = 'usd';
              netPerPersonNightExtra = performSingleConversion(lodge.accuratePriceAmount, 'usd', lodge.accuratePriceCurrency) - performSingleConversion(this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceAmount'), 'usd', this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceCurrency'))
            }
            let markup = options.stage.get('itinerary.quote.markupPercentage') || 25;
            let numGuests = this.get('ui.addLodgeOptionModal.stage.itinerary.numGuests')
            let numNights = this.get('ui.addLodgeOptionModal.stage.numNights')

            let netPerPersonExtra = netPerPersonNightExtra * numNights;
            let netExtra = netPerPersonExtra * numGuests;
            let totalSellingPriceExtra = netExtra * (100 + parseFloat(markup)) / (100 * this.get('whitelabel.agency.meta.pcTfmm'));
            if (this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceAmount') && !parseInt(this.get('ui.addLodgeOptionModal.stage.lodge.accuratePriceAmount'))==0 && lodge.accuratePriceAmount && !parseInt(lodge.accuratePriceAmount)==0) {
              lodgeOption.setProperties({
                netPerPersonNightExtra,
                netPerPersonExtra,
                netExtra,
                markup,
                totalSellingPriceExtra,
                currency
              })
            }
            options.stage.get('lodgeOptions').pushObject(lodgeOption);
          })
        }
        this.get('tripService').madeChanges();
      }
    },
    removeLodgeOption(options) {
      options.stage.get('lodgeOptions').removeObject (options.lodgeOption)
      this.get('tripService').madeChanges();
    },
    setCurrency(lodgeOption, currency) {
      lodgeOption.set('currency', currency.name);
      this.get('tripService').madeChanges();
    }
  }
});
