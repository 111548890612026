import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  task
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  session: service(),

    @computed('deal.trips')
    backgroundImageStyle(kodakOriginalUrl, coverStyle) {
    let style = `background-image: url('https://waybird.imgix.net/users/profile_images/000/001/793/original/eve.jpg?w=100&h=100&auto=format&crop=fit&fit=min&dpr=2&q=50');`
    if (coverStyle) {
        style = style + " background-position: " + "no-repeat";
    }
    return Ember.String.htmlSafe(style);
    },

    init() {
        this._super(...arguments);
        this.loadTripsTask.perform()
    },

    loadTripsTask: task(function*() {
      let ids = this.deal.hasMany('trips').ids();
      let queryAttrs = {
        sort: 'recommended',
        ids
      };

      if (ids.length > 0) {
        return this.store.query('tripSummary', queryAttrs).then((tripSummaries)=>{
          this.set('tripSummaries', tripSummaries)
        })
      }

    }).keepLatest(),
});
