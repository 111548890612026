import {
  helper as buildHelper
} from '@ember/component/helper';

export function itemById(params, namedArgs) {
  if (namedArgs.array && namedArgs.id) {
    let item = namedArgs.array.filterBy('id', namedArgs.id).firstObject;

    if (namedArgs.key) {
      return item.get(namedArgs.key)
    }

    return item;
  }
}

export default buildHelper(itemById);
