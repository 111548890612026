import Helper from '@ember/component/helper';
import {
  inject as service
} from '@ember/service';

export default Helper.extend({

  tripService: service('trip'),

  compute(params, namedArgs) {
    return this.tripService.customiseLinkParams(namedArgs.trip, namedArgs.options)    
  }

});

