import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['recommended-lodges'],
  entityModals: service(),
  lodgeUtils: service(),
  tripService: service('trip'),

  init() {
    this._super(...arguments);

    if (!this.trip.tripIdeasLoaded){
      console.log('THIS COMPONENT CAN ONLY BE INSERTED ONCE TRIP IDEAS LOADED')
    }

    const { lodgeIdeas, lodgeQueryPromise } = this.lodgeUtils.getLodgeIdeas(this.trip, this.stage, true, true);
    lodgeQueryPromise.then(()=> {
      this.set('lodgesQueried', true)
    })
    this.set('lodgeIdeas', lodgeIdeas);
  },

});
