import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';

export default Component.extend({

  classNames: ['region-form'],

  whitelabel: service(),
  router: service(),
  store: service(),
  cache: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.editEntityModalOptions') editEntityModalOptions: null,
  @alias('tripService.savingCustomEntity') savingCustomEntity: false,


});
