import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { alias } from "ember-decorators/object/computed";

export default Component.extend({
  tripService: service("trip"),
  @alias("tripService.currentTrip") trip: null,
  actions: {
    saveName() {
      this.set("tripService.showTripNamingWithoutSaveModal", false);
      this.get("tripService").madeChanges();
    },
  },
});
