define("ember-leaflet/macros/to-lat-lng", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _default() {
    var latKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'lat';
    var lngKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lng';
    return Ember.computed(latKey, lngKey, {
      get: function get() {
        var _ref = [this.get(latKey), this.get(lngKey)],
          lat = _ref[0],
          lng = _ref[1];
        return this.L.latLng(lat, lng);
      },
      set: function set(key, value) {
        this.setProperties(_defineProperty(_defineProperty({}, latKey, value ? value.lat : value), lngKey, value ? value.lng : value));
        return value;
      }
    });
  }
});