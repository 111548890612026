import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    guestLineItems: {
      serialize: 'records'
    },
    perGuest: false
  },


  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    if (json.markup_percentage === '') {
      json.markup_percentage = null
    }

    json.guest_line_items_attributes = json.guest_line_items;
    delete json.guest_line_items;

    return json;
  }

});
