import Component from '@ember/component';

export default Component.extend({

  classNames: ['essential-info'],

  countries: [],

  showEssentialInfoModal: false,
  modalContent: null,
  modalType: null,

  willDestroyElement() {
    this.setProperties({
      modalContent: null,
      modalType: null,
      showEssentialInfoModal: false
    });
  },

  actions: {
    openModal(type, country) {
      this.setProperties({
        modalContent: country.get(`countryInfo.${type}`),
        modalType: `${type}`,
        showEssentialInfoModal: true
      });
    }
  }

});