import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['routes-list'],

  ui: service(),
  scroll: service(),
  screen: service(),
  messageBus: service(),
  fastboot: service(),

  xplorer: service('components/x-plorer'),

  reloadTimer: null,

  actions: {
    transitionRouteSlider(val, type) {
      this.get('messageBus').publish('suggestion-added', val, type);
    }
  }
});
