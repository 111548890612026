import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  getEntityFiltersByEntityIdsForServerQuery
} from 'b5b/utils'

export default Component.extend({

  classNames: ['recommended-places'],

  store: service(),
  tripService: service('trip'),

  trip: null,
  regions: null,
  selectedCountry: null,

  hideNav: false,

  newStageNumNights: 3,

  didReceiveAttrs(){
    this._super(...arguments);

    let queryParams = {
      sort: 'sequence_asc',
      currentRegionIds: this.uniqueRegionIds,
      per_page: 18
    }

    if (this.uniqueRegionIds.length == 0) {
      queryParams.per_page = 400;
      this.set('hideNav', false);
    } else {
      this.set('hideNav', true);
      this.set('selectedCountry', null);
    }

    if (!(this.uniqueRegionIds && this.uniqueRegionIds.length>0) && this.uniqueCountryIds) {
      this.set('hideNav', true);
      queryParams.filters = getEntityFiltersByEntityIdsForServerQuery({countryIds: this.uniqueCountryIds});
    }


    this.store.query('region', queryParams).then((regions) => {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('regions', regions);
    });
  },

  @computed('regions.[]', 'selectedCountry', 'uniqueRegionIds')
  sortedRegions(regions, selectedCountry, uniqueRegionIds){
    if (!regions) {
      return [];
    }

    if (selectedCountry) {
      return regions.filterBy('country', selectedCountry)
    }

    return regions.filter((region) => {
      if (!uniqueRegionIds.includes(region.id)){
        return region;
      }
    })
  },

  @computed('regions.[]')
  possibleCountries(regions){
    if (!regions) {
      return [];
    }

    return regions.mapBy('country').uniqBy('id');
  },

  @computed('selectedCountry', 'uniqueRegionIds')
  seeMoreParams(selectedCountry, uniqueRegionIds) {
    let params = {
      changingRegion: true
    };

    if (selectedCountry){
      params['selectedCountryNames'] = [selectedCountry.name]
    } else {
      params['currentRegionIds'] = uniqueRegionIds;
    }

    return [
      'regions', {
        isQueryParams: true,
        values: params
      }
    ];
  }

});
