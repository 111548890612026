import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';



export default Route.extend({

  xplorer: service('components/x-plorer'),
  messageBus: service(),

  setupController(controller) {
    this._super(...arguments);
    this.get('xplorer').setupController(controller);
    controller.loadReviewsTask.perform()
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.get('xplorer').teardownController();
      this.clearFilters(controller)
    }
  },

  clearFilters(controller) {
    controller.setProperties({
      filters: [],
      page: 1,
      sort: null,
      starRating: 'all'
    });
  },  
  actions: {
    clearReviewsFilters() {
      let controller = this.controllerFor('reviews');
      this.clearFilters(controller)
      run.next(() => {
        this.messageBus.publish('suggestions-refresh');
        // controller.loadReviewsTask.perform()
      })      
    }
  }

});
