define('ember-chrome-devtools/formatters/object', ['exports', 'ember-chrome-devtools/formatters/utils/jsonml'], function (exports, _jsonml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ObjectFormatter = undefined;
  exports.property = property;
  exports.properties = properties;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _marked = regeneratorRuntime.mark(getProperties),
      _marked2 = regeneratorRuntime.mark(properties);

  /** All properties from the object and its prototype chain, including non-enumerables */
  function getProperties(obj) {
    var seen, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, name;

    return regeneratorRuntime.wrap(function getProperties$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            seen = Object.create(null);

          case 1:
            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            _context.prev = 4;
            _iterator = Object.getOwnPropertyNames(obj)[Symbol.iterator]();

          case 6:
            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
              _context.next = 15;
              break;
            }

            name = _step.value;

            if (name in seen) {
              _context.next = 12;
              break;
            }

            seen[name] = true;
            _context.next = 12;
            return [name, Object.getOwnPropertyDescriptor(obj, name)];

          case 12:
            _iteratorNormalCompletion = true;
            _context.next = 6;
            break;

          case 15:
            _context.next = 21;
            break;

          case 17:
            _context.prev = 17;
            _context.t0 = _context['catch'](4);
            _didIteratorError = true;
            _iteratorError = _context.t0;

          case 21:
            _context.prev = 21;
            _context.prev = 22;

            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }

          case 24:
            _context.prev = 24;

            if (!_didIteratorError) {
              _context.next = 27;
              break;
            }

            throw _iteratorError;

          case 27:
            return _context.finish(24);

          case 28:
            return _context.finish(21);

          case 29:
            if (obj = Object.getPrototypeOf(obj)) {
              _context.next = 1;
              break;
            }

          case 30:
          case 'end':
            return _context.stop();
        }
      }
    }, _marked, this, [[4, 17, 21, 29], [22,, 24, 28]]);
  }

  /** Used in tests */
  function lookupDescriptor(obj, key) {
    do {
      var descriptor = Object.getOwnPropertyDescriptor(obj, key);
      if (descriptor) {
        return descriptor;
      }
    } while (obj = Object.getPrototypeOf(obj));
  }

  /**
   * Compute JsonML for an object property
   * @param obj the object
   * @param key the property name
   * @param descriptor the property descriptor
   * @returns {*} JsonML
   */
  function property(obj, key) {
    var descriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : lookupDescriptor(obj, key);

    Ember.assert('missing descriptor', !!descriptor);

    var enumerable = descriptor.enumerable;

    if (typeof descriptor.get === 'function') {
      if (descriptor.get.name === 'GETTER_FUNCTION' || descriptor.get.isInheritingGetter || key === 'isDestroyed' || key === 'isDestroying') {
        // Ember getter that's probably safe to evaluate
        var _value = descriptor.get.call(obj);
        return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.reference(_value));
      }

      // ES5 getter: forcing the property to compute might have a side effect
      return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.lazy(function () {
        return _jsonml.reference(Ember.get(obj, key));
      }));
    }

    var value = descriptor.value;
    if (typeof value === 'function') {
      // ignore
      return;
    }

    if (value instanceof Ember.ComputedProperty) {
      // For convenience, show cached ComputedProperty without needing to expand it
      // Don't check the cache for AliasedProperty https://github.com/emberjs/ember.js/issues/15545
      var cached = Ember.cacheFor(obj, key);
      if (cached !== undefined) {
        // use the cached value
        return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.computedPropertyIcon(), _jsonml.reference(cached));
      }
    }

    if (value !== null && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && value.isDescriptor) {
      // ComputedProperty (not cached) or AliasedProperty
      // Create a lazy getter, because forcing the property to compute might have a side effect
      return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.computedPropertyIcon(), _jsonml.lazy(function () {
        return _jsonml.reference(Ember.get(obj, key));
      }));
    }

    return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.reference(value));
  }

  function properties(obj) {
    var _iteratorNormalCompletion2, _didIteratorError2, _iteratorError2, _iterator2, _step2, _ref, _ref2, key, descriptor, element;

    return regeneratorRuntime.wrap(function properties$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            _iteratorNormalCompletion2 = true;
            _didIteratorError2 = false;
            _iteratorError2 = undefined;
            _context2.prev = 3;
            _iterator2 = getProperties(obj)[Symbol.iterator]();

          case 5:
            if (_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done) {
              _context2.next = 17;
              break;
            }

            _ref = _step2.value;
            _ref2 = _slicedToArray(_ref, 2);
            key = _ref2[0];
            descriptor = _ref2[1];
            element = property(obj, key, descriptor);

            if (!element) {
              _context2.next = 14;
              break;
            }

            _context2.next = 14;
            return element;

          case 14:
            _iteratorNormalCompletion2 = true;
            _context2.next = 5;
            break;

          case 17:
            _context2.next = 23;
            break;

          case 19:
            _context2.prev = 19;
            _context2.t0 = _context2['catch'](3);
            _didIteratorError2 = true;
            _iteratorError2 = _context2.t0;

          case 23:
            _context2.prev = 23;
            _context2.prev = 24;

            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }

          case 26:
            _context2.prev = 26;

            if (!_didIteratorError2) {
              _context2.next = 29;
              break;
            }

            throw _iteratorError2;

          case 29:
            return _context2.finish(26);

          case 30:
            return _context2.finish(23);

          case 31:
          case 'end':
            return _context2.stop();
        }
      }
    }, _marked2, this, [[3, 19, 23, 31], [24,, 26, 30]]);
  }

  var ObjectFormatter = exports.ObjectFormatter = function () {
    function ObjectFormatter() {
      _classCallCheck(this, ObjectFormatter);
    }

    _createClass(ObjectFormatter, [{
      key: 'header',
      value: function header(obj) {
        if (obj instanceof Ember.Object && !Ember.Array.detect(obj)) {
          return _jsonml.header(obj.toString());
        }
      }
    }, {
      key: 'hasBody',
      value: function hasBody() {
        return true;
      }
    }, {
      key: 'body',
      value: function body(obj) {
        return _jsonml.list.apply(_jsonml, _toConsumableArray(properties(obj)));
      }
    }]);

    return ObjectFormatter;
  }();
});