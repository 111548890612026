import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  run
} from '@ember/runloop';

import togglePillsComponent from 'b5b/components/toggle-pills/component';

export default togglePillsComponent.extend({
  classNames: ['stars', 'x-suggestions'],
  allStarRatingsCompilation: [{type: "star-rating", count: 1, name: "All", value: "all"}, {type: "star-rating", count: 1, name: "5 stars", value: "5"}, {type: "star-rating", count: 1, name: "4 stars", value: "4"}, {type: "star-rating", count: 1, name: "3 stars", value: "3"}, {type: "star-rating", count: 1, name: "2 stars", value: "2"}, {type: "star-rating", count: 1, name: "1 star", value: "1"}],

  didInsertElement() {
    this._super(...arguments);
    this.setViewport();
    this.$('.viewport').scrollLeft(0);
    // this.set('boundScrollHandler', this.onScroll.bind(this));
  },

  setViewport() {
    this.set('viewport', this.element.getElementsByClassName('viewport suggestions')[0]);
    this.get('viewport').addEventListener('scroll', this.onScroll.bind(this), false);
  },

  didRender() {
    this._super(...arguments);

    if (this.element.getElementsByClassName('viewport suggestions')[0] && this.set('viewport') !== this.element.getElementsByClassName('viewport suggestions')[0]) {
      this.setViewport();
    }
    this.updateScrollState();
  },

  onScroll() {
    this.throttleTimer = run.throttle(this, this.updateScrollState, 150, false);
  },

  updateScrollState() {
    this.scrollStateTimer = run.next(() => {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.setProperties({
        canScrollLeft: this.viewport.scrollLeft > 10,
        canScrollRight: this.viewport.scrollWidth - this.viewport.offsetWidth !== this.viewport.scrollLeft,
        scrolled: true
      });
    })

  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.throttleTimer);
    run.cancel(this.scrollStateTimer);
    // this.$('.viewport').off('scroll', this.boundScrollHandler);
    this.get('viewport').removeEventListener('scroll', this.onScroll.bind(this), false);
  },

  actions: {
    scrollRight() {
      $('.viewport').animate({
        scrollLeft: '-=353'
      }, 300);
    },

    scrollLeft() {
      $('.viewport').animate({
        scrollLeft: '+=353'
      }, 300);
    }
  }

});
