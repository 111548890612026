import Route from '@ember/routing/route';
import EntitiesExperiencesRouteMixin from 'b5b/mixins/entities/experiences/route';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(EntitiesExperiencesRouteMixin, {

  selectedModelType: 'selectedLodgeNames',
  entityRouteName: 'lodge',

  headTags() {
    this._super(...arguments);
    let model =  this.modelFor(this.entityRouteName);

    let robots = 'noindex, nofollow';
    let link  = `/region/${model.get('region.friendlyId')}/experiences`;

    return generateMeta({
      robots,
      link
    });
  },

});
