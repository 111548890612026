import {
  reads
} from '@ember/object/computed';
import Service, {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  run
} from '@ember/runloop';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent,
  getLoccalStorageKeyModalWizardShown,
  setItemInLocalStorage
} from 'b5b/utils';
import RSVP from 'rsvp';

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default Service.extend({

  session: service(),
  store: service(),
  scroll: service(),
  screen: service(),
  router: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  entityNavVisible: true,
  monthNavVisible: true,
  forceScrollOffMainNav: false,

  sideNavOptions: [],

  entityNavData: null,

  homeImages: [],
  homeRotationIndexCache: 0,

  showUnpricedLodgesModal: false,
  showPayBalanceMessageModal: false,
  showProcessingModal: false,
  showGeneralMessageModal: false,
  showHowItWorksRoutesModal: false,
  showSaveMoneyModal: false,
  showTripPlanningModal: false,

  isPrimarySearchOpen: false,

  showingFullScreenEmailSubscription: false,
  subsciptionTimer: null,

  preferedWizardBanner: null,

  showUXFeedbackModal: false,
  showWizardModal: false,
  inWizard: false,

  showRegisterModal: false,
  showLoginModal: false,
  showPasswordResetModal: false,
  showChangePasswordModal: false,

  showPricingDetailsModal: false,
  showSavedWizardTripsModal: false,
  showUneditableTripModal: false,

  showBasicNav: false,
  showMainNav: false,
  showSideNav: false,
  sideNavActive: false,

  showHeroNav: false,

  generalMessageHeading: null,
  generalMessage: null,

  viewedEntityToolsModal: null,

  viewingMapListing: false,

  showVideo: false,
  videoThumbnail: 'https://waybird.imgix.net/general/south-luangwa-walking-safari-wildlife-zambia-timbuktu-travel-video-thumbnail.jpg',
  videoLink: 'https://player.vimeo.com/video/195304638?byline=0&badge=0&autoplay=1&color=128D8D&title=0&portrait=0',

  userResetEmail: null,

  footerBarForceShow: true,
  footerBarWhite: false,

  hideHubSpot: false,

  googleMapsPromise: null,
  youtubePromise: null,
  agencyTopNavHeight: 54,

  @computed()
  hasTouchSupport() {
    if (!this.get('isFastBoot')) {
      return window.DocumentTouch && document instanceof window.DocumentTouch ||
        navigator.maxTouchPoints > 0 ||
        window.navigator.msMaxTouchPoints > 0;
    }
  },

  @computed('scroll.isCTABarVisible', 'screen.isTablet', 'screen.isDesktop', 'footerBarForceShow', 'bottomBarExists', 'modalFooterExists', 'footerBarWhite', 'screen.is600', 'screen.isMobileOnly')
  bumpUpHubSpot(isCTABarVisible, isTablet, isDesktop, footerBarForceShow, bottomBarExists, modalFooterExists, footerBarWhite, is600, isMobileOnly) {
    return (!is600 && (bottomBarExists || footerBarWhite) && (isCTABarVisible || footerBarForceShow))
      || (modalFooterExists && isMobileOnly);
  },

  @computed('router.currentRouteName', 'entityNavData', 'forceScrollOffMainNav')
  scrollOffMainNav(currentRouteName, entityNavData, forceScrollOffMainNav) {
    return forceScrollOffMainNav || entityNavData || currentRouteName == 'trip.index.enquire' || currentRouteName.includes('start');
  },

  @computed('scrollOffMainNav', 'entityNavData', 'agencyTopNavHeight')
  entityFloatingWidgetTop(scrollOffMainNav, entityNavData, agencyTopNavHeight) {
    let top = 38;
    if (!scrollOffMainNav) {
      top = top + agencyTopNavHeight;
    }
    if (entityNavData) {
      top = top + 54;
    }
    return top;
  },

  init() {
    this._super(...arguments);
  },

  activateFullScreenEmailSubscription() {
    run.cancel(this.subscriptionTimer);
    // slight delay to allow for local storage check
    this.subscriptionTimer = run.later(() => {
      this.set('showingFullScreenEmailSubscription', true);
    }, 1000);
  },


  setAgencyTopNavHeight () {
    const agencyTopNavHeight = $('.site-logo').parents('.nav-bar').height();
    this.set('agencyTopNavHeight', agencyTopNavHeight);
    cssVars({
      variables: {
        '--agencyTopNavHeight': agencyTopNavHeight,
        '--fullNavHeight': agencyTopNavHeight+54+'px',
        '--extraBannerHeight': '64px',
      }
    });
  },

  showGeneralMessage(messageHeading, message) {
    trackEvent('general-message:show')
    this.setProperties({
      showGeneralMessageModal: true,
      generalMessageHeading: messageHeading,
      generalMessage: message
    });
  },

  dontShowWizardModalEver() {
    setItemInLocalStorage(getLoccalStorageKeyModalWizardShown(), true);
    this.set('showWizardModal', false);
  },

  closeEnquirePopupModal() {
    this.set('showEnquirePopupModal', false)
  },

  toggleLike(entityType, entity) {

    let actualEntityType=entityType;
    if (entityType=='tripSummary') {
      actualEntityType='trip';
    }

    let user = this.get('session.currentUser');
    if (!user) {
      this.set('lastLikeAttempt', {
        entityType,
        entity
      });
      trackEvent('like:login-challenge:displayed', {
        entity: entityType
      });
      return this.set('showRegisterModal', true);
    }

    let entityFavourites = user.get(`${actualEntityType}Favourites`);
    var likedEntity = entityFavourites.findBy(`${actualEntityType}Id`, entity.get('id'));
    var unliking = true && likedEntity ? true : false;
    if (unliking) {
      entityFavourites.removeObject(likedEntity);
      likedEntity.deleteRecord();
    } else {
      likedEntity = this.get('store').createRecord(`${actualEntityType}-favourite`);
      likedEntity.set('user', user);
      likedEntity.set(entityType, entity);
      entityFavourites.addObject(likedEntity);
    }

    if (!likedEntity.debouncedSave) {
      likedEntity.debouncedSave = debounce(run.bind(likedEntity, 'save'), 1500);
    }
    likedEntity.debouncedSave();

    trackEvent('like:toggle', {
      liked: unliking,
      entity: entityType
    });
  },

  getGoogleMapsReadyPromise() {
    if (this.googleMapsPromise) {
      return this.googleMapsPromise
    } else {
      let context = this;
      return new RSVP.Promise(function(outerResolver) {
        addAsyncScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDuSUd2bpYNZUomo5Ph_EwgAe2UzV_T47E&libraries=places', null, function() {
          let googleMapsPromise = new RSVP.Promise(function(resolve) {
            resolve(true);
          });
          context.set('googleMapsPromise', googleMapsPromise);
          outerResolver(googleMapsPromise);
        });

      });
    }

  },

  getBlobUtilReadyPromise() {
    if (this.blobUtilPromise) {
      return this.blobUtilPromise
    } else {
      let context = this;
      return new RSVP.Promise(function(outerResolver) {
        addAsyncScript('https://unpkg.com/blob-util/dist/blob-util.min.js', null, function() {
          let blobUtilPromise = new RSVP.Promise(function(resolve) {
            resolve(true);
          });
          context.set('blobUtilPromise', blobUtilPromise);
          outerResolver(blobUtilPromise);
        });

      });
    }

  },

  getYoutubeReadyPromise() {
    if (this.youtubePromise) {
      return this.youtubePromise
    } else {
      let context = this;
      return new RSVP.Promise(function(outerResolver) {
        addAsyncScript('https://www.youtube.com/iframe_api', null, function() {
        });

        let youtubePromise = new RSVP.Promise(function(resolve) {
          window.onYouTubePlayerAPIReady=function() {
            resolve(true);
          }
        });
        context.set('youtubePromise', youtubePromise);
        outerResolver(youtubePromise);

      });
    }

  },

  toggleSideNavActive() {
    this.toggleProperty('sideNavActive')
  },

  actions: {
    closeEnquirePopupModal() {
      this.closeEnquirePopupModal(...arguments)
    }
  }




});
