import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';
import EmberObject from '@ember/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default Route.extend(tripSubRoute, {

  @alias('tripService.currentTrip') currentTrip: false
  
});
