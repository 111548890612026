import { helper } from '@ember/component/helper';
import {
  entityType
} from 'b5b/utils';

export function entityTypeHelper([entity]) {
  return entityType(entity)
}

export default helper(entityTypeHelper);
