import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';

export default Component.extend({
  tagName: '',

  router: service(),
  scroll: service(),

  scrollTimer: null,

  didRender() {
    this._super(...arguments);
    if (this.router.location.location.hash && $(this.router.location.location.hash).position().top) {
      run.cancel(this.scrollTimer);
      this.scrollTimer = run.next(() => {
        let pos = $(this.router.location.location.hash).position().top - 50;
        this.get('scroll').to(pos);
      });
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.scrollTimer);
  }

});
