import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  htmlSafe
} from '@ember/string';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  generateMeta,
  trackEvent,
  metadataDate
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  ui: service(),
  store: service(),
  locationScrollHistory: service(),
  headTagsService: service('head-tags'),
  headData: service(),
  tripService: service('trip'),
  @alias('tripService.hasBackButton') hasBackButton: null,

  scroll: service(),
  session: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  afterModel(model, transition) {
    if (this.get('isFastBoot')) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('lodgeInfo'));
      });
    }
    if (model.blockFromLoading) {
      transition.abort();
      return this.router.transitionTo('index');
    }
    //This is a bit of hack to make sure that the async relationships have loaded
    let asyncModels = RSVP.hash({
      lodgeInfo: model.get('lodgeInfo'),
      country: model.get('country')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.get('headTagsService').collectHeadTags();
    });

    var webPage,
      breadcrumbList;

    model.get('lodgeInfo').then(() => {
      webPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "headline": (model.get('seoName') + ', ' + model.get('region.country.name')),
        "image": {
          "@type": "ImageObject",
          "url": model.get('kodakOriginalUrl') + "?w=1400&h=960",
          "height": 960,
          "width": 1400
        },
        "datePublished": metadataDate(model.get('createdAt')),
        "dateModified": metadataDate(model.get('updatedAt')),
        "publisher": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "author": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "description": model.get('lodgeInfo.overview'),
        "mainEntity": {
          "@type": "Hotel",
          "name": model.get('name'),
          "priceRange": model.get('fromPrice') ? `$${Math.round(model.get('fromPrice')*0.7)} - $${Math.round(model.get('fromPrice')*1.1)}` : 'Enquire',
          "telephone": "+44 (0)203 8083 860",
          "address": (model.get('region.name') + ', ' + model.get('region.country.name')),
          "image": {
            "@type": "ImageObject",
            "url": model.get('kodakOriginalUrl') + "?w=1400&h=960",
            "height": 960,
            "width": 1400
          },
          "geo": {
            "@type": "GeoCoordinates",
            "address": (model.get('region.name') + ', ' + model.get('region.country.name')),
            "addressCountry": model.get('region.country.name'),
            "latitude": model.get('latitude'),
            "longitude": model.get('longitude')
          }
        }
      };

      breadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "http://www.timbuktutravel.com/lodges",
            "name": "Properties",
            "image": "https://assets.timbuktutravel.com/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.timbuktutravel.com/lodge/" + model.get('lodgeFriendlyId'),
            "name": model.get('name'),
            "image": model.get('kodakOriginalUrl') + "?w=1200&h=630"
          }
        }]
      };
    }).finally(() => {
      this.set('headData.dataStructures', [
        htmlSafe(JSON.stringify(webPage)),
        htmlSafe(JSON.stringify(breadcrumbList))
      ]);
    });

  },


  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('lodge:view')
    if (this.get('locationScrollHistory.history.length') === 0) {
      this.set('tripService.hasBackButton', false);
    }

    let links = [{
      name: 'Overview',
      params: {
        routeName: 'lodge.index',
        routeModel: model
      }
    }];

    if (model.belongsTo('region').id()) {
      if (this.whitelabel.showRoutes) {
        links.pushObject({
          name: 'Trips',
          params: {
            routeName: 'lodge.routes',
            routeModel: model
          }
        })
      }
      links.pushObject({
        name: 'When to go',
        params: {
          routeName: 'lodge.when-to-go',
          routeModel: model
        }
      })

      let whenToGo = Ember.Object.create({
        name: 'When to go',
        params: {
          routeName: 'lodge.when-to-go',
          routeModel: model
        },
        hideUntilReady: true
      });
      if (model.get('region.destinationInfo')) {
        model.get('region.destinationInfo').then((destinationInfo)=> {
          if (model.get('destinationInfo.seasonalRating.length') || model.get('destinationInfo.whenToGo')) {
            whenToGo.set('hideUntilReady',false);
          }
        })
      }
      links.pushObject(whenToGo)

      links.pushObject({
        name: 'Experiences',
        params: {
          routeName: 'lodge.experiences',
          routeModel: model
        }
      })
    }


    if (model.get('specialOfferCount') && this.whitelabel.isForTimbuktu) {
      links.pushObject({
        name: 'Special offers',
        params: {
          routeName: 'lodge.offers',
          routeModel: model,
          count: model.get('specialOfferCount')
        }
      });
    }

    this.get('session.currentUserPromise').then((user) => {
      if (user && (user.get('isTimbuktu'))) {
        links.pushObject({
          name: 'Tools',
          params: {
            action: 'ui.viewedEntityToolsModal',
            actionProperty: model
          }
        });
      }

      this.get('ui').setProperties({
        entityNavData: {
          entity: model,
          title: model.get('displayName'),
          titleLink: {
            routeName: 'lodge',
            routeModel: model
          },
          links: links
        }
      });
    })

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (!isExiting) {
      this.get('scroll').to(0, this);
    } else {
      this.set('ui.entityNavData', null);
      this.set('ui.forceScrollOffMainNav', false);
    }
  },

  actions: {
    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);

      if (this.get('changingLodge') &&
        transition.targetName !== 'lodges') {
        this.setProperties({
          hasBackButton: false,
          changingLodge: false
        });
      }
      // return true so willTransition bubbles
      return true;
    }
  }

});
