import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['save-share', 'white'],

  router: service(),
  tripService: service('trip'),
  @alias('tripService.savingTrip') savingTrip: false,

  @computed('entity', 'router._router.currentRouteName')
  isRouteIdea(entity, currentRouteName) {
    return entity.get('isRoute') && currentRouteName.indexOf('route-idea') >= 0;
  },

  @computed('entity')
  isTrip(entity) {
    return entity.constructor.modelName === 'trip'
  }


});