import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  bool
} from 'ember-decorators/object/computed';

import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({

  classNames: 'places-section',

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  country: null,
  region: null,
  excludedPlace: null,

  hideCountryName: false,

  @bool('places.length') isVisible: null,
  isShownAll: false,

  @computed('country', 'region')
  seeAllLink(country, region) {
    if (country) {
      return {
        title: 'Show all places',
        route: 'country.regions',
        item: country
      }
    } else if (region) {
      return {
        title: 'Show all places',
        route: 'region.regions',
        item: region
      }
    } else {
      return {
        title: 'Show all places',
        route: 'countries'
      }
    }
  },

  preRendering: computed('isFastBoot', function() {
    if (typeof Fastboot !== 'undefined' || this.get('isFastBoot')) {
      return;
    }
    return window.timbuktu.preRendering;
  }),

  @computed('places', 'excludedPlace')
  recommendedPlaces(places, excludedPlace) {
    return places.without(excludedPlace);
  },

  actions: {
    showPlaces() {
      this.set('isShownAll', true);
      trackEvent('destination:show-all-places');
    }
  }

});
