import Component from '@ember/component';
import {
  lowerCaseAndDasherized
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: 'col-xs-4 col-sm-2 col-md-2 pt30 text-center',

  @computed('index', 'screen.isMobileOnly')
  wildlifeIconWrapperClass(index, isMobile) {
    if (index < 6 && !isMobile) {
      return 'tall-wildlife-icon-wrapper';
    } else if (index < 3 && isMobile) {
      return 'tall-wildlife-icon-wrapper';
    } else {
      return 'short-wildlife-icon-wrapper';
    }
  },

  svgIconName: function() {
    return 'wildlife--' + lowerCaseAndDasherized(this.get('wildlifeAbundance.wildlife.name'));
  }.property('wildlifeAbundance.wildlife.name'),

  svgIconClasses: function() {
    return `wildlife-icon abundance-${this.get('wildlifeAbundance.abundance')}`;
  }.property('wildlifeAbundance.abundance')

});