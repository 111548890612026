import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';
import {
  not
} from 'ember-decorators/object/computed';


export default Component.extend({


  regions: null,
  countries: null,

  selectedCountry: null,
  selectedRegion: null,
  tripService: service('trip'),
  @not('selectedRegion') selectedRegionNotChosen: true,

  init() {
    this._super(...arguments);
    // THis modal is not currently used as we dont have add ons
    this.set('countries', this.get('store').peekAll('country').sortBy('name'));
    this.set('regions', this.get('store').peekAll('region').sortBy('name'));
  },

  @computed('regions', 'selectedCountry')
  filteredRegions(regions, selectedCountry) {
    if (!selectedCountry) {
      return regions.sortBy('name');
    }

    // Just getting first country regions still
    return this.get('countries').findBy('name', selectedCountry.get('name')).get('regions').sortBy('name');
  },

  actions: {
    selectCountry(country) {
      this.set('selectedCountry', country);
      this.set('selectedRegion', null);
    },
    selectRegion(region) {
      this.set('selectedRegion', region);
      this.set('selectedCountry', region.get('country'));
    },
    addStageToTrip() {
      this.tripService.createStage({
        model: this.selectedRegion,
        type: 'region'
      });
      this.set('tripService.showChoosePlaceModal', false);
    }
  }

});
