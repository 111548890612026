import Helper from '@ember/component/helper';
import {
  nextNonTransferStage
} from 'b5b/utils';
import {
  observer
} from '@ember/object';

export default Helper.extend({

  arrayLengthObserver: observer('_stages.@each.entity', function() {
    this.recompute();
  }),

  compute([stages, stageIndex]) {
    this.set('_stages', stages);
    return nextNonTransferStage(stages, stageIndex);
  }
});
