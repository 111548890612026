import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';


export default DS.Model.extend({

  month: DS.belongsTo('month', {
    async: false
  }),

  overview: DS.attr(),
  bestBeachesDestinationList: DS.attr(),
  bestTimeDestinationList: DS.attr(),
  highlightDestinationList: DS.attr(),

  monthHighlights: DS.hasMany('monthHighlight'),

  @computed('bestBeachesDestinationList')
  bestBeachDestinations(bestBeachesDestinationList) {
    if (bestBeachesDestinationList) {
      return bestBeachesDestinationList.map((entry) => {
        entry.destination = this.get('store').peekRecord(entry.type, entry.id);
        return entry
      })

    }
  },

  @computed('bestTimeDestinationList')
  bestTimeDestinations(bestTimeDestinationList) {
    if (bestTimeDestinationList) {
      return bestTimeDestinationList.map((entry) => {
        entry.destination = this.get('store').peekRecord(entry.type, entry.id);
        return entry
      })
    }
  },

  @computed('highlightDestinationList')
  highlightDestinations(highlightDestinationList) {
    if (highlightDestinationList) {
      return highlightDestinationList.map((entry) => {
        entry.destination = this.get('store').peekRecord(entry.type, entry.id);
        return entry
      })
    }
  }
});