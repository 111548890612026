import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  transferDescriptionParagraph
} from 'b5b/utils';

export function transferDescriptionParagraphHelper(params, namedArgs) {
  return transferDescriptionParagraph(params, namedArgs);
}

export default buildHelper(transferDescriptionParagraphHelper);
