define("ember-leaflet/components/tooltip-layer", ["exports", "ember-leaflet/components/div-overlay-layer"], function (_exports, _divOverlayLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _divOverlayLayer.default.extend({
    leafletOptions: Object.freeze(['direction', 'permanent', 'sticky', 'interactive', 'opacity']),
    // if this tooltip is permanent, we need to render the content immediately
    shouldRender: Ember.computed.reads('permanent'),
    createLayer: function createLayer() {
      return this.L.tooltip(this.get('options')).setContent(this.get('destinationElement'));
    },
    didCreateLayer: function didCreateLayer() {
      this._addPopupListeners();
    },
    addToContainer: function addToContainer() {
      this.get('parentComponent')._layer.bindTooltip(this._layer);
    },
    removeFromContainer: function removeFromContainer() {
      this.get('parentComponent')._layer.unbindTooltip();
    },
    _onLayerRemove: function _onLayerRemove(_ref) {
      var layer = _ref.layer;
      if (layer === this._layer) {
        this._removePopupListeners();
        this.set('shouldRender', false);
      }
    },
    _addPopupListeners: function _addPopupListeners() {
      var _this = this;
      // we need to hijack the `onAdd` method because we need to
      // render the template *before* the popup is opened.
      // This way, the popup will set its dimensions according to the rendered DOM.
      var oldOnAdd = this._layer.onAdd;
      this._layer.onAdd = function (map) {
        // we need to user `layerremove` event becase it's the only one that fires
        // *after* the popup was completely removed from the map
        map.addEventListener('layerremove', _this._onLayerRemove, _this);
        // trigger _initLayout manually, otherwise Tooltip doesn't have the container set
        // to calculate initial position
        if (!_this._layer._container) {
          _this._layer._initLayout();
        }
        // this will make in-element render to the document fragment
        _this.set('shouldRender', true);
        // ember-wormhole will render on the afterRender queue, so we need to render after that
        Ember.run.next(function () {
          if (_this.get('shouldRender')) {
            oldOnAdd.call(_this._layer, map);
          }
        });
      };
    },
    _removePopupListeners: function _removePopupListeners() {
      var parentComponent = this.get('parentComponent');
      parentComponent._layer._map.removeEventListener('layerremove', this._onLayerRemove, this);
    }
  });
});