import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import {
  trackEvent,
} from 'b5b/utils';

export default Component.extend({

  classNameBindings: [':entity-nav', 'viewingMapListing', 'ui.displayOn', 'entityNavStuckToTop:stuck-to-top'],

  ui: service(),
  router: service(),
  screen: service(),
  tripService: service('trip'),

  canScrollLeft: false,
  canScrollRight: true,

  scrolled: false,

  throttleTimer: null,

  @alias('ui.viewingMapListing') viewingMapListing: false,
  @alias('ui.entityNavStuckToTop') entityNavStuckToTop: false,

  @computed('router.currentRouteName', 'screen.isMobileOnly', 'ui.entityNavData.mobileMenu')
  showEntityNavMobileMenu(currentRouteName, isMobileOnly, mobileMenu) {
    let showForThisRoute = false;
    if (!mobileMenu || !mobileMenu.visibleForRoutes || mobileMenu.visibleForRoutes && currentRouteName.includes(mobileMenu.visibleForRoutes)) {
      showForThisRoute = true;
    }
    return isMobileOnly && mobileMenu && showForThisRoute;
  },

  didInsertElement() {
    this._super(...arguments);

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    this.set('viewport', this.element.getElementsByClassName('viewport')[0]);
    this.set('boundScrollHandler', this.onScroll.bind(this));
    $(this.viewport).on('scroll', this.boundScrollHandler);

    this.centerActive();
  },

  centerActive() {
    if (!this.$('.viewport .active').offset()) {
      return;
    }

    let viewport = this.$('.viewport'),
      viewportWidth = viewport.outerWidth(),
      activeItem = this.$('.viewport .active'),
      activeCurrentPosition = activeItem.offset().left,
      activeWidth = activeItem.outerWidth(),
      activeCenter = activeCurrentPosition - (viewportWidth / 2) + (activeWidth / 2);

    viewport.scrollLeft(activeCenter);
  },

  didRender() {
    if (!this.get('scrolled')) {
      this.set('canScrollRight', this.viewport.scrollWidth !== this.viewport.offsetWidth && (this.viewport.scrollWidth - this.viewport.offsetWidth) !== this.viewport.scrollLeft);
    } else {
      this.get('scrolled', false)
    }
  },

  onScroll(e) {
    this.throttleTimer = run.throttle(this, this.updateScrollState, e, 150, false);
  },

  updateScrollState() {
    this.setProperties({
      canScrollLeft: this.viewport.scrollLeft > 10,
      canScrollRight: this.viewport.scrollWidth - this.viewport.offsetWidth !== this.viewport.scrollLeft,
      scrolled: true
    });
  },

  willDestroyElement() {
    run.cancel(this.throttleTimer);
    $(this.viewport).off('scroll', this.boundScrollHandler);
    this.set('ui.entityNavData', null);
  },

  actions: {
    scrollRight() {
      $('.viewport').animate({
        scrollLeft: '-=153'
      }, 300);
    },

    scrollLeft() {
      $('.viewport').animate({
        scrollLeft: '+=153'
      }, 300);
    },

    linkAction(actionName, actionProperty) {
      this.set(actionName, actionProperty);
    },
    trackClickEntityNav(link) {
      trackEvent('entity-nav-click',
      {
        entityType: this.get('ui.entityNavData.entity._internalModel.modelName'),
        linkName: link.name
      })
    }
  }

});
