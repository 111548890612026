
import Route from '@ember/routing/route';
import EntitiesRoutesRouteMixin from 'b5b/mixins/entities/routes/route';

export default Route.extend(EntitiesRoutesRouteMixin, {

  headTags() {

    const title = `My trips | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }
  },


});


// import Route from '@ember/routing/route';
// import PrettyQuery from 'b5b/mixins/pretty-query';

// export default Route.extend(PrettyQuery, {

//   ui: service(),
//   session: service(),
//   xplorer: service('components/x-plorer'),


//   queryParams: {

//     searchValue: {
//       replace: true
//     },
//     page: {
//       replace: true
//     },
//     sort: {}
//   },

//   setupController(controller) {
//     this._super(...arguments);
//     this.xplorer.setupController(controller);
//     controller.setProperties({
//       firstTimeLoad: false,
//       buster: true
//     });
//     if (!controller.allAccountTrips) {
//       controller.set('user_id', this.get('session.currentUser.id'));
//     }
//     this.xplorer.loadRecords('tripSummaries');
//   },

//   resetController(controller, isExiting) {
//     this._super(...arguments);
//     this.xplorer.setProperties({
//       page: 1,
//       bookingState: 'all'
//     });

//     if (isExiting) {
//       this.xplorer.teardownController();
//       // this.get('xplorer').set('buster', null);
//       controller.setProperties({
//         searchValue: '',
//         page: 1,
//         firstTimeLoad: true,
//         userOnly: false,
//         buster: false
//       });
//     }
//   }

// });
