import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

import {
  trackEvent
} from 'b5b/utils';


export default Route.extend(tripSubRoute, {

  tripService: service('trip'),
  router: service(),

  setupController(controller) {

    // NOTE IS IMPORTANT THAT WE DO NOT USE THE MODEL FROM MODEL HOOK.. the trip is injected into the controller
    let trip = this.get('tripService.currentTrip');
    let tripUrl = `${window.location.origin}${this.router.urlFor('trip', trip)}`
    controller.set('tripUrl', tripUrl);

    trackEvent('trip:enquiry-success');
  }

});
