import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  equal
} from 'ember-decorators/object/computed';

import RecognizerMixin from 'ember-gestures/mixins/recognizers';

export default Component.extend(RecognizerMixin, {
  classNameBindings: [':peek-slider', ':clearfix', 'grey', 'showControlsAt'],

  router: service(),

  slides: null,

  currentIndex: 0, // starts at 0
  totalSlides: 0, //
  slideWidth:0,
  viewportWidth: 0,

  seeAllLink: null,
  title: null,
  subtitle: null,

  onClick: null,

  showControlsAt: null,
  visibleSlides: null,

  canSwipe: true, // beware of nested gestures
  recognizers: '',

  @equal('currentIndex', 0) atFirst: false,

  init() {
    this._super(...arguments);

    if (this.canSwipe) {
      this.set('recognizers', 'swipe');

      // This is done in the mixin, but we need to redo it
      // otherwise its not properly setup
      let recognizers = this.get('recognizers');
      if (recognizers) {
        this.set('recognizers', this.get('-gestures').retrieve(recognizers.split(' ')));
      }
    }
  },

  didInsertElement() {
    this._super(...arguments);

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    this.set('boundScrollHandler', this.setPosition.bind(this));
    this.$('.viewport').on('scroll', this.boundScrollHandler);
  },

  didInsertSlide(slideId = Math.round(Math.random() * 10000)) {
    if (!this.get('slides')) {
      this.set('slides', []);
    }

    this.get('slides').pushObject(slideId);
    this.setSliderDimensions();
  },

  setSliderDimensions() {
    if (!this.$('.slide')[0]) {
      return console.error('You need to add the class ".slide" to the card.')
    }

    if (!this.get('slides') && this.$('.slide')[0]) {
      return;
    }

    this.setProperties({
      totalSlides: this.get('slides.length'),
      viewportWidth: $('#' + this.elementId + ' > .wrap > .viewport')[0].offsetWidth,
      slideWidth: $('#' + this.elementId + ' > .wrap > .viewport > .slide')[0].offsetWidth
    });
  },

  willDestroyElement() {
    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }
    this.set('slides', null);
    this.$('.viewport').off('scroll', this.boundScrollHandler);
  },

  setPosition(e) {
    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    this.setProperties({
      currentIndex: Math.round(e.currentTarget.scrollLeft / this.get('slideWidth'))
    });
  },

  @computed('totalSlides', 'seeAllLink', 'inModal')
  seeAllVisible(totalSlides, seeAllLink, inModal) {
    if (inModal) {
      return false;
    }
    return totalSlides >= 5 && seeAllLink;
  },

  @computed('currentIndex', 'totalSlides', 'viewportWidth', 'slideWidth')
  atLast(currentIndex, totalSlides, viewportWidth, slideWidth) {
    return currentIndex === totalSlides - Math.round(viewportWidth / slideWidth);
  },

  @computed('totalSlides', 'viewportWidth', 'slideWidth')
  allSlidesVisible(totalSlides, viewportWidth, slideWidth) {
    return totalSlides*slideWidth <= viewportWidth;
  },

  @computed('screen.isMobileOnly', 'screen.isTabletOnly', 'totalSlides', 'inModal', 'showControlsAt', 'allSlidesVisible', 'visibleSlides')
  showControls(isMobile, isTablet, totalSlides, inModal, showControlsAt, allSlidesVisible,  visibleSlides) {

    if (allSlidesVisible){
      return false;
    }

    // we're assuming the min total slides here, eg modal routes
    if (showControlsAt && totalSlides > 2) {
      return true;
    }

    let localMinSlides = visibleSlides;
    if (!visibleSlides) {
      localMinSlides = isTablet ? 2 : (inModal ? 2 : 3);
    }

    return isMobile ? false : totalSlides > localMinSlides;
  },

  @computed('seeAllLink.title')
  seeAllButtonTitle(title) {
    return title ? title : "Show all";
  },

  click() {
    if (this.get('onClick')) {
      this.get('onClick')();
    }
  },

  actions: {
    block() {
      return;
    },

    prev() {
      if (!this.get('currentIndex')) {
        return false;
      }

      this.$(' > .wrap > .viewport > .slide:eq(' + (this.get('currentIndex') - 1) + ')').velocity('scroll', {
        container: $('#' + this.elementId + ' > .wrap > .viewport')[0],
        axis: 'x',
        duration: 200
      });
    },

    next() {
      if (this.get('atLast')) {
        return false;
      }

      this.$(' > .wrap > .viewport > .slide:eq(' + (this.get('currentIndex') + 1) + ')').velocity('scroll', {
        container: $('#' + this.elementId + ' > .wrap > .viewport')[0],
        axis: 'x',
        duration: 200
      });
    }
  }

});
