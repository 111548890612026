import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {

  ui: service(),
  session: service(),

  queryParams: {

  },

  beforeModel() {
    return this.get('session.currentUserPromise');
  },

  setupController(controller) {
    this._super(...arguments);

    controller.setProperties({
    });

  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      controller.setProperties({
        // searchValue: '',
        // page: 1,
        // firstTimeLoad: true,
        // userOnly: false,
        // buster: false
      });
    }
  }

});
