import Service, {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

import {
  trackEvent
} from 'b5b/utils';

export default Service.extend({

  // Entity modals are avaialble throughout the application
  // The modalHistory array keeps track of the modals that have been opened
  // modalPermissions has an array of permitted actions

  router: service(),
  messageBus: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),

  closeAllEntityModals: null,
  onModal: null,

  modalHistory: [],
  modalPermissions: [],

  @alias('xplorer.selectedContinentNames') selectedContinentNames: null,
  @alias('xplorer.selectedCountryNames') selectedCountryNames: null,
  @alias('xplorer.selectedRegionNames') selectedRegionNames: null,
  @alias('xplorer.selectedExperienceNames') selectedExperienceNames: null,
  @alias('xplorer.selectedMonthNames') selectedMonthNames: null,
  @alias('modalHistory.lastObject') currentModalConfig: null,

  @computed('modalPermissions.[]')
  hasPermissionAddLodge(modalPermissions) {
    return modalPermissions.includes('perm-add-lodge');
  },

  @computed('modalPermissions.[]')
  hasPermissionAddRegion(modalPermissions) {
    return modalPermissions.includes('perm-add-region');
  },

  @computed('modalPermissions.[]')
  hasPermissionSaveTemplate(modalPermissions) {
    return modalPermissions.includes('perm-save-template');
  },

  @computed('modalPermissions.[]')
  hasPermissionCreateStage(modalPermissions) {
    return modalPermissions.includes('perm-create-stage');
  },

  addModalPermission(permission) {
    if (!this.get('modalPermissions').includes(permission)) {
      this.get('modalPermissions').pushObject(permission)
    }
  },

  removeModalPermission(permission) {
    if (!this.get('modalPermissions').includes(permission)) {
      this.get('modalPermissions').removeObject(permission)
    }
  },

  openAddOnModal(options = { /* model=stageFilter */ }) {
    if (options.model.isSingleEntity) {
      this.openModal({
        model: options.model.filterRegions.firstObject
      });
    } else {
      this.openModal(options);
    }
  },

  openStageModal(options = { /* stage=stage */ }) {

    let model;
    let stage = options.stage;
    if (stage.get('lodge')) {
      model = stage.get('lodge');
    } else if (stage.get('addOnStateActive')) {
      model = stage.get('addOnFilter');
    } else if (stage.get('region')) {
      model = stage.get('region');
    }

    return this.openModal({
      model
    });
  },

  openModal(options = { /*model,  modalType(optional) */ }) {
    if (!options.modalType) {
      options.modalType = options.model.get('_internalModel.modelName');
    }
    console.log(options)
    if (options.modalType == 'schedule' && this.get('tripService.currentTrip.isLayoutDayByDay')) {
      options.widePortal=true
    }
    $('.t-modal.entity').scrollTop(0);
    this.get('modalHistory').pushObject(options);
    this.setProperties({
      onModal: true,
      closeAllEntityModals: options.closeAllEntityModals,
      editInModal: options.editInModal
    });
    trackEvent('entity-modal:' + options.modalType + ':open', {routeName: this.router.currentRouteName});
  },

  closeModal() {
    this.setProperties({
      onModal: false,
      editInModal: false
    });
    if (this.closeAllEntityModals) {
      this.closeAllEntityModals();
    }
    this.set('closeAllEntityModals', null);
    this.set('modalHistory', []);
    this.set('modalPermissions', []);
  },

  goBackInModalHistory() {
    if (this.get('modalHistory.length') === 1) {
      this.closeModal()
    } else {
      this.get('modalHistory').popObject();
    }
  },

  actions: {
    openModal(options) {
      this.openModal(options)
    },

    closeModal() {
      this.closeModal();
    },
    goBackInModalHistory() {
      this.goBackInModalHistory();
    },

    openAddonModal(options) {
      this.openAddonModal(options);
    }
  }

});
