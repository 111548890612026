import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  memberAction
} from 'ember-api-actions';

export default DS.Model.extend({


  name: DS.attr(),
  desciption: DS.attr(),
  path: DS.attr(),
  documentType: DS.attr(),
  enabled: DS.attr(),
    
  user: DS.belongsTo('user', {
    inverse: null,
    async: false
  }),  
  trip: DS.belongsTo('trip', {
    inverse: null,
    async: false
  }),   
  
  presignedUrlToView: memberAction({
    path: 'presigned_url_to_view'
  }),
});
