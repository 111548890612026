import Component from '@ember/component';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['wizard-prompt'],

  didInsertElement() {
    // trackEvent('modal:wizard:show');
  },

  actions: {
    close() {
      // trackEvent('modal:wizard:close:overlay');
      if (this.onClose) {
        this.onClose();
      }
    }
  }
});
