import Component from '@ember/component';
import {
  alias,
  or
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['stage-details-row-block-item-content'],

  entityModals: service(),

  @alias('entityModals.onModal') onModal: null,  

  actions: {
    openModal(options) {
      if (this.get('onModal')) {
        this.get('entityModals').openModal(options);
      }
    }
  }
});
