import Service, {
  inject as service
} from '@ember/service';

export default Service.extend({

  /*

    R&D
    ====

    Possible events:
    user transitions forward/backward through app elements and links
    user transitions forward/backward via browser buttons

    on listing pages params should be included in history, ie, you should browse through them incrementally
    Edit: This has its own complications so we put it on hold for now

    Sometimes a user enters a 'suspended' mode, this entails:
    - still being able to browse through the params using browser back/forward
    - being able to exit the mode and returning to original point of entry using an explicit action/trigger

    For suspended mode:
    We need a clear point of entry, thats simple, we can just use one of our choose-a-lodge actions, where we activate the mode and counter
    Then we also need a clear point of exit, this needs to happen on the route in most cases where we exit the mode, this is a backup
    We can then also have a more explicit exit action which leaves the mode and resets the values
    Edit: We now only explicitly exit suspensions via user actions, but since we dont teardown suspensions its no longer critical

    To further expand on the above, we actually have a 'cancel' and 'exit' mode
    - 'cancel' for when you either leave the suspension area, or even complete the action and return to the original point.
    - 'exit' for when you explcitly want to just go back to the original point
    Edit: We've since dropped cancel completely to avoid sprinkling the codebase with cancels all over

    For normal transitioning:
    We need to set all the relevant QP's to not be replace: true so that one can browser through them.
    Back and forward should behave as normal, no overriding
    Currently there is a bit of a lag on this, especially when visting more complex/listing pages
    Edit: See note on params above

    Notes:
    One tricky part is pagination and using reloadModel
    In normal cases we want to reloadModel when page: changes, via pagination or using browser nav
    However, we also reset the page to 1 when changing most filters, in which case we dont want it to reload model
    Edit: See note on params above

    ----------

    So what do we know so far:

    We can create a history service ('history' is already existing, owned by Ember) to manage some functionality and state
    We can reopen the HistoryLocation class to trigger mirror events on the history service
    These include:
      - onUpdateUrl / onpopstate
      - replaceUrl
      - pushState

    Currently most params are set to replace: true, which means that they are not navigatable.
    Setting them replace: false makes them navigatable in the url but doesnt trigger any state changes
    Setting them to reloadModel: true causings a retrigger of the model hooks, but we currently bypass that so need to trigger a messagebus event
    or so to update all the different entities.
    Doing it in its simplest form causes issues however as they get out of sync, showing some of the pills as unselected when they are not and vice-versa
    Wrapping the trigger in run.next solves that synchronocity but raises another issue or two

    One specific param which causes issues using this approach is the page: param - in normal cases use we also want it to behave as the others,
    but since we reset it when loading other entities we dont want it to trigger a reload then.
    Also when using the browser nav we want it to behave like normal. A little confusing with this overlap.

    Ive setup a provisional suspension mode, which we can use to exit certain route patterns, which counts the history and simply reverts back
    the same amount. This is simple and works well off the bat, but the above issue is a separate issue to be addressed.

    Ive also noticed that sometimes the values passed into serializeQueryParam in pretty-query isnt the array version of the params, yet
    the type param is set to array, so not sure what happens there

    Note that the onpopstate binding in transition-actions could potentially interfere

    Sometimes one runs into a state when on clicking back the url changes but the current page just continues loading, and then after transitions back
    Possibly related to this known issue: https://github.com/emberjs/ember.js/issues/5210
    Edit: Seems related to my local setup and add blockers - the browser needs to complete the asset loading

    Leaving the console logs for now while we make sure everything works as expected
    Edit: Cleaned

    When making changes to this service, please run through these tests (yes we need a test suite)

  */



  /*

    Tests:
    ======

    Standard
    --------
    trips > trip editor > back
    trips > trip editor > add lodge > back > back
    trips > trip editor > add lodge > + add > back
    trips > trip editor > add lodge > + add > add lodge > back > back

    trip editor > back
    trip editor > add lodge > back > back
    trip editor > add lodge > + add > back
    trip editor > add lodge > + add > add lodge > back > back


    Browser nav
    -----------
    trips > trip editor > choose lodge > browser back > back
    trips > trip editor > choose lodge > browser back > browser forward > back > back

    trips > trip editor > add lodge > browser back > browser back
    trips > trip editor > add lodge > + add > browser back
    trips > trip editor > add lodge > + add > add lodge > browser back > browser back


    Overriding suspensions
    ----------------------
    trips > trip editor > trip view (new suspension) > edit trip > choose a lodge > back > back


    Special scenarios
    ----------------------
    new trip in nav > custom route > add a region > click back > dont save trip
    trips > trip editor > trip view > trip editor > add lodge > + add > trip editor > browser back > don't save
  */



  /*
    How this works
    ==============

    Note: This might have changed slighlty since.

    1. On init we check if the browser has history, and if so, push that to our local cache of uuids

    2. In the router.js we bind into the browser history events, pushState and onUpdateUrl
      a. pushState is generally triggered when you transition to a route explictly
      b. onUpdateUrl is triggered when using the browser nav
      c. we also check if you are starting in a suspension route and if so enter it.

    3. When triggering a pushState, the following events occur:
      a. we push the uuid for the history entry to our local cache
      b. we set that uuid as the current/active uuid
      c. we check if there are any suspensions, and if so:
        - we incremenent each suspensions transition count by 1
      d. we then check if we are entering any permanent suspensions (like trip editor)
        - if so, enter a new suspension, setting all the values
      e. we save a reference to the previousRouteName so that we can check that later

    4. When entering a suspension the following occurs:
      a. we run through the existing suspensions and check if its an existing one
      b. if we are working with an existing one:
        - we set it as then currentSuspension
        - if the suspension has nested suspensions and the previousRouteName is one of them
          -- dont reset transition count for suspension
          -- update
          -- return
        - otherwise we just reset the transition count to 0 (we are entering a suspension like the first time)
        - return
      c. create a new suspension
        - set it as the currentSuspension
        - push it to the suspensions array

    5. When exiting a suspension do the following:
      a. if there are no active suspensions, just transition to the defined default or fallback
      b. if you are exiting the currentSuspension
        - if the currentSuspension has more than 0 transitions
          -- transition back the same amount of transitions
          -- update all other suspensions with the same amount
          -- remove the currentSuspension from the suspensions
        - else transition to the defined default
      c. else just go back 1 in history (so that the user doesnt get stuck)

    6. When transitioning to default:
      a. check if we have a defined default
        - transition to default
      b. otherwise transition to homepage

  */


  router: service(),

  init() {
    this._super(...arguments);

    /*
      If we start with browser history, add that to our cache immediately
    */
    if (window.history.state && window.history.state.uuid) {
      this.addCacheUuid(window.history.state.uuid)
    }
  },


  permanentSuspensionRoutes: {
    'trip.index.editor': {
      name: 'trip-editor',
      nestedSuspensionRoutes: ['lodges', 'regions']
    },
    'wizard.index': {
      name: 'wizard',
      nestedSuspensionRoutes: ['wizard.success', 'trip.index.editor']
    },
    'ask': {
      name: 'ask'
    }
  },

  /*
    This is what we transition to if no history exists, eg. loaded trip.index.editor directly
  */
  suspensionDefaults: {
    'trip-editor': {
      route: 'trips'
    },
    'ask': {
      route: 'index'
    },
    'wizard.success': {
      route: 'index'
    }
  },

  previousRouteName: null, // to check where we come from

  suspensions: [], // collection of all suspension states
  currentSuspension: null, // if we dont teardown suspensions we cant just default to last one, so we now set this explicitly

  // exitingSuspension: false,

  uuidCache: [], // history - array order is NB
  currentUuid: null, // current history item

  addCacheUuid(uuid) {
    this.get('uuidCache').push(uuid);
    this.set('currentUuid', uuid);
  },

  onUpdateURL(args) {
    /*
      We keep track of the current history item (currentUuid), as well as all other items (uuidCache)
      On browser back/forward, we check if the intended new one is before or after the current one, this gives us an indication
      of the direction we are moving in

      NOTE: If the user reloads the page, the browser has history, but we dont, so in the case that they use the browser nav first
      we need to build up our history from it. Thus there is a brief period of disconnect until they line up where we cant do the logic below
    */


    if (this.get('uuidCache').indexOf(args.state.uuid) === -1) {

      /*
        Adding history items here is risky because we dont know if its back or forward, so we cant depend on array order
        Need to figure out a way to differentiate between browser back and forward if we want to do this, currently theres no clear solution,
        thus the implementation of this uuidCache solution anyway

        We're assuming that were moving back here, its the most likely scenario, in which case we also need to update our transition counts
      */
      this.updateAllSuspensionTransitions(-1);

    } else {


      if (this.get('uuidCache').indexOf(args.state.uuid) < this.get('uuidCache').indexOf(this.get('currentUuid'))) {
        /* going back */
        if (!this.get('exitingSuspension')) {
          this.updateAllSuspensionTransitions(-1);
        }

      } else {
        /* going forward */
        this.updateAllSuspensionTransitions(1);
      }

      this.set('currentUuid', args.state.uuid);

      /* reset */
      this.set('exitingSuspension', false);
    }
  },


  onPushState(uuid) {
    /*
      The order in here is important
    */

    if (uuid) {
      this.addCacheUuid(uuid);
    }

    this.checkPermanentSuspensions();

    if (!Ember.isEmpty(this.get('suspensions'))) {
      /*
        I attempted a solution here where we limit the transition counting for some suspensions, but we can't do that
        The history continues pushing and popping so we need to just run with that, ie, if you transition between two routes
        all the time, just keep counting, dont try to pause the parent route's count as the history includes the transitions
        to the other route. Obvious, but also not.
      */

      this.updateAllSuspensionTransitions(1, 'onPushState');
    }

    /* The route we just came from */
    this.set('previousRouteName', this.router.currentRouteName);
  },


  /*
    Start of all suspensions
  */
  enterSuspension(options = { /* name, nestedSuspensionRoutes, cachedPreviousRouteName */ }) {
    /*
      Here we can either track explicitly named suspension modes, or handle them more dynamically
      The one has the advantage of being easier to manage but what about overlap?
      The other is harder to manage in that is anonymous

      Okay, we need to check if there are any existing suspensions, as soon as you enter another one, you only work with the last one
    */

    let activeSuspension = false;

    this.get('suspensions').forEach((item) => {
      if (item.name === options.name) {
        activeSuspension = item;
        return;
      }
    });

    /*
      Here were saying that if you transition to another route and come back into an existing suspension, then replace it so that back
      takes you this new origin route.

      Sometimes though we dont want that to happen, like when coming back from a nested suspension, like from lodges/regions to trip editor, so
      we set a list of optional nested suspensions which bypasses the reset
    */

    if (activeSuspension) {
      this.set('currentSuspension', activeSuspension);

      if (options.nestedSuspensionRoutes && options.nestedSuspensionRoutes.includes(options.cachedPreviousRouteName)) {

        // We're returning from a nested suspension (eg change-lodge) so we dont reset transitions
        return;
      }

      /* Reset suspension when re-entering suspension eg, trips for trip view */
      activeSuspension.transitions = 0;

      /* Do we need to do anything here perhaps, other than returning? */
      return;
    }

    let newSuspension = {
      name: options.name,
      transitions: 0
    };

    this.set('currentSuspension', newSuspension);
    this.get('suspensions').pushObject(newSuspension);
  },

  /*
    To revert back to start of suspension
  */
  exitSuspension(name) {
    if (!this.get('suspensions.length')) {
      /*
        Exiting but no history stored, like when directly loading suspension route,
        we need to fallback to defaults
      */
      return this.transitionToDefault(name);

    }

    let activeSuspension = false;

    this.get('suspensions').forEach((item) => {
      if (item.name === name) {
        activeSuspension = item;
        return;
      }
    });


    if (activeSuspension) {
      if (activeSuspension.transitions > 0) {

        /*
          We might need smarter logic here to cater for scenarios where we have prompts on exit modals,
          for now it works on trip editor though
        */
        let backTransitionCount = activeSuspension.transitions * -1;

        /* When we do this we need to update all suspension transition counts, otherwise they get out of sync */
        this.updateAllSuspensionTransitions(backTransitionCount, 'exitingSuspension');
        this.set('exitingSuspension', true);

        window.history.go(backTransitionCount);

      } else {
        /* If we started on a suspension route we need to also fallback to default */
        return this.transitionToDefault(name);
      }

    } else {
      /*
        Would be good if we can be sure this will never happen

        This happens if you quickly click back hastily without waiting for transitions to complete, so could just ignore it potentially
        in order to enfore normal navigation.

        I think we need a solution here otherwise the user can get stuck in a place where back doesnt respond.
        For now we just do a manual history back transition

        We might need a smarter solution here where we check if there are any parent suspensions, and then give them preference, ie
        exit them first and then go back
      */
      return window.history.go(-1);
    }
  },

  checkPermanentSuspensions() {
    if (this.get('permanentSuspensionRoutes')[this.router.currentRouteName]) {

      let suspension = this.get('permanentSuspensionRoutes')[this.router.currentRouteName];

      this.enterSuspension({
        name: suspension.name,
        nestedSuspensionRoutes: suspension.nestedSuspensionRoutes,
        cachedPreviousRouteName: this.previousRouteName
      });
    }
  },

  updateAllSuspensionTransitions(count) {
    this.get('suspensions').forEach((item) => {
      item.transitions += count
    });
  },

  transitionToDefault(name) {
    if (this.get('suspensionDefaults')[name]) {
      return this.get('router').transitionTo(this.get('suspensionDefaults')[name].route);
    } else {
      return this.get('router').transitionTo('index');
    }
  }

});
