import staticStageComponent from 'b5b/components/trip-stages/static/stage/component';
import {
  inject as service
} from '@ember/service';
import {
  mapBy,
  alias,
  reads
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import { initialize } from '../../../../../initializers/inject-filter-options';

export default staticStageComponent.extend({
  classNames: ['what-to-do'],

  store: service(),
  templateManager: service(),
  entityModals: service(),
  useAutoSchedule: false,

  // didReceiveAttrs() {
  //   this._super(...arguments);



  // },

  // @computed('experienceItemsSelected.[].experience', 'recommendedExperiences.[]')
  // remainingExperiences(experienceItemsSelected, recommendedExperiences) {
  //   let experiencesSelected = []
  //   experienceItemsSelected.forEach((experienceItem)=> {
  //     experiencesSelected.pushObject(experienceItem.belongsTo('experience').value())
  //   })
  //   let remainingExperiences = [];
  //   recommendedExperiences.forEach((experience)=> {
  //     console.log(experience.name, experiencesSelected.includes(experience))
  //     if (!experiencesSelected.includes(experience)) {
  //       remainingExperiences.pushObject(experience)
  //     }
  //   })
  //   return remainingExperiences;
  // }



});
