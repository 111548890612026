import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    startDate: {
      serialize: false
    },
    filterJoins: {
      serialize: 'records'
    },
    customTransfer: {
      serialize: 'records'
    },
    customSchedule: {
      serialize: 'records'
    },
    lodgeOptions: {
      serialize: 'records'
    }
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards
    json.filter_joins_attributes = json.filter_joins;
    delete json.filter_joins;

    json.transfer_options_attributes = json.transfer_options;
    delete json.transfer_options;

    json.custom_transfer_attributes = json.custom_transfer;
    delete json.custom_transfer;

    json.custom_schedule_attributes = json.custom_schedule;
    delete json.custom_schedule;

    json.lodge_options_attributes = json.lodge_options;
    delete json.lodge_options;

    return json;
  }


});
