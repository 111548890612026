import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('country');
  },

  afterModel(model) {
    if (this.isFastBoot) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('countryInfo'));
      });
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('country:view');
    controller.set('country', model);
  },

  actions: {
    backToCountryPage() {
      this.transitionTo('country', this.get('controller.country'))
    }
  }


});
