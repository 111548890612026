import {
  inject as service
} from '@ember/service';
import {
  set
} from '@ember/object';
import {
  A
} from '@ember/array';
import Component from '@ember/component';
import {
  isEmpty,
  isPresent
} from '@ember/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  run
} from '@ember/runloop';

import RecognizerMixin from 'ember-gestures/mixins/recognizers';

export default Component.extend(RecognizerMixin, {
  classNameBindings: [':x-gallery', 'coverStyle'],

  canSwipe: true,
  showImageCaptions: false,
  forceCaption: false,

  // Cant use computed properties for these recognizers anymore

  scroll: service(),
  fastboot: service(),
  config: service(),

  onToggle: null, // closure

  init() {
    this._super(...arguments);

    this.set('_loadingImages', []);
    this.set('_allImages', []);

    if (this.canSwipe) {
      // This is done in the mixin, but we need to redo it
      // otherwise its not properly setup
      let recognizers = this.get('_recognizers');
      if (recognizers) {
        this.set('recognizers', this.get('-gestures').retrieve(recognizers.split(' ')));
      }
    }
  },

  /*
    To show images inline, set this loadImages closure along with conditionally setting the images, eg:
    images=(if (and (not lightboxEnabled) loadImages) lodge.lodgeInfo.metaLodgeImages)
  */
  loadImages: null, // closure
  _haveLoadedImages: false,

  coverImage: null,
  coverDescription: null,
  coverStyle: null,

  images: null,
  imagesLength: null,
  _cachedImagesLength: null,
  _actualImagesLength: null,

  urlPath: 'originalUrl',
  altPath: 'description',

  swipeLeft(e) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.get('canSwipe')) {
      return;
    }
    this._next();

  },

  swipeRight(e) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.get('canSwipe')) {
      return;
    }
    this._prev();
  },

  didInsertElement() {
    this._super(...arguments);
    this._setupImages();
  },

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('images')){
      this._initSetupImages();
    }
  },

  _initSetupImages() {
    if (this.get('images').then || this.get('images.length') && this.get('images.length') !== this.get('_actualImagesLength')) {
      this.set('_actualImagesLength', this.get('images.length'));
      this._setupImages();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this._loadAllTimer);
  },

  /*
    Private
  */

  _compositeImages(compArray){
    // imagesArray = this.get('images');
    compArray = compArray.concat(this.get('images')).uniqBy('originalUrl');

    if (isPresent(this.injectedImages)) {
      this.injectedImages.forEach((image) => {

        compArray.pushObject({
          dimensions: null,
          originalUrl: image,
          coverStyle: null,
          isInjected: true
        })

      });
    }

    this.set('_allImages', compArray);

    // force load all images
    if (this._haveLoadedImages) {
      this.set('_insertIndex', this.get('_allImages.length') - 1);
    }

    if (this.get('startImageUrl') && this.get('startImageUrl') !== true) {
      let startImage = compArray.findBy('originalUrl', this.startImageUrl);
      this.set('startIndex', compArray.indexOf(startImage));
    }

    if (this.get('startIndex') && this.get('startIndex') !== true) {
      this.set('_activeIndex', this.get('startIndex'));
      this.set('_insertIndex', this.get('startIndex'));
    }

  },

  _setupImages() {
    let coverUrl = this.get('coverUrl'),
      coverDescription = this.get('coverDescription'),
      coverStyle = this.get('coverStyle');

    this.set('_cachedCoverUrl', coverUrl);
    this.set('_cachedCoverStyle', coverStyle);

    var imagesArray = [],
      compArray = [];

    if (coverUrl) {
      // if cover not inserted yet
      if (isEmpty(imagesArray) && coverUrl || imagesArray[0] && coverUrl !== imagesArray[0].originalUrl) {
        compArray = [{
          description: coverDescription,
          dimensions: null,
          originalUrl: coverUrl,
          coverStyle: coverStyle
        }]
      }
    }

    if (this.get('images')) {
      if (this.get('images').then){
        this.get('images').then((images) => {
          // override and replace the promise here
          this.set('images', images);
          this.set('_actualImagesLength', images.get('length'));
          this._compositeImages(compArray);
        })
      } else {
        this._compositeImages(compArray);
      }
    } else {
      this.set('_allImages', compArray);
    }
  },

  _insertIndex: 0,
  _activeIndex: 0,

  _loadingImages: null,

  _allImages: null,

  @computed('_activeIndex', '_allImages.length')
  _atEnd(activeIndex, imagesLength) {
    if (!imagesLength) {
      return;
    }
    return activeIndex === (imagesLength - 1);
  },

  @computed('_activeIndex')
  _atStart(activeIndex) {

    if (this.showCase) {
      // console.log('showCase atEnd')
      return activeIndex === 2
    }

    return activeIndex === 0;
  },

  @computed('_insertIndex', '_allImages.length')
  _allInserted(_insertIndex, imagesLength) {
    return _insertIndex === (imagesLength - 1)
  },

  @computed('_allImages.@each', '_activeIndex', 'forceCaption')
  _currentImageCaption(_allImages, _activeIndex, forceCaption) {
    if (isEmpty(_allImages)) {
      return;
    }

    if (!_allImages.objectAt(_activeIndex)) {
      return;
    }

    return forceCaption ? forceCaption : (this.showImageCaptions ? _allImages.objectAt(_activeIndex).description : "");
  },

  @computed('_allImages.@each', '_activeIndex')
  _currentImageLoading(_allImages, _activeIndex) {
    if (isEmpty(_allImages)) {
      return;
    }

    if (!_allImages.objectAt(_activeIndex)) {
      return;
    }

    return Ember.get(_allImages.objectAt(_activeIndex), this.loadingPropName);
  },

  @computed('onToggle', '_allImages.length', 'imagesLength', 'forceControls')
  _showControls(onToggle, allImagesLength, imagesLength, forceControls) {
    return forceControls || allImagesLength > 1 || imagesLength >= 1;
  },

  @computed('elementId')
  loadingPropName(elementId) {
    // This is the prop that will be set on images to show if the image is loading for this component
    return 'loading'+elementId;
  },

  _insertAnotherImage() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.incrementProperty('_insertIndex');
    if (this._insertIndex < this._allImages.length) {
      run.later(this, this._insertAnotherImage, 100);
    }
  },

  _insertAllImages() {
    // console.log('_insertAllImages', this.debugName)
    this._insertAnotherImage();
    // run.later(this, this._insertAnotherImage, 50)
  },

  _loadImagesIfNecessary() {
    // if displaying inline and need to load info object/model
    if (!this.get('_haveLoadedImages')) {
      if (this.get('loadImages')){
        this.get('loadImages')();
      }

      this.set('_haveLoadedImages', true);
      this._insertAllImages();
    }
  },

  _next() {
    if (this.get('onToggle')) {
      return this.get('onToggle')();
    }

    this._loadImagesIfNecessary();

    if (this.get('_atEnd') && this._allImages.length > 1) {
      if (this.showCase) {
        // console.log('showCase atEnd')
        return this.set('_activeIndex', 2);
      }
      return this.set('_activeIndex', 0);
    } else {
      this.incrementProperty('_activeIndex');
    }
  },

  _prev() {
    if (this.get('onToggle')) {
      return this.get('onToggle')();
    }

    this._loadImagesIfNecessary();

    if (this.get('_atStart')  && this._allImages.length > 1) {
      return this.set('_activeIndex', this.get('_allImages.length') - 1);
    } else {
      this.decrementProperty('_activeIndex');
    }
  },

  actions: {
    _startLoad(index, image) {
      if (!this.get('_loadingImages').includes(index)) {
        this.get('_loadingImages').pushObject(index);
      }
      if (!this.fastboot.isFastBoot && !this.config.preRendering) {
        // console.log(image)
        set(image, this.loadingPropName, true);
      }
    },

    _endLoad(index, image) {
      this.get('_loadingImages').popObject(index);
      set(image, this.loadingPropName, false);
      // console.log(image)
    },

    next() {
      this._super(...arguments);
      this._next();
      return false;
    },

    prev() {
      this._super(...arguments);
      this._prev();
      return false;
    }
  }

});
