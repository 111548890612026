import Component from '@ember/component';
import MapPoints from 'b5b/mixins/map-points';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent
} from 'b5b/utils';



export default Component.extend(MapPoints, {

  classNames: ['service-review-card'],
  router: service(),
  feefo: service(),
  truncateLength: 250,

  @alias('review.tripSummary') tripSummary: false,
  @alias('feefo.loadingFeefo') loadingFeefo: null,
  @alias('feefo.feefoServiceReviews') feefoServiceReviews: null,
  @alias('feefo.viewedFeefoReview') viewedFeefoReview: null,


  actions: {
    openFeefoReview() {
      if (this.iframeEmbed) {
        window.parent.location.href='https://www.timbuktutravel.com/reviews'
      }
      else if (this.openInModal) {
        this.set('viewedFeefoReview', this.review)
        trackEvent('feefo-review:open', {url: this.router.currentURL})
      }
      return true
    },
    readMore() {
      if (!this.openInModal) {
        this.set('showFullReview', true)
        trackEvent('feefo-review:read-more', {url: this.router.currentURL})
      }
    }
  }

});
