import DS from 'ember-data';
import Self from 'b5b/models/lodge-option';

export default DS.Model.extend({
  stage: DS.belongsTo('stage', {
    async: false
  }),
  lodge: DS.belongsTo('lodge', {
    async: false,
    inverse: null
  }),
  netPerPersonNightExtra: DS.attr(),
  netPerPersonExtra: DS.attr(),
  netExtra: DS.attr(),
  priceType: DS.attr(),
  markup: DS.attr(),
  totalSellingPriceExtra: DS.attr(),
  currency: DS.attr(),
  sequence: DS.attr(),

  makeCopy() {

    let copy = this.get('store').createRecord('lodge-option');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    copy.set('lodge', this.get('lodge'))
    return copy;
  }  

});
