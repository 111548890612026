export default function(){  

	var duration = 200;

  this.transition(
    this.fromRoute('index'),
    this.toRoute('country'),
    this.use('explode', {
      matchBy: 'data-country-id',
      use: ['flyTo', { duration } ]
    }, {
      use: ['toLeft', { duration } ]
    }),
    this.reverse('explode', {
      matchBy: 'data-country-id',
      use: ['flyTo', { duration } ]
    }, {
      use: ['toRight', { duration } ]
    })    
  );
}