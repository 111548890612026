import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent,
  generateMeta
} from 'b5b/utils';
import basicNav from 'b5b/mixins/basic-nav';
import Mixin from '@ember/object/mixin';

export default Mixin.create(basicNav, {

  ui: service(),
  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    let modelRoute = this.entityType;
    if (this.entityType=='month') {
      modelRoute = 'when-to-go.'+modelRoute
    } else if (this.entityType=='general') {
      return null;
    }
    return this.modelFor(modelRoute);
  },

  beforeModel(transition) {
    this._super(...arguments);
    if (this.whitelabel.isForAgency && !this.get('session.currentUser.isManager') && !this.whitelabel.isOnboardingAgency) {
      transition.abort();
      window.history.back();
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent(this.entityType+':start-planning:view');
    controller.set('entity', model);
    controller.set('entityType', this.entityType);
    this.set('ui.entityNavVisible', false)
    this.set('ui.monthNavVisible', false)
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      // controller.setProperties({
      // });

      this.set('ui.entityNavVisible', true)
      this.set('ui.monthNavVisible', true)
    }
  },

  headTags() {
    let model = this.currentModel;
    let title = `Plan your ${model.name}`

    if (model.isSafari) {
      title += ' safari';
    }

    title +=` trip  | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }

    var description = `Plan your ${model.name} ${model.isSafari ? 'safari' : ''} trip with our fully customizable trip ideas, or start from scratch. Our travel experts can give you all the help and advice you need.`;
    let robots = 'noindex, nofollow'
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    return generateMeta({
      description,
      link,
      'og:title': title,
      robots
    });
  },


});
