import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    actualLineItems: {
      serialize: 'records'
    },
    discounts: {
      serialize: 'records'
    },
    bill: {
      serialize: false
    }
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards

    json.line_items_attributes = json.actual_line_items;
    json.discounts_attributes = json.discounts;

    delete json.actual_line_items;
    delete json.discounts;

    return json;
  }

});
