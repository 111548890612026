import Controller from '@ember/controller';
import EntitiesRoutesControllerMixin from 'b5b/mixins/entities/routes/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  aVsAn
} from 'b5b/helpers/a-vs-an';

export default Controller.extend(EntitiesRoutesControllerMixin, {

  @computed('model')
  designATripHeading(model) {
    if (model.get('isCategory')) {
      return 'Design ' + aVsAn(model.get('displayName')) + ' ' + model.get('displayName').replace('The ', '') + ' trip';
    } else {
      return 'Design a trip';
    }
  }
});
