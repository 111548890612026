import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent,
  setupTripEntityNav
} from 'b5b/utils';


export default Route.extend({

  ui: service(),
  session: service(),
  tripService: service('trip'),

  setupController(controller, model) {
    trackEvent('trip:dashboard:index');

    // NOTE IS IMPORTANT THAT WE DO NOT USE THE MODEL FROM MODEL HOOK
    // let trip = this.get('tripService.currentTrip');
    // let countryInfoPromises = trip.get('countries').mapBy('countryInfo');

    setupTripEntityNav(model, this.tripService, this.ui, this.session)
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set('ui.entityNavData', null);
    }
  },

  actions: {
    confirmFlightDetailsSent() {
      this.controller.get('trip.itinerary').setFlightsSentByGuest().then((response) => {
        this.store.pushPayload(response);
      });
    }
  }

});