import {
  isEmpty
} from '@ember/utils';
import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Controller.extend({

  settings: service(),
  ui: service(),
  router: service(),

  showMapModal: null,
  modalView: null,

  @computed('categoryRegionalExperiences.[]', 'experience', 'experience.mapMarker')
  mapMarkers(categoryRegionalExperiences, experience, mapMarker) {

    if (experience.get('isCategory')) {
      if (isEmpty(categoryRegionalExperiences)) {
        return;
      }

      let markers = [];
      categoryRegionalExperiences.forEach((experience) => {
        markers.pushObject([parseFloat(experience.get('longitudeWithFallback')).toFixed(6), parseFloat(experience.get('latitudeWithFallback')).toFixed(6)]);
      });

      return markers;
    } else {
      return mapMarker;
    }
  },
  actions: {
    showLighboxIfAvailable() {
      if (this.get('experience.metaImagesCount') > 1) {
        this.set('showGallery', true);
      }
    },
    openMapModal() {
      this.set('showMapModal', true);
    }
  }

});
