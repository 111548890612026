import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  CURRENCY_SYMBOLS_HASH
} from 'b5b/services/settings';

export default Component.extend({
  classNames: ['budget-selector'],

  settings: service(),

  budget: null,
  range: ['3000', '5000', '8000', '10,000'],
  rangeAUD: ['4500', '7500', '12,000', '15,000'],
  rangeCAD: ['4000', '7000', '11,000', '13,500'],
  rangeEUR: ['3000', '5000', '8000', '10,000'],
  rangeCHF: ['3000', '5000', '8000', '10,000'],
  rangeGBP: ['2500', '4000', '6500', '8000'],  
  rangeNZD: ['4500', '8000', '12,000', '16,000'],
  rangeZAR: ['50,000', '85,000', '140,000', '180,000'],

  unknownBand: 'Not Sure',

  getAmount(currency, level){
    let result = `${CURRENCY_SYMBOLS_HASH[currency]}`;
    switch(currency) {
      case "aud":
      result += this.get(`rangeAUD`)[level];
      break;      
      case "cad":
      result += this.get(`rangeCAD`)[level];
      break;
      case "eur":
      result += this.get(`rangeEUR`)[level];
      break;
      case "gbp":
      result += this.get(`rangeGBP`)[level];
      break;
      case "nzd":
      result += this.get(`rangeNZD`)[level];
      break;
      case "chf":
      result += this.get(`rangeCHF`)[level];
      break;      
      case "zar":
      result += this.get(`rangeZAR`)[level];
      break;
      default:
      result += this.get(`range`)[level];
    }
    return result;
  },

  @computed('settings.currentCurrency')
  lowBand(currencyCode){
    return `${this.getAmount(currencyCode, 0)} - ${this.getAmount(currencyCode, 1)}`;
  },

  @computed('settings.currentCurrency')
  mediumBand(currencyCode){
    return `${this.getAmount(currencyCode, 1)} - ${this.getAmount(currencyCode, 2)}`;
  },

  @computed('settings.currentCurrency')
  highBand(currencyCode){
    return `${this.getAmount(currencyCode, 2)} - ${this.getAmount(currencyCode, 3)}`;
  },

  @computed('settings.currentCurrency')
  veryHighBand(currencyCode){
    return `${this.getAmount(currencyCode, 3)} +`;
  },

  actions: {
    setBudget(budget){
      this.set('budget', budget);
    }
  }

});
