import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['country-card'],

  entityModals: service(),

  country: null,
  mapHover: false,

  canSwipe: true,

  @alias('entityModals.onModal') onModal: false,

  mouseEnter() {
    if (this.get('mapHover')) {
      this.set('country.hover', true);
    }
  },

  mouseLeave() {
    if (this.get('mapHover')) {
      this.set('country.hover', false);
    }
  },

  actions: {
    openModal(options) {
      if (this.get('onModal')) {
        this.get('entityModals').openModal(options);
      }
    }
  }

});
