import Controller from '@ember/controller';
import EntitiesExperiencesControllerMixin from 'b5b/mixins/entities/experiences/controller';

export default Controller.extend(EntitiesExperiencesControllerMixin, {

  // selectedCountryNames: [],
  // selectedMonthNames: [],
  // selectedExperienceNames: []

});
