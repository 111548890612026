import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import ActiveModelAdapter from 'active-model-adapter';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import {
  getErrorsHashFromServerResponse
} from 'b5b/utils';
import config from "b5b/config/environment";


export default ActiveModelAdapter.extend(DataAdapterMixin, {

  authorizer: 'authorizer:application',
  namespace: 'api/v1',

  // TODO once we support json api we can coalesce requests
  // coalesceFindRequests: true,
  config: service('config'),
  fastboot: service(),
  ui: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  init() {
    this._super(...arguments)
    // this.set('host', config.APP.host)
  },

  shouldBackgroundReloadRecord() {
    // TODO: review - reviewed - caused issues with updating lodges on trip index via substitution
    return false;
  },

  handleResponse() {
    let response = this._super(...arguments);
    if (response && response.isAdapterError) {
      let errors = getErrorsHashFromServerResponse(response);
      if (errors.cv_incorrect) {
        this.set('ui.frontEndVersionOutOfDate', true);
      }
    }

    return response;
  },

  buildURL: function( /*record, suffix*/ ) {
    if (this.isFastBoot) {
      return this._super(...arguments);
    }
    var s = this._super(...arguments)
    s = s + ".json"

    let memberActionBeingCalled = arguments[3] === 'updateRecord';

    if ((this.get('config.buster') === true || window.location.href.indexOf('buster=true') >= 0) && !memberActionBeingCalled) {
      //The check for updateRecord is to stop ?buster=true being added into the url for memberActions
      s = s.indexOf('?') > 0 ? s + '&buster=true' : s + '?buster=true'
    }
    // Specify a front-end version that allows us to bust through cache for major changes
    // cv stands for client version - whihc needs to sync with version in hawk
    // cb stands for cache buster and can just be incremented. Allows the app to start getting new data

    // NB!!!!! if you changes this you need to make changes to the Hawk codebase as well. Otherise the wrong version of homes is cached

    // If you mess with the url, you need to excluded member actions otherwise their urls get messed up and their relative path is added after the query params

    if (!memberActionBeingCalled && (window.timbuktu.services.whitelabel.isForAgency || (window.timbuktu.services.session.currentUser && window.timbuktu.services.session.currentUser.isManager))) {
      s = s.indexOf('?') > 0 ? s + '&publishedForWaybird=true' : s + '?publishedForWaybird=true'
    }

    if (!memberActionBeingCalled) {
      s = s.indexOf('?') > 0 ? s + '&cv=2&cb=7' : s + '?cv=2&cb=7'
    }

    return s
  },

  urlForDeleteRecord(id, modelName, snapshot) {
    var url = this._super(...arguments);
    if (modelName=='trip-summary') {
      return this.getTripUrl(url, modelName, id, snapshot)
    }
    return url;
  },

  getTripUrl: function(url, modelName, id, snapshot) {
    if (snapshot && snapshot.attributes().friendlyId && url.indexOf(id) > 0 ) {
      url = url.replace(id, snapshot.attributes().friendlyId)
    }

    return url;
  }

});
