import Controller from '@ember/controller';

export default Controller.extend({
  actions: {
    goToLogin() {
      this.transitionToRoute('login');
    },
    goToPasswordReset() {
      this.transitionToRoute('password-reset');
    }
  }
});