import Service, {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';

export default Service.extend({

  scroll: service(),

  history: [],

  hasWillTransitioned: false,
  hasDidTransitioned: false,

  restoreTimer: null,
  resetTimer: null,

  restoreScrollManually: false,
  blockResetOnDidTransition: false,

  usingBrowserNav() {
    return window.timbuktu.popStateTarget === window.location.pathname + window.location.search;
  },

  restorableRoute() {
    return this.usingBrowserNav() || this.restoreScrollManually;
  },

  captureLocation() {
    if (!window || this.get('transition.isAborted')) {
      return;
    }

    if (!this.history[window.location.pathname]) {
      // console.log('CAPTURE', window.location.pathname, this.get('scroll').getPosition());

      this.history[window.location.pathname] = {
        "scrollPosition": this.scroll.getPosition()
      };
    } else {
      // console.log('HISTORY exists, skip');
    }
  },

  ensureScrollToHeight() {
    if (!window) {
      return;
    }

    if (!this.history[window.location.pathname]) {
      // console.log('NO HISTORY');
      return this.resetScroll();
    }

    // console.log('RESTORE', window.location.pathname, this.get('history')[window.location.pathname].scrollPosition)
    this.scroll.to(this.history[window.location.pathname].scrollPosition, this);
    delete this.history[window.location.pathname];
  },

  scrollToHeightFromCaptureHistory() {
    run.cancel(this.restoreTimer);
    if (this.get('transition.isAborted')) {
      return;
    }
    this.restoreTimer = run.next(this, this.ensureScrollToHeight);
  },

  resetScroll() {
    run.cancel(this.resetTimer);
    // console.log('will reset scroll')
    this.resetTimer = run.next(() => {
      // console.log('resetting scroll')
      this.scroll.to(0, this);
    });
  },

  onWillTransition(transition) {
    // console.log(`onWillTranis:`)
    this.set('transition', transition);

    if (this.usingBrowserNav() || this.hasWillTransitioned || this.get('transition.isAborted')) {
      return;
    }

    if (transition.queryParams.scrollToStage) {
      this.set('blockResetOnDidTransition', true)
    }

    this.set('hasWillTransitioned', true);
    // If not using browser back/foward, capture location, and reset scroll to 0
    this.captureLocation();

    // can we always just reset initially, or will this cause flicker?
    // this.resetScroll();
  },

  onDidTransition(currentRouteName) {

    // console.log(`ondidTrans: ${currentRouteName}`)
    // guard - only run rest of logic on first transition
    if (this.hasDidTransitioned) {
      // reset once transition reaches application level
      if (currentRouteName === 'application') {
        this.setProperties({
          hasDidTransitioned: false,
          hasWillTransitioned: false,
          restoreScrollManually: false,
          transition: null
        });
      }
      return;
    }
    this.set('hasDidTransitioned', true);

    if (this.restorableRoute(currentRouteName)) {
      this.scrollToHeightFromCaptureHistory();
    } else if (!this.blockResetOnDidTransition) {
      this.resetScroll();
    }
    this.set('blockResetOnDidTransition', false)

    window.timbuktu.popStateTarget = null;
  }

});
