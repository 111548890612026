import {
  isEmpty
} from '@ember/utils';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default DS.Model.extend({
  uid: DS.attr(),
  url: DS.attr(),
  name: DS.attr(),
  teaserText: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),

  wpCreatedAt: DS.attr('b5bdate'),
  wpUpdatedAt: DS.attr('b5bdate'),


  /* This is a slightly modified variation */

  imageUrl: DS.attr('string'),
  @alias('imageUrl') kodakOriginalUrl: null,

  coverStyle: DS.attr('string', {
    defaultValue: 'center center'
  }),

  @computed('kodakOriginalUrl')
  hasKodakImage(url) {
    if (isEmpty(url) || url.indexOf('missing.png') > 0) {
      return false;
    }
    return true;
  }
});