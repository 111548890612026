import Mixin from '@ember/object/mixin';
import {
  isEmpty
} from '@ember/utils';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default Mixin.create({

  kodakOriginalUrl: DS.attr('string'),

  coverStyle: DS.attr('string', {
    defaultValue: 'center center'
  }),

  @computed('kodakOriginalUrl')
  hasKodakImage(url) {
    if (isEmpty(url) || url.indexOf('missing.png') > 0) {
      return false;
    }
    return true;
  },

  // lodges and trips override this but we need this so that floating widgets etc for entity pages conistently rember to use the heroEntity
  @computed()
  heroEntity() {
    return this;
  }

});