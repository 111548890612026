import Route from '@ember/routing/route';
import PrettyQuery from 'b5b/mixins/pretty-query';
import {
  inject as service
} from '@ember/service';

export default Route.extend(PrettyQuery, {

  ui: service(),

  queryParams: {
    selectedBlogTagNames: {
      replace: true
    },
  },

  setupController() {
    this._super(...arguments);
    this.set('ui.iframeEmbed', true)
  }
});
