import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent,
  setupTripEntityNav
} from 'b5b/utils';

export default Route.extend({

  ui: service(),
  session: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),  

  setupController(controller, model) {
    this._super(...arguments);

    // we bind this callback to the xplorer even though we setting the documents ourself in the controller, because the xplorer service still needs to use this when it updates the lodges. For example when the pagination is used
    this.get('xplorer').setupController(controller, this.controller.entitiesLoaded.bind(this.controller));
    setupTripEntityNav(model, this.tripService, this.ui, this.session)
    controller.loadDocumentsTask.perform();
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.clearFilters(controller);
      // reset the properties not related to filters
      controller.setProperties({
      });
      this.set('ui.entityNavData', null);

      this.get('xplorer').teardownController();

    }
  },

  clearFilters(controller) {
    // controller.setProperties({
    // });
  },


});