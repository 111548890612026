define("ember-sticky-element/templates/components/sticky-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RDjjzhBY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"sticky-element/trigger\",null,[[\"offset\",\"enter\",\"exit\",\"registerElement\"],[[23,[\"top\"]],[27,\"action\",[[22,0,[]],\"parentTopEntered\"],null],[27,\"action\",[[22,0,[]],\"parentTopExited\"],null],[27,\"action\",[[22,0,[]],\"registerTopTrigger\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"\\n  \",[21,\"containerClassName\"],\"\\n  \",[27,\"if\",[[23,[\"isSticky\"]],[23,[\"containerStickyClassName\"]]],null],\"\\n  \",[27,\"if\",[[23,[\"isStickyTop\"]],[23,[\"containerStickyTopClassName\"]]],null],\"\\n  \",[27,\"if\",[[23,[\"isStickyBottom\"]],[23,[\"containerStickyBottomClassName\"]]],null]]]],[12,\"style\",[21,\"containerStyle\"]],[9],[0,\"\\n\\n  \"],[14,1,[[27,\"hash\",null,[[\"isSticky\",\"isStickyTop\",\"isStickyBottom\"],[[23,[\"isSticky\"]],[23,[\"isStickyTop\"]],[23,[\"isStickyBottom\"]]]]]]],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"stickToBottom\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"sticky-element/trigger\",null,[[\"type\",\"offset\",\"enter\",\"exit\",\"registerElement\"],[\"bottom\",[23,[\"offsetBottom\"]],[27,\"action\",[[22,0,[]],\"parentBottomEntered\"],null],[27,\"action\",[[22,0,[]],\"parentBottomExited\"],null],[27,\"action\",[[22,0,[]],\"registerBottomTrigger\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sticky-element/templates/components/sticky-element.hbs"
    }
  });
  _exports.default = _default;
});