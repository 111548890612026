import xItineraryStageEdit from "b5b/components/x-itinerary/stage/edit/component";
import config from 'b5b/config/environment';

export default xItineraryStageEdit.extend({
  classNames: ["destination"],

  actions: {
    setDate() {
      this.get("datePickerInstance").open();
    },
    removeLodgeOption(options) {
      options.stage.lodgeOptions.removeObject(options.lodgeOption);
      this.get("tripService").madeChanges();
    },
    viewRegionalAvailability(options) {
    
      let id = options.stage.get('regionProxy.friendlyId');    
      let url = `${config.APP.adminHost}/server/region_availabilities/${id}`;
      if (options.stage.startDate) {
        let startDateString = moment(options.stage.startDate).format("YYYY-MM-DD")
        let endDateString = moment(options.stage.endDate).format("YYYY-MM-DD")
        url = url + `?start_date=${startDateString}&end_date=${endDateString}`
      }
      window.open(url, '_blank');
    
    }
  }

});
