import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  getCategoryExperiencesPromise
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('experience');
  },

  afterModel(model) {
    if (this.isFastBoot) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('experienceInfo'));
      });
    }
  },

  setupController: function(controller, model) {
    this._super(controller, model);

    if (model.get('isCategory')) {
      this.store.query('experience', {
        selectedExperienceNames: [model.get('name')],
        isRegional: true
      }).then((categoryRegionalExperiences) => {
        controller.setProperties({
          categoryRegionalExperiences
        });
      });
    } else {
      let regionalExperiences = this.store.query('experience', {
        filters: JSON.stringify([{type: 'region', id: model.get('region.id')}]),
        isRegional: true
      });

      controller.setProperties({
        regionalExperiences
      });
    }

    let categoryExperiences = getCategoryExperiencesPromise(this.store);

    controller.setProperties({
      experience: model,
      categoryExperiences
    });
  },

  resetController(controller) {
    this._super(...arguments)
    // reseting scroll when destination changes
    controller.set('categoryRegionalExperiences', null);
  }

});
