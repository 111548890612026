import { helper } from '@ember/component/helper';

export function exploreRoutesParams(params, namedArgs) {

  let entity = namedArgs.entity;
  let entityType = namedArgs.entityType;

  let targetRouteParams = {};
  let routeName = entityType+'.routes';

  switch (entityType) {
    case 'region':
      targetRouteParams['selectedRegionNames'] = [entity.get('name')];
      break;
    case 'country':
      targetRouteParams['selectedCountryNames'] = [entity.get('name')];
      break;
    case 'month':
      routeName = 'when-to-go.' + routeName;
      targetRouteParams['selectedMonthNames'] = [entity.get('name')];
      break;
    case 'lodge':
      targetRouteParams['selectedLodgeNames'] = [entity.get('name')];
      break;
    case 'general':
      routeName = 'trips'
    case 'blogTags':
      routeName = 'trips'

    default:
      break;
  }

  if (entity && entityType!='blogTags') {
    return [
      routeName, entity, {
        isQueryParams: true,
        values: targetRouteParams
      }
    ];

  } else {
    return [
      routeName, {
        isQueryParams: true,
        values: targetRouteParams
      }
    ];
  }

}

export default helper(exploreRoutesParams);
