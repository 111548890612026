import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  tagName: '',
  entityModals: service(),
  dataReady: false,

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') country: null,

  didInsertElement() {
    this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: this.country.id}]),
    }).then((regions)=> {
      this.set('dataReady', true)
    })
  }

});
