import Component from "@ember/component";
import {
  observer
} from '@ember/object';

export default Component.extend({
  classNames: ["x-card"],
  isForcedOpen: false,
  isOpen: false,

  checkForAllCardsBeingClosed: observer('item.forceCardClosed', function() {
    if (this.item.forceCardClosed) {
      this.set("isOpen", false);
      this.set("item.forceCardOpen", false);
      this.set("item.forceCardClosed", false);
    }
  }).on('init'),

  checkForAllCardsBeingOpened: observer('item.forceCardOpen', function() {
    if (this.item.forceCardOpen) {
      this.set("isOpen", true);
      this.set("item.forceCardOpen", false);
      this.set("item.forceCardClosed", false);
    }    
  }).on('init'),


  actions: {
    toggleIsOpen() {
      this.set("isOpen", !this.isOpen);
    },
    ensureOpen() {
      this.set("isOpen", true);
    }
  },
});
