import { helper } from '@ember/component/helper';

export function tripIdeaTitle([type]/*, hash*/) {
  var realTitle = '';
  switch (type) {
    case 'low':
      realTitle = 'Shoestring';
      break;
    case 'mid':
      realTitle = 'Mid-range';
      break;
    case 'high':
      realTitle = 'Luxury';
      break;
    case 'custom':
      realTitle = 'Choose your own properties';
      break;
    default:
      break
  }

  return realTitle;
}

export default helper(tripIdeaTitle);
