export default {
  name: "app-controller-reference",

  initialize: function(instance) {

    if (typeof FastBoot !== 'undefined') {
      return;
    }

    var appController = instance.lookup('controller:application');
    var affiliateService = instance.lookup('service:affiliate');
    var settingsService = instance.lookup('service:settings');    
    var whitelabel = instance.lookup('service:whitelabel');
    var settings = instance.lookup('service:settings');
    var session = instance.lookup('service:session');

    window.timbuktu.services = {whitelabel, settings, session};

    window.timbuktu.appController = appController;
    window.timbuktu.affiliateService = affiliateService;
    window.timbuktu.settingsService = settingsService;
  }

}
