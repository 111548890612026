define('ember-deferred-content/components/deferred-content', ['exports', 'ember-deferred-content/templates/components/deferred-content'], function (exports, _deferredContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      Component = Ember.Component,
      computed = Ember.computed,
      not = Ember.computed.not,
      get = Ember.get,
      _set = Ember.set;


  var DeferredContentComponent = Component.extend({
    layout: _deferredContent.default,
    isPending: not('isSettled'),
    tagName: '',
    promise: computed({
      set: function set(key, promise) {
        var _this = this;

        assert('You must pass a promise to ember-deferred-content', typeof promise.then === 'function');
        _set(this, 'isRejected', false);
        _set(this, 'isFulfilled', false);
        _set(this, 'isSettled', false);
        _set(this, 'content', null);

        promise.then(function (result) {
          if (!get(_this, 'isDestroyed')) {
            _set(_this, 'isFulfilled', true);
            _set(_this, 'content', result);
          }
        }, function (result) {
          if (!get(_this, 'isDestroyed')) {
            _set(_this, 'isRejected', true);
            _set(_this, 'content', result);
          }
        }).finally(function () {
          if (!get(_this, 'isDestroyed')) {
            _set(_this, 'isSettled', true);
          }
        });

        return promise;
      }
    })
  });

  DeferredContentComponent.reopenClass({
    positionalParams: ['promise']
  });

  exports.default = DeferredContentComponent;
});