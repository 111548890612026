import Mixin from '@ember/object/mixin';
import Service, {
  inject as service
} from '@ember/service';

export default Mixin.create({

  router: service(),
  tripService: service('trip'),

  beforeModel(transition) {
    this._super(...arguments);


    // This causes the first stage to be opened always when choosing a lodge
    //
    // if (!transition.queryParams.currentStageIndex) {
    //   // if there are enough stages (not a new trip)
    //   if (this.get('tripService.currentTrip.itinerary.stages').length > 1) {
    //     // open the first stage always if not focusing a stage
    //     this.set('tripService.currentStageIndex', 1);
    //     this.set('tripService.currentStage.stageExpanded', true);
    //   }
    // }


    if (transition.queryParams.currentStageIndex) {
      this.set('tripService.currentStageIndex', transition.queryParams.currentStageIndex);
      this.get('tripService').focusStage();
    }


    // We're limiting this now to the create route
    // Ie., only create new stages with the entities if coming via create
    if (transition.queryParams.viaCreate) {
      let filters = transition.queryParams.filters && JSON.parse(transition.queryParams.filters);
      let filterLodges = filters && filters.filterBy("type", 'lodge')
      if (filterLodges && filterLodges.length > 0) {      
        filterLodges.forEach((filterLodge) => {
          let lodge = this.get('store').peekRecord('lodge', filterLodge.id)
          this.set('tripService.newStage', this.get('store').createRecord('stage', {
            stageType: 'lodge',
            numNights: 3
          }));

          this.get('tripService').doChangeFoundNewLodge({
            lodge: lodge,
            scrollToLodge: false
          })
        })
      }
      // we dont clear this in resetController as you would lose context when you go to choose lodges / places
      // instead we set every time we go to new trip
      let filterCountries = filters && filters.filterBy("type", 'country')
      if (filterCountries && filterCountries.length > 0 ) {      
        let countryIds = filterCountries.mapBy('id');
        this.controllerFor(this.routeName).set('uniqueCountryIds', countryIds.uniq())
      } else {
        this.controllerFor(this.routeName).set('uniqueCountryIds', null)
      }

      let filterRegions = filters && filters.filterBy("type", 'region')
      if (filterRegions && filterRegions.length > 0) {
        let regionIds = filterRegions.mapBy('id')
        this.addRegionsToTripByRegionIds(regionIds);
      }
      let filterExperiences = filters && filters.filterBy("type", 'experience')
      if (filterExperiences && filterExperiences.length > 0) {

        let regionIds = []
        filterExperiences.forEach((filterExperience) => {
          let experience = this.store.peekRecord('experience', filterExperience.id);
          if (experience.belongsTo('region').id()) {
            regionIds.pushObject(experience.belongsTo('region').id())
          }
        })
        this.addRegionsToTripByRegionIds(regionIds)
      }

    }
  },

  addRegionsToTripByRegionIds(regionIds) {
    regionIds.forEach((regionId) => {
      let region = this.store.peekRecord('region', regionId)
      this.set('tripService.newStage', this.get('store').createRecord('stage', {
        stageType: 'region',
        numNights: 3
      }));
      this.get('tripService').doChangeFoundNewRegion({
        region: region
      })
    })
  },


});
