import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  or
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNames: ['safari-specialist'],

  settings: service(),
  tripService: service('trip'),

  avatarSize: 50,
  static: false,

  avatarOnly: false,

  @or('trip.consultant', 'settings.consultant') consultant: false,

  click() {
    if (this.get('static')) {
      return;
    }
  }

});
