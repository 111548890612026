import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent,
  lodgeStyles
} from 'b5b/utils';
import RSVP from 'rsvp';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';

// NOTE:
// We needed to isolate this logic to a component as its still used in a model in the context of trip.edit.
// Once those cases are removed we can just move this all back to stage.own-arrangement

export default Component.extend({

  classNames: ['lodge-form'],

  router: service(),
  store: service(),
  cache: service(),
  whitelabel: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.editEntityModalOptions') editEntityModalOptions: null,
  @alias('tripService.savingCustomEntity') savingCustomEntity: false,


  @computed()
  lodgeStyles() {
    return lodgeStyles();
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },
  
});
