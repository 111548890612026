import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  htmlSafe
} from '@ember/template';

export default Component.extend({
  classNames: ['map-opener'],
  tagName: '',

  entityModals: service(),

  spacingRatio: null,

  onClick: null, // override closure

  @alias('entityModals.onModal') onModal: null,

  @computed('spacingRatio')
  style(spacingRatio){
    if (spacingRatio){
      return htmlSafe('padding-top:' + spacingRatio + '%;');
    }
  },

  @computed('onModal', 'openMapModal', 'showMapModal')
  shouldOpenModal(onModal, openMapModal, showMapModal) {
    return (openMapModal && showMapModal) || onModal;
  },

  actions: {
    openModal(options) {
      if (this.onClick) {
        return this.onClick();
      }

      if (this.onModal && options) {
        this.get('entityModals').openModal(options);
      } else if (this.openMapModal && this.showMapModal) {
        this.openMapModal();
      }

    }
  }
});
