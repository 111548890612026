import LinkComponent from '@ember/routing/link-component';
import { trackEvent } from 'b5b/utils';


export default LinkComponent.extend({
  click() {
    this._super(...arguments);
    let _trackEvent = this.trackEvent;
    let _trackEventArguments = this.trackEventArgs;
    if (_trackEvent) {
      trackEvent(_trackEvent, _trackEventArguments);
    }
  }
});
