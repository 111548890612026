import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import {
  inject as service
} from '@ember/service';



export default Route.extend(basicNav, {
});
