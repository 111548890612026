import Service from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';



export default Service.extend({

  /*
    We basically just set the values here to persist values if users come back to tweak answers
  */

  wizardStepController: null,

  @alias('wizardStepController.goAnytime') goAnytime: null,
  @alias('wizardStepController.selectedContinentName') selectedContinentName: null,
  @alias('wizardStepController.preferedPartner') preferedPartner: null,
  @alias('wizardStepController.planningStage') planningStage: null,
  @alias('wizardStepController.expertShouldContact') expertShouldContact: null,
  @alias('wizardStepController.preferedYear') preferedYear: null,
  @alias('wizardStepController.allCountries') allCountries: null,
  @alias('wizardStepController.experiencesCompilation') experiencesCompilation: null,
  @alias('wizardStepController.selectedCountryNames') selectedCountryNames: null,
  @alias('wizardStepController.selectedExperienceNames') selectedExperienceNames: [],
  @alias('wizardStepController.selectedMonthNames') selectedMonthNames: null,
  @alias('wizardStepController.wizardRules') wizardRules: null,
  @alias('wizardStepController.errors') errors: null,
  @alias('wizardStepController.email') email: null,
  @alias('wizardStepController.firstName') firstName: null,
  @alias('wizardController.currentStep') currentStep: null,

  @computed('currentStep', 'firstName', 'email')
  readyForNextStep(currentStep, firstName, email) {
    if (currentStep==5) {
      if (firstName && email && email.indexOf('@')>0) {
        return true;
      }
      return false;
    }
    return true;
  },

  setupController(options={} /* wizardStepController, wizardController, cb */) {
    this.setProperties({
      wizardStepController: options.wizardStepController,
      wizardController: options.wizardController,
      loadCallback: options.cb
    });
  },

  teardownController() {
    this.setProperties({
      wizardStepController: null,
      loadCallback: null
    });
  },

  alertUserStepNotComplete() {
    if (this.currentStep == 5) {
      let errors = {};
      if (!this.firstName) {
        errors.first_name = 'Please provide a first name';
      }
      if (!this.email || this.email.indexOf('@')<=0) {
        errors.email = 'Please provide a valid email';
      }
      this.wizardStepController.set('errors', errors)
    }
  }

});
