import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';
import {
  getErrorsHashFromServerResponse,
  findRecordsToUnload,
  setupTripEntityNav,
  capitalizeFirstLetter
} from 'b5b/utils';
import RSVP from 'rsvp';

export default Mixin.create({

  store: service(),
  session: service(),
  ui: service(),
  dealService: service(),
  messageBus: service(),
  whitelabel: service(),
  tripService: service('trip'),

  // TODOLATER.. Ideally this method should move to the trip service
  saveTrip(unsavedTrip, options) {

    if (this.whitelabel.isOnboardingAgency && !options.allowCreationOfBlankTripForOnboardingSite) {
      this.set('ui.showWaybirdSignupModal', true)
      return new RSVP.Promise(function (resolve) {
        resolve();
      });
    }

    var tripToSave = options.specifiedTrip || unsavedTrip;

    this.set('tripService.savingTrip', true)
    let currentUser = this.get('session.currentUser');
    var notOwnTrip = (tripToSave.get('master') || !currentUser || !tripToSave.hasMany('users').ids().includes(currentUser.get('id')))
    // var notOwnTrip = false;

    // OK this is a bit of a hack.. Basically we are ignoring the sequence elsewhere in the app, so we jsut allocate the sequence when we save the trip
    tripToSave.get('itinerary.stages').forEach((stage, index) => {
      if (!stage.isDeleted) {
        stage.set('sequence', index);
        let blocks = stage.get('customSchedule.blocks');
        if (blocks) {
          blocks.forEach((block, blockIndex) => {
            if (!block.isDeleted) {
              block.set('sequence', blockIndex);
              let blockItems = block.get('blockItems');
              if (blockItems) {
                blockItems.forEach((blockItem, blockItemIndex) => {
                  if (!blockItem.isDeleted) {
                    blockItem.set('sequence', blockItemIndex);
                  }
                });
              }

            }
          });
        }

      }
    });

    let makeCopyOfTrip = notOwnTrip || tripToSave.get('makeCopyOfTripWhenSaving');

    if (options.hasOwnProperty('makeCopyOfTrip')) {
      makeCopyOfTrip = options.makeCopyOfTrip;
    }

    if (makeCopyOfTrip) {
      let copyOfTrip = tripToSave.makeCopyForSaving({
        removeReferenceToSpecialOfferWhenSaving: tripToSave.removeReferenceToSpecialOfferWhenSaving
      });

      this.tripService.changeTripLayout({trip: copyOfTrip}, options.newTripLayout)
      tripToSave = copyOfTrip;
    }

    if (currentUser) {
      tripToSave.set('owner', currentUser);
    }

    tripToSave.get('itinerary.videos').forEach((video)=> {
      if (!video.url) {
        this.store.unloadRecord(video)
      }
    })

// TODODEALS..This is the original  code for saving guests
    let savingGuests = [];
    tripToSave.itinerary.guests.filterBy('isNew').forEach((guest)=> {
      savingGuests.pushObject(guest.save())
    })

    // TODODEALS this is the replacement for guests on deals
    // guests are not part of the embedded save
    // let savingGuests = [];
    // tripToSave.itinerary.itineraryGuests.filter((itineraryGuest)=>{
    //   return itineraryGuest.guest.isNew || itineraryGuest.guest.hasDirtyAttributes;
    // }).forEach((itineraryGuest)=> {
    //   savingGuests.pushObject(itineraryGuest.guest.save())
    // })

    // if (this.dealService.currentDeal && !tripToSave.deal) {
    //   tripToSave.set('deal', this.dealService.currentDeal)
    // }

    // if (!tripToSave.id && tripToSave.get('deal.guests')) {
    //   //new trip so add all the guets from the deal to this trip
    //   tripToSave.get('deal.guests').forEach((guest)=> {
    //     tripToSave.get('itinerary').get('itineraryGuests').pushObject(this.store.createRecord('itinerary-guest', {guest}))
    //   })
    // }

    return RSVP.all(savingGuests).then(()=> {
      return tripToSave.save().then((savedTrip) => {
        if (options.showSuccessFlash || (currentUser && currentUser.isManager)) {
          this.flashMessages.success('Trip saved')
        }
        // console.log('TRIP SUCCESFULLY SAVES!');

        savedTrip = this.fixupModelsInStoreAfterEmbeddedSave(savedTrip)
        this.tripService.cleanupQuoteToolsDirtyIndicators();
        //Clean up on proposed attributes that are not real attributes
        savedTrip.set('proposedPrimaryManager', null)
        savedTrip.set('proposedNotifyNewPrimaryManager', null)

        this.get('messageBus').publish('trip-saved');

        if (!options.specifiedTrip) {
          // Mkae sure the video tab is add when videos are added
          setupTripEntityNav(this.tripService.currentTrip, this, this.ui, this.session)
        }

        return savedTrip;
      }, (error) => {

        // Default is if there is an error while saving a trip, but for example in enquiry controller we want to handle the failure further up promise chaing
        if (!options.blockFailureToSaveReporting) {
          Bugsnag.notify('Error while trying to save trip', JSON.stringify(arguments));
          this.flashMessages
            .danger('Sorry! There was an error while trying to save this trip', {
              timeout: 4000,
              sticky: false,
              showProgress: true
            });

          if (currentUser.isManager) {
            let errors = getErrorsHashFromServerResponse(error)
            let message = '';
            Object.keys(errors).forEach((key) => {
              let descriptiveKey = capitalizeFirstLetter(key.replace(/_/g, ' '))
              message += `${descriptiveKey}: ${errors[key]}<br>`;
            })
            this.ui.showGeneralMessage('This trip is not valid', message);
          }
        }
        throw error;
      }).finally(() => {
        this.set('tripService.savingTrip', false);
      });
    })
  },


  fixupModelsInStoreAfterEmbeddedSave(trip) {
    // When you do an embedded save it is not overriding dirty properties on the embedded models if the server supplies different values
    // THis is not such an issue for us in general at this stage, but we do need to ensuree that needsTransfer is always set back to false on stages
    // otherwise it will stay true on the stages that were set as needsTranser: true and ignore the response
    // https://github.com/emberjs/data/issues/4552
    // https://github.com/emberjs/data/issues/2487

    trip.get('itinerary.stages').forEach((stage) => {
      // Stages need to be set back to default after price request or save
      stage.set('madeTransferAffectingChanges', false);
      stage.set('needsTransfer', false);
    });

    // This is caused by the same problem above. If you set override or margin percentage adjustment but then switch auto markup back on,
    // the server will set those values to null, and respond with the null values but the embedded save is not updating the attributes on the embedded models

    if (this.tripService.timbuktuTripOnPricingModel4 && trip.itinerary.quote) {
      trip.get('itinerary.quote.actualLineItems').forEach((lineItem) => {
        // console.log(lineItem)
        if (lineItem.useTimbuktuAutoMarkup) {
          lineItem.set('marginPercentageAdjustment', null)
          lineItem.set('overridePerGuest', null)

        }
      })
    }

    var recordsToUnload = [];

    // We might have created new records without ids. The server will allocate them ids, but because its an embedded save they are not tied back to the
    // original records without ids, effectively duplicating any new records. so we remove the original ones without ids

    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary').hasMany('stages')));
    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary').hasMany('payments')));
    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary').hasMany('videos')));
    // TODODEALS remove this reference to guests on itinerary
    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary').hasMany('guests')));
    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary').hasMany('itineraryGuests')));

    trip.get('itinerary.payments').forEach((payment) => {
      if (payment.get('multiCurrency')) {
        recordsToUnload = recordsToUnload.concat(findRecordsToUnload(payment.get('multiCurrency').hasMany('currencyContributions')));
      }
    });

    if (trip.get('itinerary.quote')) {
      recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary.quote').hasMany('actualLineItems')));
      recordsToUnload = recordsToUnload.concat(findRecordsToUnload(trip.get('itinerary.quote').hasMany('discounts')));
      trip.get('itinerary.quote.actualLineItems').forEach((lineItem) => {
        recordsToUnload = recordsToUnload.concat(findRecordsToUnload(lineItem.hasMany('guestLineItems')));
      });
    }

    trip.get('itinerary.stages').forEach((stage) => {
      if (stage.get('customSchedule')) {
        recordsToUnload = recordsToUnload.concat(findRecordsToUnload(stage.get('customSchedule').hasMany('blocks')));
        stage.get('customSchedule.blocks').forEach((block) => {
          recordsToUnload = recordsToUnload.concat(findRecordsToUnload(block.hasMany('blockItems')));
          block.get('blockItems').forEach((blockItem) => {
            recordsToUnload = recordsToUnload.concat(findRecordsToUnload(blockItem.hasMany('blockItemExperiences')));
          });
        });
      }
      recordsToUnload = recordsToUnload.concat(findRecordsToUnload(stage.hasMany('lodgeOptions')));


    });


    recordsToUnload.forEach((record) => {
      this.store.unloadRecord(record);
    });
    return trip
  }

});
