import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  isPresent
} from '@ember/utils';
import SetupCountryFaqModal from 'b5b/mixins/setup-country-faq-modal';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(SetupCountryFaqModal, {

  needToKnowType: 'health',

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags() {
    var country = this.modelFor('country');
    const title = `Vaccinations and health info for your ${country.get('seoName')} | ${this.whitelabel.agencySeoName}`;
    const description = title;

    let link;
    if (isPresent(country.get('countryInfo.health'))) {
      link = `country/${country.get('countryFriendlyId')}/health`;
    } else {
      link = `country/${country.get('countryFriendlyId')}`;
    }

    if (!this.isFastBoot) {
      document.title = title;
    }
    return generateMeta({
      description,
      link,
      'og:title': title
    });
  }

});
