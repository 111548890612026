import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({

  experiences: DS.hasMany('experience', {
    async: false
  }),

  homeImages: DS.attr(),
  locationsCount: DS.attr(),
  categoryExperienceCount: DS.attr(),
  lodgeTypeExperienceCount: DS.attr(),
  activityExperienceCount: DS.attr(),
  travelStatuses: DS.attr(),

  search: DS.belongsTo('search', {
    inverse: null
  }),

  @computed('experiences')
  tripTypeExperiences(experiences) {
    return experiences.filterBy('isTripType')
  }

});
