import {
  reads
} from '@ember/object/computed';
import {
  debounce,
  run
} from '@ember/runloop';
import Service, {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';
import {
  isEmpty
} from '@ember/utils';
import {
  computed
} from 'ember-decorators/object';


const SCROLL_HEIGHT_CTA_BAR = 200;

export default Service.extend({

  screen: service(),
  locationScrollHistory: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  /*
    NOTE: there are differences here

    window                - physical screen sizes
    window.document       - document size extending past the visible viewport
    window.document.body  - needed for animations
  */

  scrollContainer: window.document, // default target

  // window.document
  _bind() {
    $(this.get('scrollContainer')).on('scroll', this.get('_instance'));
  },

  _unbind() {
    $(this.get('scrollContainer')).off('scroll', this.get('_instance'));
  },

  // Since the header watches it on all pages, might as well
  // do a once off setup and not worry about cross component intereference
  init() {

    if (this.get('isFastBoot')) {
      return;
    }

    // Disables scroll reset for chrome
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    if (this.get('_instance')) {
      console.info('Scroll init: _instance running');
      return;
    }
    this.set('_instance', this._scrolling.bind(this));
    this._bind();
  },

  /*
  Usage:
  ---
    this.get('scroll').set('startedCallback', this.scrollStarted.bind(this));
  */
  startedCallback: null, // bound callback that fires on scroll start
  activeCallback: null, // debounced continuously, use sparingly
  endedCallback: null, // bound callback that fires on scroll end

  _instance: null,

  // states
  started: false,
  active: false,
  ended: true,

  @computed('scrollContainer')
  animateContainer(scrollContainer) {
    return (scrollContainer === window.document) ? window.document.body : scrollContainer;
  },

  // window.document
  @computed('scrollContainer')
  containerHeight(scrollContainer) {
    return $(scrollContainer).outerHeight();
  },



  /*
  UI Properties
  ----
  */

  isCTABarVisible: false,
  trackEnabled: true,
  trackDebounce: 16, // 16ms default would be 60fps - causes issues with filter sticking though
  position: null, // scrollContainer scrollTop value

  // For getting position when tracking is disabled
  getPosition() {
    return $(this.get('scrollContainer')).scrollTop();
  },

  threshold: null,

  isSearching: false,

  onRemoveModal: null, // closure

  openModals: [],

  addModal(id, el) {
    this.get('openModals').pushObject({
      'pos': this.getPosition(),
      'el': el,
      'uid': id
    });

    this.set('isModalOpen', true);
  },

  removeModal(id, el) {
    let pos;

    this.get('openModals').forEach((item) => {
      if (item.uid === id) {
        pos = item.pos;
        this.get('openModals').removeObject(item);
      }
    });

    this.to(pos, el);

    if (isEmpty(this.get('openModals'))) {
      this.set('isModalOpen', false);
    }

    if (this.get('onRemoveModal')) {
      this.get('onRemoveModal')();
      this.set('onRemoveModal', null);
    }
  },

  lock: false, // manual override

  lockScroll: observer('isSearching', 'isModalOpen', 'isMenuExpanded', 'lock', function() {
    let bool = this.get('isSearching') || this.get('isModalOpen') || this.get('isMenuExpanded') || this.get('lock');
    if (bool) {
      $('html').addClass('scroll-locked');
    } else {
      $('html').removeClass('scroll-locked');
    }
  }),


  /*
    Sometimes we run into double render issues when setting eg. isSearching
    especially when using multi pills.
    Unsetting it here is one solution
  */

  unset(prop) {
    run.next(() => {
      this.set(prop, false);
    })
  },

  /*
  Usage:
  ---
    setup: function() {
      this.get('scroll').to(123, this, 300);
    }.on('didInsertElement'),
  */
  to(x, el, duration, easing, selector, cb) {
    // console.log('scrollservice.to', this.scrollContainer)
    if (duration || easing) {

      duration = duration || 300;
      easing = easing || 'linear';
      el = el || this.get('scrollContainer');

      run.scheduleOnce('afterRender', el, this._animate.bind(this), x, duration, easing, selector, cb);

    } else {
      // window.document
      run.scheduleOnce('afterRender', el, this._jump.bind(this), x, selector);

    }

    if (this.get('trackEnabled')) {
      this.set('position', x);
    }
  },

  // Run in context of element in order to schedule
  // it after the afterRender event
  _jump(x, selector) {
    // console.log('stroll.jump', x, selector)
    $(selector || this.get('scrollContainer')).scrollTop(x);
  },

  _animate(x, duration, easing, selector, cb) {
    // $(selector || this.get('scrollContainer')).scrollTop(x);
    // Since the scroll will happen effectively sync, we can call the callback straight away
    // Jquery animate doesn 't seem tobe working at the moment. Just oing to scroll for now until we
    $(selector || this.get('animateContainer')).animate({
      scrollTop: x
    }, duration, easing, function() {
      if (cb) {
        cb();
      }
    });
  },

  _scrolling() {
    if (this.get('ended') && !this.get('started')) {
      debounce(this, this._start, 251, true);
    }

    if (this.get('trackEnabled')) {
      debounce(this, this._track, this.get('trackDebounce'));
    }

    if (this.get('started') && !this.get('ended')) {
      debounce(this, this._end, 250);
    }
  },

  _start() {
    this.setProperties({
      started: true,
      active: true,
      ended: false
    });

    if (this.get('startedCallback')) {
      this.get('startedCallback')();
    }
  },

  _updateScrollTrackers(position) {

    this.setProperties({
      isCTABarVisible: position >= SCROLL_HEIGHT_CTA_BAR
    });
  },

  _track() {
    let position = $(this.get('scrollContainer')).scrollTop();

    this.set('position', position);

    this._updateScrollTrackers(position)

    if (this.get('activeCallback')) {
      this.get('activeCallback')(position);
    }
  },

  _end() {
    this.setProperties({
      started: false,
      active: false,
      ended: true
    });

    if (this.get('endedCallback')) {
      this.get('endedCallback')();
    }
  },

  // Should be explicitly called on component teardown
  cleanup() {

    console.info('Scroll: cleanup');

    this.setProperties({
      scrollContainer: window.document,
      startedCallback: null,
      activeCallback: null,
      endedCallback: null
    });
  }

});
