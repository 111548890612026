import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import AuthFormComponent from 'b5b/components/auth-form/component';
import {
  captureEmail,
  getErrorsHashFromServerResponse,
  trackEvent
} from 'b5b/utils';

export default AuthFormComponent.extend({

  classNames: ['register'],

  ui: service(),

  didInsertElement() {
    this._super(...arguments);
    this.ui.setProperties({
      showLoginModal: false,
      showPasswordResetModal: false,
      showChangePasswordModal: false
    });
  },

  showForm: false,

  validations: {
    'firstName': {
      presence: {
        message: "Please fill out"
      }
    },
    'lastName': {
      presence: {
        message: "Please fill out"
      }
    },
    'email': {
      presence: {
        message: "Please fill out"
      }
    },
    'password': {
      presence: {
        message: "Please fill out"
      }
    }
  },

  actions: {
    createUser() {
      let userAuthenticated = this.userAuthenticated ? this.userAuthenticated : run.bind(this, 'genericUserAuthenticated');

      const {
        firstName,
        lastName,
        email,
        password
      } =
      this;

      this.set('saving', true);

      this.store.createRecord('user', {
          name: firstName,
          surname: lastName,
          email,
          password
        })
        .save()
        .then(() => {
          captureEmail(email, 'register', 'create-user', firstName);
          return this.session
            .authenticate('authenticator:server-side-aware-authenticator', email, password);
        })
        .then(() => {
          trackEvent('registration', {
            registration: 'email'
          });
          window.timbuktu.reportConversions.reportUserRegistration();
          this.set('saving', false);
          userAuthenticated('register');

          if (window && typeof window.fbq !== 'undefined') {
            window.fbq('track', 'CompleteRegistration');
          }


        })
        .catch((serverResponse) => {
          this.set('errors', getErrorsHashFromServerResponse(serverResponse));
          this.set('showErrors', this.errors && true);
          this.set('saving', false);
        });

      return false;
    }
  }

});
