import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  alias,
  reads
} from 'ember-decorators/object/computed';
import {
  getSortTitle
} from 'b5b/utils';

export default Component.extend({
  classNames: ['lodges-list'],

  
  screen: service(),
  scroll: service(),
  ui: service(),
  messageBus: service(),
  fastboot: service(),
  @reads('fastboot.isFastBoot') isFastBoot: false,

  tripService: service('trip'),

  minLodgePrice: null,
  maxLodgePrice: null,

  lodgeCountCache: 0,
  reloadTimer: null,

  @computed('sort', 'screen.isMobileOnly')
  sortTitle(sort, isMobileOnly) {
    return getSortTitle(sort, isMobileOnly);
  },

  @computed('lodges.meta.totalCount', 'exploredLodges.length', 'unexploredLodges.length', 'lodgesLoading', 'customLodges.length')
  lodgeCount(lodgesLength, exploredLodges, unexploredLodges, lodgesLoading, customLodges) {
    if (lodgesLength) {
      this.set('lodgeCountCache', lodgesLength);
    }

    if (!exploredLodges && !unexploredLodges && !lodgesLoading && !customLodges) {
      this.set('lodgeCountCache', 0);
    }

    return this.get('lodgeCountCache');
  },

  @mapBy('collections', 'name') collectionNames: null,

  @computed('countries', 'selectedCountryNames')
  selectedCountry(countries, selectedCountryNames) {
    return countries.findBy('name', selectedCountryNames[0]);
  },

  @computed('countries')
  countryNames(countries) {
    return countries.mapBy('name').sort();
  },

  @alias('lodges') matchingLodges: null,

  @computed('matchingLodges.[]')
  exploredLodges(matchingLodges) {
    if (!matchingLodges) {
      return;
    }

    return matchingLodges.filter((lodge) => {
      if (this.whitelabel.isForTimbuktu) {
        return lodge.get('isPolished');
      } else {
        return lodge.get('isPolished') || lodge.get('metaImages.length')>=3;
      }
      
    });
  },

  @computed('matchingLodges.[]', 'exploredLodges.[]')
  unexploredLodges(matchingLodges, exploredLodges) {
    if (!matchingLodges) {
      return;
    }

    return matchingLodges.filter((lodge) => {
      return !exploredLodges.includes(lodge) && lodge.get('isUnexplored')
    });
  },
  @computed('matchingLodges.[]', 'exploredLodges.[]')
  customLodges(matchingLodges, exploredLodges) {
    if (!matchingLodges) {
      return;
    }

    return matchingLodges.filter((lodge) => {
      return !exploredLodges.includes(lodge) && lodge.get('isCustom')
    });
  },

  @computed('screen.isMobileOnly', 'isFastBoot')
  numColumns(isMobile, isFastBoot) {
    if (isFastBoot) {
      return 2;
    }
    return isMobile ? 1 : 2;
  }

});
