import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  inject as service
} from '@ember/service';

export default Controller.extend(UseCurrentTrip, {
  paymentProcessedController: controller('trip.index.payment-processed'),
  tripService: service('trip'),

  setupPaymentDue() {
    this.get('tripService.currentTrip.itinerary.paymentsDue').forEach ((paymentDue)=> {
      if (this.get('paymentProcessedController.paymentType') == paymentDue.paymentType && this.get('paymentProcessedController.paymentId') == paymentDue.get('customPayment.id')) {
        this.set('paymentDue', paymentDue);
      }
    })

  }
});
