import DS from 'ember-data';

export default DS.Model.extend({

  name: DS.attr('string'),
  termsAndConditionsUrl: DS.attr('string'),

  bankAccountsJson: DS.attr(),
  meta: DS.attr(),
  registeredOffice: DS.attr()

});
