import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
  

export default Component.extend({
  tagName: '',
  tripService: service('trip'),
  actions: {
    openDatePicker(e) {
      this.tripService.tripDatePickerInstance.open();
      e.stopPropagation()
    },
  }
});
