import Route from '@ember/routing/route';

import {
  trackEvent
} from 'b5b/utils';

export default Route.extend( {

  setupController(controller, model) {
    this._super(...arguments);
  },
});
