import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  store: service(),

  compute([country], namedArgs) {
    return this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: country.id}]),
    }).then((regions)=> {
      return regions;
    })
  }

});
