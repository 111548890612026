import Route from '@ember/routing/route';

export default Route.extend({

  setupController() {
    if (typeof Fastboot !== 'undefined') {
      return;
    }
    localStorage.clear()
    this.transitionTo('index').then(() => {
      location.reload()
    })
  }

});
