import Mixin from '@ember/object/mixin';

export default Mixin.create({

  queryParams: {
    swlat: {
      replace: true
    },
    swlng: {
      replace: true
    },
    nelat: {
      replace: true
    },
    nelng: {
      replace: true
    },
    zoom: {
      replace: true
    }
  }

});
