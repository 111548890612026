import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';

export function passengerNumbers([itinerary]) {

  let passengers = '';

  if (itinerary.get('numAdults') > 0) {
    passengers += `<span class='no-wrap'>${itinerary.get('numAdults')} Adult${itinerary.get('numAdults') > 1 ? 's':''}</span>`;
  }
  if (itinerary.get('numYoungAdults') > 0) {
    passengers += `, <span class='no-wrap'>${itinerary.get('numYoungAdults')} Young adult${itinerary.get('numYoungAdults') > 1 ? 's':''}</span>`;
  }
  if (itinerary.get('numChildren') > 0) {
    passengers += `, <span class='no-wrap'>${itinerary.get('numChildren')} Child${itinerary.get('numChildren') > 1 ? 'ren':''}</span>`;
  }
  if (itinerary.get('numInfants') > 0) {
    passengers += `, <span class='no-wrap'>${itinerary.get('numInfants')} Infant${itinerary.get('numInfants') > 1 ? 's':''}</span>`;
  }

  return htmlSafe(passengers);
}

export default buildHelper(passengerNumbers);
