import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({

  ui: service(),
  actions: {
    changeGuestAgeType(guest, ageType) {
      guest.set('ageType', ageType)
    },
    addGuest() {
      let newGuest = this.store.createRecord('guest');
      newGuest.set('ageType', 'adult');
      newGuest.set('label', `Guest ${this.get('deal.guests.length')+1}`)
      this.get('deal.guests').pushObject(newGuest);
    },

    removeGuest(guest) {
      let blocked = false;
      if (guest.isNew) {
        guest.deleteRecord('guest');
      }
      else {
        this.ui.showGeneralMessage('Oops!', 'We need to check if guests exist on the trip');
      }
    },
  }
});
