import DS from 'ember-data';

export default DS.Model.extend({

  totalReviews: DS.attr(),
  totalReviewsFiveStar: DS.attr(),
  totalReviewsFourStar: DS.attr(),
  totalReviewsThreeStar: DS.attr(),
  totalReviewsTwoStar: DS.attr(),
  totalReviewsOneStar: DS.attr(),

  serviceRating: DS.attr(),
  productRating: DS.attr(),

  feefoServiceReviews: DS.hasMany('feefoServiceReview')
  // feefoTripReviews: DS.hasMany('feefoServiceReview', {
  //   inverse: null
  // })
  // feefoProductReviews: DS.hasMany('feefoProductReviw'),

});
