import Route from '@ember/routing/route';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend({

  model() {
    return this.store.queryRecord('page-content', {
      id: 'inspire'
    });
  },

  setupController(controller) {
    this._super(...arguments);
    controller.set('highlightSlides', this.store.peekAll('home').get('firstObject.slider.slides'));
    //This page is not currently used
    controller.set('countries', this.store.peekAll('country').sortBy('name'));
    controller.set('months', this.store.peekAll('month').sortBy('sequence'));
  },

  headTags() {
    const title = `Inspiration from Africa | ${this.whitelabel.agencySeoName}`;
    var description = 'Here’s everything you need to know to start planning your trip.';

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = 'noindex, nofollow';
    return generateMeta({
      description,
      'og:title': title,
      link: 'inspire',
      robots
    });
  }

});
