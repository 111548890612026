import DS from 'ember-data';

export default DS.Model.extend({
  sequence: DS.attr(),
  destinationType: DS.attr(),
  region: DS.belongsTo('region', {
    async: false
  }),
  country: DS.belongsTo('country', {
    async: false
  }),
  destinationList: DS.belongsTo('destinationList')
});
