import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  isEmpty
} from '@ember/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  and,
  alias,
  or
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';

export default Component.extend({
  classNames: ['route-summary-map'],

  cache: service(),
  router: service(),
  ui: service(),
  screen: service(),
  tripService: service('trip'),

  @or('tripService.tripPreviewModal.trip', 'tripService.currentTrip') trip: null,
  @alias('trip.itinerary.nonTransferStages') stages: null,

  isInsertedToDom: false,
  parentElSelector: null,

  screenHeight: false,
  heightOffset: 0,

  bottomBarPopups: false,

  height: null, // this overrides dynamic height changes

  mapOnlyView: null,
  _mapOnlyView: null,

  countriesLoaded: false,
  countries: null,

  // @gte('bounds.length', 2) hasBounds: null,
  // @and('screen.initialized', 'isInsertedToDom', 'hasBounds') isMapReady: false,
  @and('screen.initialized', 'isInsertedToDom', 'countriesLoaded', 'bounds') isMapReady: false,


  didInsertElement() {
    this._super(...arguments);
    this.setHeightForMap();

    // We need countries to ensure we can show correct map when the trip is empty
    this.cache.getAllPublishedCountriesPromise().then((countries)=> {
      this.set('countriesLoaded', true);
      this.set('countries', countries);
    })
  },

  setHeightForMap() {
    let $el = this.$();

    if (this.get('height')) {
      $el.height(this.get('height'));
      return;
    }

    if (this.get('screenHeight')) {
      $el.height(this.get('screen.height') - this.get('heightOffset'));
      return;
    }

    let parentElSelector = this.get('parentElSelector');
    let parent = parentElSelector ? $el.closest(parentElSelector) : $el.parent();
    $el.height(parent.outerHeight());
  },

  // didReceiveAttrs() {
  didUpdateAttrs() {
    this._super(...arguments);
    this.setHeightForMap();

    if (this._mapOnlyView !== this.mapOnlyView){
      // Need to rerender map when expanding otherwise it leaves a grey gap
      this.set('isInsertedToDom', false);
      this.insertTimer = run.next(() => {
        this.set('isInsertedToDom', true);
      })
      this.set('_mapOnlyView', this.mapOnlyView);
    }

  },

  afterRenderEvent() {
    if (this.get('screen.isMobileOnly')) {
      return this.insertTimer = run.later(() => {
        this.set('isInsertedToDom', true);
      }, 300)
    }
    this.set('isInsertedToDom', true);
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.insertTimer);
  },

  @computed('stages.@each.entity')
  allStagesHaveEntities(stages) {
    let check = stages.get('length') ? true : false;

    stages.forEach((stage) => {
      if (isEmpty(stage.get('entity'))) {
        check = false;
      }
    });

    return check;
  },

  @computed('stages.@each.entity')
  bounds(stages) {

    // in this case we only want to show the stages if there is atleast 1, excluding the start and end
    if (stages.get('length') == 0) {
      var bounds = this.countries.reduce(function(result, country) {
        if (country.get('latitudeWithFallback') && country.get('longitudeWithFallback')) {
          let lat = country.get('latitudeWithFallback'),
          lng = country.get('longitudeWithFallback');
          result.push([lat, lng]);
        }
        return result;
      }, []);

      return bounds;
    }

    stages = stages.rejectBy('stageType', 'add-on');

    var bounds = stages.reduce(function(result, stage) {
      if (stage.entity && stage.get('entity.latitudeWithFallback') && stage.get('entity.longitudeWithFallback')) {
        let lat = stage.get('entity.latitudeWithFallback'),
        lng = stage.get('entity.longitudeWithFallback');
        result.push([lat, lng]);
      }
      return result;
    }, []);

    if (bounds.get('length') == 1) {
      // Its crazy but true. Ian 30/12/2016. This is needed because otherwise the bounds calculation blows up on initial page load if there is only a single lodge in the result set.
      // The bounds seems fine if you change fitlers so there is a single lodge but refresh and everythign falls over!
      var lat = parseFloat(bounds[0][0]);
      var lng = parseFloat(bounds[0][1]);
      bounds.pushObject([(lat - 0.25).toString(), (lng - 0.25).toString()])
      bounds.pushObject([(lat + 0.25).toString(), (lng + 0.25).toString()])
    }

    return bounds;

  },

  @computed('trip.itinerary.nonTransferStages.@each.entity')
  polyLineLocations(stages) {
    var lines = [];
    stages = stages.rejectBy('stageType', 'add-on');

    if (!stages){
      return [];
    }

    var stageWithMapLocations = stages.reduce(function(result, stage) {
      if (stage.mapLocation) {
        result.push(stage);
      }
      return result;
    }, []);

    for (var i = 0; i < stageWithMapLocations.length - 1; i += 1) {

      if (!stageWithMapLocations.objectAt(i).get('mapLocation')) {
        return;
      }

      lines.push(
        [stageWithMapLocations.objectAt(i).get('mapLocation'), stageWithMapLocations.objectAt(i + 1).get('mapLocation')]
      );
    }
    return lines;
  },

  actions: {
    hideMarker(stage, marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      // cant run this if using a specified trip as it bypasses the currentStageIndex logic
      if (!this.get('tripService.tripPreviewModal.trip') && (stage.absoluteIndex !== this.get('tripService.currentStageIndex'))) {
        this.set('tripService.currentStageIndex', stage.absoluteIndex);
        this.get('tripService').focusStage();
      }

      marker.target.setOpacity(0);
    },

    showMarker(stage, marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      // cant run this if using a specified trip as it bypasses the currentStageIndex logic
      if (this.tripService.currentStage && !this.get('tripService.tripPreviewModal.trip') && (stage.absoluteIndex === this.get('tripService.currentStageIndex'))) {
        this.set('tripService.currentStage.stageExpanded', false);
      }

      marker.target.setOpacity(1);
    },

    closeMapModal() {
      if (this.get('mapOnlyView')) {
        this.set('mapOnlyView', null);
      } else {
        this.set('showMapModal', null);
      }
    }
  }

});
