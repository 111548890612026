import DS from 'ember-data';

import {
  memberAction
} from 'ember-api-actions';


export default DS.Model.extend({

  currency: DS.attr(),
  amount: DS.attr(),
  charge: DS.belongsTo('charge'),

  commissionReported: memberAction({
    path: 'commission-reported'
  })

});
