import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';

// THis mixin is intended for the login and join route and contains the common functionality to allow them to handle whena  user
// is redirected from an authenticated route to the login/register area
export default Mixin.create({

  ui: service(),
  session: service(),

  beforeModel(transition) {
    this._super(...arguments);
    
    return this.get('session.currentUserPromise').then(() => {
      if (this.whitelabel.isOnboardingAgency) {
        transition.abort();
        return this.set('ui.showWaybirdSignupModal', true)
      }
    })
  },

  actions: {

    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);

      // If you leave the login or register area, we want to ensure that we clear the session.attemptedTransition
      // unless you are actually making the transition to your original attempted transition or naivagting within the register/login area

      let attemptedTransition = this.get('session.attemptedTransition');
      if (attemptedTransition && attemptedTransition.targetName != transition.targetName) {
        // Basicaly at this stage you're transitioning but not making the attempted transition that caused you to be redirected to the auth area
        // So lets check if you are navigating with the login/register area
        if (['login', 'join'].indexOf(transition.targetName) == -1) {
          // you are leaving the login / register area, and you're not doing the attempted then clear it cause it can cause issues if you authenticate later
          console.log('clearing attempting transition')
          this.set('session.attemptedTransition', null);
        }

      }
      // return true so willTransition bubbles
      return true;

    }
  }

});
