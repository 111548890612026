import EditWidgetComponent from 'b5b/components/t-widget/edit/component';
import {
  inject as service
} from '@ember/service';

export default EditWidgetComponent.extend({
  classNames: ['bottom-bar'],
  dateFormat: "D MMM",
  ui: service(),

  didInsertElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', true);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('ui.bottomBarExists', false);
  }

});