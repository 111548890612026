import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  uniqBy
} from 'b5b/utils';
import RSVP from 'rsvp';
import DS from 'ember-data';
const {
  PromiseArray
} = DS;


export default Component.extend({

  tagName: '',
  entityModals: service(),
  tripService: service('trip'),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') model: null,

  @computed('model')
  heading(model) {
    let title = 'When to go';
    switch (model.get('_internalModel.modelName')) {
      case 'country':
      case 'region':
        return title += ' to ' + model.get('shortName');
      case 'lodge':
        return title += ' to ' + model.get('region.shortName');
    }
    return title;
  },

  @computed('model')
  destination(model) {
    switch (model.get('_internalModel.modelName')) {
      case 'country':
      case 'region':
        return model;
      case 'lodge':
        return model.get('region');
    }
  },

  @computed('model')
  whenToGoDestinations(model) {
    if (model.get('_internalModel.modelName') === 'trip') {
      let uniqueRegions = model.get('uniqueRegions');
      let promise = RSVP.all(uniqueRegions.mapBy('destinationInfo'))
        .then(function() {
          return RSVP.all(uniqueRegions.mapBy('country.destinationInfo'))
        }).then(function() {
          return uniqBy(uniqueRegions, 'name').concat(uniqueRegions.mapBy('country').uniq())
            .filterBy('destinationInfo.seasonalRating.length')
        });
      return PromiseArray.create({
        promise
      });
    }
  }

});