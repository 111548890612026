import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  equal
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNameBindings: [':stage-whats-included', 'grey'],
  tripService: service('trip'),
  ui: service(),

  @computed('stage.regionProxy.category')
  category(stageEntityCategory) {
    return stageEntityCategory || 'Safari';
  },

  @equal('category', 'Beach') isBeach: false,
  @equal('category', 'Retreat') isRetreat: false,
  @equal('category', 'City') isCity: false,
  @equal('category', 'Safari') isSafari: false

});
