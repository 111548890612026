import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  tagName: 'svg',
  classNameBindings: [':svg-icon', 'svgIconClassName'],

  @computed('iconName')
  svgIconClassName(iconName) {
    return `svg-${iconName}-dims`;
  }

});