import {
  helper as buildHelper
} from '@ember/component/helper';

export function tripLink(params, namedArgs) {
  let trip = namedArgs.trip;
  let tripSummary = namedArgs.tripSummary;
  if (trip) {
    return `https://${trip.managingAgency.domain}/trip/${trip.friendlyId}`
  } else if (tripSummary) {
    return `https://${namedArgs.agency.domain}/trip/${tripSummary.get('tripFriendlyId')}`
  }
  
}

export default buildHelper(tripLink);
