import Component from '@ember/component';
import {
  COUNTRIES,
  PAYGENIUS_CURRENCIES,
  USA_STATES
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({

  @computed()
  sourceCountries() {
    return COUNTRIES;
  },

  @computed()
  sourceStates() {
    return USA_STATES;
  },

  regions: null,

  @computed('deal.sourceCountry', 'deal.settlementCurrency')
  currencyMismatch(sourceCountry, settlementCurrency) {
    let sourceCountryDefinition = COUNTRIES.findBy('code', sourceCountry);
    if (sourceCountryDefinition && settlementCurrency) {
      if (sourceCountryDefinition.defaultCurrency && sourceCountryDefinition.defaultCurrency != settlementCurrency) {
        return `A source country of ${sourceCountryDefinition.name} typically has a settlement currency of ${sourceCountryDefinition.defaultCurrency}, however the settlement currency is currently ${settlementCurrency}. Are you sure this is correct?`
      }
    }
  },

  actions: {
    setSourceCountry(country) {
      this.set('deal.sourceCountry', country.code);
      let sourceCountryMeta = COUNTRIES.findBy('code', this.deal.sourceCountry);
      this.set('deal.settlementCurrency', sourceCountryMeta.defaultCurrency);
    },

    setSourceState(state) {
      this.set('deal.sourceState', state.code);
    }
  }

});
