import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';

export default Route.extend({

  ui: service(),

  setupController(controller) {
    this._super(...arguments);
    let agency = this.modelFor('waybird.account');
    controller.set('email', agency.get('generalEmail'));
    controller.set('agency', agency)
    if (agency.get('agentRegistered')) {      
      window.location="https://"+agency.get('domain')+"/join"   
    }
  }
});
