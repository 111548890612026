import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';

export default DS.Model.extend(HasKodakImage, {
  title: DS.attr(),
  tagline: DS.attr(),
  wildlife: DS.belongsTo('wildlife', {
    async: false
  }),
  experience: DS.belongsTo('experience', {
    async: false
  }),
  country: DS.belongsTo('country', {
    async: false
  }),
  countryInfo: DS.belongsTo('countryInfo', {
    async: false
  })

});