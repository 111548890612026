import {
  helper as buildHelper
} from '@ember/component/helper';

import {
  capitalizeFirstLetter
} from 'b5b/utils';

export function uppercaseFirstLetter([toCapitalise] /*, hash*/ ) {
  if (toCapitalise) {
    return capitalizeFirstLetter(toCapitalise);
  }
}

export default buildHelper(uppercaseFirstLetter);
