import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';

export default Route.extend({

  store: service(),
  cache: service(),

  model() {
    return this.cache.getAllPublishedCountriesPromise()
  },

  setupController(controller) {
    this._super(...arguments);

    controller.set('countries', this.cache.getAllPublishedCountries());
    controller.set('countriesOpen', this.controller.countries.filterBy('isBordersOpen', true).filterBy('isQuarantineRequired', false).sortBy('name'));
    controller.set('countriesOpenWithQuarantine', this.controller.countries.filterBy('isBordersOpen', true).filterBy('isQuarantineRequired', true).sortBy('name'));
    controller.set('countriesClosed', this.controller.countries.filterBy('isBordersOpen', false).sortBy('name'));
  }
});
