import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['addon-card-full'],

  tripService: service('trip'),
  addOn: null

});
