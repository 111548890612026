
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import {
  generateMeta,
} from 'b5b/utils';


export default Route.extend({

  ui: service(),
  session: service(),
  templateManager: service(),
  tripService: service('trip'),

  setupController: function() {
    this._super(...arguments);

    let links = [{
      name: 'Countries',
      image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
      params: {
        routeName: 'library.countries'
      }
    },{
      name: 'Regions',
      image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
      params: {
        routeName: 'library.regions'
      }
    },{
      name: 'Properties',
      image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
      params: {
        routeName: 'library.lodges'
      }
    },{
      name: 'Experiences',
      image: 'https://waybird.imgix.net/assets/images/peep/experiences.JPG',
      params: {
        routeName: 'library.experiences'
      }
    },

    // {
    //   name: 'Templates',
    //   image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
    //   params: {
    //     routeName: 'library.templates'
    //   }
    // }
  ];

  // We do this so that the display of schedules in library is not linked to current trip
  // if we were to use the library when changing entities then we would need to only clear currentTrip
  // if we are not busy swopping an entity on a trip

  this.set('templateManager.useFakeTrip', true)
  this.set('tripService.currentTrip', null)

    this.get('session.currentUserPromise').then((currentUser) => {
      if (currentUser && currentUser.get('isManager')) {
        this.get('ui').setProperties({
          entityNavData: {
            title: 'Library',
            titleLink: {
              routeName: 'library'
            },
            links: links
          }
        });
      }
    })
  },

  headTags() {

    const title = `Library | ${this.whitelabel.agencySeoName}`;

    let robots = 'noindex, nofollow';
    if (!this.isFastBoot) {
      document.title = title;
    }

    return generateMeta({
      robots,
    });
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    // reseting scroll when destination changes
    if (isExiting) {
      this.set('ui.entityNavData', null);
    } else {
      // causing issues on mobile when transitioning from mapView to experience - disabling for now
      // this.get('scroll').to(0, this);
    }


  }
});
