import {
  scheduleOnce
} from '@ember/runloop';
import Component from '@ember/component';
import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
// import Enumerable from '/ember-runtime/mixins/enumerable';

let App;

// we can use this to suppress all warnings and deprecations as seen on
// https://stackoverflow.com/questions/19435115/how-to-disable-deprecation-warnings-in-ember-js/35366612#35366612
// [William @ 2018-11-15]
// Ember.deprecate = function(){};
// Ember.warn = function(){};

App = Application.extend({
  // LOG_TRANSITIONS: true,
  modulePrefix: config.modulePrefix,
  customEvents: {
    // touchstart: null,
    touchmove: null,
    touchcancel: null,
    touchend: null,

    mousedown: null,
    // mouseenter: null,
    // mouseleave: null,
    mousemove: null,
    mouseup: null,
    mousewheel: null,
    mouseout: null,

    // keyup: null,
    keydown: null,
    keypress: null,

    // drag: null,
    // dragend: null,
    // dragenter: null,
    // dragleave: null,
    // dragover: null,
    // dragstart: null,
    // drop: null,

    dblclick: null
  },
  Resolver
});

Component.reopen({
  didInsertElement() {
    this._super(...arguments);
    scheduleOnce('afterRender', this, this.afterRenderEvent);
  },
  afterRenderEvent() {
    this._super(...arguments);
    // use this for logic that requires rendering first
  }
});

loadInitializers(App, config.modulePrefix);

export default App;
