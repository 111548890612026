import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  htmlSafe
} from '@ember/string';

import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['loading-screen'],

  attributeBindings: ['style'],

  screen: service(),
  @alias('screen.height') screenHeight: null,

  @computed('screenHeight')
  style(screenHeight) {
    // min navbar height - 54px
    return htmlSafe('height:' + (screenHeight - 54) + 'px;');
  }

});