import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  filterBy,
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent,
  lodgeStyles,
  generateReplaceImagesForEntity,
  resolveUrl,
  TRACKING_DOMAINS
} from 'b5b/utils';
import RSVP from 'rsvp';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';
import sortBy from '../../helpers/sort-by';
import ImageMeta from "b5b/models/image-meta";
import EmberObject from '@ember/object';
import File from 'ember-file-upload/file';

const ImportableImage = EmberObject.extend({
  available: null,
  selected: null,
  src: null,
  resolved: false,
  lowRes: false
});

// NOTE:
// We needed to isolate this logic to a component as its still used in a model in the context of trip.edit.
// Once those cases are removed we can just move this all back to stage.own-arrangement

export default Component.extend({

  classNames: ['edit-entity-modal'],

  router: service(),
  store: service(),
  cache: service(),
  ui: service(),
  tripService: service('trip'),
  fileQueue: service('file-queue'), 
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.editEntityModalOptions') editEntityModalOptions: null,
  @alias('tripService.savingCustomEntity') savingCustomEntity: false,

  selectedCountry: null,
  selectedRegion: null,
  numberOfRooms: null,
  coverStyles: ['left top', 'left center', 'left bottom' ,'right top', 'right center', 'right bottom', 'center top', 'center center', 'center bottom'],

  countries: null,

  errors: null,

  onClose: null, // closure for modal

  backupLodge: null, // used when we need to revert the stages lodge
  entity: null,
  editEntityStage: null,
  dataReady: false,
  importableImages: [],
  havePermissionToImport: false,

  didInsertElement() {
    this._super(...arguments);

    this.setProperties({
      entity: this.editEntityModalOptions.entity,
      editEntityStage: this.editEntityModalOptions.stage,
      entityType: this.editEntityModalOptions.entityType,

    });

    if (this.entity.isNew && !(this.entity.latitude) && !(this.entity.longitude)) {
      this.set('tripService.editEntityModalOptions.chooseGpsOnMap', true);
    }

    this.set('dataReadyPromise', this.cache.getAllCountriesPromise()).then((countries)=>{

      this.set('countries', countries);

      if (this.get('entity.country') && this.countries.indexOf(this.get('entity.country'))===-1) {
        this.countries.pushObject(this.get('entity.country'));
        this.set('countries', this.countries.sortBy('name'));
      }

    })


    this.get('dataReadyPromise').then(()=>{
      this.set('dataReady', true)
      // if stage has lodge and we are going to replace it with new custom lodge then grab some existing values
      if (this.get('entity.isNew') && this.get('editEntityStage.lodge')) {
        this.set('backupLodge', this.get('editEntityStage.lodge'));
        this.set('entity.region', this.get('editEntityStage.entity.region'))
      }
      this.set('entity.destinationName', this.get('editEntityStage.entity.destinationName'))      

      if (this.get('entity.region')) {
        this.setRegion(this.get('entity.region'))
      } else if (this.get('entity.country')) {
        //Some lodges have destination name and country
        this.setCountry(this.get('entity.country'))
      }

      // if (!this.selectedCountry) {
      //   // this is basically for new lodges, with no region or country
      //   this.set('selectedCountry', this. countries[0])
      // }

    })
  },

  @computed()
  lodgeStyles() {
    return lodgeStyles();
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

  @computed('importableImages.@each.available', 'importableImages.@each.selected')
  imagesToImport(importableImages) {
    
    let images = importableImages.filter((importableImage)=> {
      return importableImage.available && importableImage.selected
    });    
    return images;
  },

  @computed('importableImages.@each.available')
  imagesAvailableToImport(importableImages) {
    
    let images = importableImages.filter((importableImage)=> {
      return importableImage.available
    });    
    return images;
  }, 

  @computed('imagesAvailableToImport.@each.lowRes')
  lowResImages(imagesAvailableToImport) {
    
    let images = imagesAvailableToImport.filter((importableImage)=> {
      return importableImage.lowRes == true;
    });    
    return images;
  },   

  @computed('importableImages.@each.resolved')
  allImagesResolved(importableImages) {
    
    let images = importableImages.filter((importableImage)=> {
      return importableImage.resolved
    });    
    return images.length==importableImages.length;
  }, 
  
  willDestroyElement() {
    this._super(...arguments);

    // revert lodge if we exit the modal without saving
    if (!this.get('savingCustomEntity') && this.editEntityStage && this.backupLodge) {
      this.set('editEntityStage.lodge', this.get('backupLodge'));
    }

    this.setProperties({
      selectedCountry: null,
      selectedRegion: null,
      currentStageIndex: null,
      savingCustomEntity: false
    });
    // this.tripService.setProperties({
    //   newStage: null
    // })
  },
  setCountry(country) {
    this.set('selectedCountry', country);
    this.set('selectedRegion', null);
    this.get('entity').setProperties({
      region: null,
      country: country
    });
    if (country.get('regions.length')==0) {
      this.send('enableDestinationName');
    }
  },
  setRegion(region) {
    this.set('selectedRegion', region);
    this.set('entity.region', region);
    if (region) {
      this.set('entity.destinationName', null);
      this.set('showDestinationName', false);
      this.set('selectedCountry', region.get('country'));
      this.set('entity.country', region.get('country'));
    }
  },
  setSequenceOnAllReplaceImages(entity) {
    entity.get('replaceImages.images').forEach((metaImage, index)=> {
      metaImage.set('sequence',index);
    })
  },

  stopImportingAndStartManagingPhotos() {
    if (this.get('xhrInProgress')) {
      this.get('xhrInProgress').abort();
      this.set('xhrInProgress', null);
    }   
    this.set('importableImages', [])   
    this.set('tripService.editEntityModalOptions.managePhotos', true)
    this.set('tripService.editEntityModalOptions.importImages', false)
  },

  actions: {
    chooseKodak() {
      this.set('choosingKodak', true);
    },
    chooseImageAsKodak(imageMeta) {
      this.get('entity.replaceImages.images').forEach((imageMeta)=> {
        Ember.set(imageMeta,'use_as_kodak_image', false)
      })
      Ember.set(imageMeta,'use_as_kodak_image', true);
      this.set('entity.metaImageUsedAsKodak', imageMeta);
      this.set('choosingKodak', false);
    },
    selectCountry(country) {
      this.setCountry(country)
    },
    afterGpsFinderUpdateEntity() {
      this.set('tripService.editEntityModalOptions.chooseGpsOnMap', false);
      this.set('tripService.editEntityModalOptions.updateExistingGpsLocation', false);  
    },

    selectRegion(region) {
      this.setRegion(region);
    },
    onCancelClick() {
      this.set('tripService.editEntityModalOptions', false);
    },

    updateLodgeLocation() {
      this.set('tripService.editEntityModalOptions.updateExistingGpsLocation', true)
      this.set('tripService.editEntityModalOptions.chooseGpsOnMap', true)
    },
    enableDestinationName() {
      this.set('showDestinationName', true);
      this.setRegion(null);
    },
    manageImages() {
      if (!this.get('entity.replaceImages')) {
        generateReplaceImagesForEntity(this.entity);
      }
      this.set('entity.replaceImages.timestamp', Date.now())
      this.set('tripService.editEntityModalOptions.managePhotos', true)
    },
    importImagesFromWebsite() {
      this.set('tripService.editEntityModalOptions.managePhotos', false)
      this.set('tripService.editEntityModalOptions.importImages', true)
    },
    cancelImport() {
      this.stopImportingAndStartManagingPhotos();
    },
    sortEndActionEntityImages(entity) {
      this.setSequenceOnAllReplaceImages(entity);
    },
    disableImage(metaImage) {
      if (metaImage.use_as_kodak_image) {
        this.ui.showGeneralMessage('Oops!', 'This image is currently used as the main cover image photo. Please choose a different a main cover image before trying to remove this image.');
      } else {
        metaImage.set('enabled',false);
      }      
    },
    uploadImage: function (file) {
            
      let url = this.whitelabel.getFullHostUrl('/api/v1/upload_requests/signed_url');
      let data = {name: file.blob.name, type: file.blob.type};
      this.set('photosUploading', true)

      RSVP.cast($.ajax({
        url,
        type: "POST",
        data: `utf8=%E2%9C%93&data%5D=${JSON.stringify(data)}`,
        context: this
      })).then(function (response) {
        return file.upload(response.url, {
          data: response.credentials
        });
      }).then(function (response) {
        let newImage = Ember.Object.create({
          location: response.headers.location,
          type: 'new',
          enabled: true
        })
        if (this.fileQueue.find('entityPhotos').get('files.length')==0) {
          this.set('photosUploading', false)
        }
        if (this.get('entity.replaceImages.images.length')==0) {
          newImage.set('use_as_kodak_image', true)
          this.set('entity.metaImageUsedAsKodak', newImage);
        }
        this.get('entity.replaceImages.images').unshiftObject(newImage)
        this.setSequenceOnAllReplaceImages(this.get('entity'));
      }.bind(this));
    },

    addImagesToCustomEntity() {
      async function fetchBlob(url) {
        const response = await fetch(url);
    
        return response.blob();
      }
      let context = this;
      this.set('showImagePermissionsModal', false)      
      context.imagesToImport.forEach((imageToImport)=> {
          const blob = fetchBlob(imageToImport.src).then((blob)=> {
          const fileToImport = File.fromBlob(blob);
          context.send('uploadImage', fileToImport)
        })    
      })
      this.set('havePermissionToImport', false);
      this.stopImportingAndStartManagingPhotos();       
    },    


    selectAllImagesToImport() {
      this.importableImages.forEach((importable) => {
        if (importable.available) {
          importable.set('selected', true)
        }        
      })

    },
    clearAllImagesToImport() {
      this.importableImages.forEach((importable) => {
        importable.set('selected', false)
      })
    },
    scanImportImagesUrl() {

      // if (this.get('xhrInProgress')) {
      //   this.get('xhrInProgress').abort();
      //   this.set('xhrInProgress', null);
      // }

      if (!this.importImagesUrl) {
        this.ui.showGeneralMessage('Oops!', 'What url would you like to scan?');
        return new RSVP.Promise(function (resolve) {
          resolve(true);
        });
      }

      this.set('importableImages', [])
      this.set('scrapingImages', true);
      let context = this;

      let scrapeUrl = "https://scraper.waybird.workers.dev";

      let importImagesUrl = this.importImagesUrl;

      if ((importImagesUrl.indexOf('//') == 0)) {
        importImagesUrl = "https:" + importImagesUrl;
      }
      if ((importImagesUrl.indexOf('http') != 0)) {
        importImagesUrl = "https://" + importImagesUrl;
      }  

      let xhr = $.ajax({
        url: scrapeUrl,
        type: "GET",
        data: {importImagesUrl},
        context: this
      })
      this.set('xhrInProgress', xhr);
      return xhr.then((response)=> {
        if (!this.tripService.editEntityModalOptions.importImages) {
          return;
        }

        let importableImages = []; 
         let jsonResponse = response instanceof Object ? response : JSON.parse(response);

        //  Since the 202 response header has already been sent from the server. If there is an error, it just send a message that the scrape has failed. The request could also fail in the ajax error handler below if there is maybe a netowkr issue or something
         if (jsonResponse.result && jsonResponse.result=='fail') {
          this.ui.showGeneralMessage('Oops!', 'We were unable to scan this url. Please check that it is valid.');
          context.set('scrapingImages', false)
        }

        if (jsonResponse.body && jsonResponse.body.images) {
          jsonResponse.body.images.forEach((image)=> {   
            if (image.src && !image.src.startsWith('data:')) {
              let imageSrc = resolveUrl(importImagesUrl, image.src)
              let trackingDomain = false;
              TRACKING_DOMAINS.forEach((domain)=> {
                if (imageSrc && imageSrc.indexOf(domain) > 0 && !(importImagesUrl.indexOf(domain)>0)) {
                  trackingDomain=true
                }
              })
              let corsProxyImageSrc = "https://image-proxy.waybird.workers.dev/corsproxy/?imgurl="+imageSrc
              if (imageSrc && !trackingDomain && !importableImages.findBy('src', corsProxyImageSrc)) {
                importableImages.pushObject(ImportableImage.create({available: true, src: corsProxyImageSrc, originalSrc: imageSrc, selected: false}))
              }          
            }       
          }) 
        }
        if (jsonResponse.evaluate_results && jsonResponse.evaluate_results[0]) {
          jsonResponse.evaluate_results[0].forEach((result)=> {
            const imageUrl = result.match(/url\(['"]?([^'"]+)['"]?\)/i);
            if (imageUrl && imageUrl[1] && !imageUrl[1].startsWith('data:')) {              
              let imageSrc = resolveUrl(importImagesUrl, imageUrl[1])
              let corsProxyImageSrc = "https://image-proxy.waybird.workers.dev/corsproxy/?imgurl="+imageSrc
              if (imageSrc && !importableImages.findBy('src', corsProxyImageSrc)) {
                importableImages.pushObject(ImportableImage.create({available: true, src: corsProxyImageSrc, originalSrc: imageSrc, selected: false}))
              }  
            }            
          })
        }
        this.set('scrapingImages', false)
        this.set('importableImages', importableImages)
      }, (error)=> {
        if (error.statusText != 'abort') {
          this.ui.showGeneralMessage('Oops!', 'We were unable to scan this url. For best results please copy and paste the full url from your browser. Once you\'ve confirmed the url, please try again and if you have no success please contact us and let us know the url that isn\'t working, so we can take a look.');
        }        
        context.set('scrapingImages', false)
      })    
    }

  }
});
