import TripStagesComponent from 'b5b/components/trip-stages/component';

export default TripStagesComponent.extend({
  tagName: '',

  focusStage(stage, stageIndex) {

    this.set('tripService.currentStageIndex', stageIndex);
    this.get('tripService').focusStage();

  }
});
