import { helper as buildHelper } from '@ember/component/helper';

export function keysInHash([hash]) {
  if (hash) {
    return Object.keys(hash);
  } else {
    return [];
  }

}

export default buildHelper(keysInHash);