import Route from '@ember/routing/route';
import EntitiesRoutesRouteMixin from 'b5b/mixins/entities/routes/route';
import {
  generateMeta,
} from 'b5b/utils';

export default Route.extend(EntitiesRoutesRouteMixin, {

  selectedModelType: 'selectedLodgeNames',
  entityRouteName: 'lodge',

  headTags() {
    this._super(...arguments);
    let model =  this.modelFor(this.entityRouteName);

    let robots = 'noindex, nofollow'
    let link  = `/region/${model.get('region.friendlyId')}/routes`;

    return generateMeta({
      robots,
      link
    });
  },

});
