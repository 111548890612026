import Component from '@ember/component';
import {
    serializeDate
} from 'b5b/transforms/b5bdate';

export default Component.extend({

    selectedDate: '',

    actions: {
        selecCallbackDate(selectedDate) {
            let date = ''
            this.set('selectedDate', selectedDate);
            if (selectedDate) {
                const today = new Date()
                if (selectedDate == 'Tomorrow') {
                    date = serializeDate(today.setDate(today.getDate() + 1));
                }
                else if (selectedDate == '2 days') {
                    date = serializeDate(today.setDate(today.getDate() + 2));

                }
                else if (selectedDate == '3 days') {
                    date = serializeDate(today.setDate(today.getDate() + 3));

                }
            }
            this.deal.set('callBackDate', date);
        },

    }

});
