import Route from "@ember/routing/route";

export default Route.extend({

  setupController(controller) {
    if (controller.get('trip.itinerary.quote.actualLineItems.length')==0) {
      let a = this.get('store').createRecord('actualLineItem', {
        currency: 'usd',
        originalRateType: 'net',
        forceCardOpen: true,
        useTimbuktuAutoMarkup: true
      });

      controller.get('trip.itinerary.quote.actualLineItems').pushObject(a);      
    } else if (controller.get('trip.itinerary.quote.actualLineItems.length')==1 && !controller.get('trip.itinerary.quote.actualLineItems.firstObject.originalPerGuest')) {
      controller.set('trip.itinerary.quote.actualLineItems.firstObject.forceCardOpen', true)
    }      
  }

});
