import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  or,
  reads,
  alias
} from 'ember-decorators/object/computed';

import kbTriggerLogic from 'b5b/mixins/kb-trigger-logic';

export default Component.extend(kbTriggerLogic, {

  classNames: ['region-card-simple'],

  entityModals: service(),
  tripService: service('trip'),

  region: null,
  hideCountryName: false,
  openInModal: false, //Passed into region card config

  canSwipe: true,

  @reads('entityModals.onModal') onModal: false,
  @alias('tripService.changingRegion') changingRegion: false,
  @or('onModal', 'openInModal', 'changingRegion') modalOpener: null,


  actions: {
    openModal(region) {
      if (this.get('changingRegion')) {
        this.get('entityModals').addModalPermission('perm-add-region');
      }
      if (this.modalOpener) {
        this.get('entityModals').openModal({
          model: region
        });
      }
    }
  }

});
