import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  isArray
} from '@ember/array';
import {
  htmlSafe
} from '@ember/string';
import {
  computed
} from 'ember-decorators/object';
import {
  on
} from 'ember-decorators/object/evented';

export default Component.extend({

  classNameBindings: [':t-hero', '_rotatable:is-rotatable'],

  attributeBindings: ['style'],

  screen: service(),
  ui: service(),
  whitelabel: service(),

  heroData: null,

  // Overrides
  coverStyle: null,
  fitStyle: 'min',

  blendColor: '000000', // hex
  blendAlpha: 15,
  blendMode: 'normal',

  // Rotatable defaults
  heroStateCache: null,
  rotationDelay: 3500,
  transitionDuration: 1000,
  transitionName: 'fadeOut',

  // click() {
  //   if (this.get('onClick')) {
  //     this.get('onClick')();
  //   }
  // },

  // How will this work?
  // We can make the images absolutely positioned and then set them bottom: 0; etc
  @computed('coverStyle', 'heroData.coverStyle')
  _cropStyle(manualCoverStyle, coverStyle) {
    return manualCoverStyle || coverStyle;
  },

  @computed('heroData')
  _rotatable(heroData) {
    return isArray(heroData);
  },

  @on('didInsertElement')
  setupRotator() {
    if (this.get('_rotatable')) {

      if (this.get('heroStateCache')) {
        this.setProperties({
          _alphaIndex: this.get('heroStateCache._alphaIndex'),
          _betaIndex: this.get('heroStateCache._betaIndex'),
          _rotationIndex: this.get('heroStateCache._rotationIndex'),
          _alphaActive: this.get('heroStateCache._alphaActive'),
          _betaActive: this.get('heroStateCache._betaActive')
        });
      }

      this._runRotationEngine();
    }
  },

  @on('willDestroyElement')
  teardown() {
    if (this.get('_rotatable')) {
      run.cancel(this._engineTimer);
      run.cancel(this._transitionTimer);
      this.setProperties({
        _engineTimer: null,
        _transitionTimer: null
      });

      this.set('heroStateCache', {
        _alphaIndex: this.get('_alphaIndex'),
        _betaIndex: this.get('_betaIndex'),
        _rotationIndex: this.get('_rotationIndex'),
        _alphaActive: this.get('_alphaActive'),
        _betaActive: this.get('_betaActive')
      });
    }
  },

  _rotationIndex: 0,

  _engineTimer: null,
  _transitionTimer: null,

  _prevTimer: null,
  _nextTimer: null,

  _alphaImage: null, // ref
  _betaImage: null, // ref

  _alphaIndex: 0,
  _betaIndex: 1,

  _alphaActive: true,
  _betaActive: false,

  goingBack: false,

  @computed('transitionName', 'transitionDuration')
  _transitionAnimation(transitionName, transitionDuration) {
    let style = '';
    style += 'animation: ' + transitionName + ' ' + transitionDuration + 'ms linear both;';
    style += '-webkit-animation: ' + transitionName + ' ' + transitionDuration + 'ms linear both;';
    style += 'transition-delay: ' + (transitionDuration + 150) + 'ms;';
    style += '-webkit-transition-delay: ' + (transitionDuration + 150) + 'ms;';

    return htmlSafe(style);
  },

  // do check on slow connections
  _notReady() {
    return (!this.get('_alphaImage') || !this.get('_betaImage')) ||
      (this.get('_betaImage.isActive') && !this.get('_alphaImage.isLoaded')) ||
      (this.get('_alphaImage.isActive') && !this.get('_betaImage.isLoaded'));
  },

  _runRotationEngine() {
    this._engineTimer = run.later(() => {
      if (this._notReady()) {
        return this._runRotationEngine();
      }

      // run forward
      this.incrementProperty('_rotationIndex');

      this._runTransition(true);
    }, this.rotationDelay);
  },

  _runTransition(restart = false) {

    if (!restart) {
      run.cancel(this._engineTimer);
      this.set('transitionDuration', 100);
    }

    this.setProperties({
      _alphaActive: !this.get('_alphaActive'),
      _betaActive: !this.get('_betaActive')
    });

    this._transitionTimer = run.later(() => {

      if (this.get('_rotationIndex') >= this.get('heroData.length')) {
        this.set('_rotationIndex', 0);
      }

      if (this.get('_rotationIndex') < 0) {
        this.set('_rotationIndex', this.get('heroData.length') - 1);
      }


      if (this.get('_alphaActive')) {
        this.set('_betaIndex', this.get('_rotationIndex'));
      } else {
        this.set('_alphaIndex', this.get('_rotationIndex'));
      }

      if (restart) {
        this._runRotationEngine();
      }
    }, this.transitionDuration + 150);
  },

  @computed('heroData', '_alphaIndex')
  _alphaPath(heroData, _alphaIndex) {
    return heroData[_alphaIndex].originalUrl;
  },

  @computed('heroData', '_alphaIndex')
  _alphaDescription(heroData, _alphaIndex) {
    return heroData[_alphaIndex].description;
  },

  @computed('heroData', '_alphaIndex')
  _alphaCoverStyle(heroData, _alphaIndex) {
    return heroData[_alphaIndex].coverStyle;
  },

  @computed('heroData', '_betaIndex')
  _betaPath(heroData, _betaIndex) {
    return heroData[_betaIndex].originalUrl;
  },

  @computed('heroData', '_betaIndex')
  _betaDescription(heroData, _betaIndex) {
    return heroData[_betaIndex].description;
  },

  @computed('heroData', '_betaIndex')
  _betaCoverStyle(heroData, _betaIndex) {
    return heroData[_betaIndex].coverStyle;
  },

  @computed('heroData')
  _staticPath(heroData) {
    if (!heroData) {
      return;
    }

    if (typeof heroData == 'string') {
      return heroData;
    }

    // Note that we might have undefined passed in here for guests trips that refer to a template trip that isn't loaded
    if (heroData && heroData.get('kodakOriginalUrl')) {
      return heroData.get('kodakOriginalUrl');
    }
  },

  actions: {
    alphaLoaded(img) {
      if (!this.get('_alphaImage')) {
        this.set('_alphaImage', img);
      }
    },

    betaLoaded(img) {
      if (!this.get('_betaImage')) {
        this.set('_betaImage', img);
      }
    },

    goPrev() {
      this.set('_rotationIndex', this.get('_rotationIndex') - 1);
      this._runTransition();
    },

    goNext() {
      this.incrementProperty('_rotationIndex');
      this._runTransition();
    }
  }

});