import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import { isArray } from '@ember/array';

export default Component.extend({

  router: service(),
  screen: service(),

  entity: null,
  entityTrips: null,

  hideCreate: false,
  ignoreBlogTags: false,

  didReceiveAttrs(){
    let entityType = this.entityType;
    if (!entityType) {
      entityType = isArray(this.entity) ? 'countriesList' : this.get('entity.constructor.modelName');
      this.set('entityType', entityType)
    }

    if (this.entity && this.cachedEntity !== this.entity){
      this.set('cachedEntity', this.entity);
      this.loadEntityTripsTask.perform().then(()=> {
        if (this.entityType=='blogTags' && this.entityTrips.length==0) {
          this.set('ignoreBlogTags', true)
          return this.loadEntityTripsTask.perform()
        }
      });
    }
  },

  loadEntityTripsTask: task(function * () {
    let query = {
      per_page: 6,
    };
    let filters = [];

    switch (this.entityType){
      
      case 'region':
        // fall back to country for custom regions
        if (this.get('entity.isCustom')) {
          filters.pushObject({type: 'country', name: this.get('entity.country.name'), id: this.get('entity.country.id')})
        } else {
          filters.pushObject({type: this.entityType, name: this.get('entity.name'), id: this.get('entity.id')})
        }
      case 'experience':        
      case 'lodge':
      case 'country':        
      //could potentially fall back to country here as well for custom entities?
        filters.pushObject({type: this.entityType, name: this.get('entity.name'), id: this.get('entity.id')})        
        break;
      case 'countriesList':
        let countries = this.entity;

        if (countries.length>0) {
          query['orCountryIds'] = countries.mapBy('id');
        }
        break;

      case 'trip':
      case 'route':
        console.log('AAARRGGHHH - Lets rather just pass in the countries list')
        query['selectedCountryNames'] = [this.get('entity.countries.firstObject.name')];
        break;
      case 'blogTags':
        if (!this.ignoreBlogTags) {
          query['selectedBlogTagNames'] = this.get('entity');
        }
        break;


      default:
        console.log('AAARRGGHHH - we are missing an entity type')
        break;
    }
    query.filters = JSON.stringify(filters)
    let resolvedTrips = yield this.get('store').query('tripSummary', query);
    this.set('entityTrips', resolvedTrips);
  }),

  actions:{
    gotoTrips(){
      switch (this.get('entity.constructor.modelName')){
        case 'country':
          this.get('router').transitionTo('country.routes', this.get('entity'));
         break;
        case 'region':
          this.get('router').transitionTo('region.routes', this.get('entity'));
          break;
        case 'experience':
          this.get('router').transitionTo('experience.routes', this.get('entity'));
        case 'lodge':
          this.get('router').transitionTo('lodge.routes', this.get('entity'));

        case 'trip':
        case 'route':
          this.get('router').transitionTo('trips', {
            selectedCountryNames: this.get('entity.countries.firstObject.name')
          });
          break;

        default:
          console.log('AAARRGGHHH - we are missing an entity type')
          this.get('router').transitionTo('trips');
          break;
      }
    }
  }
});
