import { helper } from '@ember/component/helper';
import {
  formatCurrencyHash,
  formatConvertedPrice
} from 'b5b/helpers/format-currency';
import {
  htmlSafe
} from '@ember/string';


export function stageAccomPrice(params, namedArgs) {

  let price = namedArgs.price * namedArgs.numNights * namedArgs.numGuests;

  let totalPrice= formatCurrencyHash({
    val: price,
    fromCurrency: namedArgs.currency,
    toCurrency: namedArgs.currency,
    roundToClosest: 0,
    isText: false,
    truncate: false,
    markPricesUp: true
  });

  return htmlSafe(totalPrice)
}

export default helper(stageAccomPrice);
