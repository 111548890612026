import Controller from '@ember/controller';

import {
  task
} from 'ember-concurrency';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import PrettyQuery from 'b5b/mixins/pretty-query';

export default Controller.extend(PrettyQuery, {

  store: service(),
  scroll: service(),
  feefo: service(),
  xplorer: service('components/x-plorer'),
  starRating: 'all',
  page: 1,
  per_page: 18,
  filters: [],
  additionalFilter: null,

  queryParams: [{
    starRating: {},
    page: {},
    sort: {},
    filters: {}
  }],

  @alias('xplorer.feefoServiceReviews') reviews: null,

  loadReviewsTask: task(function*() {
    let reviews = yield this.get('xplorer').loadRecords('feefoServiceReviews', {skipSettingsRecords: true});
    this.set('reviews', reviews)    
  }).keepLatest(),


  // willDestroyElement() {
  //   this._super(...arguments);
  //   run.cancel(this.loader);
  // }

});
