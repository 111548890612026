import { helper } from '@ember/component/helper';
import {
  shouldShowStageSchedule
} from 'b5b/utils';

// This decided whether to show the stage.schedule to non-managers
export function shouldShowStageScheduleHelper(params, namedArgs) {
  let stage = namedArgs.stage;
  let schedule = namedArgs.schedule;
  let customScheduleOnly = namedArgs.customScheduleOnly;
  return shouldShowStageSchedule(stage, schedule, customScheduleOnly)
}

export default helper(shouldShowStageScheduleHelper);
