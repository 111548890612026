import staticStageComponent from 'b5b/components/trip-stages/static/stage/component';
import {
  nextNonTransferStage
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';

export default staticStageComponent.extend({
  classNames: ['transfer'],

});
