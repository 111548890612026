import {
  loadSvgAsync
} from 'b5b/utils';

export default {
  name: "load-svg-async",

  initialize: function() {

    if (typeof FastBoot !== 'undefined') {
      return;
    }

    if (window && (window.timbuktu.usingIe == false || window.timbuktu.ieVersion >= 10)) {
      // Don't load SVG's for less then IE10 because the app doesnm't work properly on IE9 and I suspect that it is sending the req uest differently
      // so cloudlfare then caches the response to an IE9 request which makes other browser break or visa versa
      loadSvgAsync();
    }
  }

}