import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';
import {
    computed
  } from 'ember-decorators/object';
import {
  isEmpty
} from '@ember/utils';
import {
  getDurationForNumNights
} from 'b5b/utils';

export default Component.extend({
  classNames: ['static-trip-overview'],

  tripService: service('trip'),

  @computed('trip.tripLength')
  duration(tripLength) {
    return getDurationForNumNights(tripLength)
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(stages) {
    return stages.map((stage) => {
    return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapDefaultLatLng(stages) {
    if (isEmpty(stages)) {
      return {
        lat: -4.721974,
        lng: 22.884122
      }
    }
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  },


  actions: {

  }

});
