import Component from '@ember/component';
import {
  trackEvent
} from 'b5b/utils';
import {
  TEAM
} from 'b5b/utils';

export default Component.extend({
  classNames: ['ux-feedback'],

  specialist: TEAM.find(obj => obj.name === "Archie"),
  avatarSize: 56,

  didInsertElement() {
    trackEvent('modal:ux-feedback:show');
  },

  actions: {
    close() {
      trackEvent('modal:ux-feedback:close:overlay');
      if (this.onClose) {
        this.onClose();
      }
    }
  }
});
