import Controller from '@ember/controller';

export default Controller.extend({

  queryParams: [{

    selectedBlogTagNames: {
      as: 'blogTags'
    },
    page: {},
    per_page: {}
  }],

  selectedBlogTagNames: []



});
