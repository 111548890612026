import {
  computed
} from 'ember-decorators/object';
import Component from '@ember/component';

export default Component.extend({

  classNameBindings: [':x-itinerary-stage', 'isComponentForRealStage', 'stageIndexNum'],

  stage: null,
  isComponentForRealStage: true, //Is this component and editable block for a stage where the editable entity is the primary type. ex: lodge/location/region.. but not schedule as that is tacked onto the lodge/region stage. Used for focusStaeg logic

  @computed('stageIndex')
  stageIndexNum(stageIndex) {
    return `stage-index-${this.stageIndex}`
  }

  

});
