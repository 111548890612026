import filterPaneComponent from 'b5b/components/filter-pane/component';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';
import {
  ROUTE_DURATIONS,
  getCategoryExperiencesPromise,
  getDurationForKey
} from 'b5b/utils';
import { isArray } from '@ember/array';
import { getLodgeRelatedExperiencesPromise } from '../../../utils';

export default filterPaneComponent.extend({
  classNames: ['routes'],

  showOnlyWithOffers: false,

  tripTypeAndCategoryExperiences: [],

  init() {
    this._super(...arguments);
    this.set('performingSetupTask', this.get('setupTripRelatedExperiences').perform());
  },

  @computed('selectedTripLengths.[]')
  durationOptions(selectedTripLengths) {
    if (isArray(selectedTripLengths)) {
      return ROUTE_DURATIONS.map((duration) => {
        return {
          active: selectedTripLengths.includes(duration.key),
          key: duration.key,
          name: duration.name
        }
      })

    }
  },

  setupTripRelatedExperiences: task(function*() {
    let catExperiences = yield getCategoryExperiencesPromise(this.get('store'));
    this.set('tripTypeAndCategoryExperiences', catExperiences);
  }),

  actions: {
    
    toggleTripType(model, type) {

      let classic = {id: 210, type: 'experience'}
      let offTheBeatenTrack = {id: 208, type: 'experience'}

      // console.log("toggleTripType", model, type)
      if (model.id==classic.id && this.xplorer.isFilterPresent(offTheBeatenTrack)) {
        this.messageBus.publish('suggestion-removed', this.xplorer.getFilterForModel(offTheBeatenTrack));  
      }

      if (model.id==offTheBeatenTrack.id && this.xplorer.isFilterPresent(classic)) {
        this.messageBus.publish('suggestion-removed', this.xplorer.getFilterForModel(classic));  
      }      

      this.send('toggleFilter', model, type)
    },

    loadTrips() {
      this.loadTripsTask.perform()
    },

    toggleDuration(durationKey) {
      var duration = {
        type: 'duration',
        key: durationKey,
        name: getDurationForKey(durationKey).name
      }
      if (this.get('selectedTripLengths').includes(durationKey)) {
        this.send('removeSuggestion', duration);
      } else {
        this.send('addSuggestion', duration, 'duration', durationKey);
      }
    }
  }

});
