import { helper } from '@ember/component/helper';

export function pathPointsForTripSummary(params, namedArgs) {

  return namedArgs.tripSummary.get('metaStageMapLocations').map((stage) => {
    // Note the inverse lat/lng vs map markers
    return [parseFloat(stage.lat).toFixed(6), parseFloat(stage.lng).toFixed(6)];
  });
}

export default helper(pathPointsForTripSummary);
