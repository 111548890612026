import payFormComponent from 'b5b/components/pay-form/component';

export default payFormComponent.extend({

  chargeType: 'external',

  classNames: ['pay-form'],

  _payButtonReady :true

});



