import {
  inject as service
} from '@ember/service';
import {
  reads
} from '@ember/object/computed';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({

  classNameBindings: [':footer-bar', 'white', 'onScreen', 'forceShow', 'isModalOpen', 'isFastBoot'],

  ui: service(),
  scroll: service(),
  router: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  @alias('scroll.isModalOpen') isModalOpen: false,
  @alias('scroll.isCTABarVisible') onScreen: false,

  @alias('ui.footerBarForceShow') forceShow: true,
  @alias('ui.footerBarWhite') white: false,

  willDestroElement() {
    this._super(...arguments);
    this.setProperties({
      white: false,
      forceShow: true
    })
  },

  actions: {
    openForm(e) {
      // dont open form if opening live-chat
      if (e.target.classList.includes('live-chat')) {
        return false;
      }
      trackEvent('ask-a-question-link:form:open' + (this.get('screen.isMobileOnly') ? ':mobile' : ''));
    }
  }

});
