import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['t-note'],

  tripService: service('trip'),

  note: null,
  _note: null, // interim value

  autofocus: true,
  placeholder: 'Any questions or requests? Add a note here...',

  isEditing: false,

  onDone: null, // closure
  onDelete: null, // closure

  focusTimer: null,

  @computed('note')
  isBlank(note){
    return Ember.isBlank(note);
  },

  didInsertElement(){
    this._super(...arguments);
    // work with interim value
    this.set('_note', this.note);
  },

  willDestroyElement(){
    this._super(...arguments);

    run.cancel(this.focusTimer);
    this.setProperties({
      _note: null,
      isEditing: false
    });
  },

  click(e){
    if (!this.isEditing){
      this.activate();
    }
    // to prevent stage expanding/collapsing
    e.stopPropagation();
  },

  focusOut() {
    this.deactivate();
  },

  deactivate(){
    if (this._note=='') {
      this.set('_note', null);
    }
    if (this.note != this._note) {
      this.get('tripService').madeChanges();
    }
    this.set('note', this._note);
    this.set('isEditing', false);

    if (this.onDone){
      this.onDone();
    }
  },

  activate(){
    this.set('isEditing', true);
    this.focusTimer = run.next(this, function() {
      this.$('textarea').focus();
    });
  },

  actions: {
    done(){
      this.deactivate();
    },

    edit(){
      this.activate();
    },

    delete(){
      this.setProperties({
        note: null,
        _note: null,
        isEditing: false
      })

      if (this.onDelete){
        this.onDelete();
      }
    },

    keyUp(){},
    onFocus(){}
  }


});
