import Helper from '@ember/component/helper';

import {
  isEmpty,
  isPresent
} from '@ember/utils';
import {
  encodePolyline
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';

export default Helper.extend({

  lat: null,
  lng: null,

  markers: null,
  zoom: 2,

  markerSize: 44,
  markerTheme: 'red_shadow',
  noPin: false, // hides marker

  @computed('markerSize')
  yellowPinUrl(markerSize) {
    return 'https://waybird.imgix.net/images/map/marker_yellow.png?w=' + markerSize;
  },

  @computed('markerSize', 'markerTheme')
  pinUrl(markerSize, markerTheme) {
    return 'https://waybird.imgix.net/images/map/marker_' + markerTheme + '.png?w=' + markerSize;
  },

  isReady: true, //This defaults to true but sometimes this component should only render once a promise has resolved. For example a trip itinerary has to be loaded. You can then defer the image tage being instantianted using this property

  pathPoints: null,
  pathStroke: 10,
  pathColor: '3c74b4',
  pathOpacity: 1,
  size: '300',
  accessToken: 'pk.eyJ1IjoidGltYnVrdHV0cmF2ZWwiLCJhIjoiY2poMGw1eHpkMmRkbDJ6bW8xYWZ5OGpjYyJ9.PYvAO7lUJ40SOpGvwmQLqw',

  styleUrl: 'https://api.mapbox.com/styles/v1/timbuktutravel/cjh1jmo6w096x2slgl0dbdb40/static/',

  @computed('pathPoints')
  polylineEncode(pathPoints) {
    if (!pathPoints) {
      return;
    }
    var enc = encodePolyline(pathPoints);
    return encodeURIComponent(enc);
  },

  // sizes: "(min-width: 1147px) 400px, (min-width: 768px) 450px, 400px",

  breakpoints: [320, 480],

  /*
    Used to calculate the height
    width * 0.6666  =  3 / 2 ratio
  */
  ratio: null,
  defaultRatio: 1,

  @computed('markers.[]')
  filteredMarkers(markers) {
    return markers && markers.filter((marker) => {
      if (isNaN(marker[0]) || isNaN(marker[1]) || marker[0] === null || marker[1] === null || marker[0] === undefined || marker[1] === undefined) {
        return;
      }
      return marker;
    });
  },

  @computed('filteredMarkers', 'pinUrl', 'zoom', 'markerTheme', 'noPin')
  markerStrings(filteredMarkers, pinUrl, zoom, markerTheme, noPin) {
    if (!filteredMarkers || noPin) {
      return;
    }

    let markerStrings = '';
    filteredMarkers.forEach((marker, index) => {

      let actualPinUrl = pinUrl;
      if (markerTheme === 'blue' && (index === 0 || index === filteredMarkers.length - 1)) {
        actualPinUrl = this.get('yellowPinUrl');
      }

      markerStrings += 'url-' + encodeURIComponent(actualPinUrl) + '(' + marker[0] + ',' + marker[1] + ')';
      if (filteredMarkers.length - 1 !== index) {
        markerStrings += ',';
      }
    });

    if (filteredMarkers.length === 1) {
      markerStrings += '/' + filteredMarkers + ',' + zoom;
    }

    return markerStrings;
  },

  @computed('polylineEncode', 'pathStroke', 'pathColor', 'pathOpacity')
  polylineStrings(polylineEncode, pathStroke, pathColor, pathOpacity) {
    if (!polylineEncode) {
      return;
    }

    let path = '';
    path += 'path-'
    path += pathStroke ? pathStroke + '+' : '';
    path += pathColor ? pathColor + '-' : '';
    path += pathOpacity ? pathOpacity : '';
    path += polylineEncode ? '(' + polylineEncode + ')' : '';
    return path;
  },

  @computed('filteredMarkers')
  center(filteredMarkers) {
    if (isEmpty(filteredMarkers)) {
      return;
    }

    // cheekily tapping into Leaflet here to get the center point
    let getCenter = L.latLngBounds(filteredMarkers).getCenter(),
      center = getCenter.lat + ',' + getCenter.lng;
    return center;
  },

  @computed('polylineStrings', 'size', 'accessToken', 'markerStrings', 'center', 'zoom', 'pinUrl', 'ratio', 'defaultRatio', 'lat', 'lng', 'styleUrl', 'filteredMarkers')
  src(polylineStrings, size, accessToken, markerStrings, center, zoom, pinUrl, ratio, defaultRatio, lat, lng, styleUrl, filteredMarkers) {

    var url = styleUrl;
    let latLongPresent = lat && lng;

    if (latLongPresent) {
      url += parseFloat(lng).toFixed(6) + ',' + parseFloat(lat).toFixed(6) + ',' + zoom + '/';
      return url + size + 'x' + Math.round(size * (ratio || defaultRatio)) + '@2x?access_token=' + accessToken;
    }

    if (polylineStrings) {
      if (latLongPresent) {
        url += ',';
      }
      url += polylineStrings;
    }

    if (markerStrings) {
      if ((polylineStrings) || (latLongPresent)) {
        url += ',';
      }
      url += markerStrings;
    }

    let latLongPresentViaMarkers = filteredMarkers.length === 1;

    if (latLongPresentViaMarkers) {
      // This is an alternative way of specifying lat and long normally passed in as the mapEntity marker
      url += '/' + filteredMarkers + ',' + zoom;
    }

    if (!latLongPresent && !latLongPresentViaMarkers) {
      url = url + '/auto/';
    } else {
      url = url + '/';
    }

    return url + size + 'x' + Math.round(size * (ratio || defaultRatio)) + '@2x?access_token=' + accessToken;
  },


  @computed('polylineStrings', 'size', 'accessToken', 'markerStrings', 'center', 'zoom', 'pinUrl', 'ratio', 'defaultRatio', 'lat', 'lng', 'styleUrl', 'breakpoints', 'filteredMarkers')
  srcset(polylineStrings, size, accessToken, markerStrings, center, zoom, pinUrl, ratio, defaultRatio, lat, lng, styleUrl, breakpoints, filteredMarkers) {

    if (!breakpoints) {
      return;
    }

    var compositeUrl = '';
    let latLongPresent = lat && lng;

    breakpoints.forEach((breakpoint, index) => {

      let bpString = '';

      if (latLongPresent) {
        bpString += parseFloat(lng).toFixed(6) + ',' + parseFloat(lat).toFixed(6) + ',' + zoom + '/';
        return compositeUrl += styleUrl + bpString + breakpoint + 'x' + Math.round(breakpoint * (ratio || defaultRatio)) + '@2x?access_token=' + accessToken + ' ' + breakpoint + 'w' + (index < breakpoints.length - 1 ? ',' : '');
      }

      if (polylineStrings) {
        if (latLongPresent) {
          bpString += ',';
        }
        bpString += polylineStrings;
      }

      if (markerStrings) {
        if ((polylineStrings) || (latLongPresent)) {
          bpString += ',';
        }
        bpString += markerStrings;
      }
      compositeUrl += styleUrl + bpString;

      let latLongPresentViaMarkers = filteredMarkers.length === 1;

      if (latLongPresentViaMarkers) {
        // This is an alternative way of specifying lat and long normally passed in as the mapEntity marker
        compositeUrl += '/' + filteredMarkers + ',' + zoom;
      }

      if (!latLongPresent && !latLongPresentViaMarkers) {
        compositeUrl = compositeUrl + '/auto/';
      } else {
        compositeUrl = compositeUrl + '/';
      }

      compositeUrl += breakpoint + 'x' + Math.round(breakpoint * (ratio || defaultRatio)) + '@2x?access_token=' + accessToken + ' ' + breakpoint + 'w' + (index < breakpoints.length - 1 ? ',' : '');
    });

    return compositeUrl;
  },

  compute(params, namedArgs) {

    this.setProperties({
      lat: isPresent(namedArgs.lat) ? namedArgs.lat : this.lat,
      lng: isPresent(namedArgs.lng) ? namedArgs.lng : this.lng,
      markers: isPresent(namedArgs.markers) ? namedArgs.markers : this.markers,
      zoom: isPresent(namedArgs.zoom) ? namedArgs.zoom : this.zoom,
      markerSize: isPresent(namedArgs.markerSize) ? namedArgs.markerSize : this.markerSize,
      markerTheme: isPresent(namedArgs.markerTheme) ? namedArgs.markerTheme : this.markerTheme,
      noPin: isPresent(namedArgs.noPin) ? namedArgs.noPin : this.noPin,
      pathPoints: isPresent(namedArgs.pathPoints) ? namedArgs.pathPoints : this.pathPoints,
      pathStroke: isPresent(namedArgs.pathStroke) ? namedArgs.pathStroke : this.pathStroke,
      pathColor: isPresent(namedArgs.pathColor) ? namedArgs.pathColor : this.pathColor,
      pathOpacity: isPresent(namedArgs.pathOpacity) ? namedArgs.pathOpacity : this.pathOpacity,
      size: isPresent(namedArgs.size) ? namedArgs.size : this.size,
      breakpoints: isPresent(namedArgs.breakpoints) ? namedArgs.breakpoints : this.breakpoints,
      ratio: isPresent(namedArgs.ratio) ? namedArgs.ratio : this.ratio
    });

    return this.src;
  }
});
