import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import Ember from 'ember';

export function bulletFormat([val], namedArgs) {

  let string = Ember.Handlebars.Utils.escapeExpression(val);
  string = string.replace(/\r\n?/g, "\n").trim();
  if (string.length > 0) {
    // Convert double new lines into paragaph tags
    string = string.replace(/\n\n+/g, '$@NEW_PARA@$\n');

    let bulletsArray = string.split("\n");
    let formatted='';
    bulletsArray.forEach((bullet) => {
      formatted=formatted+'<li>'+bullet+'</li>'
    })
    formatted = formatted.replace(/\$\@NEW_PARA\@\$/g, '</ul>'+'<br><ul class="bullets-para">');

    return htmlSafe('<ul class="bullets-para">'+ formatted + "</ul>");
  }
  return htmlSafe("");
}

export default buildHelper(bulletFormat);
