import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default Controller.extend({

  tripService: service('trip'),

  @alias('tripService.hasBackButton') hasBackButton: false,
  @alias('tripService.currentTrip') trip: false,
  @alias('tripService.currentStageIndex') stageIndex: false,
  @alias('tripService.dontBook') dontBook: false,
  @alias('tripService.numNights') numNights: false,

  settings: service(),
  session: service(),
  store: service(),
  ui: service(),
  whitelabel: service(),
  entityModals: service(),


  allFeefoProductReviews: null,
  showMapModal: null,

  @computed('lodge')
  mapMarker(lodge) {
    if (!lodge.get('latitudeWithFallback') || !lodge.get('longitudeWithFallback')) {
      return;
    }

    return [
      [parseFloat(lodge.get('longitudeWithFallback')).toFixed(6), parseFloat(lodge.get('latitudeWithFallback')).toFixed(6)]
    ];
  },

  actions: {
    onBackButtonClick() {
      window.history.back();
    },

    fetchAllReviews() {
      let query = {
        lodge_id: this.get('lodge.id')
      };
      this.get('store').query('feefoProductReview', query).then((reviews) => {
        this.set('allFeefoProductReviews', reviews);
      });
    },

    showLighboxIfAvailable() {
      if (this.get('lodge.metaImagesCount') > 1) {
        this.set('showGallery', true);
      }
    },
    openMapModal() {
      this.set('showMapModal', true);
    }
  }

});
