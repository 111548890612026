define("ember-leaflet/templates/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WFvaCrvH",
    "block": "{\"symbols\":[\"components\",\"c\",\"&default\"],\"statements\":[[4,\"let\",[[27,\"hash\",null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"rectangle\",\"wmsTile\",\"geoJSON\"],[[27,\"component\",[\"tile-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"marker-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"circle-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"circle-marker-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"image-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"video-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"polyline-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"polygon-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"rectangle-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[22,0,[]]]]],[27,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[22,0,[]]]]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,0,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[4,\"each\",[[22,0,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"ember-leaflet-assign-to\",[[22,1,[]]],[[\"key\",\"value\",\"onChange\"],[[22,2,[\"as\"]],[27,\"component\",[[22,2,[\"name\"]]],[[\"parentComponent\"],[[22,0,[]]]]],[22,0,[\"mergeComponents\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[14,3,[[22,0,[\"mergedComponents\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,3,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/leaflet-map.hbs"
    }
  });
  _exports.default = _default;
});