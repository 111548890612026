import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default Route.extend({
  
  whitelabel: service(),
  ui: service(),

  beforeModel(transition) {
    if (this.whitelabel.agency.needsOnboarding) {
      transition.abort();
      this.set('ui.showNeedsOnboardingModal', true);
    }   
    if (this.whitelabel.isOnboardingAgency) {
      transition.abort();
      this.set('ui.showWaybirdSignupModal', true)
    }      
  },
})