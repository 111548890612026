import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({

  store: service(),
  tripService: service('trip'),
  whitelabel: service(),
  ui: service(),

  beforeModel(transition) {
    
    if (this.whitelabel.agency.needsOnboarding) {
      transition.abort();
      this.set('ui.showNeedsOnboardingModal', true);
      return
    }

    if (this.whitelabel.isOnboardingAgency) {
      transition.abort();
      this.set('ui.showWaybirdSignupModal', true)
      return
    } 
     
    if (transition.targetName == 'trip.index.edit.pricing.index') {
      this.transitionTo('trip.index.edit.pricing.line-items');
    }
    
  },

  afterModel(model, transition) {
    this._super(...arguments);
    this.tripService.ensureAllPartnersLoaded();
    return this.tripService.allPartnersPromise;
  },


  setupController(controller) {
    this.tripService.ensureTripHasQuote(controller.get('trip'));
  }

});
