import pillSearchComponent from 'b5b/components/pill-search/component';
import {
  inject as service
} from '@ember/service';

export default pillSearchComponent.extend({
  classNames: ['regions'],
  searchType: 'region',
  tripService: service('trip')
});
