import DS from 'ember-data';

export default DS.Model.extend({

  specialOffer: DS.belongsTo('specialOffer'),

  validFrom: DS.attr('b5bdate'),
  validTo: DS.attr('b5bdate'),
  sequence: DS.attr()

});
