import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  afterModel() {
    this._super(...arguments);
    trackEvent('wizard:index:view');
    this.controllerFor('wizard').setStep(0);
  }

});
