import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({

  pathForType: function() {
    return 'trips';
  },

  buildURL: function(modelName, id, snapshot) {
    var url = this._super(...arguments);
    return this.getTripUrl(url, modelName, id, snapshot)
  }


});
