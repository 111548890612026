import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({

  ui:service(),
  tagName: '',

  init() {
    this._super(...arguments);

  },

  didReceiveAttrs() {
    if (this.get('trip.loomVideo.url') && !this.get('trip.loomVideo.url.isDestroyed') && !this.get('trip.loomVideo.url.isDestroying')) {
      this.set('urlHolder', this.get('trip.loomVideo.url'))
    }
  },

  actions: {
    onChangeUrlHolder(event) {
      if (!this.trip.loomVideo || this.get('trip.loomVideo.url.isDestroyed') || this.get('trip.loomVideo.url.isDestroying') ) {
        let loomVideo = this.store.createRecord('video', {videoType: 'loom'})
        this.get('trip.itinerary.videos').pushObject(loomVideo);
        this.set('trip.loomVideo', loomVideo)
      }
      this.set('trip.loomVideo.url', event.target.value)
    }
  }



});
