import { helper } from "@ember/component/helper";

export function userCanEditRegion(params, namedArgs) {
  //Can have unlogged in users
  let isManager = namedArgs.user ? namedArgs.user.isManager : false;
  let userId = namedArgs.user ? namedArgs.user.id : null;

  if (
    namedArgs.region && namedArgs.region.polishLevel=='custom' &&
    namedArgs.region.userOwnerId == userId &&
    isManager
  ) {
    return true;
  }
  return false;
}

export default helper(userCanEditRegion);
