import {
  alias
} from '@ember/object/computed';
import Component from '@ember/component';
import {
  getErrorsHashFromServerResponse
} from 'b5b/utils';

// Basically, two states for the state-button, one nested in a form without onClick, and the other with a explicit onClick
// Event will bubble if no onClick handler. If there is an explicit onClick then the event wont bubble

export default Component.extend({
  classNameBindings: [':state-button', ':btn', 'disabled', 'active'],
  attributeBindings: ['disabled', 'type'],
  tagName: 'button',

  disabled: alias('active'),
  active: false,
  value: null,
  onClick: null, // see below, expects promise
  onError: null,
  valid: true, // default
  onTrigger: null, // simple action fire

  click(e) {
    if (!this.valid) {
      return;
    }

    if (this.onTrigger) {
      return this.onTrigger();
    }

    if (this.onClick) {

      e.stopPropagation();
      e.preventDefault();

      this.set('active', true);
      this.onClick().then((response) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        this.set('active', false);
      }).catch((response) => {
        this.set('active', false);
        let errorsHash = getErrorsHashFromServerResponse(response);
        if (response && response.errors && this.onError) {
          this.onError(errorsHash);
        } else {
          this.set('errors', errorsHash)
        }
      });;
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    this.setProperties({
      active: false,
      value: null,
      onClick: null,
      onError: null
    })
  }

});
