import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  cache: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags() {

    const title = `When to go to Africa | ${this.whitelabel.agencySeoName}`;
    var description = 'A month by month guide to the highlights on the continent';

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = 'index, follow';
    return generateMeta({
      description,
      'og:title': title,
      link: 'when-to-go',
      robots
    });
  },

  beforeModel() {
    this._super(...arguments);
    return this.cache.getAllPublishedCountriesPromise();
  },

  model() {
    return this.store.peekAll('month').sortBy('sequence');
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties({
      months: model
    });
    trackEvent('when-to-go:view');

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      controller.set('firstTimeLoad', true);
    }
  }
});
