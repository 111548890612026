import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  observer
} from '@ember/object';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';
import {
  task
} from 'ember-concurrency';

export default Controller.extend({


  // searchValue: '',
  // bookingState: 'all',
  // page: 1,



  queryParams: [{
    // bookingState: {
    //   as: 'state'
    // },
    // searchValue: {
    //   as: 'q'
    // },
    // sort: {}
  }],

  loadDealsTask: task(function*() {
    let queryAttrs = {
      sort: 'recommended',
      primaryAgentIds: [this.session.currentUser.id],
      buster: true
    };

    return this.store.query('deal', queryAttrs).then((deals)=>{
      this.set('deals', deals)
    })

  }).keepLatest(),

  actions: {

  }
});
