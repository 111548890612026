import Service from '@ember/service';
import {
  computed,
  observes
} from 'ember-decorators/object';

import {
  trackEvent
} from 'b5b/utils';

export default Service.extend({

  closeAllEntityModals: null,
  onModal: null,
  modalHistory: [],

  currentModalIndex: undefined, // default - none open

  @computed('modalHistory.[]', 'currentModalIndex')
  currentModalConfig(modalHistory, currentModalIndex) {
    if (currentModalIndex > -1) {
      return modalHistory.objectAt(currentModalIndex);
    }
  },

  @computed('onModal', 'currentModalIndex')
  isVisible(onModal, currentModalIndex) {
    return onModal && (currentModalIndex > -1)
  },

  @observes('currentModalIndex')
  watchModalIndex() {
    // If the user browses back to index -1, then run through modal closing steps
    if (this.currentModalIndex === -1 || this.currentModalIndex === undefined) {
      this.closeModal();
    }
  },

  openModal(options = { /*modalKey*/ }) {
    $('.t-modal').scrollTop(0);
    this.get('modalHistory').pushObject(options);
    this.set('currentModalIndex', this.get('modalHistory.length') - 1);

    this.setProperties({
      onModal: true
    });
    trackEvent('general-modal:' + options.modalKey + ':open');
  },

  closeModal() {
    this.setProperties({
      onModal: false,
      currentModalIndex: undefined,
      modalHistory: []
    });
  },

  actions: {
    openModal(options) {
      this.openModal(options)
    },

    closeModal() {
      this.closeModal();
    }
  }

});
