import Component from "@ember/component";
import { trackEvent } from "b5b/utils";

export default Component.extend({
  classNameBindings: [":x-dialog-trigger", "toggle:is-open"],

  toggle: null,
  onClick: null,
  trackEvent: null,
  applyHover: null,

  mouseEnter() {
    if (!this.applyHover) return;

    if (this.trackEvent) {
      trackEvent("mouse:enter:" + this.trackEvent);
    }
    this.set("toggle", true);
  },

  mouseLeave() {
    if (!this.applyHover) return;

    this.set("toggle", false);
  },

  click(e) {
    // this is called when you clcik the x-dialog trigget but also when you click entries in the x-dialog
    if (this.trackEvent) {
      trackEvent("link:click:" + this.trackEvent);
    }
    if (this.onClick) {
      this.onClick(e);
    } else {
      this.toggleProperty("toggle");
    }
    if (e.target && e.target.href) {
// If there is a link in the x-dialog then allow the click through
    } else {
      e.stopPropagation();
      return false;
    }
  },
});
