import Helper from '@ember/component/helper';
import {
  A
} from '@ember/array';
import {
  set,
  get,
  observer
} from '@ember/object';

export default Helper.extend({

  arrayLengthObserver: observer('lineItems.@each.perGuest', function() {
    this.recompute();
  }),

  compute([lineItems]) {

    set(this, '_lineItems', lineItems);

    let lossFound = false;
    lineItems.forEach((lineItem)=>{
      let marginAfterTransactionFees = lineItem.marginAfterTransactionFees && parseFloat(lineItem.marginAfterTransactionFees);
      let perGuest = lineItem.perGuest && parseFloat(lineItem.perGuest);
      let originalPerGuest = lineItem.originalPerGuest && parseFloat(lineItem.originalPerGuest);
  
      if ((Math.round(marginAfterTransactionFees * 100) / 100) < 0 || perGuest <= originalPerGuest) {            
        lossFound=true;
      }
    })

    return lossFound;
  }
});
