import DS from 'ember-data';

export default DS.Model.extend({

  countries: DS.hasMany('country', {
    inverse: null
  }),

  areas: DS.hasMany('area', {
    inverse: null
  }),

  regions: DS.hasMany('region', {
    inverse: null
  }),

  experiences: DS.hasMany('experience', {
    inverse: null
  }),

  months: DS.hasMany('month', {
    inverse: null
  }),

  compilation: DS.attr()

});
