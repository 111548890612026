import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

import {
  getCapuredEmail
} from 'b5b/utils';

export default Route.extend({

  session: service(),
  affiliate: service(),

  setupController() {
    this.affiliate.getAffiliateAndCurrentUserPromise().then((hash) => {

      let affiliateId = PostAffTracker.getAffInfo().getAffiliateId();
      let affiliateVisitorId = PostAffTracker.getAffInfo().cookieManager.getVisitorId();
      let email = hash.user ? hash.user.get('email') : '';
      let isTimbuktu = hash.user ? hash.user.get('isTimbuktu') === true : 'NotTimbuktu';

      alert(`Is Timbuktu ${isTimbuktu} - Affiliate Id: ${affiliateId} - Affiliate Visitor Id: ${affiliateVisitorId} - localStorage email: ${getCapuredEmail()} - User email: ${email}`)

    })
  }
});