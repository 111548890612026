import Ember from 'ember';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: ['progress-bar'],

  current: 0,
  total: 0,

  @computed('current', 'total')
  progressWidth(current, total) {
    let width = ((current / total) * 100) + '%';
    return Ember.String.htmlSafe("width: " + width);
  }
});
