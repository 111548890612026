import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import config from 'b5b/config/environment';

export default payFormComponent.extend({

  chargeType: 'pps',

  classNames: ['pay-form'],

  router: service(),
  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  didInsertElement() {
    this._super(...arguments);
    addAsyncScript('https://sales.protectedpaymentservices.com/sdk/web/v1/js/hostedforms.min.js', null, function() {
      this.set('_payButtonReady', true)
    }.bind(this));
  },

  // not great but this sets a few props as side effects of being computed

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return settlementCurrency;
  },

  actions: {

// https://solutions.flywire.com/en/checkout/implementation
// https://solutions.flywire.com/en/checkout/implementation#cookbook
// https://solutions.flywire.com/en/webhooks/notifications-v2
// https://solutions.flywire.com/en/portal-configuration


    openPayModal() {
      // Dont allow guest to pay with historical rates
      if (window.timbuktu.xeDate) {
        this.get('ui').showGeneralMessage('Oops!', 'You are currently using historical exchange rates. Please reload the page with the latest exchange rates before making payment. The url should not include xeDate=xx-xx-xxxx in it.');
        return;
      }

      // check if the terms and conditions have been accepted
      if (!this.get('trip.itinerary.termsAcceptedDateTime') && !this.get('session.currentUser.isManager') && !this.get('trip.isGiftList')) {
        this.get('ui').showGeneralMessage('Oops!', `Please accept ${capitalizeFirstLetter(this.whitelabel.agency.name)}'s terms and conditions before continuing`);
        return;
      }

      // //console.log(`FLYWIRE_${config.APP.flywireEnv.toUpperCase()}_PORTAL_${this.get('paymentDue.settlementCurrency').toUpperCase()}`)
      // let flywirePortal = this.get(`trip.contractingEntity.meta.properties.FLYWIRE_${config.APP.flywireEnv.toUpperCase()}_PORTAL_${this.get('paymentDue.settlementCurrency').toUpperCase()}`);
      // //console.log(this.get(`trip.contractingEntity.meta.properties`))
      // if (!flywirePortal) {
      //   let message = `We've had a problem preparing a ${this.get('paymentDue.settlementCurrency')} payment. Please contact us`;
      //   if (this.whitelabel.contactEmail) {
      //     message = message + ` on ${this.whitelabel.contactEmail}`
      //   }
      //   this.get('ui').showGeneralMessage('Oops!', message);
      //   return;
      // }

      // DEMO CODE FROM PPS
      // var req = {
      //   merchantID: '100001',
      //   action: 'SALE',
      //   type: '1',
      //   currencyCode: '826',
      //   countryCode: '826',
      //   amount: '1001',
      //   orderRef: 'Test purchase',
      //   redirectURL: 'https://www.merchant.com/payment/',
      //   signature: '07599ef4cdb2e26cb2bf34a9c65190a7ce82494bc1df144c3bb0d20ee2655d8278dc663b2b0421ef12b8f081e821151bb4c644277c5d65b5523a96539b53b5aa',
      // };

      // var data = {
      //   id: 'my-payment-form',
      //   url: 'https://gateway.example.com/hosted/modal/',
      //   modal: true,
      //   data: req,
      //   submit: {
      //     type: 'button',
      //     label: 'Pay <i>Now</i>'
      //   }
      // };

      // var form = new window.hostedForms.classes.Form('paynow', data);



      trackEvent('pay-button:click');
console.log(' pay button clicked')
      this.saveChargeToServer().then((charge)=> {

        let amount = this.transactionAmount * 100;

        let currencyCodes = {
          'gbp': '826',
          'eur': '978',
          'usd': '840',
          'zar': '710',
          'aud': '036',
          'cad': '124',
          'nzd': '554',
        }

        let currencyCode = currencyCodes[this.transactionCurrency]
        console.log(amount, currencyCode)

        var req = {
        	action: 'SALE',
        	type: '1',
          currency: this.transactionCurrency,
        	currencyCode,
        	countryCode: '826',
        	amount,
        	orderRef: `${this.trip.id}_${charge.id}`,
          customerEmail: this.get('session.currentUser.email'),
        };
        // var req = {
        //   merchantID: '100001',
        //   action: 'SALE',
        //   type: '1',
        //   currencyCode: '826',
        //   countryCode: '826',
        //   amount: '1001',
        //   orderRef: 'Test purchase',
        //   redirectURL: 'https://www.merchant.com/payment/',
        //   signature: '07599ef4cdb2e26cb2bf34a9c65190a7ce82494bc1df144c3bb0d20ee2655d8278dc663b2b0421ef12b8f081e821151bb4c644277c5d65b5523a96539b53b5aa',
        // };
        console.log(req)

        return charge.getPpsSignature({req}).then((signatureResponse)=> {
          console.log(' pps signature received')
          let ppsReq = signatureResponse.req

          var data = {
            id: 'my-payment-form',
            url: 'https://sales.protectedpaymentservices.com/hosted/modal/',
            modal: true,
            data: ppsReq,
            submit: {
              type: 'auto',
              label: 'Pay <i>Now</i>'
            }
          };

          // if (this.whitelabel.agency.allowBankTransferPayments) {
          //   flywireConfig.paymentOptionsConfig.filters.type.pushObject('bank_transfer')
          // }
          // if (this.whitelabel.agency.allowCreditCardPayments) {
          //   flywireConfig.paymentOptionsConfig.filters.type.pushObject('credit_card')
          // }
          // if (!this.whitelabel.agency.allowBankTransferPayments && !this.whitelabel.agency.allowCreditCardPayments ) {
          //   return this.get('ui').showGeneralMessage('Oops!', 'Please contact your travel specialist. Payments need to be enabled.');
          // }

          let divId = 'pps-form-container-'+this.elementId;
          var form = new window.hostedForms.classes.Form(divId, data);
          console.log(form)
          // $('#'+divId+' button')[0].click()
          console.log(' form button clicked')
        })




      })

    }
  }
});



