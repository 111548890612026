import { helper } from '@ember/component/helper';

export function metaImageSource(params, namedArgs) {
  let metaImage = namedArgs.metaImage;
  if (metaImage.location) {
    // Temporarily uplaoded files that still need to be converted into lodge images in a sidelkiq worker
    let src = metaImage.location;
    if (window.location.host.indexOf('localhost')>=0) {
      src = src.replace('https://static.waybird.com', 'https://dev-assets.timbuktutravel.com')
    }
    return src;
  } else {
    // Previously uplaoded lodge images
    let src = metaImage.originalUrl;
    let width = namedArgs.imageType=='kodak' ? 1420 : 320;
    return src+'?w='+width;
  }


}

export default helper(metaImageSource);
