import { A } from '@ember/array';
import { on } from '@ember/object/evented';
import { isEmpty } from '@ember/utils';
import { run } from '@ember/runloop';
import { observer } from '@ember/object';
import RangeSliderComponent from 'ember-cli-nouislider/components/range-slider';

export default RangeSliderComponent.extend({
  classNames: ['range-slider'],

  setup: on('didInsertElement', function() {
    let $this = this.element;

    let properties = this.getProperties(
      'start', 'step', 'margin',
      'limit', 'range', 'connect',
      'orientation', 'direction',
      'behaviour', 'animate', 'snap',
      'pips', 'format'
    );
    let sliderEvents = A(['change', 'set', 'slide', 'update', 'start', 'end']);

    // This is a fix to not create new instances when they already exist
    if (!this.slider) {
      noUiSlider.create($this, properties);
    }

    let slider = $this.noUiSlider;
    this.set('slider', slider);

    sliderEvents.forEach(event => {
      if (!isEmpty(this.get(`on-${event}`))) {
        slider.on(event, () => {
          run(this, function() {
            let val = this.slider.get();
            this.sendAction(`on-${event}`, val);
          });
        });
      }
    });

    /** DEPRECATED AND WILL BE REMOVED BEFORE 1.0 **/
    slider.on('change', () => {
      run(this, function() {
        let val = this.slider.get();
        this.sendDeprecatedAction("change", val);
      });
    });

    if (!isEmpty(this.slide)) {
      slider.on('slide', () => {
        run(this, function() {
          let val = this.slider.get();
          this.sendDeprecatedAction('slide', val);
        });
      });
    }
  }),

  teardown: on('willDestroyElement', function() {
    var slider = this.slider;

    slider.off('change');
    slider.off('slide');
    slider.off('set');
    slider.off('update');
    slider.off('start');
    slider.off('end');

    slider.destroy();
  }),

  setVal: observer('start', function() {
    let slider = this.slider;

    if (slider) {
      var val = this.start;
      slider.set(val);
    }
  })
});
