import DS from 'ember-data';

export default DS.Model.extend({

  region: DS.belongsTo('region', {
    async: false
  }), 
  
  associatedRegion: DS.belongsTo('region', {
    inverse: null,
    async: false
  }), 
  
});
