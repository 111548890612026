import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  showInViewPort
} from 'b5b/utils';
  
export default Component.extend({

  tripService: service('trip'),
  entityModals: service(),
  router: service(),
  ui: service(),

  classNameBindings: [':stage'],

  focusTimer: null,
  stageExpanded: false,
  mapHover: false,

  @alias('tripService.tripDatePickerInstance') datePickerInstance: null,

  focusStage() {
    this.focusTimer = run.schedule('afterRender', () => {
      showInViewPort($(this.element), -164, 300);
    });
  },

  mouseEnter() {
    if (this.mapHover) {
      this.set('stage.hover', true);
    }
  },

  mouseLeave() {
    if (this.mapHover) {
      this.set('stage.hover', false);
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('stage.stageExpanded', false);
  },

  actions: {

    toggleExpand() {
      this.toggleProperty('stage.stageExpanded');
      if (this.get('stage.stageExpanded')) {
        this.set('tripService.currentStageIndex', this.index);
        this.focusStage();
      }
    },

    setDate(){
      this.get('datePickerInstance').open();
    }

  }
});
