import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  alias,
  or
} from 'ember-decorators/object/computed';

export default Component.extend({
  tagName: '', 

  scroll: service(),
  tripService: service('trip'),
  router: service(),
  screen: service(),

  @or('tripService.tripPreviewModal.trip', 'tripService.currentTrip') trip: null,
  @alias('tripService.newStageIndex') newStageIndex: null,

  showWhatsIncluded: false,

  didInsertElement() {
    this._super(...arguments);
    // scroll new stage into view
    if (this.get('newStageIndex') && $('.stage-block')[this.get('newStageIndex') - 1]) {
      let pos = $('.stage-block')[this.get('newStageIndex') - 1].getBoundingClientRect().top - 70;
      this.insertTimer = run.next(() => {
        this.get('scroll').to(pos, this);
        this.set('newStageIndex', null);
      });
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.insertTimer);
  }

});
