import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import {
  inject as service
} from '@ember/service';
import {
  getUrlParams
} from 'b5b/utils';


export default Route.extend(basicNav, {

  stripeService: service(),
  ui: service(),
  router: service(),
  tripService: service('trip'),

  beforeModel() {
    this._super(...arguments);
    return this.stripeService.getStripeReadyPromise();
  },

  activate() {
    this.ui.set('showProcessingModal', true);
  },
  
  deactivate() {
    this.ui.set('showProcessingModal', false);
  },

  setupController(controller, paymentId) {


    let params=getUrlParams();

    let stripeConnectAccount = params['stripe_connect_account'];
    let stripeOptions = {};
    if (stripeConnectAccount) {
      stripeOptions['stripeAccount']=stripeConnectAccount;
    }

    const stripe = Stripe(params['publishable_key'], stripeOptions);    
    
    stripe.retrievePaymentIntent(params['payment_intent_client_secret']).then(({paymentIntent}) => {
      let baseRoute = this.get('tripService.currentTrip.isGiftList') ? 'trip.index.contribute' : 'trip.index.pay'
      // Make sure that these steps are kept in sync with flywire
      switch (paymentIntent.status) {
        case 'succeeded':
          // provissionally completing the charge will add the current user to the trip
          this.tripService.currentTrip.provisionallyCompleteCharge({charge_id: params['charge_id'], stripe_payment_intent_id: paymentIntent.id, payment_processor: 'stripe'}).then((response)=>{
            // We can now reload the trip with the status updated on server and current user as use on trip
            
            if (response.charge_saved != true) {
              return this.router.transitionTo(`${baseRoute}.error`);
            }
            
            this.tripService.refreshCurrentTripFromServer().then(()=> {
              if (this.tripService.currentTrip.itinerary.processPath || this.get('tripService.currentTrip.isGiftList')) {
                this.router.transitionTo(`${baseRoute}.success`);
              } else {
                this.router.transitionTo('trip.index.deposit-success');
              }               
            })
          })

          break;

        case 'processing':
        case 'requires_action':
          this.router.transitionTo(`${baseRoute}.processing`);
          break;

        default:
          this.router.transitionTo(`${baseRoute}.error`);
          break;
      }
    });
  }
});
