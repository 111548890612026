import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let entityImagelUrl = namedArgs.entityImagelUrl;
    let entityImageCoverStyle = namedArgs.entityImageCoverStyle;
    let overrideDayImageUrl = namedArgs.overrideDayImageUrl;
    let overrideDayImageCoverStyle = namedArgs.overrideDayImageCoverStyle;
    let coverExperienceKodakOriginalUrl = namedArgs.coverExperienceKodakOriginalUrl;
    let coverExperienceCoverStyle = namedArgs.coverExperienceCoverStyle;
    
    if (overrideDayImageUrl) {
      return {imageUrl: overrideDayImageUrl, coverStyle: overrideDayImageCoverStyle}
    } else if (entityImagelUrl) {
      return {imageUrl: entityImagelUrl, coverStyle: entityImageCoverStyle}
    } else {
      return {imageUrl: coverExperienceKodakOriginalUrl, coverStyle: coverExperienceCoverStyle}
    }
  }

});
