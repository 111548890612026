import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  and,
  gt
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['t-map'],

  screen: service(),

  entityList: null,
  popupOpenIndex: null,
  mapOnlyView: null,
  modalView: null,


  @gt('bounds.length', 0) hasBounds: null,
  @and('screen.initialized', 'hasBounds') readyToShowMap: false,

  @computed('entityList.[]')
  bounds(entityList) {
    if (entityList && entityList.get('length') > 0) {
      var bounds = entityList.reduce(function(result, entity) {
        if (entity && entity.get('latitudeWithFallback') && entity.get('longitudeWithFallback')) {
          let lat = entity.get('latitudeWithFallback'),
          lng = entity.get('longitudeWithFallback');
          result.push([lat, lng]);
        }
        return result;
      }, []);

      if (bounds.get('length') == 1) {
        // Its crazy but true. Ian 30/12/2016. This is needed because otherwise the bounds calculation blows up on initial page load if there is only a single lodge in the result set.
        // The bounds seems fine if you change fitlers so there is a single lodge but refresh and everythign falls over!
        var lat = parseFloat(bounds[0][0]);
        var lng = parseFloat(bounds[0][1]);
        bounds.pushObject([(lat - 0.25).toString(), (lng - 0.25).toString()])
        bounds.pushObject([(lat + 0.25).toString(), (lng + 0.25).toString()])
      }

      return bounds;
    }
  },

  actions: {
    hideMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed') || this.get('mapOnlyView') || !screen.isDesktop) {
        return;
      }
      marker.target.setOpacity(0);
    },

    showMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed') || this.get('mapOnlyView') || !screen.isDesktop) {
        return;
      }

      marker.target.setOpacity(1);
    },

    closeMapModal() {
      if (this.get('mapOnlyView')) {
        this.set('mapOnlyView', null);
      } else {
        this.set('showMapModal', null);
      }
    }
  }

});
