import { helper } from "@ember/component/helper";

export function userCanEditExperience(params, namedArgs) {
  //Can have unlogged in users
  let isManager = namedArgs.user ? namedArgs.user.isManager : false;
  let userId = namedArgs.user ? namedArgs.user.id : null;

  if (
    namedArgs.experience && namedArgs.experience.polishLevel=='custom' &&
    namedArgs.experience.userOwnerId == userId &&
    isManager
  ) {
    return true;
  }
  return false;
}

export default helper(userCanEditExperience);
