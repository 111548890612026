import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags() {
    const title = `About ${this.whitelabel.agency.name} | ${this.whitelabel.agencySeoName}`;

    var description = 'Journeys designed by you, built by us. Timbuktu was founded in 2014 with a clear mission: to make far-flung corners of the world more accessible by empowering you, our travellers, to explore and build trips, regardless of experience or budget - and save some pennies along the way.';
    if (this.whitelabel.isForAgency) {
      description = '';
    }

    if (!this.isFastBoot) {
      document.title = title;
    }
    let robots = "index, follow"
    return generateMeta({
      description,
      robots,
      link: 'about',
      'og:title': title
    });
  },
  model() {

    return this.store.query('user', {agency_id: this.whitelabel.agency.id, include_on_about_us: true})
  },

  afterModel(model, transition) {
    this._super(...arguments);
    if (this.whitelabel.isForAgency) {
      transition.abort();
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('about:view');
    controller.set('users', model)
  }

});
