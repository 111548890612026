import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import schedule from '../../../serializers/schedule';

export default Component.extend({
  tagName: '',

  tripService: service('trip'),
  entityModals: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') blockItem: null,
  @alias('entityModals.currentModalConfig.stage') stage: null,


  actions: {

  }
});
