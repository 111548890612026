import Service from '@ember/service';

export default Service.extend({

  currentDeal: null,

  saveDeal(options={}) {
    if (!options.deal) {
      options.deal=this.currentDeal;
    }
    return options.deal.save().then((deal)=> {
      let savingGuests = [];
      deal.guests.filter((deal)=>{
        return deal.isNew || deal.hasDirtyAttributes;
      }).forEach((guest)=> {
        savingGuests.pushObject(guest.save())
      })

      return Ember.RSVP.all(savingGuests)
    })
  },

  actions: {

    saveDeal(options) {
      return this.saveDeal(options)
    }
  }

});
