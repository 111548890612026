import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import RSVP from "rsvp";

export default Controller.extend(UseCurrentTrip, TripSaver, {

  router: service(),

  @alias('tripService.allPartners') allTripPartners: null,

  @computed('allTripPartners.[]', 'trip.itinerary.partners.[]')
  filteredTripPartners(allPartners, addedPartners) {
    return allPartners.filter((partner) => {
      return addedPartners.includes(partner) ? false : partner;
    });
  },

  getBrochureQueryParams() {
    var queryParams = {};

    queryParams.includeTripOverview = this.get('includeTripOverview');
    queryParams.includeStageInfo = this.get('includeStageInfo');
    queryParams.includeCover = this.get('includeCover');
    queryParams.includeCountries = this.get('includeCountries');
    queryParams.includeRegions = this.get('includeRegions');
    queryParams.includeLodges = this.get('includeLodges');
    queryParams.includeInclusions = this.get('includeInclusions');
    queryParams.includeQuoteIntro = this.get('includeQuoteIntro');
    queryParams.includePreDeparture = this.get('includePreDeparture');
    queryParams.includeLodgeContacts = this.get('includeLodgeContacts');
    queryParams.includePartnersInFront = this.get('includePartnersInFront');
    queryParams.includePartnersInBack = this.get('includePartnersInBack');
    queryParams.includeDestinationDates = this.get('includeDestinationDates');    
    return queryParams;
  },

  isBrochureConfigured() {
    return this.includeCover || this.includeTripOverview || this.includeStageInfo || this.includeCountries || this.includeRegions ||
      this.includeLodges || this.includeInclusions || this.includePreDeparture || this.includeQuoteIntro || this.includeLodgeContacts ||
       this.includePartnersInFront || this.includePartnersInFront || this.includePartnersInBack || this.showBrief || this.showDetailed;
  },  

  actions: {
    addItineraryPartner(partner) {
      return this.get('trip.itinerary').addItineraryPartner({
        partner_id: partner.id
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.get('flashMessages').success('Partner added!');
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      })
    },

    removeItineraryPartner(partner) {
      return this.get('trip.itinerary').removeItineraryPartner({
        partner_id: partner.id
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.get('flashMessages').success('Partner removed!');
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      })
    },

    setupBriefBrochure() {
      this.setProperties({
        includeCover: true,
        includeTripOverview: true,
        includeStageInfo: true,
        includeCountries: false,
        includeRegions: false,
        includeLodges: false,
        includeInclusions: false,
        includePreDeparture: false,
        includeQuoteIntro: true,
        includeLodgeContacts: false,
        includePartnersInFront: true,
        includePartnersInBack: false,
        includeDestinationDates: false,
        showBrief: true,
        showDetailed: false
      });
    },
    setupDetailedBrochure() {
      this.setProperties({
        includeCover: true,
        includeTripOverview: true,
        includeStageInfo: true,
        includeCountries: true,
        includeRegions: true,
        includeLodges: true,
        includeInclusions: true,
        includePreDeparture: true,
        includeQuoteIntro: true,
        includeLodgeContacts: false,
        includePartnersInFront: true,
        includePartnersInBack: true,
        includeDestinationDates: true,
        showBrief: false,
        showDetailed: true
      });
    },

    emailBrochureToMe() {
      if (this.isBrochureConfigured()) {
        let queryParams = this.getBrochureQueryParams();
        let brochureUrl = this.router.urlFor('trip.index.brochure', this.trip, {
          queryParams
        })
        let url = `${window.location.origin}${brochureUrl}`
        return this.tripService.emailBrochureToCurrentUser({url}).then(()=>{
          this.get('ui').showGeneralMessage('Brochure sent', "We're sending the brochure to your email address now. It will be with you shortly.");
        });

      } else {
        this.get('ui').showGeneralMessage('Oops', 'Please specify what type of brochure you\'d like to see');
        // Return promise for button
        return new RSVP.Promise(function(resolve) {
          resolve();
        });
      }
    },

    viewBrochure() {
      if (this.isBrochureConfigured()) {
        let queryParams = this.getBrochureQueryParams();

        this.transitionToRoute('trip.index.brochure', this.trip, {
          queryParams
        });
      } else {
        this.get('ui').showGeneralMessage('Oops', 'Please specify what type of brochure you\'d like to see');
      }
    },    
  }

});
