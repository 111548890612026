import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: 'mini-trip-ideas-chooser',

  ui: service(),
});
