import Service, {
  inject as service
} from '@ember/service';
import {
  reads
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';


/*
  TO DO
  Load lodge reviews via this service and cache them on here, to avoid repeated queries to server
*/

export default Service.extend({

  store: service(),
  fastboot: service(),
  feefoMeta: null,
  feefoServiceReviews: null,
  feefoTripReviews: null,

  viewedFeefoReview: null,

  loadingFeefo: false,

  @reads('fastboot.isFastBoot') isFastBoot: null,
  @computed('feefoMeta.serviceRating')
  serviceRating(serviceRating) {
    return parseFloat(serviceRating);
  },


  init() {
    this._super(...arguments);
    if (this.get('isFastBoot')) {
      return;
    }

    this.set('loadingFeefo', true);

    this.get('store').findRecord('feefo', 1).then((feefo) => {
      this.setProperties({
        feefoMeta: feefo,
        feefoServiceReviews: feefo.get('feefoServiceReviews'),
        feefoTripReviews: feefo.get('feefoServiceReviews'),
        loadingFeefo: false
      });
    });
  }

});
