import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['context-menu'],
  tripService: service('trip'),
  
  actions: {
    closeMiniContextThen(action) {
      action();
    },
  }
});
