import Mixin from '@ember/object/mixin';

import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import {
  isEmpty
} from '@ember/utils';
import PrettyQuery from 'b5b/mixins/pretty-query';
import {
  generateMeta,
  trackEvent,
  minTripPriceForRange,
  maxTripPriceForRange
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  isArray
} from '@ember/array';
import controller from '../../../about/controller';

export default Mixin.create(PrettyQuery, {

  ui: service(),
  messageBus: service(),
  screen: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  xplorer: service('components/x-plorer'),
  @alias('xplorer.meta.noMatch') noMatch: null,

  pills: service(),
  @alias('pills.minPriceForRange') minPriceForRange: null,
  @alias('pills.maxPriceForRange') maxPriceForRange: null,

  queryParams: {
    selectedContinentNames: {
      replace: true
    },
    selectedCountryNames: {
      replace: true
    },
    selectedRegionNames: {
      replace: true
    },
    selectedMonthNames: {
      replace: true
    },
    selectedExperienceNames: {
      replace: true
    },
    selectedLodgeNames: {
      replace: true
    },
    selectedTripLengths: {
      replace: true
    },
    selectedBudget: {
      replace: true
    },
    searchValue: {
      replace: true
    },
    isPopular: {
      replace: true
    },
    showOnlyWithOffers: {
      replace: true
    },
    searchTemplateTrips: {
      replace: true
    },
    searchPackages: {
      replace: true
    },
    page: {
      replace: true
    },
    sort: {
      replace: true
    },
    reset: {
      replace: true,
      refreshModel: true
    },
    bookingState: {
      replace: true
    },
    polishLevel: {
      replace: true
    },
    allAccountTrips: {},

  },

  per_page: 16,

  selectedModelType: 'selectedCountryNames',
  selectedModelTypeNameReference: null,

  // headTags() {
  //   let model = this.controller.model;
  //   const title = `Regions of ${model.name} | ${this.whitelabel.agencySeoName}`;
  //   var description = `Explore places in ${model.name} with fully customizable trip ideas and our travel experts to get you there.`;

  //   if (!this.isFastBoot) {
  //     document.title = title;
  //   }
  //   let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
  //   let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;
  //   return generateMeta({
  //       description,
  //       'og:title': title,
  //     link,
  //     robots
  //   });
  // },

  headTags() {
    let model =  this.modelFor(this.entityRouteName);
    let title = model.get('name');
    if (this.whitelabel.isForTimbuktu) {
      title = `Bespoke ${model.name}`
      if (model.isSafari) {
        title += ' Safari';
      }
      title += ' Trips';
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }

    var description = `Discover our fully customizable ${model.name} ${model.isSafari ? 'safari ': ''}trip ideas with travel experts to give you all the help and advice you need.`;
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    return generateMeta({
      description,
      robots,
      'og:title': title,
      'og:image': model.get('kodakOriginalUrl'),
      link
    });
  },

  beforeModel(transition) {
    this._super(...arguments);
    if (!this.get('whitelabel.showRoutes') && transition.targetName!='users.my-trips') {
      transition.abort();
    }
  },

  model(params) {
    // return an empty array so the model is different every time this function is called. Thus setupController is called! So basically if you set reset=true as query param then setupController will be called
    return [];
  },

  setupController(controller, model) {
    this._super(...arguments);

    // console.log('setupController! tris')
    if (controller.reset) {
      // console.log('resetting pills from setupController')
      this.pills.externalReset();
      this.get('messageBus').publish('pill-search-multi-search');
    }

    if (controller.polishLevel=='personal') {
      controller.set('user_id', this.get('session.currentUser.id'))
    }

    this.get('xplorer').setupController(controller);

    let minTripPrice = minTripPriceForRange(), // $
      maxTripPrice = maxTripPriceForRange(), // $
      minTripLength = 3,
      maxTripLength = 25;

    controller.setProperties({
      minTripLength,
      maxTripLength,
      maxTripPrice,
      minTripPrice
    });

    // Tried a few things to hide the default model, but leaving for now
    let modelForTripsSubRoute = this.modelFor(this.entityRouteName);
    if (modelForTripsSubRoute) {
      controller.set('model', modelForTripsSubRoute)
      let orRegionIds = []
      modelForTripsSubRoute.get('associatedEntities') && modelForTripsSubRoute.get('associatedEntities').forEach((associatedEntity)=> {
        if (associatedEntity.get('associatedRegion')) {
          orRegionIds.pushObject(associatedEntity.get('associatedRegion.id'))
        }
      })
      if (orRegionIds.length > 0) {
        orRegionIds.pushObject(modelForTripsSubRoute.get('id'))
        controller.set('orRegionIds', orRegionIds)
      } else {
        this.xplorer.addFilter(modelForTripsSubRoute)
      }
    }

    if (this.additionalControllerSetup) {
      this.additionalControllerSetup(controller, modelForTripsSubRoute)
    }

    if (isEmpty(controller.get('selectedBudget'))) {
      controller.set('selectedBudget', [minTripPrice, maxTripPrice]);
    }

    this.setProperties({
      minPriceForRange: minTripPriceForRange,
      maxPriceForRange: maxTripPriceForRange
    });

    // we bind this callback to the xplorer even though we setting the lodges ourself in the controller, because the xplorer service still needs to use this when it updates the lodges. For example when the pagination is used
    this.get('xplorer').setupController(controller, this.controller.entitiesLoaded.bind(this.controller));

    controller.setProperties({
      allowAlternateSuggestions: false,
      reset: false,
      firstTimeLoad: false
    });
    trackEvent('trips:view');

    controller.loadTripsTask.perform();
    this.set('pills.activeType', 'routes');
    this.set('ui.viewingMapListing', true);
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      controller.set('firstTimeLoad', true);
      controller.set('allowAlternateSuggestions', null);
      controller.set('showOnlyWithOffers', false)
      controller.set('orRegionIds', [])
      this.resetFilterParams(controller);
      this.set('pills.activeType', null);

      this.set('ui.viewingMapListing', false);
    }
  },

  resetFilterParams(controller) {
    controller.set('selectedContinentName', []);
    controller.set('selectedCountryNames', []);
    controller.set('selectedRegionNames', []);
    controller.set('selectedExperienceNames', []);
    controller.set('selectedLodgeNames', []);
    controller.set('selectedTripLengths', []);
    controller.set('searchValue', '');
    controller.set('page', 1);
    controller.set('selectedMonthNames', []);
    controller.set('selectedBudget', [minTripPriceForRange(), maxTripPriceForRange()]);
    controller.set('recurringDateRange', ['any', 'any', 'any', 'any']);
    controller.set('isPopular', false);
    controller.set('summaries', true);
    controller.set('filters', []);
    controller.set('additionalFilter', null);
    controller.set('sort', controller.defaultSort);
    controller.set('polishLevel', controller.defaultPolishLevel);
    controller.set('bookingState', 'all');
    controller.set('allAccountTrips', false);
  },

  activate() {
    this._super(...arguments);
    this.get('screen').addBreakpoints({
      perPaneView: "(max-width: 1373px)"
    });
  },

  deactivate() {
    this._super(...arguments);
    this.get('screen').removeBreakpoints({
      perPaneView: "(max-width: 1373px)"
    });
    this.get('xplorer').teardownController();
  },

  actions: {

    resetFilters() {
      this.resetFilterParams(this.controller);
      this.get('messageBus').publish('suggestions-reset');
    }
  }

});
