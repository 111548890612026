import xSuggestionsComponent from 'b5b/components/x-suggestions/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default xSuggestionsComponent.extend({
  session: service(),

  classNames: ['lodges'],
  suggestionTypes: ['lodges'],
  tripService: service('trip'),
  @alias('loadEntitiesTask') loadLodgesTask: null,

  init() {
    this._super(...arguments);    
    this.get('messageBus').subscribe('listing-map-position-changed', this, this.loadSuggestions);
  },

});
