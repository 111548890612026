import {
  isEmpty
} from '@ember/utils';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  uniqBy,
  getDurationForNumNights
} from 'b5b/utils';
import RSVP from 'rsvp';
import DS from 'ember-data';
const {
  PromiseArray
} = DS;


export default Controller.extend({

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapDefaultLatLng(stages) {
    if (isEmpty(stages)) {
      return {
        lat: -4.721974,
        lng: 22.884122
      }
    }
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  },

  @computed('trip.tripLength')
  duration(tripLength) {
    return getDurationForNumNights(tripLength)
  },

  @computed('trip.uniqueRegions')
  whenToGoDestinations(uniqueRegions) {
    let promise = RSVP.all(uniqueRegions.mapBy('destinationInfo'))
      .then(function() {
        return RSVP.all(uniqueRegions.mapBy('country.destinationInfo'))
      }).then(function() {
        return uniqBy(uniqueRegions, 'name').concat(uniqueRegions.mapBy('country').uniq())
          .filterBy('destinationInfo.seasonalRating.length')
      });
    return PromiseArray.create({
      promise
    });
  }

});
