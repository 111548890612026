import Component from '@ember/component';
import {
  trackEvent
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['email-signup'],

  postRegister: true,
  session: service(),

  didInsertElement() {
    trackEvent('modal:subscribe:show', {
      hook: 'email-signup'
    });
    run.later(() => {
      trackEvent('modal:subscribe:no-bounce', {
        hook: 'email-signup'
      })
    }, 10000);
  },

  submit(e) {
    e.preventDefault();

    this.signupNewsletterIgnoreExistingSubscription(
      this.subscriptionName,
      this.subscriptionEmail,
      'full-screen',
      'newsletter-signup'
    );
    return false;
  },

  actions: {
    close() {
      trackEvent('modal:subscribe:close:overlay');
      if (this.onClose) {
        this.onClose();
      }
    },

    subscribePostRegister() {
      this.signupNewsletterIgnoreExistingSubscription(
        this.get('session.currentUser.name'),
        this.get('session.currentUser.email'),
        'full-screen-register',
        'newsletter-signup-after-register'
      );
      return false;
    }
  }

});
