import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';

import bodyScrollLock from 'body-scroll-lock';

export default Component.extend({
  classNameBindings: [':t-modal', 'isFocused', 'iosReady'],

  scroll: service(),

  onClose: null,
  overlayCloses: true,
  iosReady: true,

  iosTimer: null,

  // iOS fix
  iosRefresh() {
    this.set('iosReady', true);
    // disabled this for performance, causes delay and jump,
    // re-enable freely
    // this.set('iosReady', false);
    // this.iosTimer = run.later(() => {
    //   this.set('iosReady', true);
    // }, 300);
  },

  didInsertElement() {
    this._super(...arguments);

    bodyScrollLock.disableBodyScroll(this.element);

    this.scroll.addModal(this.elementId, this);
    this.iosRefresh();
  },

  willDestroyElement(){
    this._super(...arguments);
    bodyScrollLock.enableBodyScroll(this.element);
  },

  didDestroyElement() {
    this._super(...arguments);

    this.scroll.removeModal(this.elementId, this);
    run.cancel(this.iosTimer);
  },

  actions: {
    overlayClose() {
      if (this.onClose && this.overlayCloses) {
        this.onClose();
      }
    },

    close() {
      if (this.onClose) {
        this.onClose();
      }
    }
  }

});
