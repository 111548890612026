import {
  isPresent
} from '@ember/utils';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  alias
} from 'ember-decorators/object/computed';

export default DS.Model.extend({

  name: DS.attr('string'),
  surname: DS.attr('string'),
  email: DS.attr(),
  consultant: DS.attr('boolean'),
  partner: DS.attr('boolean'),
  isAgent: DS.attr('boolean'),
  isContentTeam: DS.attr('boolean'),
  includeOnAboutUs: DS.attr('boolean'),
  generateSignature: DS.attr('boolean'),
  aboutUsDescription: DS.attr(),
  aboutUsRole: DS.attr(),


  @alias('consultant') isConsultant: false,
  @alias('partner') isPartner: false,

  isTimbuktu: DS.attr('boolean'),

  agency: DS.belongsTo('agency', {
    inverse: null
  }),

  tagline: DS.attr('string'),
  bio: DS.attr('string'),
  profileImageUrl: DS.attr('string'),

  isSubscribedToNewsletter: DS.attr('boolean'),

  image: DS.attr('string'),
  password: DS.attr(),
  lodgeFavourites: DS.hasMany('lodgeFavourite'),
  tripFavourites: DS.hasMany('tripFavourite'),
  countryFavourites: DS.hasMany('countryFavourite'),
  regionFavourites: DS.hasMany('regionFavourite'),
  experienceFavourites: DS.hasMany('experienceFavourite'),

  @mapBy('lodgeFavourites', 'lodge') favouritedLodges: null,
  @mapBy('countryFavourites', 'country') favouritedCountries: null,
  @mapBy('regionFavourites', 'region') favouritedRegions: null,
  @mapBy('experienceFavourites', 'experience') favouritedExperiences: null,
  @mapBy('tripFavourites', 'trip') favouritedTrips: null,

  @computed('image')
  secureImage(image) {
    if (image && image.indexOf('https') === -1) {
      image = image.replace('http', 'https');
    }
    return image;
  },

  @computed('name', 'surname')
  fullName(name, surname) {
    return name + ' ' + surname;
  },

  @computed('name', 'surname')
  initials(name, surname) {
    var initials = '';
    if (isPresent(name)) {
      initials = initials + name.substring(0, 1);
    }
    if (isPresent(surname)) {
      initials = initials + surname.substring(0, 1);
    }
    return initials;
  },

  @computed('email')
  isSuperConsultant(email) {
    return email === 'jonty.medcalf@timbuktutravel.com' ||
      email === 'johnny.prince@timbuktutravel.com' || email === 'ian.petzer@timbuktutravel.com' || email === 'gareth.jones@timbuktutravel.com' || email === 'pdfgen@timbuktutravel.com' || email === 'ianpetzer+consultant@gmail.com' || email ==='julian.ghinn@timbuktutravel.com' || email=='godfrey.zhomwa@timbuktutravel.com';
  },

  @computed('isConsultant', 'isPartner', 'isAgent')
  isManager(isConsultant, isPartner, isAgent) {
    return isConsultant || isPartner || isAgent;
  },


});
