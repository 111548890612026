import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';
import {
  not
} from 'ember-decorators/object/computed';


export default Component.extend({

  cache: service(),

  locations: null,
  countries: null,

  selectedCountry: null,
  selectedLocation: null,
  tripService: service('trip'),
  whitelabel: service(),
  @not('selectedLocation') selectedLocationNotChosen: true,

  isStartLocation: true,
  stage: null,
  dataReady: false,

  init() {
    this._super(...arguments);
    this.cache.getAllLocationsPromise().then((locations)=> {
      this.set('locations', locations)     
      if (this.whitelabel.isForTimbuktu) {
        this.set('countries', this.cache.getAllPublishedCountries());
      } else {
        this.set('countries', this.cache.getAllCountriesPromise());
      }            

      this.stage = this.get('tripService.showChooseLocationModal');

      this.set('isStartLocation', (this.stage.get('sequence') === 0));
      this.set('selectedCountry', this.stage.get('location.region.country'));
      this.set('selectedLocation', this.stage.get('location'));
      this.set('dataReady', true)
    })
  },

  @computed('locations', 'selectedCountry')
  filteredLocations(locations, selectedCountry) {
    if (!selectedCountry) {
      return locations.sortBy('name');
    }

    return locations.filterBy('country', selectedCountry).sortBy('name');
  },

  actions: {
    selectCountry(country) {
      this.set('selectedCountry', country);
      this.set('selectedLocation', null);
    },

    selectLocation(location) {
      this.set('selectedLocation', location);
      this.set('selectedCountry', location.get('country'));
    }

  }

});
