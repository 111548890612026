import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import { computed } from "ember-decorators/object";
import {
  transferDescriptionParagraph,
  prevNonTransferStage,
  nextNonTransferStage,
} from "b5b/utils";
import { TRANSFER_VEHICLE_TYPES } from "b5b/utils";

export default Component.extend({
  tagName: '',

  tripService: service('trip'),
  templateManager: service(),

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get("stage.transfer.duration")) {
      let hours = Math.floor(this.get("stage.transfer.duration") / 60),
        minutes = this.get("stage.transfer.duration") % 60;

      this.setProperties({
        hours: hours,
        minutes: minutes,
      });
    }
  },

  @computed()
  allVehicleTypeKeys() {
    let arr = [];
    Object.keys(TRANSFER_VEHICLE_TYPES).forEach((key) => {
      arr.pushObject(key);
    });
    return arr.sort();
  },

  actions: {
    setVehicleType(vehicleType) {
      // We need a template transfer to be set from the server side so that we try to match the vehicle type of the custom transfer to a template transfer of same vehicle type
      this.set("stage.transfer.vehicleType", vehicleType);
      this.set("stage.needsTransfer", true);
    },
    updateDuration() {
      let duration =
        (parseInt(this.get("hours")) || 0) * 60 +
        (parseInt(this.get("minutes")) || 0);

      if (!isNaN(duration)) {
        this.set("stage.transfer.duration", duration);
      }
    },
    ensureCustomTransfer() {
      // let description = transferDescriptionParagraph(null, {prevStage: prevNonTransferStage(this.stages, this.stageIndex), nextStage: nextNonTransferStage(this.stages, this.stageIndex), transfer: this.stage.transfer});
      if (this.stage.isTemplateTransferStage) {
        let customTransfer = this.store.createRecord("transfer", {
          vehicleType: this.stage.templateTransfer.vehicleType,
          description: this.stage.transfer.description,
          transferType: "custom",
          distance: this.stage.templateTransfer.distance,
          duration: this.stage.templateTransfer.duration,
        });

        // When we convert to custom transfer we want the server to try and find closest matching template transfer
        this.stage.setProperties({
          customTransfer: customTransfer,
          templateTransfer: null,
          stageType: "custom-transfer",
          needsTransfer: true,
        });
      }
    },

    ensureCustomTransferThen(action, ...actionArgs) {
      this.send("ensureCustomTransfer");
      action(...actionArgs);
    },

    closeMiniContextThen(action) {
      action();
    },


  }

});
