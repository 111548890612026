import DS from 'ember-data';

export default DS.Model.extend({

  name: DS.attr('string'),
  hubspotDealId: DS.attr(),
  sourceCountry: DS.attr(),
  sourceState: DS.attr(),
  settlementCurrency: DS.attr(),
  callBackDate: DS.attr(),

  users: DS.hasMany('user', {
    async: false,
    inverse: null
  }),
  trips: DS.hasMany('trip', {
    async: false
  }),
  guests: DS.hasMany('guest', {
    async: false
  }),




});
