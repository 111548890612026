import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {

  isNewSerializerAPI: true,
  attrs: {
    defaultItinerary: {
      serialize: 'records'
    },
    seoName: {
      serialize: false
    },
    master: {
      serialize: false
    },
    dream: {
      serialize: false
    },
    teaserText: {
      serialize: false
    },
    coverStyle: {
      serialize: false
    },
    minNights: {
      serialize: false
    },
    maxNights: {
      serialize: false
    },
    bestTimeToGo: {
      serialize: false
    },
    tripInfo: {
      serialize: false
    },
    experiences: {
      serialize: false
    },
    country: {
      serialize: false
    },
    friendlyId: {
      serialize: false
    },
    createdAt: {
      serialize: false
    },
    updatedAt: {
      serialize: false
    }

  },

  serialize() {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards
    json.itinerary_attributes = json.default_itinerary;
    delete json.default_itinerary;
    return json;
  },

  normalize(noOp, hash) {
    hash.has_template_trip = hash.template_trip_id && true;
    return this._super(...arguments);
  }

});
