import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),


  model(params) {
    return params.charge_id;
  },

  setupController(controller, chargeOrChargeId) {
    let charge = typeof chargeOrChargeId == 'string' ? this.store.peekRecord('charge', chargeOrChargeId) : chargeOrChargeId;
    // NB don't set trip here as it is injected into the controller.
    controller.setProperties({
      charge
    });
    trackEvent('trip:dashboard:receipt');
  }


});
