import { helper as buildHelper } from '@ember/component/helper';

export function arrayOfLength(params, namedArgs) {
  let length = namedArgs.length;

  let array = [];
  for (var i = 0; i < Math.round(length); i++) {
    if (namedArgs.generateStrings) {
      array.push(i+'')
    } else {
      array.push(i)
    }
  }
  return array;
}

export default buildHelper(arrayOfLength);
