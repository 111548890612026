import Component from '@ember/component';
import {
    inject as service
  } from '@ember/service';

export default Component.extend({
    tagName: '',
    top: 80,
    ui: service(),
    router: service(),
});
