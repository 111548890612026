import Service from '@ember/service';
import {
  computed,
  observes
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent,
  findRecordsToUnload,
  getErrorsHashFromServerResponse,
  transferDescriptionParagraph,
  prevNonTransferStage,
  nextNonTransferStage,
  scheduleHeading,
  getBlockTitle
} from 'b5b/utils';

import {
  run
} from '@ember/runloop';
import blockItemExperience from '../models/block-item-experience';
import stage from '../models/stage';

export default Service.extend({


  ui: service(),
  store: service(),
  session: service(),
  whitelabel: service(),
  tripService: service('trip'),

  tripToolsShowTemplateType: null,
  currentStage: null,

  addCustomSchedule(options={/*stage, card*/}) {
    // console.log(options)
    let customSchedule=this.store.createRecord('schedule', {scheduleType: 'custom'});
    if (options.createBlock) {
      let block = this.store.createRecord('block', {sequence: customSchedule.get('blocks.length')})
      customSchedule.get('blocks').pushObject(block);
    }
    // let blockItem = this.store.createRecord('blockItem', {sequence: block.get('blockItems.length')})
    // block.get('blockItems').pushObject(blockItem);
    if (options.stage) {
      // Can also create a customSchedule as firstSchedule on an itinerary
      options.stage.set('customSchedule', customSchedule)
    }

    if (options.card) {
      options.card.ensureOpen();
    }
    return customSchedule;
  },


  duplicateAndSaveAsTemplateSchedule(options={/*schedule, region*/}) {
    let template = options.schedule.makeCopy();
    template.set('scheduleType', 'template');
    template.set('region', options.region)
    this.set('templateScheduleToBeSavedMeta', {schedule: template, stage: options.stage, associatedEntity: 'region'})
  },
  duplicateBlockAndSaveAsTemplateSchedule(options={/*block, region*/}) {
    let template = this.store.createRecord('schedule', {scheduleType: 'template'});;
    template.get('blocks').pushObject(options.block.makeCopy());
    template.set('region', options.region)
    this.set('templateScheduleToBeSavedMeta', {schedule: template, stage: options.stage, associatedEntity: 'region'})
  },


  duplicateBlockItemAndSaveAsTemplateSchedule(options={/*schedule, region, blockItem*/}) {
    let template = this.store.createRecord('schedule', {scheduleType: 'template'});;
    template.set('region', options.region)
    let block = this.get('store').createRecord('block');
    template.get('blocks').pushObject(block);
    block.get('blockItems').pushObject(options.blockItem.makeCopy());
    this.set('templateScheduleToBeSavedMeta', {schedule: template, stage: options.stage, associatedEntity: 'region'})
  },


  duplicateAndSaveAsTemplateTransfer(options={/*transfer, stage*/}) {
    let template = options.transfer.makeCopy();
    template.set('transferType', 'template');
    // template.set('currency', 'usd');
    // template.set('rate', 'usd');

    let currentStageIndex = options.stage.get('itinerary.stages').indexOf(options.stage);
    let prevStage = prevNonTransferStage(options.stage.get('itinerary.stages'), currentStageIndex);
    let nextStage = nextNonTransferStage(options.stage.get('itinerary.stages'), currentStageIndex);

    if (prevStage && prevStage.isLocationStage) {
      template.set('fromLocation', prevStage.location);
    } else if (prevStage) {
      template.set('fromRegion', prevStage.regionProxy);
    }
    if (nextStage && nextStage.isLocationStage) {
      template.set('toLocation', nextStage.location);
    } else if (nextStage) {
      template.set('toRegion', nextStage.regionProxy);
    }

    this.set('templateTransferToBeSavedMeta', {transfer: template, stage: options.stage})
  },

  saveAsTemplate(options) {
    let template = this.store.createRecord('template', {overview: options.overview, templateType: options.templateType, region: options.stage.regionProxy, country: options.stage.countryProxy});
    let associatedEntity=options.associatedEntity || 'lodge';
    this.set('templateToBeSavedMeta', {template: template, stage: options.stage, associatedEntity})
  },

  saveInclusionAsTemplate(options) {
    let inclusion = this.store.createRecord('inclusion', {includes: options.stage.whatsIncluded, excludes: options.stage.whatsExcluded, templateType: options.templateType, region: options.stage.regionProxy});
    this.set('templateInclusionToBeSavedMeta', {inclusion: inclusion, stage: options.stage, associatedEntity: 'lodge'})

  },

  getQueryAttrsForStageTransferOptions(stage) {

    let currentStageIndex = this.get('tripService.currentTrip.itinerary.stages').indexOf(stage);
    let prevStage = prevNonTransferStage(this.get('tripService.currentTrip.itinerary.stages'), currentStageIndex);
    let nextStage = nextNonTransferStage(this.get('tripService.currentTrip.itinerary.stages'), currentStageIndex);
    if (prevStage && nextStage) {

      let queryAttrs = {
        templateType: 'template',
        buster: true,
        sort: 'recommended'
      };

      queryAttrs['from_'+prevStage.stageEntityType+'_id']=prevStage.entity.id
      queryAttrs['to_'+nextStage.stageEntityType+'_id']=nextStage.entity.id

      return queryAttrs;
    }

  },

  saveNewTemplateTransfer(options={}) {

    if (this.whitelabel.isOnboardingAgency) {
      return this.set('ui.showWaybirdSignupModal', true)
    }

    this.templateTransferToBeSavedMeta.transfer.save().then((transfer)=> {
      this.set('templateTransferToBeSavedMeta',null)
      this.get('ui').showGeneralMessage('Congrats!', `We saved your new template transfer perfectly. Any changes you make now will only affect this trip. Your saved template will remain unchanged.`);
    }, (error)=> {
      if (this.session.currentUser.isManager) {
        let errors = getErrorsHashFromServerResponse(error)
        let message = '';
        Object.keys(errors).forEach((key) => {
          message += key + ': ' + errors[key] + '<br>';
        })

        this.ui.showGeneralMessage('This Transfer is not valid', message);
      }
    })
  },

  saveNewTemplateSchedule(options={/* schedule */}) {

    if (this.whitelabel.isOnboardingAgency) {
      return this.set('ui.showWaybirdSignupModal', true)
    }

    if (this.templateScheduleToBeSavedMeta.stage.lodge && this.templateScheduleToBeSavedMeta.associatedEntity == 'lodge') {
      this.set('templateScheduleToBeSavedMeta.schedule.lodge', this.templateScheduleToBeSavedMeta.stage.lodge)
    }

    this.templateScheduleToBeSavedMeta.schedule.save().then((schedule)=> {
      this.fixupModelsInStoreAfterEmbeddedScheduleSave(schedule)
      this.set('templateScheduleToBeSavedMeta',null)
      this.get('ui').showGeneralMessage('Congrats!', `We saved your new template schedule perfectly. Any changes you make now will only affect this trip. Your saved template will remain unchanged.`);
    }, (error)=> {
      if (this.session.currentUser.isManager) {
        let errors = getErrorsHashFromServerResponse(error)
        let message = '';
        Object.keys(errors).forEach((key) => {
          message += key + ': ' + errors[key] + '<br>';
        })

        this.ui.showGeneralMessage('This Schedule is not valid', message);
      }
    })
  },
  saveNewTemplateInclusion(options={/* inclusion */}) {

    if (this.whitelabel.isOnboardingAgency) {
      return this.set('ui.showWaybirdSignupModal', true)
    }

    if (this.templateInclusionToBeSavedMeta.stage.lodge && this.templateInclusionToBeSavedMeta.associatedEntity == 'lodge') {
      this.set('templateInclusionToBeSavedMeta.inclusion.lodge', this.templateInclusionToBeSavedMeta.stage.lodge)
    }
    this.templateInclusionToBeSavedMeta.inclusion.save().then((inclusion)=> {
      this.set('templateInclusionToBeSavedMeta',null)
      this.get('ui').showGeneralMessage('Congrats!', `We saved your new template inclusion perfectly. Any changes you make now will only affect this trip. Your saved template will remain unchanged.`);
    }, (error)=> {
      if (this.session.currentUser.isManager) {
        let errors = getErrorsHashFromServerResponse(error)
        let message = '';
        Object.keys(errors).forEach((key) => {
          message += key + ': ' + errors[key] + '<br>';
        })

        this.ui.showGeneralMessage('This Inclusion is not valid', message);
      }
    })
  },
  saveNewTemplate(options={/*  */}) {

    if (this.whitelabel.isOnboardingAgency) {
      return this.set('ui.showWaybirdSignupModal', true)
    }

    if (this.templateToBeSavedMeta.stage.lodge && this.templateToBeSavedMeta.associatedEntity == 'lodge') {
      this.set('templateToBeSavedMeta.template.lodge', this.templateToBeSavedMeta.stage.lodge)
    }
    if (this.templateToBeSavedMeta.stage.location && this.templateToBeSavedMeta.associatedEntity == 'location') {
      this.set('templateToBeSavedMeta.template.location', this.templateToBeSavedMeta.stage.location)
    }

    this.templateToBeSavedMeta.template.save().then((template)=> {
      this.set('templateToBeSavedMeta',null)
      this.get('ui').showGeneralMessage('Congrats!', `We saved your new template perfectly. Any changes you make now will only affect this trip. Your saved template will remain unchanged.`);
    }, (error)=> {
      if (this.session.currentUser.isManager) {
        let errors = getErrorsHashFromServerResponse(error)
        let message = '';
        Object.keys(errors).forEach((key) => {
          message += key + ': ' + errors[key] + '<br>';
        })

        this.ui.showGeneralMessage('This template is not valid', message);
      }
    })
  },

  addBlock(options={/*schedule*/}) {
// console.log(options)
    let sequence = options.schedule.get('blocks.length');

    this.set('tripService.currentStageIndex', this.get('tripService.currentTrip.itinerary.stages').indexOf(options.stage))
    this.set('tripService.currentBlockIndex', sequence)
    let block = this.store.createRecord('block', {sequence})
    options.schedule.get('blocks').pushObject(block);
    if (options.scrollToCurrentBlock) {
      this.tripService.focusStage(options)
    }

    // let blockItem = this.store.createRecord('blockItem', {sequence: block.get('blockItems.length')})
    // block.get('blockItems').pushObject(blockItem);
  },

  showAddableExperiencesInTripTools(options={/* stage, item*/}) {
    console.log(arguments)
    // ?Put this in run next because we have onclick for main pane that closes this
    run.next(()=> {
      this.set('tripToolsShowTemplateType', 'experience')
      this.set('currentStage', options.stage)
      this.set('currentBlock', options.block)
      this.set('currentBlockItem', options.blockItem)
    })
  },
  toggleExperienceInCurrentBlockItem(options={/* experience */}) {
    if (this.get('currentBlockItem.allExperiencesInBlockItems').includes(options.experience)) {
      this.get('currentBlockItem.blockItemExperiences').forEach((blockItemExperience)=> {
        if (blockItemExperience && blockItemExperience.get('experience') == options.experience) {
          this.get('currentBlockItem.blockItemExperiences').removeObject(blockItemExperience);
        }
      })
    } else {
      console.log(this.currentBlockItem.get('blockItemExperiences.length'))
      this.currentBlockItem.get('blockItemExperiences').pushObject(this.store.createRecord('blockItemExperience', {experience: options.experience, sequence: this.currentBlockItem.get('blockItemExperiences.length')}))
    }

  },

  addAllExperiencesToCurrentBlockItem(options={/* experiences */}) {
    options.experiences.forEach((experience)=> {
      if (!this.get('currentBlockItem.allExperiencesInBlockItems').includes(experience)) {
        this.currentBlockItem.get('blockItemExperiences').pushObject(this.store.createRecord('blockItemExperience', {experience: experience, sequence: this.currentBlockItem.get('blockItemExperiences.length')}))
      }
    })
  },

  removeExperienceItem(options={/*stage, experienceitem*/}) {
    options.experienceItem.get('item.experienceItems').removeObject(options.experienceItem)
  },

  showAddableTemplatesInTripTools(options={/*stage, templateType, card*/}) {
    // ?Put this in run next because we have onclick for main pane that closes this
  run.next(()=> {
    // console.log(options)
    this.set('tripToolsShowTemplateType', options.templateType)
    this.set('currentStage', options.stage)
    this.set('currentBlock', options.block)
    this.set('currentCard', options.card)
    this.set('targetSchedule', options.targetSchedule)
    this.set('scrollToCurrentBlock', options.scrollToCurrentBlock)
  })
},


  duplicateScheduleAndAddToStage(options) {


    if (options.stage.customSchedule) {
      let templateScheduleFirstBlock = options.schedule.get('blocks.firstObject')
      if (options.currentBlock) {
        //This means the contents of the template get inserted into current day / page
        templateScheduleFirstBlock.get('blockItems').forEach((blockItem)=> {
          options.currentBlock.get('blockItems').pushObject(blockItem.makeCopy())
        })
      } else {
        // No currentBlock . No current day / page to insert into, so new block/day/page created and content inserted into that
        if (templateScheduleFirstBlock) {
          let templateCopy = templateScheduleFirstBlock.makeCopy();
          if (options.stage.get('itinerary.trip.isLayoutDayByDay') && !templateCopy.dayTitle) {
            let heading = scheduleHeading(options.stage.get('itinerary.stateBeforeQuote'), this.whitelabel.agency.scheduleHeadingTripIdeas, this.whitelabel.agency.scheduleHeadingQuotes, options.stage.get('itinerary.trip.scheduleHeading'), options.stage.get('.schedule.scheduleHeading'))
            heading = `${heading} in ${options.stage.placeName}`
            templateCopy.set('dayTitle', heading)
            templateCopy.set('dayImageTitle', options.stage.placeName)
          }
          options.stage.get('customSchedule.blocks').pushObject(templateCopy)
        }
      }

      options.schedule.get('blocks').forEach((block, index)=> {
        if (index > 0) {
          // We've already dealt with first block so only index > 0
          let copy = block.makeCopy();
          options.stage.get('customSchedule.blocks').pushObject(copy)
        }
      })
    } else {
      //No custom schedule
      options.stage.set('templateSchedule', null)
      let scheduleCopy = options.schedule.makeCopy()
      options.stage.set('customSchedule', scheduleCopy)
    }
    if (options.scrollToCurrentBlock) {
      this.set('tripService.currentStageIndex', this.get('tripService.currentTrip.itinerary.stages').indexOf(options.stage))
      this.set('tripService.currentBlockIndex', options.stage.get('customSchedule.blocks.length') - 1)
      this.tripService.focusStage(options)
    }


  },
  duplicateBlockItemAndAddToBlock(options) {
    this.currentBlock.get('blockItems').pushObject(options.blockItem.makeCopy());
  },
  showBlockOrderingModal(options) {
    this.set('reorderBlocksInScheduleMeta', options)
  },
  showBlockItemOrderingModal(options) {
    this.set('reorderBlockItemsInSchedule', options.schedule)
  },

  allocateCopyOfScheduleToStage(options) {
    this.duplicateScheduleAndAddToStage(options);
    if (this.currentCard) {
      this.currentCard.ensureOpen();
    }
    this.finishRightPaneTemplateSelection();
  },
  addCopyOfBlockItemToBlock(options) {
    this.duplicateBlockItemAndAddToBlock(options);
    this.finishRightPaneTemplateSelection();
  },
  allocateCopyOfInclusionToStage(options) {
    options.stage.set('whatsIncluded', options.inclusion.includes)
    options.stage.set('whatsExcluded', options.inclusion.excludes)
    this.finishRightPaneTemplateSelection();
  },
  allocateCopyOfTransferToStage(options) {

    let description=null;
    if (
      options.transfer.isTemplateTransfer &&
      (!options.transfer.description || options.transfer.description == "")
    ) {
      let stages = options.stage.get('itinerary.stages');
      let stageIndex = stages.indexOf(options.stage);

      description = transferDescriptionParagraph(null, {
        prevStage: prevNonTransferStage(stages, stageIndex),
        nextStage: nextNonTransferStage(stages, stageIndex),
        transfer: options.transfer,
      });
    }
    let duplicateTransfer = options.transfer.makeCopy();
    if (description) {
      duplicateTransfer.set('description', description)
    }
    options.stage.set('templateTransfer', null)
    options.stage.set('customTransfer', duplicateTransfer)
    options.stage.set('stageType', 'custom-transfer')
    this.finishRightPaneTemplateSelection();
  },
  allocateCopyOfTemplateToStage(options) {
    if (options.template.templateType=='lodge_why_love') {
      options.stage.set('overviewWhyLove', options.template.overview)
    } else if (options.template.templateType=='stage_covid_overview') {
      options.stage.set('overviewCovid', options.template.overview)
    }
    this.finishRightPaneTemplateSelection();
  },

  sortEndActionBlocksInSchedule(schedule) {
    schedule.get('blocks').forEach((block, index)=> {
      block.set('sequence', index);
    })
  },
  sortEndActionBlockItemsInSchedule(schedule, block) {
    block.get('blockItems').forEach((blockItem, index) => {
      blockItem.set('sequence', index);
    });
  },


  removeBlock(options) {
    if (options.block.get('schedule.stage.itinerary.trip.isLayoutDayByDay')) {
      let checkinBlockItem = options.block.get('blockItems').findBy('blockItemType', 'checkin')
      if (checkinBlockItem) {
        this.ui.showGeneralMessage('Oops!', 'This '+getBlockTitle(options.block.get('schedule.stage.itinerary.trip'))+' can\'t be removed as it showcases the checkin to a property');
        return false;
      }
      let transferBlockItem = options.block.get('blockItems').findBy('blockItemType', 'todays-transfers')
      if (transferBlockItem) {
        this.ui.showGeneralMessage('Oops!', 'This '+getBlockTitle(options.block.get('schedule.stage.itinerary.trip'))+' can\'t be removed as it showcases a transfer');
        return false;
      }
      let precedingStagesBlockItem = options.block.get('blockItems').findBy('blockItemType', 'todays-preceding-stages')
      if (precedingStagesBlockItem) {
        this.ui.showGeneralMessage('Oops!', 'This '+getBlockTitle(options.block.get('schedule.stage.itinerary.trip'))+' can\'t be removed as it showcases the preceding transfers and locations');
        return false;
      }

    }
    options.schedule.get('blocks').removeObject(options.block);
    if (options.schedule.get('blocks.length')<=0) {
      this.removeSchedule(options)
    }
  },

  fixupModelsInStoreAfterEmbeddedScheduleSave(schedule, blockItem) {
    // When you do an embedded save it is not overriding dirty properties on the embedded models if the server supplies different values
    // THis is not such an issue for us in general at this stage, but we do need to ensuree that needsTransfer is always set back to false on stages
    // otherwise it will stay true on the stages that were set as needsTranser: true and ignore the response
    // https://github.com/emberjs/data/issues/4552
    // https://github.com/emberjs/data/issues/2487


    var recordsToUnload = [];

    // We might have created new records without ids. The server will allocate them ids, but because its an embedded save they are not tied back to the
    // original records without ids, effectively duplicating any new records. so we remove the original ones without ids

    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(schedule.hasMany('blocks')));
    schedule.get('blocks').forEach((block) => {
      recordsToUnload = recordsToUnload.concat(findRecordsToUnload(block.hasMany('blockItems')));
      block.get('blockItems').forEach((blockItem) => {
        recordsToUnload = recordsToUnload.concat(findRecordsToUnload(blockItem.hasMany('blockItemExperiences')));
      })
    });

    recordsToUnload.forEach((record) => {
      this.store.unloadRecord(record);
    });
    return schedule;
  },

  fixupModelsInStoreAfterEmbeddedBlockItemSave(blockItem) {
    // When you do an embedded save it is not overriding dirty properties on the embedded models if the server supplies different values
    // THis is not such an issue for us in general at this stage, but we do need to ensuree that needsTransfer is always set back to false on stages
    // otherwise it will stay true on the stages that were set as needsTranser: true and ignore the response
    // https://github.com/emberjs/data/issues/4552
    // https://github.com/emberjs/data/issues/2487


    var recordsToUnload = [];

    // We might have created new records without ids. The server will allocate them ids, but because its an embedded save they are not tied back to the
    // original records without ids, effectively duplicating any new records. so we remove the original ones without ids

    recordsToUnload = recordsToUnload.concat(findRecordsToUnload(blockItem.hasMany('blockItemExperiences')));

    recordsToUnload.forEach((record) => {
      this.store.unloadRecord(record);
    });
    return blockItem;
  },

  removeSchedule(options) {
    options.stage.set('templateSchedule', null)
    options.stage.set('customSchedule', null)
  },
  removeBlockItem(options) {
    console.log(options)
    options.block.get('blockItems').removeObject(options.blockItem);
  },
  finishRightPaneTemplateSelection() {
    this.set('tripToolsShowTemplateType', null)
    this.set('currentStage', null)
    this.set('currentBlock', null)
    this.set('currentCard', null)
    this.set('targetSchedule', null)
    this.set('scrollToCurrentBlock', null)
    this.set('tripToolsFilterByPartner', null)

  },

  addBlockItem(options) {
    let blockItem = this.store.createRecord('blockItem', {sequence: options.block.get('blockItems.length'), blockItemType: options.blockItemType});
    if (options.forceCardOpen !== undefined) {
      blockItem.set('forceCardOpen', options.forceCardOpen)
    }
    if (options.insertIndex) {
      options.block.get('blockItems').splice(options.insertIndex, 0, blockItem);
    } else {
      options.block.get('blockItems').pushObject(blockItem)
    }

    return blockItem;
  },

  actions: {

    removeSchedule() {
      this.removeSchedule(...arguments)
    },
    addDay() {
      this.addDay(...arguments)
    },
    addBlock() {
      this.addBlock(...arguments)
    },
    addBlockItem() {
      this.addBlockItem(...arguments)
    },


    showAddableExperiencesInTripTools() {
      this.showAddableExperiencesInTripTools(...arguments)
    },

    removeExperienceItem() {
      this.removeExperienceItem(...arguments)
    },
    addCustomSchedule() {
      this.addCustomSchedule(...arguments)
    },
    findTemplateSchedule() {
      this.findTemplateSchedule(...arguments)
    },
    showBlockOrderingModal() {
      this.showBlockOrderingModal(...arguments)
    },
    showBlockItemOrderingModal() {
      this.showBlockItemOrderingModal(...arguments)
    },

    sortEndActionBlocksInSchedule() {
      this.sortEndActionBlocksInSchedule(...arguments)
    },
    sortEndActionBlockItemsInSchedule() {
      this.sortEndActionBlockItemsInSchedule(...arguments)
    },
    duplicateAndSaveAsTemplateSchedule() {
      this.duplicateAndSaveAsTemplateSchedule(...arguments)
    },
    duplicateBlockAndSaveAsTemplateSchedule() {
      this.duplicateBlockAndSaveAsTemplateSchedule(...arguments)
    },
    duplicateBlockItemAndSaveAsTemplateSchedule() {
      this.duplicateBlockItemAndSaveAsTemplateSchedule(...arguments)
    },


    removeBlock() {
      this.removeBlock(...arguments)
    },
    removeBlockItem() {
      this.removeBlockItem(...arguments)
    },


    saveNewTemplateSchedule() {
      this.saveNewTemplateSchedule(...arguments)
    },
    toggleExperienceInCurrentBlockItem() {
      this.toggleExperienceInCurrentBlockItem(...arguments)
    },
    allocateCopyOfScheduleToStage() {
      this.allocateCopyOfScheduleToStage(...arguments)
    },
    addCopyOfBlockItemToBlock() {
      this.addCopyOfBlockItemToBlock(...arguments)
    },
    dropBlockItem(options, blockItem) {
      options.block.get('blockItems').pushObject(blockItem)
    },
    duplicateAndSaveAsTemplateTransfer() {
      this.duplicateAndSaveAsTemplateTransfer(...arguments)
    },
    showAddableTemplatesInTripTools() {
      this.showAddableTemplatesInTripTools(...arguments);
    },
    allocateCopyOfTransferToStage() {
      this.allocateCopyOfTransferToStage(...arguments)
    },
    saveAsTemplate() {
      this.saveAsTemplate(...arguments)
    },
    saveNewTemplate() {
      this.saveNewTemplate(...arguments)
    },
    saveInclusionAsTemplate() {
      this.saveInclusionAsTemplate(...arguments)
    },
    saveNewTemplateInclusion() {
      this.saveNewTemplateInclusion(...arguments)
    },
    allocateCopyOfInclusionToStage() {
      this.allocateCopyOfInclusionToStage(...arguments)
    },
    finishRightPaneTemplateSelection() {
      this.finishRightPaneTemplateSelection(...arguments)
    },
    closeEntityModalsAndSaveTemplate() {
      this.closeEntityModalsAndSaveTemplate(...arguments)
    }



  }

});
