import { inject as service } from '@ember/service';
import {
  or
} from 'ember-decorators/object/computed';

import Component from '@ember/component';

export default Component.extend({
  classNames: 'consultant-feefo-block',
  settings: service(),

  @or('trip.consultant', 'settings.consultant') consultant: false,
});