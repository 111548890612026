import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import Mixin from '@ember/object/mixin';

var rememberScroll = Mixin.create({

  locationScrollHistory: service(),
  entityModals: service(),

  actions: {
    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);
      this.locationScrollHistory.onWillTransition(transition);
      this.entityModals.closeModal();
      // return true so willTransition bubbles
      return true;

    },

    didTransition() {
      // console.log(`diTrans: ${this.fullRouteName}`)
      this._super(...arguments);
      this.locationScrollHistory.onDidTransition(this.fullRouteName);
      return true; // Bubble the didTransition event!
    }
  }

});

export function initialize() {

  if (typeof FastBoot !== 'undefined') {
    return;
  }

  window.onpopstate = function(event) {
    if (event.state) {
      window.timbuktu.popStateTarget = event.state.path;
    }
  };
  Route.reopen(rememberScroll);
}

export default {
  name: 'transition-actions',
  initialize: initialize
};