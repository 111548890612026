import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  model(params) {
    return params.payment_id;
  },

  setupController(controller, paymentId) {

    if (['balance', 'deposit'].includes(paymentId)) {
      controller.set('paymentType', paymentId)
    } else {
      controller.set('paymentType', 'payment')
      controller.set('paymentId', paymentId)
    }


  }


});
