import {
  helper as buildHelper
} from '@ember/component/helper';
import config from '../config/environment';

import {
  pluralize
} from 'ember-inflector';

export function hawkEditLink(params, namedArgs) {
  if (namedArgs.entity) {
    let entity = namedArgs.entity;
    let entityType = pluralize(namedArgs.entity.get('constructor.modelName'));
    let linkToEdit = namedArgs.linkToEdit || false;
    let entityId = entity.friendlyId || entity.id
    return `${config.APP.adminHost}/admin/${entityType}/${entityId}${linkToEdit ? '/edit': ''}`;
  }
}

export default buildHelper(hawkEditLink);
