import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import Component from '@ember/component';

export default Component.extend({

  templateManager: service(),
  entityModals: service(),
  store: service(),
  dayExperiences: null,
  classNames: ['trip-tools-right-pane-add-experiences', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  polishLevel: 'default_agency',

  polishLevels: ["default_agency", "default", "custom", "personal"],

  didInsertElement() {
    this._super(...arguments);
    if (this.get('templateManager.currentStage.regionProxy.name')) {
      this.get('loadExperiencesTask').perform();
    }
  },

  loadExperiencesTask: task(function*() {
    let filters = [{type: 'region', id: this.get('templateManager.currentStage.regionProxy.id')}];
    this.get('templateManager.currentStage.regionProxy').get('associatedEntities') && this.get('templateManager.currentStage.regionProxy').get('associatedEntities').forEach((associatedEntity)=> {
      if (associatedEntity.get('associatedRegion')) {
        filters.pushObject({type: 'region', id: associatedEntity.get('associatedRegion.id')})
      }
    })  
    if (this.textFilter) {
      filters.pushObject({
        name: this.textFilter,
        type: "text",
      })      
    }        
    return this.store.query('experience', {
      filters: JSON.stringify(filters),
      isRegional: true,
      buster: true,
      polishLevel: this.polishLevel,
      per_page: 50,
      sort: "alphabetical_asc"
    }).then((experiences)=>{
      this.set('experiences', experiences)
    })

  }).keepLatest(),

  actions: {
    textFilterChanged(textFilter) {
      this.set('textFilter', textFilter)
      this.loadExperiencesTask.perform();
    },

    setPolishLevel(val) {
      this.set('polishLevel', val)
      this.get('loadExperiencesTask').perform();
    },      
  }
});
