import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  isEmpty
} from '@ember/utils';
import {
  getLoccalStorageEmailCapturedKey,
  getItemFromLocalStorage
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  alias,
  reads
} from 'ember-decorators/object/computed';

import PillsMixin from 'b5b/pills/mixin';

export default Component.extend(PillsMixin, {
  classNameBindings: [':pill-search', 'isActive:active'],

  scroll: service(),
  ui: service(),
  store: service(),
  messageBus: service(),
  settings: service(),
  router: service(),

  fastboot: service(),
  @reads('fastboot.isFastBoot') isFastBoot: null,

  // @alias('scroll.isSearching') active: false,
  active: false,
  currentActive: false, // to differentiate between mutiple searches on same page

  @computed('active', 'currentActive')
  isActive(active, currentActive) {
    return active && currentActive;
  },

  selectOnFocus: true, //detault
  forceShowClear: true,

  didSelect: false,
  didSelectValue: null,
  blockClear: false,

  @computed('pseudoValue', 'forceShowClear')
  showClear(pseudoValue, forceShowClear) {
    return forceShowClear || pseudoValue !== '';
  },

  value: '',
  pseudoValue: '', // for content buffering

  debounceTimer: null,
  insertFocusTimer: null,

  includeLodges: false,
  includeTrips: false,
  autofocus: '',
  seeAllResultsVisible: true,

  querying: false,

  onUpdate: null, // closure
  onClose: null, // closure
  onClear: null, // closure

  results: [],

  popularSearches: [],

  init() {
    this._super(...arguments);
    this.populatePills();
    this.messageBus.subscribe('pill-removed', this, this.checkRemovedPill);
  },

  @alias('pills.suggestionsCompilation') suggestionsCompilation: null,
  @alias('pills.loadSuggestionsTaskRunning') loadSuggestionsTaskRunning: false,

  // {{!-- Removing suggested searches for global launch as we do not have all the required content to drive suggested searches --}}
  // @computed('suggestionsCompilation')
  // suggestedSearches(suggestionsCompilation) {
  //   let count = 0,
  //     topSuggestions = [];

  //   // first we try and only get destinations
  //   topSuggestions = suggestionsCompilation.filter((suggestion) => {
  //     if ((suggestion.get('constructor.modelName') === 'country' || suggestion.get('constructor.modelName') === 'region' || suggestion.get('constructor.modelName') === 'area') && count < 5) {
  //       count++;
  //       return suggestion
  //     }
  //   });

  //   // otherwise we simply return the top 5
  //   if (isEmpty(topSuggestions)) {
  //     topSuggestions = suggestionsCompilation.slice(0, 5);
  //   }

  //   return topSuggestions;
  // },

  checkRemovedPill(pill) {
    // Why do we need this?
    // It breaks clearing the pill on listing pages, eg Kenya
    // if (this.blockClear) {
    //   this.set('blockClear', false);
    //   return;
    // }

    if (this.pseudoValue === pill.name) {
      this.send('clear', false);
    }
  },

  didInsertElement() {
    // We need to wait until after didInsertElement to ensure the input has been inserted into the dom
    if (this.get('autofocus')) {
      run.cancel(this.insertFocusTimer);
      this.insertFocusTimer = run.next(() => {
        this._refocus();
      });
    }

    this.set('popularSearches', this.get('store').peekAll('home').get('firstObject.search'));

    // this should only happen for the first item
    if (this.multiIndex === 0 && this.entityModel) {

      // Simply use the model name instead
      this.set('pseudoValue', this.entityModel.get('name'));

      // Not necessarily the plural of Names always - something to check
      // let modelParams = 'selected' + capitalizeFirstLetter(this.get('entityModel.constructor.modelName')) + 'Names';
      // let modelParamsValues = this.get(modelParams);

      // if (!isEmpty(modelParamsValues) && modelParamsValues[0]) {
      //   this.set('pseudoValue', modelParamsValues[0]);
      // }

      // Alternative implementation - but pills are asyn so beware
      // this.selectedPills.forEach((pill) => {
      //   if (pill.name === this.entityModel.get('name')) {
      //     this.set('pseudoValue', this.entityModel.get('name'));
      //   }
      // })
    }

    if (!this.isFastBoot) {
      this.set('boundKeyDownHandler', this.handleKeyPress.bind(this));
      $(document).on('keydown', this.boundKeyDownHandler);
    }

  },

  // note, @computed here will cause double render
  placeholder: 'Search',

  handleKeyPress() {
    if (!this.didSelect) {
      return
    }

    this.selectedPills.forEach((pill) => {
      if (pill.name === this.didSelectValue) {
        this.pills.removeSelection(pill);
      }
    });

    this.setProperties({
      didSelect: false,
      didSelectValue: null,
      blockClear: true
    });
  },

  _refocus() {
    this.refocusFocusTimer = run.next(this, function() {
      this.$('input').focus();
    });
  },

  updateSearchSuggestions() {
    let value = this.get('pseudoValue') && this.get('pseudoValue').trim();

    run.cancel(this.searchTimer);
    this.set('querying', true);

    if (isEmpty(value)) {
      this.set('querying', false);
      this.set('results', []);
    } else {

      this.set('querying', true);

      // console.log('pill search')
      this.get('store').createRecord('search', {
        searchType: this.get('searchType'),
        value: value,
        polishLevel: this.polishLevel,
        previousSearch: this.get('results'),
        email: getItemFromLocalStorage(getLoccalStorageEmailCapturedKey()),
        // THis needs to be reworked to use ids if we actually want this functionality
        // excludedContinentNames: this.get('selectedContinentNames'),
        // excludedCountryNames: this.get('selectedCountryNames'),
        // excludedAreaNames: this.get('selectedAreaNames'),
        // excludedRegionNames: this.get('selectedRegionNames'),
        // excludedExperienceNames: this.get('selectedExperienceNames'),
        // excludedMonthNames: this.get('selectedMonthNames')
      }).save().then((searchResponse) => {

        run.cancel(this.searchTimer);
        this.searchTimer = run.later(() => {
          this.set('querying', false);
        }, 200);

        this.set('results', searchResponse);
      });

    }
  },

  focusOut() {
    this.set('isFocused', null);
    this.deactivate();
  },

  deactivate() {
    if (!this.get('isDestroyed') && !this.get('isDestroying')) {
      this.set('active', false);
      this.set('currentActive', false);

      if (!this.get('pseudoValue') && (this.get('value'))) {
        console.log('no pseudoValue but value')
      }


      if (this.get('onClose')) {
        this.get('onClose')();
      }

      run.next(() => {
        if (!this.get('isDestroyed') && !this.get('isDestroying') && isEmpty(this.get('selectedPills'))) {
          this.set('pseudoValue', '');
        }
      })
    }
  },

  updateValue(val) {
    this.set('pseudoValue', val);
    this.debounceTimer = run.debounce(this, this.updateSearchSuggestions, val, 350, false);
  },

  selectResult(result /*  type, key */ ) {
    // Note this actually calls through to selectResult in pills mixin!
    this._super(...arguments);
    // if we use shortName it wont match the suggestion pill and will fall out of sync
    this.set('pseudoValue', result.get('name'));
    this.set('active', false);
    this.set('currentActive', false);
    this.set('results', []);
  },

  externalReset() {
    this._super(...arguments);
    run.cancel(this.debounceTimer);
    this.set('pseudoValue', '');
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.debounceTimer);
    run.cancel(this.insertFocusTimer);
    run.cancel(this.refocusFocusTimer);
    run.cancel(this.scrollUpTimer);

    // this causes a double render on milti search
    // we need this to unset scroll lock though!

    this.get('scroll').unset('isSearching');
    this.set('currentActive', false);


    this.set('results', []);
    this.set('pseudoValue', '');
    this.set('value', '');

    if (!this.isFastBoot) {
      $(document).off('keydown', this.boundKeyDownHandler);
    }
  },

  actions: {
    reset() {
      this.resetFilters();
      this._refocus();
    },

    clear(focus = false) {
      this.selectedPills.forEach((pill) => {
        if (pill.name === this.pseudoValue) {
          this.pills.removeSelection(pill);
        }
      });

      if (this.onClear) {
        this.onClear(this.pseudoValue);
      }

      this.set('pseudoValue', '');

      if (focus) {
        this._refocus();
      }
    },

    focusInput() {
      this.$('input').focus();
    },

    keyUp(val) {
      this.updateValue(val);
    },

    onFocus() {
      if (this.selectOnFocus) {
        this.$('input').select();
        this.setProperties({
          didSelect: true,
          didSelectValue: this.pseudoValue
        });
      }

      this.updateSearchSuggestions();

      if (!this.get('isActive')) {
        this.scrollUpTimer = run.next(() => {
          this.get('scroll').to(0, null, 300);
          this.set('active', true);
          this.set('currentActive', true);
        })
      }
    },

    deactivate() {
      this.deactivate();
    }

  }

});
