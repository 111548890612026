import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
/*
  This is a wrapper which manages multiple nested instances
*/

export default Component.extend({
  classNames: ['pill-search-multi'],

  messageBus: service(),

  // instances: 1,
  currentInstanceIdx: 1, // needs to be unique, keep on counting

  instances: [1],

  didInsertElement(){
    this._super(...arguments);
// console.log('did insert multi pill')
    // If we dont do this it doesnt see to reset properly between subroute changes :/
    this.resetMultiSearchPills();
    this.get('messageBus').subscribe('pill-search-multi-search', this, this.resetMultiSearchPills);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.resetMultiSearchPills();
  },

  resetMultiSearchPills() {
    // console.log('resetting ills!')
    let newIdx = (new Date()).getTime();
    this.setProperties({
      instances: [newIdx],
      currentInstanceIdx: newIdx
    });
  },

  actions: {
    add() {
      this.incrementProperty('currentInstanceIdx');
      this.get('instances').pushObject(this.get('currentInstanceIdx'));
    },

    remove(idx) {
      if (this.get('instances.length') === 1) {
        return;
      }
      this.get('instances').removeObject(idx);
    }
  }
});
