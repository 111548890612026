import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({

  loadingRegions: false,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    if (!this.selectedCountry) {
      return
    }

    this.set('loadingRegions', true);

    this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: this.selectedCountry.id}]),
      polishLevel: 'default_agency',
      buster: true,
      per_page: 300
    }).then((regions)=> {

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('regions', this.store.peekAll('region').filterBy('country', this.selectedCountry));

      if (this.get('entity.region') && this.regions.indexOf(this.get('entity.region'))===-1) {
        this.regions.pushObject(this.get('entity.region'))
      }

      if (regions.length==0) {
        this.enableDestinationName()
      }
      this.set('loadingRegions', false)
    })
  },

  @computed('regions', 'selectedCountry')
  filteredRegions(regions, selectedCountry) {

    if (!regions) {
      return;
    }

    if (selectedCountry && regions) {
      return regions.filterBy('regionType', 'place').sortBy('name');
    }
  },

});
