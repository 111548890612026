import { helper } from '@ember/component/helper';

let bookingStateLabels = {
  'all': 'All booking stages',
  'saved': 'Saved trips',
  'quote': 'Quotes',
  'booking': 'Bookings',
  'past': 'Past trips',
  'enquiry': 'Enquiries',
  'deposit_paid': 'Deposit paid',
  'balance_due': 'Balance due',
  'balance_paid': 'Fully paid'
}

export function getBookingStateLabel([bookingState]) {

  return bookingStateLabels[bookingState];
}

export default helper(getBookingStateLabel);
