import TWidgetComponent from 'b5b/components/t-widget/component';

import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default TWidgetComponent.extend({
  classNames: ['edit'],

  tripService: service('trip'),
  // used in sub class templates
  @alias('tripService.savingTrip') savingTrip: false,
  ui: service(),
  settings: service(),

  @alias('tripService.currentTrip') trip: null

});
