import {
  computed
} from 'ember-decorators/object';

import {
  or,
  equal
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import Self from 'b5b/models/block-item';
import {
  task
} from 'ember-concurrency';


export default DS.Model.extend({
  // Template fields
  internalName: DS.attr(),
  templateType: DS.attr(), // this says whether this is a template or a custom block item
  polished: DS.attr(),
  isMaster: DS.attr(),
  region: DS.belongsTo('region', {inverse: null, async: false}),
  lodge: DS.belongsTo('lodge', {inverse: null, async: false}),
  originalUser: DS.belongsTo('user', {inverse: null, async: false}),
  autoIncludeAllExperiences: DS.attr(),


  // regular fields
  blockItemType: DS.attr(), // defines component type to insert
  heading: DS.attr(),
  overview: DS.attr(),
  sequence: DS.attr(),
  originalBlockId: DS.attr(),

  block: DS.belongsTo('block', {
    async: false
  }),
  blockItemExperiences: DS.hasMany('blockItemExperience', {
    async: false
  }),

  @equal('blockItemType', 'checkin') isCheckinBlockItem: false,
  @equal('blockItemType', 'todays-transfers') isTodaysTransfersBlockItem: false,
  @equal('blockItemType', 'todays-locations') isTodaysLocationsBlockItem: false,
  @equal('blockItemType', 'todays-preceding-stages') isTodaysPrecedingStagesBlockItem: false,
  @equal('blockItemType', 'trip-summary') isTripSummaryBlockItem: false,
  @equal('blockItemType', 'about-region') isAboutRegionBlockItem: false,

  @computed('blockItemType')
  isDayByDayBlockItem(blockItemType) {
    return blockItemType;
  },

  // THis ignores autoIncludeAllExperiences and only specifies includes experiences explicityly included via blockItems
  @computed('blockItemExperiences.@each.experience')
  allExperiencesInBlockItems(blockItemExperiences) {

    return blockItemExperiences.reduce(function(result, blockItemExperience) {
      if (blockItemExperience.experience) {
        result.push(blockItemExperience.experience);
      }
      return result;
    }, [])

  },

  loadAllExperiences: task(function* () {
    let experiences = yield this.store.query("experience", {
      isRegional: true,
      per_page: 50,
      filters: JSON.stringify([{type: 'region', id: this.get('block.schedule.stage.regionProxy.id')}]),
    })
    this.set('experiencesLoaded', experiences.toArray())
    return experiences;

  }),

  @computed('blockItemExperiences.@each.experience','autoIncludeAllExperiences', 'block.schedule.stage.regionProxy', 'loadAllExperiences.lastSuccessful.value', 'experiencesLoaded.[]')
  allExperiences(blockItemExperiences, autoIncludeAllExperiences, regionProxy, experiencesFromTask, experiencesLoaded) {
    if (autoIncludeAllExperiences && (regionProxy || experiencesLoaded) ) {
      // Weird thing happens here where the schedule.stage relationship is present when experiences need to be loaded but is goone once they are loaded
      if (!experiencesLoaded) {
        this.get('loadAllExperiences').perform();
      }

      if (experiencesLoaded) {
        return experiencesLoaded
      } else {
        return []
      }
    } else {
      return blockItemExperiences.reduce(function(result, blockItemExperience) {
        if (blockItemExperience.experience) {
          result.push(blockItemExperience.experience);
        }
        return result;
      }, []);
    }
  },

  @computed('heading', 'overview', 'blockItemType')
  title(heading, overview, blockItemType) {
    if (this.isTodaysTransfersBlockItem) {
      // return `${this.get('block.schedule.stage.transfer.vehicleTypeLabel')} ${this.get('block.schedule.stage.transfer.description')}`
      return 'Transfer'
    } else if (this.isTodaysLocationsBlockItem) {
      return 'Location'
    } else if (this.isTodaysPrecedingStagesBlockItem) {
      return 'Transfers and Locations'
    } else if (this.isTripSummaryBlockItem) {
      return 'Trip summary'

    } else {
      return heading || overview;
    }
  },

  @computed('isTodaysTransfersBlockItem', 'isTodaysLocationsBlockItem')
  canEditBlockItem(isTodaysTransfersBlockItem, isTodaysLocationsBlockItem) {
    return !isTodaysTransfersBlockItem && !isTodaysLocationsBlockItem;
  },

  makeCopy() {

    let copy = this.get('store').createRecord('block-item');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    this.get('blockItemExperiences').forEach((blockItemExperience)=> {
      copy.get('blockItemExperiences').pushObject(blockItemExperience.makeCopy())
    })
    return copy;
  }


});
