import DS from 'ember-data';

export default DS.Model.extend({
  name: DS.attr(),
  email: DS.attr(),
  phoneNumber: DS.attr(),
  averageCommission: DS.attr(),
  afterHoursPhoneNumber: DS.attr(),
  afterHoursEmail: DS.attr()
});
