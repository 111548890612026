import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['edit-block-item',],
  ui: service(),
  templateManager: service(),
  entityModals: service(),

  tripService: service('trip'),

  actions: {
    closeMiniContextThen(action) {
      action();
    },
    sortEndActionBlockItemExperiences(blockItem) {
      blockItem.get('blockItemExperiences').forEach((blockItemExperience, index)=> {
        blockItemExperience.set('sequence', index);
      })
    },    
    removeBlockItemExperience(blockItem, blockItemExperience) {
      blockItem.get('blockItemExperiences').removeObject(blockItemExperience);
    },       
  }
});
