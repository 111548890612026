import {
  computed
} from 'ember-decorators/object';
import {
  equal
} from 'ember-decorators/object/computed';

import PeekSliderComponent from 'b5b/components/peek-slider/component';

export default PeekSliderComponent.extend({
  classNames: ['highlight-slider'],

  currentGroupIndex: 0,

  @equal('currentIndex', 0) atFirstGroup: false,

  @computed('currentGroupIndex', 'totalSlides')
  atLastGroup(currentGroupIndex, totalSlides) {
    return currentGroupIndex === totalSlides - 3
  },

  actions: {
    nextGroup() {
      this.$('.slide:eq(' + (this.get('currentGroupIndex') + 3) + ')').velocity('scroll', {
        container: this.$('.viewport'),
        axis: 'x',
        duration: 400
      });

      this.setProperties({
        currentGroupIndex: this.get('currentGroupIndex') + 3
      });
    },

    prevGroup() {
      this.$('.slide:eq(' + (this.get('currentGroupIndex') - 3) + ')').velocity('scroll', {
        container: this.$('.viewport'),
        axis: 'x',
        duration: 400
      });

      this.setProperties({
        currentGroupIndex: this.get('currentGroupIndex') - 3
      });
    }
  }
});