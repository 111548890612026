import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent
} from 'b5b/utils';

export default Controller.extend({

  ui: service(),
  session: service(),
  tripService: service('trip'),
  store: service(),

  actions: {
    saveResults(){
      let user = this.get('session.currentUser');
      this.set('ui.lastWizardTripRecommendations', this.get('tripRecommendations'));
      if (!user) {
        this.set('ui.lastWizardTripRecommendations', this.get('tripRecommendations'));
        trackEvent('save-all-wizard-recommendations:login-challenge:displayed', {
          submissionId: this.model.id
        });
        this.set('ui.showRegisterModal', true);
        return false;
      }
      this.tripService.saveWizardRecommendations();
    },

    closeShowSavedWizardTripsModal(){
      if (this.get('ui.savingWizardTrips')){
        this.get('flashMessages').danger('Please wait while we save the trips.', {
          timeout: 4000,
          sticky: false
        });
        return;
      } else{
        this.set('ui.showSavedWizardTripsModal', false);
        this.set('ui.savingWizardTrips', false);
      }
    }
  }

});
