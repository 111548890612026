import Component from '@ember/component';
import {
  trackEvent
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  scrollToStage: null, //mandatory. Pass this in
  blockIndex: null, //optional - passed in

  locationScrollHistory: service(),
  scroll: service(),

  didInsertElement() {
    this._super(...arguments);
    if (this.scrollToStage) {
      let index = this.scrollToStage;
      let localBlockIndex = this.blockIndex

      let actuallyFocus = function () { 
        let stageSelector = $(".is-component-for-real-stage:eq(" + index + ")");
        if (localBlockIndex) {
          stageSelector = $(`.stage-index-${index}.x-itinerary-stage-schedule`);
        }
        if (stageSelector.length == 0) {
          return run.later(actuallyFocus, 50);
        }
        // stage.set('forceCardOpen', true)
        $("html, body").animate(
          {
            scrollTop:
              stageSelector.offset().top - 100,
          },
          500
        );
      };

      run.schedule("afterRender", actuallyFocus);
    }

  }
});
