import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  AVAILABLE_CURRENCIES
} from 'b5b/services/settings';
import {
  computed
} from 'ember-decorators/object';
import {
  addSecondCurrencyHashContentsIntoFirstHash
} from 'b5b/helpers/format-currency';


export default Component.extend({
  store: service(),
  ui: service(),
  tripService: service('trip'),
  settings: service(),
  classNames: "line-item",
  selectedLineItem: null,
  showGuestAllocationModal: false,

  @alias('tripService.currentTrip') trip: null,

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

  @computed('whitelabel.agency.usesMargin')
  overrideTypes(agencyUsesMargin) {
    let overrideTypes =  ['overridePerGuest']
    console.log(overrideTypes)
    if (this.tripService.timbuktuTripOnPricingModel4) {
      overrideTypes.pushObject('marginPercentageAdjustment')
    } else {  
      if (agencyUsesMargin) {
        overrideTypes.pushObject('marginPercentage')
      } else {
        overrideTypes.pushObject('markupPercentage')
      }
    }

    return overrideTypes;    
  },
  
  didInsertElement() {
    let overPerGuestSet = (Math.abs(parseInt(this.item.overridePerGuest)) >=0);
    let marginPercentageAdjustmentSet = (Math.abs(parseInt(this.item.marginPercentageAdjustment)) >0);
    let markupPercentageSet = (this.item.markupPercentage || (Math.abs(parseInt(this.item.markupPercentage)) >=0)) && !this.whitelabel.agency.usesMargin;
    let marginPercentageSet = (this.item.marginPercentage || (Math.abs(parseInt(this.item.marginPercentage)) >=0)) && this.whitelabel.agency.usesMargin

    if (overPerGuestSet) { 
      this.set('overrideType', 'overridePerGuest');
      this.set('showMoreOptions', true)
    } else if (marginPercentageAdjustmentSet) {           
      this.set('overrideType', 'marginPercentageAdjustment');
      this.set('showMoreOptions', true)
    } else if (marginPercentageSet && !this.tripService.timbuktuTripOnPricingModel4) {
      this.set('overrideType', 'marginPercentage');
      this.set('showMoreOptions', true)
    } else if (markupPercentageSet && !this.tripService.timbuktuTripOnPricingModel4) {
      this.set('overrideType', 'markupPercentage');
      this.set('showMoreOptions', true)            
    } else {
      // Default for drop down if no existing overide
      if (this.tripService.timbuktuTripOnPricingModel4) {
        this.set('overrideType', 'marginPercentageAdjustment');
      } else if (this.whitelabel.agency.usesMargin) {
        this.set('overrideType', 'marginPercentage');        
      } else {
        this.set('overrideType', 'markupPercentage');
      }
    }
  },

  actions: {
    changeOverrideType(overrideType) {
      this.set('overrideType', overrideType)
      if (overrideType == 'overridePerGuest') {
        this.set('item.markupPercentage', null);
        this.set('item.marginPercentage', null);        
      } else {
        this.set('item.overridePerGuest', null);        
      }
    },

    setServiceType(serviceType) {
      this.set('item.serviceType', serviceType);
      this.set('tripService.lineItemsChanged', true);
    },

    decrementMarginAdjustment(item) {

      if (!item.marginPercentageAdjustment) {
        item.set('marginPercentageAdjustment', 0)
      }
      item.set('marginPercentageAdjustment', (parseFloat(item.marginPercentageAdjustment) - 1).toFixed(2))
      this.set('tripService.lineItemsChanged', true);
    },

    incrementMarginAdjustment(item) {

      if (!item.marginPercentageAdjustment) {
        item.set('marginPercentageAdjustment', 0)
      }
      item.set('marginPercentageAdjustment', (parseFloat(item.marginPercentageAdjustment) + 1).toFixed(2))
      this.set('tripService.lineItemsChanged', true);
    }, 

    updateGuestSelection(guest) {
      this.tripService.set('quoteGuestsChanged', true);
      if (this.get('selectedLineItem.selectedGuests').includes(guest)) {

        let guestLineItem = this.get('selectedLineItem.guestLineItems').filterBy('guest', guest);
        if (guestLineItem.length) {
          // we don't need to worry about destroying the record because when the trip is saved then we overwrite the previous version on the server
          this.get('selectedLineItem.guestLineItems').removeObject(guestLineItem.firstObject);
        }
      } else {

        let guestLineItem = this.get('store').createRecord('guestLineItem', {
          guest: guest,
          lineItem: this.get('selectedLineItem')
        });

        this.get('selectedLineItem.guestLineItems').pushObject(guestLineItem);
      }
    },

    removeLineItem(item) {
      this.get('trip.itinerary.quote.actualLineItems').removeObject(item);
      this.set('tripService.lineItemsChanged', true);
    },


    openChooseGuestModal(item) {
      this.set('selectedLineItem', item);
      this.set('showGuestAllocationModal', true);
    },

    closeChooseGuestModal() {
      this.set('showGuestAllocationModal', false);
    },

    addLineItemPartner(item, partner) {
      item.set('partner', partner);
      this.set('tripService.lineItemsChanged', true);
    },
    
    setLineItemCurrency(lineItem, currency) {
      lineItem.set('currency', currency)
      this.set('tripService.lineItemsChanged', true);
    },

    // THis should be used on inputs that are tied to db entries are bigdecimal or numeric instead of integer
    onChangeLineItemCost(event) {
      this.set('tripService.lineItemsChanged', true);
      let value = event.target.value;
      if (value) {
        // We do this so that people can paste in numbers like 2,999.05 and the copy paste works
        value = value.replace(/,/g, '');
        // Here we are making sure that all int values at least have a decimal place of .0
        // Otherwise the integer values are stored as BigDeimals on the server and when they serialized they get .0, which is then considered a dirty attribute after saving
        
        // Ian Apr 2022 This code below is actually not really needed anymore as we are not tracking dirty fields anymore
        // let fullStopPos = value.lastIndexOf('.');
        // if (fullStopPos<0) {
        //   value=value+'.0';
        // } else if (fullStopPos == value.length-1) {
        //   value=value+'0';
        // }
        event.target.value = value;
      } else {
        // You cannot set the value to 0.0, otherwise you cannot clear a value. For example bug where override value could nto be removed
        // event.target.value='0.0';
      }
    },
    
    onChangeLineItemField(event) {
      // What happens is someone clicks into a field, and then clicks out, but the null value is set to empty string.
      // The empty string is serialized as a null though
      // So this field stays as a dirty value
      let value = event.target.value;
      if (value==='') {
        event.target.value=null;
      }
      this.set('tripService.lineItemsChanged', true);
    },

  }
});
