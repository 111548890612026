export default {
  name: "clear-double-boot",
  initialize: function(instance) {

    if (typeof FastBoot === 'undefined') {
      var originalDidCreateRootView = instance.didCreateRootView;
      instance.didCreateRootView = function() {
        let elements = document.querySelectorAll(instance.rootElement + ' .ember-view');
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          element.parentNode.removeChild(element);
        }
        originalDidCreateRootView.apply(instance, arguments);
      };
    }
  }
}