import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  tagName: '',

  entityModals: service(),

  @alias('entityModals.currentModalConfig') currentModalConfig: null,
  @alias('entityModals.currentModalConfig.model') template: null,
  @alias('entityModals.currentModalConfig.stage') stage: null,
  @alias('entityModals.currentModalConfig.stageIndex') stageIndex: null,

});
