import { helper } from '@ember/component/helper';
import {
  SPECAL_TRANSFER_TYPES,
  TRANSFER_TYPE_SELF_DRIVE,
  TRANSFER_TYPE_FLYING
} from 'b5b/models/itinerary';
import {
  VEHICLE_TYPE_SELF_DRIVE,
  VEHICLE_TYPE_COMMERCIAL_FLIGHT,
  VEHICLE_TYPE_LIGHT_AIRCRAFT
} from 'b5b/models/transfer';

export function isTransferOptionRecommended(params, namedArgs) {
  let itineraryTransferType = namedArgs.itineraryTransferType;
  let transferOptionVehicleType = namedArgs.transferOptionVehicleType;
  let transferOptionRecommended = namedArgs.transferOptionRecommended;

  if (SPECAL_TRANSFER_TYPES.includes(itineraryTransferType)) {
    if (itineraryTransferType==TRANSFER_TYPE_SELF_DRIVE && transferOptionVehicleType==VEHICLE_TYPE_SELF_DRIVE) {
      return true;
    } else if (itineraryTransferType==TRANSFER_TYPE_FLYING && [VEHICLE_TYPE_COMMERCIAL_FLIGHT, VEHICLE_TYPE_LIGHT_AIRCRAFT].includes(transferOptionVehicleType)) {
      return true;
    }
    return false;
  } else {
    return transferOptionRecommended;
  }
}

export default helper(isTransferOptionRecommended);
