import Component from '@ember/component';

export default Component.extend({
  classNames: ['video-player'],

  actions: {
    playVideo() {
      let video = this.$('video')
      video.attr('controls','true')
      video[0].play();
      this.set('showPlayButton', false)  
    }
  }
});
