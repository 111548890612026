import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  generateMeta,
  trackEvent,
  getCategoryExperiencesPromise
} from 'b5b/utils';

export default Route.extend({

  store: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return getCategoryExperiencesPromise(this.store);
  },

  headTags() {

    const title = `The greatest experiences in the world | ${this.whitelabel.agencySeoName}`;
    var description = 'From sleeping under a billion stars in the Masai Mara to wandering the temples of India, explore our top experiences and design your very own trip.';

    if (!this.isFastBoot) {
      document.title = title;
    }

    let robots = !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    return generateMeta({
      description,
      'og:title': title,
      link: 'experiences',
      robots
    });
  },



  setupController(controller, model) {
    this._super(...arguments);

    controller.setProperties({
      reset: false,
      firstTimeLoad: false,
      experiences: model
    });
    trackEvent('experiences:categories:view');

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      controller.set('firstTimeLoad', true);
    }
  }
});
