import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  observer
} from '@ember/object';

export default Helper.extend({
  tripService: service('trip'),

  settings: service(),
  onCurrencyChange: observer('settings.currentCurrency', function() {
    this.recompute();
  }),

  // Trip Rate prices are used to show trip rates for specials / packages in a list to guest. THe prices shown
  // need to match up to prices created by pricing agen.t

  compute(params, namedArgs) {
    this.settings.currentCurrency; //Have to access this value in order for the recompute to trigger in the observer
    let val = {};
    val[namedArgs.rate.currency]=namedArgs.rate.perAdult;
    let args = {trip: namedArgs.trip, val, factor: namedArgs.rate.factor  };
    if (Object.keys(namedArgs).length != 2) {
      throw new TypeError('Unsupported args. ONly expecting trip and rate')
    }

    let tripPrice = this.tripService.getTripPrice(params, args);

    return htmlSafe(tripPrice);
  }

});
