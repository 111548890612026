import DS from 'ember-data';

export default DS.Model.extend({
  stageFilter: DS.belongsTo('stageFilter', {
    async: false
  }),
  stage: DS.belongsTo('stage', {
    async: false
  })
});
