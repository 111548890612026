import Component from '@ember/component';
import { run } from '@ember/runloop';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  didInsertElement() {
    let delay = this.delay
    if (delay) {
      run.later(this, function() {
        trackEvent(this.event, this.metadata)
      }, delay);
    } else {
      trackEvent(this.event, this.metadata)
    }

  }
});