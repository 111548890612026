import {
  inject as service
} from '@ember/service';

import Component from '@ember/component';

export default Component.extend({
  tripService: service('trip'),
  router: service(),

  didInsertElement() {
    this.router.transitionTo('trip.index');
  }
});
