import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import {
  computed
} from 'ember-decorators/object';


export default Mixin.create({

  @computed('trip.itinerary.isLoaded', 'trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(itineraryIsLoaded, stages) {
    if (!itineraryIsLoaded) {
      return
    }

    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.isLoaded', 'trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(itineraryIsLoaded, stages) {
    if (!itineraryIsLoaded) {
      return
    }

    return stages.map((stage) => {
      // Note the inverse lat/lng vs pathPoints
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  }

});
