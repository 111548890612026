import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  htmlSafe
} from '@ember/string';

import moment from 'moment';
import CountryModel from 'b5b/models/country';
import {
  computed
} from 'ember-decorators/object';
import {
  bool
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

const monthNames = moment.months();
const monthNamesShort = monthNames.map((str) => str.slice(0, 3));
const monthLetters = monthNames.map((str) => str.slice(0, 1));

export default Component.extend({
  classNames: ['section'],

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  @computed('destinations.firstObject', 'destinations')
  selectedDestination(destination, destinations) {
    let dest = destinations.find(function(dest) {
      return dest instanceof CountryModel;
    }) || destination;
    return dest;
  },


  @computed('destinations.@each.isLoaded', 'selectedDestination')
  destination(destinations, selectedDestination) {
    return selectedDestination || destinations && destinations.get('firstObject');
  },

  @bool('destination.destinationInfo.seasonalRating.length') isVisible: null,

  monthNames,
  monthNamesShort,
  monthLetters,
  selectedMonthIndex: 0,

  @computed('destination.destinationInfo.seasonalRating', 'selectedMonthIndex')
  currentRating(seasonalRating, selectedMonthIndex) {
    if (!seasonalRating || !seasonalRating.objectAt) {
      return;
    }
    return seasonalRating.objectAt(selectedMonthIndex);
  },

  @computed('selectedMonthIndex', 'screen.isMobileOnly', 'screen.width', 'isFastBoot')
  hoverLineOffsetStyle(selectedMonthIndex, isMobile, screenWidth, isFastBoot) {
    if (isFastBoot) {
      return '';
    }
    let el = this.$('.month-circle-wrapper:eq(0)');
    let defSize = isMobile ? screenWidth / 12 : 60;
    let circleWidth = el && el.width() || defSize;
    let offset = selectedMonthIndex * circleWidth - 1;
    return htmlSafe('transform: translateX(' + offset + 'px);' + 'width:' + circleWidth + 'px;');
  },

  actions: {
    selectMonth(index) {
      this.set('selectedMonthIndex', index);
      trackEvent('when-to-go:select-month', {
        month: index
      });
    },
    changeSelectedDestination(destination) {
      trackEvent('when-to-go:select-destination', {
        destination: destination.get('name')
      });
      this.set('selectedDestination', destination);
    }
  }

});