import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({

  classNames: ['start-planning'],

  @computed('entity.kodakOriginalUrl', 'entity.coverStyle')
  backgroundImageStyle(kodakOriginalUrl, coverStyle) {
    let style = `background-image: url(${kodakOriginalUrl || 'https://waybird.imgix.net/general/south-luangwa-walking-safari-wildlife-zambia-timbuktu-travel-video-thumbnail.jpg'}?w=768);`
    if (coverStyle) {
      style = style + " background-position: " + coverStyle;
    }
    return Ember.String.htmlSafe(style);
  },


});
