import { helper as buildHelper } from '@ember/component/helper';
import {
  stringifyNumber
} from 'b5b/utils';

export function stringifiedNumber([n]) {
  return stringifyNumber(n);
}

export default buildHelper(stringifiedNumber);
