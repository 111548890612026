import xSuggestionsComponent from 'b5b/components/x-suggestions/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  maxTripPriceForRange,
  minTripPriceForRange,
  trackEvent
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';


export default xSuggestionsComponent.extend({
  classNames: ['routes'],

  store: service(),
  suggestionTypes: ['routes'],

  polishLevels: ["personal", "custom"],
  startMonth: 'any',
  startYear: 'any',
  lastStartMonth: 'any',
  lastStartYear: 'any',

  // PLEASE DO NOT CHANGE THESE DEFAULTS WITOUT CHANGING THE SERVER SIDE DEFAULTS
  priceRange: {
    min: 0,
    max: maxTripPriceForRange()
  },
  possibleMonths: ['any', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

  @alias('loadEntitiesTask') loadTripsTask: null,

  @computed()
  possibleYears() {
    let currentYear = new Date().getFullYear(),
      years = [];

    // let startYear = moment(currentYear).add(-5, 'years').toDate();

    years.pushObject('any')

    for (var i = currentYear - 5; i < (currentYear); i++) {
      years.pushObject(i);
    }    

    for (var i = currentYear; i <= (currentYear + 5); i++) {
      years.pushObject(i);
    }

    return years;
  },

  @computed('session.currentUser.isManager')
  bookingStates(isManager) {
    let bookingStates = ['all', 'saved', 'quote', 'booking', 'past']
    if (isManager) {
      bookingStates=bookingStates.concat(['enquiry', 'deposit_paid', 'balance_due', 'balance_paid'])
    }

    return bookingStates;
  },
  
  setRecurringDateRange() {  
    // this.set('recurringDateRange', `${this.startMonth}:${this.lastStartMonth}-${this.startYear}:${this.lastStartYear}`);
    this.set('recurringDateRange', [this.startMonth, this.lastStartMonth, this.startYear, this.lastStartYear]);
    this.get('messageBus').publish('suggestion-added',this.recurringDateRange, 'recurring-date-range');
  },

  didInsertElement() {
    this._super(...arguments)
    this.messageBus.subscribe('pill-removed', this, this.checkIfRecurringDateRangeSuggestionRemoved);
    if (this.get('recurringDateRange.length') > 0) {
      this.set('startMonth', this.recurringDateRange[0])
      this.set('lastStartMonth', this.recurringDateRange[1])
      this.set('startYear', this.recurringDateRange[2])
      this.set('lastStartYear', this.recurringDateRange[3])
    }
  },

  checkIfRecurringDateRangeSuggestionRemoved(pill) {
    if (pill.type=='recurring-date-range') {
      this.set('startMonth', 'any')
      this.set('startYear', 'any')
      this.set('lastStartMonth', 'any')
      this.set('lastStartYear', 'any')    
      // recurringDateRange is set back to default in pills service
    }
  },

  actions: {
    setBookingState(state) {
      this.setProperties({
        bookingState: state,
        page: 0
      });
      this.loadTripsTask.perform()
      trackEvent('my-trips:setBookingState:' + state);
    },    
    setPolishLevel(val) {
      this.set('polishLevel', val)
      console.log(val)
      if(val==='personal') {
        this.set('allAccountTrips', false)
        this.set('xplorer.user_id', this.get('session.currentUser.id'))
      } else if(val==='custom') {
        this.set('allAccountTrips', true)
        this.set('xplorer.user_id', null)
      } else {
        this.set('polishLevel',null)
        // this.set('sort', 'priority_asc')
      }
  
      this.get('loadEntitiesTask').perform();
    },    
    selectBudget(val) {
      let _val = val ? val.map(Math.round) : [this.get('minLodgePrice'), this.get('maxLodgePrice')];

      this.set('selectedBudget', _val);
      this.get('messageBus').publish('suggestion-added', _val, 'budget');

      trackEvent('lodges:filter-pane', {
        filterBy: 'budget',
        value: _val
      });
    },
    setStartMonth(month) {
      this.set('startMonth', month)
      this.setRecurringDateRange();
    },
    setStartYear(year) {
      this.set('startYear', year)
      this.setRecurringDateRange();
    },    
    setLastStartMonth(month) {
      this.set('lastStartMonth', month)
      this.setRecurringDateRange();
    },
    setLastStartYear(year) {
      this.set('lastStartYear', year)
      this.setRecurringDateRange();
    },    


    updateBudget([min, max]) {
      this.budgetTimer = run.next(() => {
        this.set('minPrice', Math.round(min));
        this.set('maxPrice', Math.round(max));
      });
    }       
  }

});
