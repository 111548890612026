import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  FLYWIRE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';

export default payFormComponent.extend({

  chargeType: 'paygenius',

  classNames: ['pay-form'],

  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  didInsertElement() {
    this._super(...arguments);
    // Note that currently the paymentsDue collection is re-created when the currency is changed.
    // IF this implementation changes at all, then this will intent will need to be re-created whenever the users preferrred currency changes

    throw new TypeError('This is not compatible with latest charges implementation.');

    if (this.paymentSucceeded) {
      return this.handleSuccesfulPayment('Payment completed by returning to success url from PayGenius')
    }
    let transactionParams = {
      amount: this.tripService.getTripPrice({}, {val: this.paymentDue.priceHash, toCurrency: this.paymentDue.settlementCurrency, trip: this.get('trip'), formatPrice: false}),
      currency: this.paymentDue.settlementCurrency,
      payment_type: this.paymentDue.paymentType
    }
    if (this.paymentDue.customPayment) {
      transactionParams.payment_id = this.paymentDue.customPayment.id
    }

    this.get('trip').createPaygeniusRedirect(transactionParams).then((response)=> {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return
      }
      this.set('paymentDue.redirectUrl', response.redirect_url);
      this.set('_payButtonReady', true);
    })
  },

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return FLYWIRE_CURRENCIES.includes(settlementCurrency) ? settlementCurrency : 'usd';
  },

  @computed('session.currentUser.email')
  canSeeChargeLink(email) {
    return email === 'johnny.prince@timbuktutravel.com' || email === 'ian.petzer@timbuktutravel.com' || email === 'gareth.jones@timbuktutravel.com' || email === 'ianpetzer+consultant@gmail.com';
  },

});



