import { helper } from '@ember/component/helper';

export function subtractTripRatePrice(params, namedArgs) {
  let fromTripRateDupe = JSON.parse(JSON.stringify(namedArgs.from))
  fromTripRateDupe['perAdult']=parseInt(fromTripRateDupe['perAdult'])-parseInt(namedArgs.val.perAdult)

  return fromTripRateDupe;
}

export default helper(subtractTripRatePrice);
