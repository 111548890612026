import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    multiCurrency: {
      serialize: 'records'
    }
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards

    json.multi_currency_attributes = json.multi_currency;
    delete json.multi_currency;

    return json;
  }
});