import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  htmlSafe
} from '@ember/string';
import {

  getBlockTitle
} from 'b5b/utils';


export default Helper.extend({

  tripService: service('trip'),
  whitelabel: service(),
  settings: service(),

  compute(params) {
    let blockTitle = getBlockTitle(this.get('tripService.currentTrip'), this.whitelabel);
    return htmlSafe(blockTitle);
  }

});
