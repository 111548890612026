import { helper } from "@ember/component/helper";

export function userCanEditLodge(params, namedArgs) {
  //Can have unlogged in users
  let isManager = namedArgs.user ? namedArgs.user.isManager : false;
  let userId = namedArgs.user ? namedArgs.user.id : null;

  if (
    isManager &&
    namedArgs.lodge &&
    namedArgs.lodge.isCustom &&
    ( namedArgs.lodge.userOwnerId == userId || !namedArgs.lodge.userOwnerId)
  ) {
    return true;
  }
  return false;
}

export default helper(userCanEditLodge);
