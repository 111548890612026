import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent
} from 'b5b/utils';
import $ from 'jquery';

export default Component.extend({

  tripService: service('trip'),
  ui: service(),
  scroll: service(),
  router: service(),


});
