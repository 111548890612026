import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember/service';

export default Route.extend({

  store: service(),

  beforeModel(transition) {
    this._super(...arguments);
    if (this.get('whitelabel.isForAgency')) {
      transition.abort();
    }
  },

  model() {
    return this.modelFor('users');
  },

  setupController(controller, model) {
    this._super(...arguments);
    if (model.get('tripFavourites.length')>0) {
      let ids = model.get('tripFavourites').mapBy('tripId');
      let query = {
        per_page: 40,
        ids
      };
      this.store.query('tripSummary', query).then((tripSummaries) => {
        controller.set('favouritedTripSummaries', tripSummaries);
      });
    }
  },

  activeTab: 'lodges'
});
