import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  ui: service(),
  settings: service(),
  session: service(),
  headTagsService: service('head-tags'),
  headData: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('month', model);
    trackEvent('month:view');

    let links = [{
      name: 'Overview',
      params: {
        routeName: 'when-to-go.month.index',
        routeModel: model
      }
    }, {
      name: 'Trips',
      params: {
        routeName: 'when-to-go.month.routes',
        routeModel: model
      }
    }, {
      name: 'Countries',
      params: {
        routeName: 'when-to-go.month.countries',
        routeModel: model
      }
    }]

    this.get('session.currentUserPromise').then((user) => {
      if (user && (user.get('isTimbuktu') || user.get('isManager'))) {
        links.pushObject({
          name: 'Tools',
          params: {
            action: 'ui.viewedEntityToolsModal',
            actionProperty: model
          }
        });
      }

      this.get('ui').setProperties({
        entityNavData: {
          entity: model,
          title: model.get('name'),
          titleLink: {
            routeName: 'when-to-go.month',
            routeModel: model
          },
          links: links
        }
      });
    })

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (!isExiting) {
      // causing issues on mobile when transitioning from mapView to experience - disabling for now
      // this.get('scroll').to(0, this);
    } else {
      this.set('ui.entityNavData', null);
    }
  }

});
