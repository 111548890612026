import xSuggestionsComponent from 'b5b/components/x-suggestions/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default xSuggestionsComponent.extend({
  classNames: ['experiences'],
  suggestionTypes: ['experiences'],  

  @alias('loadEntitiesTask') loadExperiencesTask: null,
  

  init() {
    this._super(...arguments);
    this.get('messageBus').subscribe('listing-map-position-changed', this, this.loadSuggestions);
  },



});
