import {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';
import {
  reads
} from '@ember/object/computed';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';

import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';

import {
  showInViewPort,
  trackEvent
} from 'b5b/utils';
import lodgeOption from '../../models/lodge-option';

export default Component.extend({

  classNames: ['x-itinerary'],

  store: service(),
  whitelabel: service(),
  router: service(),
  ui: service(),
  fastboot: service(),
  templateManager: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  tripService: service('trip'),

  @alias('tripService.newLodge') newLodge: null,
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.currentStageIndex') stageIndex: null,
  @alias('tripService.newStage') newStage: null,
  @alias('tripService.numNights') numNights: null,
  @alias('tripService.dontBook') dontBook: null,
  @alias('trip.itinerary.stages') stages: null,

  stageIndexes: [], //see keepStageIndexesInSync comment

  locations: [],

  isPartnerEditable: false,
  showingShouldDeleteModal: false,
  stageToRemove: null,
  tripLocation: null, //Used to determine where this itinerary is inserted to differentiate analytics reporting

  init() {
    this._super(...arguments);

    if (this.get('isPartnerEditable')) {
      let locations = this.get('store').peekAll('location').sortBy('name');
      this.set('locations', locations);
    }
  },

  keepStageIndexesInSync: observer('trip.itinerary.stages.length', function() {
    //This is a bit ridiculous but basically req  uired due to draft trips. When a trip is saved, the draft trip is replaced.
    //If you iterate over trip stages directly then all of the components in the x-itinerary edit view get re-inserted as all the stages
    //are new draft stages after save.
    // By iterating over a fixed array and fixed indexes, the stages are not re-inserted and we use the let helper on the template to access the stage
    // This would not be needed if we used real trips directly instead of via draft trips proxy
    // stageIndexesFreshness causes the array-item-by-index to recalculate every time this observer does. Otherwise you end up pointing at old stages that no longer belong to an itinerary after a save
    if (!this.trip) {
      return;
    }


    let stagesLength = this.trip.itinerary.stages.length;
    let stageIndexesLength = this.stageIndexes.length;
    if (stagesLength > stageIndexesLength) {
      for (var i = stageIndexesLength; i < stagesLength; i++) {
        this.stageIndexes.pushObject(i)
      }
    } else {
      for (var i = stagesLength; i < stageIndexesLength; i++) {
        this.stageIndexes.popObject(i)
      }
    }
    this.set('stageIndexesFreshness', (new Date()).getTime())

  }).on('init'),

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES.map((cur, index) => {
      return {
        name: cur,
        symbol: CURRENCY_SYMBOLS[index]
      };
    });
  },

  actions: {

    doRemoveStage() {
      this.get('stages').removeObject(this.get('stageToRemove'));
      this.setProperties({
        showingShouldDeleteModal: false,
        stageToRemove: null
      });
      trackEvent(`${this.get('tripLocation')}:remove-lodge`, {
        tripModifyFlag: true
      });
      this.get('tripService').madeChanges();
    },

    willRemoveStage(stage) {
      this.setProperties({
        showingShouldDeleteModal: true,
        stageToRemove: stage
      });
    },

    setStartDate(startDate) {
      this.set('trip.itinerary.startDate', startDate);
      this.get('tripService').madeChanges();
      trackEvent(`${this.get('tripLocation')}:set-start-date`);
    },
    expandAllStages() {
      this.get('trip.itinerary.stages').forEach((stage)=> {
        stage.set('forceCardOpen', true)
      })
    },
    closeAllStages() {
      this.get('trip.itinerary.stages').forEach((stage)=> {
        stage.set('forceCardClosed', true)
      })
    },
    closeMiniContextThen(action) {
      action();
    },
  }
});
