import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';


export default Helper.extend({

  store: service(),

  compute(params, namedArgs) {
    let guest = this.store.peekRecord('guest', parseInt(namedArgs.guestId));
    if (guest) {
      return htmlSafe(guest.name);
    }
  }

});
