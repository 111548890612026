import { computed } from '@ember/object';
import DS from 'ember-data';

export default DS.Model.extend({
  user: DS.belongsTo('user', {
    async: false
  }),
  trip: DS.belongsTo('trip', {
    async: true
  }),
  tripSummary: DS.belongsTo('trip-summary', {
    async: true,
    inverse: null
  }),

  tripId: computed('trip', 'tripSummary', function() {
    return this.belongsTo('trip').id() || this.belongsTo('tripSummary').id();
  })

});
