import DS from 'ember-data';
import {
  ActiveModelSerializer
} from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {
    currencyContributions: {
      serialize: 'records'
    }
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards

    json.currency_contributions_attributes = json.currency_contributions;

    return json;
  }

});