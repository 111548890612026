import { inject as service } from "@ember/service";
import Controller, { inject as controller } from "@ember/controller";
import { computed } from "ember-decorators/object";
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  reads
} from 'ember-decorators/object/computed';

export default Controller.extend(UseCurrentTrip, {
  store: service(),
  ui: service(),
  tripService: service("trip"),
  tripIndexEditController: controller("trip.index.edit"),

  @reads('tripService.hideAllPrices') readOnlyHideAllPrices: null,

  actions: {
    toggleHideAllPrices() {
      if (this.tripService.currentTrip.displayPrices=='standard') {
        this.set('tripService.currentTrip.displayPrices', "hide-all")
      } else {
        this.set('tripService.currentTrip.displayPrices', "standard")
      }

    },
    addLineItem() {
      if (
        !this.get("trip.itinerary.quote.sourceCountry") ||
        !this.get("trip.itinerary.quote.settlementCurrency")
      ) {
        this.get("ui").showGeneralMessage(
          "Oops",
          "Please select source country and settlement currency before adding pricing"
        );
        return;
      }

      // We specify these defaults so that we can't possibly get validation errors when we save
      // We also need to specify net here as users can only create net line items now
      let a = this.get("store").createRecord("actualLineItem", {
        currency: "usd",
        originalRateType: "net",
        forceCardOpen: true,
        useTimbuktuAutoMarkup: true
      });

      // if you remove all the line items from a quote and save it to the server,
      // the serialized response doesn't include a quote so you need to create a new one when someon adds a line item
      this.tripService.ensureTripHasQuote(this.trip);

      this.get("trip.itinerary.quote.actualLineItems").pushObject(a);
      this.set("tripService.lineItemsChanged", true);
    },

    // This should be used on inputs that are tied to db entries are BigDecimals or numeric instead of integer
    onChangeLineItemCost(event) {
      this.set("tripService.lineItemsChanged", true);
      let value = event.target.value;
      if (value) {
        // We do this so that people can paste in numbers like 2,999.05 and the copy paste works
        value = value.replace(/,/g, "");
        // Here we are making sure that all int values at least have a decimal place of .0
        // Otherwise the integer values are stored as BigDecimals on the server and when they serialized they get .0, which is then considered a dirty attribute after saving

        // Ian Apr 2022 This code below is actually not really needed anymore as we are not tracking dirty fields anymore
        // let fullStopPos = value.lastIndexOf('.');
        // if (fullStopPos<0) {
        //   value=value+'.0';
        // } else if (fullStopPos == value.length-1) {
        //   value=value+'0';
        // }
        event.target.value = value;
      } else {
        // You cannot set the value to 0.0, otherwise you cannot clear a value. For example bug where override value could nto be removed
        // event.target.value='0.0';
      }
    },
  },
});
