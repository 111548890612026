import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  observer
} from '@ember/object';


export default Helper.extend({

  xplorer: service('components/x-plorer'),

  compute(params, namedArgs) {
    return this.xplorer.isFilterPresent(namedArgs.model);
  },

  onFilterChange: observer('xplorer.filters.[]', function() {
    this.recompute();
  }),  

});