import { inject as service } from "@ember/service";
import xItineraryStage from "b5b/components/x-itinerary/stage/component";
import { computed } from "ember-decorators/object";
import { alias } from "ember-decorators/object/computed";

export default xItineraryStage.extend({
  classNames: ["edit"],

  ui: service(),
  tripService: service("trip"),
  entityModals: service(),
  templateManager: service(),

  @alias("tripService.currentTrip.itinerary.stages") stages: null,
  @alias("tripService.tripDatePickerInstance") datePickerInstance: null,
  @alias("tripService.currentTrip") trip: null,

  actions: {
    closeMiniContextThen(action) {
      action();
    },
    openTripSorting(){
      this.set('tripService.tripSortingModal', {
        view: 'list'
      });
    },
  },
});
