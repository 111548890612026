import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  getErrorsHashFromServerResponse,
  trackEvent
} from 'b5b/utils';

export default Controller.extend({


});
