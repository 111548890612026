import Component from '@ember/component';

export default Component.extend({
  tagName: 'li',
  classNames: ['suggestion-tab', 'selected'],

  didInsertElement() {
    if (['lodge', 'region', 'experience'].includes(this.pill.type)) {
      this.store.findRecord(this.pill.type, this.pill.id, {buster: true}).then((pillEntity) => {
        this.set('pillEntity', pillEntity)      
      })  
    }
  },  

  click() {
    this.sendAction('removeSelection', this.pill)
  }
});
