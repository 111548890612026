import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed,
  observes
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';

export default Component.extend({

  /*

  Original order of logic:
  ===============

  1. setupController
    - run visitorOnProtectedRouteHandler
      - get user promise
        - if user isTimbuktu or currentTripUserEmails contains currentUser.email
          - allow
        - else if controller
          - if currentUser
            - loggedInAsSomeoneElse
          - else
            - prompt register

  2. sessionAuthenticated observer
    - if authentcated
      - if showSaveTripModalAfterLogin
        - showTripNamingModal
        - tripToBeSavedAfterLogin - null

    - showSaveTripModalAfterLogin
      - false

  3. currentUserChanged observer (session.currentUser.email)
    - if currentTrip has id
      - query trip
        - then
          - if currentTripUserEmails contains currentUser.email
            - create draft trip from trip
          - if on trip.index.quote && visitorOnProtectedRouteHandler
           - run visitorOnProtectedRouteHandler




  Different scenarios
  ----------------------
  - User that is unauthenticated
  - User that is unauthenticated but has allocated email, name and surname (invited) and not logged in
  - User that is unauthenticated but has allocated email, name and surname (invited) and logged in
  - User that is authenticated but not trip owner
  - User that is authenticated and is trip owner
  - User that is consultant
  - User that is Timbuktu



  Laymans Process
  -----------

  Initial setup and checks:

  Check if accessing protected route, if so, proceed, else pass

  Check special user permissions (visitorOnProtectedRouteHandler)
    if isTimbuktu or a trip user then allow
    else if logged in, prompt to log into valid trip account
    else prompt register if pending invites exist or else login

  In case of session.isAuthenticated change do
    if true
      if showSaveTripModalAfterLogin
        show modal
    reset showSaveTripModalAfterLogin

  In case of session.currentUser.email change
    get visitorOnProtectedRouteHandler
    if currentTrip has id, query for it
      then if reloaded trip has the current user associated they are given access
    if on quote route
      run visitorOnProtectedRouteHandler


  */

  store: service(),
  ui: service(),
  router: service(),
  tripService: service('trip'),
  session: service(),
  whitelabel: service(),

  @alias('session.currentUser') currentUser: null,
  @alias('tripService.currentTrip') currentTrip: null,

  @alias('tripService.showTripNamingModal') showTripNamingModal: false,
  @alias('tripService.showSaveTripModalAfterLogin') showSaveTripModalAfterLogin: false,
  @alias('tripService.tripToBeSavedAfterLogin') tripToBeSavedAfterLogin: false,

  refreshCheckTimer: null,
  registerVisible: true, //Show the register option by default.. it will have showForm=false so people can choose login or register

  protectedRoutes: [
    'trip.index.dashboard',
    'trip.index.deposit-success',
    'trip.index.edit.', //IF you make it just trip.index.edit then it will also catch the editor
    'trip.index.pay',
    'trip.index.contribute',
    'trip.index.login'
  ],

  showRegisterForm: false,
  allocatedEmailIsRegistered: false,

  didInsertElement() {
    this._super(...arguments);

    if (!this.get('allocatedEmail')) {
      return;
    }

    if (this.get('allocatedEmailIsRegistered')) {
      this.set('registerVisible', false);
    } else {
      this.set('showRegisterForm', true);
    }
  },

  checkTripRefresh() {

    // If you log in as a manager you get browser refresh anyway
    //If trip belongs to user and is stateQuote, so they can't make changes, then refresh the trip so that all the correct data shows up on dashboard
    // an anonymous user doesnt get all the data around charges and other users etc.
    // Dont refresh the trip if beforeState quote because otherwise you lose unsaved changes to routes and user trips

    if (this.get('currentTrip.userTrip') && this.get('currentTrip.itinerary.stateQuoteAndAfter')) {
      return this.get('store').queryRecord('trip', {
        id: this.get('currentTrip.friendlyId'),
        buster: true
      });
    }

  },

  @computed('router.currentRouteName', 'protectedRoutes', 'currentTrip.userTrip', 'currentTrip.onlyTripUsersCanView')
  accessingProtectedRoute(currentRouteName, protectedRoutes, isUserTrip, onlyTripUsersCanView) {
    // console.log('accessingProtectedRoute: ', currentRouteName);
    let boolean = false;
    if (this.whitelabel.isOnboardingAgency && currentRouteName.includes('trip.index.edit.')) {
      return false;
    }

    if (onlyTripUsersCanView) {
      return true
    }
    protectedRoutes.forEach((route) => {
      if (currentRouteName.includes(route)) {
        boolean = true;
      }
    });
    // if (currentRouteName==='trip.index.quote' && isUserTrip) {
    //   boolean = true;
    // }
    return boolean;
  },

  @computed('currentUser', 'tripService.currentTripUserEmails.@each')
  currentUserIsCurrentTripUser(currentUser, currentTripUserEmails) {
    return currentUser && currentTripUserEmails.includes(currentUser.get('email'));
  },

  @observes('session.isAuthenticated')
  sessionAuthenticated() {
    if (!this.get('session.isAuthenticated') || (!this.get('showSaveTripModalAfterLogin') && !this.get('tripService.unsavedTripChangesTransition'))) {
      return;
    }

    if (this.get('tripService.unsavedTripChangesTransition')) {
      this.get('tripService').saveChanges({
        bypass: true,
        manualTransition: true,
        fromSection: 'unsaved-changes-modal'
      });
      this.get('tripService.unsavedTripChangesTransition').retry();
    } else {
      this.setProperties({
        showTripNamingModal: {},
        tripToBeSavedAfterLogin: null,
        showSaveTripModalAfterLogin: false
      });
    }
  },

  @observes('currentUser.email')
  currentUserChanged() {
    run.cancel(this.refreshCheckTimer);
    this.refreshCheckTimer = run.next(() => this.checkTripRefresh());
  },

  @computed('currentUser', 'currentUserIsCurrentTripUser')
  loggedInAsSomeoneElse(currentUser, currentUserIsCurrentTripUser) {
    return currentUser && !currentUserIsCurrentTripUser;
  },

  @computed('accessingProtectedRoute', 'currentUser', 'currentUserIsCurrentTripUser', 'currentTrip', 'currentUser.email', 'tripService.userCanManageThisTrip', 'allocatedEmail', 'currentUser.isTimbuktu', 'currentUser.isManager', 'router.currentRouteName', 'currentTrip.itinerary.isInstantBookable', 'currentTrip.onlyTripUsersCanView')
  accessGranted(accessingProtectedRoute, currentUser, currentUserIsCurrentTripUser, currentTrip, currentUserEmail, userCanManageThisTrip, allocatedEmail, isTimbuktu, userIsManager, currentRouteName, isInstantBookable, onlyTripUsersCanView) {


    // This needs to be first to avoid rendering the login component in page
    if (!accessingProtectedRoute) {
      return true;
    }

    if (!currentUserEmail) {
      //  This CP can only resolve properly if there is a current user email. Lets just make sure that the current user has been resolved

      this.get('tripService').sendEvent({
        eventType: 'access:blocked:' + currentRouteName,
        allocatedEmail: allocatedEmail
      });

      return false;
    }

    if (onlyTripUsersCanView && !(currentUserIsCurrentTripUser || userIsManager || currentUserEmail=='pdfgen@timbuktutravel.com')) {
      return false;
    }

    if (currentUserIsCurrentTripUser || userCanManageThisTrip || userIsManager) {

      this.get('tripService').sendEvent({
        eventType: 'access:granted:' + currentRouteName,
        allocatedEmail: currentUserEmail
      });

      return true;
    }

    // Anyone can access the pay page. THey just have to be logged in
    if ((currentRouteName.includes('trip.index.pay') || currentRouteName.includes('trip.index.contribute')) && currentUser) {
      return true;
    }

    this.get('tripService').sendEvent({
      eventType: 'access:blocked:' + currentRouteName,
      allocatedEmail: currentUserEmail
    });

    return false;
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.refreshCheckTimer);
  },

  actions: {
    showRegisterWithShowFormTrue() {
      this.set('registerVisible', true);
      this.set('showRegisterForm', true);
    }
  }

});
