import Route from '@ember/routing/route';
import EntitiesLodgesRouteMixin from 'b5b/mixins/entities/lodges/route';
import {
  generateMeta,
} from 'b5b/utils';

export default Route.extend(EntitiesLodgesRouteMixin, {

  headTags() {
    let title = `Best hotels and safari lodges in the world | ${this.whitelabel.agencySeoName}`;
    let robots = "index, follow"
    var description = `Discover the best hotels and safari lodges in the world, with customizable trip ideas and travel experts to give you all the help and advice you need.`;

    if (!this.isFastBoot) {
      document.title = title;
    }

    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    return generateMeta({
      description,
      robots,
      'og:title': title,
      link
    });
  },

});
