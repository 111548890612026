import THeroComponent from 'b5b/components/t-hero/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default THeroComponent.extend({

  screen: service(),
  tripService: service('trip'),

  @computed ('tripService.currentTrip.name')
  tripNameSizeClass (tripName) {
    let size = 'normal';
    if (tripName.length > 100 ) {
      size = 'large';
    }
    if (tripName.length > 200 ) {
      size = 'x-large';
    }
    if (tripName.length > 300 ) {
      size = 'xx-large';
    }
    return size;
  },

  actions: {
    playVideo() {
      
      if (this.screen.isMobileOnly) {
        let vid = document.getElementById("VideoTagForTripClip")
        $(vid).attr('controls','true')
        vid.play();
        this.set('showTripPlayButton', false)  
        this.set('videoStarted', true)
      } else {
        this.set('showTripClipInModal', true)
      }
      return false;
    },
    showTripImagesGallery() {
      if ((this.screen.isMobileOnly && this.videoStarted)) {
        //Do nothing.. let the video tag handle it
      } else {
        this.set('showGalleryByUrl', true)
      }           
    }
  }
});
