import Component from '@ember/component';
import {
  or
} from 'ember-decorators/object/computed';

export default Component.extend({
  tagName: '',
  @or('transfer', 'stage.entity') actualTransfer: true

});
