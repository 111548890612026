import Helper from '@ember/component/helper';
import {
  A
} from '@ember/array';
import {
  set,
  get,
  observer
} from '@ember/object';

export default Helper.extend({

  arrayLengthObserver: observer('lineItem.perGuest', 'lineItem.marginAfterTransactionFees', 'lineItem.originalPerGuest', function() {
    this.recompute();
  }),

  compute([lineItem]) {

    set(this, '_lineItem', lineItem);

    //The check for perGuest less than originalPerGuest is a little unnecessaty due to margin check but it eals with case where perGuest is zero
    let marginAfterTransactionFees = lineItem.marginAfterTransactionFees && parseFloat(lineItem.marginAfterTransactionFees);
    let perGuest = lineItem.perGuest && parseFloat(lineItem.perGuest);
    let originalPerGuest = lineItem.originalPerGuest && parseFloat(lineItem.originalPerGuest);

    if ((Math.round(marginAfterTransactionFees * 100) / 100) < 0 || perGuest <= originalPerGuest) {
      return true;
    }

    return false;
  }
});
