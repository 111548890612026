import {
  once
} from '@ember/runloop';
import {
  on
} from '@ember/object/evented';
import EmberRouter from '@ember/routing/router';

export function initialize(application) {

  if (typeof FastBoot !== 'undefined') {
    return;
  }


  var logTracking = application.get('LOG_TRACKING');
  if (!window.timbuktu.preRendering && !window.timbuktu.pdfGen && window.location.host.indexOf('localhost') < 0) {

    EmberRouter.reopen({

      notifyLeadIn: on('didTransition', function() {
        once(this, function() {
          if (logTracking) {
            console.info('Tracking Lead In pageview:', this.url);
          }
          if (window._hsq) {
            window._hsq.push(['trackPageView'])
          }
        });
      }),

      /*
       * Push the page transition to the
       * Bing analytics engine.
       *
       * @method notifyBing
       */
      notifyBing: on('didTransition', function() {
        once(this, function() {
          if (logTracking) {
            console.info('Tracking Bing pageview:', this.url);
          }

          window.uetq = window.uetq || [];
          window.uetq.push('event', 'page_view');
        });
      }),

      /*
       * Push the page transition to
       * Facebook.
       *
       * @method notifyFacebook
       */
      notifyFacebook: on('didTransition', function() {
        once(this, function() {
          if (window && typeof window.fbq !== 'undefined') {
            window.fbq('track', 'PageView');
          }
        });
      }),

      /*
       * Push the page transition to
       * Inspectlet.
       *
       * @method notifyInspectlet
       */
      notifyInspectlet: on('didTransition', function() {
        once(function() {
          if (window && typeof window.__insp !== 'undefined') {
            window.__insp.push(['virtualPage']);
          }
        });
      }),

      /*
       * Enable Optimizely experiments
       * for the route.
       *
       * @method notifyOptimizely
       */
      notifyOptimizely: on('didTransition', function() {
        once(function() {
          if (window && typeof window.optimizely !== 'undefined') {
            window.optimizely.push(['activate']);
          }
        });
      })
    });

  }

}



export default {
  name: 'pageview',
  initialize
};
