import Component from '@ember/component';

export default Component.extend({
  classNames: ['slide'],

  onInsert: null, // closure

  didInsertElement() {
    this._super(...arguments);

    if (this.get('parentView.didInsertSlide')) {
      this.parentView.didInsertSlide(this.elementId);
    }
  }

});
