import {
  helper
} from '@ember/component/helper';

export function depluraliseSimple(params, namedArgs) {
  let plural = namedArgs.plural;
  let output = plural;

  if (plural && plural[plural.length - 1] == 's') {
    output = output.substring(0, plural.length - 1);
  }

  return output;
}

export default helper(depluraliseSimple);
