import { helper } from '@ember/component/helper';

import {
  yearNameFromNumber
} from 'b5b/utils';

export function yearNameFromNumberHelper([yearNumber]) {
  return yearNameFromNumber(yearNumber)
}

export default helper(yearNameFromNumberHelper);
