import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  AVAILABLE_CURRENCIES
} from 'b5b/services/settings';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({
  tagName: '',
  tripService: service('trip'),
  templateManager: service(),

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES;
  },

});
