import Component from '@ember/component';

export default Component.extend({
  classNames: ['slide', 'see-all'],

  didInsertElement() {
    this._super(...arguments);

    if (this.get('parentView.didInsertSlide')) {
      this.parentView.didInsertSlide(this.elementId);
    }
  }

});
