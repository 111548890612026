import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  trackEvent,
  getTermsAndConditionsUrlForTrip
} from 'b5b/utils';
import {
  reads
} from 'ember-decorators/object/computed';

export default Controller.extend(UseCurrentTrip, {
  settings: service(),
  ui: service(),
  screen: service(),
  whitelabel: service(),
  tripService: service('trip'),

  @reads('trip.itinerary.termsAcceptedDateTime') readOnlyTermsAcceptedDateTime: null,
  isLoading: null,

  actions: {
    acceptTermsAndConditions() {

      if  (this.get('session.currentUser.isManager')) {
        this.get('ui').showGeneralMessage('Oops!', `As a travel specialist, you cannot accept these terms and conditions. Your customers are required to accept these terms and conditions before the payment button is enabled for them.`);
        return false;  
      }
      
      // With paypal we need to force user to accept terms and conditions first as we can't stop them from clicking the paypal button      
      if (!this.get('trip.itinerary.termsAcceptedDateTime')) {
        // We are going to manually set the terms accepted date and time as we don't refresh the trip from the server
        this.ui.set('showProcessingModal', true);
        this.get('trip.itinerary').setTermsAcceptedDateTime({terms_accepted_url: getTermsAndConditionsUrlForTrip(this.trip)}).then((response) => {
          this.set('trip.itinerary.termsAcceptedDateTime', new Date());
          this.ui.set('showProcessingModal', false);
          // DONT REFRESH THE TRIP AFTER ACCEPTING TS AND CS.. IT CAUSES THE ARRAY OF PAYMENTS DUE TO REFRESH
          // this.get('store').pushPayload(response);
          // this.set('isLoading', false);
        });
      }
    }
    
  }
});
