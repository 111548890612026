import StepComponent from 'b5b/components/booking-steps/step/component';
import {
  computed
} from 'ember-decorators/object';

export default StepComponent.extend({

  // exception to the rule - dont care about insurance details
  @computed('trip.itinerary.allGuestsPersonalDetailsComplete')
  current(allGuestsPersonalDetailsComplete) {
    return allGuestsPersonalDetailsComplete;
  }

});