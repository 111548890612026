import MagazineStageComponent from 'b5b/components/trip-stages/magazine/stage/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';
import {
  shouldShowStageSchedule,
  getSortedTripIdeas
} from 'b5b/utils';

export default MagazineStageComponent.extend({
  classNames: ['destination'],
  lodgeUtils: service(),
  tripService: service('trip'),
  recommendedLodges: null,
  recommendedLodgeCover: null,
  backupExperience: {
    kodakOriginalUrl: "https://waybird.imgix.net/assets/images/destination-fallback/Mountains_3.jpg",
    coverStyle: "center center"
  },

  init() {
    this._super(...arguments);
    if (!this.stage.lodge) {
      this.trip.tripIdeasPromise.then(()=> {
        this.getRecommendedLodges()
      })
    }  
  },
  
  getRecommendedLodges () {
    let recommendedLodges = [];
    const { lodgeIdeas, lodgeQueryPromise } = this.lodgeUtils.getLodgeIdeas(this.trip, this.stage, false);
    lodgeQueryPromise.then(() => {
      
      lodgeIdeas.forEach((lodgeIdea) => {
        let lodgeIdeaMeta = { 
          originalUrl: lodgeIdea.lodge.kodakOriginalUrl,
          coverStyle: lodgeIdea.lodge.coverStyle,
          description: lodgeIdea.lodge.name,
        };
        recommendedLodges.pushObject(lodgeIdeaMeta)
      });
      if (recommendedLodges.length == 0) {
        // Backup in case no lodges in region
        recommendedLodges = [{
          originalUrl: "https://waybird.imgix.net/assets/images/destination-fallback/Mountains_1.jpg",
          coverStyle: "center center"
        }]        
      }      


      this.set('recommendedLodgeCover', recommendedLodges[0]);
      this.set('recommendedLodges', recommendedLodges);
    });
  },

  @computed('stage.schedule.allExperiences', 'stage.overrideScheduleKodakImageUrl')
  experienceImages(allExperiences, overrideScheduleKodakImageUrl) {
    if (allExperiences) {
      let experienceImages = [];
      //The first image is the cover experience, unless there is an image override, then we include all
      let includeFromIndex = 1;
      if (overrideScheduleKodakImageUrl) {
        includeFromIndex = 0;
      }
      allExperiences.forEach((experience, index) => {
        
        if (index>=includeFromIndex) {
          experienceImages.pushObject({ 
            originalUrl: experience.heroEntity.kodakOriginalUrl,
            description: experience.name
          })
        }
      });
      return experienceImages;
    }
  },

  @computed('stage', 'stage.schedule')
  shouldShowStageScheduleToGuest (stage, schedule) {
    let customScheduleOnly = false;
    return shouldShowStageSchedule(stage, schedule, customScheduleOnly)    
  },  

  @computed('stage', 'stage.schedule', 'session.currentUser.isManager', 'trip.itinerary.beforeQuoteNotInstantBookable')
  shouldShowAddExperiencesCTA (stage, schedule, isManager, beforeQuoteNotInstantBookable) {
    if (this.whitelabel.isOnboardingAgency) {
      return true;
    }
  },  

  
  @computed('stage', 'stage.lodge', 'session.currentUser.isManager', 'whitelabel.isOnboardingAgency', 'trip.tripIdeasLoaded', 'recommendedLodgeCover')
  shouldShowAddLodgeCTA (stage, lodge, isManager, isOnboardingAgency, tripIdeasLoaded, recommendedLodgeCover) {    
    if ((this.whitelabel.isOnboardingAgency && !lodge)) {
      return true;
    }
  },  

  @computed('stage', 'stage.lodge', 'stage.lodgeKodakImageUrl', 'shouldShowAddLodgeCTA', 'stage.destinationKodakImageUrl')
  shouldShowLodgeCarousel(stage, lodge, lodgeKodakOriginalUrl, shouldShowAddLodgeCTA, stageDestinationKodakImageUrl) {    
    if (shouldShowAddLodgeCTA || (lodge && (lodgeKodakOriginalUrl != stageDestinationKodakImageUrl))) {
      return true;
    }
  },  


  @computed('shouldShowAddExperiencesCTA', 'shouldShowStageScheduleToGuest', 'stage.scheduleKodakImageUrl')
  shouldShowExperiencesCarousel (shouldShowAddExperiencesCTA, shouldShowStageScheduleToGuest, scheduleKodakImageUrl) {
    // console.log("shouldShowExperiencesCarousel ", shouldShowAddExperiencesCTA || (shouldShowStageScheduleToGuest && !!scheduleKodakImageUrl))
    return shouldShowAddExperiencesCTA || (shouldShowStageScheduleToGuest && !!scheduleKodakImageUrl);
  },   

  actions: {
    clickExperiencesCarousel(options) {
      if (this.get('stage.schedule')) {
        this.get('entityModals').openModal(options);        
      } else {
        this.router.transitionTo('trip.index.edit.itinerary');
      }       
    }
  }
});
