import Mixin from '@ember/object/mixin';
import PrettyQuery from 'b5b/mixins/pretty-query';
import MapSearchingRouteMixin from 'b5b/mixins/map-searching/route';

import {
  inject as service
} from '@ember/service';
import {
  reads
} from '@ember/object/computed';
import {
  isEmpty
} from '@ember/utils';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  generateMeta,
  trackEvent,
  minLodgePriceForRange,
  maxLodgePriceForRange
} from 'b5b/utils';
import {
  task
} from 'ember-concurrency';

export default Mixin.create(PrettyQuery, MapSearchingRouteMixin, {

  historyService: service(),
  router: service(),
  screen: service(),
  store: service(),
  ui: service(),
  scroll: service(),
  messageBus: service(),
  tripService: service('trip'),
  @alias('tripService.hasBackButton') hasBackButton: null,
  @alias('tripService.currentTrip') trip: null,

  pills: service(),
  @alias('pills.minPriceForRange') minPriceForRange: null,
  @alias('pills.maxPriceForRange') maxPriceForRange: null,

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  xplorer: service('components/x-plorer'),

  queryParams: {
    searchValue: {
      replace: true
    },
    selectedContinentNames: {
      as: 'continents'
    },
    selectedCountryNames: {
      replace: true
    },
    selectedRegionNames: {
      replace: true
    },
    selectedAreaNames: {
      replace: true
    },
    selectedExperienceNames: {
      replace: true
    },
    selectedLodgeStyleNames: {
      replace: true
    },
    selectedBudget: {
      replace: true
    },
    selectedLodgeNames: {
      replace: true
    },
    selectedAutoBudgetNames: {
      replace: true
    },
    changingLodge: {
      replace: true
    },
    unexplored: {
      replace: true
    },
    showOnlyAvailable: {
      replace: true
    },
    showOnlyWithOffers: {
      replace: true
    },
    exclusiveUseOnly: {
      replace: true
    },
    sort: {
      replace: true
    },
    startDate: {
      replace: true
    },
    endDate: {
      replace: true
    },
    polishLevel: {
      replace: true
    }
  },

  headTags() {
    let model = this.currentModel;
    let title = model.get('name');
    if (this.whitelabel.isForTimbuktu) {

      if (model.isSafari) {
        title += ' Safari Lodges & Camps';
      } else {
        title += ` Hotels & Properties`
      }
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }

    var description = `Discover ${model.isSafari ? 'safari lodges and camps' : 'hotels and properties'} in ${model.name} with our fully customizable trip ideas and our travel experts to give you all the help and advice you need.`;
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    return generateMeta({
      description,
      link,
      'og:title': title,
      robots
    });
  },


  setupController(controller, model) {
    this._super(...arguments);

    this.set('ui.viewingMapListing', true);

    if (!this.get('trip')) {
      controller.set('changingLodge', false);
    }

    if (!controller.get('changingLodge')) {
      this.set('tripService.currentStageIndex', null);
    }

    let minLodgePrice = 0, // $
      maxLodgePrice = maxLodgePriceForRange(); // $ DO NOT CHANGE THESE DEFAULTS WITOUT CHANGING THE SERVER SIDE DEFAULTS

    controller.setProperties({
      maxLodgePrice,
      minLodgePrice
    });

    if (isEmpty(controller.get('selectedBudget'))) {
      controller.set('selectedBudget', [minLodgePrice, maxLodgePrice]);
    }


    this.setProperties({
      minPriceForRange: minLodgePriceForRange,
      maxPriceForRange: maxLodgePriceForRange
    });

    // we bind this callback to the xplorer even though we setting the lodges ourself in the controller, because the xplorer service still needs to use this when it updates the lodges. For example when the pagination is used
    this.get('xplorer').setupController(controller, this.controller.entitiesLoaded.bind(this.controller));

    if (controller && model) {
      this.xplorer.addFilter(model)
      model.get('associatedEntities') && model.get('associatedEntities').forEach((associatedEntity)=> {
        if (associatedEntity.get('associatedRegion')) {
          this.xplorer.addFilter(associatedEntity.get('associatedRegion'))
        }
      })
    }

    controller.loadLodgesTask.perform();

    controller.setProperties({
      reset: false,
      firstTimeLoad: false
    });
    trackEvent('lodges:view');

    this.set('pills.activeType', 'lodges');
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.clearFilters(controller);
      // reset the properties not related to filters
      controller.setProperties({
        firstTimeLoad: true,
        changingLodge: false,
        zoom: 3,
        mapInstance: null,
        center: null,
        searchMode: 'loading',
        mapOnlyView: null,
        filterOnlyView: null
      });

      this.get('xplorer').teardownController();

      this.set('ui.viewingMapListing', false);
      this.set('pills.activeType', null);
    }
  },

  clearFilters(controller) {
    controller.setProperties({
      searchValue: '',
      filters: [],
      additionalFilter: null,
      selectedCountryNames: [],
      selectedRegionNames: [],
      selectedAreaNames: [],
      selectedLodgeNames: [],
      selectedExperienceNames: [],
      selectedLodgeStyleNames: [],
      selectedAutoBudgetNames: [],
      page: 1,
      selectedBudget: [controller.get('minLodgePrice'), controller.get('maxLodgePrice')],
      swlat: null,
      swlng: null,
      nelat: null,
      nelng: null,
      showOnlyAvailable: false,
      startDate: null,
      endDate: null,
      exclusiveUseOnly: false,
      showOnlyWithOffers: false,
      polishLevel: 'default',
      sort: null
    });
  },

  activate() {
    this._super(...arguments);
    this.get('screen').addBreakpoints({
      perPaneView: "(max-width: 1373px)"
    });
  },

  deactivate() {
    this._super(...arguments);
    this.get('screen').removeBreakpoints({
      perPaneView: "(max-width: 1373px)"
    })
  },

  actions: {
    showLodge(lodge) {
      trackEvent('lodges-list-map:view-lodge');
      this.transitionTo('lodge', lodge);
    },

    resetFilters() {
      this.clearFilters(this.get('controller'));
      this.set('controller.searchMode', 'destinationChange');
      this.controller.loadLodgesTask.perform();
    },

    showNewCustomLodge() {
      this.clearFilters(this.get('controller'));
      this.set('controller.searchMode', 'destinationChange');
      this.set('controller.polishLevel', 'custom')
      this.set('controller.sort', 'created_at_desc')
      this.messageBus.publish('suggestions-refresh');
      this.controller.loadLodgesTask.perform();
    },

    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);

      if (this.get('changingLodge') &&
        transition.targetName !== 'lodge.index'
      ) {
        this.setProperties({
          hasBackButton: false,
          changingLodge: false
        });
      }
      // return true so willTransition bubbles
      return true;

    }
  }

});
