import {
  inject as service
} from '@ember/service';
import {
  alias, equal
} from 'ember-decorators/object/computed';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['stage', 'stage-counter'],

  tripService: service('trip'),
  router: service(),
  entityModals: service(),

  trip: null,
  stage: null

});
