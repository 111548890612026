import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNames: ['wizard-banner'],

  ui: service(),

  name: null,
  prefered: false,

  /*
    We give each banner instance a unique name, with the assumption that only one will be shown at a time
    When we prefer one banner, we run a check on each instance to see if its name is equal to the prefered one

    This allows us to wrap the banner in context with context specific conditionals, like on routes listing

    Right now it only allows for single swap outs, if we want multiple ones we need to either give them the same name
    or expand the logic to cater for arrays
  */

  didInsertElement() {
    this._super(...arguments);
    if (this.prefered) {
      this.set('ui.preferedWizardBanner', this.name);
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    if (this.prefered) {
      this.set('ui.preferedWizardBanner', null);
    }
  },

  @computed('name', 'ui.preferedWizardBanner')
  isVisible(name, preferedWizardBanner) {
    if (preferedWizardBanner) {
      return name === preferedWizardBanner;
    }

    return true;
  }

});
