import { computed } from '@ember/object';
import DS from 'ember-data';

export default DS.Model.extend({
  user: DS.belongsTo('user', {
    async: false
  }),
  region: DS.belongsTo('region', {
    async: true
  }),
  regionId: computed('region', function() {
    return this.belongsTo('region').id();
  })

});
