import {
  isEmpty
} from '@ember/utils';
import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  getDurationForNumNights,
  trim
} from 'b5b/utils';

export default Component.extend({
  classNameBindings: [':route-card', 'frozen', 'landscapeOnTablet'],

  entityModals: service(),
  router: service(),
  tripService: service('trip'),
  store: service(),
  screen: service(),
  session: service(),

  tripSummary: null,

  landscapeOnTablet: false,

  showButtons: false,
  frozen: false, // keep mobile layout
  regionsForCard: null,

  canSwipe: true,

  didInsertElement() {
    let regionIds = this.tripSummary.hasMany('regions').ids()
    this.store.query('region', {ids: regionIds, force_region_ids: true}).then((regions)=> {
      let regionsForCard=[]
      regions.filter((region) => {
        if (!(region.isStopOver) && regionIds.includes(region.id)){
          regionsForCard.pushObject(region)
        }
      })
      this.set('regionsForCard', regionsForCard);
    })

  },

  @computed('tripSummary.metaTripLength')
  duration(tripLength) {
    return getDurationForNumNights(tripLength)
  },

  @computed('screen.width', 'tripSummary.teaserText', 'landscapeOnTablet')
  teaserText(width, teaserText, landscapeOnTablet) {
    if (landscapeOnTablet) {
      if (width>1500) {
      } else if (width> 1146) {
        return trim(teaserText, 150)
      } else if (width > 768) {
        return trim(teaserText, 120)
      }
    }
    else {
      if (width>1500) {
        return trim(teaserText, 170)
      } else if (width>1450) {
        return trim(teaserText, 150)
      } else if (width>1400) {
        return trim(teaserText, 130)
      } else if (width> 1146) {
        return trim(teaserText, 90)
      } else if (width > 768) {
        return trim(teaserText, 170)
      }
    }
    return teaserText
  },

  @computed('whitelabel.isForAgency', 'session.currentUser.isManager')
  overallCardlinkParams(isForAgency, isManager) {
    if (isForAgency && isManager || this.whitelabel.isOnboardingAgency) {
      return this.customiseLinkParams
    } else {
      return this.linkParams
    }
  },

  @computed('tripSummary', 'selectedLodgeNames')
  linkParams(tripSummary, selectedLodgeNames) {
    let params = {};

    if (!isEmpty(selectedLodgeNames)) {
      params['selectedLodgeNames'] = selectedLodgeNames;
    }

    return [
      'trip.index.index', tripSummary.get('friendlyId'), {
        isQueryParams: true,
        values: params
      }
    ];
  },

  @computed('tripSummary', 'selectedLodgeNames')
  customiseLinkParams(tripSummary, selectedLodgeNames) {
    let params = {};

    if (!isEmpty(selectedLodgeNames)) {
      params['selectedLodgeNames'] = selectedLodgeNames;
    }
    let routeName = (this.get('session.currentUser.isManager') || this.whitelabel.isOnboardingAgency) ?  'trip.index.edit.itinerary' : 'trip.index.editor'

    return [
      routeName, tripSummary.get('friendlyId'), {
        isQueryParams: true,
        values: params
      }
    ];
  },

  actions: {
    previewTrip() {
      this.set('tripService.tripPreviewModal', {
        trip: this.store.queryRecord('trip', {id: this.tripSummary.tripFriendlyId}),
        view: 'list'
      });
    }
  }

});
