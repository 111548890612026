import StepComponent from 'b5b/components/booking-steps/step/component';
import {
  computed
} from 'ember-decorators/object';

export default StepComponent.extend({

  @computed('trip.itinerary.allGuestsPersonalDetailsComplete')
  personalDetailsRequired(allGuestsPersonalDetailsComplete) {
    return !allGuestsPersonalDetailsComplete;
  },

  @computed('trip.itinerary.allGuestsInsuranceDetailsComplete')
  insuranceDetailsRequired(allGuestsInsuranceDetailsComplete) {
    return !allGuestsInsuranceDetailsComplete;
  }

});