import DS from 'ember-data';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export default DS.Transform.extend({
  deserialize(serialized) {
    if (serialized) {
      // NOTE: we assume that all timestamps returned are UTC - eg, trip.updatedAt
      return moment.utc(serialized, DATE_FORMAT).toDate();
    }
    return serialized;
  },

  serialize(deserialized) {
    if (deserialized) {
      return moment(deserialized).format(DATE_FORMAT);
    }
    return deserialized;
  }
});
