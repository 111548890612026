import { inject as service } from '@ember/service';
import Component from '@ember/component';

import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['t-footer'],

  router: service(),

  actions: {
    liveChat() {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.open()
      }
      trackEvent('t-footer:live-chat');
    }
  }
});
