import { reads } from '@ember/object/computed';
import DS from 'ember-data';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({
  trip: DS.belongsTo('trip', {
    async: true
  }),
  highlights: DS.attr(),
  overview: DS.attr('string'),
  activitiesIntro: DS.attr('string'),
  wildlifeIntro: DS.attr('string'),
  specialOfferSummary: DS.attr(),
});
