import {
  helper as buildHelper
} from '@ember/component/helper';

import {
  capitalizeFirstLetter
} from 'b5b/utils';

export function firstLetter([toTrim] /*, hash*/ ) {
  if (toTrim) {
    return toTrim[0];
  }
}

export default buildHelper(firstLetter);
