import {
  isPresent
} from '@ember/utils';
import {
  reads, alias
} from '@ember/object/computed';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  or
} from 'ember-decorators/object/computed';
import Self from 'b5b/models/lodge-info';

export default DS.Model.extend({

  lodge: DS.belongsTo('lodge', {
    async: false
  }),

  overview: DS.attr(),
  overviewWhyLove: DS.attr(),
  overviewInsidersTip: DS.attr(),
  overviewThinkAbout: DS.attr(),
  overviewPositiveImpact: DS.attr(),

  numberOfRooms: DS.attr(),
  highlights: DS.attr(),
  standaloneQuote: DS.attr(),
  mainAreaIntro: DS.attr(),
  accommodationIntro: DS.attr(),
  activitiesIntro: DS.attr(),
  wildlifeIntro: DS.attr(),

  contactEmail: DS.attr(),
  contactTelephone: DS.attr(),
  contactWebsiteUrl: DS.attr(),
  contactBookingsUrl: DS.attr(),
  contactAddress: DS.attr(),
  contactFacebook: DS.attr(),
  contactMobileBookingsUrl: DS.attr(),
  contactFrontDeskTelephone: DS.attr(),

  atTheLodgeOriginalUrl: DS.attr(),
  foodAndDrinkOriginalUrl: DS.attr(),
  onSafariOriginalUrl: DS.attr(),

  youtubeIds: DS.attr(),

  @or('overviewInsidersTip', 'overviewWhyLove', 'overviewThinkAbout') atLeastOneSnippet: null,

  // we moved the images to the core model for the new lodge cards
  // we can remove the method on the server on lodgeImages after side_filtering is deployed
  // and cache cleared
  metaLodgeImages: alias('lodge.metaLodgeImages'),
  images: reads('metaLodgeImages'),

  // lodgeImages: DS.attr(),

  feefoProductReviews: DS.hasMany('feefoProductReview'),

  specialOffers: DS.hasMany('specialOffer'),

  @computed('lodge.region.regionInfo.wildlives', 'lodge.country.countryInfo.wildlives')
  wildlives(regionWildlives, countryWildlives) {
    if (isPresent(regionWildlives)) {
      return regionWildlives;
    } else {
      return countryWildlives;
    }
  },

  makeCopy() {

    let copy = this.get('store').createRecord('lodgeInfo');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })

    return copy;
  }


});
