import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({

  settings: service(),
  ui: service(),

  showMapModal: null,

  actions: {
    openMapModal() {
      this.set('showMapModal', true);
    }
  }


});
