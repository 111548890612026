import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  or
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNames: ['image-with-name'],

  settings: service(),
  tripService: service('trip'),

  avatarSize: 50


});
