import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  task
} from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  run
} from '@ember/runloop';


export default Controller.extend(UseCurrentTrip, TripSaver, {

  xplorer: service('components/x-plorer'),
  scroll: service(),
  // if you don;t alias the entities like this then pagination wont work
  @alias('xplorer.events') events: null,
  @alias('tripService.currentTrip.id') tripId: null,
  eventType: 'trip:view',
  sort: 'created_at_desc',
  per_page: 20,
  page: 1,

  queryParams: ['page'],  

  loadEventsTask: task(function*() {
    console.log('loadEventsTask')
    this.loader = run.next(() => {
      this.get('scroll').to(0);
    });

    if (this.searchMode == 'destinationChange') {
      this.resetLatLng();
    }
    let events = yield this.get('xplorer').loadRecords('events', {skipSettingsRecords: true});
    this.set('events', events)
  }).keepLatest(),

  actions: {

  }
});
