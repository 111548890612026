import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';


export default Component.extend({

  tagName: '',
  feefo: service(),
  hideReviewsText: false,
  nonClickable: false,

});
