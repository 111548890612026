import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  trackEvent
} from 'b5b/utils';

export default Controller.extend({

  whitelabel: service(),
  ui: service(),
  settings: service(),
  feefo: service(),

  actions: {
    openVideo() {
      trackEvent('x-video:open:from:home');
      this.set('ui.showVideo', true);
    }
  }

});
