import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';
import {
  setupTripEntityNav
} from 'b5b/utils';

export default Route.extend({

  ui: service(),
  session: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  tripService: service('trip'),

  // model() {
  //   return this.modelFor('trip');
  // },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('trip:when-to-go:view');
    controller.set('trip', model);

    // setup entity nav
    setupTripEntityNav(model, this.get('tripService'), this.ui, this.session);
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set('ui.entityNavData', null);
    }
  }

});