import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default Route.extend({
  
  whitelabel: service(),
  ui: service(),
  xplorer: service('components/x-plorer'),

  setupController(controller, model) {
    this._super(...arguments);

    // we bind this callback to the xplorer even though we setting the events ourself in the controller, because the xplorer service still needs to use this when it updates the events. For example when the pagination is used
    this.get('xplorer').setupController(controller);

    controller.loadEventsTask.perform();
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.clearFilters(controller);
      // reset the properties not related to filters
      // controller.setProperties({
      // });
      this.get('xplorer').teardownController();
    }
  },

  clearFilters(controller) {
    controller.setProperties({
      page: 1,
      sort: null
    });
  },  


  beforeModel(transition) {
    if (this.whitelabel.agency.needsOnboarding) {
      transition.abort();
      this.set('ui.showNeedsOnboardingModal', true);
    }    
    if (this.whitelabel.isOnboardingAgency) {
      transition.abort();
      this.set('ui.showWaybirdSignupModal', true)
    }       
  },
})