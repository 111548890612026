import Route from '@ember/routing/route';
import EntitiesCountriesRouteMixin from 'b5b/mixins/entities/countries/route';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend(EntitiesCountriesRouteMixin, {

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('countries:view');
  },
});
