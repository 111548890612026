import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';



export default Component.extend({
  tripService: service('trip'),
  templateManager: service(),

  didInsertElement() {
    this._super(...arguments);
    this.get('loadTransferOptionsTask').perform();

  },

  loadTransferOptionsTask: task(function*() {
    let queryAttrs = this.templateManager.getQueryAttrsForStageTransferOptions(this.stage)

    queryAttrs.includeOwnArrangements=true;
    queryAttrs.includeFallbackIfNoResults=true;
    if (this.showCurrentUserTransfersOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }
    // console.log(queryAttrs)
    return this.store.query('transfer', queryAttrs).then((transfers)=>{
      this.set('transferOptions', transfers)
    })
  }).keepLatest(),


  actions: {
    setTransferOption(option) {
      this.stage.set('customTransfer', null);
      this.stage.set('templateTransfer', option);
      this.stage.set('stageType', 'template-transfer');
      this.tripService.madeChanges();
    }
  }


});
