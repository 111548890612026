
import DayByDaySection from 'b5b/components/day-by-day-section/component';
import {
  computed
} from 'ember-decorators/object';

export default DayByDaySection.extend({

  @computed('stage.lodge.metaLodgeImages', 'stage.lodgeKodakImageUrl', 'stage.lodgeKodakImageCoverStyle', 'block.overrideDayImageUrl')
  lodgeImagesStartingWithKodak(metaLodgeImages, lodgeKodakImageUrl, lodgeKodakImageCoverStyle, overrideDayImageUrl) {
    if (!this.get('stage.lodge')) {
      return null;
    }
    let lodgeImages = [...metaLodgeImages]
    let metaKodakImage =  {
      originalUrl: lodgeKodakImageUrl,
      coverStyle: lodgeKodakImageCoverStyle,
    };
    lodgeImages.insertAt(0, metaKodakImage);
    return lodgeImages.filter(function(lodgeImage) {
      return lodgeImage.originalUrl !== overrideDayImageUrl;
    });
  },

  actions: {
    showLightboxGallery(imageIndex) {
      console.log(imageIndex)
      this.set('ui.entityNavVisible', false)
      this.set('showGallery', {imageIndex})

    },
    closeLightboxGallery() {
      this.set('ui.entityNavVisible', true)
      this.set('showGallery', false)
    }
  }

});
