import Component from '@ember/component';

export default Component.extend({

  classNameBindings: [':x-accordion', 'smallAccordion'],

  siblings: [],

  actions: {
    manageItem(item) {
      if (item.get('isOpen')) {
        item.set('isOpen', false);
        return;
      }

      this.siblings.forEach((sibling) => {
        sibling.set('isOpen', sibling === item);
      });
    }
  }

});
