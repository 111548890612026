import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({

  affiliate: service(),
  settings: service(),
  store: service(),
  tripService: service('trip'),
  router: service(),
  ui: service(),

  setupController(controller, model) {
    this._super(...arguments);

    let links = [{
      name: 'Trip',
      params: {
        routeName: 'trip.index.index',
        routeModel: model
      }
    }];

    if (model.get('itinerary.stateQuoteAndAfter')) {
      links.pushObject({
        name: 'Details',
        params: {
          routeName: 'trip.index.quote',
          routeModel: model
        }
      });
    }

    this.ui.setProperties({
      entityNavData: {
        entity: model,
        links: links
      }
    });
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set('ui.entityNavData', null);
    }
  }

});