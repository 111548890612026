import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  alias
} from 'ember-decorators/object/computed';

import EmberObject from '@ember/object';

const Cover = EmberObject.extend({
  loaded: false,
  title: null,
  text: null,
  url: null
});

export default Controller.extend({
  router: service(),
  session: service(),
  historyService: service(),
  wizardService: service('wizard'),
  wizardStepController: controller('wizard.step'),
  @alias('wizardStepController.completing') completing: false,
  @alias('wizardStepController.step') step: false,

  currentStep: 0,
  steps: null,
  loadingRoutes: false,

  stepCovers: [Cover.create({
    title: '',
    text: '',
    url: 'https://waybird.imgix.net/assets/images/general/wizard-modal-cover.jpg'
  }), Cover.create({
    title: 'Discover wild & remote places',
    text: 'Choose from hundreds of handpicked properties and experiences across the far-flung corners of Africa.',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/1-Liwonde_mvuu08_2981.jpg'
  }), Cover.create({
    title: 'Trips for all budgets',
    text: 'We have recommended properties across all price points so you can design the perfect trip to fit your style and budget.',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/2-Etosha_01461a8a47953f7c2ed447b29cfce527752e7806f2.jpg'
  }), Cover.create({
    title: 'Make it your own',
    text: 'Each trip is fully customisable. Chop and change properties and decide how long you’d like to stay.',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/3-Laikipia_NayPalad__BirdNest_RGB.jpg'
  }), Cover.create({
    title: 'Get instant prices & availability',
    text: 'Add your dates to see accurate prices and lodge availability.',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/4-kaya-mawa-deck-lounge-lake-malawi-timbuktu-travel.JPG'
  }), Cover.create({
    title: 'Experts on hand',
    text: 'Get advice and suggestions from our travel experts to help you design your trip.',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/5-STC-Canoeing-5.JPG'
  }), Cover.create({
    title: 'We’ll find you the best prices',
    text: 'Get the inside track on special offers and save some pennies along the way!',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/6-ubuntu-camp-sundowners.JPG'
  }), Cover.create({
    title: 'We’ll take care of the details',
    text: 'From hire cars to helicopters, we’ll look after all your arrangements and transfers. ',
    url: 'https://waybird.imgix.net/assets/images/trip-wizard/7-samburu-Saruni-Samburu-Camp-Saruni-Samburu.jpg'
  })],

  @computed('currentStep', 'steps')
  nextStep(currentStep, steps) {
    if (currentStep < steps.length) {
      let nextStep = currentStep + 1;
      // // Skip email and first name for logged in users
      // if (nextStep==5 && this.session.get('currentUser.email')) {
      //   nextStep=nextStep+1;
      // }
      return nextStep;
    }
  },

  @computed('currentStep')
  previousStep(currentStep) {
    if (currentStep) {
      return currentStep - 1;
    }
  },

  @computed('steps', 'session.currentUser.email')
  totalSteps(steps, currentUserEmail) {
    // if (currentUserEmail) {
    //   return steps.length - 1;
    // }
    return steps.length;
  },

  @computed('currentStep', 'session.currentUser.email')
  effectiveStep(currentStep, currentUserEmail) {
    // if (currentUserEmail && currentStep==6) {
    //   return 5;
    // }
    return currentStep;
  },

  setStep(step){
    this.set('currentStep', step);
  },

  @computed('currentStep')
  isIntro(currentStep) {
    return currentStep === 0;
  },

  @computed('router.currentRouteName')
  isSuccessRoute(currentRouteName) {
    return currentRouteName === 'wizard.success';
  },

  @computed('currentStep', 'steps', 'session.currentUser')
  finalStepAndLoggedIn(currentStep, steps, currentUser) {
    return (currentStep === steps.length) && currentUser;
  },

  @computed('screen.isMobileOnly', 'screen.height')
  fixedButtonBar(isMobileOnly, height) {
    if (isMobileOnly && height < 530) {
      return true;
    } else if ( height < 770) {
      return true;
    }
  },

  actions: {
    coverLoaded(idx){
      this.get('stepCovers')[idx].set('loaded', true);
    },

    goBack() {
      window.history.back();
    },
    completeWizard() {
      return this.wizardStepController.calledByCompleteWizardAction();
    }
  }


});
