import Route from '@ember/routing/route';

import {
  inject as service
} from '@ember/service';

export default Route.extend({



  activate() {
    this.set('ui.showWaybirdSignupModal', false)
  }


});
