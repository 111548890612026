import Component from '@ember/component';
import {
  htmlSafe
} from '@ember/template';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNameBindings: [':limited-text', 'unlimited', 'isMobileOnly:mobile-only'],
  attributeBindings: ['style'],

  unlimited: false,
  content: null,
  maxHeight: null,
  isMobileOnly: null,
  buttonText: "+ Read more",
  moreOffset: 60,

  didInsertElement() {
    let height = this.$('.limited-content').innerHeight();
    if (height < 100) {
      this.set('unlimited', true)
    }
  },

  @computed('maxHeight', 'unlimited')
  style(maxHeight, unlimited) {
    if (!maxHeight || unlimited) {
      return
    }

    let style = "";
    style = 'max-height: ' + maxHeight + 'px;';
    return htmlSafe(style);
  },

  @computed('maxHeight', 'moreOffset')
  moreStyle(maxHeight, moreOffset) {
    if (!maxHeight) {
      return;
    }

    let style = "top: " + (maxHeight - moreOffset) + "px;";
    return htmlSafe(style);
  }
});
