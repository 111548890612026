import {
  inject as service
} from '@ember/service';
import TooltipOnElementComponent from 'ember-tooltips/components/tooltip-on-element';
import {
  computed
} from 'ember-decorators/object';

export default TooltipOnElementComponent.extend({

  ui: service(),

  effect: 'fade',
  delay: 0,
  enableLazyRendering: true,
  keepInWindow: true,
  openOn: null,
  setPin: true,
  animationDuration: 200,
  duration: 5000,

  @computed('ui.hasTouchSupport', 'openOn')
  event(hasTouchSupport, openOn) {
    if (openOn) {
      return openOn;
    }
    return hasTouchSupport ? 'click' : 'hover';
  }

});
