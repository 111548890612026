import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['lodges-section'],

  store: service(),

  destinationType: null, // country | region
  loadingLodges: true,

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('loadingLodges', true);

    let query = {
      per_page: 6
    };

    if (this.destinationType === 'region') {
      this.set('selectedRegionName', this.get('destination.name'))
      this.set('selectedCountyName', this.get('destination.country.name'))
      query['selectedRegionNames'] = [this.get('destination.name')];
    } else if (this.destinationType === 'country') {
      this.set('selectedCountyName', this.get('destination.name'))
      query['selectedCountryNames'] = [this.get('destination.name')];
    }

    this.get('store').query('lodge', query).then((lodges) => {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('recommendedLodges', lodges);
      this.set('loadingLodges', false);
    });

    let route = 'lodges'
    if (this.entityType !== 'lodge') {
      route=`${this.entityType}.lodges`;
    }

    var hash = {
      route,
      query,
      title: 'Show all properties'
    };
    this.set('seeAllLinkHash', hash);

  },

  willDestroyElement(){
    this._super(...arguments);
    this.set('loadingLodges', true);
  }

});
