import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import Component from '@ember/component';

export default Component.extend({

  classNames: ['trip-tools-right-pane-add-block-items', 'trip-tools-right-pane', 'trip-tools-right-pane-add-anything'],
  templateManager: service(),
  entityModals: service(),
  store: service(),
  session: service(),
  dayExperiences: null,
  showCurrentUserBlockItemsOnly: false,
  showCurrentLodgeBlockItemsOnly: false,

  didInsertElement() {
    this._super(...arguments);
    if (this.get('templateManager.currentStage.regionProxy.id')) {
      this.get('loadBlockItemsTask').perform();
    }
  },

  loadBlockItemsTask: task(function*() {
    let queryAttrs = {
      templateType: 'template',
      buster: true,
      sort: 'recommended'
    };

    let filters = [];
    
    if (this.showCurrentLodgeBlockItemsOnly) {
      filters.pushObject({type: 'lodge', id: this.templateManager.currentStage.lodge.id})
    } else {
      filters.pushObject({type:'region', id: this.get('templateManager.currentStage.regionProxy.id')});      
      this.get('templateManager.currentStage.regionProxy').get('associatedEntities') && this.get('templateManager.currentStage.regionProxy').get('associatedEntities').forEach((associatedEntity)=> {
        if (associatedEntity.get('associatedRegion')) {
          filters.pushObject({type: 'region', id: associatedEntity.get('associatedRegion.id')})
        }
      })  
    }    
    if (this.textFilter) {
      filters.pushObject({
        name: this.textFilter,
        type: "text",
      })      
    }
    queryAttrs.filters=JSON.stringify(filters)    

    if (this.showCurrentUserBlockItemsOnly) {
      queryAttrs.userId=this.session.currentUser.id;
    }

    return this.store.query('blockItem', queryAttrs).then((blockItems)=>{
      this.set('blockItems', blockItems)
    })

  }).keepLatest(),

  actions: {
    textFilterChanged(textFilter) {
      this.set('textFilter', textFilter)
      this.loadBlockItemsTask.perform();
    }    
  }
});
