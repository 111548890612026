import Component from '@ember/component';

export default Component.extend({
  classNames: ['start-planning', 'library'],

  init() {
    this._super(...arguments);
    let countriesStyle = `background-image: url('https://waybird.imgix.net/lodge_images/images/000/101/196/original/29031782935_6f3e677793_k.jpg?w=768');`
    let regionsStyle = `background-image: url('https://waybird.imgix.net/experiences/kodak_images/000/011/788/original/bangkok-Thailand-Timbuktu-2_copy.jpg?w=768');`
    let experiencesStyle = `background-image: url('https://waybird.imgix.net/lodge_images/images/000/102/046/original/Lolobezi_076.jpg?w=768');`
    let lodgesStyle = `background-image: url('https://waybird.imgix.net/lodge_images/images/000/095/771/original/Lolobezi_022.jpg?w=768');`
    
    let experiencesCoverStyle = "center bottom"
    
    if (experiencesCoverStyle) {
      experiencesStyle = experiencesStyle + " background-position: " + experiencesCoverStyle+";";
    }

    let lodgesCoverStyle = "center center"
    
    if (lodgesCoverStyle) {
      lodgesStyle = lodgesStyle + " background-position: " + lodgesCoverStyle+";";
    }

    this.set('countriesStyle', Ember.String.htmlSafe(countriesStyle))    
    this.set('regionsStyle', Ember.String.htmlSafe(regionsStyle))    
    this.set('experiencesStyle', Ember.String.htmlSafe(experiencesStyle)) 
    console.log(experiencesStyle)
    this.set('lodgesStyle', Ember.String.htmlSafe(lodgesStyle))    
  }
});
