import Component from '@ember/component';
import {
  SUGGESTIONS
} from 'b5b/utils';
import RSVP from 'rsvp';

export default Component.extend({
  classNames: ['suggestions-checklist'],

  suggestionData: SUGGESTIONS,
  suggestions: null,
  checklist: null,

  init() {
    this._super(...arguments);

    // if (this.suggestionField=='overviewWhyLove') {
    //   let suggestions=[]
    //   let extraInfos = [this.get('modelToUpdate.lodge.lodgeInfo'), this.get('modelToUpdate.regionProxy.regionInfo')]

    //   let promise = RSVP.all(extraInfos).then(()=> {
    //     if (this.get('modelToUpdate.lodge.lodgeInfo.overviewWhyLove')) {
    //       suggestions.pushObject(this.get('modelToUpdate.lodge.lodgeInfo.overviewWhyLove'))
    //     }
    //     if (this.get('modelToUpdate.lodge.lodgeInfo.overviewInsidersTip')) {
    //       suggestions.pushObject(this.get('modelToUpdate.lodge.lodgeInfo.overviewInsidersTip'))
    //     }
    //     if (this.get('modelToUpdate.regionProxy.regionInfo.overviewWhyLove')) {
    //       suggestions.pushObject(this.get('modelToUpdate.regionProxy.regionInfo.overviewWhyLove'))
    //     }
    //     if (this.get('modelToUpdate.regionProxy.regionInfo.overviewOffBeatenTrack')) {
    //       suggestions.pushObject(this.get('modelToUpdate.regionProxy.regionInfo.overviewOffBeatenTrack'))
    //     }


    //     this.set('suggestions', suggestions);
    //     let temp = {};
    //     for (let i = 0; i < this.suggestions.length; i++) {
    //       temp[this.suggestions[i]] = false;
    //     }
    //     this.set('checklist', temp);
    //   })

    // } else {
      let suggestionKey = `${this.modelToUpdate.constructor.modelName}.${this.suggestionField}`;
      this.set('suggestions', this.suggestionData[suggestionKey]);
      let temp = {};
      for (let i = 0; i < this.suggestions.length; i++) {
        temp[this.suggestions[i]] = false;
      }
      this.set('checklist', temp);
    // }

  },

  actions: {
    updateChecklist(item) {
      let temp = this.checklist;
      temp[item] = !temp[item];
      this.set('checklist', temp);
    },

    saveSuggestions() {
      let suggestionText = "";

      for (let i = 0; i < this.suggestions.length; i++) {
        if (this.checklist[this.suggestions[i]] === true) {
          suggestionText += (this.suggestions[i] + "\n");
        }
      }

      // edge case for first and last transfer suggestion
      if (this.suggestionField === 'firstTransfer' || this.suggestionField === 'lastTransfer') {
        this.set('suggestionField', 'description');
      }

      // if we need to append to existing text - also deals with whitespace when clicking in the textbox
      let fieldToUpdateKey = 'modelToUpdate.' + this.suggestionField;
      let fieldToUpdate = this.get(fieldToUpdateKey);
      if (fieldToUpdate !== null && fieldToUpdate.length > 1) {
        suggestionText = fieldToUpdate + "\n" + suggestionText;
      }

      this.set(fieldToUpdateKey, suggestionText);
      this.set('showTextSuggestionsModal', false);
    }
  }

});
