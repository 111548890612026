import {
  on
} from 'rsvp';
import Ember from 'ember';
import config from '../config/environment';

export default {
  name: "bugsnag",

  initialize: function(instance) {

    if (typeof FastBoot !== 'undefined' || typeof Bugsnag === 'undefined') {
      return;
    }

    var appController = instance.lookup('controller:application');
    Bugsnag.apiKey = config.APP.BUGSNAG.API_KEY;
    Bugsnag.releaseStage = config.environment;
    Bugsnag.notifyReleaseStages = ["staging", "production"];

    // Routing errors and edge cases
    Ember.onerror = function(error) {
      Bugsnag.context = appController.get('currentPath');
      Bugsnag.notifyException(error);
      if (error.message && error.stack) {
        console.error(error.message, error.stack);
      } else {
        if (error.stack) {
          console.error(error.stack);
        }
      }

    };

    // Errors that occur in Promises
    on('error', function(error) {
      Bugsnag.context = appController.get('currentPath');
      Bugsnag.notifyException(error);
    });



    // Ember Logger errors
    // Ember.Logger.error = function(message, cause, stack) {
    //   Bugsnag.context = appController.get('currentPath');
    //   Bugsnag.notifyException(new Error(message), null, {
    //     cause: cause,
    //     stack: stack
    //   });
    //   console.error(stack);
    //   console.error(message);
    // };
  }
}