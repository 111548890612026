import {
  helper
} from '@ember/component/helper';

export function seeAllLinkParams(params, namedArgs /* route item selectedCountryNames selectedRegionNames selectedExperienceNames */ ) {
  let queryParams = {};
  if (namedArgs.seeAllLink.query) {
    let countries = namedArgs.seeAllLink.query.selectedCountryNames;
    let regions = namedArgs.seeAllLink.query.selectedRegionNames;
    let experiences = namedArgs.seeAllLink.query.selectedExperienceNames;
    let isPopular = namedArgs.seeAllLink.query.isPopular;

    if (countries) {
      queryParams.countries = [countries];
    }
    if (regions) {
      queryParams.regions = [regions];
    }
    if (experiences) {
      queryParams.experiences = [experiences];
    }

    if (isPopular) {
      queryParams.isPopular = true;
    }
  }

  if (namedArgs.seeAllLink.route) {
    if (namedArgs.seeAllLink.item) {
      return [
        namedArgs.seeAllLink.route, namedArgs.seeAllLink.item, {
          isQueryParams: true,
          values: queryParams
        }
      ];
    } else {
      return [
        namedArgs.seeAllLink.route, {
          isQueryParams: true,
          values: queryParams
        }
      ];
    }
  }
}

export default helper(seeAllLinkParams);
