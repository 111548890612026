import { helper } from '@ember/component/helper';

export function tripIdeaDescription([type]/*, hash*/) {
  var description = '';
  switch (type) {
    case 'low':
      description = 'Affordable properties that are simple and rustic but always comfortable.';
      break;
    case 'mid':
      description = 'Stay in properties that offer the full works for a taste of the good life.';
      break;
    case 'high':
      description = 'The ultimate in style and sophistication – you won’t want to go home!';
      break;
    case 'custom':
      description = 'Mix and match properties to fit your style and budget';
      break;
    default:
      break
  }

  return description;
}

export default helper(tripIdeaDescription);
