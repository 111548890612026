import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  didInsertElement() {
    this._super(...arguments);
    this.document.presignedUrlToView().then((response)=> {
      this.set('url', response.url)
    })
    
  },  
  actions: {
    imageLoadError() {
      this.set('errorLoadingImage', true)
    }
  }
});
