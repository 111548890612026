import Component from "@ember/component";
import { computed } from "ember-decorators/object";
import { reads } from "ember-decorators/object/computed";
import { INCLUSION_TYPES } from "b5b/services/settings";
import template from "../../../models/template";

export default Component.extend({
  editingTemplateTarget: null,
  @reads("editTemplateModalOptions.template") template: {},
  @computed()
  inclusionTypes() {
    return INCLUSION_TYPES;
  },

// TODOLIBRARY bad name for component

  actions: {
    changeTemplateTarget(selectResult) {
      if (selectResult.action == "search:select" && selectResult.result) {
        if (selectResult.type == "lodge") {
          this.template.set("lodge", selectResult.result);
          this.template.set(
            "region",
            selectResult.result.region ? selectResult.result.region : null
          );
        } else if (selectResult.type == "region") {
          this.template.set("lodge", null);
          this.template.set("region", selectResult.result);
        }
      }
      this.set("editingTemplateTarget", false);
    }
  }
});
