import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  activate() {

    if (window && typeof window.fbq !== 'undefined') {
      window.fbq('track', 'InitiateCheckout');
    }

  }

});
