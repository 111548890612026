define('ember-picturefill/services/picturefill', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  var scheduleOnce = run.scheduleOnce;
  exports['default'] = Service.extend({

    lib: computed(function () {
      return window.picturefill;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._setupElementArray();
    },

    reload: function reload() {
      var lib = get(this, 'lib');

      if (lib) {
        lib.apply(undefined, arguments);
      }
    },

    enqueue: function enqueue(element) {
      get(this, 'elements').push(element);

      scheduleOnce('afterRender', this, '_flush');
    },

    _flush: function _flush() {
      var elements = get(this, 'elements');

      this.reload({ elements: elements });
      this._setupElementArray();
    },

    _setupElementArray: function _setupElementArray() {
      set(this, 'elements', []);
    }
  });
});