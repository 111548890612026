import Component from '@ember/component';

export default Component.extend({
  tagName: '',

  actions: {
    setDayNumber(block, dayNumString) {
      if (dayNumString=="No day") {
        block.set('daysAfterStart', null)
      } else {
        block.set('daysAfterStart', parseInt(dayNumString))
      }
      this.set('chooseDay', false)
    },
  }
});
