import staticStageComponent from 'b5b/components/trip-stages/static/stage/component';

export default staticStageComponent.extend({

  actions: {
    // openDatePicker(e) {
    //   this.tripService.tripDatePickerInstance.open();
    //   e.stopPropagation()
    // },
  }

});
