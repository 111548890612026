import {
  inject as service
} from '@ember/service';

import Component from '@ember/component';

export default Component.extend({
  tagName: "",
  ui: service(),
  tripService: service('trip'),
  actions: {
    closeOptionsModalThen(action) {
      action();
      this.set("ui.showStageOptionsModal", false);
    },
  }
});
