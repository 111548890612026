import EmberObject from '@ember/object';

export default EmberObject.extend({

  enabled: true,
  coverStyle: null,
  description: null,
  id: null,
  originalUrl: null,
  sequence: true,
  use_as_kodak_image: null
 
});
