import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import AuthFormComponent from 'b5b/components/auth-form/component';
import {
  trackEvent
} from 'b5b/utils';


export default AuthFormComponent.extend({

  classNames: ['login'],

  ui: service(),
  router: service(),

  didInsertElement() {
    this._super(...arguments);
    this.ui.setProperties({
      showRegisterModal: false,
      showPasswordResetModal: false,
      showChangePasswordModal: false
    });
  },

  actions: {
    authenticate() {
      let userAuthenticated = this.userAuthenticated ? this.userAuthenticated : run.bind(this, 'genericUserAuthenticated');
      this.set('saving', true);
      let email = this.email && this.email.toLowerCase();
      return this.session
        .authenticate('authenticator:server-side-aware-authenticator', email, this.password)
        .then(() => {
          trackEvent('login', {
            registration: 'email'
          });
          userAuthenticated();
        })
        .catch((res) => {
          this.set('saving', false);
          this.set('errors.email', [res.error]);
          this.propertyDidChange('errors.email');
          this.set('showErrors', true);
        });

    },

    logout() {
      this.router.transitionTo('index');
      return this.session.invalidate();
    }
  }

});
