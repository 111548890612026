import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  isEmpty,
  isNone
} from '@ember/utils';

export default Component.extend({
  classNames: ['tripclip-video'],

  @computed('url')
  embedUrl(url) {
    if (isNone(url)) {
      return '';
    }
    return url.replace(/portal\.tripclip\.world\/preview\//, 'portal.tripclip.world/embed/');
  },

//   https://portal.tripclip.world/embed/timbuktu/eKY8C3d7T7I*
// https://portal.tripclip.world/preview/timbuktu/eKY8C3d7T7I*

});
