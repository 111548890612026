export function initialize(application) {

  if (typeof FastBoot !== 'undefined') {
    return;
  }

  application.inject('controller', 'screen', 'service:screen');
  // Apparently service injects are lazy, but not sure if we should inject them into all components
  application.inject('component', 'screen', 'service:screen');
}

export default {
  name: 'screen',
  initialize
};
