import xItineraryStageEdit from "b5b/components/x-itinerary/stage/edit/component";
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default xItineraryStageEdit.extend({
  classNameBindings: [':x-itinerary-stage-schedule'],

  isComponentForRealStage: false, //make the focus stage logic skip the schedule

  ui: service(),
  templateManager: service(),
  entityModals: service(),

  tripService: service('trip'),

  possibleLayouts: ['what-to-do', 'day', 'general'],

  actions: {

    removeExperienceItem(item, experienceItem) {
      item.get('experienceItems').removeObject(experienceItem);
    },
    removeDayExperience(day, dayExperience) {
      day.get('dayExperiences').removeObject(dayExperience);
    },


    dropExperienceItem(options, experienceItem) {
      options.item.get('experienceItems').pushObject(experienceItem)
    },
    dropDayExperience(options, dayExperience) {
      options.day.get('dayExperiences').pushObject(dayExperience)
    }
  }

});
