import { helper as buildHelper } from '@ember/component/helper';
import {
  lowerCaseAndDasherized
} from 'b5b/utils';

export function lowercaseDasherized([val]) {
  return lowerCaseAndDasherized(val);
}

export default buildHelper(lowercaseDasherized);
