import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';

export default Controller.extend({

  entityModals: service(),
  blogPosts: [
    {
      url: 'https://www.timbuktutravel.com/blog/whats-it-like-to-go-on-safari-now',
      snippet: 'If I could take this trip all over again, I would, and I think this is a great moment for those who can travel right now to enjoy a much more private safari.',
      kodakOriginalUrl: 'https://waybird.imgix.net/assets/images/open-for-travel/pedro-1.jpg'
    },
    {
      url: 'https://www.timbuktutravel.com/blog/whats-it-like-to-go-on-safari-now',
      snippet: 'You can see that the procedures and protocols are clear and taken seriously, and that every member of staff is well trained on how to conduct themselves.',
      kodakOriginalUrl: 'https://waybird.imgix.net/assets/images/open-for-travel/pedro-2.jpg'
    },
    {
      url: 'https://www.timbuktutravel.com/blog/whats-it-like-to-go-on-safari-now',
      snippet: 'Witnessing three wildebeest crossings of the Mara river was spectacular. Even though I have watched it so many times on video, seeing it live and in person was absolutely thrilling and a completely different experience.',
      kodakOriginalUrl: 'https://waybird.imgix.net/assets/images/open-for-travel/pedro-3.jpg'
    },

]
});
