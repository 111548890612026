import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import config from 'b5b/config/environment';

export default Route.extend({

  tripService: service('trip'),
  store: service(),
  ui: service(),

  afterModel(model, transition) {
    this._super(...arguments);

    if (this.whitelabel.agency.needsOnboarding) {
      transition.abort();
      this.set('ui.showNeedsOnboardingModal', true);      
    }
    if (this.whitelabel.isOnboardingAgency) {
      transition.abort();
      this.set('ui.showWaybirdSignupModal', true)
      return
    }     

    if (!this.get('tripService.currentTrip.id')) {
      transition.abort();
      this.get('ui').showGeneralMessage('Oops', 'Please save this trip before using these tools');
    }
  },

  setupController(controller) {
    this._super(...arguments);
    controller.set('userEmail', this.get('tripService.currentTrip.userEmail'));
    controller.set('tripEventsLogLink', `${config.APP.adminHost}/admin/events?&q%5Bby_trip_id_in%5D=${this.tripService.currentTrip.id}&q%5Bevent_type_contains%5D=trip%3Aview&order=created_at_desc`);
    controller.set('travelStatuses', this.store.peekAll('home').get('firstObject.travelStatuses'));
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        duplicateGuestDetails: null
      });
    }

  }
});
