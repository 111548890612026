import DS from 'ember-data';

export default DS.Model.extend({

  // feefo: DS.belongsTo('feefo'),
  // feefoServiceReview: DS.belongsTo('feefoServiceReview'),

  productTitle: DS.attr(),
  productUrl: DS.attr(),

  uid: DS.attr(),
  url: DS.attr(),

  rating: DS.attr(),
  review: DS.attr(),

  guestName: DS.attr(),

  reviewCreatedAt: DS.attr('b5bdate'),
  reviewUpdatedAt: DS.attr('b5bdate')

});