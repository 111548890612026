import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  getErrorsHashFromServerResponse,
  trackEvent
} from 'b5b/utils';

export default Controller.extend({

  actions: {
    createUser() {

      const {
        firstName,
        lastName,
        email,
        password
      } =
      this;

      this.set('saving', true);
      this.set('errors', null)
      this.set('showErrors', false)

      return this.store.createRecord('user', {
          name: firstName,
          surname: lastName,
          email,
          password,
          agency: this.agency
        })
        .save()
        .then(() => {          
          this.set('saving', false);
          this.transitionToRoute('waybird.account.created', this.agency);
          return true;          
        })
        .catch((serverResponse) => {
          this.set('errors', getErrorsHashFromServerResponse(serverResponse));
          this.set('showErrors', this.errors && true);
          this.set('saving', false);
        });
    }
  }

  



});
