import { helper as buildHelper } from '@ember/component/helper';
import {
  lowerCaseAndDasherized
} from 'b5b/utils';

export function countrySvgPath([style, countryName]) {

  if (style && countryName) {
    return `https://waybird.imgix.net/assets/images/countries/${style}-svg/${lowerCaseAndDasherized(countryName)}.svg`
  }

}

export default buildHelper(countrySvgPath);