import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  inject as service
} from '@ember/service';

// Pass in an array of items to this component in order for the active element and array to update in didUpdateAttrs
// The actual layout for the items is passed in as a yielded block but if you if it changes ever then you want didUpdateAttrs to run
export default Component.extend({
  classNames: ['carousel-nav'],

  ui: service(),
  router: service(),

  canScrollLeft: false,
  canScrollRight: true,

  scrolled: false,

  scrollDistance: 153,
  scrollDuration: 300,

  throttleTimer: null,

  didInsertElement() {
    this._super(...arguments);

    if (this.isDestroying || this.isDestroyed) {
      return;
    }

    this.set('viewport', this.element.getElementsByClassName('viewport')[0]);
    this.set('boundScrollHandler', this.onScroll.bind(this));
    $(this.viewport).on('scroll', this.boundScrollHandler);

    this.centerActive();
  },



  didUpdateAttrs() {
    this._super(...arguments);
        run.next(() => {
            // Wait for the changes to the attrs to propagate to DOM
            this.centerActive();
          });
  },

  centerActive() {

    let viewport = this.$('.viewport');
    viewport.scrollLeft(0);

    let  viewportWidth = viewport.outerWidth(),
      activeItem = this.$('.viewport .active'),
      activeItemOffset = activeItem.offset();

    if (!activeItemOffset) {
      return;
    }

    let  activeCurrentPosition = activeItemOffset.left - viewport.offset().left,
      activeWidth = activeItem.outerWidth(),
      activeCenter = activeCurrentPosition - (viewportWidth / 2) + (activeWidth / 2);

    viewport.scrollLeft(activeCenter);
  },

  didRender() {
    if (!this.scrolled) {
      this.set('canScrollRight', this.viewport.scrollWidth !== this.viewport.offsetWidth && (this.viewport.scrollWidth - this.viewport.offsetWidth) !== this.viewport.scrollLeft);
    } else {
      this.scrolled;
    }
  },

  onScroll(e) {
    this.throttleTimer = run.throttle(this, this.updateScrollState, e, 150, false);
  },

  updateScrollState() {
    this.setProperties({
      canScrollLeft: this.viewport.scrollLeft > 10,
      canScrollRight: this.viewport.scrollWidth - this.viewport.offsetWidth !== this.viewport.scrollLeft,
      scrolled: true
    });
  },

  willDestroyElement() {
    run.cancel(this.throttleTimer);
    $(this.viewport).off('scroll', this.boundScrollHandler);
  },

  actions: {
    scrollRight() {
      $('.viewport').animate({
        scrollLeft: '-=' + this.scrollDistance
      }, this.scrollDuration);
    },

    scrollLeft() {
      $('.viewport').animate({
        scrollLeft: '+=' + this.scrollDistance
      }, this.scrollDuration);
    }
  }

});
