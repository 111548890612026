import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import sideNav from 'b5b/mixins/side-nav';
import {
  setupTripEntityNav
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';
import tripSubRoute from 'b5b/mixins/trip-sub-route';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

export default Route.extend(tripSubRoute, sideNav, {

  ui: service(),
  scroll: service(),
  session: service(),
  templateManager: service(),
  whitelabel: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,

  beforeModel(transition) {
    this._super(...arguments);

    return this.get('session.currentUserPromise').then(() => {
      if (!(this.get('session.currentUser.isManager') || this.get('session.currentUser.isTimbuktu') || this.whitelabel.isOnboardingAgency)) {
        transition.abort();
      }
    })
  },

  setupController() {
    this._super(...arguments);

    var trip = this.get('trip');

    this.tripService.ensureAllPartnersLoaded();

    // navs setup
    setupTripEntityNav(trip, this.get('tripService'), this.get('ui'), this.get('session'));

    let userManagingThisTrip = trip.get('userTrip') && this.get('tripService.userCanManageThisTrip');
    let editingPublishedContent = trip.contentManagedTrip && this.get('session.currentUser.isContentTeam');
    let showManagerSideTabs = userManagingThisTrip || this.whitelabel.isOnboardingAgency
    if (this.whitelabel.showManagerTools || editingPublishedContent) {
      this.get('ui').setProperties({
        displayOnSideBar: 'hide-after-tablet',
        sideNavOptions: [{
          route: 'trip.index.edit.itinerary',
          title: 'Itinerary',
          item: trip
        }]
      });
    }

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.guests',
        title: 'Guests',
        item: trip
      }, {
        route: 'trip.index.edit.pricing.line-items',
        title: 'Pricing',
        routeModel: trip,
        subNavOptions: [
          {
            route: 'trip.index.edit.pricing.line-items',
            title: 'Line Items',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.discounts',
            title: 'Discounts',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.payments',
            title: 'Payments',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.summary',
            title: 'Summary',
            routeModel: trip
          },
        ]
      }]))
    }

    if (showManagerSideTabs || editingPublishedContent) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.images',
        title: 'Images',
        routeModel: trip
      }]))
    }

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.quote-details',
        title: 'Details',
        item: trip
      }]))
    }

    if (showManagerSideTabs || editingPublishedContent) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.video',
        title: 'Video',
        routeModel: trip
      }]))
    }

    if (trip.itinerary.isInstantBookable) {

      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.rates',
        title: 'Rates',
        item: trip,
        showMainNav: false
      }]))
    }

    this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
      route: 'trip.index.edit.brochure',
      title: 'Brochure',
      routeModel: trip
    }]))

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.analytics',
        title: 'Analytics',
        routeModel: trip
      }]))
    }

    this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
      route: 'trip.index.edit.tools',
      title: 'Tools',
      item: trip
    }]))

  },

  resetController(isExiting) {
    this._super(...arguments);

    this.get('ui').setProperties({
      sideNavOptions: null,
      showMainNav: true
    });


    if (isExiting) {
      this.set('ui.entityNavData', null);
      this.set('templateManager.tripToolsShowTemplateType', null)
    }
  },

  actions: {
    saveTrip(options={}) {
      if (this.get('trip.itinerary.quote.actualLineItems.length') == 1) {
        //Deal with possible blank line item created whne going to pricing route
        let lineItem = this.get('trip.itinerary.quote.actualLineItems.firstObject');
        if (lineItem.isNew && !lineItem.originalPerGuest && !lineItem.overridePerGuest) {
          lineItem.deleteRecord();
        }
      }

      let valid = true;
      let validServiceTypes = true;
      let validReaons = true;
      let lineItems = this.get('trip.itinerary.quote.actualLineItems');
      if (lineItems) {
        //If there is no quote then line items will be null
        this.get('trip.itinerary.quote.actualLineItems').forEach((lineItem) => {
          if (!lineItem.belongsTo('partner').id()){
            valid = false;
          }
          if (this.tripService.timbuktuTripOnPricingModel4 && !lineItem.belongsTo('serviceType').id()) {
            validServiceTypes = false
          }
          // console.log(lineItem.reasonForNoAutoMarkup)
          if (this.tripService.timbuktuTripOnPricingModel4 && !lineItem.useTimbuktuAutoMarkup && !lineItem.reasonForNoAutoMarkup) {
            validReaons = false
          }

        });
      }

      if (!valid){
        this.get('ui').showGeneralMessage('Oops!', 'Please ensure that all line items in the pricing tab have partners allocated to them.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }
      if (!validServiceTypes){
        this.get('ui').showGeneralMessage('Oops!', 'Please ensure that all line items in the pricing tab have service types allocated to them.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }
      if (!validReaons){
        this.get('ui').showGeneralMessage('Oops!', 'If the Timbuktu auto markup is disabled on any line items please add a reason.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      if (valid && this.get('trip.itinerary.quote.actualLineItems.length') > 0 && (!this.get('trip.itinerary.quote.sourceCountry') || !this.get('trip.itinerary.quote.settlementCurrency'))) {
        this.get('ui').showGeneralMessage('Oops', 'Please select source country and settlement currency in pricing tab');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }


      let allTransferVehicleTypes = this.get('trip.itinerary.transferStages').mapBy('transfer.vehicleType');
      if (allTransferVehicleTypes.includes(undefined)){
        this.set('controller.showCheckVehicleTypes', true);
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      let args = {showSuccessFlash: true};
      if (options.makeCopyOfTrip !== undefined) {
        args.makeCopyOfTrip = options.makeCopyOfTrip;
      }
      return this.controller.saveTrip(this.get('trip'), args);
    },

    closeRoute() {
      this.transitionTo('trip', this.get('trip'));
    },

    // didTransition() {
    //   if ($('.main-pane')) {
    //     this.get('scroll').to(0, this, null, null, $('.main-pane'));
    //   }
    //   return true;
    // },

  }

});
