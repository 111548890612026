import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  isPresent
} from '@ember/utils';
import SetupCountryFaqModal from 'b5b/mixins/setup-country-faq-modal';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(SetupCountryFaqModal, {

  needToKnowType: 'visas',

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags: function() {
    var country = this.modelFor('country');
    const title = `Visa requirements for your ${country.get('seoName')} | ${this.whitelabel.agencySeoName}`;
    const description = `Visa requirements for your ${country.get('seoName')}`;
    let link;
    if (isPresent(country.get('countryInfo.visas'))) {
      link = `country/${country.get('countryFriendlyId')}/visas`;
    } else {
      link = `country/${country.get('countryFriendlyId')}`;
    }

    if (!this.isFastBoot) {
      document.title = title;
    }

    return generateMeta({
      description,
      link,
      'og:title': title
    });
  }

});
