import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  htmlSafe
} from '@ember/string';
import {
  getTermsAndConditionsUrlForTrip
} from 'b5b/utils';

export default Helper.extend({
  whitelabel: service(),

  // Trip Rate prices are used to show trip rates for specials / packages in a list to guest. THe prices shown
  // need to match up to prices created by pricing agen.t

  compute(params, namedArgs) {

    return htmlSafe(getTermsAndConditionsUrlForTrip(namedArgs.trip));
  }

});
