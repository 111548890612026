import TMapComponent from 'b5b/components/t-map/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';

export default TMapComponent.extend({

  tripService: service('trip'),
  @alias('tripService.currentTrip') currentTrip: null

});
