import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend(basicNav, {

  setupController() {
    this._super(...arguments);
    trackEvent('trip:dashboard:guest-details');
  }

});
