import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({


  ui: service(),
  tripService: service('trip'),

  @computed()
priceTypes() {
  return [
    { key: 'net-ppn-extra', name: 'Net per person per night extra' },
    { key: 'net-pp-extra', name: 'Net per person extra' },
    { key: 'net-extra', name: 'Net extra' },
    { key: 'selling-extra', name: 'Extra selling price incl fees' }
  ]
},

@computed('lodgeOption.totalSellingPriceExtra')
extraTransactionFees(totalSellingPriceExtra) {
  if (totalSellingPriceExtra) {
    return totalSellingPriceExtra * 0.035;
  }
},

actions: {

  netChanged() {
    let numGuests = this.get('ui.addLodgeOptionModal.stage.itinerary.numGuests')
    let numNights = this.get('ui.addLodgeOptionModal.stage.numNights')

    if (this.lodgeOption.priceType == 'net-ppn-extra') {

      if (this.lodgeOption.netPerPersonNightExtra && this.lodgeOption.markup) {
        let netPerPerson = this.lodgeOption.netPerPersonNightExtra * numNights;
        let net = netPerPerson * numGuests;
        let selling = net * (100 + parseFloat(this.lodgeOption.markup)) / (100 * this.get('whitelabel.agency.meta.pcTfmm'));
        this.set('lodgeOption.netPerPersonExtra', netPerPerson);
        this.set('lodgeOption.netExtra', net);
        this.set('lodgeOption.totalSellingPriceExtra', selling);
      }
    } else if (this.lodgeOption.priceType == 'net-pp-extra') {

      if (this.lodgeOption.netPerPersonExtra && this.lodgeOption.markup) {
        let netPerPersonNight = this.lodgeOption.netPerPersonExtra/ numNights;
        let net = this.lodgeOption.netPerPersonExtra * numGuests;
        let selling = net * (100 + parseFloat(this.lodgeOption.markup)) / (100 * this.get('whitelabel.agency.meta.pcTfmm'));
        this.set('lodgeOption.netPerPersonNightExtra', netPerPersonNight);
        this.set('lodgeOption.netExtra', net);
        this.set('lodgeOption.totalSellingPriceExtra', selling);
      }
    } else if (this.lodgeOption.priceType == 'net-extra') {
      if (this.lodgeOption.netExtra && this.lodgeOption.markup) {

        let selling = this.lodgeOption.netExtra * (100 + parseFloat(this.lodgeOption.markup)) / (100 * this.get('whitelabel.agency.meta.pcTfmm'));
        let netPerPerson = this.lodgeOption.netExtra / numGuests;
        let netPerPersonNight = netPerPerson/ numNights;
        this.set('lodgeOption.totalSellingPriceExtra', selling);
        this.set('lodgeOption.netPerPersonExtra', netPerPerson);
        this.set('lodgeOption.netPerPersonNightExtra', netPerPersonNight);

      }
    }
  },

  setPriceType(lodgeOption, priceTypeObject) {
    lodgeOption.set('priceType', priceTypeObject.key);
    this.tripService.madeChanges();
  }
}
});

