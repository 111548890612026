import filterPaneComponent from 'b5b/components/filter-pane/component';
import {
  computed
} from 'ember-decorators/object';
import {
  task
} from 'ember-concurrency';
import {
  REGION_TYPES,
  getCategoryExperiencesPromise
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';

export default filterPaneComponent.extend({
  classNames: ['regions'],

  selectedLodgeStyleNames: null,

  categoryExperiences: null,
  cache: service(),

  // @computed()
  // allRegionTypes() {
  //   return REGION_TYPES;
  // },

  @computed('entityModel')
  showCountries(entityModel) {
    return !entityModel;
  },

  init() {
    this._super(...arguments);
    this.set('performingSetupTask', this.get('setupCategoryExperiences').perform());
    //We load the countries in the generic /regions route
    this.cache.getAllPublishedCountriesPromise().then((countries)=> {
      this.set('countries', countries)
    })
  },

  setupCategoryExperiences: task(function*() {
    let catExperiences = yield getCategoryExperiencesPromise(this.get('store'));
    this.set('categoryExperiences', catExperiences);
  }),

  didRender() {
    this._super(...arguments);

    let filterExperiences = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'experience')    
    if (this.get('categoryExperiences.length') && filterExperiences && filterExperiences.length > 0) {
      this.set('showAllExperiences', true);
    }        
  }

});
