import { helper } from '@ember/component/helper';

export function wizardSubmissionFormatter(params/*, hash*/) {

  let results = [];
  if (params[0]) {

    //parse string if required
    let jsonObj = (typeof params[0] === 'string') ? JSON.parse(params[0]) : params[0];

    Object.keys(jsonObj).map(function(key) {


      switch (key) {
        case 'goAnytime':
          if (jsonObj[key]) {
            results.pushObject('Anytime');
          }
         break


        // case 'preferedPartner':
        //  break

        // case 'planningStage':
        //  break

        case 'selectedCountryNames':
        case 'selectedExperienceNames':
          if (jsonObj[key].length > 0) {
            results = results.concat(jsonObj[key]);
          }
         break;

        case 'selectedMonthNames':
          if (jsonObj[key].length > 0) {
            if (jsonObj['preferedYear']) {
              results = results.concat(jsonObj[key] + ' in ' + jsonObj['preferedYear']);
            } else {
              results = results.concat(jsonObj[key]);
            }
          }
         break

        default:
         break;
      }
    })
  }

  // remove blank/null values
  results.removeObject(null);

  return results;
}

export default helper(wizardSubmissionFormatter);
