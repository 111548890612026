import Component from "@ember/component";
import { observes } from "ember-decorators/object";
import { run } from '@ember/runloop';

export default Component.extend({
  classNames: ["x-textarea"],
  autoExpand: true,
  disabled: false,
  required: false,
  autocomplete: 'off',
  spellcheck: false,
  textarea: null,
  didInsertElement() {
    this._super(...arguments);
    this.set('textarea', this.element.querySelector("textarea"));
    this.expandAsPerHeight(this.textarea);
  },
  expandAsPerHeight(textarea) {
    if (this.autoExpand) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  },
  @observes('value')
  valueChanged() {
    run.next(()=> {
      this.expandAsPerHeight(this.textarea);
    })
  },
  actions: {
    expandAsPerHeight(e) {
      this.expandAsPerHeight(e.target);
    },
    focusIn() {
      if (this.focusIn) {
        this.focusIn();
      }
    },
    onkeyup() {
      if (this.onkeyup) {
        this.onkeyup();
      }
    },
    onfocus() {
      if (this.onfocus) {
        this.onfocus();
      }
    },
  },
});
