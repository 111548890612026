import DS from 'ember-data';

export default DS.Model.extend({
  email: DS.attr(),
  firstName: DS.attr(),
  submissions: DS.attr(),
  wizardRules: DS.attr(),
  user: DS.belongsTo('user', {
    inverse: null
  }),
  tripSummaries: DS.hasMany('tripSummary', {async: false, inverse: null})
});
