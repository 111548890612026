import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  capitalizeFirstLetter
} from 'b5b/utils';

export function deunderscore([str] /*, hash*/ ) {
  return capitalizeFirstLetter(str.replace(/_/g, ' '));
}

export default buildHelper(deunderscore);
