import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  htmlSafe
} from '@ember/string';


export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let blockTitle = this.get('tripService.currentTrip.isLayoutDayByDay') ? 'Pages' : 'What you\'ll do'
    return htmlSafe(blockTitle);
  }

});
