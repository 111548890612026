import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  reads
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['region-experience-card'],

  entityModals: service(),
  router: service(),
  tripService: service('trip'),

  experience: null,

  canSwipe: true,

  @reads('entityModals.onModal') onModal: false,
  onStage: false,

  mouseEnter() {
    if (this.get('mapHover')) {
      this.set('experience.hover', true);
    }
  },

  mouseLeave() {
    if (this.get('mapHover')) {
      this.set('experience.hover', false);
    }
  },

  actions: {
    openModal(model) {
      if (this.get('onModal')) {
        this.get('entityModals').openModal({
          model
        });
      }
    }
  }

});
