define('ember-deferred-content/components/deferred-content/pending-content', ['exports', 'ember-deferred-content/templates/components/deferred-content/pending-content'], function (exports, _pendingContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _pendingContent.default,
    tagName: ''
  });
});