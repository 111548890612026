import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  teamSort
} from 'b5b/utils';

export default Controller.extend({

  @computed('users')
  teamWithEmails(users) {
    if (users) {
      let team  = users.toArray().sort(teamSort);
      return team;  
    }
  }
});
