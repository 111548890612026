define('ember-chrome-devtools/formatters/lazy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var Lazy = exports.Lazy = function Lazy(compute) {
    _classCallCheck(this, Lazy);

    this.compute = compute;
  };

  var LazyFormatter = exports.LazyFormatter = function () {
    function LazyFormatter() {
      _classCallCheck(this, LazyFormatter);
    }

    _createClass(LazyFormatter, [{
      key: 'header',
      value: function header(obj) {
        if (obj instanceof Lazy) {
          var style = '\n        opacity: 0.8;\n        font-style: italic;\n      ';
          return ['span', { style: style }, '(...)'];
        }
      }
    }, {
      key: 'hasBody',
      value: function hasBody() {
        return true;
      }
    }, {
      key: 'body',
      value: function body(obj) {
        return obj.compute();
      }
    }]);

    return LazyFormatter;
  }();
});