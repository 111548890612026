import Component from '@ember/component';
import {
  isEmpty
} from '@ember/utils';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNameBindings: [':x-val', 'fail', 'visible::masked'],

  errors: null,
  log: false,
  visible: true,

  @computed('errors')
  fail(errors) {
    return !isEmpty(errors);
  }

});