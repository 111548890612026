import {
  helper as buildHelper
} from '@ember/component/helper';

// Expects lodge or stage
export function regionCommaParentRegion(params, namedArgs ) {

  let lodge = namedArgs.lodge;
  let stage = namedArgs.stage;
  let region = namedArgs.region;
  if (!region) {
    region = lodge && lodge.region || stage && stage.regionProxy
  }

  if (!lodge && !stage) {
    console.log('WARNING --- You need to pass in a lodge or stage!!!!')
  }

  if (region && region.get('regionAndCountryAreTheSameThing')) {
    return region.get('country.displayName');
  }

  let regionDisplayName = region && region.displayName || lodge && lodge.placeName || stage && stage.placeName;

  if (!regionDisplayName) {
    return '';
  }


  let displayValue = regionDisplayName;
  if (region && region.belongsTo('parentRegion').id()) {
    displayValue = `${displayValue}, ${region.get('parentRegion.displayName')}`
  }
  return displayValue;

}

export default buildHelper(regionCommaParentRegion);
