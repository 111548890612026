import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';
import {
  alias
} from 'ember-decorators/object/computed';

export default Mixin.create({

  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null

});