import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend(HasKodakImage, {
  name: DS.attr('string'),
  teaserText: DS.attr(),
  description: DS.attr(),
  listingHeading: DS.attr(),
  filterType: DS.attr(), // add-on || lodge-filter || budget-filter || lodge-options
  destinationList: DS.belongsTo('destinationList'),
  sector: DS.belongsTo('sector', {async: false, inverse: null}),
  autoBudget: DS.attr(),
  experiences: DS.hasMany('experience', {
    inverse: null,
    async: false
  }),

  @computed('destinationList.destinationListItems.length')
  isSingleEntity(destinationListItemsLength) {
    return destinationListItemsLength === 1;
  },

  @computed('destinationList.destinationListItems')
  filterRegions(destinationListItems) {
    if (destinationListItems) {
      return destinationListItems.filterBy('destinationType', 'region').mapBy('region');
    }
    return [];
  },

  @computed('destinationList.destinationListItems')
  filterCountries(destinationListItems) {
    if (destinationListItems) {
      return destinationListItems.filterBy('destinationType', 'country').mapBy('country');
    }
    return [];
  },


});
