import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  didInsertElement() {
    //  we need to insert these page breaks at teh bottom of pages because the content is very dynamic and we need to ensure that there is a page break after teh absolutely positioned hero on the cover
    if (this.breakNum > this.maxBreakNum) {
      this.set('maxBreakNum', this.breakNum);
    }
  }
});
