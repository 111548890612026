import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({

  ui:service(),
  tagName: '',

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('playerId', `player${new Date().getTime()}`);
    if (this.url && this.url.indexOf('http') >= 0) {
      this.set('singleVideoUrl', this.url.replace('share', 'embed'))
    } else if (this.url) {
      this.set('singleVideoUrl', null)
    }
  },




});
