import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

export default Controller.extend(UseCurrentTrip, {
  queryParams: [{
    returnRoute: {
      as: 'return'
    }
  }]
});
