import {
  inject as controller
} from '@ember/controller';
import Mixin from '@ember/object/mixin';

import {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  isEmpty
} from '@ember/utils';
import {
  minTripPriceForRange,
  maxTripPriceForRange
} from 'b5b/utils';
import {
  task
} from 'ember-concurrency';
import {
  run
} from '@ember/runloop';


export default Mixin.create({

  firstTimeLoad: true,

  screen: service(),
  scroll: service(),
  ui: service(),
  pills: service(),
  filters: [],
  additionalFilter: null,
  orRegionIds: [],

  xplorer: service('components/x-plorer'),
  @alias('xplorer.tripSummaries') tripSummaries: null,

  applicationController: controller('application'),

  @alias('applicationController.mapOnlyView') mapOnlyView: null,
  @alias('applicationController.filterOnlyView') filterOnlyView: null,

  @alias('trips.meta.noMatch') noMatch: false,

  per_page: 18,
  paginationScrollHeight: 400,

  @computed('screen.isMobileOnly')
  paginationPageCount(isMobile) {
    return isMobile ? 3 : 10;
  },

  @alias('tripSummaries.loading') isLoading: false,

  updatePageParam: observer('page', function() {
    var page = this.get('page');
    if (page) {
      this.set('page', page);
    }

    // causes page jump with the inline filter pane
    // if (!this.get('firstTimeLoad')) {
    //   this.get('scroll').to(400, this);
    // }
  }),

  // Query param defaults are defined here. Please note that if you want to change any of these, then the defaults passed into the link-to 'trips' needs to get these new default values
  // Please ensure these are also set up in the controller mixin

  queryParams: [{
    selectedContinentNames: {
      as: 'continents'
    },
    selectedCountryNames: {
      as: 'countries'
    },
    selectedRegionNames: {
      as: 'regions'
    },
    selectedMonthNames: {
      as: 'months'
    },
    selectedExperienceNames: {
      as: 'experiences'
    },
    selectedLodgeNames: {
      as: 'lodges'
    },
    searchValue: {
      as: 'q'
    },
    selectedTripLengths: {
      as: 'durations'
    },
    selectedBudget: {
      as: 'budget'
    },    
    isPopular: {
      as: 'popular'
    },
    
    searchTemplateTrips: {},
    searchPackages: {},
    showOnlyWithOffers: {},
    page: {},
    sort: {},
    reset: {},
    filters: {},
    recurringDateRange: {},    
  }],

  // If you change any of these you need to change the tempalte for link-to-trips as well
  selectedContinentNames: [],
  selectedCountryNames: [],
  selectedRegionNames: [],
  selectedMonthNames: [],
  selectedExperienceNames: [],
  selectedLodgeNames: [],
  searchValue: '',
  selectedTripLengths: [],
  selectedBudget: [minTripPriceForRange(), maxTripPriceForRange()],
  recurringDateRange: ['any', 'any', 'any', 'any'],
  isPopular: false,
  searchTemplateTrips: false,
  searchPackages: false,
  showOnlyWithOffers: false,
  page: 1,
  sort: 'from_price_asc',
  defaultSort: 'from_price_asc',
  reset: false,
  // You need to override both of these polish levels if you want a different default
  polishLevel: 'default',
  defaultPolishLevel: 'default',
  bookingState: 'all',

  // End default query params

  loadTripsTask: task(function*() {
    console.log('loadTripsTask')
    this.loader = run.next(() => {
      this.get('scroll').to(this.paginationScrollHeight || 0);
    });

    if (this.searchMode == 'destinationChange') {
      this.resetLatLng();
    }
    let trips = yield this.get('xplorer').loadRecords('tripSummaries', {skipSettingsRecords: true});
    this.set('tripSummaries', trips)    
    this.entitiesLoaded();
  }).keepLatest(),


  entitiesLoaded() {
    // Right now we do nothing, but this can be used to trigger side effects when entities are loaded. See the map listing for example
  }, 

  actions: {
    resetFilters() {
      this.send('resetFilters');
    },

    // Not sure if this might cause issues, since it teardown the map everytime
    // It does however fix another issue where it tries to set positions on a non existing map
    // This is duplicated here as we dont ahve map searching on routes currently
    onMapDestroy() {
      // clear to avoid it trying to set bounds when not in dom
      this.set('mapInstance', null);
    }
  }


});
