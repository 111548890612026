import Component from "@ember/component";
import { inject as service } from "@ember/service";

import { run } from "@ember/runloop";
import {
  computed
} from 'ember-decorators/object';
import {
  copyTextToClipboard
} from 'b5b/utils';

export default Component.extend({
  ui: service(),
  router: service(),
  tripService: service('trip'),
  classNames: ['trip-tools-right-pane-standard', 'trip-tools-right-pane'],

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  },  

  actions: {
    focusStage(stage, index) {
      this.router.transitionTo("trip.index.edit.itinerary");
      
      let actuallyFocus = function () { 
        let stageSelector = $(".is-component-for-real-stage:eq(" + index + ")");
        if (stageSelector.length == 0) {
          return run.later(actuallyFocus, 50);
        }
        stage.set('forceCardOpen', true)
        $("html, body").animate(
          {
            scrollTop:
              stageSelector.offset().top - 100,
          },
          500
        );
      };

      run.schedule("afterRender", actuallyFocus);
    },
    copyTripLinkToClipboard() {
      var url = window.location.origin + "/trip/" + this.get('trip.friendlyId');
      try {
        copyTextToClipboard(url);
        this.get('flashMessages').success('Trip link copied to clipboard');
        return true;
      } catch (err) {
        this.get('flashMessages').danger('Couldn\'t copy link');
      }
    },
    
  },
});
