import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNameBindings: [':select-button', 'isSelected', 'cover:has-cover', 'fixedSquare'],
  attributeBindings: ['style'],

  cover: null,

  onSelect: null,
  selectedValue: null,
  selectedValues: null,
  collection: false, // if button is part of array
  arrayLimit: 0,

  @computed('selectedValue.[]', 'value', 'isArray')
  isSelected(selectedValue, value, isArray) {
    if (isArray) {
      return selectedValue.includes(value);
    } else {
      return selectedValue === value;
    }
  },

  @computed('selectedValue')
  isArray(selectedValue) {
    return Array.isArray(selectedValue);
  },

  @computed('w', 'h')
  style(w, h) {
    if (w && h) {
      return `min-width: ${w}px; min-height: ${h}px;`
    }
  },

  click() {

    if (this.get('isArray')) {
      if (this.get('isSelected')) {
        this.get('selectedValue').removeObject(this.get('value'));
      } else {

        if (this.get('arrayLimit') && this.get('selectedValues.length') === this.get('arrayLimit')) {
          this.get('flashMessages').warning("You've reached your limit.");
          return;
        }

        this.get('selectedValue').pushObject(this.get('value'));
      }
    } else {
      if (this.get('isSelected')) {
        this.set('selectedValue', undefined);
      } else {
         this.set('selectedValue', this.get('value'))
      }
    }

    // this needs to run last
    if (this.get('onSelect')) {
      this.get('onSelect')(this.get('value'));
    }

  }
});
