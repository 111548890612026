import {
  isPresent
} from '@ember/utils';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  alias
} from 'ember-decorators/object/computed';

export default DS.Model.extend({


  isGiftListOwner: DS.attr('boolean'),

  user: DS.belongsTo('user', {
    async: false,
    inverse: null
  }),
  trip: DS.belongsTo('trip'),

});
