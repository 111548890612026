import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  isPresent
} from '@ember/utils';
import SetupCountryFaqModal from 'b5b/mixins/setup-country-faq-modal';
import {
  generateMeta
} from 'b5b/utils';

export default Route.extend(SetupCountryFaqModal, {

  needToKnowType: 'whatToPack',

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags() {
    let region = this.modelFor('region');
    var country = region.get('country');
    const title = `What to pack for your ${region.get('seoName')} | ${this.whitelabel.agencySeoName}`;
    const description = country.get('countryInfo.whatToPack');

    let link;
    if (isPresent(country.get('countryInfo.whatToPack'))) {
      link = `country/${country.get('countryFriendlyId')}/what-to-pack`
    } else {
      link = `country/${country.get('countryFriendlyId')}`
    }

    if (!this.isFastBoot) {
      document.title = title;
    }
    return generateMeta({
      link,
      description,
      'og:title': title
    });
  }

});
