define("ember-leaflet/components/geojson-layer", ["exports", "ember-leaflet/components/base-layer", "ember-leaflet/mixins/style", "ember-leaflet/mixins/div-overlayable"], function (_exports, _baseLayer, _style, _divOverlayable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * @public
   * An ember-leaflet wrapper for L.geoJson, which renders GeoJson data onto a
   * map as features.
   *
   * Takes:
   *   - geoJSON: the GeoJSON object to render
   *   - all standard leaflet options for L.geoJson
   */
  var _default = _exports.default = _baseLayer.default.extend(_divOverlayable.default, _style.default, {
    leafletRequiredOptions: Object.freeze(['geoJSON']),
    leafletOptions: Object.freeze(['stroke', 'color', 'weight', 'opacity', 'fill', 'fillColor', 'fillOpacity', 'fillRule', 'dashArray', 'lineCap', 'lineJoin', 'clickable', 'pointerEvents', 'className', 'pointToLayer', 'style', 'onEachFeature', 'filter', 'coordsToLatLng']),
    leafletEvents: Object.freeze(['click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'add', 'remove', 'popupopen', 'popupclose']),
    leafletProperties: Object.freeze(['style']),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var geoJSON = this.get('geoJSON');
      if (geoJSON) {
        this.pushDataToLeaflet(geoJSON);
      }
    },
    pushDataToLeaflet: function pushDataToLeaflet(geoJSON) {
      if (!this._layer) {
        return;
      }

      // recall that GeoJSON layers are actually layer groups -- we have to clear
      // their contents first...
      this._layer.clearLayers();

      // we need to update the group layers options before re-adding geojson
      // otherwise, they wouldn't get the changes that could be happening meanwhile
      this.notifyPropertyChange('options');
      this._layer.options = this.get('options');
      if (geoJSON) {
        // ...then add new data to recreate the child layers in an updated form
        this._layer.addData(geoJSON);
      }
    },
    createLayer: function createLayer() {
      var _this$L;
      return (_this$L = this.L).geoJson.apply(_this$L, _toConsumableArray(this.get('requiredOptions')).concat([this.get('options')]));
    }
  });
});