import Route from '@ember/routing/route';
import tripEditorRoute from 'b5b/mixins/trip-editor/route';
import Service, {
  inject as service
} from '@ember/service';
export default Route.extend(tripEditorRoute, {

  cache: service(),
  

  beforeModel() {
    this._super(...arguments);
    // return this.cache.getAllLocationsPromise();
  }

});
