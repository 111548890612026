import { helper } from '@ember/component/helper';

export function properNot([val]) {
  if (val==undefined || val == null) {
    return false
  }
  return !val;
}

export default helper(properNot);
