import {
  inject as service
} from '@ember/service';
import Mixin from '@ember/object/mixin';

export default Mixin.create({

  headTagsService: service('head-tags'),
  entityModals: service(),

  setupController: function(controller) {
    var country = this.modelFor('country') || this.modelFor('region').get('country');
    controller.set('country', country);

    let closeAllEntityModals = () => {
      this.transitionTo('country.index', country);
    }

    this.entityModals.openModal({
      model: country,
      modalType: 'need-to-know',
      needToKnowType: this.needToKnowType,
      closeAllEntityModals
    })
  },

  afterModel: function( /*lodge*/ ) {
    let country = this.modelFor('country') || this.modelFor('region').get('country');

    return country.get('countryInfo').then(() => {
      this.headTagsService.collectHeadTags();
    });
  }

});
