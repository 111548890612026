import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  tripService: service('trip'),

  /*
    YOU NEED to pass in settings.currentCurrency
    Comments moved to tripService.getLodgePrice


NB: Can you use lodge-price-in-trip helper?
    If not, pick one of the two variants below.




NOTE: you only need LODGE accurate price if you are showing this price in a list of lodges

    {{lodge-price
      lodge=lodge
      currency=settings.currentCurrency
      startDate=tripService.startDateForPricingAndAvailability
      lodgeAccuratePriceDate=lodge.accuratePriceDate
      lodgeStartDate=lodge.startDate
      lodgeAccuratePriceAmount=lodge.accuratePriceAmount
      lodgeAccuratePriceCurrency=lodge.accuratePriceCurrency
    }} person/night


OR you only need STAGE accurate price if you are showing this price in an itinerary

    {{lodge-price
      lodge=lodge
      currency=settings.currentCurrency
      startDate=tripService.startDateForPricingAndAvailability
      stageAccuratePriceDate=stage.accuratePriceDate
      stageStartDate=stage.startDate
      stageAccuratePriceAmount=stage.accuratePriceAmount
      stageAccuratePriceCurrency=stage.accuratePriceCurrency}} person/night
  */

  compute(params, namedArgs) {
    return this.tripService.getLodgePrice(params, namedArgs);
  }

});
