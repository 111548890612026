import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import Component from '@ember/component';

export default Component.extend({

  store: service(),
  whitelabel: service(),

  classNames: ['blogs-section'],

  entities: null, // for arrays of same model type
  entity: null, // for single entity

  perPage: 18,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    if (this.get('entity') !== this.get('cache') && this.get('entities') !== this.get('cache')) {
      this.set('cache', this.get('entity') || this.get('entities'));
      this.setupQueryParams();
    }

    // for homepage eg
    if (!this.get('entity') && !this.entities) {
      this.doQuery({
        per_page: this.get('perPage')
      });
    }
  },

  @computed('entityType')
  seeAllLinkRoute(entityType) {
    return `${entityType}.articles`;
  },

  setupQueryParams() {

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    this.set('blogPosts', null);

    let paramKey = '',
      paramNames = [],
      queryParams = {
        per_page: this.get('perPage')
      };

    if (this.get('entity')) {
      if (this.get('entity.constructor.modelName')) {
        paramKey = 'selected' + this.get('entity.constructor.modelName').capitalize() + 'Names';
        paramNames.pushObject(this.get('entity.name'));
        queryParams[paramKey] = paramNames;
        this.doQuery(queryParams);
      } else {
        this.get('entity').then((entity) => {
          paramKey = 'selected' + entity.get('constructor.modelName').capitalize() + 'Names';
          paramNames.pushObject(entity.get('name'));
          queryParams[paramKey] = paramNames;
          this.doQuery(queryParams);
        });
      }
    } else if (!Ember.isEmpty(this.get('entities'))) {
      paramKey = 'selected' + this.get('entities.firstObject.constructor.modelName').capitalize() + 'Names';
      paramNames = this.get('entities').mapBy('name');
      queryParams[paramKey] = paramNames;
      this.doQuery(queryParams);
    }
  },

  doQuery(queryParams) {
    this.store.query('blog-post', queryParams).then((blogPosts) => {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      this.set('blogPosts', blogPosts);
    });
  },

  actions: {
    openBlog() {
      window.open('https://www.timbuktutravel.com/blog', '_blank');
    }
  }

});
