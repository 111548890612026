import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
// import { run } from "@ember/runloop";
import { alias, or } from "ember-decorators/object/computed";
import { computed } from "ember-decorators/object";
import { task } from "ember-concurrency";
import { observer } from "@ember/object";
import { isEmpty } from 'ember-utils';
import PrettyQuery from 'b5b/mixins/pretty-query';

export default Controller.extend(PrettyQuery, {
  classNames: ["templates-listing"],
  xplorer: service("components/x-plorer"),

  templateKeys: [
    "lodge_why_love",
    "stage_covid_overview",
    "transfers",
    "schedules",
    "inclusions",
  ],

  templateKey: "lodge_why_love",
  templateOrigin: "agency", //Only retrieves templates for this agency. Not the master ones owned by Timbuktu / waybird
  //THis cant be done
  buster: true,
  sort: "recommended",
  page: 1,
  per_page: 18,
  paginationPageCount: 3,
  filters: [],
  additionalFilter: null,
  queryParams: [
    {
      page: {},
      templateKey: {},
      filters: {},
    },
  ],
  @alias("xplorer.templates") templates: null,
  @alias("xplorer.transfers") transfers: null,
  @alias("xplorer.schedules") schedules: null,
  @alias("xplorer.inclusions") inclusions: null,

  @computed("templateKey")
  templateModel(templateKey) {
    let templateModel = null;
    switch (templateKey) {
      case "transfers":
        templateModel = "transfer";
        break;
      case "schedules":
        templateModel = "schedule";
        break;
      case "inclusions":
        templateModel = "inclusion";
        break;
      default:
        templateModel = "template";
    }
    return templateModel;
  },

  @computed(
    "templateModel",
    "transfers",
    "schedules",
    "inclusions",
    "templates"
  )
  templateResults(templateModel, transfers, schedules, inclusions, templates) {
    if (templateModel === "transfer") {
      return transfers;
    } else if (templateModel === "schedule") {
      return schedules;
    } else if (templateModel === "inclusion") {
      return inclusions;
    } else {
      return templates;
    }
  },

  @or(
    "xplorer.isLoading",
    "loadTemplatesTask.isRunning",
    "loadTransfersTask.isRunning",
    "loadSchedulesTask.isRunning",
    "loadInclusionsTask.isRunning"
  )
  taskRunning: false,

  loadTemplatesTask: task(function* () {
    let templates = yield this.get("xplorer").loadRecords("templates", {
      skipSettingsRecords: true,
    });
    this.set("templates", templates);
  }).restartable(),

  loadTransfersTask: task(function* () {
    this.setProperties({
      templateType: 'template',
      buster: true,
      sort: "recommended",
      templateOrigin: this.templateOrigin,
    });

    let results = yield this.get("xplorer").loadRecords("transfers", {
      skipSettingsRecords: true,
    });

    this.set("transfers", results);
  }).restartable(),

  loadSchedulesTask: task(function* () {
    this.setProperties({
      templateType: 'template',
      buster: true,
      sort: "recommended",
    });

    let results = yield this.get("xplorer").loadRecords("schedules", {
      skipSettingsRecords: true,
    });

    this.set("schedules", results);
  }).restartable(),

  loadInclusionsTask: task(function* () {
    this.setProperties({
      templateType: 'template',
      buster: true,
      sort: "recommended",
    });

    let results = yield this.get("xplorer").loadRecords("inclusions", {
      skipSettingsRecords: true,
    });

    this.set("inclusions", results);
  }).restartable(),

  fetchTemplates: task(function* () {
    if (this.templateKey === "transfers") {
      this.get("loadTransfersTask").perform();
    } else if (this.templateKey === "schedules") {
      this.get("loadSchedulesTask").perform();
    } else if (this.templateKey === "inclusions") {
      this.get("loadInclusionsTask").perform();
    } else if (
      ["lodge_why_love", "stage_covid_overview"].includes(this.templateKey)
    ) {
      this.get("loadTemplatesTask").perform();
    }
  }).restartable(),

  selectTemplate(templateKey) {
    this.setProperties({
      templateKey,
      page: 1,
    });
    this.get('fetchTemplates').perform();
  },

  actions: {

  },
});
