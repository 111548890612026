import DS from 'ember-data';
import { ActiveModelSerializer } from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {

    blockItems: {
      serialize: 'records'
    },
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards
    json.block_items_attributes = json.block_items;

    json.block_items_attributes.forEach((blockItem)=> {
      if (blockItem.original_block_id != blockItem.block_id) {
        //If you move block item from one block Item to another then this will cause
        // new blockItem to be created on server and old one to be deleted
        blockItem.id=null
        blockItem.block_id=null
        blockItem.block_item_experiences_attributes.forEach((blockItemExperience)=> {
          // If a block is moved, all of the block item experiences need to be re-created under new block id
          blockItemExperience.block_item_id=null
          blockItemExperience.id=null
        })
      }


    })

    delete json.block_items;


    return json;
  }
});
