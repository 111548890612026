import FeefoSection from 'b5b/components/feefo-section/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default FeefoSection.extend({
  classNames: ['trips'],

  @alias('feefo.loadingFeefo') loadingFeefo: null,
  @alias('feefo.feefoTripReviews') feefoTripReviews: null


});