import {
  and
} from '@ember/object/computed';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';


export default DS.Model.extend({

// NB: Guests are not part of the embedded save for trips. ItineraryGuest mappings are, but the actual guests need to be saved seperately

  itinerary: DS.belongsTo('itinerary'),
  sequence: DS.attr(),
  videoType: DS.attr('string'),
  url: DS.attr('string'),
  disabled: DS.attr('boolean'),

});
