import { computed } from '@ember/object';
import DS from 'ember-data';

export default DS.Model.extend({
  user: DS.belongsTo('user', {
    async: false
  }),
  country: DS.belongsTo('country', {
    async: true
  }),
  countryId: computed('country', function() {
    return this.belongsTo('country').id();
  })

});
