import { helper } from '@ember/component/helper';

export function possibleDaysAfterStart(params, namedArgs) {
  let array = ["No day"];
  for (var i = 0; i <= Math.round(namedArgs.numNights); i++) {
    // if (namedArgs.generateStrings) {
      array.push(i+'')
    // } else {
    //   array.push(i)
    // }
  }
  return array;
}

export default helper(possibleDaysAfterStart);
