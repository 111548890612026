import Component from '@ember/component';
import { inject as service } from "@ember/service";
import { getErrorsHashFromServerResponse } from "b5b/utils";

export default Component.extend({
  classNames: ['guest-row'],

  ui: service(),
  showingShouldDeleteModal: false,
  guestToRemove: null,

  actions: {
    changeGuestAgeType(guest, ageType) {
      guest.set("ageType", ageType);
    },

    willRemoveGuest(guest) {
      this.setProperties({
        showingShouldDeleteModal: true,
        guestToRemove: guest
      });
    },

    doRemoveGuest () {
      this.attrs.removeGuest(this.guestToRemove)
      this.setProperties({
        showingShouldDeleteModal: false,
        guestToRemove: null
      });
    },

    saveGuestLocal() {
      this.set("saving", true);
      this.set("errors", null);
      this.set("showErrors", false);
      this.guest
        .save()
        .then(() => {
          this.set("saving", false);
          this.set("showGuestDetailModal", false);
        })
        .catch((serverResponse) => {
          let errors = getErrorsHashFromServerResponse(serverResponse);
          if (errors && errors.authorisation) {
            this.ui.showGeneralMessage("Oops!", errors.authorisation);
          } else {
            this.flashMessages.danger(
              "Sorry! Please could you fill out all the required fields",
              {
                timeout: 4000,
                sticky: false,
              }
            );
          }

          this.set("saving", false);
          this.set("errors", errors);
          this.set("showErrors", true);
        });
    },
  },
});
