import { inject as service } from '@ember/service';
import Component from '@ember/component';

/*
  To be used as wrapping container whenever access to the service is required
  but not other component or controller is available.
  Remeber to add new ones to yield hash
*/

export default Component.extend({

  tagName: '',
  ui: service()

  // other: inject.service()

});
