import Helper from '@ember/component/helper';

export default Helper.extend({

  compute(params, namedArgs) {
    if (namedArgs.priceHash) {
      let containsOtherCurrency = false;
      Object.keys(namedArgs.priceHash).forEach((currency) => {
        if (namedArgs.currency !== currency) {
          containsOtherCurrency = true;
        }
      })
      return containsOtherCurrency;
    }
  }

});