import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['addon-card'],

  tripService: service('trip'),
  entityModals: service(),
  addOn: null,

  click() {
    this.entityModals.openAddOnModal({
      model: this.addOn.stageFilter
    })
  }

});