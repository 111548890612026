import {
  trackEvent
} from 'b5b/utils';
import Component from '@ember/component';

export default Component.extend({

  tagName: '',

  didInsertElement() {
    let sidePane = $('.editor .side-pane');
    if (sidePane.length > 0) {
      let height = sidePane.height();
      let windowHeight = $(window).height();
      // console.log(height, windowHeight)
      if (height < 479 || windowHeight < 587) {
        trackEvent('trip:editor:save-button-blocked', {height, windowHeight})
      }
    }

  }
});
