import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['how-it-works-routes'],

  ui: service(),

  hideVideo: false

});
