import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['category-experience-card'],

  entityModals: service(),

  experience: null,

  canSwipe: true,

  @alias('entityModals.onModal') onModal: null,

  actions: {
    openModal(options) {
      if (this.get('onModal')) {
        this.get('entityModals').openModal(options);
      }
    }
  }

});
