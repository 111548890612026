import Component from '@ember/component';

export default Component.extend({
  actions: {
    openMapModal() {
      if (this.mapOpenerAction) {
        this.mapOpenerAction();
      } else {
        this.set('showMapModal', true)
      }
    }
  }
});
