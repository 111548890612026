import {
  inject as service
} from '@ember/service';
import TripOverviewComponent from 'b5b/components/trip-overview/component';
import {
  computed
} from 'ember-decorators/object';

export default TripOverviewComponent.extend({
  type: 'invoice',
  settings: service(),
  whitelabel: service(),
  tripService: service('trip'),
  classNames: ['trip-overview-invoice-receipt'],

  @computed('surchargeTotalAmount')
  hasSurcharges(surchargeTotalAmount) {
    let total = 0
    Object.keys(surchargeTotalAmount).forEach((surchargeCurrencyKey) => {
      total += surchargeTotalAmount[surchargeCurrencyKey];
    });
    return total > 0;
  },

  @computed('trip.itinerary.validCharges.@each.surchargeAmount')
  surchargeTotalAmount(charges) {
    var hash = {}
    charges.forEach((charge) => {
      if (charge.get('surchargeAmount') > 0) {
        hash[charge.get('currency')] = (hash[charge.get('currency')] || 0) + charge.get('surchargeAmount')
      }
    });
    return hash;
  },

  // This is the new calculation
  @computed('surchargeTotalAmount', 'trip.itinerary.totalSellingPriceHash')
  totalWithSurchargeAndDiscounts(surchargeTotalAmount, quoteTotal) {
    var includingSurcharge = {};
    let keys = Object.keys(quoteTotal).concat(Object.keys(surchargeTotalAmount));
    //de-dupe keys
    // var uniqueKeys = deDupeArray(keys);
    keys.forEach((currencyKey) => {
      includingSurcharge[currencyKey] = parseFloat(surchargeTotalAmount[currencyKey] || 0) + parseFloat(quoteTotal[currencyKey] || 0);
    });
    return includingSurcharge;
  }
});
