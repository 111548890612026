import Component from '@ember/component';
import {
  htmlSafe
} from '@ember/string';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNameBindings: [':x-dialog', 'isOpen', 'alignment', 'static'],
  attributeBindings: ['style'],

  isOpen: false,

  align: 'left top', // default
  minWidth: null,
  offsetY: null,
  offsetX: null,
  arrowY: null, // from left or right, depends on align setting

  static: false,

  @computed('minWidth', 'offsetY', 'offsetX', 'align', 'screen.isMobileOnly')
  style(minWidth, offsetY, offsetX, align, isMobile) {
    var style = '';
    if (minWidth && !isMobile) {
      style += 'min-width:' + minWidth + 'px;';
    }
    if (offsetY) {
      style += 'margin-left:' + offsetY + 'px;';
    }
    if (offsetX) {
      if (align.indexOf('bottom') > 0) {
        style += 'margin-top:' + offsetX + 'px;';
      } else {
        style += 'margin-bottom:' + offsetX + 'px;';
      }
    }
    return htmlSafe(style);
  },

  @computed('arrowY', 'align')
  arrowStyle(arrowY, align) {
    if (arrowY) {
      if (align.indexOf('left') >= 0) {
        return htmlSafe('left:' + arrowY + 'px;');
      } else {
        return htmlSafe('right:' + arrowY + 'px;');
      }
    }
  },

  @computed('align')
  alignment(align) {
    return 'align-' + align.dasherize();
  }

});