import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  isPresent
} from '@ember/utils';
import SetupCountryFaqModal from 'b5b/mixins/setup-country-faq-modal';
import {
  generateMeta,
  humanReadableList
} from 'b5b/utils';

export default Route.extend(SetupCountryFaqModal, {

  needToKnowType: 'gettingThere',

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  headTags: function() {
    let region = this.modelFor('region');
    var country = region.get('country');
    const title = `How to get to ${region.get('name')} | ${this.whitelabel.agencySeoName}`;
    const description = country.get('countryInfo.gettingThere');

    let link;
    if (isPresent(country.get('countryInfo.gettingThere'))) {
      link = `country/${country.get('countryFriendlyId')}/getting-there`;
    } else {
      link = `country/${country.get('countryFriendlyId')}`;
    }

    if (!this.isFastBoot) {
      document.title = title;
    }
    return generateMeta({
      description,
      link,
      'og:title': title
    });
  }

});
