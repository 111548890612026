import { helper as buildHelper } from '@ember/component/helper';

export function aVsAn([name]) {
  let firstLetter = name.charAt(0).toLowerCase();
  // Ignoring u for now because Uganda seems weird with an Uganda safari
  if (firstLetter === 'a' || firstLetter === 'e' || firstLetter === 'i' || firstLetter === 'o') {
    return 'an'
  }
  return 'a';
}

export default buildHelper(aVsAn);