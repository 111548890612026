import Component from '@ember/component';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['enquire-popup'],

  didInsertElement() {
    trackEvent('enquire:popup:show');
  },

  actions: {
    close() {
      if (this.onClose) {
        this.onClose();
      }
    }
  }
});
