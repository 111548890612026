import Component from '@ember/component';
import {
  task
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  filterBy,
} from 'ember-decorators/object/computed';

export default Component.extend({
  
  @filterBy('documents', 'enabled', true) enabledDocs: null,
  @filterBy('enabledDocs', 'documentType', 'passport') passports: null,

  @computed('enabledDocs.@each.documentType')
  ticketsVouchers(enabledDocs) {
    return enabledDocs.filter((document)=> {
      return ['ticket', 'voucher'].includes(document.documentType)
    });
  },

  @computed('enabledDocs.@each.documentType')
  miscDocs(enabledDocs) {
    return enabledDocs.filter((document)=> {
      return document.documentType=='miscellaneous' || !document.documentType;
    });
  },

  @computed('documents.@each.enabled')
  disabledDocs(documents) {
    return documents.filter((document)=> {
      return !document.enabled
    });
  },  

  classNames: ['documents-list'],  
  xplorer: service('components/x-plorer'),  




  
});
