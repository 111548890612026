import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  AVAILABLE_CURRENCIES,
  INCLUSION_TYPES
} from 'b5b/services/settings';


export default Controller.extend({

  scroll: service(),
  screen: service(),
  templateManager: service(),
  entityModals: service(),
  ui: service(),
  dealService: service(),

  modelToUpdate: null,
  suggestionField: null,
  showTextSuggestionsModal: false,
  showCheckVehicleTypes: false,
  selectedDate: 'dealDetails',

  userData: 'en',
  sourceData: [{ 'code': 'en', 'name': 'Engaged' }, { 'code': 'ne', 'name': 'Not Engaged' }],

  actions: {
    setUserData() {

    },

    selecCallbackDate (selectedDate) {
      // console.log("selected",selectedDate)
      this.set('selectedDate', selectedDate);
    }
  }

});
