import { helper } from '@ember/component/helper';

export function mapMarkersForTripSummary(params, namedArgs) {

  return namedArgs.tripSummary.get('metaStageMapLocations').map((stage) => {
    // Note the inverse lat/lng vs pathPoints
    return [parseFloat(stage.lng).toFixed(6), parseFloat(stage.lat).toFixed(6)];
  });

}

export default helper(mapMarkersForTripSummary);
