import { helper } from '@ember/component/helper';

export function customTripParams(params, namedArgs) {
    let targetParams = {};
    let routeName = 'create';
    let entity = namedArgs.entity;
    let entityType = namedArgs.entityType;

    switch (entityType) {
      case 'region':
      case 'country':
      case 'lodge':
      case 'experience':
        targetParams['filters'] = [{id: entity.id, type: entityType, name: entity.name}];
        break;
      case 'month':
        targetParams['selectedMonthNames'] = [entity.get('name')];
        break;
      default:
        break;
    }

    return [
      routeName, {
        isQueryParams: true,
        values: targetParams
      }
    ];

}

export default helper(customTripParams);
