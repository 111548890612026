define('ember-chrome-devtools/formatters/array', ['exports', 'ember-chrome-devtools/formatters/object', 'ember-chrome-devtools/formatters/utils/jsonml'], function (exports, _object, _jsonml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ArrayFormatter = undefined;
  exports.item = item;
  exports.items = items;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _marked = regeneratorRuntime.mark(items);

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function item(idx, value) {
    return _jsonml.item(_jsonml.name(idx), _jsonml.separator(), _jsonml.reference(value));
  }

  function items(obj) {
    var _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, _ref, _ref2, idx, value;

    return regeneratorRuntime.wrap(function items$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            _context.prev = 3;
            _iterator = obj.toArray().entries()[Symbol.iterator]();

          case 5:
            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
              _context.next = 15;
              break;
            }

            _ref = _step.value;
            _ref2 = _slicedToArray(_ref, 2);
            idx = _ref2[0];
            value = _ref2[1];
            _context.next = 12;
            return item(idx, value);

          case 12:
            _iteratorNormalCompletion = true;
            _context.next = 5;
            break;

          case 15:
            _context.next = 21;
            break;

          case 17:
            _context.prev = 17;
            _context.t0 = _context['catch'](3);
            _didIteratorError = true;
            _iteratorError = _context.t0;

          case 21:
            _context.prev = 21;
            _context.prev = 22;

            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }

          case 24:
            _context.prev = 24;

            if (!_didIteratorError) {
              _context.next = 27;
              break;
            }

            throw _iteratorError;

          case 27:
            return _context.finish(24);

          case 28:
            return _context.finish(21);

          case 29:
          case 'end':
            return _context.stop();
        }
      }
    }, _marked, this, [[3, 17, 21, 29], [22,, 24, 28]]);
  }

  var ArrayFormatter = exports.ArrayFormatter = function () {
    function ArrayFormatter() {
      _classCallCheck(this, ArrayFormatter);
    }

    _createClass(ArrayFormatter, [{
      key: 'header',
      value: function header(obj) {
        if (obj instanceof Ember.Object && Ember.Array.detect(obj)) {
          var length = Ember.cacheFor(obj, 'length');
          return _jsonml.header(obj + '(length = ' + (length || '?') + ')');
        }
      }
    }, {
      key: 'hasBody',
      value: function hasBody() {
        return true;
      }
    }, {
      key: 'body',
      value: function body(obj) {
        return _jsonml.list.apply(_jsonml, _toConsumableArray(items(obj)).concat(_toConsumableArray((0, _object.properties)(obj))));
      }
    }]);

    return ArrayFormatter;
  }();
});