import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';

export default DS.Model.extend({

  originalRateType: DS.attr(),
  lineItemType: DS.attr('string', {
    defaultValue: 'manual'
  }),
  markupPercentage: DS.attr(),
  marginPercentage: DS.attr(),
  
  useTimbuktuAutoMarkup: DS.attr('boolean'),
  autoMarginPercentage: DS.attr(),    
  reasonForNoAutoMarkup: DS.attr(),  
  marginPercentageAdjustment: DS.attr(),  
  autoPerGuest: DS.attr(),  
  marginAfterTransactionFees: DS.attr(),  

  quantity: DS.attr('number', {
    defaultValue: 1
  }),
  description: DS.attr(),

  currency: DS.attr(),
  chargePer: DS.attr(),
  originalPerAdult: DS.attr(),
  originalPerChild: DS.attr(),
  originalPerYoungAdult: DS.attr(),
  originalPerInfant: DS.attr(),
  originalPerGuest: DS.attr(),

  perGuest: DS.attr(),

  overridePerAdult: DS.attr(),
  overridePerChild: DS.attr(),
  overridePerYoungAdult: DS.attr(),
  overridePerInfant: DS.attr(),
  overridePerGuest: DS.attr(),

  guestLineItems: DS.hasMany('guestLineItem', {
    async: false
  }),

  partner: DS.belongsTo('partner', {
    async: true,
    inverse: null
  }),

  serviceType: DS.belongsTo('serviceType', {
    async: false,
    inverse: null
  }),  

  quote: DS.belongsTo('quote', {
    async: false
  }),

  @computed()
  possibleItemTypes() {
    return ['manual', 'lodge_rate', 'lodge_rate_max_min', 'lodge_from_price_guess', 'transfer', 'cost', 'error'];
  },

  @computed()
  possibleItemErrorTypes() {
    return ['unpriced_lodge', 'lodge_closed'];
  },

  @computed('guestLineItems.@each.guest')
  selectedGuests(guestLineItems) {
    return guestLineItems.mapBy('guest');
  },

  @computed('guestLineItems.@each.guest', 'quote.itinerary.guests.@each.id')
  applicableGuests(guestLineItems, itinGuests) {
    let guests = []
    if (guestLineItems.length > 0) {
      guests = guestLineItems.mapBy('guest');
    } else {
      guests = itinGuests;
    }
    if (guests.length > 0 && guests.objectAt(0).content) {
      guests = guests.mapBy('content')
    }
    return guests;
  },

  @computed('applicableGuests.@each.guest', 'quote.itinerary.guests.@each.id')
  excludedGuests(applicableGuests, itinGuests) {
    return itinGuests.filter((guest) => {
      return !applicableGuests.includes(guest);
    });
  }




});
