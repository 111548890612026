import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import RSVP, {
  Promise as EmberPromise
} from 'rsvp';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend(basicNav, {

  tripService: service('trip'),
  stripeService: service(),
  @alias('tripService.currentTrip') currentTrip: false,

  beforeModel() {
    this._super(...arguments);
    return this.stripeService.getStripeReadyPromise();
  },

  setupController() {
    this._super(...arguments);

    trackEvent('trip:pay:view', {
      trip: this.get('currentTrip.id'),
      type: this.get('currentTrip.tripType'),
      state: this.get('currentTrip.itinerary.state')
    });

  }
});
