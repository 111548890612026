import TWidgetComponent from 'b5b/components/t-widget/component';
import {
  computed
} from 'ember-decorators/object';

import {
  inject as service
} from '@ember/service';

export default TWidgetComponent.extend({
  classNames: ['waybird'],

  router: service(),


});
