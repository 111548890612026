import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  inject as service
} from '@ember/service';

export default Controller.extend(UseCurrentTrip, {
  // These fields were traditionally automatically included so for old links where the props are explicity set the default is to include them
  includeCover: true,
  includeTripOverview: true,
  includeCountries: true,
  includeRegions: true,
  includeLodges: true,
  includeInclusions: true,

  // These fields have always been optional
  includeQuoteIntro: false,
  includePreDeparture: false,
  includeLodgeContacts: false,
  includePartnersInFront: false,
  includePartnersInBack: false,
  includeDestinationDates: false,  
  includeStageInfo: false,

  queryParams: [
    'includeCover',
    'includeTripOverview',
    'includeStageInfo',
    'includeCountries',
    'includeRegions',
    'includeLodges',
    'includeInclusions',
    'includePreDeparture',
    'includeQuoteIntro',
    'includeLodgeContacts',
    'includePartnersInFront',
    'includePartnersInBack',
    'includeDestinationDates',
  ],

  ui: service(),
  tripService: service('trip'),

  actions: {
    emailBrochureToMe() {
      let url = `${window.location.href}`
      return this.tripService.emailBrochureToCurrentUser({url});      
    },
  }
});
