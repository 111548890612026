import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({

  tagName: 'div',
  classNames: ['currency-converter'],

  settings: service(),

  isDark: false,
  otherClasses: 'uppercase currency-converter in-modal', // defaults

  @computed('isDark', 'otherClasses')
  triggerClasses(isDark, otherClasses) {
    return isDark ? 'dark ' + otherClasses : otherClasses;
  },

  @computed('isDark', 'otherClasses')
  dropdownClasses(isDark, otherClasses) {
    return isDark ? 'dark ' + otherClasses : otherClasses;
  },

  @computed()
  currencies() {
    return AVAILABLE_CURRENCIES.map((cur, index) => {
      return {
        name: cur,
        symbol: CURRENCY_SYMBOLS[index]
      };
    });
  },

  @computed('settings.currentCurrency', 'currencies')
  currentCurrency(currentCurrency, currencies) {
    for (var i = currencies.length - 1; i >= 0; i--) {
      let cur = currencies[i];
      if (currentCurrency === cur.name) {
        return cur;
      }
    }
  },

  actions: {
    changeCurrentCurrency(currency) {
      trackEvent('currency-converter:change-currency', {
        currency: currency.name
      });
      this.get('settings').set('currentCurrency', currency.name);
      if (this.afterChangeCurrency) {
        this.afterChangeCurrency();
      }
    }
  }

});