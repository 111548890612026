import StepComponent from 'b5b/components/booking-steps/step/component';
import {
  computed
} from 'ember-decorators/object';

export default StepComponent.extend({
  classNameBindings: ['current'],

  @computed('completed')
  current(completed) {
    return !completed;
  },

  @computed('trip.itinerary.validCharges.firstObject')
  depositCharge(depositCharge) {
    return depositCharge;
  }


});
