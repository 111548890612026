import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';
import {
  formatCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  htmlSafe
} from '@ember/string';


export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let transfer = namedArgs.transfer;
    let val = transfer.chargePer == 'pax' ? transfer.cost : transfer.cost / 2;

    let price = formatCurrencyHash({
      val: val,
      fromCurrency: transfer.currency,
      toCurrency: namedArgs.toCurrency || this.get('settings.currentCurrency'),
      roundToClosest: 0,
      isText: false,
      truncate: false,
      markPricesUp: true
    });

    let transferText = `${price} per person`

    return htmlSafe(transferText);
  }

});
