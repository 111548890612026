import {
  helper as buildHelper
} from '@ember/component/helper';
import {
  toTitleCase
} from 'b5b/utils';

export function uppercaseEachFirstLetter([params]/*, hash*/) {
  return toTitleCase(params);
}

export default buildHelper(uppercaseEachFirstLetter);
