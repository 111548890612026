define("ember-deferred-content/templates/components/deferred-content/fulfilled-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b7iEzi4S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isFulfilled\"]]],null,{\"statements\":[[14,1,[[23,[\"result\"]]]]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-deferred-content/templates/components/deferred-content/fulfilled-content.hbs"
    }
  });
  _exports.default = _default;
});