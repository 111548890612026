import Mixin from '@ember/object/mixin';
import {
  equal,
  or,
  empty,
  not
} from 'ember-decorators/object/computed';

// This is used on itineraries and also trip-summaries

export default Mixin.create({

  @equal('state', 'cancelled') stateCancelled: null,
  @empty('state') stateEmpty: null,
  @equal('state', 'enquiry') stateEnquiry: null,
  @or('stateEmpty', 'stateEnquiry') stateBeforeQuote: null,
  @equal('state', 'quote') stateQuote: null,
  @or('stateQuote', 'stateDepositPaid', 'stateBalanceDue', 'stateBalancePaid') stateQuoteAndAfter: null,
  @equal('state', 'deposit_paid') stateDepositPaid: null,
  @or('stateDepositPaid', 'stateBalanceDue', 'stateBalancePaid') stateDepositPaidAndAfter: null,
  @or('stateEmpty', 'stateEnquiry', 'stateQuote', 'stateDepositPaid') stateBeforeBalanceDue: null,
  @equal('state', 'balance_due') stateBalanceDue: null,
  @or('stateBalanceDue', 'stateBalancePaid') stateBalanceDueAndAfter: null,
  @equal('state', 'balance_paid') stateBalancePaid: null,
  @not('stateBalancePaid') stateBeforeBalancePaid: null,
  @or('stateQuote', 'stateDepositPaid', 'stateBalanceDue', 'stateBalancePaid') afterEnquiry: null,
  @or('stateEnquiry', 'afterEnquiry') stateEnquiryAndAfter: null,


  @or('stateCancelled', 'stateDepositPaidAndAfter') stateBookedOrCancelled: null,



});
