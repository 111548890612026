import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({

  classNames: ['x-video'],
  tagName: 'iframe',
  attributeBindings: ['src', 'frameBorder', 'width', 'height', 'webkitallowfullscreen', 'mozallowfullscreen', 'allowfullscreen'],

  router: service(),

  src: null,

  webkitallowfullscreen: '',
  mozallowfullscreen: '',
  allowfullscreen: '',

  // defaults
  maxWidth: 640,
  padding: 0, // so /2 each side
  frameBorder: "0",

  ratio: 1.77777,

  didInsertElement() {
    trackEvent('x-video:inserted:from:' + this.get('router.currentRouteName'));
  },

  @computed('screen.height', 'padding', 'maxWidth')
  _widthLimit(screenHeight, padding, maxWidth) {
    let heightLimit = Math.round((screenHeight - padding) * this.ratio);
    return Math.min(heightLimit, maxWidth);
  },

  @computed('_widthLimit', 'screen.width', 'padding')
  width(_widthLimit, screenWidth, padding) {
    return Math.min(_widthLimit, screenWidth - padding);
  },

  @computed('width')
  height(width) {
    return Math.round(width / this.ratio);
  }

});