import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['modal-footer'],

  ui: service(),

  didInsertElement() {
    this._super(...arguments);
    this.set('ui.modalFooterExists', true);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('ui.modalFooterExists', false);
  }

});
