import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  task
} from 'ember-concurrency';

export default Controller.extend({

  ui: service()

});
