import TWidgetComponent from 'b5b/components/t-widget/component';
import {
  computed
} from 'ember-decorators/object';

import {
  inject as service
} from '@ember/service';

export default TWidgetComponent.extend({
  classNames: ['entity'],

  router: service(),

  @computed('entityType', 'entity')
  entityName(entityType, entity) {
    switch (entityType) {
      case 'region':
      case 'country':
        return entity.get('shortName') || entity.get('name');
      default:
        return entity.get('name');
    }
  },

  @computed('entity.constructor.modelName')
  entityType(modelName) {
    return modelName;
  }

});
