import { helper as buildHelper } from '@ember/component/helper';

export function stageDay(params, namedArgs) {
  let currentIndex = namedArgs.index,
    stages = namedArgs.stages,
    day = 1;

  stages.forEach((stage, index) => {
    if (index < currentIndex) {
      day += stage.get('numNights');
    }
  });

  return 'Day ' + day;
}

export default buildHelper(stageDay);
