import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({
  classNameBindings: [':highlight-card', 'isActive:active'],

  router: service(),

  slide: null,
  slideIndex: null,
  activeIndex: null,

  @computed('slideIndex', 'activeIndex')
  isActive(slideIndex, activeIndex) {
    return slideIndex < activeIndex * 3 && slideIndex >= (activeIndex - 1) * 3
  },

  @computed('slide')
  slideType(slide) {
    return slide.get('slideType');
  },

  @computed('slide', 'slideType')
  slideEntity(slide, slideType) {
    return slide.get(slideType);
  },

  @computed('slideType', 'slideEntity')
  slideImage(slideType, slideEntity) {
    return slideEntity.get('kodakOriginalUrl');
  },

  @computed('slideType', 'slideEntity')
  slideImageCoverStyle(slideType, slideEntity) {
    return slideEntity.get('coverStyle');
  },

  @computed('slideType', 'slideEntity')
  slideTitle(slideType, slideEntity) {
    return slideEntity.get('name');
  },

  @computed('slideType', 'slideEntity')
  slideTeaser(slideType, slideEntity) {
    return slideEntity.get('teaserText');
  },

  @computed('slideType')
  highlightTitle(slideType) {
    switch (slideType) {
      case 'experience':
        return 'What to do';
      case 'lodge':
        return 'Where to stay';
      case 'region':
      case 'country':
        return 'Where to go';
      default:
        break;
    }
  },

  @computed('slideIndex')
  hasMap(slideIndex) {
    return (slideIndex + 3) % 3 === 0;
  },

  @computed('slideEntity', 'slideType')
  entityPoint(slideEntity, slideType) {

    if (!slideEntity.get('latitude') || !slideEntity.get('longitude') || !(slideType === 'country' || slideType === 'region')) {
      return;
    }

    return [
      [parseFloat(slideEntity.get('longitude')).toFixed(6), parseFloat(slideEntity.get('latitude')).toFixed(6)]
    ];
  },

  click() {
    this.get('router').transitionTo(this.get('slideType'), this.get('slideEntity'));
  }

});