import Mixin from '@ember/object/mixin';
import {
  computed
} from 'ember-decorators/object';
import EmberObject from '@ember/object';


// This is used on itineraries and also trip-summaries

export default Mixin.create({

  @computed()
  tripCancelledStep() {
    return EmberObject.create({
      stepName: 'trip-cancelled',
      componentName: 'booking-steps/step/trip-cancelled',
      completed: true,
      current: false
    });
  },

  @computed('stateDepositPaidAndAfter')
  depositPaidStep(stateDepositPaidAndAfter) {
    return EmberObject.create({
      stepName: 'deposit-paid',
      componentName: 'booking-steps/step/deposit-paid',
      completed: stateDepositPaidAndAfter,
      current: false
    });
  },

  @computed('flightsSentByGuest')
  flightsConfirmationStep(flightsSentByGuest) {
    return EmberObject.create({
      stepName: 'flights-confirmation',
      componentName: 'booking-steps/step/flights-confirmation',
      completed: flightsSentByGuest,
      current: false
    });
  },

  @computed('allGuestsPersonalDetailsComplete', 'allGuestsInsuranceDetailsComplete')
  guestDetailsStep(allGuestsPersonalDetailsComplete, allGuestsInsuranceDetailsComplete) {
    return EmberObject.create({
      stepName: 'guest-details',
      componentName: 'booking-steps/step/guest-details',
      completed: allGuestsPersonalDetailsComplete && allGuestsInsuranceDetailsComplete,
      current: false
    });
  },

  @computed('itineraryConfirmed')
  tripConfirmationStep(itineraryConfirmed) {
    return EmberObject.create({
      stepName: 'trip-confirmation',
      componentName: 'booking-steps/step/trip-confirmation',
      completed: itineraryConfirmed,
      current: false
    });
  },

  @computed('stateBalancePaid', 'balanceSentByGuest')
  balancePaymentStep(stateBalancePaid, balanceSentByGuest) {
    return EmberObject.create({
      stepName: 'balance-payment',
      componentName: 'booking-steps/step/balance-payment',
      completed: stateBalancePaid || balanceSentByGuest,
      current: false
    });
  },

  @computed('depositPaidStep', 'flightsConfirmationStep', 'guestDetailsStep', 'tripConfirmationStep', 'balancePaymentStep', 'stateCancelled')
  flightsFirstSteps(depositPaidStep, flightsConfirmationStep, guestDetailsStep, tripConfirmationStep, balancePaymentStep, stateCancelled) {
    if (stateCancelled) {
      return [
        depositPaidStep,
        this.tripCancelledStep
      ]
    }
    return [
      depositPaidStep,
      flightsConfirmationStep,
      guestDetailsStep,
      tripConfirmationStep,
      balancePaymentStep
    ]
  },

  @computed('depositPaidStep', 'flightsConfirmationStep', 'guestDetailsStep', 'tripConfirmationStep', 'balancePaymentStep', 'stateCancelled')
  flightsLastSteps(depositPaidStep, flightsConfirmationStep, guestDetailsStep, tripConfirmationStep, balancePaymentStep, stateCancelled) {
    if (stateCancelled) {
      return [
        depositPaidStep,
        this.tripCancelledStep
      ]
    }
    return [
      depositPaidStep,
      guestDetailsStep,
      tripConfirmationStep,
      flightsConfirmationStep,
      balancePaymentStep
    ]
  },

  @computed('depositPaidStep', 'guestDetailsStep', 'tripConfirmationStep', 'balancePaymentStep', 'stateCancelled')
  noFlightsSteps(depositPaidStep, guestDetailsStep, tripConfirmationStep, balancePaymentStep, stateCancelled) {
    if (stateCancelled) {
      return [
        depositPaidStep,
        this.tripCancelledStep
      ]
    }
    return [
      depositPaidStep,
      guestDetailsStep,
      tripConfirmationStep,
      balancePaymentStep
    ]
  },

  @computed('processPath', 'flightsFirstSteps.@each', 'flightsLastSteps.@each', 'noFlightsSteps.@each')
  processPathSteps(processPath, flightsFirstSteps, flightsLastSteps, noFlightsSteps) {
    let steps;

    switch (processPath) {
      case 'flights_first':
        steps = flightsFirstSteps;
        break;

      case 'flights_last':
        steps = flightsLastSteps;
        break;

      case 'no_flights':
        steps = noFlightsSteps;
        break;

      default:
        steps = flightsLastSteps;
        break;
    }

    // NOTE: this is where we set the current step state
    steps.forEach((step, index, arr) => {
      // sets the next step as completed if the current one is compeled
      if (arr[index + 1]) {
        if (!arr[index + 1].get('completed')) {
          arr[index + 1].set('current', step.get('completed'));
        }
      }
    });

    return steps;

  },

  @computed('processPathSteps')
  allProcessStepsCompleted(processPathSteps) {
    return processPathSteps.filterBy('completed').length === processPathSteps.length;
  }


});


