import Route from '@ember/routing/route';
import EntitiesRoutesRouteMixin from 'b5b/mixins/entities/routes/route';

export default Route.extend(EntitiesRoutesRouteMixin, {
  selectedModelType: 'selectedExperienceNames',
  entityRouteName: 'experience',

  additionalControllerSetup(controller, experience) {
    if (experience.isRegional && experience.region) {
      controller.set('selectedRegionNames', [experience.region.name])
    }
  }
});
