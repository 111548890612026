import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import config from 'b5b/config/environment';

export default Component.extend({
  classNames: ['auth-form', 'reset'],

  ui: service(),

  didReset: false,
  sending: false,

  didInsertElement() {
    this._super(...arguments);
    this.ui.setProperties({
      showRegisterModal: false,
      showLoginModal: false,
      showChangePasswordModal: false
    });
    this.set('email', this.get('ui.userResetEmail'));
  },

  willDestroyElement() {
    this._super(...arguments);
    this.setProperties({
      didReset: false,
      sending: false
    });
  },

  actions: {

    doReset() {
      this.setProperties({
        sending: true,
        errors: null,
        showErrors: false
      });
      let url = this.whitelabel.getFullHostUrl('/server/users/password');
      $.ajax({
        url,
        type: "POST",
        data: `user%5Bemail%5D=${encodeURIComponent(this.email)}`,
        context: this
      }).then(function() {
        // handle your server response here
        this.setProperties({
          didReset: true,
          sending: false
        });
      }).catch(function(res) {
        this.setProperties({
          sending: false,
          errors: $.parseJSON(res.responseText).errors,
          showErrors: true
        });
      });

      return false;
    },

    onLogin() {
      if (this.onLogin) {
        this.onLogin();
      }
    }
  }
});
