import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['feefo-section'],

  feefo: service(),
  router: service(),

  @alias('feefo.loadingFeefo') loadingFeefo: null,
  @alias('feefo.feefoServiceReviews') feefoServiceReviews: null,
  @alias('feefo.viewedFeefoReview') viewedFeefoReview: null,



});
