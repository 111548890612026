import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import PrettyQuery from "b5b/mixins/pretty-query";

export default Route.extend(PrettyQuery, {
  xplorer: service("components/x-plorer"),

  queryParams: {
    page: {
      replace: true,
    },
    templateKey: {
      replace: true,
    },
    filters: {
      replace: true,
    },
  },
  
  model(params) {
    return params;
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('filters', model.filters);
    this.get("xplorer").setupController(controller);
  },

  resetController(controller) {
    this._super(...arguments);
    this.get("xplorer").teardownController();
  },
});
