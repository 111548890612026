import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import TripSaver from 'b5b/mixins/trip-saver';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

export default Controller.extend(TripSaver, UseCurrentTrip, {

  settings: service(),
  store: service(),
  session: service(),
  ui: service()
});
