import {
  helper
} from '@ember/component/helper';


export function priceBasedOnNumPeopleSharing(params, namedArgs) {
  return namedArgs.trip.itinerary.tripRatesSpecialOffer.ratesBasedOnNumPeopleSharing || 2;
}

export default helper(priceBasedOnNumPeopleSharing);
