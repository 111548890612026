import Component from '@ember/component';

import {
  computed
} from 'ember-decorators/object';

import {
  inject as service
} from '@ember/service';

export default Component.extend({
  classNames: ['design-a-trip'],

  router: service(),

  entity: null,

  @computed('entityType', 'entity')
  entityName(entityType, entity) {
    switch (entityType) {
      case 'region':
      case 'country':
        return entity.get('shortName') || entity.get('name');
      default:
        return entity.get('name');
    }
  },

  @computed('entity.constructor.modelName')
  entityType(modelName) {
    return modelName;
  },


});
