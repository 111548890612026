import {
  isEmpty
} from '@ember/utils';
import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

import {
  alias,
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import { trackEvent } from '../../../utils';
import TripSaver from 'b5b/mixins/trip-saver';
import TripEditorController from 'b5b/mixins/trip-editor/controller';

export default Controller.extend(UseCurrentTrip, TripSaver, TripEditorController, {

  entityModals: service(),
  screen: service(),
  ui: service(),
  tripService: service('trip'),
  session: service(),
  @alias('trip.itinerary.accomStageLodgeMapping.length') stagesWithLodgesCount: null,
  @alias('tripService.savingTrip') savingTrip: false,


  newTripModal: false,

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  pathPoints(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lat).toFixed(6), parseFloat(stage.get('mapLocation').lng).toFixed(6)];
    });
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapDefaultLatLng(stages) {
    if (isEmpty(stages)) {
      return {
        lat: -4.721974,
        lng: 22.884122
      }
    }
  },

  @computed('trip.itinerary.nonTransferStages.@each.mapLocation')
  mapMarkers(stages) {
    return stages.map((stage) => {
      return [parseFloat(stage.get('mapLocation').lng).toFixed(6), parseFloat(stage.get('mapLocation').lat).toFixed(6)];
    });
  },


  @computed('trip.uniqueCountries', 'uniqueCountryIds')
  filterCountries(tripUniqueCountries, uniqueCountryIds) {
    let countryIds = [];
    tripUniqueCountries.forEach((country)=> {
      countryIds.pushObject(country.get('id'))
    })
    if (uniqueCountryIds) {
      uniqueCountryIds.forEach((countryId)=> {
        countryIds.pushObject(countryId)
      })
    }
    let countries = []
    this.store.peekAll('country').filter((country)=> {
      if (countryIds.includes(country.id)) {
        countries.pushObject(country)
      }
    })
    return countries;
  },

  actions: {

    openAddOnModalWithPermissions(options) {
      this.get('entityModals').addModalPermission('perm-add-region');
      this.get('entityModals').openAddOnModal(options)

      if (options.type == 'region') {
        this.set('tripService.showChoosePlaceModal', true);
      } else {
        this.tripService.createStage(options)
      }
    },

    addNewDestination() {
      trackEvent('trip:editor:add-new-destination', {numStages: this.get('trip.itinerary.stages.length')})
      this.set('ui.showNewStageModal', {filterOptions: {filterCountries: this.get('filterCountries')}})
    }
  }
});
