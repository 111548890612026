import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent,
} from 'b5b/utils';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  ui: service(),
  store: service(),
  whitelabel: service(),
  tripService: service('trip'),

  beforeModel(transition) {
    this._super(...arguments);

    if (!this.whitelabel.isOnboardingAgency && this.whitelabel.isForAgency && !this.get('session.currentUser.isManager')) {
      transition.abort();
    }

    trackEvent('trip:new');

    var trip = this.store.createRecord('trip');
    this.tripService.set('currentTrip', trip);
    trip.setProperties({
      defaultItinerary: this.store.createRecord('itinerary'),
      friendlyId: 'my-new-trip',
    });

    trip.set('layout', this.whitelabel.agency.defaultLayout)
    if (trip.isLayoutDayByDay) {
      this.tripService.addDayByDaySupport(trip)
    }

    transition.queryParams['viaCreate'] = true;

    if (this.whitelabel.showManagerTools) {
      this.controllerFor('trip.new').setProperties({
        viaCreateParams: transition.queryParams,
        draftTrip: trip,
        showNewTripModal: {newTripLayout: this.get('whitelabel.agency.defaultLayout')}
      });
    } else {

      let filters = transition.queryParams.filters && JSON.parse(transition.queryParams.filters);
      let filterCountries = filters && filters.filterBy("type", 'country')
      if (filterCountries && filterCountries.length > 0) {
        trip.set('name', `My ${filterCountries[0].name} trip`)
      }

      if (!trip.name) {
        trip.set('name', 'My new trip')
      }

      this.transitionTo('trip.index.editor', trip, {
        queryParams: transition.queryParams
      });
    }
  }
});
