import DS from 'ember-data';

export default DS.Model.extend({

  feefo: DS.belongsTo('feefo'),
  tripSummary: DS.belongsTo('trip-summary', {
    inverse: null
  }),

  uid: DS.attr(),
  url: DS.attr(),

  rating: DS.attr(),
  title: DS.attr(),
  review: DS.attr(),

  guestName: DS.attr(),

  reviewCreatedAt: DS.attr('b5bdate'),
  reviewUpdatedAt: DS.attr('b5bdate')

});
