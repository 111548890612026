import Service, {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  not,
  alias,
  or
} from 'ember-decorators/object/computed';
import {
  FLYWIRE_CURRENCIES,
  WAYBIRD_CURRENCIES,
} from 'b5b/utils';

export default Service.extend({

  agency: null, //Agency linked to this domain. Populate in appliation route as part of critical data
  session: service(),
  tripService: service('trip'),
  isForAgency: false,
  @not('isForAgency') isForTimbuktu: false,

  getFullHostUrl(subDirPath) {
    return window.location.protocol + '//' + window.location.host + subDirPath;
  },
  // privileges: {
  //   guestCanCustomiseTrip: false,
  //   agentCanCustomiseTrip: false,
  //   currentUserCanCustomiseTrip: false,
  // },

  // @computed('isForAgency', 'privileges')
  // can(isForAgency, privileges) {
  //   if (!isForAgency) {
  //     return true;
  //   }
  //   return false;
  // },

  // Need a way to load the agency with the initial server response

  @computed()
  isOnboardingAgency() {
    return window.location.host.indexOf('onboarding.') >= 0
  },

  @computed('isForTimbuktu', 'isOnboardingAgency', 'session.currentUser.isManager')
  showRoutes(isForTimbuktu, isOnboardingAgency, isManager) {
    return isForTimbuktu || isOnboardingAgency || isManager;
  },

  @computed('tripService.currentTrip.primaryManager.email', 'settings.consultant.email', 'agency.generalEmail')
  contactEmail(primaryManagerEmail, consultantEmail, generalEmail) {
    if (primaryManagerEmail) {
      return primaryManagerEmail
    } else if (consultantEmail) {
      return consultantEmail;
    } else if (generalEmail) {
      return generalEmail;
    }

  },

  @computed()
  settlementCurrencies() {
    if (this.isForAgency && this.get('agency.meta.currencies') && this.get('agency.meta.currencies.length') > 0) {
      return this.get('agency.meta.currencies');
    } else if (this.isForAgency) {
      return WAYBIRD_CURRENCIES;
    } else {
      return FLYWIRE_CURRENCIES;
    }
  },

  @computed('isForTimbuktu', 'agency.termsAndConditionsUrl')
  termsAndConditionsUrl(isForTimbuktu, agencyLegalUrl) {
    if (isForTimbuktu) {
      return 'https://www.timbuktutravel.com/legal/terms-and-conditions';
    } else {
      return agencyLegalUrl;
    }
  },

  @computed('isForTimbuktu', 'agency.name')
  agencySeoName(isForTimbuktu, agencyName) {
    if (isForTimbuktu) {
      return 'Timbuktu Travel';
    } else {
      return agencyName ;
    }
  },

  @computed('isForTimbuktu', 'session.currentUser.isManager')
  timbuktuAndAgencyManagersOnly(isForTimbuktu, isManager) {
    if (isForTimbuktu) {
      return true;
    } else if (isManager) {
      return true;
    }
    return false;
  },

  @computed('isOnboardingAgency', 'session.currentUser.isManager')
  showManagerTools(isOnboardingAgency, isManager) {
    return isOnboardingAgency || isManager;
  },


  @computed('tripService.currentTrip.contractingEntity', 'agency.contractingEntities.firstObject')
  contractingEntity(tripContractingEntity, agencyContractingEntity) {
    return tripContractingEntity || agencyContractingEntity;
  },


  @computed('contractingEntity')
  canSetBalanceDueDate(contractingEntity) {
    let ID_WAYBIRD_CONTRACTING_ENTITY = 3;
    return contractingEntity.id != ID_WAYBIRD_CONTRACTING_ENTITY;
  }




});


