import DS from 'ember-data';
import { ActiveModelSerializer } from 'active-model-adapter';

export default ActiveModelSerializer.extend(DS.EmbeddedRecordsMixin, {
  isNewSerializerAPI: true,
  attrs: {

    blockItemExperiences: {
      serialize: 'records'
    },
  },

  serialize( /* snapshot, options */ ) {
    var json = this._super(...arguments);

    //We do this so that the embedded enquiry matches Rails json standards
    json.block_item_experiences_attributes = json.block_item_experiences;

    json.block_item_experiences_attributes.forEach((blockItemExperience)=> {

      if (blockItemExperience.original_block_item_id != blockItemExperience.block_item_id) {
        //If you move block item experience from one block Item to another then this will cause
        // new blockItemExperience to be created on server and old one to be deleted
        blockItemExperience.block_item_id=null
        blockItemExperience.id=null
      }


    })
    delete json.block_item_experiences;

    return json;
  }
});
