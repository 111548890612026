import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({
  classNames: ['guest-inviter'],

  tripService: service('trip'),
  ui: service(),
  @alias('tripService.currentTrip') trip: null,

  email: null,
  inviteSent: false,

  willDestroyElement() {
    this._super(...arguments);
    this.setProperties({
      email: null,
      inviteSent: false
    });
  },

  actions: {
    inviteUser() {
      this.set('inviteSent', false);
      trackEvent('trip:invite:user');
      return this.get('trip').inviteUser({
        email: this.get('email'),
        forceInviteEmail: this.get('forceInviteEmail')
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.set('inviteSent', true);
      }).catch(() => {
        this.get('flashMessages').danger('Sorry! That person has already been invited.', {
          timeout: 4000,
          sticky: false
        });
      });
    },
    openInviteModal() {
      if (!this.tripService.savedTripEditableByUser) {
        this.ui.showGeneralMessage('Oops!', 'You need to save this trip before you can invite others.');
        return;
      }
      this.set('showInviteModal', true)
    }
  }

});
