import TripStagesComponent from 'b5b/components/trip-stages/component';
import {
  inject as service
} from '@ember/service';

export default TripStagesComponent.extend({

  ui: service(),
  session: service()
});
