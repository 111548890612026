import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {
  session: service(),

  model() {
    return this.get('session.currentUserPromise');
  },

  actions: {
    logout() {
      return this.session.invalidate();
    }
  }
});
