import {
  alias,
  uniqBy,
  mapBy
} from '@ember/object/computed';
import {
  not
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';
import {
  isEmpty,
  isPresent
} from '@ember/utils';
import DS from 'ember-data';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
// import {
//   convertCurrencyHash
// } from 'b5b/helpers/format-currency';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  humanReadableList,
  prevTransferStage,
  nextTransferStage,
  TRIP_IDEAS_BUDGET_MAPPING,
  uniqueBy
} from 'b5b/utils';
import EmberObject from '@ember/object';
import Itinerary from 'b5b/models/itinerary';
import Stage from 'b5b/models/stage';


import {
  memberAction
} from 'ember-api-actions';

const {
  PromiseArray
} = DS;

import TripCarouselImages from 'b5b/mixins/trip-carousel-images';
import { next } from '@ember/runloop';

export default DS.Model.extend(HasKodakImage, TripCarouselImages, {

  session: service(),
  templateManager: service(),

  name: DS.attr(),
  seoName: DS.attr(),
  published: DS.attr(),
  fromSection: DS.attr(), //This is saved to the server with trip. Stops some automations for example if you are saving wizard recommendations
  metaContinentNames: DS.attr(),
  hubspotDealId: DS.attr(),
  deal: DS.belongsTo('deal', {
    async: false
  }),
  contractingEntityCurrentTermsAndConditionsUrl: DS.attr(),

  // Types
  master: DS.attr('boolean'),
  dream: DS.attr('boolean'),
  isRoute: DS.attr('boolean'),
  isPackage: DS.attr('boolean'),
  isSpecial: DS.attr('boolean'),
  isTripIdeaForRoute: DS.attr('boolean'),
  isGiftList: DS.attr('boolean'),

  onlyTripUsersCanView: DS.attr('boolean'),
  scheduleHeading: DS.attr(),

  specialOfferTag: DS.attr(),
  isPopular: DS.attr(),
  allocatedEmailIsRegistered: DS.attr('boolean', {
    defaultValue: false
  }),
  version: DS.attr(),

  teaserText: DS.attr(),
  tripIdeaType: DS.attr(),
  routeComboName: DS.attr(),
  minNights: DS.attr(),
  maxNights: DS.attr(),
  hasTemplateTrip: DS.attr(),
  displayPrices: DS.attr(),
  layout: DS.attr(),

  bestTimeToGo: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate-time'),

  friendlyId: DS.attr(),
  shareVersionId: DS.attr(),
  showShareVersion: DS.attr(),
  tripIdeasSummary: DS.attr(),
  reportTripView: DS.attr(),

  giftIntro: DS.attr(),
  giftReceiver: DS.attr(),
  hideGiftAmounts: DS.attr(),
  giftReceiverImageOriginalUrl: DS.attr(),
  hideGiftListContributions: DS.attr(),

  managingAgency: DS.belongsTo('agency', {
    async: false,
    inverse: null
  }),
  paymentProcessor: alias('managingAgency.paymentPlatform'),

  templateTripIdea: DS.belongsTo('trip', {
    inverse: null
  }),

  tripIdeas: DS.hasMany('trip', {
    async: true,
    inverse: 'ideaForTrip'
  }),

  ideaForTrip: DS.belongsTo('trip', {
    async: true,
    inverse: 'tripIdeas'
  }),

  // This is never set by the server. Its only used to set a new primary manager when changing the waybird account
  proposedPrimaryManager: DS.belongsTo('user', {
    inverse: null
  }),
  proposedNotifyNewPrimaryManager: DS.attr(),
  consultant: DS.belongsTo('user', {
    inverse: null
  }),
  owner: DS.belongsTo('user', {
    inverse: null
  }),
  // This is only serialized for managers. As we don't want the emails of users to be exposed to the Internet
  users: DS.hasMany('user', {
    async: false,
    inverse: null
  }),
  userTrips: DS.hasMany('user-trip', {
    async: false,
    inverse: 'trip'
  }),

  invitedUsers: DS.hasMany('invitedUser', {
    async: false
  }),

  tripInfo: DS.belongsTo('tripInfo'),
  experiences: DS.hasMany('experience', {
    inverse: null
  }),
  country: DS.belongsTo('country', {
    async: false
  }),
  contractingEntity: DS.belongsTo('contracting-entity', {
    async: false,
    inverse: null
  }),
  defaultItinerary: DS.belongsTo('itinerary', {
    async: false,
    inverse: 'trip'
  }),

  templateTrip: DS.belongsTo('trip', {
    async: true,
    inverse: null
  }),

  primaryManager: alias('consultant'),
  hasTripIdeas: alias('isRoute'),
  sortedTripIdeas: alias('tripIdeas'),
  sortedTripIdeasSummary: alias('tripIdeasSummary'),
  itinerary: alias('defaultItinerary'),
  numNights: alias('itinerary.numNights'),
  useNewPricingModel: alias('itinerary.useNewPricingModel'),

  uniqueCountries: alias('countries'),
  uniqueRegions: uniqBy('regions', 'id'),
  uniqueRegionIds: mapBy('uniqueRegions', 'id'),
  uniqueRegionNames: mapBy('uniqueRegions', 'name'),

  sendUsefulInfoEmail: memberAction({
    path: 'send-useful-info'
  }),

  setTripConsultant: memberAction({
    path: 'set-trip-consultant'
  }),

  addSelfToTrip: memberAction({
    path: 'add-self-to-trip'
  }),

  duplicateTrip: memberAction({
    path: 'duplicate-trip'
  }),

  convertTripToRoute: memberAction({
    path: 'convert-trip-to-route'
  }),

  convertToQuoteUsingSpecialOffer: memberAction({
    path: 'convert_to_quote_using_special_offer'
  }),

  inviteUser: memberAction({
    path: 'invite-user'
  }),

  toggleGiftListOwner: memberAction({
    path: 'toggle_gift_list_owner'
  }),

  getLatestVersion: memberAction({
    path: 'get_latest_version'
  }),

  createPaygeniusRedirect: memberAction({
    path: 'create-paygenius-redirect'
  }),

  deleteUserInvite: memberAction({
    path: 'delete-user-invite'
  }),

  provisionallyCompleteCharge: memberAction({
    path: 'provisionally_complete_charge'
  }),

  hasUnsavedChanges: false,

  @computed()
  tripSummary(){
    let peekRecord = this.store.peekRecord('tripSummary', this.id);
    return peekRecord ? peekRecord : this.store.findRecord('tripSummary', this.friendlyId);
  },

  @computed('layout')
  isLayoutDayByDay(layout){
    return layout=='day-by-day';
  },

  @computed('layout')
  isLayoutMagazine(layout){
    return layout=='magazine';
  },

  @computed('tripIdeaType')
  isTripIdea(tripIdeaType){
    return tripIdeaType ? true : false;
  },

  @computed('users.[]')
  regularUsers(users) {
    return users.rejectBy('isManager', true);
  },

  @computed('sortedTripIdeasSummary.@each.estimate')
  fromPrice(sortedTripIdeasSummary) {
    let summary = sortedTripIdeasSummary.objectAt(0);
    if (summary) {
      return summary.estimate.from_prices;
    }
  },

  @computed('tripIdeaType')
  tripIdeaTitle(tripIdeaType){
    if (!tripIdeaType) {
      return;
    }
    return TRIP_IDEAS_BUDGET_MAPPING[tripIdeaType];
  },

  @computed('master', 'dream', 'isRoute', 'isPackage', 'isSpecial', 'isTripIdeaForRoute')
  userTrip(master, dream, isRoute, isPackage, isSpecial, isTripIdeaForRoute) {
    return !master && !dream && !isRoute && !isPackage && !isSpecial && !isTripIdeaForRoute;
  },
  @not('userTrip') contentManagedTrip: true,

  @computed('master', 'dream', 'isRoute', 'isPackage')
  tripType(master, dream, isRoute, isPackage) {
    if (dream) {
      return 'dream'
    } else if (master) {
      return 'master'
    } else if (isRoute) {
      return 'route'
    } else if (isPackage) {
      return 'package'
    }
    return 'user'
  },

  @computed('userEmail')
  validUserEmail(userEmail) {
    return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail));
  },

  @computed('itinerary.stages.@each.numNights')
  tripLength(stages) {
    return stages && stages.reduce(function(sum, stage) {
      return sum + (stage.get('numNights') || 0);
    }, 0);
  },

  @computed('regions.@each', 'overrideKodak')
  heroEntity(regions, overrideKodak) {
    // NB: THIS IS DUPLICATED in the trip summary
    // Please keep this in sync with the logic on server that allocates
    if (overrideKodak) {
      return EmberObject.create({
        kodakOriginalUrl: this.get('overrideKodak.originalUrl'),
        description: this.get('overrideKodak.description'),
        coverStyle: this.get('overrideKodak.coverStyle')
      });
    }
    if (this.get('hasKodakImage')) {
      return this;
    }

    if (regions.length > 0) {
      let secondDestinationRegion = regions.objectAt(1);
      let firstDestinationRegion = regions.objectAt(0);

      if (secondDestinationRegion && secondDestinationRegion.get('kodakOriginalUrl')) {
        return secondDestinationRegion;
      }
      return firstDestinationRegion.get('heroEntity')
    } else {
      return EmberObject.create({
        kodakOriginalUrl: 'https://timbuktutravel.imgix.net/assets/images/destination-fallback/Desert_1.jpg',
        coverStyle: 'center center'
      });
    }
  },

  @computed('friendlyId', 'id')
  tripFriendlyId(friendlyId, id) {
    return friendlyId || id;
  },

  @computed('countries.[]')
  countriesList(countries) {
    return humanReadableList(countries);
  },

  @computed('regions.[]')
  regionsList(regions) {
    return humanReadableList(regions);
  },

  @computed('lodgeActivities')
  topActivities(activities) {
    return activities && activities.slice(0, 4);
  },

  @computed('lodgeActivities', 'topActivities.length')
  otherActivities(activities, len) {
    return activities && activities.slice(len);
  },


  @computed('itinerary.accomStages.@each.lodge')
  lodgeActivities(stages) {
    var allActivityExperiences = [];

    stages.forEach((stage) => {
      stage.get('lodge.activityExperiences').forEach((experience) => {
        allActivityExperiences.pushObject(experience);
      })
    })

    return allActivityExperiences.uniq();
  },

  @computed('itinerary.accomStages.@each.lodge')
  unpricedLodgesNames(lodges) {
    let unpricedLodgesNames = [];

    lodges.forEach((stage) => {
      if (stage.get('lodge') && isEmpty(stage.get('lodge.fromPrice'))) {
        unpricedLodgesNames.pushObject(stage.get('lodge.name'));
      }
    });

    return unpricedLodgesNames;

  },

  @computed('itinerary.nonTransferAndLocationStages.[]')
  countries(nonTransferAndLocationStages) {
    let countrys = [];

    if (nonTransferAndLocationStages) {
      nonTransferAndLocationStages.forEach((stage) => {
        if (isPresent(stage.get('countryProxy.name'))) {
          countrys.pushObject(stage.get('countryProxy'));
        }
      })
    }
    let countries = uniqueBy(countrys, 'id');
    return countries;
  },


  @computed('countries')
  countryNames(countries) {
    return countries.mapBy('name').sort();
  },

  @computed('itinerary.nonTransferAndLocationStages.[]')
  regions(nonTransferAndLocationStages) {
    let regions = [];

    if (nonTransferAndLocationStages) {
      nonTransferAndLocationStages.forEach((stage) => {
        // Check that the region is present by checking for its name. Right now because the regions are sync relationships, but now some lodges might not have regions, we have to check if that region is actually there
        if (isPresent(stage.get('regionProxy.name'))) {
          regions.pushObject(stage.get('regionProxy'));
        }
      })
    }
    return regions;
  },

  @computed('itinerary.nonTransferAndLocationStages.[]')
  effectiveRegions(nonTransferAndLocationStages) {
    let regions = [];
    let regionNames = []

    if (nonTransferAndLocationStages) {
      nonTransferAndLocationStages.forEach((stage) => {
        // Check that the region is present by checking for its name. Right now because the regions are sync relationships, but now some lodges might not have regions, we have to check if that region is actually there

        if (isPresent(stage.get('regionProxy.name')) && regionNames.includes(stage.get('regionProxy.name'))) {
          //This region name has already been added, so lets move on!
        } else if (isPresent(stage.get('regionProxy.name')) && !regionNames.includes(stage.get('regionProxy.name'))) {
          regions.pushObject(stage.get('regionProxy'));
          regionNames.pushObject(stage.get('regionProxy.name'));
        } else if (isPresent(stage.get('placeName')) && !regionNames.includes(stage.get('placeName'))) {
          regionNames.pushObject(stage.get('placeName'));
          regions.pushObject({
            placeName: stage.get('placeName'),
            country: stage.get('countryProxy'),
            fakeRegion: true,
            kodakOriginalUrl: 'https://waybird.imgix.net/assets/images/destination-fallback/Desert_1.jpg',
            regionInfo: {
              metaImagesAfterQuote: [
                {originalUrl: 'https://waybird.imgix.net/assets/images/destination-fallback/Mountains_1.jpg'},
                {originalUrl: 'https://waybird.imgix.net/assets/images/destination-fallback/Mountains_3.jpg'}
              ]
            }

          })
        }
      })
    }
    return regions;
  },


  @computed('itinerary.accomStages.[]')
  lodgeKodakImages(accomStages){
    let kodaks = [];

    accomStages.forEach((stage, index) => {
      // use tripIdeaKodak for cover
      if (index === 1) {
        return
      }
      kodaks.pushObject({
        description: stage.get('lodge.name'),
        originalUrl: stage.get('lodge.kodakOriginalUrl'),
        dimensions: null
      });
    });

    // put first image last
    kodaks.push(kodaks.shift());

    return kodaks;
  },

  @computed('itinerary.accomStages.[]', 'itinerary.stages.@each.lodge', 'itinerary.stages.@each.entity')
  tripIdeaKodak(accomStages){
    if (accomStages && accomStages.objectAt(1) && accomStages.objectAt(1).get('lodge.kodakOriginalUrl')) {
      return accomStages.objectAt(1).get('lodge.kodakOriginalUrl');
    } else if (accomStages && accomStages.objectAt(0) && accomStages.objectAt(0).get('lodge.kodakOriginalUrl')) {
      return accomStages.objectAt(0).get('lodge.kodakOriginalUrl');
    } else {
      // also define on editor controller
      return 'https://waybird.imgix.net/assets/images/general/choose-your-own-lodges.JPG';
    }
  },

  @computed('updatedAt')
  lastSavedToday(updatedAt) {
    return moment(updatedAt).isSame(moment().clone().startOf('day'), 'd');
  },

  @computed('minNights', 'maxNights', 'itinerary.accomStages')
  rangeOfNights(minNights, maxNights, accomStages) {
    if (isPresent(minNights) && isPresent(maxNights)) {
      return `${minNights} to ${maxNights}`;
    } else {
      return `${accomStages.length*2} to ${accomStages.length*4}`;
    }
  },

  makeCopy() {
    var trip = this.get('store').createRecord('trip');
    trip.set('name', this.get('name'));
    trip.set('layout', this.get('layout'));
    trip.set('hubspotDealId', this.get('hubspotDealId'));
    trip.set('defaultItinerary', this.get('itinerary').makeCopy());
    return trip;
  },


  refreshStartEndLocationsAndTransfers() {

    // We exclude add on stages for now
    let nonTransferAndLocationStages = this.get('itinerary.nonTransferAndLocationStages').rejectBy('stageType', 'add-on')
    console.log(nonTransferAndLocationStages)
    if (nonTransferAndLocationStages.get('length') >= 1) {
      let startStage = this.get('itinerary.stages.firstObject');
      let finishStage = this.get('itinerary.stages.lastObject');
      if (this.get('itinerary.autoAllocateStartAndEnd')) {

        if (!startStage.get('itinerary.manualStartLocation') && startStage.isLocationStage) {
          let startLocation = nonTransferAndLocationStages.get('firstObject.entity.defaultLocation') || nonTransferAndLocationStages.get('firstObject.entity.region.defaultLocation') || nonTransferAndLocationStages.get('firstObject.entity.region.country.defaultLocation');
          if (startLocation) {
            startStage.set('location', startLocation);
          }
        }

        if (!finishStage.get('itinerary.manualEndLocation') && finishStage.isLocationStage) {
          let endLocation = nonTransferAndLocationStages.get('lastObject.entity.defaultLocation') || nonTransferAndLocationStages.get('lastObject.entity.region.defaultLocation') || nonTransferAndLocationStages.get('lastObject.entity.region.country.defaultLocation');
          if (endLocation) {
            finishStage.set('location', endLocation);
          }
        }
      }

      if (this.isLayoutDayByDay && finishStage.isLocationStage) {
        // This needs a schedule to showcase final transfer and location
        if (!finishStage.schedule) {
          this.templateManager.addCustomSchedule({stage: finishStage, createBlock: true})
        }
        if (!finishStage.schedule.getBlockItemByTypeIfItExists('todays-transfers') && !finishStage.schedule.getBlockItemByTypeIfItExists('todays-preceding-stages')) {
          this.templateManager.addBlockItem({block: finishStage.get('customSchedule.blocks.firstObject'), blockItemType: 'todays-preceding-stages'})
        }
      }

      // Check if any stages were re-ordered. We set needsTransfer on the stage, so that the server populates the transfer
      this.get('itinerary.stages').forEach((stage, index) => {
        if (stage.madeTransferAffectingChanges){

          let prevTransfer = prevTransferStage(this.get('itinerary.stages'), index);
          let nextTransfer = nextTransferStage(this.get('itinerary.stages'), index);

          if (prevTransfer && !prevTransfer.isDeleted) {
            prevTransfer.set('needsTransfer', true);
          }

          if (nextTransfer && !nextTransfer.isDeleted) {
            nextTransfer.set('needsTransfer', true);
          }
          if (stage.isTransferStage) {
            stage.set('needsTransfer', true);
          }
        }
      });

      // this.ensureWeHaveStartingAndEndingTransfers()

    } else {
      // This clears the stages if the last non transfer is removed by a user that is not a manager
      if (!this.get('session.currentUser.isManager')) {
        // Ian 02/03 2015 This will cause problems in the future if we have locations anywhere other than just the start and end location
        this.get('itinerary.stages').clear();
      }
    }

  },

  makeCopyForSaving(options) {

    // Code smells!

    var copyForSaving = this.get('store').createRecord('trip');
    copyForSaving.set('name', this.get('name'));
    copyForSaving.set('layout', this.get('layout'));
    copyForSaving.set('hubspotDealId', this.get('hubspotDealId'));
    copyForSaving.set('fromSection', this.get('fromSection'));
    copyForSaving.set('overrideKodak', this.get('overrideKodak'));
    copyForSaving.set('overrideSecondKodak', this.get('overrideSecondKodak'));
    copyForSaving.set('overrideThirdKodak', this.get('overrideThirdKodak'));
    copyForSaving.set('displayPrices', this.get('displayPrices'));
    copyForSaving.set('proposedNotifyNewPrimaryManager', this.get('proposedNotifyNewPrimaryManager'));
    copyForSaving.set('isGiftList', this.get('isGiftList'));
    copyForSaving.set('hideGiftAmounts', this.get('hideGiftAmounts'));
    copyForSaving.set('giftIntro', this.get('giftIntro'));
    copyForSaving.set('giftReceiver', this.get('giftReceiver'));
    copyForSaving.set('hideGiftListContributions', this.get('hideGiftListContributions'));
    copyForSaving.set('reportTripView', this.get('reportTripView'));
    copyForSaving.set('onlyTripUsersCanView', this.get('onlyTripUsersCanView'));
    copyForSaving.set('scheduleHeading', this.get('scheduleHeading'));


    //NB: If you are going to add properties here think about whether you want them to be
    // here if a copy of a trip is saved! also impacts the duplicater on the server

    copyForSaving.set('defaultItinerary', this.get('defaultItinerary').makeCopy());
    copyForSaving.set('templateTrip', this);

    if (options.removeReferenceToSpecialOfferWhenSaving) {
      copyForSaving.set('itinerary.specialOfferId', null);
    }

    return copyForSaving;
  },

  // This code is used to apply changes from draft stage to real stage. Different to making copy of a draft trip
  applyChangesToStage(sourceStage, targetStage) {
    // NB Code here also needs to apply to stage makeCopy

    Stage.eachAttribute((name) => {
      targetStage.set(name, sourceStage.get(name));
    })

    targetStage.set('lodge', sourceStage.get('lodge'));
    targetStage.set('location', sourceStage.get('location'));
    targetStage.set('region', sourceStage.get('region'));
    targetStage.set('customTransfer', sourceStage.get('customTransfer'));
    targetStage.set('templateTransfer', sourceStage.get('templateTransfer'));
    targetStage.set('customSchedule', sourceStage.get('customSchedule'));
    targetStage.set('templateSchedule', sourceStage.get('templateSchedule'));

    // When saving a trip, only add filter joins in if they aren't already present
    // Because when the draft trip is created, duplicates of the fitler joins are added to the draft which allows us to blow them away while applying trip ideas `
    sourceStage.get('filterJoins').forEach((filterJoin) => {
      if (!targetStage.get('filterJoins').filterBy('stageFilter', filterJoin.stageFilter)) {
        targetStage.get('filterJoins').pushObject(filterJoin);
      }
    })

    if (sourceStage.get('lodgeOptions')) {
      sourceStage.get('lodgeOptions').forEach((lodgeOption) => {
        if (!targetStage.get('lodgeOptions').includes(lodgeOption)) {
          targetStage.get('lodgeOptions').pushObject(lodgeOption);
        }
      })
    }


  }

  // ensureWeHaveStartingAndEndingTransfers() {
  //   // This adds the first transfer if it doesnt exist - eg new trip
  //   // get the second stage, and check if its a transfer stage, else add one
  //   // we also check that there is atleast one stage before it
  //   if (!this.get('itinerary.stages').objectAt(1).isTransferStage && this.get('itinerary.stages').objectAt(0)){
  //     let newStartTransferStage = this.get('store').createRecord('stage', {
  //       stageType: 'template-transfer',
  //       numNights: 0,
  //       needsTransfer: true
  //     });

  //     this.get('itinerary.stages').insertAt(1, newStartTransferStage);
  //     // TO DO: focus the correct stage after adding first stage to new trip
  //   }

  //   // This adds the last transfer if it doesnt exist - eg new trip
  //   // get the second last stage, and check if its a transfer stage, else add one
  //   // we also check that there is atleast one more stage after it
  //   let secondLastStage = this.get('itinerary.stages').objectAt(this.get('itinerary.stages.length') - 2);
  //   let lastStage = this.get('itinerary.stages').objectAt(this.get('itinerary.stages.length') - 1);
  //   if (!secondLastStage.isTransferStage && lastStage){
  //     let newEndTransferStage = this.get('store').createRecord('stage', {
  //       stageType: 'template-transfer',
  //       numNights: 0,
  //       needsTransfer: true
  //     });

  //     this.get('itinerary.stages').insertAt((this.get('itinerary.stages.length') - 1), newEndTransferStage);
  //   }
  // }

});
