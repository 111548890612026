import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';


export default DS.Model.extend({
  currencyContributions: DS.hasMany('currency-contribution', {
    inverse: null
  }),


  @computed('currencyContributions.@each.amount')
  priceHash(currencyContributions) {

    let hash = {};
    currencyContributions.forEach((contribution) => {
      hash[contribution.currency] = contribution.amount;
    })
    return hash;
  }

});