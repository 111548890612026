import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  tagName: '',
  ui: service(),
  settings: service(),
  tripService: service('trip'),
});
