
import DayByDaySection from 'b5b/components/day-by-day-section/component';
import {
  computed
} from 'ember-decorators/object';
import {
isEmpty
} from '@ember/utils';
import stage from '../../../models/stage';

export default DayByDaySection.extend({
  @computed('stage', 'trip.itinerary.stages.@each.entity', 'trip.itinerary.stages.@each.dayNumberStart')
  todaysLocationStages(currentStage, stages) {
    return stages && stages.filter(function (stage, index) {
      return stage.dayNumberStart == currentStage.dayNumberStart && stage.isLocationStage;
    });    
  },

});
