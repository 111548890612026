import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  alias,
  or,
  equal
} from 'ember-decorators/object/computed';
import {
  friendlyIdToSeoName
} from 'b5b/utils';
import Self from 'b5b/models/region';
import {
  memberAction
} from 'ember-api-actions';
import {
  inject as service
} from '@ember/service';

export default DS.Model.extend(HasKodakImage, {
  friendlyId: DS.attr(),
  name: DS.attr(),
  internalDescription: DS.attr('string'),
  regionType: DS.attr(),
  published: DS.attr(),
  shortName: DS.attr('string'),
  teaserDescription: DS.attr(),
  teaserText: DS.attr(),
  overview: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),

  placesCount: DS.attr(),
  isStopOver: DS.attr(),

  polishLevel: DS.attr('string'),
  userOwnerId: DS.attr('string'),
  userOwnerName: DS.attr('string'),
  shareWithAgency: DS.attr(),
  isArchived: DS.attr(),

  category: DS.attr(),
  imagesCount: DS.attr(),
  regionAndCountryAreTheSameThing: DS.attr(),

  replaceImages: DS.attr(),
  replaceImagesVersion: DS.attr(),

  metaImageUsedAsKodak: DS.attr(), // Only used for saving chagnes to kodak
  metaImages: DS.attr(), // Readonly json version of region images created when region saved at server
  metaImagesCount: DS.attr(),
  metaImagesAfterQuoteCount: DS.attr(),

  longitude: DS.attr('string'),
  latitude: DS.attr('string'),

  defaultLocation: DS.belongsTo('location', {
    async: false,
    inverse: null
  }),

  parentRegion: DS.belongsTo('region', {
    inverse: null
  }),

  country: DS.belongsTo('country', {
    inverse: null,
    async: false
  }),
  //originalEntity currently not serialised back from server. This is used to save relationship to servero/
  originalEntity: DS.belongsTo('region', {
    inverse: null,
    async: false
  }),
  associatedEntities: DS.hasMany('associatedEntity', {
    async: false
  }),

  regionInfo: DS.belongsTo('regionInfo'),
  lodges: DS.hasMany('lodge'),
  childRegions: DS.hasMany('region', {
    inverse: null
  }),

  areas: DS.hasMany('area', {
    async: true
  }),

  specifyReplaceImages: memberAction({
    path: 'specify_replace_images'
  }),

  whitelabel: service(),

  @equal('regionType', 'place') isPlace: false,
  @equal('regionType', 'grouping') isGrouping: false,
  @equal('polishLevel', 'polished') isPolished: false,
  @equal('polishLevel', 'custom') isCustom: false,
  @alias('latitude') latitudeWithFallback: null,
  @alias('longitude') longitudeWithFallback: null,
  @alias('regionInfo') destinationInfo: null,
  @alias('regionInfo') entityInfo: null,

  @or('shortName', 'name') displayName: null,
  // Note Safari has capital here but is lowercase for countries
  @equal('category', 'Safari') isSafari: true,

  @mapBy('regionInfo.metaImages', 'kodakOriginalUrl') carouselImages: null,

  // a region can reference itself, eg. entity.region where entity is a region
  @computed()
  region() {
    return this;
  },

  // @computed('name')
  // isStopOver(name) {
  //   return ['Windhoek', 'Arusha', 'Johannesburg', 'Dar es Salaam', 'Nairobi'].indexOf(name) > -1;
  // },

  @computed('latitude', 'longitude')
  mapMarker(latitude, longitude) {
    if (!latitude || !longitude) {
      return;
    }

    return [
      [parseFloat(longitude).toFixed(6), parseFloat(latitude).toFixed(6)]
    ];
  },

  @computed('isSafari', 'name')
  h1Name(isSafari, regionName) {
    let title = regionName;
    if (this.whitelabel.isForTimbuktu) {
      if (isSafari) {
        title += ' Safari';
      }
      title += ' Trips & Tours';
    }
    return title;
  },

  @computed('associatedEntities.[]')
  associatedRegions(associatedEntities) {
    return associatedEntities.map((entity)=> {
      return entity.get('associatedRegion')
    });
  },

  @computed()
  mapEntity(latitude, longitude) {
    return this;
  },

  @computed('latitude', 'longitude')
  isValidMapLocation(lat, long) {
    return !isNaN(lat) && !isNaN(long)
  },

  @computed('friendlyId', 'name')
  seoName(friendlyId, regioName) {
    if (friendlyId) {
      return friendlyIdToSeoName(friendlyId);
    } else {
      return regioName+' trip';
    }
  },

  @computed('metaImages')
  metaImagesAfterQuote(metaImages){
    //Not all meta images will have excludeOnQuote set so we have to check for truthiness, instead of filter by false
    return metaImages.filter((metaImage) => {
      return !(metaImage.excludeOnQuote)
    });
  },

  @computed('kodakOriginalUrl', 'country')
  heroEntity(kodakOriginalUrl, country) {
    if (kodakOriginalUrl) {
      return this;
    }

    if (country && country.get('hasKodakImage')) {
      return country;
    }

  },

  @computed('friendlyId', 'id')
  regionFriendlyId(friendlyId, id) {
    return friendlyId || id;
  },

  makeCopy() {

    let copy = this.get('store').createRecord('region');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })

    copy.set('defaultLocation', this.get('defaultLocation'))
    copy.set('country', this.get('country'))
    return copy;
  }

});
