import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';

export default payFormComponent.extend({

  chargeType: 'stripe',

  classNames: ['pay-form stripe-card-form'],

  stripev3: service(),
  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  cardNumberOptions: {
    placeholder: 'Card number'
  },
  expiryOptions: {
    placeholder: 'MM/YY'
  },
  cvcOptions: {
    placeholder: 'CVC'
  },
  postalCodeOptions: {
    placeholder: ''
  },

  didInsertElement() {
    this._super(...arguments);

    throw new TypeError('This is not compatible with latest charges implementation.');
    
    // Note that currently the paymentsDue collection is re-created when the currency is changed.
    // IF this implementation changes at all, then this will intent will need to be re-created whenever the users preferrred currency changes

    // The creation of payment intents has been moved to charges
    // this.get('trip').createPaymentIntent({amount: this.get('transactionAmount'), currency: this.get('transactionCurrency')}).then((response)=> {
    //   if (this.get('isDestroying') || this.get('isDestroyed')) {
    //     return
    //   }
    //   this.set('paymentDue.intentClientSecret', response.intent.client_secret);
    //   this.set('_payButtonReady', true);
    // })
  },
  // not great but this sets a few props as side effects of being computed

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return STRIPE_CURRENCIES.includes(settlementCurrency) ? settlementCurrency : 'usd';
  },

  actions: {
    processPayment(stripeElement) {

      // Dont allow guest to pay with historical rates
      if (window.timbuktu.xeDate) {
        this.get('ui').showGeneralMessage('Oops!', 'You are currently using historical exchange rates. Please reload the page with the latest exchange rates before making payment. The url should not include xeDate=xx-xx-xxxx in it.');
        return;
      }

      // check if the terms and conditions have been accepted
      if (!this.get('trip.itinerary.termsAcceptedDateTime') && !this.get('session.currentUser.isManager') && !this.get('trip.isGiftList')) {
        this.get('ui').showGeneralMessage('Oops!', `Please accept ${capitalizeFirstLetter(this.whitelabel.agency.name)}'s terms and conditions before continuing`);
        return;
      }

      trackEvent('pay-button:click');
      // only one transaction at a time
      if (this._processing) {
        trackEvent('pay-button:click:already-processing');
        return;
      }

      this.set('_processing', true);

      this.ui.set('showProcessingModal', true);
      let context = this;
      this.stripev3.handleCardPayment(
        this.paymentDue.intentClientSecret, stripeElement, {
          payment_method_data: {
            billing_details: {name: this.session.currentUser.name}
          }
        }
      ).then(function(stripeResponse) {

        // console.log(stripeResponse)
        context.set('_processing', false);
        if (stripeResponse.error) {
          context.handleFailedPayment(stripeResponse).then().finally(()=> {
            context.ui.set('showProcessingModal', false);
            context.ui.showGeneralMessage('Oops', 'We were unable to process your payment due to the following reason:<br><br> '+stripeResponse.error.message);
          });
        } else {
          context.handleSuccesfulPayment(stripeResponse)
        }
      });

    }
  }
});



