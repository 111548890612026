import TWidgetComponent from 'b5b/components/t-widget/component';

import {
  inject as service
} from '@ember/service';
import {
  htmlSafe
} from '@ember/string';
import {
  computed
} from 'ember-decorators/object';
import {
  alias,
  equal
} from 'ember-decorators/object/computed';

export default TWidgetComponent.extend({
  classNames: ['trip'],

  settings: service(),
  ui: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.savingTrip') savingTrip: false,
  @alias('trip.itinerary.accomStageLodgeMapping.length') stagesWithLodgesCount: null,

  @alias('tripService.viewingSomeoneElsesTrip') viewingSomeoneElsesTrip: false,

  @equal('stagesWithLodgesCount', 0) routeHasNoLodges: null,

  onQuote: false,

  @computed('stagesWithLodgesCount', 'trip.itinerary.nonTransferAndLocationStages.[].length')
  progressWidth(stagesWithLodgesCount, totalStagesCount) {
    return htmlSafe('width:' + ((stagesWithLodgesCount / totalStagesCount) * 100) + '%;');
  }
});
