import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  and,
  gt
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNames: ['map-container'],

  entityModals: service(),


  isInsertedToDom: false,
  parentElSelector: null,
  showLodgePrices: true,
  popupOpenIndex: 1,

  stages: [],

  height: null, // this overrides dynamic height changes

  @gt('bounds.length', 2) hasBounds: null,
  @and('screen.initialized', 'isInsertedToDom', 'hasBounds') isMapReady: false,

  didInsertElement() {
    this._super(...arguments);
    this.setHeightForMap();
  },

  setHeightForMap() {
    let $el = this.$();

    if (this.get('height')) {
      $el.height(this.get('height'));
      return;
    }

    let parentElSelector = this.get('parentElSelector');
    let parent = parentElSelector ? $el.closest(parentElSelector) : $el.parent();
    $el.height(parent.outerHeight());
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this.setHeightForMap();
    if (this.get('searchBarOpen')) {
      this.send('showSearchBar')
    }
  },


  afterRenderEvent() {
    this.set('isInsertedToDom', true);
  },

  @computed('stages.@each.entity')
  bounds(stages) {

    if (stages.get('length') > 0) {

      var bounds = stages.reduce(function(result, stage) {
        if (stage.entity && stage.get('entity.latitudeWithFallback') && stage.get('entity.longitudeWithFallback')) {
          let lat = stage.get('entity.latitudeWithFallback'),
          lng = stage.get('entity.longitudeWithFallback');
          result.push([lat, lng]);
        }
        return result;
      }, []);

      if (bounds.get('length') == 1) {
        // Its crazy but true. Ian 30/12/2016. This is needed because otherwise the bounds calculation blows up on initial page load if there is only a single lodge in the result set.
        // The bounds seems fine if you change fitlers so there is a single lodge but refresh and everythign falls over!
        var lat = parseFloat(bounds[0][0]);
        var lng = parseFloat(bounds[0][1]);
        bounds.pushObject([(lat - 0.25).toString(), (lng - 0.25).toString()])
        bounds.pushObject([(lat + 0.25).toString(), (lng + 0.25).toString()])
      }

      return bounds;
    }
  },

  @computed('stages.@each.entity')
  polyLineLocations(stages) {
    if (!stages){
      return [];
    }

    var lines = [];
    for (var i = 0; i < stages.length - 1; i += 1) {
      lines.push(
        [stages.objectAt(i).get('mapLocation'), stages.objectAt(i + 1).get('mapLocation')]
      );
    }
    return lines;
  },

  actions: {
    hideMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      marker.target.setOpacity(0);
    },

    showMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      marker.target.setOpacity(1);
    }
  }

});
