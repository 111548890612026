import { helper } from "@ember/component/helper";

export function templateKeyToLabel([key] /*, hash*/) {
  const templateHash = {
    lodge_why_love: "Why you'll love it",
    stage_covid_overview: "Covid-19 Overview",
    transfers: "Transfers",
    schedules: "Schedules",
    inclusions: "Inclusions",
  };
  return templateHash[key];
}

export default helper(templateKeyToLabel);
