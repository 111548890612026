import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  tripService: service('trip'),

  didInsertElement() {
    if (this.tripService.currentTrip.get('id') && this.tripService.currentTrip.userTrip) {
      this.get('tripService').sendEvent({
        eventType: 'trip:view'
      });
    }
  }
});
