import {
  inject as service
} from '@ember/service';
import {
  generateMeta
} from 'b5b/utils';
import Mixin from '@ember/object/mixin';

export default Mixin.create({

  tripService: service('trip'),
  headTagsService: service('head-tags'),

  headTags() {
    // Sub routes of trips should not be indexed... also no follow... stop the explosion of subroute urls under trips
    return generateMeta({
      'robots': 'noindex, nofollow'
    });
  }

});