import Service, {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';

export default Service.extend({

  store: service(),
  publishedCountriesRetrieved: false,
  allCountriesRetrieved: false,
  allRegionsRetrieved: false,
  allCountriesAndRegionsRetrieved: false,

  getAllCountriesPromise() {
    if (!this.allCountriesRetrieved) {
      return this.store.query('country', {includeUnpublished: true}).then((countries)=> {
        this.set('allCountriesRetrieved', true);
        return countries.sortBy('name')
      })
    }
    let context=this;
    return new RSVP.Promise(function(resolve) {
      resolve(context.store.peekAll('country').sortBy('name'));
    });
  },

  getAllRegionsPromise() {
    if (!this.allRegionsRetrieved) {
      return this.store.query('region', {}).then((regions)=> {
        this.set('allRegionsRetrieved', true);
        return regions.sortBy('name')
      })
    }
    let context=this;
    return new RSVP.Promise(function(resolve) {
      resolve(context.store.peekAll('region').sortBy('name'));
    });
  },


  getAllCountriesAndRegionsPromise() {
    if (!this.allCountriesAndRegionsRetrieved) {
      return this.store.query('country', {includeUnpublished: true, includeRegions: true}).then((countries)=> {
        this.set('allCountriesAndRegionsRetrieved', true);
        return countries.sortBy('name')
      })
    }
    let context=this;
    return new RSVP.Promise(function(resolve) {
      resolve(context.store.peekAll('country').filterBy('published').sortBy('name'));
    });
  },

  getAllPublishedCountriesPromise() {
    if (!this.publishedCountriesRetrieved) {
      return this.store.query('country', {}).then((countries)=> {
        this.set('publishedCountriesRetrieved', true);
        return countries.sortBy('name')
      })
    }
    let context=this;
    return new RSVP.Promise(function(resolve) {
      resolve(context.store.peekAll('country').filterBy('published').sortBy('name'));
    });
  },

  getAllPublishedCountries() {
    return this.store.peekAll('country').filterBy('published').sortBy('name');
  },

  getAllLocationsPromise() {
    let locationsCount = this.store.peekAll('home').get('firstObject.locationsCount');
    let locations = this.store.peekAll('location').sortBy('name');
    if (locations.get('length') >= locationsCount) {
      return new RSVP.Promise(function(resolve) {
        resolve(locations);
      });
    }

    return this.store.query('location', {}).then((locations) => {
      return locations.sortBy('name');
    });
  }

});
