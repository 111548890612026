// Note: this used to extend route cards but no longer does
import Component from '@ember/component';

import {
  computed
} from 'ember-decorators/object';
import MapPoints from 'b5b/mixins/map-points';

export default Component.extend(MapPoints, {
  classNames: ['route-review-card'],

  review: null,



});
