import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

export default Controller.extend({

  settings: service(),
  session: service(),
  store: service(),
  ui: service(),
  whitelabel: service(),


  actions: {
    addGuest() {
      let newGuest = this.store.createRecord('guest');
      newGuest.set('ageType', 'adult');
      newGuest.set('name', `Adult ${this.deal.get('dealGuests.length')+1}`)
      let dealGuest = this.store.createRecord('deal-guest');
      dealGuest.set('guest', newGuest)
      this.get('deal.dealGuests').pushObject(dealGuest);
    },
  }

});
