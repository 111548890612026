import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent,
  getErrorsHashFromServerResponse
} from 'b5b/utils';

export default Route.extend({

  saved: false,
  tripService: service('trip'),
  ui: service(),

  // for rolling back if not saved
  cachedContactSharedGuest: null,
  cachedEmergencySharedGuest: null,
  cachedInsuranceSharedGuest: null,

  model(params) {
    // remember if there is null in url it will be string of null for param
    if (!params.guest_id || params.guest_id == 'null') {
      return null;
    }
    if (!this.tripService.get('tripBelongsToCurrentUser')) {
      // Return null here otherwise it attempts to retrieve teh guest user details and server throws exception which causes just spinner to show instead of authentication prompt
      return null;
    }
    return this._super(...arguments)
  },

  setupController(controller, model) {
    if (!model) {
      return this.transitionTo('trip.index.dashboard.guest-details', this.get('tripService.currentTrip.friendlyId'));
    }

    // NB Don't set the trip here. It is injected into the controller
    controller.setProperties({
      guest: model,
      errors: [],
      saving: false
    });
    trackEvent('trip:dashboard:guest-details:guest');

    // for rollback
    this.setProperties({
      cachedContactSharedGuest: model.get('contactSharedGuest'),
      cachedEmergencySharedGuest: model.get('emergencySharedGuest'),
      cachedInsuranceSharedGuest: model.get('insuranceSharedGuest')
    });

  },

  resetController(controller, isExiting) {
    this._super(...arguments)
    if (isExiting) {
      // have to reset this as instance variables on controllers retain scope
      this.set('saved', false);
    }
  },

  actions: {

    saveGuest(guest) {
      this.controller.set('saving', true);
      this.controller.set('errors', null);
      this.controller.set('showErrors', false);

      return guest.save().then(() => {
        this.controller.set('saving', false);
        this.set('saved', true);
        this.transitionTo('trip.index.dashboard.guest-details.index', this.controller.get('trip'));
      }).catch((serverResponse) => {

        let errors = getErrorsHashFromServerResponse(serverResponse);
        if (errors && errors.authorisation) {
          this.ui.showGeneralMessage('Oops!', errors.authorisation);
        } else {
          this.flashMessages
            .danger('Sorry! Please could you fill out all the required fields', {
              timeout: 4000,
              sticky: false
            });
        }

        this.controller.set('errors', errors);
        this.controller.set('showErrors', true);
        this.controller.set('saving', false);
      });
    },

    willTransition() {
      this._super(...arguments);

      if (!this.saved) {
        this.controller.get('guest').rollbackAttributes();

        this.controller.get('guest').setProperties({
          contactSharedGuest: this.cachedContactSharedGuest,
          emergencySharedGuest: this.cachedEmergencySharedGuest,
          insuranceSharedGuest: this.cachedInsuranceSharedGuest
        });
      }
      // return true so willTransition bubbles
      return true;

    }
  }
});
