import DS from 'ember-data';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

var Inclusion = DS.Model.extend({
  internalName: DS.attr(),
  overview: DS.attr(),
  // inclusionType: DS.attr(),
  inclusionPolicy: DS.attr(),
  excludes: DS.attr(),
  includes: DS.attr(),
  polished: DS.attr(),
  isMaster: DS.attr(),
  region: DS.belongsTo('region', {inverse: null, async: false}),
  lodge: DS.belongsTo('lodge', {inverse: null, async: false}),
  originalUser: DS.belongsTo('user', {inverse: null, async: false}),

  @computed('region', 'lodge')
  entity(region, lodge) {
    return lodge || region;
  },
});

export default Inclusion;
