import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';



export default Route.extend({

  store: service(),


  model(transition) {
    // Dont look up deal with id of new
    // this._super(...arguments);

    trackEvent('deal:new');

    var deal = this.store.createRecord('deal');
    return deal;

  },

  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('model.sourceCountry', 'US');
    controller.set('model.sourceState', 'CA');
    controller.set('model.settlementCurrency', 'usd')
    controller.set('deal', model);
  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      controller.set('model', null)
      controller.set('deal', null)
    }


  }

});
