import { helper } from '@ember/component/helper';
import {
  pluralEntityType
} from 'b5b/utils';

export function pluralEntityTypeHelper([entity]) {
  return pluralEntityType(entity)
}

export default helper(pluralEntityTypeHelper);
