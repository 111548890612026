import Route from '@ember/routing/route';
export default Route.extend({

  model() {
    return this.modelFor('deal.index');
  },

  setupController: function (controller, model) {
    this._super(...arguments);
    controller.set('deal', model);
  },
});
