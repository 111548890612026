import Mixin from '@ember/object/mixin';

import RSVP from 'rsvp';
import DS from 'ember-data';
import {
  computed
} from 'ember-decorators/object';
import {
  getTripImageFromRegionalImage,
  getTripImageFromEntityMetaImage
} from 'b5b/utils';



export default Mixin.create({

  overrideKodak: DS.attr(),
  overrideSecondKodak: DS.attr(),
  overrideThirdKodak: DS.attr(),

  @computed('uniqueRegions.@each.id', 'overrideKodak', 'overrideSecondKodak', 'overrideThirdKodak')
  tripCarouselImages(regions, overrideKodak, overrideSecondKodak, overrideThirdKodak){
    let regionIds = this.get('regions').mapBy('id');
    let polishLevels = this.get('regions').mapBy('polishLevel');
    //If this is regionsIds is empty array then ember doesnt include it in the query, so we just force the issue
    let regionsQuery = this.store.query('region', {
      ids: regionIds, 
      include_region_info: true, 
      force_region_ids: true,
      buster: polishLevels.includes('custom')
    });

    function getRegionHeroImage(region) {
      return {
        description: region.get('name'),
        originalUrl: region.get('heroEntity.kodakOriginalUrl'),
        coverStyle: region.get('heroEntity.coverStyle'),
        regionId: region.get('id')
      }
    }

    return regionsQuery.then((regions) => {

      let images = [];
      let firstRegionIndex = 0,
        firstRegionImage = false,
        backupFirstRegionImage = false,
        secondRegionIndex = 1,
        secondRegionImage = false,
        backupSecondRegionImage = false,
        firstRegionImageIndex = 0,
        secondRegionImageIndex = 0;

        if (regions.length === 1) {
          secondRegionIndex = 0;
          secondRegionImageIndex = 2;
        }
        let numProperRegions = regions.filterBy('isStopOver', false).length;
        let properRegions = [];
        regions.forEach((region) => {
          // Ideally we don't want stopover images in the carousel. But we need two regions for trip hero so if we don't ahve enough proper regions, we include the stopover ones
          if (numProperRegions < 2 || !region.get('isStopOver')) {
            properRegions.pushObject(region)
          }
        })
      properRegions.forEach((region, regionIndex) => {
        let regionInfo = this.store.peekRecord('regionInfo', region.id)
        // insert region kodaks first
        images.pushObject(getRegionHeroImage(region))


        //Rather verbose but not all regions have metaImagesAfterQuote so we need to shift the indexes along
        if (Ember.isEmpty(region.get('metaImagesAfterQuote'))) {
          if (!firstRegionImage) {
             if (regionIndex === firstRegionIndex){
               firstRegionIndex++;
               secondRegionIndex = firstRegionIndex + 1;
             }
          } else {
            if (!secondRegionImage) {
              if (regionIndex === secondRegionIndex){
                 secondRegionIndex++;
              }
            }
          }
        }

        let quoteFriendlyImages = region.get('metaImagesAfterQuote');

        let regionImages = []
        quoteFriendlyImages.forEach((image, index)=> {
          if (index < 10) {
            let newImage = getTripImageFromRegionalImage(image, regionInfo.get('region'))

            //See if the override image is found in the collection of region images

            if (!firstRegionImage && this.overrideSecondKodak && this.overrideSecondKodak.originalUrl == newImage.originalUrl ) {
              firstRegionImage = newImage;
            } else if (!firstRegionImage && regionIndex === firstRegionIndex && index == firstRegionImageIndex){
              if (!this.overrideSecondKodak) {
                firstRegionImage = newImage;
              } else {
                backupFirstRegionImage = newImage;
              }
            }

            if (!secondRegionImage && this.overrideThirdKodak && this.overrideThirdKodak.originalUrl == newImage.originalUrl ) {
              secondRegionImage = newImage;
            } else if (!secondRegionImage && regionIndex === secondRegionIndex && index == secondRegionImageIndex){
              if (!this.overrideThirdKodak) {
                secondRegionImage = newImage;
              } else {
                backupSecondRegionImage = newImage;
              }
            }

            regionImages.pushObject(newImage);
          }
        })
        if (!firstRegionImage && this.overrideSecondKodak && this.overrideSecondKodak.regionId==regionInfo.get('id')) {
          firstRegionImage = this.overrideSecondKodak;
          regionImages.unshift(firstRegionImage)
        }
        if (!secondRegionImage && this.overrideThirdKodak && this.overrideThirdKodak.regionId==regionInfo.get('id')) {
          secondRegionImage = this.overrideThirdKodak;
          regionImages.unshift(secondRegionImage)
        }
        images = images.concat(regionImages)
      });

      let uniqueImages = images.uniqBy('originalUrl');

      // Started trying to include lodge images here .. jsut need to do the cehck if override present but not founf in first 10
      // if (models.length == 0 && images.length ==0) {
      //   this.itinerary.accomStages.forEach((accomStage)=> {
      //     if (accomStage.lodge) {
      //       let lodgeMeta={id: accomStage.get('lodge.region.id'), name: accomStage.get('lodge.name'), images:[]}
      //       accomStage.lodge.metaLodgeImages.forEach((lodgeImage, imageIndex)=> {
      //         if (imageIndex < 10) {
      //           let newImage = getTripImageFromEntityMetaImage(lodgeImage, accomStage.get('lodge'));
      //           if (!firstRegionImage && this.overrideSecondKodak && this.overrideSecondKodak.originalUrl == newImage.originalUrl ) {
      //             firstRegionImage = newImage;
      //           }
      //           if (!secondRegionImage && this.overrideThirdKodak && this.overrideThirdKodak.originalUrl == newImage.originalUrl ) {
      //             secondRegionImage = newImage;
      //           }
      //           lodgeMeta.images.pushObject(newImage)
      //         }
      //       })
      //       loopable.pushObject(lodgeMeta)
      //     }
      //   })
      // }

      // If there are override images then use them!
      if (!firstRegionImage && this.overrideSecondKodak) {
        firstRegionImage = this.overrideSecondKodak;
        uniqueImages.unshift(firstRegionImage)
      }
      if (!secondRegionImage && this.overrideThirdKodak) {
        secondRegionImage = this.overrideThirdKodak;
        uniqueImages.unshift(secondRegionImage)
      }

      // Use the backup region images if the first image hasn't
      if (!firstRegionImage && backupFirstRegionImage) {
        firstRegionImage = backupFirstRegionImage;
      }

      if (!secondRegionImage && backupSecondRegionImage) {
        secondRegionImage = backupSecondRegionImage;
      }

      if (!firstRegionImage && properRegions.length > 0) {
        firstRegionImage = getRegionHeroImage(properRegions.objectAt(0))        
      }
      if (!secondRegionImage && properRegions.length > 1) {
        secondRegionImage = getRegionHeroImage(properRegions.objectAt(1))
      } else if (!secondRegionImage && properRegions.length > 0 && properRegions.objectAt(0).get('metaImagesAfterQuote.length')>0) {
        secondRegionImage =  getTripImageFromRegionalImage(properRegions.objectAt(0).get('metaImagesAfterQuote').objectAt(0), properRegions.objectAt(0))
      }      

      // generic images to cater for lodges that don't have any other photos
      if (!firstRegionImage) {
        firstRegionImage = {
          originalUrl: 'https://waybird.imgix.net/assets/images/destination-fallback/Mountains_1.jpg',
          coverStyle: 'center center'
        }
        uniqueImages.pushObject(firstRegionImage);
      }
      if (!secondRegionImage) {
        secondRegionImage = {
          originalUrl: 'https://waybird.imgix.net/assets/images/destination-fallback/Mountains_3.jpg',
          coverStyle: 'center center'
        }
        uniqueImages.pushObject(secondRegionImage);
      }

      this.set('firstRegionImage', firstRegionImage)
      this.set('secondRegionImage', secondRegionImage)

      return uniqueImages;

    });
  }

});
