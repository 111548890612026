import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  classNames: ['experiences-section'],

  destination: null,

  showSubheading: true,
  inModal: false,

  isCategories: false,

  @computed('experience')
  isRegionalExperience(experience) {
    return experience && experience.get('isRegionalExperience');
  },

  @computed('destination', 'isRegionalExperience')
  selectedCountryName(destination, isRegionalExperience) {
    if (destination) {
      var destinationType = destination.get('constructor.modelName')
    }
    if (destinationType === 'region') {
      return this.get('destination.country.name')
    } else if (destinationType === 'country') {
      return this.get('destination.name')
    } else if (isRegionalExperience) {
      return this.get('experience.region.country.name')
    }
  },

  @computed('destination')
  selectedRegionName(destination) {
    if (destination) {
      var destinationType = destination.get('constructor.modelName')
    }
    if (destinationType === 'region') {
      return this.get('destination.name')
    } else {
      return 'All Regions'
    }
  },

  @computed('experience', 'isRegionalExperience')
  selectedExperienceNames(experience, isRegionalExperience) {
    if (experience && !isRegionalExperience) {
      return experience.get('name')
    }
  },

  @computed('selectedCountryName', 'selectedRegionName', 'selectedExperienceNames', 'specifySeeAllLinkHash', 'isRegionalExperience', 'experience')
  seeAllLinkHash(selectedCountryName, selectedRegionName, selectedExperienceNames, specifySeeAllLinkHash, isRegionalExperience, experience) {
    if (specifySeeAllLinkHash) {
      return specifySeeAllLinkHash;
    }

    var query = {};
    var hash = {};

    if (isRegionalExperience) {
      hash = {
        title: "Show all experiences",
        route: 'region.experiences',
        item: experience.get('region'),
        query
      }
    } else {
      hash = {
        title: "Show all experiences",
        route: 'experience.experiences',
        item: experience,
        query
      }
    }

    if (selectedCountryName) {
      query['countries'] = [selectedCountryName];
    }
    if (selectedRegionName) {
      query['regions'] = [selectedRegionName];
    }
    if (selectedExperienceNames) {
      query['experiences'] = [selectedExperienceNames];
    }
    return hash;
  },

  @computed('destination.destinationInfo.experiences', 'excludedExperience', 'specifyExperiences')
  recommendedExperiences(destinationExperiences, excludedExperience, specifyExperiences) {
    let experiences = specifyExperiences || destinationExperiences;
    if (experiences) {
      if (excludedExperience) {
        experiences = experiences.rejectBy('id', excludedExperience.get('id'))
      }
      return experiences.slice(0, 6);
    }
  }

});
