import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({



  templateManager: service(),


  @computed('trip.itinerary.stages.lastObject.schedule.allBlockItems.@each.blockItemType')
  lastLocationPageCorrectlyConfigured(allBlockItems) {

    let finishStage = this.get('trip.itinerary.stages.lastObject');
    if (!finishStage || !finishStage.schedule) {
      return false;
    }
    if (finishStage.schedule.getBlockItemByTypeIfItExists('todays-transfers') || finishStage.schedule.getBlockItemByTypeIfItExists('todays-preceding-stages')) {
      return true
    }

    return false
  },

});
