import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';

import {
  COUNTRIES,
  USA_STATES
} from 'b5b/utils';

export default Component.extend({

  @computed()
  sourceCountries() {
    return COUNTRIES;
  },

  @computed()
  sourceStates() {
    return USA_STATES;
  },

  @computed('sourceCountry', 'settlementCurrency')
  currencyMismatch(sourceCountry, settlementCurrency) {
    let sourceCountryDefinition = COUNTRIES.findBy('code', sourceCountry);
    if (sourceCountryDefinition && settlementCurrency) {
      if ((sourceCountryDefinition.defaultCurrency && sourceCountryDefinition.defaultCurrency != settlementCurrency) && this.whitelabel.settlementCurrencies.includes(sourceCountryDefinition.defaultCurrency)) {
        return `A source country of ${sourceCountryDefinition.name} typically has a settlement currency of ${sourceCountryDefinition.defaultCurrency}, however the settlement currency is currently ${settlementCurrency}. Are you sure this is correct?`
      }
    }
  },

  actions: {
    setSourceCountry(country) {
      this.set('sourceCountry', country.code);
      let sourceCountryMeta = COUNTRIES.findBy('code', this.sourceCountry);
      if (this.whitelabel.settlementCurrencies.includes(sourceCountryMeta.defaultCurrency)) {
        this.set('settlementCurrency', sourceCountryMeta.defaultCurrency);
      }
      if (this.sourceCountry != 'US') {
        this.set('sourceState', null);
      }
    },
    setSourceState(state) {
      this.set('sourceState', state.code);
    }
  }

});
