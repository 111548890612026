import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  store: service(),

  compute([country], namedArgs) {
    return this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: country.id}]),
    }).then((regions)=> {
      let images = [];
      regions.forEach((region) => {
        if (country.name !== region.name) {
          images.pushObject({
            description: region.get('name'),
            originalUrl: region.get('kodakOriginalUrl'),
            coverStyle: region.get('coverStyle')
          })
        }
      })
      return images;
    })
  }

});
