import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import RSVP from 'rsvp';
import {
  metadataDate,
  generateMeta
} from 'b5b/utils';
import {
  htmlSafe
} from '@ember/string';

import Route from '@ember/routing/route';

export default Route.extend({

  store: service(),
  fastboot: service(),
  headTagsService: service('head-tags'),
  headData: service(),
  isFastBoot: reads('fastboot.isFastBoot'),


  headTags() {
    let model = this.currentModel;
    const title = `Where to go in ${model.get('name')} in Africa | ${this.whitelabel.agencySeoName}`;
    let description = model.get('asyncModels.monthInfo.overview')
    model.set('asyncModels', null);

    let robots = 'index, follow';
    if (!this.isFastBoot) {
      document.title = title;
    }

    return generateMeta({
      description,
      robots,
      'og:title': title,
      'og:image': model.get('kodakOriginalUrl'),
      'link': `when-to-go/month/${model.get('monthFriendlyId')}`
    });
  },


  afterModel(model) {

    let asyncModels = RSVP.hash({
      monthInfo: model.get('monthInfo')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.headTagsService.collectHeadTags();
    });

    var webPage,
      breadcrumbList,
      organization;

    model.get('monthInfo').then(() => {
      // We'll just resolve the promise to keep this consistent with other routes..
      webPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "headline": `Where to go in ${model.get('name')} in Africa`,
        "image": {
          "@type": "ImageObject",
          "url": model.get('kodakOriginalUrl') + "?w=1400&h=960",
          "height": 960,
          "width": 1400
        },
        "datePublished": metadataDate(model.get('createdAt')),
        "dateModified": metadataDate(model.get('updatedAt')),
        "publisher": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "author": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "description": model.get('countryInfo.overview'),
        "mainEntity": {
          "@type": "Thing",
          "name": model.get('name')
        }
      };

      breadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "http://www.timbuktutravel.com/when-to-go",
            "name": "When to go to Africa",
            "image": "https://waybird.imgix.net/images/when-to-go/when-to-go-to-africa-main-timbuktu-travel.JPG?w=1200&h=630"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.timbuktutravel.com/when-to-go/month/" + model.get('monthFriendlyId'),
            "name": `Where to go in ${model.get('name')} in Africa`,
            "image": model.get('kodakOriginalUrl') + "?w=1200&h=630"
          }
        }]
      };

      organization = this.get('settings.organizationSchema');

    }).finally(() => {
      this.set('headData.dataStructures', [
        htmlSafe(JSON.stringify(webPage)),
        htmlSafe(JSON.stringify(breadcrumbList)),
        htmlSafe(JSON.stringify(organization))
      ]);
    });


  },

  setupController: function(controller, model) {
    this._super(...arguments);

    let query = {
      per_page: 3
    };

    query['selectedMonthNames'] = [model.get('name')]

    this.store.query('tripSummary', query).then((trips) => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      controller.set('recommendedTrips', trips);
    });

    controller.setProperties({
      month: model
    });

    let countries = [];

    model.get('monthInfo').then((monthInfo) => {

      monthInfo.get('bestTimeDestinations').forEach((destination) => {
        if (destination.type === 'country' && !countries.includes(destination.destination)) {
          countries.pushObject(destination.destination);
        }
      });

      monthInfo.get('highlightDestinations').forEach((destination) => {
        if (destination.type === 'country' && !countries.includes(destination.destination)) {
          countries.pushObject(destination.destination);
        }
      });

      controller.setProperties({
        countries: countries
      });
    });
  }

});
