import LodgeCard from 'b5b/components/lodge-card/component';
import {
  inject as service
} from '@ember/service';

export default LodgeCard.extend({
  tripService: service('trip'),
  classNames: ['lodge-card-custom']

});
