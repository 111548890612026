import DS from 'ember-data';

export default DS.Model.extend({

  guest: DS.belongsTo('guest', {
    inverse: null,
    async: false
  }),
  lineItem: DS.belongsTo('actualLineItem')

});
