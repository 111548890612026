import Component from '@ember/component';

import {
  inject as service
} from '@ember/service';

import {
  computed
} from 'ember-decorators/object';
import {
  or
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import {
  htmlSafe
} from '@ember/string';
import bodyScrollLock from 'body-scroll-lock';

export default Component.extend({
  tagName: '',

  screen: service(),
  messageBus: service(),
  pills: service(),
  whitelabel: service(),

  // this is shared between all child components, if it causes issues we can separate it and move it down
  @computed('selectedTripLengths', 'selectedContinentNames', 'selectedCountryNames', 'selectedExperienceNames', 'selectedLodgeStyleNames', 'selectedRegionNames', 'selectedRegionTypeNames', 'selectedMonthNames', 'selectedBudget', 'selectedLodgeNames', 'showOnlyAvailable', 'showOnlyWithOffers', 'maxLodgePrice', 'minLodgePrice', 'startDate', 'endDate', 'selectedAreaNames', 'exclusiveUseOnly')
  totalFiltersApplied(selectedTripLengths, selectedContinentNames, selectedCountryNames, selectedExperienceNames, selectedLodgeStyleNames, selectedRegionNames, selectedRegionTypeNames, selectedMonthNames, selectedBudget, selectedLodgeNames, showOnlyAvailable, showOnlyWithOffers, maxLodgePrice, minLodgePrice, startDate, endDate, selectedAreaNames, exclusiveUseOnly) {

    let total = 0;

    total += selectedTripLengths && selectedTripLengths.length ? 1 : 0;
    total += selectedContinentNames ? selectedContinentNames.length : 0;
    total += selectedCountryNames ? selectedCountryNames.length : 0;
    total += selectedExperienceNames ? selectedExperienceNames.length : 0;
    total += selectedLodgeStyleNames ? selectedLodgeStyleNames.length : 0;
    total += selectedAreaNames ? selectedAreaNames.length : 0;
    total += selectedRegionNames ? selectedRegionNames.length : 0;
    total += selectedRegionTypeNames ? selectedRegionTypeNames.length : 0;
    total += selectedMonthNames ? selectedMonthNames.length : 0;
    total += selectedLodgeNames ? selectedLodgeNames.length : 0;
    total += selectedBudget ? ((selectedBudget[0].toString() !== this.minPriceForRange().toString() || selectedBudget[1].toString() !== this.maxPriceForRange().toString()) ? 1 : 0) : 0;
    total += showOnlyAvailable ? 1 : 0;
    total += showOnlyWithOffers ? 1 : 0;
    total += exclusiveUseOnly ? 1 : 0;
    total += (startDate || endDate) ? 1 : 0;

    return total;
  },

  didInsertElement() {
    this._super(...arguments);
    this.set('mapSearchEnabled', !this.screen.isMobileOnly);
  },

  willDestroyElement() {
    this._super(...arguments);
    // If you go into map pane view, and then click through to a lodge, we need to ensure that there are no body scroll locks in place
    bodyScrollLock.clearAllBodyScrollLocks();      
  },
    
  didRender() {
    this._super(...arguments);
    if (this.filterOnlyView && this.get('screen.isTablet')) {
      this.setProperties({
        filterOnlyView: null
      });
    }

    if (this.mapOnlyView && !this.get('screen.isPerPaneView')) {
      this.setProperties({
        mapOnlyView: null
      });
    }
  },

  @or('mapOnlyView', 'filterOnlyView') mapOrFilterView: false,

  panes: ['map', 'listing', 'filter'],

  @computed('screen.isPerPaneView', 'mapOnlyView', 'filterOnlyView')
  showMapPane(isPerPaneView, mapOnlyView) {
    return mapOnlyView || !isPerPaneView
  },

  @computed('ui.agencyTopNavHeight')
  mapPaneStyle(agencyTopNavHeight) {
    const greenNav = $('.green-nav.visible').height() || 0;
    const mapTop = agencyTopNavHeight + greenNav;
    return htmlSafe(`top: ${mapTop}px`);
  },

  @computed('mapOnlyView', 'filterOnlyView')
  showListingPane(mapOnlyView, filterOnlyView) {
    // return true;
    return !mapOnlyView && !filterOnlyView;
  },

  @computed('screen.isTablet', 'filterOnlyView', 'mapOnlyView')
  showFilterPane(isTablet, filterOnlyView) {
    return isTablet || (!isTablet && filterOnlyView)
  },

  actions: {

    enablePaneView(enabledPane) {
      if(enabledPane === 'map') {
        bodyScrollLock.disableBodyScroll($(".wrapper"));
      } else {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
      this.panes.forEach((pane) => {
        this.set(pane + 'OnlyView', pane === enabledPane || null);
      });
    },

    disablePaneView(disabledPane) {
      bodyScrollLock.clearAllBodyScrollLocks();
      this.set(disabledPane + 'OnlyView', null);
    },

    resetSuggestionsCategory(name) {
      this._super(...arguments);
      // extended in child components

      switch (name) {

        case 'selectedContinentNames':
          this.set('selectedContinentNames', []);
          break;

        case 'selectedCountryNames':
          this.set('selectedCountryNames', []);
          break;

        case 'selectedRegionNames':
          this.set('selectedRegionNames', []);
          break;

        case 'selectedAreaNames':
          this.set('selectedAreaNames', []);
          break;

        case 'selectedExperienceNames':
          this.set('selectedExperienceNames', []);
          break;

        case 'selectedMonthNames':
          // this.set('selectedMonths', null);
          // can we remove the default of Any Month here?
          this.set('selectedMonthNames', ['Any Month']);
          break;

        case 'datesAndAvailability':
          this.setProperties({
            showOnlyAvailable: false,
            startDate: null,
            endDate: null
          });
          break;

        default:
          break;
      }

      run.next(() => {
        this.messageBus.publish('suggestions-refresh');
        this.loadSuggestions();
      })

    }
  }
});
