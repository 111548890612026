import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  not
} from 'ember-decorators/object/computed';
import {
  inject as service
} from '@ember/service';

import {
  CHARGE_PER_VEHICLE,
  VEHICLE_TYPE_SELF_DRIVE
} from 'b5b/models/transfer';

export default Component.extend({
  tagName: 'span',
  @not('transfer.isCustomTransfer') isVisible: false,
  classNames: ['transfer-price-with-tooltips'],

  settings: service(),

  click(e){
    e.stopPropagation();
    e.preventDefault();
  },

  @computed('transfer.isFallback', 'transfer.chargePer', 'transfer.vehicleType', 'transfer.duration')
  toolTipMessages(isFallback, chargePer, vehicleType, duration) {
    let messages = [];
    messages.pushObject("This is an estimated price.");

    if (chargePer==CHARGE_PER_VEHICLE) {
      messages.pushObject("Price is based on 2 people travelling.");
    }
    if (vehicleType==VEHICLE_TYPE_SELF_DRIVE) {
      messages.pushObject("This is a day rate. Get in touch for more info.");
    }
    if (duration) {
      messages.pushObject("Times are approximate and do not include waiting or check-in time.")
    }

    messages.pushObject("Get in touch to find out more.");

    return messages;
  }
});
