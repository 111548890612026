import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import {
  computed
} from 'ember-decorators/object';
import {
  AVAILABLE_CURRENCIES,
  CURRENCY_SYMBOLS
} from 'b5b/services/settings';

export default Controller.extend({
  settings: service(),
  ui: service(),

});