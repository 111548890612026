
import DayByDaySection from 'b5b/components/day-by-day-section/component';
import {
  computed
} from 'ember-decorators/object';
import {
isEmpty
} from '@ember/utils';

export default DayByDaySection.extend({

  actions: {
    openDatePicker(e) {
      this.tripService.tripDatePickerInstance.open();
      e.stopPropagation()
    },
  }


});
