import { inject as service } from '@ember/service';
import Component from '@ember/component';
import config from 'b5b/config/environment';

export default Component.extend({
  classNames: ['auth-form', 'change'],

  ui: service(),

  token: null,
  didChange: false,
  sending: false,
  onLogin: null,

  didInsertElement() {
    this._super(...arguments);
    this.ui.setProperties({
      showRegisterModal: false,
      showLoginModal: false,
      showResetPassword: false
    });
  },

  willDestroyElement() {
    this._super(...arguments);
    this.setProperties({
      didChange: false,
      sending: false
    });
  },

  actions: {
    doChange() {
      this.set('sending', true);

      let url = this.whitelabel.getFullHostUrl('/server/users/password');
      $.ajax({
        url,
        type: "POST",
        data: `utf8=%E2%9C%93&_method=put&user%5Bpassword%5D=${this.newPassword}&user%5Bpassword_confirmation%5D=${this.confirmPassword}&user%5Breset_password_token%5D=${this.token}`,
        context: this
      }).then(function() {
        // handle your server response here
        this.setProperties({
          didChange: true,
          sending: false
        });
      }).catch(function(errors) {
        // handle errors here
        this.set('errors', $.parseJSON(errors.responseText).errors);

        this.setProperties({
          sending: false
        });
      });

      return false;

      // utf8=%E2%9C%93&_method=put&user%5Bpassword%5D=PASSWORD&user%5Bpassword_confirmation%5D=PASS_CONFIRMATION&user%5Breset_password_token%5D=RESET_TOKEN
    },

    onLogin() {
      if (this.onLogin) {
        this.onLogin();
      }
    }
  }
});
