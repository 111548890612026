import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Controller.extend({

  settings: service(),
  ui: service(),

  showMapModal: null,

  entityTrips: null,

  // @computed('country')
  // mapMarker(country) {
  //   if (!country.get('latitude') || !country.get('longitude')) {
  //     return;
  //   }

  //   return [
  //     [parseFloat(country.get('longitude')).toFixed(6), parseFloat(country.get('latitude')).toFixed(6)]
  //   ];
  // },

  actions: {
    openMapModal() {
      this.set('showMapModal', true);
    }
  }

});
