import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

import {
  trackEvent
} from 'b5b/utils';


export default Route.extend(tripSubRoute, basicNav, {

  beforeModel(transition) {
    this._super(...arguments);
        
    if (this.get('whitelabel.isForAgency')) {
      transition.abort();        
    }
  },


  setupController() {
    this._super(...arguments);
    // NOTE IS IMPORTANT THAT WE DO NOT USE THE MODEL FROM MODEL HOOK.. WE NEED TO GET THE DRAFT TRIP that is set ir trip route
    trackEvent('trip:enquire:start');
  }
});
