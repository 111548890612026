import {
  inject as service
} from '@ember/service';
import {
  capitalize
} from '@ember/string';
import NavBarComponent from 'b5b/components/nav-bar/component';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  pluralize
} from 'ember-inflector';
import {
  trackEvent
} from 'b5b/utils';


export default NavBarComponent.extend({

  session: service(),
  scroll: service(),
  ui: service(),
  router: service(),
  whitelabel: service(),

  classNameBindings: [':main', 'isActive'],

  tagName: 'nav',

  @alias('scroll.isMenuExpanded') isActive: false,

  @computed('router.currentRouteName')
  isInProfile(routeName) {
    return routeName.indexOf('users') === 0;
  },

  didRender() {
    this._super(...arguments);
    if (this.get('screen.isMobileOnly')) {
      // reset scroll for mobile
      this.get('scroll').to(0, this, null, null, this.$('.nav-items'));
    }
  },

  actions: {
    closeNav() {
      this.set('isActive', false);
    },

    closeChat() {
      window.HubSpotConversations.widget.remove();
      this.set('isActive', false);
    },


    toggleNav() {
      this.toggleProperty('isActive');
      trackEvent('link:click:mobile-nav-toggle-nav')
    },

    logout() {
      this.get('router').transitionTo('index');
      return this.get('session').invalidate();
    }

  }

});
