import Component from '@ember/component';
import {
  trackEvent,
  metadataDate
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  reads
} from 'ember-decorators/object/computed';
import {
  isEmpty
} from '@ember/utils';
import {
  inject as service
} from '@ember/service';
import PillsMixin from 'b5b/pills/mixin';
import {
  observer
} from '@ember/object';

export default Component.extend(PillsMixin, {
  classNameBindings: [':filter-pane', 'isSearching'],
  
  pills: service(),
  scroll: service(),
  cache: service(),
  xplorer: service('components/x-plorer'),
  @reads('scroll.isSearching') isSearching: false,

  tripService: service('trip'),
  messageBus: service(),

  possibleMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],


  // syncCountryPlaces: observer('filters.[]', function() {
    
  //   let filterRegions = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'region')
  //   let filterCountries = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'country')    
  //   let countryPlaces = [];
  //   if (filterRegions && filterRegions.length > 0) {
  //     let regionIds = filterRegions.mapBy('id')
  //     this.store.query('region', {ids: regionIds, force_region_ids: true}).then((regions)=> {
  //       regions.forEach((region)=> {
  //         if (!(countryPlaces.includes(region.country))) {
  //           countryPlaces.pushObject(region.country)
  //         }
  //       })        
  //       this.set('countryPlaces', countryPlaces)
  //     })
  //   }
  //   if (filterCountries && filterCountries.length > 0) {
  //     let countryIds = filterCountries.mapBy('id')
  //     this.cache.getAllPublishedCountriesPromise().then((countries)=> {
  //       countries.forEach((country)=> {
  //         if (countryIds.includes(country.id) && !(countryPlaces.includes(country))) {
  //           countryPlaces.pushObject(country)
  //         }                    
  //       })
  //       this.set('countryPlaces', countryPlaces)
  //     })
  //   }    

  // }).on('init'),



  syncRegionAreas: observer('filters.[]', function() {
    
    let filterRegions = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'region')
    let filterAreas = this.xplorer.filters && this.xplorer.filters.filterBy("type", 'area')    
    let regionAreas = [];
    if (filterRegions && filterRegions.length > 0) {
      let regionIds = filterRegions.mapBy('id')
      this.store.query('region', {ids: regionIds, force_region_ids: true, buster: true}).then((regions)=> {
        regionAreas = (regionAreas.concat(regions)).uniq()
        this.set('regionAreas', regionAreas)
        this.set('regionAreas', regionAreas)
      })
    }
    if (filterAreas && filterAreas.length > 0) {
      let areaIds = filterAreas.mapBy('id')
      this.store.query('area', {ids: areaIds, force_area_ids: true}).then((areas)=> {
        areas.forEach((area)=> {
          if (!(regionAreas.includes(area.region))) {
            regionAreas.pushObject(area.region)
          }
        })
        this.set('regionAreas', regionAreas)
      })
    }    

  }).on('init'),

  checkDates() {
    if (this.get('startDate') && this.get('endDate')) {

      this.set('tripService.didChangeLodgeDates', true);

      this.send('addSuggestion', {
        start: this.get('startDate'),
        end: this.get('endDate')
      }, 'dates');
    }
  },

  actions: {
    setStartDate(date) {
      let newMoment = moment(date);
      let startMoment = moment(this.get('startDate'));
      let endMoment = moment(this.get('endDate'));

      if (newMoment.diff(endMoment, 'days') >= 0) {
        if (this.get('startDate') && this.get('endDate')) {
          let diff = endMoment.diff(startMoment, 'days');
          // end date always need to be at least one after the start
          diff = diff > 0 ? diff : 1;
          this.set('endDate', metadataDate(newMoment.clone().add(diff, 'days')));
        } else {
          this.set('endDate', metadataDate(newMoment.clone().add(1, 'days')));
        }
      }
      this.set('startDate', metadataDate(newMoment));
      this.checkDates();
    },

    setEndDate(date) {
      this.set('endDate', metadataDate(date));
      this.checkDates();
    },

    addSuggestion(item, type, key) {
      trackEvent('filter-pane:suggestion:added');
      this.messageBus.publish('suggestion-added', item, type, key);
    },

    removeSuggestion(suggestion) {
      trackEvent('filter-pane:suggestion:removed');
      this.messageBus.publish('suggestion-removed', suggestion);
    },

    toggleNameFilter(filter, name, type) {
      let suggestion = {
        name,
        type
      };

      if (this.get(filter).constructor === Array) {
        if (this.get(filter).includes(name)) {
          this.send('removeSuggestion', suggestion)
        } else {
          this.send('addSuggestion', name, type)
        }
      } else {
        if (this.get(filter)) {
          this.send('removeSuggestion', suggestion)
        } else {
          this.send('addSuggestion', name, type)
        }
      }
    },

    toggleFilter(model, type) {
      if (this.get('xplorer').isFilterPresent(model)) {
        trackEvent('filter-pane:suggestion:removed');
        this.messageBus.publish('suggestion-removed', this.xplorer.getFilterForModel(model));  
      } else {
        trackEvent('filter-pane:suggestion:added');
        this.messageBus.publish('suggestion-added', model, type);  
      }
    }

  }
});
