import { subscribe, unsubscribe } from '@ember/instrumentation';
import Mixin from '@ember/object/mixin';
import {
  run
} from '@ember/runloop';


export default Mixin.create({

  setupListenerForFacebookAuth: function() {
    // Currently there is code in the application route which capures a facebook login and emits a
    // user.loggedIn event that this can respond to
    let userAuthenticated = this.userAuthenticated ? this.userAuthenticated : run.bind(this, 'genericUserAuthenticated');

    this.set('userLoggedInListener', subscribe("user.loggedIn", {
      after: function() {
        userAuthenticated();
      },
      before: function() {

      }
    }));
  }.on('didInsertElement'),

  removeListenerForFacebookAuth: function() {
    unsubscribe(this.userLoggedInListener);

  }.on('willDestroyElement')

});