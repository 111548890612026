import Component from '@ember/component';

export default Component.extend({

  classNameBindings: [':x-accordion-item', 'isOpen'],

  isOpen: false,

  didInsertElement() {
    this.get('parentView.siblings').pushObject(this);
  },

  willDestroyElement() {
    if (this.get('parentView.siblings')) {
      this.get('parentView.siblings').removeObject(this);
    }
  },

  actions: {
    toggleItem() {
      if (this.get('parentView.actions.manageItem')) {
        this.parentView.send('manageItem', this);
      } else {
        this.toggleProperty('isOpen');
      }
    }
  }

});
