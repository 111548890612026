import {
  inject as service
} from '@ember/service';
import Controller from '@ember/controller';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';

export default Controller.extend(UseCurrentTrip, {

  hideFlightsBanner: false,
  settings: service(),
  ui: service(),
  whitelabel: service()

});
