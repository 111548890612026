define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y2lyHBWK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"meta\"],[11,\"name\",\"ember-cli-head-start\"],[9],[10],[1,[21,\"head-content\"],false],[7,\"meta\"],[11,\"name\",\"ember-cli-head-end\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });
  _exports.default = _default;
});