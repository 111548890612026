import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({
  classNameBindings: [':trip-date-selector'],

  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,
  @alias('tripService.tripDatePickerInstance') datePickerInstance: null,


  dateFormat: "D MMM [']YY",
  placeholder: 'Start date',

  actions: {
    openPicker(e) {
      e.stopPropagation();
      this.get('datePickerInstance').open();
    }
  }

});
