import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';
import {
  bool
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';

export default Component.extend({

  classNames: 'countries-section',

  store: service(),
  cache: service(),

  @bool('countries.length') isVisible: null,
  isShownAll: false,

  country: null,

  init() {
    this._super(...arguments);
    this.cache.getAllPublishedCountriesPromise().then((countries)=> {
      this.set('countries', countries.without(this.country).filterBy('continentName', this.country.continentName))
    })
  },

  actions: {
    showPlaces() {
      this.set('isShownAll', true);
      trackEvent('destination:show-all-countries');
    }
  }

});
